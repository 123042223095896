import kebabCase from 'lodash/fp/kebabCase';
import lowerCase from 'lodash/fp/lowerCase';
import startCase from 'lodash/fp/startCase';
import * as R from 'ramda';
/**
   * Get the rest of string apart from the first string
   * and turn them into start case. ETH2_VALIDATOR_NODE => Validator Node
   * @param  {string} nodeType - the node type
   * @return {string}
   */
export const makeNodeTypeToDisplay = R.curry(
  (nodeType) => R.pipe(
    R.split('_'),
    R.tail,
    R.join(' '),
    lowerCase,
    startCase,
  )(nodeType),
);

const generateRandomChar = R.curry((characters, hash) => {
  const charactersLength = characters.length;
  return hash + characters.charAt(Math.floor(Math.random() * charactersLength));
});

export const generateHash = R.curry(() => {
  const characters = 'abcdefghijklmnopqrstuvwxyz0123456789';
  const dummyList = ['a', 'b', 'c', 'd', 'e'];
  const result = R.reduce(generateRandomChar(characters), '', dummyList);
  return result;
});

/**
   * Generate default node name from user email and node type
   * @param  {string} email
   * @param  {string} nodeType - the node type
   * @return {string}
   */
export const makeDefaultNodeNameToDisplay = R.curry((nodeType) => {
  const hash = generateHash();
  const defaultNodeNameToDisplay = `${kebabCase(nodeType)}-${hash}`;
  return defaultNodeNameToDisplay;
});
