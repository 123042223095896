import * as R from 'ramda';
import { combineReducers } from 'redux';

import {
  createReducer,
  mergeStateAndPayload,
} from '../store_utils';

import { formatPhalaPoolRewardData, formatPhalaRollupRewardData } from './services';
import * as types from './types';

const poolRewardsIS = {
  phala: {
    total: 0,
    items: [],
  },
};

const poolRewardsRUpdaters = {
  [types.GET_USER_POOLS_SUCCESS]: (state, payload) => (
    mergeStateAndPayload(
      state,
      {
        phala: formatPhalaPoolRewardData(payload),
      },
    )
  ),
};
const poolRewardsR = createReducer(poolRewardsIS, poolRewardsRUpdaters);

const rollupRewardsIS = {
  phala: {
    ownerRollupRewards: 0,
    sixCore: 0,
    fourCore: 0,
  },
};

const updateUserRollupRewards = R.curry((payload, state) => R.over(
  R.lensPath(['phala']),
  R.mergeLeft(formatPhalaRollupRewardData(payload)),
)(state));
const rollupRewardsRUpdaters = {
  [types.GET_USER_ROLLUP_REWARDS_SUCCESS]: (state, payload) => (
    updateUserRollupRewards(payload, state)
  ),
};
const rollupRewardsR = createReducer(rollupRewardsIS, rollupRewardsRUpdaters);

const poolsIS = {
  phala: {
    items: [],
    meta: {},
  },
};

const poolsRUpdaters = {
  [types.GET_POOLS_SUCCESS]: (state, payload) => (
    mergeStateAndPayload(
      state,
      {
        phala: payload,
      },
    )
  ),
};
const poolsR = createReducer(poolsIS, poolsRUpdaters);

const poolR = combineReducers({
  poolRewards: poolRewardsR,
  rollupRewards: rollupRewardsR,
  pools: poolsR,
});

export default poolR;
