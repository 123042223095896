import BigNumber from 'bignumber.js';
import {
  pipeAwait,
} from 'jsutils';
import * as R from 'ramda';

import {
  axiosGetUnsecure,
} from '../../modules/axios_utils';

import {
  ethApiUrl,
} from './constants';

export const sanitizeHex = (hex) => {
  const newHex = hex.substring(0, 2) === '0x' ? hex.substring(2) : hex;
  if (newHex === '') {
    return '';
  }
  const resultHex = newHex.length % 2 !== 0 ? `0${newHex}` : newHex;
  return `0x${resultHex}`;
};

export const convertStringToHex = (value) => {
  const bigNum = new BigNumber(`${value}`).toString(16);
  return bigNum;
};

export const handleSanitizeHex = R.pipe(
  convertStringToHex,
  sanitizeHex,
);

export const convertAmountToRawNumber = R.curry(
  (decimals, value) => {
    const num = new BigNumber(`${value}`).times(new BigNumber('10').pow(decimals)).toString();
    return num;
  },
);

export const createGetAccountNonce = R.curry(
  async (ethApiUrl, address, chainId) => {
    const url = `${ethApiUrl}/account-nonce?address=${address}&chainId=${chainId}`;
    return pipeAwait(
      axiosGetUnsecure,
      R.prop('result'),
    )(url);
  },
);

export const getAccountNonce = createGetAccountNonce(ethApiUrl);

export const getGasPrice = R.curry(
  async (ethApiUrl) => {
    const url = `${ethApiUrl}/gas-prices`;
    return pipeAwait(
      axiosGetUnsecure,
      R.view(
        R.lensPath(
          ['result', 'slow', 'price'],
        ),
      ),
    )(url);
  },
);
