import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import whyDidYouRender from '@welldone-software/why-did-you-render';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { reduxForm } from 'redux-form';

import { useAddFunctionToRef, useGetQuery, useUserDataSelector } from '../../hooks/hooks';
import { minLength8 } from '../../modules/validates';
import { investmentBaseRoute, notFoundPageRoute, signinRoute } from '../../routes';
import { routerServices } from '../../store/router/index';
import { persistor } from '../../store/store';
import { userOperations } from '../../store/user/index';
import {
  GET_FIRST_SIGNIN_SUCCESS,
  UPDATE_PASSWORD_SUCCESS,
  SIGNIN_SUCCESS,
} from '../../store/user/types';
import { RoundedButton } from '../MaterialUiCustom/MaterialUiCustom';
import PasswordField from '../PasswordField/PasswordField';

import { styles } from './styles';
import { validateSetPassword } from './validate';

// store

if (process.env.REACT_APP_ENV !== 'prod') {
  whyDidYouRender(React);
}
const useStyles = makeStyles(styles);
const formName = 'setPassword';

const {
  REACT_APP_USER_DEFAULT_PASSWORD,
} = process.env;

const FirstSignin = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();
  const queryLng = useGetQuery('lng');
  const history = useHistory();
  const { match } = props;

  const {
    submitting,
    pristine,
    invalid,
    handleSubmit,
    redirectPathName,
    customFirstSigninId,
    disableSignOut,
  } = props;

  const firstSigninIdToUse = customFirstSigninId || match.params.token;

  const [data, setData] = useState({});
  // const [errorMessage, setErrorMessage] = useState('');
  const [error, setError] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(true);
  const [isSignedIn, setIsSignedIn] = useState(false);
  const [redirectToSignin, setRedirectToSignin] = useState(false);
  const [disableButton, setDisableButton] = useState(false);
  const { userId } = useUserDataSelector();

  const handleClickShowPassword = () => setShowPassword(!showPassword);

  const onSignout = useAddFunctionToRef(
    async () => {
      await dispatch(userOperations.signOut(userId));
      await persistor.purge();
    // await persistor.flush();
    // await persistor.pause();
    },
  );

  const handleSetPassword = async (formValues) => {
    setDisableButton(true);

    const { password } = formValues;
    const resetPasswordResponse = await dispatch(userOperations.changeUserPassword(data.userId, REACT_APP_USER_DEFAULT_PASSWORD, password));
    if (resetPasswordResponse.type === UPDATE_PASSWORD_SUCCESS) {
      await dispatch(userOperations.setUserFirstSignedIn(data.firstSigninId));
      const signinResponse = await dispatch(userOperations.signin(
        { t },
        data.email,
        password,
        'user',
      ));
      if (signinResponse.type === SIGNIN_SUCCESS) {
        setIsSignedIn(true);
      } else {
        setRedirectToSignin(true);
      }
    } else {
      setDisableButton(false);
    }
  };

  const onGetFirstSignin = useAddFunctionToRef(async (token) => {
    setLoading(true);
    const response = await dispatch(userOperations.getFirstSignin(
      token,
    ));

    if (response.type !== GET_FIRST_SIGNIN_SUCCESS) {
      setError(true);
    } else {
      const { payload } = response;
      const {
        signedIn,
      } = payload;
      if (signedIn) {
        setError(true);
      } else {
        setData(payload);
        setLoading(false);
        if (!disableSignOut) {
          onSignout(userId);
        }
      }
    }
  });

  useEffect(() => {
    if (firstSigninIdToUse) {
      onGetFirstSignin(firstSigninIdToUse);
    }
  }, [onGetFirstSignin, firstSigninIdToUse]);

  useEffect(() => {
    if (error) {
      return routerServices.historyPush(
        history,
        {
          pathname: redirectPathName || notFoundPageRoute,
          queryLng,
        },
      );
    }
  }, [error, queryLng, history, redirectPathName]);

  useEffect(() => {
    if (redirectToSignin) {
      return routerServices.historyPush(
        history,
        {
          pathname: signinRoute,
          queryLng,
        },
      );
    }
  }, [redirectToSignin, queryLng, history]);

  useEffect(() => {
    if (isSignedIn) {
      return routerServices.historyPush(
        history,
        {
          pathname: redirectPathName,
          queryLng,
        },
      );
    }
  }, [dispatch, isSignedIn, queryLng, history, redirectPathName]);

  const getContent = () => {
    if (loading) {
      return (
        <Box className={classes.centerComponent}>
          <CircularProgress />
        </Box>
      );
    }
    const titles = {
      en: (<Typography
        variant="h2"
        align="center"
        color="secondary"
        className={classes.title}
      >
        {t('Welcome to Neukind')}
           </Typography>
      ),
      ja: (
        <Box
          display="flex"
          justifyContent="center"
          flexDirection="column"
        >
          <Typography
            variant="h2"
            align="center"
            color="secondary"
            className={classes.title}
          >
            Neukindのダッシュボードへ
          </Typography>
          <Typography
            variant="h2"
            align="center"
            color="secondary"
            className={classes.title}
          >
            <Typography
              variant="h2"
              align="center"
              color="secondary"
              className={classes.title}
            >
              ようこそ。
            </Typography>
          </Typography>
        </Box>)
      ,
    };
    const getTitle = R.curry((titles, queryLng) => {
      const title = titles[queryLng];
      return title || titles.en;
    });
    return (
      <Box className={classes.paper}>
        <form onSubmit={handleSubmit(handleSetPassword)}>
          <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
            className={classes.changePasswordDialog}
          >
            {getTitle(titles, queryLng)}
            <Typography
              className={classes.description}
            >
              {t('This is the first time you visit our website. Please set your password.')}
            </Typography>
            <PasswordField
              handleClickShowPassword={handleClickShowPassword}
              showPassword={showPassword}
              customClassName={classNames(classes.textField)}
              name="password"
              label={t('Password')}
              validate={minLength8}
              showLabel={false}
              showTitle
            />
            <PasswordField
              handleClickShowPassword={handleClickShowPassword}
              showPassword={showPassword}
              customClassName={classNames(classes.textField)}
              id="confirm-password-field"
              name="confirmPassword"
              label={t('Confirm Password')}
              validate={minLength8}
              showLabel={false}
              showTitle
            />
            {/* {errorMessage && <Typography id="error" color="error">{errorMessage}</Typography>} */}
            <Box width="100%">
              <RoundedButton
                variant="contained"
                color="primary"
                type="submit"
                disabled={submitting || pristine || invalid || disableButton}
                className={classNames(classes.button)}
                noMargin
              >
                {t('Set password')}
              </RoundedButton>
            </Box>
          </Grid>
        </form>
      </Box>
    );
  };

  return getContent();
};

FirstSignin.defaultProps = {
  redirectPathName: `${investmentBaseRoute}/mining`,
  disableSignOut: false,
};

FirstSignin.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      token: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  submitting: PropTypes.bool.isRequired,
  pristine: PropTypes.bool.isRequired,
  invalid: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  redirectPathName: PropTypes.string,
  disableSignOut: PropTypes.bool,
};

FirstSignin.whyDidYouRender = true;

export default reduxForm({
  form: formName,
  validate: validateSetPassword,
})(FirstSignin);
