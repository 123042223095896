import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import { useIsMobile } from '../../hooks/hooks';

import { styles } from './styles';

import BackToHomeButton from 'components/MaterialUiCustom/Button/BackToHomeButton';
import RedirectButton from 'components/MaterialUiCustom/Button/RedirectButton';

const useStyles = makeStyles(styles);

const NotFoundPage = (props) => {
  const {
    errorTitle,
    errorMessage,
    btnText,
    redirectURL,
    displayBackButton,
  } = props;
  const isMobileDevice = useIsMobile();
  const titleVariant = isMobileDevice ? 'h3' : 'h1';
  const bodyVariant = isMobileDevice ? 'body1' : 'h5';
  const classes = useStyles();

  const getButton = (redirectURL, btnText) => (redirectURL || btnText
    ? (
      <RedirectButton
        redirectURL={redirectURL}
        color="primary"
        variant="text"
      >
        {btnText}
      </RedirectButton>
    )
    : <BackToHomeButton />);
  return (
    <Grid container justifyContent="center" className={classnames(classes.paper)}>
      <Grid item xs={12}>
        <Typography variant={titleVariant} align="center" className={classnames(classes.centerComponent, classes.title)}>{errorTitle}</Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant={bodyVariant} align="center" className={classnames(classes.centerComponent)}>
          {errorMessage}
        </Typography>
      </Grid>
      {
      displayBackButton && (
      <Grid item>
        {getButton(redirectURL, btnText)}
      </Grid>
      )
      }
    </Grid>
  );
};

NotFoundPage.propTypes = {
  errorTitle: PropTypes.string.isRequired,
  errorMessage: PropTypes.string.isRequired,
  btnText: PropTypes.string,
  redirectURL: PropTypes.string,
  displayBackButton: PropTypes.bool.isRequired,
};
NotFoundPage.defaultProps = {
  redirectURL: null,
  btnText: null,
};

export default NotFoundPage;
