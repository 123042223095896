import PropTypes from 'prop-types';
import React from 'react';
import { useHistory } from 'react-router-dom';

import RoundedButton from './RoundedButton';

import { useGetQuery } from 'hooks/hooks';
import { routerServices } from 'store/router';

const RedirectButton = (props) => {
  const {
    children,
    redirectURL,
    color,
    variant,
  } = props;
  const history = useHistory();
  const queryLng = useGetQuery('lng');
  const handleOnClick = (redirectURL) => {
    routerServices.historyPush(
      history,
      {
        pathname: redirectURL,
        queryLng,
      },
    );
    window.location.reload();
    return false;
  };
  return (
    <RoundedButton
      color={color}
      variant={variant}
      onClick={() => handleOnClick(redirectURL)}
    >
      {children}
    </RoundedButton>
  );
};

RedirectButton.propTypes = {
  color: PropTypes.string,
  redirectURL: PropTypes.string.isRequired,
  variant: PropTypes.string,
  children: PropTypes.node.isRequired,
};
RedirectButton.defaultProps = {
  color: 'default',
  variant: 'default',
};

export default RedirectButton;
