import * as R from 'ramda';

const { REACT_APP_ENV } = process.env;

export const getENVWhenChainChange = (REACT_APP_ENV_PARAM) => {
  const appEnv = REACT_APP_ENV_PARAM || REACT_APP_ENV;
  const isNotProd = R.or(
    appEnv === 'dev',
    appEnv === 'stage',
  );
  const envKey = isNotProd ? 'dev_or_stage' : appEnv;
  return envKey;
};
