import * as R from 'ramda';
import { combineReducers } from 'redux';
import { PURGE } from 'redux-persist';

import {
  createReducer,
  mergeStateAndPayload,
} from '../store_utils';

import * as types from './types';

// STRIPE CUSTOMER
const stripeCustomerIS = {
  stripeCustomerId: '',
};

const stripeCustomerRUpdaters = {
  [types.GET_USER_STRIPE_DATA_SUCCESS]: (
    state,
    payload,
  ) => mergeStateAndPayload(state, payload),
};

const stripeCustomerR = createReducer(stripeCustomerIS, stripeCustomerRUpdaters);

// STRIPE PAYMENT METHOD
const stripePaymentMethodIS = {
  data: [],
};

const stripePaymentMethodRUpdaters = {
  [types.GET_USER_STRIPE_PAYMENT_METHODS_SUCCESS]: (
    state,
    payload,
  ) => mergeStateAndPayload(state, payload),

  // this doesn't update state
  [types.GET_USER_STRIPE_DATA_CANCEL]: null,

  // this reset the state to initial state
  [PURGE]: R.always(stripePaymentMethodIS),

};

const stripePaymentMethodR = createReducer(stripePaymentMethodIS, stripePaymentMethodRUpdaters);

// STRIPE PRICE
const stripePriceIS = {
  priceIds: {
    ETH2_VALIDATOR_NODE: {
      day: {
        stripePriceId: 'price_1HglhNHQU8NsFw0MNNf6kvdB',
      },
      month: {
        stripePriceId: process.env.REACT_APP_ETH2_VALIDATOR_NODE_MONTH || 'price_1HtrwaHQU8NsFw0MOg6lNQy3',
      },
      year: {
        stripePriceId: process.env.REACT_APP_ETH2_VALIDATOR_NODE_YEAR || 'price_1HzfcwHQU8NsFw0MxaNB56A6',
      },
    },
    ETH_FULL_NODE: {
      day: {
        stripePriceId: 'price_1HglhNHQU8NsFw0MNNf6kvdB',
      },
      month: {
        stripePriceId: 'price_1HglhNHQU8NsFw0MNNf6kvdB',
      },
    },
    DOT_VALIDATOR_NODE: {
      day: {
        stripePriceId: 'price_1HglhNHQU8NsFw0MNNf6kvdB',
      },
      month: {
        stripePriceId: 'price_1HglhNHQU8NsFw0MNNf6kvdB',
      },
    },
    NEM_UNUNIFI_VALIDATOR_NODE: {
      day: {
        stripePriceId: 'price_1HglhNHQU8NsFw0MNNf6kvdB',
      },
      month: {
        stripePriceId: process.env.REACT_APP_ETH2_VALIDATOR_NODE_MONTH || 'price_1HtrwaHQU8NsFw0MOg6lNQy3',
      },
      year: {
        stripePriceId: process.env.REACT_APP_ETH2_VALIDATOR_NODE_YEAR || 'price_1HzfcwHQU8NsFw0MxaNB56A6',
      },
    },
  },
};
const stripePriceRUpdaters = {
  [types.SET_STRIPE_PRICE]: (
    state,
    payload,
  ) => mergeStateAndPayload(state, payload),
};

const stripePriceR = createReducer(stripePriceIS, stripePriceRUpdaters);

const reducers = combineReducers({
  stripeCustomer: stripeCustomerR,
  stripePaymentMethods: stripePaymentMethodR,
  stripePrice: stripePriceR,
});

export default reducers;
