export const searchBarStyle = (theme) => ({
  searchPaper: {
    width: 320,
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
  searchInput: {
    width: '100%',
  },
});

export const nodeOwnerContentItemStyles = ((theme) => ({
  root: {
    padding: theme.spacing(2, 2),
    height: '100%',
  },
  content: {
    padding: theme.spacing(0, 0, 1, 0),
    height: '100%',
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(0, 0, 1, 0),
    },
  },
  title: {
    padding: theme.spacing(1, 2, 0, 2),
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(1, 0, 0, 0),
    },
    fontWeight: 600,
  },
}));

export const nodeOwnerOverviewStyles = (theme) => {
  const h3Properties = {
    fontSize: theme.typography.h3.fontSize,
    fontWeight: theme.typography.h3.fontWeight,
  };
  return ({
    container: {
      padding: theme.spacing(0, 2, 1, 2),
      minWidth: '100px',
      ...theme.centerComponent,
    },
    dataWithCircleBg: {
      color: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
    },
    dataWithCircle: {
      strokeLinecap: 'round',
      color: theme.nodeColors.ETH,
    },
    percent: {
      ...h3Properties,
    },
    totalEarning: {
      fontSize: theme.typography.h2.fontSize,
      fontWeight: theme.typography.h2.fontWeight,
    },
    overviewText: {
      ...h3Properties,
    },
    percentTextAsc: {
      ...h3Properties,
      color: theme.palette.green.main,
    },
    percentTextDesc: {
      ...h3Properties,
      color: theme.palette.error.main,
    },
    stack2Top: {
      paddingBottom: theme.spacing(0.5),
      [theme.breakpoints.down('xs')]: {
        padding: theme.spacing(0, 0.5, 0, 0),
      },
    },
    stack2Bottom: {
      paddingTop: 4,
      [theme.breakpoints.down('xs')]: {
        padding: theme.spacing(0, 0, 0, 0.5),
      },
    },
    amountCard: {
      height: '100%',
      margin: 0,
      background: 'transparent linear-gradient(20deg, #607BEE 50%, #C0CCF9 120%) 0% 0% no-repeat padding-box',
    },
  });
};

export const eth2ValidatorAllValidatorsStyles = (theme) => ({
  pubKeyText: {
    fontSize: 14,
    maxWidth: 200,
    textOverflow: 'ellipsis',
  },
  rowExpandedContentItemTitle: {
    fontSize: 14,
    fontWeight: 600,
    minWidth: 150,
  },
  rowExpandedContent: {
    paddingBottom: theme.spacing(2),
  },
});

export const eth2ValidatorEarningHistoryStyles = (theme) => ({
  downloadButton: {
    padding: 0,
    margin: 0,
  },
  downloadButtonText: {
    fontSize: 14,
    fontWeight: 500,
  },
});

export const eth2ValidatorStyles = (theme) => ({
  legacyPageButton: {
    padding: 0,
    margin: '0px 0px 2px 8px',
    textTransform: 'none',
    textDecoration: 'underline',
  },
});
