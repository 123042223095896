import {
  pipeAwait,
  chain,
} from 'jsutils';
import * as R from 'ramda';

import {
  graphqlNormalize,
} from '../../modules/apollo/apollo';
import {
  returnAction,
  createAction,
} from '../store_utils';

import * as types from './types';

export const setPaginationInput = R.curry((scope, paginationInput) => (
  createAction(types.SET_PAGINATION_INPUT_SUCCESS, scope, paginationInput)
));

export const getPaginationMeta = R.curry(
  async (query, queryVariables, queryName) => pipeAwait(
    query,
    graphqlNormalize(queryName),
    chain(R.view(R.lensProp('PaginationMeta'))),
    returnAction(types.GET_PAGINATION_META_SUCCESS),
  )(queryVariables),
);

export const setPaginationMeta = R.curry((scope, paginationMeta) => createAction(
  types.SET_PAGINATION_META_SUCCESS,
  scope,
  paginationMeta,
));
