export const searchBarStyle = (theme) => ({
  searchPaper: {
    width: 320,

    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
  searchInput: {
    width: '100%',
  },
});

export const InvestmentEarningDashboardItemStyles = ((theme) => ({
  root: {
    padding: theme.spacing(2, 2),
    height: '100%',
  },
  content: {
    padding: theme.spacing(0, 0, 1, 0),
    height: '100%',
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(0, 0, 1, 0),
    },
  },
  title: {
    padding: theme.spacing(1, 2, 0, 2),
    fontWeight: 600,
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(1, 0, 0, 0),
    },
  },
}));

export const miningOverviewStyles = (theme) => {
  const h3Properties = {
    fontSize: theme.typography.h3.fontSize,
    fontWeight: theme.typography.h3.fontWeight,
  };
  return ({
    container: {
      padding: theme.spacing(0, 2, 1, 2),
      minWidth: '100px',
      ...theme.centerComponent,
    },
    dataWithCircleBg: {
      color: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
    },
    dataWithCircle: {
      strokeLinecap: 'round',
      color: theme.nodeStatus.active,
    },
    percent: {
      ...h3Properties,
    },
    totalEarning: {
      padding: theme.spacing(0, 2, 1, 2),
      fontSize: theme.typography.h2.fontSize,
      fontWeight: theme.typography.h2.fontWeight,
    },
    overviewText: {
      padding: theme.spacing(0, 2, 1, 2),
      ...h3Properties,
    },
    percentTextAsc: {
      ...h3Properties,
      color: theme.palette.green.main,
    },
    percentTextDesc: {
      ...h3Properties,
      color: theme.palette.error.main,
    },
    transferableTitle: {
      ...h3Properties,
    },
    amountCard: {
      height: '100%',
      margin: 0,
      background: 'transparent linear-gradient(20deg, #85B501 50%, #AAE801 120%) 0% 0% no-repeat padding-box',
    },
    rollupEarnings: {
      marginTop: theme.spacing(2),
      padding: theme.spacing(2),
    },
  });
};

export const miningAddressStyle = () => ({
  chainIcon: {
    width: 25,
    height: 25,
  },
});

export const miningHeaderExtraStyle = (theme) => ({
  connectWalletButton: {
    padding: theme.spacing(1.5, 5),
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
});

export const miningOrderHistoryStyles = (theme) => ({
  miningStepperContainer: {
    backgroundColor: '#F4F5F9',
    marginLeft: '40%',
    padding: theme.spacing(0, 0, 3, 0),
  },
  miningStepperStep: {
    backgroundColor: '#F4F5F9',
  },
});

export const miningStatisticsStyles = (theme) => ({
  downloadButton: {
    padding: 0,
    margin: 0,
  },
  downloadButtonText: {
    fontSize: 14,
    fontWeight: 500,
  },
});
