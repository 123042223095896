import * as R from 'ramda';
import { combineReducers } from 'redux';
import { PURGE } from 'redux-persist';

import {
  DEPLOY_NODE_SUCCESS,
} from '../deploy_config/types';
import {
  createReducer,
  updateStateWithKey,
} from '../store_utils';

import * as types from './types';

const depositDataIS = {
  depositKeys: [],
};
const depositDataRUpdaters = {
  [types.EXTRACT_DEPOSIT_KEYS]: (
    state,
    payload,
  ) => updateStateWithKey('depositKeys', state, payload),
  [PURGE]: R.always(depositDataIS),
  [types.RESET_DEPOSIT]: R.always(depositDataIS),
  [types.UPDATE_TRANSACTION_STATUS]: (
    state,
    payload,
  ) => {
    const {
      pubkey,
      status,
      txHash,
    } = payload;
    const { depositKeys } = state;
    const index = R.findIndex(R.propEq('pubkey', pubkey), depositKeys);
    if (index === -1) return state;
    const lens = R.lensPath(['depositKeys', index]);
    const data = {
      ...depositKeys[index],
      transactionStatus: status,
      txHash,
    };
    return R.set(lens, data, state);
  },
  [types.INSERT_NODE_DATA]: (state, payload) => {
    const { depositKeys } = state;
    return R.pipe(
      R.map(R.pick(['nodeName', 'nodeId'])),
      R.addIndex(R.map)((item, index) => {
        const depositKey = depositKeys[index];
        return {
          ...depositKey,
          ...item,
        };
      }),
      (newDepositKeys) => ({
        depositKeys: newDepositKeys,
      }),
    )(payload);
  },
  [DEPLOY_NODE_SUCCESS]: (state, payload) => {
    const { depositKeys } = state;
    const { data } = payload;
    const { nodes } = data;
    if (!nodes) {
      return state;
    }
    return R.pipe(
      R.map(R.pick(['nodeName', 'nodeId'])),
      R.addIndex(R.map)((item, index) => {
        const depositKey = depositKeys[index];
        return {
          ...depositKey,
          ...item,
        };
      }),
      (newDepositKeys) => ({
        depositKeys: newDepositKeys,
      }),
    )(nodes);
  },
};

const depositDataR = createReducer(depositDataIS, depositDataRUpdaters);

const depositR = combineReducers({
  depositData: depositDataR,
});
export default depositR;
