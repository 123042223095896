import * as loadingOperations from './operations';
import reducer from './reducers';
import * as loadingTypes from './types';

export {
  loadingOperations,
  loadingTypes,
};

export default reducer;
