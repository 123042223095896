import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import ErrorOutlineOutlinedIcon from '@material-ui/icons/ErrorOutlineOutlined';
import isString from 'lodash/isString';
import PropTypes from 'prop-types';
import React from 'react';

import { RoundedButton } from '../MaterialUiCustom/MaterialUiCustom';

import { failPageStyles } from './styles';

const useStyles = makeStyles(failPageStyles);

const FailPage = ({
  onCancel,
  message,
  btnText,
  primaryText,
}) => {
  const classes = useStyles();

  const getMessageContent = (message) => {
    if (isString(message)) {
      return (
        <Typography
          variant="body1"
          className={classes.secondaryText}
        >
          {message}
        </Typography>
      );
    }

    return (
      <Box className={classes.secondaryText}>
        {message}
      </Box>
    );
  };

  return (
    <Box
      display="flex"
      alignItems="center"
      flexDirection="column"
      width="100%"
      pb={7}
    >
      <Box>
        <ErrorOutlineOutlinedIcon
          className={classes.icon}
        />
      </Box>
      <Box pt={3}>
        <Typography
          variant="h2"
          className={classes.primaryText}
        >
          {primaryText}
        </Typography>
      </Box>
      <Box pt={1}>
        {getMessageContent(message)}
      </Box>
      <Box pt={3}>
        <RoundedButton
          variant="contained"
          onClick={onCancel}
        >
          {btnText}
        </RoundedButton>
      </Box>
    </Box>
  );
};

FailPage.propTypes = {
  onCancel: PropTypes.func.isRequired,
  primaryText: PropTypes.string.isRequired,
  message: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
  ]).isRequired,
  btnText: PropTypes.string.isRequired,
};

export default FailPage;
