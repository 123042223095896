import * as R from 'ramda';

import { generateAccount, intializeKeyring } from './accounts';

export const generateSenderAccount = R.curry((sender) => {
  const {
    type,
    ss58Format,
    secretPhrase,
    password,
    metadata,
    hardDerivation,
    softDerivation,
    options,
  } = sender;
  const senderKeyring = intializeKeyring(type, ss58Format);
  const senderAccount = generateAccount(
    options,
    senderKeyring,
    secretPhrase,
    password,
    metadata,
    hardDerivation,
    softDerivation,
  );
  return senderAccount;
});
