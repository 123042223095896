import {
  axiosPost,
  axiosPatch,
  axiosGet,
  axiosPut,
  isJsonApi,
  sleep,
  pipeAwait,
  getTimeDiffInSecond,
} from 'jsutils';
import * as R from 'ramda';

import env from '../config/env';

import {
  HttpStatusCodes,
  insecureRoutes,
  keyToAuthTokenDataInLocalStorage,
} from './appConstants';
import { getItem, doesItemWithKeyExistsInLocalStorage } from './browser_utils';
import { generateAndAppendQueryToUrl } from './query_utils';
import { generateReactGaException } from './utils';

const { rootUrl } = env;
// const rootUrl = 'http://localhost:5001';
export const extractAxiosData = (axiosRes) => axiosRes.data;
export const extractDataFromServerData = (axiosData) => axiosData;
export const extractDataFromAxiosCallToServer = R.pipe(extractAxiosData, extractDataFromServerData);
export const encodeURIComponentJSON = (jsonData) => encodeURIComponent(JSON.stringify(jsonData));

/**
 * Set value for withCredentials prop
 * @param  {boolean} withCredentials - value of withCredentials prop
 * @param  {Object}  axiosRequestConfig - current axios request config
 * @return {Object} updated axios request config with withCredentaisl property value set
 */
export const setWithCredentialsProp = R.curry((withCredentials, axiosRequestConfig) => (
  R.mergeLeft(
    { withCredentials },
    axiosRequestConfig,
  )
));

/**
 * Add auth token to request if the server route is secured route
 * @param {boolean}  isRouteSecure - indicates the server route is secured or not
 * @param {string}   authToken - auth token for authrization
 * @param {Object}   config - current request config
 * @return {Object} updated request config
 */
export const addAuthorizationToConfig = R.curry(
  (isRouteSecure, authToken, axiosRequestConfig) => {
    if (isRouteSecure) {
      return R.mergeLeft(axiosRequestConfig, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });
    }
    return axiosRequestConfig;
  },
);

/**
 * Prepare the config for axios request
 * @param  {string} url - route url
 * @param  {Object} config - data to prepare axios request config
 * @return {Object} axios request config
 */
/* const configureAxiosRequest = R.curry((config) => {
  const {
    data,
    authToken,
    isRouteSecure,
    withCredentials,
  } = config;
  return R.pipe(
    addAuthorizationToConfig(isRouteSecure, authToken),
    setWithCredentialsProp(withCredentials),
    trace('Final Request Config'),
  )(data);
}); */

/**
 * Handle axios request
 * @param  {string} method <get, post, put, patch,..> - all axios's method
 * @param  {string} url - api endpoint
 * @param  {Object} config contains data and other axios configs - similar
 * to axios object but doesnot contain url property
 * @return {Object} axios's response object
 * Note that in many cases we only need to pass data,
 * that is why we check doesConfigContainData
 */
/* const handleAxiosRequest = R.curry(
  async (method, url, config) => {
    try {
      const axiosRequestConfig = configureAxiosRequest(config);
      trace(`Axios Request Config for URL ${url}`, axiosRequestConfig);
      const res = await axios(R.mergeLeft(
        { method, url },
        axiosRequestConfig,
      ));
      return Result.Ok(res);
    } catch (error) {
      return Result.Error(error);
    }
  },
); */

// request to server // todo remove
/**
 * @param  {string} url
 * @param  {Object} config
 * @return {Object} standard response from server
 */
export const axiosGetToServer = R.curry(
  async (url) => pipeAwait(
    axiosGet,
    extractDataFromServerData,
  )(url),
);
// todo remove
/**
 * @param  {string} url
 * @param  {[type]} config
 * @return {Object} standard response from server
 */
export const axiosPostToServer = R.curry(
  async (url, config) => pipeAwait(
    axiosPost(url),
    extractDataFromServerData,
  )(config),
);
// todo remove
/**
 * @param  {string} url
 * @param  {Object} config
 * @return {Object} standard response from server
 */
export const axiosPutToServer = R.curry(
  async (url, config) => pipeAwait(
    axiosPut(url),
    extractDataFromServerData,
  )(config),
);
// todo remove
/**
 * @param  {string} url
 * @param  {[type]} config
 * @return {Object} standard response from server
 */
export const axiosPatchToServer = R.curry(
  async (url, config) => pipeAwait(
    axiosPatch(url),
    extractDataFromServerData,
  )(config),
);
// todo remove
/**
 * @param  {string} url
 * @param  {Object} config
 * @return {Object} standard response from server
 */
export const axiosGetToServerWithQuery = R.curry(
  async (baseUrl, query) => pipeAwait(
    generateAndAppendQueryToUrl(baseUrl),
    axiosGetToServer,
  )(query),
);
/**
 * Check if an error is an Axios's error
 * @param  {Object} error
 * @return {boolean}
 */
export const isAxiosError = R.curry(
  (error) => R.prop('isAxiosError')(error),
);
/**
 * Check if an error is an Axios's error in dev testing
 * axios-mock-adapter has not supported isAxiosError yet
 * @param  {Object} error
 * @return {boolean}
 */
export const isAxiosErrorDev = R.curry(
  (error) => R.or(
    R.prop('response'),
    R.prop('request'),
  )(error),
);

export const handleAxiosError = R.curry(
  (error) => {
    if (error.response) {
      /*
       * The request was made and the server responded with a
       * status code that falls out of the range of 2xx
       */
      console.log(`[AxiosError]: ${error.response.data}`);
      const axiosServerError = {
        message: '[AxiosError]: Sorry, got error response from server',
        extensions: {
          statusCode: [error.response.status],
          status: [error.response.statusText],
        },
      };
      generateReactGaException(axiosServerError, false);
      return axiosServerError;
    } if (error.request) {
      /*
       * The request was made but no response was received, `error.request`
       * is an instance of XMLHttpRequest in the browser and an instance
       * of http.ClientRequest in Node.js
       */
      const axiosClientError = {
        message: '[AxiosError]: Sorry, failed to send axios request to server',
        extensions: {
          statusCode: [HttpStatusCodes.AXIOS_CLIENT_ERROR],
          status: ['AxiosClientError'],
        },
      };
      generateReactGaException(axiosClientError, true);
      return axiosClientError;
    }
    // Something happened in setting up the request and triggered an Error
    // or other type of error
    generateReactGaException(error, true);
    return {
      message: `[AxiosError]: ${error.message}`,
      extensions: {
        statusCode: [HttpStatusCodes.AXIOS_CLIENT_ERROR],
        status: ['AxiosClientError'],
      },
    };
  },
);

/**
 * Returns the remaining time after which current JWT token will expire
 * @param  {import('./typedefs').ItemKey} - keyToAuthTokenDataInLocalStorage
 * @return {number} time in seconds after which current JWT token will expire
 */
export const getRemainingJwtExpiryTimeInSecond = R.curry((keyToAuthTokenDataInLocalStorage) => {
  const accessTokenExpiry = R.pipe(
    getItem,
    R.view(R.lensProp('accessTokenExpiry')),
  )(keyToAuthTokenDataInLocalStorage);
  const accessTokenExpiryToUse = accessTokenExpiry || null;
  const remainingJwtExpiryTimeInSecond = getTimeDiffInSecond(null, accessTokenExpiryToUse);
  return remainingJwtExpiryTimeInSecond;
});

/**
 * Decide whether authentication tokens need to be updated or not
 * @param  {import('./jwt/typedefs').ItemKey}  keyToAuthTokenDataInLocalStorage
 * @return {boolean} true if token should update and false otherwise
 */
export const shouldUpdateToken = R.curry((keyToAuthTokenDataInLocalStorage) => {
  const remainingJwtExpiryTimeInSecond = getRemainingJwtExpiryTimeInSecond(
    keyToAuthTokenDataInLocalStorage,
  );
  // trace('Time Remaining in shouldUpdateToken', remainingJwtExpiryTimeInSecond);
  if (remainingJwtExpiryTimeInSecond < 120) {
    return true;
  }
  return false;
});

/**
 * Wait until the auth token updated
 * @param  {import('./jwt/typedefs').ItemKey} - keyToAuthTokenDataInLocalStorage
 * @return {[type]}
 */
const waitForAuthTokenToUpdate = R.curry(async (keyToAuthTokenDataInLocalStorage) => {
  while (
    shouldUpdateToken(keyToAuthTokenDataInLocalStorage)
    && doesItemWithKeyExistsInLocalStorage(keyToAuthTokenDataInLocalStorage)
  ) {
    // console.log('Started waiting to update token in AXIOS!');
    await sleep(1000); // TODO fix this
  }
});

/**
 * Fetch auth token from browser local storage
 * @param  {import('./jwt/typedefs').ItemKey} - keyToAuthTokenDataInLocalStorage
 * @return {string} auth token
 */
const fetchAuthTokenFromLocalStorage = R.curry(async (keyToAuthTokenDataInLocalStorage) => {
  const authTokenData = getItem(keyToAuthTokenDataInLocalStorage);

  if (authTokenData) {
    // wait until auth token is updated
    waitForAuthTokenToUpdate(keyToAuthTokenDataInLocalStorage);
    // get updated auth token
    const accessTokenHeaderAndPayload = R.pipe(
      getItem,
      R.view(R.lensProp('accessTokenHeaderAndPayload')),
    )(keyToAuthTokenDataInLocalStorage);
    return accessTokenHeaderAndPayload;
  }
  return null;
});

/**
 * Check if the given route is secured in server
 * @param  {string} url -  route URL
 * @param  {array}  inseacureRoutes - list of insecure routes in server
 * @return {boolean} if route is secure returns true and false otherwise
 */
export const createCheckIfRouteIsSecured = R.curry((insecureRoutes, url) => {
  const subUrl = url.slice(rootUrl.length);
  if (!insecureRoutes) {
    return true;
  }
  let isSecured = true;
  insecureRoutes.forEach((endpoint) => {
    if (subUrl.search(endpoint) !== -1) {
      isSecured = false;
    }
  });
  return isSecured;
});
export const checkIfRouteIsSecured = createCheckIfRouteIsSecured(insecureRoutes);
/**
 * Determine the proper value withCredentials property
 * @param  {Objcect} config - request data and if necessary contains the 'withCredentails' property
 * @return {boolean}          value of withCredentials property, i.e.true or false
 */
const determineWithCredentialsPropValue = R.curry((config) => {
  if (R.has('withCredentials', config)) {
    return config.withCredentials;
  }
  return true;
});

const handleAxiosConfig = R.curry(
  async (url, config, accessTokenHeaderAndPayload) => {
    const hasData = R.has('data', config);
    const withCredentials = determineWithCredentialsPropValue(config);
    const isRouteSecure = checkIfRouteIsSecured(url);
    if (hasData) {
      const isConfigJsonApiData = isJsonApi(config);
      if (isConfigJsonApiData) {
        return {
          data: config,
          authToken: accessTokenHeaderAndPayload,
          isRouteSecure,
          withCredentials,
        };
      }
      return {
        ...config,
        authToken: accessTokenHeaderAndPayload,
        isRouteSecure,
        withCredentials,
      };
    }
    // no data
    return {
      data: config,
      authToken: accessTokenHeaderAndPayload,
      isRouteSecure,
      withCredentials,
    };
  },
);
/**
 * Assign values to request properties of request config
 * @param {import('../store/jwt/typedefs').ItemKey} - keyToAuthTokenDataInLocalStorage
 * @param {string} url - route URL
 * @return {[type]}       [description]
 */
const assignValuesToRequestConfigProps = R.curry(
  async (keyToAuthTokenDataInLocalStorage, url, config) => pipeAwait(
    fetchAuthTokenFromLocalStorage,
    handleAxiosConfig(url, config),
  )(keyToAuthTokenDataInLocalStorage),
);

/**
 * Prepare data about axios request config
 * @param  {Object} config - object with data to be sent with optional withCredentials prop
 * @param  {import('../store/jwt/typedefs').ItemKey} - keyToAuthTokenDataInLocalStorage
 * @return {Object}  config data for axios request
 */
const createPrepareAxiosRequestConfig = R.curry(
  async (keyToAuthTokenDataInLocalStorage, url, config) => assignValuesToRequestConfigProps(
    keyToAuthTokenDataInLocalStorage, url, config,
  ),
);

export const prepareAxiosRequestConfig = createPrepareAxiosRequestConfig(
  keyToAuthTokenDataInLocalStorage,
);

export const createAxiosRequest = R.curry(
  async (axiosRequest, url, config) => pipeAwait(
    prepareAxiosRequestConfig(url),
    axiosRequest(url),
  )(config),
);

export const createAxiosGetRequest = R.curry(
  (config, url) => pipeAwait(
    prepareAxiosRequestConfig(url),
    axiosGet(url),
  )(config),
);

export const axiosPostRequest = createAxiosRequest(axiosPost);
/**
 * Get request to a JWT secure route
 * @param  {string} url
 */
export const axiosGetSecure = createAxiosGetRequest(
  { withCredentials: true },
);

/**
 * Get request to a unsecure route
 * @param  {string} url
 */
export const axiosGetUnsecure = createAxiosGetRequest(
  { withCredentials: false },
);
