import { makeStyles, Typography } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import InputAdornment from '@material-ui/core/InputAdornment';
import Link from '@material-ui/core/Link';
import toUpper from 'lodash/toUpper';
import { TextField } from 'mui-rff';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import { walletTransferBoardAmountStyles } from './styles';

import { RoundedButton } from 'components/MaterialUiCustom/MaterialUiCustom';
import { convertCryptoToFiat } from 'components/utils/utils';

import { isNumber } from 'ramda-adjunct';

const useStyles = makeStyles(walletTransferBoardAmountStyles);
// const subChain = 'pha';
const WalletTransferBoardAmount = ({
  t,
  setMaxAvailable,
  freeBalance,
  transactionFee,
  calMaxAvailable,
  amount,
  priceData,
  handleCloseMaxDialog,
  openMaxDialog,
  existentialDeposit,
  subChain,
  chain,
  recipient,
}) => {
  const classes = useStyles();
  const [fiatAmount, setFiatAmount] = useState(0);
  useEffect(() => {
    if (isNumber(amount)) {
      const fiatAmount = convertCryptoToFiat(undefined, priceData, amount);
      setFiatAmount(fiatAmount);
    }
  }, [amount, priceData]);

  const { currency } = priceData;
  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            id="recipient-input-textfield"
            // i18next-extract-mark-context-next-line ["wallet"]
            label={t('Amount', { context: 'wallet' })}
            name="amount"
            type="number"
            variant="outlined"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <RoundedButton
                    aria-label={t('max')}
                    variant="text"
                    disableFocusRipple
                    onClick={() => calMaxAvailable(setMaxAvailable, freeBalance, transactionFee)}
                    disabled={!recipient}
                  >
                    {t('max')}
                  </RoundedButton>
                  <Typography>{toUpper(subChain) || toUpper(chain)}</Typography>
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item xs={12} className={classes.fiatAmount}>
          <Typography>
            {`~${fiatAmount.toLocaleString('en-US', {
              style: 'currency',
              currency: currency || 'jpy',
            })}`}
          </Typography>
          <Typography>
            {toUpper(currency || 'jpy')}
          </Typography>
        </Grid>
      </Grid>
      <Dialog
        open={openMaxDialog}
        onClose={handleCloseMaxDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{t('Existential deposit') }</DialogTitle>
        <DialogContent>
          <Box>
            <Typography>
              {t('An address is alive only if it holds at least {{existentialDeposit}} {{chain}} (exitential deposit). Therefore, in order to keep your address alive, the maximum withrawal amount will exclude the existential deposit.', {
                existentialDeposit,
                chain: toUpper(subChain) || toUpper(chain),
              })}
              {/* {t(`An address is alive only if it holds at least ${(existentialDeposit)} ${toUpper(subChain) || toUpper(chain)} (exitential deposit). Therefore, in order to keep your address alive, the maximum withrawal amount will exclude the existential deposit.`)} */}
            </Typography>
          </Box>
          <Box mt={1}>
            <Link href="https://support.polkadot.network/support/solutions/articles/65000168651-what-is-the-existential-deposit-" target="_blank">
              {t('What is the existential deposit?')}
            </Link>
          </Box>
        </DialogContent>
        <DialogActions>
          <RoundedButton onClick={handleCloseMaxDialog} color="primary" autoFocus>
            {t('ok')}
          </RoundedButton>
        </DialogActions>
      </Dialog>
    </>
  );
};

WalletTransferBoardAmount.propTypes = {
  t: PropTypes.func.isRequired,
  setMaxAvailable: PropTypes.func.isRequired,
  freeBalance: PropTypes.number.isRequired,
  transactionFee: PropTypes.number,
  amount: PropTypes.number.isRequired,
  chain: PropTypes.string.isRequired,
  subChain: PropTypes.string.isRequired,
  calMaxAvailable: PropTypes.func.isRequired,
  handleCloseMaxDialog: PropTypes.func.isRequired,
  openMaxDialog: PropTypes.bool.isRequired,
  existentialDeposit: PropTypes.number.isRequired,
};

WalletTransferBoardAmount.defaultProps = {
  transactionFee: 0,
};

export default WalletTransferBoardAmount;
