import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Field } from 'redux-form';

// componets
import { RenderField } from '../ReduxFormCustom/ReduxFormCustom';

import styles from './styles';

const useStyles = makeStyles(styles);

const PasswordField = ({
  handleClickShowPassword,
  showPassword,
  id,
  name,
  label,
  customClassName,
  validate,
  showLabel,
  showTitle,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  return (
    <>
      {showTitle && (
      <Typography
        variant="body2"
        color="textSecondary"
        className={classNames(classes.title)}
      >
        {label || t('Password')}
      </Typography>
      )}
      <Field
        name={name || 'password'}
        id={id}
        type={showPassword ? 'text' : 'password'}
        component={RenderField}
        label={showLabel ? label || t('Password') : ''}
        variant="outlined"
        className={customClassName || classes.textField}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label={t('Toggle password visibility')}
                onClick={handleClickShowPassword}
              >
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          ),
        }}
        validate={validate || null}
      />
    </>
  );
};

PasswordField.defaultProps = {
  showPassword: false,
  id: 'password-field',
  name: '',
  label: '',
  customClassName: '',
  validate: () => {},
  showLabel: true,
  showTitle: false,
};

PasswordField.propTypes = {
  handleClickShowPassword: PropTypes.func.isRequired,
  showPassword: PropTypes.bool,
  id: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.string,
  customClassName: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
  validate: PropTypes.func,
  showLabel: PropTypes.bool,
  showTitle: PropTypes.bool,
};

export default PasswordField;
