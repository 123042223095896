// mutation
import {
  createQuery,
  createMutation,
} from '../../../modules/apollo/apollo';

import {
  createOrderMutation,
} from './mutation';
import {
  getOrdersQuery,
} from './queries';

export const graphqlGetOrders = createQuery(getOrdersQuery);

export const graphqlCreateOrder = createMutation(createOrderMutation);
