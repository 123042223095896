/* eslint-disable jsx-a11y/anchor-is-valid */
import { Divider } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Checkbox from '@material-ui/core/Checkbox';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import whyDidYouRender from '@welldone-software/why-did-you-render';
import { isSuccessAction } from 'jsutils';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
// toastify

import { useSelector, useDispatch } from 'react-redux';
import {
  useHistory,
} from 'react-router-dom';

import { deployConfigOperations } from '../../../../store/deploy_config/index';
import { CloseIconButton, RoundedButton } from '../../../MaterialUiCustom/MaterialUiCustom';
import NodeOwnerFormCart from '../NodeOwnerFormCart';
import NodeOwnerFormComplete from '../NodeOwnerFormComplete';
import NodeOwnerFormContentItem from '../NodeOwnerFormContentItem';
import NodeOwnerFormPayment from '../NodeOwnerFormPayment';
import NodeOwnerFormQuantity from '../NodeOwnerFormQuantity';

import {
  neunodeOrderFormContentStyles,
} from './styles';

import { nodeOwnerRoute } from 'routes';

// store

const { REACT_APP_ENV } = process.env;

if (REACT_APP_ENV !== 'prod') {
  whyDidYouRender(React);
}

const useStyles = makeStyles(neunodeOrderFormContentStyles);

const NeunodeOrderFormContent = (props) => {
  const dispatch = useDispatch();
  const {
    activeStep,
    handleStepperNext,
    steps,
  } = props;
  const { t } = useTranslation();
  const history = useHistory();
  const { items, paymentMethod, quantity } = useSelector((state) => state.nodeOwnerR.nodeOwnerOrderConfig);
  const userId = useSelector((state) => state.userR.user.userId);
  const { promotionCode } = useSelector((state) => state.paymentR.paymentDetail);
  const [deploying, setDeploying] = useState(false);

  const subtotal = R.pipe(
    R.map(R.prop('subtotal')),
    R.sum,
  )(items);
  const tax = R.pipe(
    R.map(R.prop('ct')),
    R.sum,
  )(items);
  const total = subtotal + tax;

  const [agreementChecked, setAgreementChecked] = useState(false);

  const classes = useStyles();

  const createGetStepTitle = R.curry((steps, activeStep) => steps[activeStep].title);
  const getStepTitle = createGetStepTitle(steps);

  const onRedirectToNodeOwner = () => {
    history.push(nodeOwnerRoute);
  };

  const onDeployNode = R.curry(
    async (
      userId,
      quantity,
      paymentDetail,
    ) => {
      setDeploying(true);
      const date = new Date().toUTCString();

      const { type } = await dispatch(deployConfigOperations.deploy(
        userId,
        quantity,
        paymentDetail,
        {
          contract: {
            contractStartDate: null,
            contractEndDate: null,
            contractSignDate: null,
            contractPeriodValue: 1,
            contractPeriod: 'year',
            dateCreated: date,
            dateUpdated: date,
            contractType: 'NODE_PROVIDER_110',
            quantity,
          },
        },
        'NODE_PROVIDER_NEUNODE',
      ));

      if (isSuccessAction(type)) {
        handleStepperNext();
      } else {
        setDeploying(false);
      }
    },
  );

  const getStepContent = (step) => {
    switch (step) {
      case 0: {
        return (
          <NodeOwnerFormContentItem
            title={getStepTitle(activeStep)}
            headerWrap
          />
        );
      }
      case 1:
        return (
          <NodeOwnerFormContentItem
            title={getStepTitle(activeStep)}
            headerWrap
            content={(
              <Box className={classes.orderAndAgreement}>
                <Box className={classes.orderAndAgreementContent}>
                  <NodeOwnerFormQuantity
                    min={5}
                    max={999}
                    price={90000}
                    productName="Neunode"
                    productImage="/img/devices/neunode_device.png"
                    description={t('1 year Fixed-term Contract, rental computing power')}
                  />
                </Box>
              </Box>
            )}
          />
        );
      case 2:
        return (
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <NodeOwnerFormContentItem
                title={getStepTitle(activeStep)}
                headerWrap
                content={(
                  <NodeOwnerFormPayment />
            )}
              />
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item xs={12}>
              <NodeOwnerFormContentItem
                title="Your Cart"
                headerWrap
                content={(
                  <NodeOwnerFormCart
                    min={5}
                    max={999}
                    price={90000}
                    disabled={deploying}
                    productName="Neunode"
                  />
            )}
              />
            </Grid>
          </Grid>
        );
      case 3:
        return (
          <NodeOwnerFormContentItem
            title={getStepTitle(activeStep)}
            headerAction={(
              <CloseIconButton
                color="default"
                onClick={onRedirectToNodeOwner}
              />
            )}
            content={(
              <Box className={classes.orderComplete}>
                <Box className={classes.orderCompleteContent}>
                  <NodeOwnerFormComplete productName="Neunode" />
                </Box>
              </Box>
            )}
          />
        );
      default:
        break;
    }
  };

  const getFooterContent = (step) => {
    switch (step) {
      case 0:
        return (
          <RoundedButton
            variant="contained"
            color="primary"
            onClick={handleStepperNext}
            className={classes.footerButton}
          >
            {t('Next step')}
          </RoundedButton>
        );
      case 1:
        return (
          <Box
            display="flex"
            alignItems="center"
            alignContent="center"
            flexDirection="column"
          >
            <Box display="flex" mb={2}>
              <Box>
                <Checkbox
                  checked={agreementChecked}
                  onChange={(event) => setAgreementChecked(event.target.checked)}
                />
              </Box>
              <Box
                display="flex"
                justifyContent="center"
                alignItems="flex-start"
                mt={1.1}
              >
                <Typography
                  variant="body1"
                >
                  {t('I have read and agree to')}
                  {' '}
                  <Link href="https://www.neukind.com/terms" target="_blank">
                    {t('NeuNode Purchase Agreement')}
                  </Link>
                </Typography>
              </Box>
            </Box>
            <Box>
              <RoundedButton
                variant="contained"
                color="primary"
                onClick={handleStepperNext}
                disabled={!agreementChecked}
                className={classes.footerButton}
              >
                {t('Next step')}
              </RoundedButton>
            </Box>
          </Box>
        );
      case 2:
        return (
          <Box
            display="flex"
            justifyContent="flex-end"
            alignItems="center"
            style={{ width: '100%' }}
          >
            <Box>
              <Typography
                variant="body1"
                style={{ fontSize: 25, fontWeight: 550 }}
              >
                {`${t('Total')} ${total.toLocaleString('en-US', {
                  style: 'currency',
                  currency: 'JPY',
                })}`}
              </Typography>
            </Box>
            <Box>
              <RoundedButton
                variant="contained"
                color="primary"
                onClick={() => onDeployNode(
                  userId,
                  quantity,
                  {
                    paymentMethod,
                    amount: total,
                    currencySymbol: 'JPY',
                    trialDays: 0,
                    promotionCode: promotionCode === '' ? null : promotionCode,
                    stripe: {
                      stripePriceId: process.env.REACT_APP_NEUNODE_PRICE,
                    },
                    paymentType: 'ONETIME',
                    dateCreated: new Date(),
                    dateUpdated: new Date(),
                  },
                )}
                disabled={deploying}
                loading={deploying}
                className={classes.footerButton}
              >
                {t('Submit Order')}
              </RoundedButton>
            </Box>
          </Box>
        );
      case 3:
        return null;
      default:
        return null;
    }
  };

  return (
    <Paper className={classes.root}>
      <Box className={classes.content}>
        <Grid container justifyContent="center" spacing={2}>
          <Grid item xs={12}>
            <Box className={classes.continueButton}>
              {getStepContent(activeStep)}
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box className={classes.continueButton}>
              {getFooterContent(activeStep)}
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Paper>
  );
};

NeunodeOrderFormContent.propTypes = {
  activeStep: PropTypes.number.isRequired,
  handleStepperNext: PropTypes.func.isRequired,
  steps: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
    }),
  ).isRequired,
};

NeunodeOrderFormContent.whyDidYouRender = true;

export default React.memo(NeunodeOrderFormContent);
