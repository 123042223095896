import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import withWidth from '@material-ui/core/withWidth';
import { gweiToEther } from 'jsutils';
import isNull from 'lodash/isNull';
import toNumber from 'lodash/toNumber';
import toUpper from 'lodash/toUpper';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import {
  useHistory,
} from 'react-router-dom';

import { useAddFunctionToRef } from '../../hooks/hooks';
import { contactFormOperations } from '../../store/contact_form/index';
import { cryptoAddressSelectors, cryptoAddressOperations } from '../../store/crypto_address/index';
import { isAPIReadFailedError } from '../../store/error/services';
import { nodeOperations } from '../../store/node/index';
import { orderOperations } from '../../store/order/index';
import { paymentOperations } from '../../store/payment/index';
import { poolOperations } from '../../store/pool/index';
import { web3Operations, web3Selectors } from '../../store/web3';
import AmountCard from '../AmountCard/AmountCard';
import Alert from '../MaterialUiCustom/Alert/Alert';
import CustomTabs from '../Tabs/CustomTabs';
import {
  percentage, toFiat, convertCryptoToFiat, convertFiatToCrypto,
} from '../utils/utils';

import OverviewBUSDItem from './Items/OverviewBUSDItem';
import OverviewEth2Item from './Items/OverviewEth2Item';
import OverviewNeunodeETH2Item from './Items/OverviewNeunodeETH2Item';
import OverviewNeunodeItem from './Items/OverviewNeunodeItem';
import OverviewPhala4core from './Items/OverviewPhala4core';
import OverviewPhalaItem from './Items/OverviewPhalaItem';
import OverviewUnUniFiItem from './Items/OverviewUnUniFiItem';
import OverviewZenItem from './Items/OverviewZenItem';
import OverviewEmptyItem from './OverviewEmptyItem';
import OverviewItemHeader from './OverviewItemHeader';
import OverviewPieChart from './OverviewPieChart';
import { naasNodeTypes } from './constants';
import { overviewStyles } from './styles';

import { RoundedButton } from 'components/MaterialUiCustom/MaterialUiCustom';
import { marketRoute, nodeOwnerRoute } from 'routes';
import { selectOwnerRollupRewards } from 'store/pool/selectors';

const useStyles = makeStyles(overviewStyles);

const { REACT_APP_PHALA_WS_ENDPOINT } = process.env;

// hardcode chain and subChain. TODO dry
const chain = 'dot';
const subChain = 'pha';

const Overview = (props) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const dispatch = useDispatch();
  const classes = useStyles();
  const history = useHistory();
  const { width } = props;

  const { nodeColors } = theme;

  const [phalaAPI, setPhalaAPI] = useState(null);

  const user = useSelector((state) => state.userR.user);
  const { userId } = user;

  const { getTotalEarningError, getUserRollupRewardsError } = useSelector((state) => state.errorR);
  const getEarningErrorExists = isAPIReadFailedError(getTotalEarningError || getUserRollupRewardsError || {});
  const { userNodes, zenEarning } = useSelector((state) => state.nodeR);
  const { nodes: rawNodes } = userNodes;
  const nodesETH2 = R.filter(R.propEq('nodeType', 'ETH2_VALIDATOR_NODE'), rawNodes);
  const nodesUnUniFi = R.filter(R.propEq('nodeType', 'UNUNIFI_VALIDATOR_NODE'), rawNodes);
  const addresses = useSelector((state) => state.cryptoAddressR.addresses);
  const accounts = cryptoAddressSelectors.selectCryptoAddressAccounts(chain, subChain, addresses);
  const phalaAddress = R.pipe(
    R.head,
    (account) => (account ? R.prop('address', account) : ''),
  )(accounts);
  const {
    getUserNodesLoading,
    getOrdersLoading,
    getTotalEarningLoading,
    getCoinPriceLoading,
    getZenBalanceLoading,
    getUserRollupRewardsLoading,
    initializeApiLoading,
    getAccountInfoLoading,
  } = useSelector((state) => state.loadingR);

  const phalaEarningLoading = getCoinPriceLoading
  || getUserRollupRewardsLoading
  || initializeApiLoading
  || getAccountInfoLoading;

  const onGetPaymentHistory = useAddFunctionToRef(async () => {
    await dispatch(paymentOperations.getSubscriptions(userId));
  });
  const onGetUserNodes = useAddFunctionToRef(async () => {
    await dispatch(nodeOperations.getUserNodes(userId));
  });
  const onGetNodesEarning = useAddFunctionToRef(async (nodes) => R.map(async (naasNodeType) => {
    const { nodeType, keyName } = naasNodeType;
    const publicKeyList = R.pipe(
      R.filter(R.propEq('nodeType', nodeType)),
      R.map(
        R.view(R.lensPath(['nodeInfo', keyName])),
      ),
      R.reject(isNull),
    )(nodes);

    if (R.length(publicKeyList) > 0) {
      await dispatch(nodeOperations.getTotalEarning(userId, publicKeyList, nodeType));
    }
  })(naasNodeTypes));

  const onGetCoinPrice = useAddFunctionToRef(
    async () => {
      await dispatch(nodeOperations.getCoinPrice('ETH', 'JPY'));
      await dispatch(nodeOperations.getCoinPrice('BTC', 'JPY'));
      await dispatch(nodeOperations.getCoinPrice('ZEN', 'JPY'));
      await dispatch(nodeOperations.getCoinPrice('PHA', 'JPY'));
    },
  );
  const onGetNeunodeOrder = useAddFunctionToRef(async () => {
    await dispatch(orderOperations.getOrders(userId, 'NODE_PROVIDER_NEUNODE'));
  });
  const onGetNeunodeETH2Order = useAddFunctionToRef(async () => {
    await dispatch(orderOperations.getOrders(userId, 'NODE_PROVIDER_NEUNODE_ETH2'));
  });
  const onGetMiningOrder = useAddFunctionToRef(async () => {
    await dispatch(orderOperations.getOrders(userId, 'NODE_PROVIDER_MINING'));
  });
  const onGetBUSDOrder = useAddFunctionToRef(async () => {
    await dispatch(orderOperations.getOrders(userId, 'LENDING'));
  });
  const onGetZenBalance = useAddFunctionToRef(async () => {
    await dispatch(nodeOperations.getZenBalance(userId));
  });
  const getIntervalFixedEarning = useAddFunctionToRef(async (userId) => {
    await dispatch(nodeOperations.getIntervalFixedEarning({
      userId,
      earningType: 'NEUNODE',
      interval: '1mo',
      currency: 'jpy',
    }));

    await dispatch(nodeOperations.getIntervalFixedEarning({
      userId,
      earningType: 'NEUNODE_ETH2',
      interval: '1mo',
      currency: 'jpy',
    }));

    await dispatch(nodeOperations.getIntervalFixedEarning({
      userId,
      earningType: 'LENDING',
      interval: '1mo',
      currency: 'jpy',
    }));
  });
  const onGetUserRollupReward = useAddFunctionToRef(
    async (userId, getUserRollupRewardsType, machineType) => {
      dispatch(
        await poolOperations.getUserRollupRewards(
          userId,
          getUserRollupRewardsType,
          machineType,
        ),
      );
    },
  );
  const onInitializeApi = useAddFunctionToRef(async (chain, endpoint) => {
    const { payload } = await dispatch(web3Operations.initializeApi(chain, endpoint));
    return payload;
  });

  const onGetAccountInfo = useAddFunctionToRef(
    async (chain, subChain, { api, address }) => {
      dispatch(await web3Operations.getAccountInfo(
        chain,
        subChain,
        {
          api,
          address,
        },
      ));
    },
  );
  const onGetCryptoAddresses = useAddFunctionToRef(
    async () => {
      dispatch(
        await cryptoAddressOperations.getCryptoAddresses(
          userId,
          null,
          toUpper(chain),
          toUpper(subChain),
        ),
      );
    },
  );
  useEffect(() => {
    onGetCryptoAddresses();
  }, [onGetCryptoAddresses]);
  useEffect(() => {
    onGetUserNodes();
  }, [onGetUserNodes]);
  useEffect(() => {
    onGetNodesEarning(rawNodes);
  }, [onGetNodesEarning, rawNodes]);
  useEffect(() => {
    onGetCoinPrice();
  }, [onGetCoinPrice]);
  useEffect(() => {
    onGetNeunodeOrder();
  }, [onGetNeunodeOrder]);
  useEffect(() => {
    onGetNeunodeETH2Order();
  }, [onGetNeunodeETH2Order]);
  useEffect(() => {
    onGetMiningOrder();
  }, [onGetMiningOrder]);
  useEffect(() => {
    onGetBUSDOrder();
  }, [onGetBUSDOrder]);
  useEffect(() => {
    onGetZenBalance();
  }, [onGetZenBalance]);
  useEffect(() => {
    onGetPaymentHistory();
  }, [onGetPaymentHistory]);
  useEffect(() => {
    getIntervalFixedEarning(userId);
  }, [getIntervalFixedEarning, userId]);

  useEffect(() => {
    const handleAsync = async () => {
      await onGetUserRollupReward(userId, 'DECENTRALIZED', 'SIX_CORE');
      // only fetch for 4 core user
      await onGetUserRollupReward(userId, 'CENTRALIZED', 'FOUR_CORE');
    };
    handleAsync();
  }, [onGetUserRollupReward, userId]);

  useEffect(() => {
    const handleAsync = async () => {
      const api = await onInitializeApi('dot', REACT_APP_PHALA_WS_ENDPOINT);
      setPhalaAPI(api);
    };
    handleAsync();
  }, [onInitializeApi]);

  useEffect(() => {
    if (phalaAddress && phalaAPI) {
      onGetAccountInfo(chain, subChain, { api: phalaAPI, address: phalaAddress });
    }
  }, [phalaAPI, onGetAccountInfo, phalaAddress]);

  // ETH2 validator nodes item
  const coinPrice = useSelector((state) => state.nodeR.coinPrice);
  const nodesEarning = useSelector((state) => state.nodeR.nodesEarning.eth2);
  const { totalEarning: totalEarningETH2NodesInETH, totalEarningData } = nodesEarning;
  const totalETH2BalanceInETH = R.pipe(
    R.map(R.prop('balance')),
    R.sum,
  )(totalEarningData);
  const totalEarningETH2Nodes = convertCryptoToFiat('ETH', coinPrice, gweiToEther(totalEarningETH2NodesInETH));
  const totalBalanceETH2Nodes = convertCryptoToFiat('ETH', coinPrice, gweiToEther(totalETH2BalanceInETH));

  // UnUniFi validator nodes item
  const ununifiNodesEarning = useSelector((state) => state.nodeR.nodesEarning.ununifi);
  const { totalEarning: totalEarningUnUniFiNodesInUnUniFi, totalEarningData: totalUnUniFiEarningData } = ununifiNodesEarning;
  const totalUnUniFiBalanceInUnUniFi = R.pipe(
    R.map(R.prop('balance')),
    R.sum,
  )(totalUnUniFiEarningData);

  // Neunode item
  const totalEarningNeunodeData = useSelector(
    (state) => state.nodeR.nodeOwnerEarning.total.neunode,
  );
  const { items: neunode } = useSelector(
    (state) => state.nodeOwnerR.nodeOwnerOrder.neunode,
  );
  const neunodeCount = R.pipe(
    R.map(
      R.prop('quantity'),
    ),
    R.sum,
  )(neunode);
  const { total: totalEarningNeunode } = totalEarningNeunodeData || { total: 0 };

  // Neunode ETH2 item
  const totalEarningNeunodeETH2Data = useSelector(
    (state) => state.nodeR.nodeOwnerEarning.total.neunodeETH2,
  );
  const { items: neunodeETH2 } = useSelector(
    (state) => state.nodeOwnerR.nodeOwnerOrder.neunodeETH2,
  );
  const neunodeETH2Count = R.pipe(
    R.map(
      R.prop('quantity'),
    ),
    R.sum,
  )(neunodeETH2);
  const { total: totalEarningNeunodeETH2 } = totalEarningNeunodeETH2Data || { total: 0 };

  // BUSD item
  const totalEarningBUSDData = useSelector((state) => state.nodeR.nodeOwnerEarning.total.busd);
  const { items: busd } = useSelector((state) => state.nodeOwnerR.nodeOwnerOrder.busd);
  const {
    total: totalEarningBUSD,
    totalInCoin: totalEarningBUSDInCoin,
    totalBalance: totalBalanceBUSD,
    // totalBalanceInCoin: totalBalanceBUSDInCoin,
  } = totalEarningBUSDData;

  const { mining6CoreOrders, mining4CoreOrders } = useSelector((state) => state.nodeOwnerR.nodeOwnerOrder.mining);
  const disableFourCore = process.env.REACT_APP_DISABLE_FOUR_CORE === 'ON';

  const totalRollupEarningSixCore = useSelector(selectOwnerRollupRewards('SIX_CORE'));
  const totalRollupEarningFourCore = useSelector(selectOwnerRollupRewards('FOUR_CORE'));

  const totalRollupEarning = totalRollupEarningSixCore + (disableFourCore ? 0 : totalRollupEarningFourCore);

  const { free: accountFreeBalance } = useSelector(
    web3Selectors.selectAccountBalance(chain, subChain, phalaAddress),
  );
  const countMining = R.pipe(
    R.map(
      (eachMiningData) => {
        const alternativeQuantity = R.view(R.lensPath(['metadata', 'quantity']), eachMiningData);
        const quantity = alternativeQuantity || R.prop('quantity', eachMiningData);
        return quantity;
      },
    ),
    R.sum,
  );

  const miningCount6Core = countMining(mining6CoreOrders);

  const miningCount4Core = disableFourCore ? 0 : countMining(mining4CoreOrders);

  const transferable = accountFreeBalance || 0;
  const totalEarningMining = phalaEarningLoading ? -1 : convertCryptoToFiat('PHA', coinPrice, transferable + totalRollupEarning);
  const totalEarningMiningSixCore = phalaEarningLoading ? -1 : convertCryptoToFiat('PHA', coinPrice, transferable + totalRollupEarningSixCore);
  const totalEarningMiningFourCore = phalaEarningLoading ? -1 : convertCryptoToFiat('PHA', coinPrice, totalRollupEarningFourCore);

  // Zen item
  const { earning: zenEarningInZen, balance: zenBalanceInZen } = zenEarning;
  const totalZenEarning = convertCryptoToFiat('ZEN', coinPrice, zenEarningInZen);
  const totalZenBalance = convertCryptoToFiat('ZEN', coinPrice, zenBalanceInZen);
  const { quantity: zenNodesCount } = zenEarning;

  // My Node content
  const showItemTitles = width === 'xs';
  const onRedirectToProtocol = () => {
    history.push(marketRoute);
  };
  const onRedirectToNodeOwner = () => {
    history.push(nodeOwnerRoute);
  };
  const openContactForm = (metadata) => {
    dispatch(contactFormOperations.openContactForm(metadata));
  };
  const emptyText = {
    naas: (
      <Box display="flex" justifyContent="center" alignItems="center" flexDirection="column">
        <Box>
          {t('You don\'t have any subscription')}
        </Box>
        <Box pt={1}>
          <RoundedButton color="secondary" variant="outlined" onClick={onRedirectToProtocol}>
            {t('Deploy a Node')}
          </RoundedButton>
        </Box>
      </Box>
    ),
    'node-owner': (
      <Box display="flex" justifyContent="center" alignItems="center" flexDirection="column">
        <Box>
          {t('You don\'t have any device')}
        </Box>
        <Box pt={1}>
          <RoundedButton color="secondary" variant="outlined" onClick={onRedirectToNodeOwner}>
            {t('buy a device')}
          </RoundedButton>
        </Box>
      </Box>
    ),
    lending: (
      <Box display="flex" justifyContent="center" alignItems="center" flexDirection="column">
        <Box>
          {t('You don\'t have any lending')}
        </Box>
        <Box pt={1}>
          <RoundedButton color="secondary" variant="outlined" onClick={() => openContactForm({ subject: 'Lending' })}>
            {t('Contact Us')}
          </RoundedButton>
        </Box>
      </Box>
    ),
  };
  const getMyNodeItemContent = (title, type, isEmpty, content) => (
    <>
      <Grid item xs={12}>
        <Typography
          variant="body1"
          color="textSecondary"
          className={classes.myNodeItemTitle}
        >
          {title}
        </Typography>
      </Grid>
      <Grid item xs={12} className={classes.myNodeItemContent}>
        <Paper>
          <Grid item xs={12}>
            <OverviewItemHeader type={type} />
          </Grid>
          {isEmpty ? (
            <Grid item xs={12}>
              <OverviewEmptyItem
                text={emptyText[type]}
              />
            </Grid>
          ) : content}
        </Paper>
      </Grid>
    </>
  );

  const naasItems = [
    {
      key: 'eth2OverviewItem',
      content: nodesETH2.length || getUserNodesLoading ? (
        <OverviewEth2Item
          nodes={nodesETH2}
          totalEarning={totalEarningETH2Nodes}
          totalBalance={totalBalanceETH2Nodes}
          showTitles={showItemTitles}
          userId={userId}
        />
      ) : null,
    },
    {
      key: 'ununifiOverviewItem',
      content: nodesUnUniFi.length || getUserNodesLoading ? (
        <OverviewUnUniFiItem
          nodes={nodesUnUniFi}
          showTitles={showItemTitles}
          userId={userId}
          totalEarningInCoin={totalEarningUnUniFiNodesInUnUniFi}
          totalBalanceInCoin={totalUnUniFiBalanceInUnUniFi}
        />
      ) : null,
    },
  ];

  const nodeOwnerItems = [
    {
      key: 'phalaOverviewItem',
      content: miningCount6Core || getOrdersLoading ? (
        <OverviewPhalaItem
          nodes={mining6CoreOrders}
          nodeCount={miningCount6Core}
          totalEarning={totalEarningMiningSixCore}
          totalBalance={totalEarningMiningSixCore}
          showTitles={showItemTitles}
        />
      ) : null,
    },
    {
      key: 'phalaOverviewItem4Core',
      content: miningCount4Core || getOrdersLoading ? (
        <OverviewPhala4core
          nodes={mining4CoreOrders}
          nodeCount={miningCount4Core}
          totalEarning={totalEarningMiningFourCore}
          totalBalance={totalEarningMiningFourCore}
          showTitles={showItemTitles}
        />
      ) : null,
    },
    {
      key: 'neunodeOverviewItem',
      content: neunodeCount || getOrdersLoading ? (
        <OverviewNeunodeItem
          nodes={neunode}
          nodeCount={neunodeCount}
          totalEarning={totalEarningNeunode}
          totalBalance={totalEarningNeunode}
          showTitles={showItemTitles}
        />
      ) : null,
    },
    {
      key: 'neunodeETH2OverviewItem',
      content: neunodeETH2Count || getOrdersLoading ? (
        <OverviewNeunodeETH2Item
          nodes={neunodeETH2}
          nodeCount={neunodeETH2Count}
          totalEarning={totalEarningNeunodeETH2}
          totalBalance={totalEarningNeunodeETH2}
          showTitles={showItemTitles}
        />
      ) : null,
    },
    {
      key: 'zenOverviewItem',
      content: zenNodesCount || getOrdersLoading || getZenBalanceLoading ? (
        <OverviewZenItem
          nodes={zenEarning}
          totalEarning={totalZenEarning}
          totalBalance={totalZenBalance}
          showTitles={showItemTitles}
        />
      ) : null,
    },
  ];
  const lendingItems = [
    {
      key: 'busdOverviewItem',
      content: !!busd[0].orderId || getOrdersLoading ? (
        <OverviewBUSDItem
          showTitles={showItemTitles}
          empty={!busd[0].orderId}
          totalEarningInCoin={totalEarningBUSDInCoin}
          totalEarning={totalEarningBUSD}
          totalBalanceInCoin={totalEarningBUSDInCoin}
          totalBalance={totalBalanceBUSD}
        />
      ) : null,
    },
  ];
  const getItemsWithDivider = (listItems) => {
    const filteredListItems = R.reject(R.propEq('content', null), listItems);
    return (
      <>
        {R.addIndex(R.map)((eachItem, index) => {
          const { key, content } = eachItem;

          return content ? (
            <Box key={key}>
              {index > 0 && <Divider />}
              { content }
            </Box>
          ) : null;
        })(filteredListItems)}
      </>
    );
  };

  // Overview content
  const convertToBTC = (value) => convertFiatToCrypto(coinPrice, value, 'BTC');

  const loading = getUserNodesLoading
  || getTotalEarningLoading
  || getOrdersLoading
  || getZenBalanceLoading
  || getCoinPriceLoading
  || phalaEarningLoading;
  const totalEarning = loading ? null
    : (totalEarningETH2Nodes + totalEarningMining + totalEarningNeunode + totalEarningNeunodeETH2 + totalZenEarning + totalEarningBUSD);
  const totalBalance = loading ? null
    : (totalBalanceETH2Nodes + totalEarningMining + totalEarningNeunode + totalEarningNeunodeETH2 + totalZenBalance + totalBalanceBUSD);

  const percenttages = {
    ETH: percentage(totalBalanceETH2Nodes / totalBalance, 2),
    UnUniFi: percentage(0, 2),
    PHA: percentage(totalEarningMining / totalBalance, 2),
    Neunode: percentage(totalEarningNeunode / totalBalance, 2),
    NeunodeETH2: percentage(totalEarningNeunodeETH2 / totalBalance, 2),
    ZEN: percentage(totalZenBalance / totalBalance, 2),
    BUSD: percentage(totalBalanceBUSD / totalBalance, 2),
  };
  const chartData = [
    {
      id: 'ETH',
      label: 'ETH',
      value: percenttages.ETH,
      color: nodeColors.ETH,
      metadata: {
        balance: toFiat(totalBalanceETH2Nodes, 'JPY'),
        staked: toFiat(totalBalanceETH2Nodes - totalEarningETH2Nodes, 'JPY'),
        earning: toFiat(totalEarningETH2Nodes, 'JPY'),
      },
    },
    {
      id: 'GUU (UnUniFi)',
      label: 'GUU (UnUniFi)',
      value: percenttages.UnUniFi,
      color: nodeColors.UnUniFi,
      metadata: {
        balance: toFiat(0, 'JPY'),
        staked: toFiat(0, 'JPY'),
        earning: toFiat(0, 'JPY'),
      },
    },
    {
      id: 'PHA',
      label: 'PHA',
      value: percenttages.PHA,
      color: nodeColors.PHA,
      metadata: {
        balance: toFiat(totalEarningMining, 'JPY'),
        staked: toFiat(0, 'JPY'),
        earning: toFiat(totalEarningMining, 'JPY'),
      },
    },
    {
      id: 'Neunode',
      label: 'Neunode',
      value: percenttages.Neunode,
      color: nodeColors.Neunode,
      metadata: {
        balance: toFiat(totalEarningNeunode, 'JPY'),
        staked: toFiat(0, 'JPY'),
        earning: toFiat(totalEarningNeunode, 'JPY'),
      },
    },
    {
      id: 'Neunode ETH2',
      label: 'Neunode ETH2',
      value: percenttages.NeunodeETH2,
      color: nodeColors.NeunodeETH2,
      metadata: {
        balance: toFiat(totalEarningNeunode, 'JPY'),
        staked: toFiat(0, 'JPY'),
        earning: toFiat(totalEarningNeunode, 'JPY'),
      },
    },
    {
      id: 'ZEN',
      label: 'ZEN',
      value: percenttages.ZEN,
      color: nodeColors.ZEN,
      metadata: {
        balance: toFiat(totalZenBalance, 'JPY'),
        staked: toFiat(totalZenBalance - totalZenEarning, 'JPY'),
        earning: toFiat(totalZenEarning, 'JPY'),
      },
    },
    {
      id: 'BUSD',
      label: 'BUSD',
      value: percenttages.BUSD,
      color: nodeColors.BUSD,
      metadata: {
        balance: toFiat(totalBalanceBUSD, 'JPY'),
        staked: toFiat(totalBalanceBUSD - totalEarningBUSD, 'JPY'),
        earning: toFiat(totalEarningBUSD, 'JPY'),
      },
    },
  ];

  const isNaasEmpty = !nodesETH2.length && !nodesUnUniFi.length && !getUserNodesLoading;
  const isNodeOwnerEmpty = !miningCount6Core && !miningCount4Core && !neunodeCount && !neunodeETH2Count && !zenNodesCount && !getOrdersLoading && !getZenBalanceLoading;
  const isLendingEmpty = !busd[0].orderId && !getOrdersLoading;
  const content = (
    <>
      <Grid
        container
        justifyContent="center"
        alignItems="center"
      >
        <Grid item xs={12}>
          <Grid
            container
            justifyContent="space-between"
            alignItems="center"
            spacing={1}
          >
            <Grid item xs={12} sm={6} md={12} lg={6}>
              <AmountCard
                data={{
                  balanceTitle: t('Total Balance'),
                  earningTitle: t('Total Earnings'),
                  stakedTitle: t('Total Staked'),
                  balance: toNumber(totalBalance).toLocaleString('en-US', {
                    style: 'currency',
                    currency: 'JPY',
                  }),
                  balanceExchanged: `≈ ${convertToBTC(totalBalance)} BTC`,
                  earning: toNumber(totalEarning).toLocaleString('en-US', {
                    style: 'currency',
                    currency: 'JPY',
                  }),
                  staked: toNumber(totalBalance - totalEarning).toLocaleString('en-US', {
                    style: 'currency',
                    currency: 'JPY',
                  }),
                }}
                loading={loading}
                className={classes.amountCard}
                earningTextColor="#ffffff"
              />
            </Grid>
            <Grid item xs={12} sm={6} md={12} lg={6} className={classes.chart}>
              <OverviewPieChart
                data={chartData}
                loading={loading}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h2" className={classes.myNodeTitle}>
            {t('My Node')}
          </Typography>
        </Grid>

        {getMyNodeItemContent(t('Node as a Service'), 'naas', isNaasEmpty, getItemsWithDivider(naasItems))}
        {getMyNodeItemContent(t('Node Owner'), 'node-owner', isNodeOwnerEmpty, getItemsWithDivider(nodeOwnerItems))}
        <Grid item xs={12}>
          <Typography variant="h2" className={classes.myNodeTitle}>
            {t('My Crypto')}
          </Typography>
        </Grid>
        {getMyNodeItemContent(t('Lending'), 'lending', isLendingEmpty, getItemsWithDivider(lendingItems))}
      </Grid>
    </>
  );

  const tabs = [
    {
      label: t('Portfolio'),
      content,
    },
  ];

  return (
    <>
      {
        getEarningErrorExists
        && (
          <Alert
            severity="error"
          >
            <Typography variant="body2" color="inherit">
              {t('Our server is busy. Please try again later.')}
            </Typography>
          </Alert>
        )
        // End
      }
      <CustomTabs
        tabs={tabs}
        paddingContent={20}
      />
    </>
  );
};

Overview.defaultProps = {
};

Overview.propTypes = {
  width: PropTypes.string.isRequired,
};

export default withWidth()(Overview);
