import * as R from 'ramda';

export const formatCreateUserPayload = (payload) => {
  const { items } = payload;

  const result = R.map(
    R.prop('user'),
  )(items || []);

  return {
    data: result,
  };
};
