import SvgIcon from '@material-ui/core/SvgIcon';
import * as React from 'react';

const HomeIconSvg = (props) => {
  const { ...muiSvgIconProps } = props;
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      width={22.5}
      height={19.502}
      viewBox="0 0 22.5 19.502"
      {...muiSvgIconProps}
    >
      <g data-name="Icon feather-home">
        <path
          data-name="Subtraction 2"
          d="M8.1 18.75H3.084a2.122 2.122 0 01-2.334-1.8v-9.9l10.5-6.3 10.5 6.3v9.9a2.121 2.121 0 01-2.333 1.8H14.6v-6.4H8.1v6.4z"
          fill="none"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={1.5}
        />
      </g>
    </SvgIcon>
  );
};

HomeIconSvg.defaultProps = {
};

HomeIconSvg.propTypes = {
};

export default HomeIconSvg;
