import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import AutorenewOutlined from '@material-ui/icons/AutorenewOutlined';
import whyDidYouRender from '@welldone-software/why-did-you-render';
import { sleep } from 'jsutils';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import {
  useHistory,
} from 'react-router-dom';

import { useAddFunctionToRef, useGetQuery, useUserDataSelector } from '../../../hooks/hooks';
import { webUrl, infinite } from '../../../modules/appConstants';
import { dashboardRoute } from '../../../routes';
import { adminOperations } from '../../../store/admin/index';
import { adminUserOperations } from '../../../store/admin/user/index';
import { routerServices } from '../../../store/router/index';
import ReactTableV7ReactWindow from '../../Tables/ReactTableV7ReactWindow';
// import ReactTableV7 from '../Tables/ReactTableV7';

// others
import { userListColumns } from '../columns';
// store

import { userListStyles } from '../styles';
// routes

if (process.env.REACT_APP_ENV !== 'prod') {
  whyDidYouRender(React);
}

const useStyles = makeStyles(userListStyles);

const UserList = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const queryLng = useGetQuery('lng');
  const { t } = useTranslation();

  const { onReload } = props;

  const [loadingImpersonatedUser, setLoadingImpersonatedUser] = useState(false);

  const { role } = useUserDataSelector();
  const getUsersLoading = useSelector((state) => state.loadingR.getUsersLoading);

  const userList = useSelector(
    (state) => state.adminR.users.userList['1-all'],
  );

  const createHandleLinkBtnClick = R.curry((queryLng, pathname) => {
    window.open(`${webUrl}${pathname}`);
  });
  const handleLinkBtnClick = createHandleLinkBtnClick(queryLng);

  const viewUserDashBoard = async (userData) => {
    dispatch(adminOperations.clearReloadUser());
    dispatch(adminOperations.impersonateUser(userData));
    setLoadingImpersonatedUser(true);
    await sleep(1000);
    setLoadingImpersonatedUser(false);
    handleLinkBtnClick(dashboardRoute);
  };

  const onGetUsers = useAddFunctionToRef(async (userList, reload = false) => {
    if (R.isEmpty(userList) || !userList || reload) {
      await dispatch(adminUserOperations.getUsers(
        { query: { } },
        { page: 1, limit: infinite },
      ));
    }
  });

  const handleOnReload = (userList) => {
    onGetUsers(
      userList, true,
    );
    onReload();
  };

  // If user is not admin redirect user to normal dashboard route
  useEffect(() => {
    if (role !== 'ADMIN') {
      routerServices.historyPush(
        history,
        {
          pathname: dashboardRoute,
          queryLng,
        },
      );
    }
  }, [history, role, queryLng]);

  useEffect(() => {
    onGetUsers(userList);
  }, [onGetUsers, userList]);

  const columnsToUse = userListColumns(t, viewUserDashBoard, loadingImpersonatedUser);
  const loading = getUsersLoading;

  const getContent = () => (
    <Paper className={classes.table}>
      <ReactTableV7ReactWindow
        title={
          (
            <Box display="flex" alignContent="center">
              <Box pr={1}>
                {t('User List')}
              </Box>
              <Box mb={0.25}>
                <IconButton
                  onClick={() => handleOnReload(userList)}
                  className={classes.reFetchButton}
                  disabled={loading}
                >
                  <AutorenewOutlined color={loading ? 'disabled' : 'primary'} />
                </IconButton>
              </Box>
            </Box>
          )
        }
        showToolbar
        scope="getUsers"
        data={userList || []}
        columns={columnsToUse}
        loading={loading}
      />
    </Paper>
  );
  return getContent();
};

UserList.defaultProps = {
  onReload: () => {},
};

UserList.propTypes = {
  onReload: PropTypes.func,
};

UserList.whyDidYouRender = true;

export default UserList;
