import gql from 'graphql-tag';

export const getNodeProviderOrderQuery = gql`
  query getNodeProviderOrder($getNodeProviderOrderInput: GetNodeProviderOrderInput!)
  {
    getNodeProviderOrder(
      getNodeProviderOrderInput: $getNodeProviderOrderInput
    ) {
      nodeProviderOrderId
      userId
      quantity
      amount
      dateCreated
      dateUpdated
      contract {
        contractStartDate
        contractEndDate
        contractPeriodValue
        contractPeriod
        contractIRR
        dateCreated
        dateUpdated
      }
      timeline {
        nodeProviderOrderTimelineId
        dateCreated
        dateUpdated
        dateCompleted
        expectDateCompleted
        stepName
        stepStatus
        description
      }
      devices {
        deviceId
        macAddress
        deviceStatus
        dateCreated
        dateUpdated
        dateActive
        earnings {
          nodeProviderEarningId
          earning
          dateCreated
          dateUpdated
          cryptoPrices
          currencySymbol
          deviceId
        }
      }
    }
  }
`;
