import Box from '@material-ui/core/Box';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grid from '@material-ui/core/Grid';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import ErrorOutlineOutlinedIcon from '@material-ui/icons/ErrorOutlineOutlined';
import whyDidYouRender from '@welldone-software/why-did-you-render';
import classNames from 'classnames';
import round from 'lodash/round';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
// toastify
import Moment from 'react-moment';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';

// others

import { useAddFunctionToRef, useIsMobile } from '../../../../hooks/hooks';
import { nodeOperations, nodeSelectors } from '../../../../store/node/index';
import { GET_NODES_ESTIMATE_ACTIVATION_DATE_SUCCESS } from '../../../../store/node/types';
import { getNodeAge, getApr } from '../../../utils/utils';
import MyNodeInfoDescriptionItem from '../MyNodeInfoDescriptionItem';

// store

import { stylesDescription } from './styles';

const useStyles = makeStyles(stylesDescription);
if (process.env.REACT_APP_ENV !== 'prod') {
  whyDidYouRender(React);
}
const ETH2MyNodeInfoAccount = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const isMobileDevice = useIsMobile();
  const {
    currency,
    nodeKey,
    nodeName,
    nodeType,
    nodeStatus,
  } = props;
  const dispatch = useDispatch();

  const nodeActivatedDate = useSelector((state) => state.nodeR.nodeActivatedDate);
  const { estimateActivationDate: activeDate } = nodeActivatedDate[nodeKey] || {};

  const [openTooltipActivationDate, setOpenTooltipActivationDate] = React.useState(false);
  const handleTooltipActivationDateClose = () => {
    setOpenTooltipActivationDate(false);
  };
  const handleTooltipActivationDateOpen = () => {
    setOpenTooltipActivationDate(true);
  };

  const [openTooltipAPR, setOpenTooltipAPR] = React.useState(false);
  const handleTooltipAPRClose = () => {
    setOpenTooltipAPR(false);
  };
  const handleTooltipAPROpen = () => {
    setOpenTooltipAPR(true);
  };

  const nodeDataFromChain = useSelector(nodeSelectors.selectNodeDataFromChain(nodeName));
  const {
    balance,
    income,
  } = nodeDataFromChain;
  const onGetUserNodeData = useAddFunctionToRef(
    async () => dispatch(
      nodeOperations.getUserNodeEarning(
        'ETH2_VALIDATOR_NODE',
        nodeName,
        nodeKey,
      ),
    ),
  );
  const showActivationDateMessage = () => {
    toast.info(
      t('Estimated activation date will be displayed at least 12 hours after your deposit transaction is confirmed'),
      {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      },
    );
  };
  const onGetNodesEstimateActivationDate = useAddFunctionToRef(
    async (nodeKey, nodeType) => {
      if (!nodeKey) {
        return;
      }
      const { type, payload } = await dispatch(
        nodeOperations.getNodesEstimateActivationDate(
          [nodeKey],
          nodeType,
        ),
      );

      if (type === GET_NODES_ESTIMATE_ACTIVATION_DATE_SUCCESS) {
        const { activationDates } = payload;
        const nodeActiveDate = R.find(R.propEq('publicKey', nodeKey), activationDates);

        if (!nodeActiveDate) {
          showActivationDateMessage();
        }
      } else {
        showActivationDateMessage();
      }
    },
  );
  const nodeAge = getNodeAge(activeDate);

  useEffect(() => {
    if (nodeKey) {
      onGetUserNodeData();
      onGetNodesEstimateActivationDate(nodeKey, nodeType);
    }
  }, [onGetUserNodeData, onGetNodesEstimateActivationDate, nodeKey, nodeType, nodeStatus]);

  const getTooltipActivationDateContent = () => {
    if (isMobileDevice) {
      return (
        <ClickAwayListener onClickAway={handleTooltipActivationDateClose}>
          <Tooltip
            open={openTooltipActivationDate}
            title={<Typography variant="body2">{t('Estimated date. For more details, please open the link in Validator Key section below.')}</Typography>}
          >
            <ErrorOutlineOutlinedIcon
              onClick={handleTooltipActivationDateOpen}
              fontSize="small"
              color="disabled"
            />
          </Tooltip>
        </ClickAwayListener>
      );
    }

    return (
      <Tooltip
        title={<Typography variant="body2">{t('Estimated date. For more details, please open the link in Validator Key section below.')}</Typography>}
      >
        <ErrorOutlineOutlinedIcon
          fontSize="small"
          color="disabled"
        />
      </Tooltip>
    );
  };

  const getTooltipAPRContent = () => {
    if (isMobileDevice) {
      return (
        <ClickAwayListener onClickAway={handleTooltipAPRClose}>
          <Tooltip
            open={openTooltipAPR}
            title={<Typography variant="body2">{`${t('Estimated rate since your node is active')} (${round(nodeAge)} ${t('days')}`}</Typography>}
          >
            <ErrorOutlineOutlinedIcon
              onClick={handleTooltipAPROpen}
              fontSize="small"
              color="disabled"
            />
          </Tooltip>
        </ClickAwayListener>
      );
    }

    return (
      <Tooltip
        title={<Typography variant="body2">{`${t('Estimated rate since your node is active')} (${round(nodeAge)} ${t('days')})`}</Typography>}
      >
        <ErrorOutlineOutlinedIcon
          fontSize="small"
          color="disabled"
        />
      </Tooltip>
    );
  };

  const getActivationDateContent = () => {
    if (activeDate) {
      return (
        <Grid item>
          <MyNodeInfoDescriptionItem
            title={(
              <Box display="flex" alignItems="center" flexDirection="row">
                <Box>
                  <Typography variant="h6">
                    {t('Activation Date')}
                  </Typography>
                </Box>
                <Box ml={1} display="flex" alignItems="center">
                  {getTooltipActivationDateContent()}
                </Box>
              </Box>
          )}
            content={(
              <Typography variant="body1">
                <Moment format="MMM D, YYYY">
                  {activeDate}
                </Moment>
              </Typography>
          )}
          />
        </Grid>
      );
    }
  };

  return (
    <Box className={classNames(classes.descriptionContent)}>
      <Grid container direction="column" spacing={2}>
        {getActivationDateContent()}
        <Grid item>
          <MyNodeInfoDescriptionItem
            title={t('Current Balance')}
            content={(
              <Grid container alignItems="center">
                <Grid zeroMinWidth item xs={12} md={6}>
                  <Typography noWrap variant="body1">{`${balance} ${currency}`}</Typography>
                </Grid>
              </Grid>
            )}
          />
        </Grid>
        <Grid item>
          <MyNodeInfoDescriptionItem
            title={t('Income')}
            content={<Typography variant="body1">{`${income} ${currency}`}</Typography>}
          />
        </Grid>
        <Grid item>
          <MyNodeInfoDescriptionItem
            title={(
              <Box display="flex" alignItems="center" flexDirection="row">
                <Box>
                  <Typography variant="h6">
                    APR
                  </Typography>
                </Box>
                <Box ml={1} display="flex" alignItems="center">
                  {getTooltipAPRContent()}
                </Box>
              </Box>
            )}
            content={(
              <Typography variant="body1">
                {`${getApr(32, income, nodeAge)}%`}
              </Typography>
            )}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

ETH2MyNodeInfoAccount.defaultProps = {
  currency: '',
  nodeKey: '',
  nodeName: '',
  nodeAge: 1,
  nodeType: '',
  nodeStatus: '',
};

ETH2MyNodeInfoAccount.propTypes = {
  currency: PropTypes.string,
  nodeKey: PropTypes.string,
  nodeName: PropTypes.string,
  nodeAge: PropTypes.number,
  nodeType: PropTypes.string,
  nodeStatus: PropTypes.string,
};
ETH2MyNodeInfoAccount.whyDidYouRender = true;

export default React.memo(ETH2MyNodeInfoAccount);
