import * as R from 'ramda';

export const formatCreateUsersInput = (data) => R.map(
  (order) => {
    const {
      email,
      isAdmin,
      createFirstSignin,
      skipActivationEmail,
      newsletter,
    } = order;

    return {
      skipActivationEmail: createFirstSignin || skipActivationEmail,
      settings: {
        emailSubscriptions: {
          newsletter,
        },
      },
      activateUser: createFirstSignin,
      createFirstSignin,
      adminKey: isAdmin ? process.env.REACT_APP_ADMIN_KEY : 'N/A',
      password: process.env.REACT_APP_USER_DEFAULT_PASSWORD,
      email,
    };
  },
)(data);
