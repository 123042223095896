import ButtonLoading from './ButtonLoading';
import CopyButton from './CopyButton';
import HintButton from './HintButton';
import IconButtons from './IconButtons';
import RedirectButton from './RedirectButton';
import RoundedButton from './RoundedButton';

export default {
  ...IconButtons,
  ButtonLoading,
  HintButton,
  CopyButton,
  RoundedButton,
  RedirectButton,
};
