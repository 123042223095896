import whyDidYouRender from '@welldone-software/why-did-you-render';
import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';

import SelectConnectors from './SelectConnectors';

const { REACT_APP_ENV } = process.env;

if (REACT_APP_ENV !== 'prod') {
  whyDidYouRender(React);
}

const ConnectToWallet = (props) => {
  const {
    onLastStepClose,
    onCloseConnectToWalletDialog,
    selectedNodesToDeposit,
    AfterConnectedComponent,
    context,
    walletConnected,
    activatingConnector,
    setActivatingConnector,
    balance,
    onDisconnectConnector,
    onApproveTx,
    wrongNetwork,
    showPopupWrongNetworkError,
  } = props;

  const {
    connector,
    library,
    chainId,
    account,
    activate,
    deactivate,
    active,
    error,
  } = context;

  /**
  * DEPOSIT KEYS
  */
  const {
    depositKeys,
  } = useSelector((state) => state.depositR.depositData);

  return !walletConnected
    ? (
      <SelectConnectors
        connector={connector}
        library={library}
        chainId={chainId}
        account={account}
        activate={activate}
        deactivate={deactivate}
        active={active}
        error={error}
        activatingConnector={activatingConnector}
        setActivatingConnector={setActivatingConnector}
        onCloseConnectToWalletDialog={onCloseConnectToWalletDialog}
        showPopupWrongNetworkError={showPopupWrongNetworkError}
        wrongNetwork={wrongNetwork}
      />
    )
    : (
      <>
        <AfterConnectedComponent
          account={account}
          balance={balance}
          onClickDisconnect={onDisconnectConnector}
          onApproveTx={onApproveTx}
          depositKeys={depositKeys}
          onLastStepClose={onLastStepClose}
          selectedNodesToDeposit={selectedNodesToDeposit}
        />
      </>
    );
};

ConnectToWallet.defaultProps = {
  onLastStepClose: () => {},
  onCloseConnectToWalletDialog: () => {},
  selectedNodesToDeposit: null,
  activatingConnector: null,
  AfterConnectedComponent: null,
  context: {
    connector: null,
    library: null,
    chainId: 0,
    account: null,
    active: false,
    error: null,
  },
  walletConnected: false,
  setActivatingConnector: () => {},
  balance: 0,
  onDisconnectConnector: () => {},
  onApproveTx: () => {},
  wrongNetwork: false,
  showPopupWrongNetworkError: () => {},
};

ConnectToWallet.propTypes = {
  onLastStepClose: PropTypes.func,
  onCloseConnectToWalletDialog: PropTypes.func,
  selectedNodesToDeposit: PropTypes.object,
  AfterConnectedComponent: PropTypes.object,
  /**
   * The types of the properties of the context can be found
   * {@link https://github.com/NoahZinsmeister/web3-react/tree/v6/docs#overview}
   */
  context: PropTypes.shape({
    connector: PropTypes.object,
    library: PropTypes.any,
    chainId: PropTypes.number,
    account: PropTypes.string,
    activate: PropTypes.func.isRequired,
    deactivate: PropTypes.func.isRequired,
    active: PropTypes.bool.isRequired,
    error: PropTypes.object,
  }),
  walletConnected: PropTypes.bool,
  activatingConnector: PropTypes.object,
  setActivatingConnector: PropTypes.func,
  balance: PropTypes.number,
  onDisconnectConnector: PropTypes.func,
  onApproveTx: PropTypes.func,
  wrongNetwork: PropTypes.bool,
  showPopupWrongNetworkError: PropTypes.func,
};

ConnectToWallet.whyDidYouRender = true;

export default React.memo(ConnectToWallet);
