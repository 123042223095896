import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import fileSaver from 'file-saver';
import JSZip from 'jszip';
import * as moment from 'moment';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { useAddFunctionToRef } from '../../../../hooks/hooks';
import { depositOperations } from '../../../../store/deposit/index';

import { eth20WalletFilesStyles } from './styles';

import DownloadButton from 'components/MaterialUiCustom/Button/DownloadButton';

const useStyles = makeStyles(eth20WalletFilesStyles);

const ProtocolConfigEth20WalletFiles = (props) => {
  const dispatch = useDispatch();
  const {
    credentials,
    onToggleHaveFiles,
    withNetworkError,
  } = props;
  const { t } = useTranslation();

  const classes = useStyles();
  const [validatorFiledownloaded, setValidatorFiledownloaded] = useState(false);
  const [depositFileDownloaded, setDepositFileDownloaded] = useState(false);

  useEffect(() => {
    if (
      R.and(
        validatorFiledownloaded,
        depositFileDownloaded,
      )
    ) {
      onToggleHaveFiles(true);
    }
  }, [
    onToggleHaveFiles,
    validatorFiledownloaded,
    depositFileDownloaded,
  ]);
  const onDowloadValidatorFileSucess = () => {
    setValidatorFiledownloaded(true);
  };
  const onDowloadDepositFileSucess = () => {
    setDepositFileDownloaded(true);
  };
  const credentialToUse = credentials || [{}];
  const credential = {
    signingKeystore: R.map(R.prop('signingKeystore'))(credentials),
    depositData: R.map(
      R.pipe(
        R.prop('depositData'),
        JSON.parse,
      ),
    )(credentialToUse),
  };
  const {
    signingKeystore,
    depositData,
  } = credential;
  const onSetDepositData = useAddFunctionToRef(() => {
    dispatch(depositOperations.extractDepositKeys(JSON.stringify(depositData)));
  });

  useEffect(() => {
    if (
      R.and(
        validatorFiledownloaded,
        depositFileDownloaded,
      )
    ) {
      onSetDepositData();
      onToggleHaveFiles(true);
    }
  }, [
    onToggleHaveFiles,
    validatorFiledownloaded,
    depositFileDownloaded,
    onSetDepositData,
  ]);

  const createDownLoadsigningKeystoreZipFile = R.curry(
    async (onDownloadSuccess, fileName, signingKeystoreData) => {
      const now = moment().unix();
      const fileNameToUse = `${fileName}-${now}.zip`;
      const zip = new JSZip();
      R.addIndex(R.forEach)(
        (eachSigningKeystore, index) => {
          const eachJsonFileName = `${fileName}-${index + 1}-${now}.json`;
          zip.file(eachJsonFileName, eachSigningKeystore);
        },
      )(signingKeystoreData);
      const content = await zip.generateAsync({ type: 'blob' });
      fileSaver.saveAs(content, fileNameToUse);
      return onDownloadSuccess();
    },
  );

  const downloadSigningKeystoreZipFile = createDownLoadsigningKeystoreZipFile(
    onDowloadValidatorFileSucess,
    'keystore-m_12381_3600_0_0_0',
  );

  const handleDownloadSigninKeystoreZipFile = () => withNetworkError(
    () => downloadSigningKeystoreZipFile(signingKeystore),
  );
  const createDownLoadDepositFile = R.curry(
    async (onDownloadSuccess, fileName, fileContent) => {
      const now = moment().unix();
      const fileNameToUse = `${fileName}-${now}.json`;
      const skBlob = new Blob([fileContent], { type: 'application/json;charset=utf-8' });
      await fileSaver.saveAs(skBlob, fileNameToUse);
      return onDownloadSuccess();
    },
  );

  const downloadDepositFile = createDownLoadDepositFile(
    onDowloadDepositFileSucess,
    'deposit_data',
  );
  const handleDownloadDepositFile = () => withNetworkError(
    () => downloadDepositFile(JSON.stringify(depositData)),
  );

  return (
    <Grid container spacing={2} className={classes.containerGrid}>
      <Grid item xs={12}>
        <Typography variant="h6">
          {t('Download wallet files')}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="body2">
          {t('You must download and save your wallet files to proceed')}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="body2" color="primary">
          {t('The files below are extremely important. You will need them later for withdrawing your fund, and getting further support from us.')}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          <Box>
            <Typography variant="body1">
              {t('Keystore json file')}
            </Typography>
          </Box>
          <Box>
            <DownloadButton
              variant="contained"
              color="primary"
              onClick={handleDownloadSigninKeystoreZipFile}
              t={t}
            />

          </Box>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          <Box>
            <Typography variant="body1">
              {t('Deposit data json file')}
            </Typography>
          </Box>
          <Box>
            <DownloadButton
              variant="contained"
              color="primary"
              onClick={handleDownloadDepositFile}
              t={t}
            />
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};

ProtocolConfigEth20WalletFiles.defaultProps = {
  credentials: null,
};

ProtocolConfigEth20WalletFiles.propTypes = {
  credentials: PropTypes.arrayOf(PropTypes.shape({
    depositData: PropTypes.string.isRequired,
    signingKeystore: PropTypes.string.isRequired,
  })),
  onToggleHaveFiles: PropTypes.func.isRequired,
  withNetworkError: PropTypes.func.isRequired,
};

export default ProtocolConfigEth20WalletFiles;
