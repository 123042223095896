/* eslint-disable jsx-a11y/anchor-is-valid */
import Box from '@material-ui/core/Box';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import whyDidYouRender from '@welldone-software/why-did-you-render';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
// lodash

import Invoice from '../../Invoice/Invoice';

import {
  nodeOwnerCompleteStyles,
} from './styles';

const { REACT_APP_ENV } = process.env;

if (REACT_APP_ENV !== 'prod') {
  whyDidYouRender(React);
}

const useStyles = makeStyles(nodeOwnerCompleteStyles);

const NodeOwnerFormComplete = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { payment } = useSelector((state) => state.nodeOwnerR.nodeOwnerOrderConfig);
  const { stripe } = payment;
  const { stripeInvoice } = stripe;

  return (
    <Box
      display="flex"
      flexDirection="column"
      pl={1}
      pr={3}
    >
      <Box mb={2}>
        <Typography style={{ fontWeight: 550 }}>
          {t('Your order has been successfully submitted. Please finish the payment within')}
          {' '}
          <Link className={classes.highlightText}>
            {t('7 days')}
          </Link>
          {' '}
          {t('in case your order may be cancelled!')}
        </Typography>
      </Box>

      <Box>
        <Invoice
          invoice={stripeInvoice}
        />
      </Box>
    </Box>
  );
};

NodeOwnerFormComplete.propTypes = {
};

NodeOwnerFormComplete.whyDidYouRender = true;
export default React.memo(NodeOwnerFormComplete);
