import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import * as axios from 'axios';
import { toNumber } from 'lodash';
import round from 'lodash/round';
import * as R from 'ramda';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import Alert from '../../MaterialUiCustom/Alert/Alert';
import { getApr } from '../../utils/utils';

import UnUniFiValidatorAllValidators from './UnUniFiValidatorAllValidators';
import UnUniFiValidatorEarningHistory from './UnUniFiValidatorEarningHistory';
import UnUniFiValidatorOverview from './UnUniFiValidatorOverview';

const { REACT_APP_UNUNIFI_MAINTENANCE } = process.env;
const nodeType = 'UNUNIFI_VALIDATOR_NODE';

const UnUniFiValidatorContent = () => {
  const [inflation, setInflation] = useState(0);
  const [isError, setIsError] = useState(false);
  const nodes = useSelector((state) => state.nodeR.userNodes.nodes);
  const ununifiNodes = R.filter(R.propEq('nodeType', nodeType), nodes);
  const {
    getUserNodesLoading,
    getTotalEarningLoading,
  } = useSelector((state) => state.loadingR);
  const { t } = useTranslation();
  const loading = getUserNodesLoading || getTotalEarningLoading;

  const nodesEarning = useSelector((state) => state.nodeR.nodesEarning.ununifi);
  const nodeActivatedDate = useSelector((state) => state.nodeR.nodeActivatedDate);
  const { totalEarningData } = nodesEarning;

  const nodesData = loading ? [] : R.map(
    (eachNode) => {
      const {
        nodeId,
        dateCreated,
        status,
        nodeInfo,
      } = eachNode;
      const {
        pubKey,
        accAddress,
        validatorId,
        ipv4Domain,
        ipv6Domain,
        locked,
      } = nodeInfo;

      const earningData = R.find(R.propEq('accAddress', accAddress), totalEarningData);
      const {
        balance: balanceInUnUniFi,
        earning: earningInUnUniFi,
        index,
        initialAmount,
      } = earningData || { balance: 0, earning: 0, initialAmount: 0 };
      const balance = balanceInUnUniFi;
      const earning = earningInUnUniFi;

      const estimateActivationDate = R.view(R.lensPath([pubKey, 'estimateActivationDate']), nodeActivatedDate) || dateCreated;
      const dateObj = new Date(estimateActivationDate);
      const now = new Date();
      const diff = (now.getTime() - dateObj.getTime()) / (1000 * 60 * 60 * 24);
      const nodeAge = Math.abs(round(diff, 2));

      const apr = estimateActivationDate ? getApr(initialAmount, earningInUnUniFi, nodeAge) : 0;

      return {
        nodeId,
        index,
        dateCreated,
        balance,
        earning,
        apr,
        status,
        pubKey,
        accAddress,
        validatorId,
        ipv4Domain,
        ipv6Domain,
        locked,
      };
    },
  )(ununifiNodes);

  const getStakingInflation = async () => {
    try {
      const { data } = await axios.get('http://ununifi.mainnet.lcd-01.neukind.network:1317/cosmos/mint/v1beta1/inflation');
      const { inflation } = data;
      setInflation(toNumber(inflation) * 100);
    } catch (err) {
      setIsError(true);
    }
  };
  useEffect(() => {
    getStakingInflation();
  }, []);

  return (
    <Grid container justifyContent="center" spacing={4}>
      {(REACT_APP_UNUNIFI_MAINTENANCE === 'ON' || isError) && (
      <Grid item xs={12}>
        <Alert
          severity="warning"
        >
          <Typography variant="body2" color="inherit">
            {t('UnUniFi earning is under maintenance. Your earning is safe.')}
          </Typography>
        </Alert>
      </Grid>
      )}
      <Grid item xs={12}>
        <UnUniFiValidatorOverview averageAPR={inflation} />
      </Grid>
      <Grid item xs={12}>
        <UnUniFiValidatorAllValidators data={nodesData} />
      </Grid>
      <Grid item xs={12}>
        <UnUniFiValidatorEarningHistory />
      </Grid>
    </Grid>
  );
};

export default UnUniFiValidatorContent;
