import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment';
import * as R from 'ramda';
import React from 'react';
import { CSVLink } from 'react-csv';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import ReactTableV7 from '../../Tables/ReactTableV7';
import InvestmentEarningDashboardItem from '../InvestmentNodeDetailsPageItem';

import { miningStatisticsColumns } from './columns';
import { miningStatisticsStyles } from './styles';

const useStyles = makeStyles(miningStatisticsStyles);
const { REACT_APP_DISABLE_PHALA_MINING_DAILY_STATISTIC } = process.env;

const MiningStatistics = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  const intervalEarning = useSelector((state) => state.nodeR.nodesEarning.pha.interval);
  const {
    getOrdersLoading,
    getIntervalEarningLoading,
  } = useSelector((state) => state.loadingR);

  // const dataToUse = R.pipe(
  //   R.map(
  //     R.prop('devices'),
  //   ),
  //   R.flatten,
  // )(orders);

  // Phala and ETH use the same grpc to get the interval earning
  const maintenanceDailyStatistic = REACT_APP_DISABLE_PHALA_MINING_DAILY_STATISTIC === 'ON';

  const columnsToUse = miningStatisticsColumns(
    t,
  );

  const formatedIntervalEarning = R.map((eachData) => ({
    ...eachData,
    date: moment(eachData.endTime).toLocaleString(),
    currency: 'JPY',
  }))(intervalEarning);

  const csvHeaders = [
    { label: 'Date', key: 'date' },
    { label: 'Earning (PHA)', key: 'totalCoin' },
    { label: 'Fiat Equivalent', key: 'total' },
    { label: 'Exchange Rate', key: 'currencyPrice' },
    { label: 'Currency', key: 'currency' },
  ];

  return (
    <InvestmentEarningDashboardItem
      titlePadding="21px 32px 16px 32px"
      title={(
        <Box display="flex" alignItems="center">
          <Box mr={2}>
            {`${t('Statistics')}`}
          </Box>
          {/* <Box ml={0.5} mr={2}>
            <CustomTooltip
              title={t(maintenanceDailyStatistic ? 'Maintenance' : 'Data will be displayed at least one day after your nodes become active')}
              content={(
                <HelpOutlineOutlinedIcon
                  color="disabled"
                  style={{ fontSize: 20 }}
                />
              )}
            />
          </Box> */}
          <Box>
            <Button disabled={getIntervalEarningLoading} className={classes.downloadButton}>
              <CSVLink
                data={maintenanceDailyStatistic ? [] : formatedIntervalEarning}
                headers={csvHeaders}
                filename="phala_earning_history.csv"
                style={{ textDecoration: 'none' }}
              >
                <Typography color="primary" className={classes.downloadButtonText}>
                  {t('Download CSV')}
                </Typography>
              </CSVLink>
            </Button>
          </Box>
        </Box>
)}
      content={(
        <ReactTableV7
          loading={getOrdersLoading}
          data={maintenanceDailyStatistic ? [] : intervalEarning}
          columns={columnsToUse}
          customPageSize={3}
          customPageSizeList={[3, 5, 10, 20, 50]}
        />
)}
    />
  );
};

export default MiningStatistics;
