// import * as R from 'ramda';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Skeleton from '@material-ui/lab/Skeleton';
import PropTypes from 'prop-types';
import React from 'react';

import { investmentNodeDetailsPageItemStyles } from './styles';

const useStyles = makeStyles(investmentNodeDetailsPageItemStyles);

const InvestmentNodeDetailsPageItem = (props) => {
  const classes = useStyles();
  const {
    title,
    align,
    content,
    loading,
    loadingVariant,
    showSecondLoadingLine,
    disableBackground,
    titlePadding,
  } = props;

  const getContent = () => {
    if (loading) {
      const alignGrid = align === 'left' ? 'flex-start' : 'center';
      return (
        <Grid
          container
          spacing={3}
          justifyContent={alignGrid}
          alignItems={alignGrid}
        >
          <Grid item xs={loadingVariant === 'circle' ? 'auto' : 12}>
            <Skeleton
              variant={loadingVariant || 'text'}
              width={loadingVariant === 'circle' ? 100 : '100%'}
              height={loadingVariant === 'circle' ? 100 : '100%'}
            />
          </Grid>
          {loadingVariant === 'text' && showSecondLoadingLine && (
          <Grid item xs={12}>
            <Skeleton
              variant="text"
              width="100%"
            />
          </Grid>
          )}
        </Grid>
      );
    }

    return content;
  };

  const cardHeaderStyleProps = {
    titleTypographyProps: {
      align,
      variant: 'h3',
      color: 'textSecondary',
    },
    style: {
      fontWeight: 600,
      padding: titlePadding === '' ? '21px 32px 8px 32px' : titlePadding,
      fontFamily: [
        'Noto-sans',
        'Sans-serif',
      ].join(','),
    },
  };

  const getRender = (disableBackground) => {
    if (disableBackground) {
      return (
        <>
          <CardHeader
            title={title}
            {...cardHeaderStyleProps}
          />
          <CardContent className={classes.cardContent}>
            {getContent()}
          </CardContent>
        </>
      );
    }

    return (
      <Card style={{ height: '100%' }}>
        <CardHeader
          title={title}
          {...cardHeaderStyleProps}
        />
        <CardContent className={classes.cardContent}>
          {getContent()}
        </CardContent>
      </Card>
    );
  };

  return getRender(disableBackground);
};

InvestmentNodeDetailsPageItem.defaultProps = {
  loading: false,
  loadingVariant: 'text',
  align: 'left',
  showSecondLoadingLine: false,
  disableBackground: false,
  titlePadding: '',
};

InvestmentNodeDetailsPageItem.propTypes = {
  content: PropTypes.element.isRequired,
  loading: PropTypes.bool,
  loadingVariant: PropTypes.string,
  title: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
  ]).isRequired,
  align: PropTypes.string,
  showSecondLoadingLine: PropTypes.bool,
  disableBackground: PropTypes.bool,
  titlePadding: PropTypes.string,
};

export default InvestmentNodeDetailsPageItem;
