import * as deployConfigOperations from './operations';
import reducer from './reducers';
import * as deploySelectors from './selectors';
import * as deployConfigTypes from './types';

export {
  deployConfigOperations,
  deployConfigTypes,
  deploySelectors,
};

export default reducer;
