import Card from '@material-ui/core/Card';
import Dialog from '@material-ui/core/Dialog';
import Divider from '@material-ui/core/Divider';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import {
  toNumber,
} from 'lodash';
import toLower from 'lodash/fp/toLower';
import round from 'lodash/round';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

// routes

import { useAddFunctionToRef } from '../../../../hooks/hooks';
import { nodeOperations } from '../../../../store/node/index';
import ConnectToWallet from '../../../ConnectToWallet/ConnectToWallet';
import { getChainsData } from '../../../Market/constants';
import MyNodeIfoResourceUsage from '../../MyNodeIfoResourceUsage/MyNodeInfoResourceUsage';

import UnUniFiMyNodeInfoAccount from './UnUniFiMyNodeInfoAccount';
import UnUniFiMyNodeInfoNodeVersion from './UnUniFiMyNodeInfoNodeVersion';

// others

import { stylesDescription } from './styles';

const useStyles = makeStyles(stylesDescription);

const UnUniFiMyNodeInfoDescriptionValidator = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { t } = useTranslation();

  const {
    nodeType,
    nodeDescription,
    nodeName,
    dateCreated,
    nodeId,
  } = props;
  const [openDepositStepper, setOpenDepositStepper] = useState(false);
  const {
    status,
    cpuUsage,
    ramUsage,
    diskUsage,
    totalRam,
  } = nodeDescription;
  const isNotDeposit = status === 'CONFIRMING_DEPOSIT' || status === 'PENDING_DEPOSIT';

  const nodeResourceData = isNotDeposit
    ? {
      balance: '32',
      income: '0',
      apr: '15%',
      cpuUsage: '0',
      ramUsage: '0',
      diskUsage: '0',
    }
    : {
      balance: '32',
      income: '1',
      apr: '15%',
      cpuUsage: cpuUsage ? toNumber(cpuUsage).toFixed(2) : '0',
      ramUsage: ramUsage && nodeDescription.totalRam ? (toNumber(ramUsage) / toNumber(totalRam)).toFixed(2) : '0',
      diskUsage: diskUsage ? (toNumber(diskUsage) / 1073741824 / 8).toFixed(2) : '0',
    };

  const protocolName = R.pipe(
    R.split('_'),
    R.head,
    toLower,
  )(nodeType);

  const chain = getChainsData(t)[protocolName];
  const chainUrl = chain ? R.view(
    R.lensPath(['nodeTypes', '0', 'launchInfo']),
    chain,
  ) : '';
  const cryptoUnit = chain ? R.view(
    R.lensPath(['cryptoUnit']),
    chain,
  ) : '';

  /**
   * Get node age in days
   * @param  {string} dateCreated - the date when the node is active
   * @return {number}
   */
  const getNodeAge = (dateCreated) => {
    const dateObj = new Date(dateCreated);
    const now = new Date();
    const diff = (now.getTime() - dateObj.getTime()) / (1000 * 60 * 60 * 24);
    const nodeAge = Math.abs(round(diff, 2));
    return nodeAge;
  };
  const nodeAge = getNodeAge(dateCreated);

  const onOpenConnectToWalletDialog = useAddFunctionToRef(() => {
    setOpenDepositStepper(true);
  });
  const { userId } = useSelector((state) => state.userR.user);

  const onGetUserNode = useAddFunctionToRef(async () => {
    dispatch(nodeOperations.getUserNode(userId, nodeName));
  });
  const [shouldGetUserNode, setShouldGetUserNode] = React.useState(false);
  const enableShouldGetUserNode = () => {
    setShouldGetUserNode(true);
  };
  const onCloseConnectToWalletDialog = () => {
    setOpenDepositStepper(false);
    // reload data here
    if (shouldGetUserNode) onGetUserNode();
    // onToggleWalletConnectorConnected(false);
  };

  const handleOnClickConnectToWallet = async (connected) => {
    onOpenConnectToWalletDialog();
  };

  const {
    pubKey,
    nodeVersion,
  } = nodeDescription.nodeInfo ? nodeDescription.nodeInfo : {};
  const AccountToRender = (
    <UnUniFiMyNodeInfoAccount
      chainUrl={chainUrl}
      currency={cryptoUnit}
      onClickConnectToWallet={handleOnClickConnectToWallet}
      nodeKey={pubKey}
      nodeName={nodeName}
      nodeAge={nodeAge}
      nodeType={nodeType}
      nodeStatus={status}
    />
  );

  return (
    <>
      <Card className={classNames(classes.description)}>
        <MyNodeIfoResourceUsage
          cpuUsage={nodeResourceData.cpuUsage.toString()}
          ramUsage={nodeResourceData.ramUsage.toString()}
          diskUsage={nodeResourceData.diskUsage.toString()}
        />
        <Divider />
        {AccountToRender}
        { nodeVersion
          && <UnUniFiMyNodeInfoNodeVersion nodeVersion={nodeVersion} />}
      </Card>

      <Dialog
        open={openDepositStepper}
        onClose={onCloseConnectToWalletDialog}
        fullWidth
        className={classNames(classes.dialog)}
      >
        <ConnectToWallet
          nodeName={nodeName}
          nodeType={nodeType}
          nodeId={nodeId}
          onLastStepClose={enableShouldGetUserNode}
          onCloseConnectToWalletDialog={onCloseConnectToWalletDialog}
        />
      </Dialog>
    </>
  );
};

UnUniFiMyNodeInfoDescriptionValidator.propTypes = {
  nodeType: PropTypes.string.isRequired,
  nodeDescription: PropTypes.shape({
    status: PropTypes.string.isRequired,
    cpuUsage: PropTypes.string,
    ramUsage: PropTypes.string,
    totalRam: PropTypes.string,
    diskUsage: PropTypes.string,
    nodeInfo: PropTypes.shape({
      pubKey: PropTypes.string.isRequired,
      nodeVersion: PropTypes.string,
    }),
  }),
  nodeName: PropTypes.string.isRequired,
  dateCreated: PropTypes.string.isRequired,
  nodeId: PropTypes.string,
};

UnUniFiMyNodeInfoDescriptionValidator.defaultProps = {
  nodeDescription: {
    cpuUsage: null,
    ramUsage: null,
    totalRam: null,
    diskUsage: null,
    nodeInfo: {
      nodeVersion: null,
    },
  },
  nodeId: '',
};

export default UnUniFiMyNodeInfoDescriptionValidator;
