import * as R from 'ramda';

export const getNonce = R.curry(
  async (api, address) => {
    const info = await api.query.system.account(address);
    return info.nonce.toNumber();
  },
);

export const increaseNonce = R.curry(
  async (api, address, index) => {
    const nonce = await getNonce(api, address);
    console.log('nonce', nonce);
    const increasedNonce = nonce + index;
    return increasedNonce;
  },
);
