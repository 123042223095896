import { makeStyles } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import whyDidYouRender from '@welldone-software/why-did-you-render';
import FileSaver from 'file-saver';
import { sleep } from 'jsutils';
import isEmpty from 'lodash/isEmpty';
import * as moment from 'moment-timezone';
import * as R from 'ramda';
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import SecretPhrase from '../../../SecretPhrase/SecretPhrase';

import MiningAddressCreationAddresses from './MiningAddressCreationAddresses';
import MiningAddressCreationConfigure from './MiningAddressCreationConfigure';
import { addressCreationGenerateStyles } from './styles';

import Alert from 'components/MaterialUiCustom/Alert/Alert';
import DownloadButton from 'components/MaterialUiCustom/Button/DownloadButton';
import { web3Operations, web3Selectors } from 'store/web3';

const useStyles = makeStyles(addressCreationGenerateStyles);

const { REACT_APP_ENV } = process.env;
if (REACT_APP_ENV !== 'prod') {
  whyDidYouRender(React);
}
const chain = 'dot';
// const subChain = 'pha';
/**
 * A custom hook to prevent rerender when update errors
 * @param {*} defState
 * @returns
 */
function useErrorsState(defState) {
  const [state, setState] = useState(defState);
  const {
    name,
    password,
  } = state;

  function smartSetState(newState) {
    const {
      name: newName,
      password: newPassword,
    } = newState;
    if (name !== newName || password !== newPassword) {
      setState(newState);
    }
  }
  return [state, smartSetState];
}

const MiningAddressCreationGenerate = ({
  onSetMethod,
  handleOnCancel,
  resetAllChildren,
  onCreateCryptoAddress,
  userId,
  enableWeb3Loading,
  createCryptoAddressLoading,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const classes = useStyles();
  const [pristine, setPristine] = useState(false);
  const [walletConfig, setWalletConfig] = useErrorsState({});
  // the last value that user choose to use
  const [lastWalletConfig, setLastWalletConfig] = useErrorsState({});
  const [errors, setErrors] = useErrorsState({});
  const [secretPhrase, setSecretPhrase] = useState('');
  const [fileDownloaded, setFileDownloaded] = useState(false);
  const [selectedAddress, setSelectedAddress] = useState('');

  const { password } = walletConfig;

  const onSelectAddress = (address) => {
    setSelectedAddress(address);
  };
  const web3State = useSelector((state) => state.web3R.web3);

  const onConfirmSecretPhrase = ({ secretPhrase }) => {
    setSecretPhrase(secretPhrase);
  };
  const onWalletConfigChange = (walletConfig) => {
    setWalletConfig(walletConfig);
  };
  const onLastWalletConfigChange = (walletConfig) => {
    setLastWalletConfig(walletConfig);
  };

  const onPristineChange = (pristine) => {
    setPristine(pristine);
  };
  const onErrorsChange = (error) => {
    setErrors(error);
  };

  const { accounts } = web3Selectors.selectWeb3GeneratedAccounts(chain, web3State);

  const downloadAccount = async ({ json }) => {
    const blob = new Blob([JSON.stringify(json)], { type: 'application/json; charset=utf-8' });
    const date = moment().format('YYYY-MM-DD-HH:MM');
    FileSaver.saveAs(blob, `neukind-wallet-${date}.json`);
  };
  const onBackupAccount = R.curry(
    async (coin, data) => {
      const { payload } = dispatch(web3Operations.backupAccount(coin, data));
      downloadAccount({ json: payload });
      await sleep(2000);
      setFileDownloaded(true);
    },
  );

  useEffect(() => {
    if (resetAllChildren) {
      handleOnCancel();
    }
  }, [handleOnCancel, resetAllChildren]);
  const steps = {
    2: {
      title: t('Configure wallet'),
      descriptions: {
        primary: t('Input name and password. Please keep your password in a safe place, you will need it later to import/export wallet JSON file into Neukind dashboard or other wallets that support Polkadot'),
      },
    },
    3: {
      title: t('Download wallet'),
      descriptions: {
        primary: t('Download and store your JSON file in a secure place. You can use this file to import into Neukind dashboard and other wallets that support Polkadot.'),
      },
    },
    4: {
      title: t('Select an address'),
      descriptions: {
        primary: t('Your mining rewards will be sent to this address. It cannot be changed once added'),
      },
    },
  };

  const formId = 'walletConfigForm';
  const stepsContents = {
    2: (
      <MiningAddressCreationConfigure
        secretPhrase={secretPhrase}
        onPristineChange={onPristineChange}
        onWalletConfigChange={onWalletConfigChange}
        formId={formId}
        t={t}
        onErrorsChange={onErrorsChange}
        initialValues={!isEmpty(lastWalletConfig) ? lastWalletConfig : undefined}
        onLastWalletConfigChange={onLastWalletConfigChange}
      />
    ),
    3: (
      <Box display="flex" justifyContent="center">
        <DownloadButton
          variant="contained"
          color="secondary"
          onClick={() => {
            onBackupAccount('dot', {
              pair: accounts[0],
              password,
            });
          }}
          t={t}
        />
      </Box>
    ),
    4: (
      <MiningAddressCreationAddresses
        accounts={accounts}
        onCreateCryptoAddress={onCreateCryptoAddress}
        userId={userId}
        createCryptoAddressLoading={createCryptoAddressLoading}
        enableWeb3Loading={enableWeb3Loading}
        handleOnCancel={handleOnCancel}
        onSelectAddress={onSelectAddress}
      />
    ),
  };
  const stepsActions = {
    2: {
      // if secondary action is backaction then in the lower component, we use handleBack function
      secondary: 'back_action',
      isFormAction: true,
      formId,
      isExtra: true,
      btnTexts: {
        primary: t('confirm'),
        secondary: t('back'),
      },
      disabled: (pristine && isEmpty(lastWalletConfig)) || !isEmpty(errors),
    },
    3: {
      primary: 'none',
      secondary: 'back_action',
      isExtra: true,
      btnTexts: {
        primary: t('next'),
        secondary: t('back'),
      },
      disabled: !fileDownloaded,
    },
    4: {
      primary: () => onCreateCryptoAddress(
        userId,
        selectedAddress,
        'DOT',
        'PHA',
      ),
      secondary: 'back_action',
      isExtra: true,
      btnTexts: {
        primary: t('confirm'),
        secondary: t('back'),
      },
      loading: createCryptoAddressLoading,
    },
  };

  return (
    <>
      <Box p={3} width="100%">
        <Alert
          severity="warning"
        >
          {t('By choosing this method, you acknowledge that Neukind does not store your secret phrase or private key. We only store your address to send the reward to you. If you have any security concern, please choose "Connect Polkadot{.js} extension" option instead')}
        </Alert>
      </Box>
      <SecretPhrase
        onGenerateSecretPhrase={null}
        onConfirmSuccess={onConfirmSecretPhrase}
        customClasses={{
          wrapPaper: classes.wrapPaper,
        }}
        firstStepSecondaryBtnAction={() => onSetMethod('')}
        stepper
        extraSteps={steps}
        extraStepsContent={stepsContents}
        extraStepsActions={stepsActions}
        regenerateSecretPhraseWhenGoBack
      />
    </>

  );
};
// MiningAddressCreationMethod.propTypes = {
//   match: PropTypes.shape({
//     params: PropTypes.shape({
//       chainAbbreviation: PropTypes.string.isRequired,
//     }).isRequired,
//   }).isRequired,
// };
MiningAddressCreationGenerate.whyDidYouRender = true;
export default MiningAddressCreationGenerate;
