import isEmail from 'validator/lib/isEmail';

const validateAccount = (values) => {
  const { email, password } = values;
  const errors = {};
  if (!email) {
    errors.email = 'Required';
  } else if (!isEmail(email)) {
    errors.email = 'Invalid email';
  }
  if (!password) {
    errors.password = 'Required';
  }
  return errors;
};

export {
  validateAccount,
};
