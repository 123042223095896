import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Skeleton from '@material-ui/lab/Skeleton';
import { ResponsivePie } from '@nivo/pie';
import toNumber from 'lodash/fp/toNumber';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import React from 'react';
import { useTranslation } from 'react-i18next';
// nivo chart

import { isValid } from '../utils/utils';

import OverviewPieChartTooltip from './OverviewPieChartTooltip';
import { overviewPieChartStyles } from './styles';

const useStyles = makeStyles(overviewPieChartStyles);

const OverviewPieChart = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const theme = useTheme();
  const { nodeColors } = theme;

  const {
    data,
    loading,
  } = props;
  const filteredData = R.reject((eachData) => {
    const { value } = eachData;
    if (!isValid(value) || toNumber(value) === 0) {
      return true;
    }

    return false;
  }, data);

  const defaultData = [
    {
      id: t('No Balance'),
      label: t('No Balance'),
      value: '100',
      color: nodeColors.noBalance,
      metadata: {
        balance: '¥0',
        staked: '¥0',
        earning: '¥0',
        isNoBalance: true,
      },
    },
  ];

  const dataToUse = filteredData.length ? filteredData : defaultData;

  const getChartContent = (data) => {
    if (loading) {
      return (
        <Box className={classes.loadingPieContainer}>
          <Skeleton
            variant="circle"
            className={classes.loadingPie}
          />
        </Box>
      );
    }

    return (
      <Box className={classes.chart}>
        <ResponsivePie
          data={data}
          margin={{
            top: 15, right: 15, bottom: 15, left: 25,
          }}
          innerRadius={0.7}
          padAngle={1}
          cornerRadius={0.5}
          activeOuterRadiusOffset={8}
          borderColor={{ from: 'color', modifiers: [['darker', 0.2]] }}
          enableArcLinkLabels={false}
          arcLinkLabelsThickness={1}
          arcLinkLabelsDiagonalLength={8}
          arcLinkLabelsStraightLength={16}
          arcLinkLabelsColor={{ from: 'color' }}
          arcLinkLabel={(data) => `${t(data.id)}: ${data.value}%`}
          enableArcLabels={false}
          tooltip={OverviewPieChartTooltip}
          colors={{ datum: 'data.color' }}
        />
      </Box>
    );
  };

  const getLegendsContent = (data) => {
    if (loading) {
      return (
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Skeleton
              variant="text"
              height={25}
              className={classes.loadingLegend}
            />
          </Grid>
          <Grid item xs={12}>
            <Skeleton
              variant="text"
              height={25}
              className={classes.loadingLegend}
            />
          </Grid>
          <Grid item xs={12}>
            <Skeleton
              variant="text"
              height={25}
              className={classes.loadingLegend}
            />
          </Grid>
        </Grid>
      );
    }

    return (
      <Box className={classes.legends}>
        <Grid container spacing={3}>
          {R.map((eachData) => {
            const {
              label,
              value,
              color,
              metadata: {
                isNoBalance,
              },
            } = eachData;

            const valueToUse = value ? toNumber(value) : 0;
            const valueToDisplay = valueToUse < 0.001 ? '< 0.001%' : `${valueToUse.toFixed(3)}%`;

            return (
              <Grid item xs={12} key={label}>
                <Box display="flex" justifyContent="space-between" alignItems="center">
                  <Box mr={1}>
                    <Box display="flex" alignItems="center">
                      <Box mr={1} className={classes.legendSymbol} style={{ backgroundColor: color }} />
                      <Box>
                        <Typography className={classes.legendText}>
                          {label}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                  {!isNoBalance && (
                  <Box>
                    <Typography className={classes.legendText}>
                      {valueToDisplay}
                    </Typography>
                  </Box>
                  )}
                </Box>
              </Grid>
            );
          })(data)}
        </Grid>
      </Box>
    );
  };

  return (
    <Grid container spacing={1} className={classes.centerComponent}>
      <Grid item xs={6} sm={8} className={classes.chartContainer}>
        {getChartContent(dataToUse)}
      </Grid>
      <Grid item xs={6} sm={4}>
        {getLegendsContent(dataToUse)}
      </Grid>
    </Grid>
  );
};

OverviewPieChart.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      label: PropTypes.string,
      value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
      ]),
      color: PropTypes.string,
      metadata: PropTypes.shape({
        balance: PropTypes.string,
        staked: PropTypes.string,
        earning: PropTypes.string,
      }),
    }),
  ).isRequired,
  /**
   * Disable on click function
   */
  loading: PropTypes.bool,
};

OverviewPieChart.defaultProps = {
  loading: false,
};

export default React.memo(OverviewPieChart);
