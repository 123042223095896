import { makeStyles } from '@material-ui/core/styles';
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import React from 'react';

import { styles } from './styles';

const useStyles = makeStyles(styles);
const ChainIcon = (props) => {
  const classes = useStyles();
  const {
    image,
    title,
    className,
  } = props;
  const classNameToUse = isEmpty(className) ? classes.chainIcon : className;
  if (!image) {
    return null;
  }
  return (
    <img
      src={image}
      alt={title}
      className={classNameToUse}
    />
  );
};
ChainIcon.propTypes = {
  /**
   * URL of the protocol icon image
   */
  image: PropTypes.string,
  /**
   * Protocol name
   */
  title: PropTypes.string,
  /**
   * custom className
   */
  className: PropTypes.string,
};
ChainIcon.defaultProps = {
  className: '',
  title: '',
  image: '',
};
export default ChainIcon;
