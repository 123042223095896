import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

import CustomTabs from '../Tabs/CustomTabs';

import BUSD from './BUSD/BUSD';
import ETH2Validator from './ETH2Validator/ETH2Validator';
import InvestmentContent from './InvestmentContent';
import Mining4Core from './Mining/Mining4Core';
// import Mining from './Mining/Mining';
import Mining6Core from './Mining/Mining6Core';
import Neunode from './Neunode/Neunode';
import NeunodeETH2 from './NeunodeETH2/NeunodeETH2';
import UnUniFiValidator from './UnUniFiValidator/UnUniFiValidator';

const mapURLToService = {
  neunode: <Neunode />,
  busd: <BUSD />,
  mining: <Mining6Core />,
  'mining-4core': <Mining4Core />,
  'eth2-validator': <ETH2Validator />,
  'ununifi-validator': <UnUniFiValidator />,
  'neunode-eth2': <NeunodeETH2 />,
};

const Investment = (props) => {
  const { t } = useTranslation();

  const {
    match,
  } = props;
  const { params } = match;
  const { service } = params;
  const content = mapURLToService[service];

  const tabs = [
    {
      label: t('Investment'),
      content: <InvestmentContent />,
      mainTab: true,
    },
  ];

  return (
    content || (
    <CustomTabs
      tabs={tabs}
    />
    )
  );
};

Investment.defaultProps = {
  match: {
    params: {
      service: '',
    },
  },
};

Investment.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      service: PropTypes.string,
    }),
  }),
};

export default Investment;
