import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';

// store
import { useAddFunctionToRef } from '../../hooks/hooks';
import { HttpStatusCodes } from '../../modules/appConstants';
import { errorOperations, errorServices } from '../../store/error/index';

const ErrorAlert = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const errorToRender = useSelector((state) => state.errorR.errorToRender);
  const { statusCode } = errorToRender || {};
  const onClearErrorToRender = useAddFunctionToRef(() => (
    dispatch(errorOperations.clearErrorToRender())
  ));

  /* const onCloseErrorAlert = () => (
    dispatch(errorOperations.clearErrorToRender())
  ); */

  // To clear error to render on page reload. With this if there is a error
  // alert it will disappear on page reload.
  useEffect(() => {
    onClearErrorToRender();
  }, [onClearErrorToRender]);

  const errorMessageToShow = {
    [HttpStatusCodes.SERVICE_UNAVAILABLE]: t('Service is currently unavailable. Please try again later.'),
    [HttpStatusCodes.GRAPHQL_VALIDATION_FAILED]: t('Whoops! Something went wrong here.'),
  };

  const isError = errorServices.shouldRenderErrorAlert(errorToRender);

  const showError = useAddFunctionToRef((error) => {
    toast.error(error, {
      toastId: error,
      position: 'top-right',
      autoClose: 10000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
    onClearErrorToRender();
  });

  useEffect(() => {
    if (isError) {
      showError(errorMessageToShow[statusCode]);
    }
  }, [errorMessageToShow, isError, showError, statusCode]);

  return null;
};

ErrorAlert.propTypes = {
};

export default ErrorAlert;
