export const amountCardStyles = (theme) => {
  const h3Properties = {
    fontSize: theme.typography.h3.fontSize,
    fontWeight: theme.typography.h3.fontWeight,
  };
  return ({
    container: {
      padding: theme.spacing(0, 2, 1, 2),
      minWidth: '100px',
      ...theme.centerComponent,
    },
    dataWithCircleBg: {
      color: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
    },
    dataWithCircle: {
      strokeLinecap: 'round',
      color: theme.nodeStatus.active,
    },
    percent: {
      ...h3Properties,
    },
    totalBalance: {
      fontSize: theme.typography.h1.fontSize,
      fontWeight: theme.typography.h1.fontWeight,
    },
    totalEarning: {
      fontSize: 20,
      fontWeight: theme.typography.h3.fontWeight,
    },
    overviewText: {
      ...h3Properties,
    },
    percentTextAsc: {
      ...h3Properties,
      color: theme.palette.green.main,
    },
    percentTextDesc: {
      ...h3Properties,
      color: theme.palette.error.main,
    },
    stack2Top: {
      paddingBottom: theme.spacing(0.5),
      [theme.breakpoints.down('xs')]: {
        padding: theme.spacing(0, 0.5, 0, 0),
      },
    },
    stack2Bottom: {
      paddingTop: 4,
      [theme.breakpoints.down('xs')]: {
        padding: '0px 0px 0px 4px',
      },
    },
    amountCard: {
      height: '100%',
      margin: 0,
      padding: theme.spacing(4),
      minWidth: 280,
    },
    dividerContainer: {
    },
    divider: {
      backgroundColor: '#FFFFFF',
      width: 0.5,
      opacity: 0.5,
    },
    balance: {
      paddingBottom: theme.spacing(4),
    },
  });
};

export const amountCardItemStyles = (theme) => ({
  haveExchangedValue: {
    paddingRight: theme.spacing(3),
  },
  exchangedText: {
    marginBottom: theme.spacing(1),
  },
});
