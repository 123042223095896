import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { Field } from 'redux-form';

import { RenderField } from '../ReduxFormCustom/ReduxFormCustom';

import { styles } from './styles';

const useStyles = makeStyles(styles);

const FieldGridItem = ({
  name, value, editMode, inputFieldName, inputFieldLabel,
}) => {
  const classes = useStyles();
  if (!editMode) {
    return (
      <Grid container item xs>
        <Grid item xs={4}>
          <Typography className={classNames(classes.cardTitle)}>{`${name}:`}</Typography>
        </Grid>
        <Grid item xs={8}>
          <Typography>{value}</Typography>
        </Grid>
      </Grid>
    );
  }

  return (
    <Grid container item spacing={1}>
      <Grid item xs={12} sm={4}>
        <Typography className={classNames(classes.fieldNameEdit)}>{`${name}:`}</Typography>
      </Grid>
      <Grid item xs={12} sm={8}>
        <Field
          name={inputFieldName}
          type="text"
          component={RenderField}
          label={inputFieldLabel}
          className={classNames(classes.textField)}
        />
      </Grid>
    </Grid>
  );
};

FieldGridItem.defaultProps = {
  value: '',
  editMode: false,
  inputFieldName: '',
  inputFieldLabel: '',
};

FieldGridItem.propTypes = {
  /**
   * Name of field
   */
  name: PropTypes.string.isRequired,
  /**
   * Value of field
   */
  value: PropTypes.string,
  /**
   * Edit mode
   */
  editMode: PropTypes.bool,
  /**
   * Name of input field in edit mode
   */
  inputFieldName: PropTypes.string,
  /**
   * Label of input field in edit mode
   */
  inputFieldLabel: PropTypes.string,
};

export default FieldGridItem;
