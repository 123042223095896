import whyDidYouRender from '@welldone-software/why-did-you-render';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import Connector from '../../ConnectToWallet/Connector';
import ConnectorWrapper from '../../ConnectToWallet/ConnectorWrapper';
import { accountsPropType } from '../propTypes';

import ConnectWalletExtension from './ConnectWalletExtension';

import useAddFunctionToRef from 'hooks/useAddFunctionToRef';
import { web3Operations } from 'store/web3';

if (process.env.REACT_APP_ENV !== 'prod') {
  whyDidYouRender(React);
}

// const accountTypes = {
//   connect: 'injectedAccounts',
//   generate: 'generatedAccounts',
// };
const ConnectWalletMethods = ({
  enableWeb3Loading,
  isExtensionsInjected,
  accounts,
  onCancel,
  // setAccountType,
  openAddressCreation,
  chain,
  ss58Format,
  setSelectedMethod,
  onConfirm,
  walletConnectMethod,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  // const [walletConnectMethod, setMethod] = useState('');
  const onSetMethod = R.curry((walletConnectMethod) => {
    // setWalletConnectMethod(walletConnectMethod);
    setSelectedMethod(walletConnectMethod);
    // const accountType = accountTypes[walletConnectMethod];
    // setAccountType(accountType);
  });
  const onEnableWeb3 = useAddFunctionToRef(async (chain, ss58Format) => {
    dispatch(await web3Operations.enableWeb3(chain, { ss58Format }));
  });
  const onClickSelectConnect = (chain, ss58Format) => {
    onSetMethod('connect');
    onEnableWeb3(chain, ss58Format);
  };
  const handleOnCancel = useAddFunctionToRef(() => {
    onSetMethod('');
    onCancel();
  });

  const createCryptoAddressLoading = useSelector(
    (state) => state.loadingR.createCryptoAddressLoading,
  );
  useEffect(() => {
    if (!isExtensionsInjected && walletConnectMethod === 'connect') {
      onEnableWeb3(chain, ss58Format);
    }
  }, [
    chain,
    isExtensionsInjected,
    onEnableWeb3,
    ss58Format,
    walletConnectMethod,
  ]);
  const Methods = {
    connect: (
      <ConnectWalletExtension
        enableWeb3Loading={enableWeb3Loading}
        isExtensionsInjected={isExtensionsInjected}
        accounts={accounts}
        openAddressCreation={openAddressCreation}
        chain={chain}
        ss58Format={ss58Format}
        handleOnCancel={handleOnCancel}
        onConfirm={onConfirm}
        createCryptoAddressLoading={createCryptoAddressLoading}
      />
    ),
    // generate: (
    //   <MiningAddressCreationGenerate
    //     onSetMethod={onSetMethod}
    //     handleOnCancel={handleOnCancel}
    //     resetAllChildren={resetAllChildren}
    //     onConfirm={onConfirm}
    //     userId={userId}
    //     createCryptoAddressLoading={createCryptoAddressLoading}
    //   />
    // ),
  };
  const getMethodsComponents = R.curry((Methods, walletConnectMethod) => {
    const Method = Methods[walletConnectMethod];
    if (!Method) throw new Error('Address creation walletConnectMethod not found');
    return Method;
  });
  const methodOptions = [
    // {
    //   connectorName: t('Generate wallet (recommended)'),
    //   connectorImgSrc: '/img/neukind-logo-dark-circle.png',
    //   onClick: () => onSetMethod('generate'),
    // },
    {
      connectorName: t('Connect Polkadot{.js} exenstion'),
      connectorImgSrc: '/img/icon/polkadot-js.png',
      onClick: () => onClickSelectConnect(chain, ss58Format),
    },
  ];
  const getMethodOptions = (methodOptions) => R.map(
    (walletConnectMethod) => {
      const { connectorName, connectorImgSrc, onClick } = walletConnectMethod;
      return (
        <Connector
          onClick={onClick}
          connectorName={connectorName}
          connectorImgSrc={connectorImgSrc}
          key={connectorImgSrc}
        />
      );
    },
  )(methodOptions);

  return !walletConnectMethod
    ? (
      <ConnectorWrapper>
        {getMethodOptions(methodOptions)}
      </ConnectorWrapper>
    )
    : (
      getMethodsComponents(Methods, walletConnectMethod)
    );
};

ConnectWalletMethods.propTypes = {
  enableWeb3Loading: PropTypes.bool,
  isExtensionsInjected: PropTypes.bool.isRequired,
  accounts: accountsPropType,
  openAddressCreation: PropTypes.bool.isRequired,
  chain: PropTypes.string.isRequired,
  ss58Format: PropTypes.number.isRequired,
  onCancel: PropTypes.func.isRequired,
  walletConnectMethod: PropTypes.string.isRequired,
  onConfirm: PropTypes.func.isRequired,
  setSelectedMethod: PropTypes.func.isRequired,
};
ConnectWalletMethods.defaultProps = {
  accounts: [],
  enableWeb3Loading: undefined,
};

ConnectWalletMethods.whyDidYouRender = true;
export default React.memo(ConnectWalletMethods);
