// query
import {
  createQuery,
  createMutation,
} from '../../../modules/apollo/apollo';

import {
  createUserStripePaymentMethodMutation,
  detachStripePaymentMethodMutation,
  cancelSubscriptionMutation,
  changeSubscriptionPlanMutation,
} from './mutations';
import {
  getUserStripeData,
  getUserStripePaymentMethods,
  validatePromotionCode,
} from './queries';
// mutations
// apollo

export const graphqlGetUserStripeData = createQuery(getUserStripeData);
export const graphqlGetUserStripePaymentMethods = createQuery(getUserStripePaymentMethods);
export const graphqlValidatePromotionCode = createQuery(validatePromotionCode);

export const graphqlCreateUserStripePaymentMethod = createMutation(
  createUserStripePaymentMethodMutation,
);
export const graphqlDetachStripePaymentMethodMutation = createMutation(
  detachStripePaymentMethodMutation,
);
export const graphqlCancelSubscriptionMutation = createMutation(
  cancelSubscriptionMutation,
);
export const graphqlChangeSubscriptionPlanMutation = createMutation(
  changeSubscriptionPlanMutation,
);
