import grey from '@material-ui/core/colors/grey';

export const styles = (theme) => ({
  title: {
    width: '100%',
    paddingLeft: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  messageField: {
    width: '100% !important',
    minHeight: 100,
    padding: '18.5px 14px', // default material ui textfield padding
    fontSize: 16,
    ...theme.font.content,
    ...theme.border.default,
    '&:hover': {
      borderColor: theme.customColor.almostBlack,
    },
    '&:focus': {
      borderColor: theme.palette.primary.main,
      borderWidth: 2,
    },
  },
  requiredSymbol: {
    fontWeight: 600,
  },
  conntactFormTextField: {
    paddingBottom: theme.spacing(2),
  },
  successIcon: {
    color: theme.palette.success.main,
    fontSize: '5rem',
    ...theme.centerComponent,
  },
  primaryText: {
    paddingTop: theme.spacing(2),
    color: grey[500],
  },
  secondaryText: {
    color: grey[400],
    paddingBottom: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
  },
  successPage: {
    paddingBottom: theme.spacing(7),
  },
});
