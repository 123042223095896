import { Box } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import React, { useState } from 'react';

import WalletNewAddress from './WalletNewAddress';
import WalletSelectAccounts from './WalletSelectAccounts';
import { accountPropType, accountsPropType } from './propTypes';

import { RoundedButton } from 'components/MaterialUiCustom/MaterialUiCustom';

// const subChain = 'pha';
const WalletTransferBoardRecipient = ({
  t,
  accounts,
  clearRecipient,
  resetFieldState,
  selectedAccount,
}) => {
  const [addRecipentMethod, setAddRecipientMethod] = useState('fromInjectedAddress');
  const handleSetAddRecipientMethod = (addRecipentMethod, currentAddRecipientMethod) => {
    if (currentAddRecipientMethod === addRecipentMethod) return;
    setAddRecipientMethod(addRecipentMethod);
    clearRecipient();
    resetFieldState('recipient');
  };

  const methods = {
    fromInjectedAddress: () => {
      const { address } = selectedAccount || {};

      const accountsWithoutSelectedAccount = R.filter((account) => !R.propEq('address', address, account), accounts);

      return <WalletSelectAccounts t={t} accounts={accountsWithoutSelectedAccount} />;
    },
    newAddress: () => <WalletNewAddress t={t} />,
  };
  const createGetAddRecipientMethod = R.curry((methods, addRecipentMethod) => {
    const method = methods[addRecipentMethod] || methods.fromInjectedAddress;
    return method();
  });
  const getAddRecipientMethod = createGetAddRecipientMethod(methods);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Box display="flex" flexDirection="row">
          <Box>
            <RoundedButton
              variant="text"
              color={addRecipentMethod === 'fromInjectedAddress' ? 'primary' : 'secondary'}
              onClick={() => handleSetAddRecipientMethod('fromInjectedAddress', addRecipentMethod)}
            >
              {t('Polkadot{.js} extenstion address')}

            </RoundedButton>
          </Box>
          <Box>
            <RoundedButton
              variant="text"
              color={addRecipentMethod === 'newAddress' ? 'primary' : 'secondary'}
              onClick={() => handleSetAddRecipientMethod('newAddress', addRecipentMethod)}
            >
              {t('new address')}

            </RoundedButton>
          </Box>
        </Box>
      </Grid>

      <Grid item xs={12}>
        {getAddRecipientMethod(addRecipentMethod)}
      </Grid>
    </Grid>

  );
};

WalletTransferBoardRecipient.propTypes = {
  accounts: accountsPropType,
  clearRecipient: PropTypes.func.isRequired,
  resetFieldState: PropTypes.func.isRequired,
  selectedAccount: accountPropType.isRequired,
  t: PropTypes.func.isRequired,
};
WalletTransferBoardRecipient.defaultProps = {
  accounts: [],
};
export default WalletTransferBoardRecipient;
