import * as R from 'ramda';
import Web3 from 'web3';

import { errorOperations } from '../error/index';
import { loadingOperations } from '../loading/index';
import { web3Services } from '../web3/index';

import * as actions from './actions';
import {
  txValue,
  contractAbis,
  contractAddress,
  TransactionStatus,
} from './constants';

export const { extractDepositKeys } = actions;

const { REACT_APP_ENV } = process.env;

// Credit: https://github.com/ethereum/eth2.0-deposit
export const createBroadcastDepositTransactions = R.curry(
  async (
    contractAbis,
    contractAddress,
    txValue,
    TransactionStatus,
    connector,
    account,
    dispatch,
    getState,
  ) => {
    try {
      dispatch(loadingOperations.setLoading('broadcastDepositTransactions', true));
      dispatch(errorOperations.clearError('broadcastDepositTransactions'));

      const { depositKeys } = getState().depositR.depositData;
      const walletProvider = await connector.getProvider();
      const web3 = new Web3(walletProvider);
      const networkId = await web3.eth.net.getId();
      const contractAbi = contractAbis[networkId];
      const contract = new web3.eth.Contract(contractAbi, contractAddress);

      const gasLimitMainnet = web3Services.handleSanitizeHex(80000);
      // Goerli testnet sometime require higher gas limit
      const gasLimitTestnet = web3Services.handleSanitizeHex(100000);
      const gasLimit = REACT_APP_ENV !== 'prod'
        ? gasLimitTestnet
        : gasLimitMainnet;

      const transactionParameters = {
        gasLimit,
        gasPrice: web3.utils.toHex(await web3.eth.getGasPrice()),
        from: account,
        value: txValue,
      };
      const isNextTransction = R.pipe(
        R.prop('transactionStatus'),
        R.flip(R.includes)([TransactionStatus.READY, TransactionStatus.REJECTED, TransactionStatus.FAILED]),
      );

      const nextTransaction = R.pipe(
        R.filter(
          isNextTransction,
        ),
        R.head,
      )(depositKeys);

      const {
        pubkey,
        withdrawal_credentials: withdrawalCredentials,
        signature,
        deposit_data_root: depositDataRoot,
      } = nextTransaction || {};

      dispatch(
        actions.updateTransactionStatus(
          pubkey,
          TransactionStatus.PENDING,
          null,
        ),
      );
      if (nextTransaction === undefined) {
        return dispatch(actions.depositSuccess(depositKeys));
      }

      const depositListener = contract.methods
        .deposit(
          web3Services.prefix0X(pubkey),
          web3Services.prefix0X(withdrawalCredentials),
          web3Services.prefix0X(signature),
          web3Services.prefix0X(depositDataRoot),
        )
        .send(transactionParameters)
        .on('transactionHash', (txHash) => {
          dispatch(
            actions.updateTransactionStatus(
              pubkey,
              TransactionStatus.STARTED,
              txHash,
            ),
          );
          return dispatch(
            createBroadcastDepositTransactions(
              contractAbis,
              contractAddress,
              txValue,
              TransactionStatus,
              connector,
              account,
            ),
          );
        })
        .on('receipt', (receipt) => {
          // TODO figure out what to do here
        })
        .on(
          'confirmation',
          async (confirmation, receipt) => {
            if (confirmation >= 0) {
              const { transactionHash } = receipt;
              if (receipt.status) {
                dispatch(
                  actions.updateTransactionStatus(
                    pubkey,
                    TransactionStatus.SUCCEEDED,
                    transactionHash,
                  ),
                );
                dispatch(loadingOperations.setLoading('broadcastDepositTransactions', false));
                return depositListener.off('confirmation');
              }
              dispatch(
                actions.updateTransactionStatus(
                  pubkey,
                  TransactionStatus.FAILED,
                  transactionHash,
                ),
              );
              dispatch(loadingOperations.setLoading('broadcastDepositTransactions', false));
              return depositListener.off('confirmation');
            }
          },
        )
        .on('error', (error) => {
          if (web3Services.isUserRejectionError(error)) {
            // when there are above 2 key we will let the loading true to
            // wait until user confirms all tx on metamask
            if (depositKeys.length < 2) {
              dispatch(loadingOperations.setLoading('broadcastDepositTransactions', false));
            }
            return dispatch(
              actions.updateTransactionStatus(
                pubkey,
                TransactionStatus.REJECTED,
                null,
              ),
            );
          }
          dispatch(
            actions.updateTransactionStatus(
              pubkey,
              TransactionStatus.FAILED,
              null,
            ),
          );
          dispatch(loadingOperations.setLoading('broadcastDepositTransactions', false));
          error.custom = true;
          return dispatch(errorOperations.setError(
            'broadcastDepositTransactions',
            { error, fallbackErrorMessage: 'Broadcast deposit transaction failed: interact with contract failed' },
          ));
        });
      await depositListener;
    } catch (error) {
      dispatch(loadingOperations.setLoading('broadcastDepositTransactions', false));
      error.custom = true;
      dispatch(errorOperations.setError(
        'broadcastDepositTransactions',
        { error, fallbackErrorMessage: 'Broadcast deposit transaction failed' },
      ));
    }
  },
);

export const broadcastDepositTransactions = createBroadcastDepositTransactions(
  contractAbis,
  contractAddress,
  txValue,
  TransactionStatus,
);

export const { resetDeposit } = actions;

export const createDeposit = R.curry(
  async (
    userId,
    steps,
    depositData,
    dispatch,
  ) => {
    try {
      dispatch(loadingOperations.setLoading('createDeposit', true));
      const { type, payload } = dispatch(
        await actions.createDeposit(
          userId,
          steps,
          depositData,
        ),
      );
      dispatch(loadingOperations.setLoading('createDeposit', false));
      dispatch(errorOperations.clearError('createDeposit'));
      return { type, payload };
    } catch (error) {
      dispatch(loadingOperations.setLoading('createDeposit', false));
      return dispatch(errorOperations.setError(
        'createDeposit',
        { error, fallbackErrorMessage: 'Create deposit failed' },
      ));
    }
  },
);

export const { insertNodeData } = actions;
