export const tableStyles = (theme) => ({
  header: {
    borderBottom: `1px solid ${theme.borderColor.default}`,
  },
  headerText: {
    fontWeight: 600,
  },
  root: {
    [theme.breakpoints.down('xs')]: {
      maxWidth: window.innerWidth * 0.9,
      overflow: 'auto',
    },
    overflow: 'scroll',

  },
  expandContent: {
    width: '100%',
    borderTop: `1px solid ${theme.borderColor.default}`,
    borderBottom: `1px solid ${theme.borderColor.default}`,
    backgroundColor: '#F4F5F9',
  },
});

export const tableActionStyles = (theme) => ({
  customTooltip: {
    backgroundColor: '#f9f9f9',
    color: 'rgba(0, 0, 0, 0.87)',
    fontSize: theme.typography.pxToRem(12),
    boxShadow: theme.shadows[3],
  },
  customArrow: {
    color: '#f9f9f9',
  },
  menuButton: {
    width: '100%',
  },
});
