import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  reduxForm,
} from 'redux-form';

import { useAddFunctionToRef, useGetQuery, useUserDataSelector } from '../../hooks/hooks';
import {
  marketRoute,
  notFoundPageRoute,
  signinRoute,
  adminDashboardRoute,
} from '../../routes';
import { navigationOperations } from '../../store/navigation/index';
import { routerServices } from '../../store/router/index';
import { persistor } from '../../store/store';
import { userOperations } from '../../store/user/index';
import {
  ACTIVATE_SUCCESS,
  SIGNIN_SUCCESS,
  VERIFY_PASSWORD_SUCCESS,
  GET_ACTIVATION,
} from '../../store/user/types';
import ErrorMessage from '../ErrorMessage/ErrorMessage';
import { RoundedButton } from '../MaterialUiCustom/MaterialUiCustom';
import PasswordField from '../PasswordField/PasswordField';

import { styles } from './styles';

const useStyles = makeStyles(styles);

const Activation = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const history = useHistory();
  const queryLng = useGetQuery('lng');
  const { match } = props;

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [activated, setActivated] = useState(false);
  const [redirectToSignin, setRedirectToSignin] = useState(false);

  const { handleSubmit } = props;
  const { submitting, pristine, invalid } = props;

  const [errorMessage, setErrorMessage] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [disableButton, setDisableButton] = useState(false);
  const { userId, role } = useUserDataSelector();

  const handleClickShowPassword = () => setShowPassword(!showPassword);

  const onActivateUser = useAddFunctionToRef(async (password) => {
    const activateUserResponse = await dispatch(userOperations.activateUser(match.params.activationId));
    if (activateUserResponse.type === ACTIVATE_SUCCESS) {
      toast.success(t('Your email address has been successfully verified'), {
        position: 'top-right',
        autoClose: 10000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      const signinResponse = await dispatch(userOperations.signin(
        { t },
        activateUserResponse.payload.user.email,
        password,
        'user',
      ));
      if (signinResponse.type === SIGNIN_SUCCESS) {
        setActivated(true);
      } else {
        setRedirectToSignin(true);
      }
    } else {
      setErrorMessage(activateUserResponse.payload.value.message);
      setDisableButton(false);
    }
  });

  const handleVerifyPassword = async (formValues) => {
    setDisableButton(true);
    setErrorMessage('');
    const { password } = formValues;
    const response = await dispatch(userOperations.validatePassword(
      { activationId: match.params.activationId, password },
    ));
    const errorMessage = R.view(R.lensPath(['payload', 'value', 'error', 'message']), response);
    if (response.type === VERIFY_PASSWORD_SUCCESS) {
      onActivateUser(password);
    } else {
      if (errorMessage) {
        // If error is Service Unavailable or GraphQL Validation Failed error then error object
        // will be different. And so in that case 'errorMessage' will have falsy value. And for
        // Service Unavailable or GraphQL Validation Failed error {@link ErrorAlert} will be
        // displayed only. For these errors we must not show the following error message.
        setErrorMessage(t('Wrong password'));
      }
      setDisableButton(false);
    }
  };

  const onSignout = useAddFunctionToRef(
    async () => {
      await dispatch(userOperations.signOut(userId));
      await persistor.purge();
    // await persistor.flush();
    // await persistor.pause();
    },
  );

  const onGetActivation = useAddFunctionToRef(async (activationId) => {
    setLoading(true);
    const response = await dispatch(userOperations.getActivation(
      activationId,
    ));

    if (response.type !== GET_ACTIVATION) {
      setError(true);
    }
    setLoading(false);
    onSignout();
  });

  const getContent = () => {
    if (loading) {
      return (
        <Box className={classnames(classes.form)}>
          <CircularProgress />
        </Box>
      );
    }

    return (
      <form onSubmit={handleSubmit(handleVerifyPassword)} className={classnames(classes.form)}>
        <Grid
          container
        >
          <Grid item xs={12}>
            <Typography
              variant="h2"
              align="center"
              color="secondary"
              className={classes.title}
            >
              {t('Enter password to continue')}
            </Typography>
            <ErrorMessage errorMessage={errorMessage} align="center" />
          </Grid>
          <Grid item xs={12}>
            <PasswordField
              handleClickShowPassword={handleClickShowPassword}
              showPassword={showPassword}
            />
          </Grid>
          <Grid item xs={12}>
            <RoundedButton
              variant="contained"
              color="primary"
              type="submit"
              disabled={submitting || pristine || invalid || disableButton}
              className={classes.button}
              noMargin
            >
              {t('Confirm')}
            </RoundedButton>
          </Grid>
        </Grid>
      </form>
    );
  };

  useEffect(() => {
    if (match.params.activationId) {
      onGetActivation(match.params.activationId);
    }
  }, [onGetActivation, match.params.activationId]);

  useEffect(() => {
    if (error) {
      return routerServices.historyPush(
        history,
        {
          pathname: notFoundPageRoute,
          queryLng,
        },
      );
    }
  }, [error, queryLng, history]);

  useEffect(() => {
    if (redirectToSignin) {
      return routerServices.historyPush(
        history,
        {
          pathname: signinRoute,
          queryLng,
        },
      );
    }
  }, [redirectToSignin, queryLng, history]);

  useEffect(() => {
    if (activated) {
      const sideBarItemIndex = role === 'ADMIN' ? 0 : 1;
      const redirectRoute = role === 'ADMIN' ? adminDashboardRoute : marketRoute;
      dispatch(navigationOperations.selectSidebarItem(sideBarItemIndex));
      return routerServices.historyPush(
        history,
        {
          pathname: redirectRoute,
          queryLng,
        },
      );
    }
  }, [dispatch, activated, role, queryLng, history]);

  return (
    <Box className={classnames(classes.paper)}>
      {getContent()}
    </Box>
  );
};

Activation.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      activationId: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  submitting: PropTypes.bool.isRequired,
  pristine: PropTypes.bool.isRequired,
  invalid: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
};

export default reduxForm({
  form: 'activation',
})(Activation);
