export const INITIALIZE_WALLET_CONNECT = 'INITIALIZE_WALLET_CONNECT';

export const WALLET_CONNECT_SUBSCRIBE_TO_EVENTS = 'WALLET_CONNECT_SUBSCRIBE_TO_EVENTS';

export const ON_WALLET_CONNECT_CONNECT = 'ON_WALLET_CONNECT_CONNECT';

export const ON_WALLET_CONNECT_DISCONECT = 'ON_WALLET_CONNECT_DISCONECT';

export const ON_WALLET_CONNECT_UPDATE = 'ON_WALLET_CONNECT_UPDATE';

export const WALLET_CONNECT_SUBSCRIBE_TO_CANCEL = 'WALLET_CONNECT_SUBSCRIBE_TO_CANCEL';

export const GET_WALLET_CONNECT_ACCOUNT_ASSETS = 'GET_WALLET_CONNECT_ACCOUNT_ASSETS';

export const GET_WALLET_CONNECT_ACCOUNT_NONCE = 'GET_WALLET_CONNECT_ACCOUNT_NONCE';

export const KILL_WALLET_CONNECT_SESSION = 'KILL_WALLET_CONNECT_SESSION';

export const SEND_TRANSACTION_WALLET_CONNECT_SUCCESS = 'SEND_TRANSACTION_WALLET_CONNECT_SUCCESS';

export const UPDATE_DEPOSIT_TRANSACTION_DATA = 'UPDATE_DEPOSIT_TRANSACTION_DATA';

export const UPDATE_WALLET_CONNECTOR_DATA = 'UPDATE_WALLET_CONNECTOR_DATA';

export const RESET_DEPOSIT_TRANSACTION_DATA = 'RESET_DEPOSIT_TRANSACTION_DATA';

export const GET_GAS_PRICE = 'GET_GAS_PRICE';
