import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/styles';
import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';

import ConnectWalletMethods from './ConnectWallet/ConnectWalletMethods';
import { accountsPropType } from './propTypes';
import { walletAccessStyles } from './styles';

const useStyles = makeStyles(walletAccessStyles);
const WalletAccess = ({
  t,
  onSetWalletConnectMethod,
  walletConnectMethod,
  onSetWalletSelectedAccount,
  chain,
  accounts,
  isExtensionsInjected,
  ss58Format,
}) => {
  // const [accountType, setAccountType] = useState('');
  const classes = useStyles();
  const enableWeb3Loading = useSelector((state) => state.loadingR.enableWeb3Loading);

  const onConfirm = (account) => {
    onSetWalletSelectedAccount(account);
  };

  return (
    <Grid container>
      <Grid item xs={12} className={classes.titleSection}>
        <Typography variant="h2">
          {t('Access your wallet')}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <ConnectWalletMethods
          openAddressCreation
          enableWeb3Loading={enableWeb3Loading}
          isExtensionsInjected={isExtensionsInjected}
          accounts={accounts}
          onCancel={() => { }}
          // setAccountType={setAccountType}
          autoConnect={false}
          chain={chain}
          ss58Format={ss58Format}
          setSelectedMethod={onSetWalletConnectMethod}
          onConfirm={onConfirm}
          walletConnectMethod={walletConnectMethod}
        />
      </Grid>
    </Grid>
  );
};

WalletAccess.propTypes = {
  t: PropTypes.func.isRequired,
  onSetWalletConnectMethod: PropTypes.func.isRequired,
  walletConnectMethod: PropTypes.string.isRequired,
  onSetWalletSelectedAccount: PropTypes.func.isRequired,
  chain: PropTypes.string.isRequired,
  accounts: accountsPropType,
  isExtensionsInjected: PropTypes.bool.isRequired,
  ss58Format: PropTypes.number.isRequired,
};

WalletAccess.defaultProps = {
  accounts: [],
};
export default WalletAccess;
