import * as types from './types';

// handleDrawer
export const openDrawer = () => ({
  type: types.OPEN_DRAWER,
  payload: {
    openDrawer: true,
  },
});
export const closeDrawer = () => ({
  type: types.CLOSE_DRAWER,
  payload: {
    openDrawer: false,
  },
});
