import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';

import { useUserDataSelector } from '../../hooks/hooks';
import { adminUtils } from '../../store/admin/index';
import NavigationAppBar from '../NavigationAppBar/NavigationAppBar';
import NavigationDrawer from '../NavigationDrawer/NavigationDrawer';

const Navigation = (props) => {
  const { isErrorPage } = props; // we will not show the sign in and sing up button in error page
  const {
    email,
    userId,
    role,
  } = useUserDataSelector();
  const authentication = useSelector((state) => state.userR.authentication);
  const { isAuthenticated } = authentication;

  const selectRoleToUse = (role) => {
    if (role === 'ADMIN') { // Check if authenticated user is an admin
      const {
        isImpersonating,
        impersonatedUser,
      } = adminUtils.getImpersonationDataFromSessionStorage();
      if (isImpersonating) {
        const { role: impersonatedUserRole } = impersonatedUser;
        return impersonatedUserRole;
      }
      return role;
    }
    return role;
  };
  const roleToUse = selectRoleToUse(role);

  return (
    <>
      <NavigationAppBar
        isAuthenticated={isAuthenticated}
        email={email}
        isErrorPage={isErrorPage}
      />
      {isAuthenticated && (
      <NavigationDrawer
        userId={userId}
        isAuthenticated={isAuthenticated}
        role={roleToUse}
        email={email}
      />
      )}
    </>
  );
};

Navigation.propTypes = {
  /**
   * Is it error page. We will not show sign in and sign up button in error page since
   * it causes some issues.
   * @type {Boolean}
   */
  isErrorPage: PropTypes.bool,
};

Navigation.defaultProps = {
  isErrorPage: false,
};

export default Navigation;
