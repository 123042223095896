import {
  pipeAwait,
} from 'jsutils';
import * as R from 'ramda';

import {
  graphqlNormalize,
} from '../../../modules/apollo/apollo';
import {
  returnAction,
} from '../../store_utils';

import {
  graphqlGetNodes,
  graphqlGetAdminTotalReport,
  graphqlUpdateUserNode,
} from './graphql/services';
import { normalizeNodeListData } from './services';
import * as types from './types';

export const getNodes = R.curry(
  async (query) => (
    pipeAwait(
      graphqlGetNodes,
      graphqlNormalize('getNodes'),
      normalizeNodeListData,
      returnAction(types.GET_NODES_SUCCESS),
    )({
      getNodesInput: query,
    })
  ),
);

export const getAdminTotalReport = R.curry(
  async (query) => pipeAwait(
    graphqlGetAdminTotalReport,
    graphqlNormalize('getAdminTotalReport'),
    returnAction(types.GET_ADMIN_MONTHLY_REPORT_SUCCESS),
  )({
    getAdminTotalReportInput: query,
  }),
);

export const sendMonthlyReportEmail = R.curry(
  async (data) => {
    const {
      usersEarnings,
      recipient,
    } = data;
    return pipeAwait(
      graphqlUpdateUserNode,
      graphqlNormalize('sendMonthlyReportEmail'),
      returnAction(types.SEND_MONTHLY_EARNING_REPORT_EMAIL_SUCCESS),
    )({
      sendMonthlyReportEmailInput: {
        usersEarnings,
        recipient,
      },
    });
  },
);

export const updateMonthlyReportEmailConfig = (data, testMode, testEmail, error) => ({
  type: types.UPDATE_MONTHLY_REPORT_EMAIL_CONFIG,
  payload: {
    data, testMode, testEmail, error,
  },
});
