import * as R from 'ramda';

import * as types from '../../types';

import * as services from './services';

export const enableWeb3 = R.curry(
  async ({ ss58Format }) => {
    const {
      accounts,
      isWeb3Injected,
      isExtensionsInjected,
    } = await services.enableWeb3(ss58Format);
    if (!isWeb3Injected) {
      return {
        type: types.ENABLE_WEB3,
        payload: {
          chain: 'dot',
          isWeb3Injected,
        },
      };
    }
    const activeChain = services.getChainNetworkOrName(ss58Format);
    return {
      type: types.ENABLE_WEB3,
      payload: {
        data: {
          activeChain,
          [activeChain]: {
            accounts,
          },
        },
        chain: 'dot',
        isWeb3Injected,
        isExtensionsInjected,
      },
    };
  },
);

export const onWeb3AccountsChange = R.curry(async ({ ss58Format, accounts }) => {
  const activeChain = services.getChainNetworkOrName(ss58Format);
  // encode address ss58Format
  const accountsToReturn = R.pipe(
    services.sortAccounts,
    services.convertAddresses(ss58Format),
  )(accounts);
  return {
    type: types.ON_WEB3_ACCOUNT_CHANGE,
    payload: {
      data: {
        activeChain,
        [activeChain]: {
          accounts: accountsToReturn,
        },
      },
      chain: 'dot',
    },
  };
});
