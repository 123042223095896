import { makeStyles } from '@material-ui/core/styles';
import isNull from 'lodash/isNull';
import moment from 'moment';
import * as R from 'ramda';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
// import { useHistory } from 'react-router-dom';

import { useAddFunctionToRef } from '../../../hooks/hooks';
// import { marketRoute } from '../../../routes';
import { nodeOperations } from '../../../store/node/index';
// import { routerServices } from '../../../store/router/index';
import { RoundedButton } from '../../MaterialUiCustom/MaterialUiCustom';
import CustomTabs from '../../Tabs/CustomTabs';

import UnUniFiValidatorContent from './UnUniFiValidatorContent';
import { ununifiValidatorStyles } from './styles';

const useStyles = makeStyles(ununifiValidatorStyles);

const { REACT_APP_ENV, REACT_APP_ENABLE_UNUNIFI_NODE_MIGRATION } = process.env;

const UnUniFiValidator = () => {
  const classes = useStyles();
  // const history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const nodeType = 'UNUNIFI_VALIDATOR_NODE';

  const {
    getUserNodesLoading,
  } = useSelector((state) => state.loadingR);
  const nodes = useSelector((state) => state.nodeR.userNodes.nodes);
  const { userId } = useSelector((state) => state.userR.user);
  const { userNodes } = useSelector((state) => state.nodeR);
  const { nodes: rawNodes } = userNodes;
  const nodesUnUniFiToUpdate = R.pipe(
    R.filter((node) => {
      const { nodeType, dateCreated } = node;
      // const accAddress = R.view(R.lensPath(['nodeInfo', 'accAddress']), node);
      const migrated = R.view(R.lensPath(['nodeInfo', 'migrated']), node);
      return nodeType === 'UNUNIFI_VALIDATOR_NODE' && ((moment(dateCreated).unix() < 1636588800 && !migrated));
    }),
    R.sort(R.ascend(R.prop('dateCreated'))),
  )(rawNodes);
  // const needUpdate = nodesUnUniFiToUpdate.length;

  // const oldestUnUniFiNodeCreatedDate = R.pipe(
  //   R.head,
  //   R.prop('dateCreated'),
  // )(nodesUnUniFiToUpdate || [{}]);
  // const hasOldNode = oldestUnUniFiNodeCreatedDate ? moment(oldestUnUniFiNodeCreatedDate).unix() < 1636588800 : false;

  const latestUnUniFiNodeCreatedDate = R.pipe(
    R.last,
    R.prop('dateCreated'),
  )(nodesUnUniFiToUpdate || [{}]);
  const hasYoungNode = latestUnUniFiNodeCreatedDate ? moment(latestUnUniFiNodeCreatedDate).unix() > 1636588800 : false;

  const onGetUserNodes = useAddFunctionToRef(async (userId) => {
    await dispatch(nodeOperations.getUserNodes(userId));
  });

  const onGetNodesEarning = useAddFunctionToRef(async (nodes) => {
    const publicKeyList = R.pipe(
      R.filter(R.propEq('nodeType', nodeType)),
      R.map(
        R.view(R.lensPath(['nodeInfo', 'accAddress'])),
      ),
      R.reject(isNull),
    )(nodes);

    if (R.length(publicKeyList) > 0) {
      await dispatch(nodeOperations.getTotalEarning(userId, publicKeyList, nodeType));
    }
  });

  useEffect(() => {
    onGetUserNodes(userId);
  }, [onGetUserNodes, userId]);

  useEffect(() => {
    onGetNodesEarning(nodes);
  }, [onGetNodesEarning, nodes]);

  const tabs = [
    {
      label: t('UnUniFi Validator'),
      content: <UnUniFiValidatorContent />,
      mainTab: true,
    },
  ];

  const redirectToUnUniFiDeployPage = () => {
    // routerServices.historyPush(
    //   history,
    //   {
    //     pathname: `${marketRoute}/ununifi/deploy`,
    //   },
    // );
    const baseUrl = REACT_APP_ENV !== 'prod'
      ? 'http://localhost:4200'
      : 'https://ununifi.io';

    const url = `${baseUrl}/portal/staking/create-validator/multiple?userId=${userId}`;
    if (window.open) {
      window.open(url);
    }
  };

  return (
    <>
      <CustomTabs
        tabs={tabs}
        extraComponent={((REACT_APP_ENABLE_UNUNIFI_NODE_MIGRATION === 'ON') || hasYoungNode) && (
          <RoundedButton
            className={classes.migrateButton}
            disabled={getUserNodesLoading || true}
            onClick={redirectToUnUniFiDeployPage}
          >
            {/* {hasOldNode ? t('migrate') : t('Connect wallet')} */}
            {/* {t('migrate')} */}

            create validator
          </RoundedButton>
        )}
      />
    </>
  );
};

export default UnUniFiValidator;
