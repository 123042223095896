import * as R from 'ramda';

import { errorOperations } from '../error/index';
import { loadingOperations } from '../loading/index';

import * as actions from './actions';

export const uploadFileToS3 = R.curry(
  async (
    nodeType,
    file,
    dispatch,
  ) => {
    try {
      dispatch(loadingOperations.setLoading('uploadFileToS3', true));

      const { type, payload } = dispatch(
        await actions.uploadFileToS3(
          nodeType,
          file,
        ),
      );
      dispatch(errorOperations.clearError('uploadFileToS3'));
      return { type, payload };
    } catch (error) {
      dispatch(loadingOperations.setLoading('uploadFileToS3', false));
      return dispatch(errorOperations.setError(
        'uploadFileToS3',
        { fallbackErrorMessage: 'upload file to s3 failed', error },
      ));
    }
  },
);
