// mutation
import {
  createQuery,
  createMutation,
} from '../../../modules/apollo/apollo';

import {
  createNodeProviderOrderMutation,
} from './mutation';
// query
import {
  getNodeProviderOrderQuery,
} from './queries';

// apollo

export const graphqlGetNodeProviderOrder = createQuery(getNodeProviderOrderQuery);

export const graphalCreateNodeProviderOrder = createMutation(createNodeProviderOrderMutation);
