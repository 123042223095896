import Box from '@material-ui/core/Box';
import Dialog from '@material-ui/core/Dialog';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import whyDidYouRender from '@welldone-software/why-did-you-render';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';

import { adminNodeOperations } from '../../../store/admin/node/index';
import { SEND_MONTHLY_EARNING_REPORT_EMAIL_SUCCESS } from '../../../store/admin/node/types';
import FailPage from '../../FailPage/FailPage';
import Alert from '../../MaterialUiCustom/Alert/Alert';
import { RoundedButton } from '../../MaterialUiCustom/MaterialUiCustom';
import SuccessPage from '../../SuccessPage/SuccessPage';
import ReactTableV7 from '../../Tables/ReactTableV7';
import CustomTabs from '../../Tabs/CustomTabs';

import AdminMonthlyEarningTable from './AdminMonthlyEarningTable';
import { monthlyReportConfirmColumns } from './columns';
import { adminEarningTableStyles } from './styles';

import { isInfluxdbError } from 'store/error/services';

if (process.env.REACT_APP_ENV !== 'prod') {
  whyDidYouRender(React);
}
const useStyles = makeStyles(adminEarningTableStyles);

const AdminEarningTable = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const getIntervalEarningError = useSelector((state) => state.errorR.getIntervalEarningError);
  const influxdbErrorExists = isInfluxdbError(getIntervalEarningError || {});

  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const handleConfirmDialogClose = () => {
    setOpenConfirmDialog(false);
  };
  const handleConfirmDialogOpen = () => {
    setOpenConfirmDialog(true);
  };

  const [statusDialog, setStatusDialog] = useState(false);
  const handleStatusDialogClose = () => {
    setStatusDialog('');
  };
  const handleStatusDialogOpen = (status) => {
    setStatusDialog(status);
  };

  const sendMonthlyReportEmailLoading = useSelector((state) => state.loadingR.sendMonthlyReportEmailLoading);
  const {
    data: monthlyReportEmailData, testMode, testEmail, error,
  } = useSelector((state) => state.adminR.adminNode.monthlyReportEmailConfig);
  const sendMontlyReportEmail = async (data, email) => {
    const { type } = await dispatch(adminNodeOperations.sendMonthlyReportEmail({ recipient: email, usersEarnings: data }));

    if (type === SEND_MONTHLY_EARNING_REPORT_EMAIL_SUCCESS) {
      handleConfirmDialogClose();
      handleStatusDialogOpen('success');
    } else {
      handleStatusDialogOpen('fail');
    }
  };
  const tabs = [
    {
      label: t('Monthly Earnings'),
      content: <AdminMonthlyEarningTable />,
    },
  ];

  const columnsToUse = monthlyReportConfirmColumns(t);

  const getConfirmDialogContent = (data) => (
    <>
      <Box className={classes.confirmDialog}>
        <ReactTableV7
          columns={columnsToUse}
          customPageSize={testMode ? 1 : data.length}
          data={testMode ? [{ email: testEmail }] : data}
          customPageSizeList={[testMode ? 1 : data.length]}
          disablePaging
        />
      </Box>
      <Divider className={classes.confirmTableDivider} />
      <Box className={classes.sendButtonContainer}>
        <Box>
          <RoundedButton
            variant="contained"
            color="primary"
            className={classes.sendButton}
            onClick={() => sendMontlyReportEmail(data, testMode ? testEmail : null)}
            loading={sendMonthlyReportEmailLoading}
          >
            {t('Send')}
          </RoundedButton>
        </Box>
        <Box>
          <RoundedButton
            color="default"
            onClick={handleConfirmDialogClose}
          >
            {t('close')}
          </RoundedButton>
        </Box>
      </Box>
    </>
  );

  return (
    <>
      {
        // TODO: Just for test. Please check TCP error existence where suitable.
        // Start
        influxdbErrorExists
        && (
          <Alert
            severity="error"
          >
            <Typography variant="body2" color="inherit">
              {t('Our server is busy. Please try again later.')}
            </Typography>
          </Alert>
        )
        // End
      }
      {testMode && (
      <Alert
        severity="warning"
      >
        <Typography variant="body2" color="inherit">
          {t('You are using test mode, monthly report will be sent to your email instead of user\'s emails')}
        </Typography>
      </Alert>
      )}
      <CustomTabs
        tabs={tabs}
        extraComponent={(
          <RoundedButton
            variant="contained"
            color="primary"
            className={classes.sendEmailButton}
            onClick={handleConfirmDialogOpen}
            disabled={!monthlyReportEmailData.length || openConfirmDialog || (testMode && error)}
          >
            {t('send email')}
          </RoundedButton>
    )}
      />
      <Dialog open={statusDialog === 'success'} onClose={handleStatusDialogClose}>
        <Box className={classes.statusDialog}>
          <SuccessPage
            onCancel={handleStatusDialogClose}
            primaryText={t('AWESOME!')}
            message={t('Monthly earning report has been sent to users. Thank you.')}
            btnText={t('close')}
          />
        </Box>
      </Dialog>
      <Dialog open={statusDialog === 'fail'} onClose={handleStatusDialogClose}>
        <Box className={classes.statusDialog}>
          <FailPage
            onCancel={handleStatusDialogClose}
            primaryText={t('OH NO!')}
            message={t('Send monthly earning report failed. Please try again.')}
            btnText={t('close')}
          />
        </Box>
      </Dialog>
      <Dialog open={openConfirmDialog} onClose={handleConfirmDialogClose} maxWidth="lg">
        {getConfirmDialogContent(monthlyReportEmailData)}
      </Dialog>
    </>
  );
};

AdminEarningTable.whyDidYouRender = true;
export default React.memo(AdminEarningTable);
