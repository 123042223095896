// query
import {
  createQuery,
  createMutation,
} from '../../../modules/apollo/apollo';

import {
  updatePoolsMutation,
  updatePoolDatasMutation,
} from './mutations';
import {
  getPoolsQuery,
  getUserPoolsQuery,
  getUserRollupRewardsQuery,
} from './queries';
// mutation
// apollo

export const graphqlGetPools = createQuery(getPoolsQuery);
export const graphqlGetUserPools = createQuery(getUserPoolsQuery);
export const graphqlGetUserRollupRewards = createQuery(getUserRollupRewardsQuery);

export const graphqlUpdatePools = createMutation(updatePoolsMutation);
export const graphqlUpdatePoolDatas = createMutation(updatePoolDatasMutation);
