import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React from 'react';

import { connectorWrapperStyles } from './styles';

const useStyles = makeStyles(connectorWrapperStyles);
const ConnectorWrapper = ({ children }) => {
  const classes = useStyles();
  return (
    <Box
      display="flex"
      alignItems="center"
      flexDirection="column"
      className={classes.connectorBox}
    >
      {children}
    </Box>
  );
};

ConnectorWrapper.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ConnectorWrapper;
