import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React from 'react';

import { chainNameStyles } from './styles';

const useStyles = makeStyles(chainNameStyles);

const ChainName = (props) => {
  const classes = useStyles();

  const {
    title,
    variant,
    ...others
  } = props;
  return (
    <Typography {...others} variant={variant} className={classes.title}>
      {title}
    </Typography>
  );
};
ChainName.propTypes = {
  /**
   * Protocol name
   */
  title: PropTypes.string,
  /**
   * Protocol name
   */
  variant: PropTypes.string,
};
ChainName.defaultProps = {
  variant: 'h6',
  title: '',
};
export default ChainName;
