export const styles = (theme) => ({
  closeButton: {
    margin: theme.spacing(2),
  },
  cardHeader: {
    margin: theme.spacing(0, 'auto', 2, 'auto'),
  },
  cardContent: {
    margin: theme.spacing(0, 1, 2, 1),
  },
  card: {
    margin: theme.spacing(2, 0, 0, 0),
  },
  headerIcon: { // the box that wraped the icon
    width: '100%',
  },
  chainIcon: {
    width: 100,
    height: 100,
  },
  buttonContainer: {
    flexGrow: 1,
  },
  deployButton: {
    padding: theme.spacing(1.5, 5),
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  deployButtonDisabled: {
    pointerEvents: 'none',
  },
});
