import { drawerWidth } from '../NavigationDrawer/constants';

export const styles = (theme) => ({
  grow: {
    flexGrow: 1,
  },
  toolbar: {
    padding: 0,
  },
  toolbarContent: {
    paddingRight: 24,
    paddingLeft: 24,
    display: 'flex',
    justifyContent: 'space-between',
  },
  menuButton: {
    marginLeft: theme.spacing(-1.5),
    [theme.breakpoints.down('xs')]: {
      marginLeft: 0,
    },
  },
  menuButtonHidden: {
    display: 'none',
  },
  appBar: {
    backgroundColor: '#fff',
    ...theme.border.default,
    borderRadius: 0,
    borderTop: 0,
    borderLeft: 0,
    borderRight: 0,
    background: theme.palette.background.paper,
    // borderBottom: theme.border.default,
    boxShadow: 'none',
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    backgroundColor: '#fff',
    ...theme.border.default,
    borderRadius: 0,
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  logo: {
    cursor: 'pointer',
  },
  mobileImage: {
    width: 100,
    height: 'auto',
  },
  mobileNotAuthenticatedImage: {
    marginLeft: theme.spacing(2),
  },
  image: {
    width: 120,
    height: 'auto',
    position: 'fixed',
  },
  authenticatedImage: {
    left: theme.spacing(5.5),
  },
  notAuthenticatedImage: {
    left: theme.spacing(5.5),
  },
  img: {
    margin: 'auto',
    display: 'block',
    maxWidth: '100%',
    maxHeight: '100%',
  },
  menuIcon: {
    color: theme.customColor.almostBlack,
  },
  menuItem: {
    color: theme.customColor.almostBlack,
  },
  exitToApp: {
    color: theme.customColor.almostBlack,
  },
  accountCircle: {
    color: theme.customColor.almostBlack,
  },
  announcementBar: {
    ...theme.centerComponent,
    width: '100%',
    backgroundColor: '#111',
  },
  announcementBarContentActionText: {
    color: theme.palette.green.main,
    // color: theme.palette.primary.main,
    fontWeight: 500,
    // cursor: 'pointer',
  },
  announcementBarLink: {
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'none',
    },
  },
});
export const navigationAppBarLangstyles = (theme) => ({
  langBtn: {
    // backgroundColor: theme.palette.background.paper,
    // borderRadius: 20,
    width: '100%',
    textTransform: 'none',
    border: 'none',
  },
  listItemText: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  langIcon: {
    color: theme.customColor.almostBlack,
  },
});

export const announcementBarContentStyles = (theme) => ({
  root: {
    padding: theme.spacing(1),
  },
  text: {
    color: 'white',
    fontWeight: 500,
  },
});
