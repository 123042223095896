import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import AmountCard from '../../AmountCard/AmountCard';
import { convertToCryptoNumber } from '../../utils/utils';
import InvestmentEarningDashboardItem from '../InvestmentNodeDetailsPageItem';

import { busdOverviewStyles } from './styles';

const useStyles = makeStyles(busdOverviewStyles);

const BUSDOverview = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  const coinPrice = useSelector((state) => state.nodeR.coinPrice);
  const BUSD = coinPrice['binance-usd'];
  const { price, percentChange24h } = BUSD || {};

  const { items: orders } = useSelector((state) => state.nodeOwnerR.nodeOwnerOrder.busd);
  const totalEarningBUSDData = useSelector((state) => state.nodeR.nodeOwnerEarning.total.busd);
  const {
    totalInCoin: totalEarningBUSDInCoin,
    totalBalance: totalBalanceBUSD,
    // totalBalanceInCoin: totalBalanceBUSDInCoin,
    currency,
  } = totalEarningBUSDData;
  const { contract } = orders[0] || {};
  const {
    getOrdersLoading,
    getIntervalFixedEarningLoading,
    getCoinPriceLoading,
  } = useSelector((state) => state.loadingR);

  const getCoinPriceContent = (price, change) => (
    <Box display="flex" justifyContent="center" flexWrap="wrap">
      <Box mr={1}>
        <Typography variant="body1" className={classes.overviewText}>
          {price ? price.toLocaleString('en-US', {
            style: 'currency',
            currency,
          }) : '-'}
        </Typography>
      </Box>
      {change && (
      <Box>
        <Typography variant="body1" className={change >= 0 ? classes.percentTextAsc : classes.percentTextDesc}>
          {change >= 0 ? `+${change.toFixed(2)}%` : `${change.toFixed(2)}%`}
        </Typography>
      </Box>
      )}
    </Box>
  );

  const getOverviewItemContent = (text) => (
    <Typography
      variant="body1"
      align="center"
      className={classes.overviewText}
    >
      {text}
    </Typography>
  );

  return (
    <Grid container alignItems="stretch" spacing={2}>
      <Grid item xs={12} sm={6}>
        <AmountCard
          data={{
            balanceTitle: t('Total Balance'),
            earningTitle: t('Total Earnings'),
            stakedTitle: t('Deposit Amount'),
            balance: `${convertToCryptoNumber(totalEarningBUSDInCoin)} BUSD`,
            balanceExchanged: `≈ ${totalBalanceBUSD.toLocaleString('en-US', {
              style: 'currency',
              currency,
            })}`,
            earning: `${convertToCryptoNumber(totalEarningBUSDInCoin)} BUSD`,
            staked: `${convertToCryptoNumber(0)} BUSD`,
          }}
          loading={getOrdersLoading || getIntervalFixedEarningLoading}
          className={classes.amountCard}
          titleTextColor="#000000"
          earningTextColor="#ffffff"
          earningExchangedTextColor="#F5F5F5"
        />
      </Grid>
      <Grid item xs={12} sm={3}>
        <Grid container style={{ height: '100%', margin: 0 }}>
          <Grid item xs={6} sm={12} className={classes.stack2Top}>
            <InvestmentEarningDashboardItem
              align="center"
              title={(
                <Typography
                  variant="body1"
                  align="center"
                  className={classes.overviewText}
                >
                  {t('BUSD Price')}
                </Typography>
              )}
              content={getCoinPriceContent(price, percentChange24h)}
              loading={getCoinPriceLoading}
            />
          </Grid>
          <Grid item xs={6} sm={12} className={classes.stack2Bottom}>
            <InvestmentEarningDashboardItem
              align="center"
              title={t('APY')}
              content={getOverviewItemContent('15%')}
              loading={getOrdersLoading}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} sm={3}>
        <InvestmentEarningDashboardItem
          align="center"
          title={t('Contract')}
          content={getOverviewItemContent(`${contract.contractPeriodValue} ${contract.contractPeriod}`)}
          loading={getOrdersLoading}
        />
      </Grid>
    </Grid>
  );
};

export default BUSDOverview;
