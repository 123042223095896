// eslint-disable-next-line import/no-extraneous-dependencies
import { Keyring } from '@polkadot/keyring';
import isEmpty from 'lodash/isEmpty';
import * as R from 'ramda';

import { getChainNetworkOrName } from '../../web3/chains/dot/services';

import { planckToToken } from './utils';

const isDerivePathCompValid = (pathComponent) => {
  if (pathComponent === 0) return true;
  return !isEmpty(pathComponent);
};
const makeDerivePath = R.curry(
  (secretPharse, password, hardDerivation, softDerivation) => {
    const isHardDerivationValid = isDerivePathCompValid(softDerivation);
    const isSoftDerivationValid = isDerivePathCompValid(softDerivation);
    const isPasswordValid = isDerivePathCompValid(password);
    if (isSoftDerivationValid && isPasswordValid) {
      return `${secretPharse}//${hardDerivation}/${softDerivation}///${password}`;
    }
    if (isSoftDerivationValid && !isPasswordValid) {
      return `${secretPharse}//${hardDerivation}/${softDerivation}`;
    }
    if (!isSoftDerivationValid && !isPasswordValid && isHardDerivationValid) {
      return `${secretPharse}//${hardDerivation}`;
    }
    return secretPharse;
  },
);

export const generateAccount = R.curry(
  (
    keyring,
    secretPharse,
    password,
    metadata,
    hardDerivation,
    softDerivation,
  ) => {
    const derivePath = makeDerivePath(
      secretPharse,
      password,
      metadata,
      hardDerivation,
      softDerivation,
    );
    return keyring.createFromUri(derivePath, metadata);
  },
);

export const intializeKeyring = R.curry((type, ss58Format) => {
  const keyring = new Keyring({
    type: type || 'sr25519',
    ss58Format: !isDerivePathCompValid(ss58Format) ? ss58Format : 42,
  });
  return keyring;
});
export const generateAccounts = R.curry(
  (
    {
      type,
      ss58Format,
      hardDerivation,
      startIndex,
      endIndex,
      secretPhrase,
      password,
      metadata,
    },
  ) => {
    const keyring = new Keyring({
      type: type || 'sr25519',
      ss58Format: ss58Format || 42,
    });
    const tempArray = R.range(startIndex, endIndex + 1);

    const accounts = R.map(
      (index) => {
        const account = generateAccount(
          keyring,
          secretPhrase,
          password,
          metadata,
          hardDerivation,
          index,
        );
        return account;
      },
      tempArray,
    );

    const activeChain = getChainNetworkOrName(ss58Format);
    return { activeChain, [activeChain]: { accounts } };
  },
);

export const backupAccount = R.curry(({ pair, password }) => {
  // if enable this, it thrown an error: no encrypted data available to decode
  /// todo figure out why
  /*
  if (!pair.isLocked) {
    pair.lock();
  }
  pair.decodePkcs8(password); */
  const json = pair.toJson(password);
  return json;
});

export const getAccountInfo = R.curry(
  async ({ api, address }) => {
    const { data: balance, nonce } = await api.query.system.account(address);
    const {
      free,
      reserved,
    } = balance;
    return {
      balance: {
        free: planckToToken(free.toString()),
        reserved: planckToToken(reserved.toString()),
      },
      nonce,
    };
  },
);
