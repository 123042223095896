import * as R from 'ramda';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import ReactTableV7 from '../../Tables/ReactTableV7';
import InvestmentEarningDashboardItem from '../InvestmentNodeDetailsPageItem';

// others
import { nodeOwnerEarningHistoryColumns } from './columns';

const NeunodeETH2EarningHistory = () => {
  const { t } = useTranslation();

  const { interval_1mo: earnings } = useSelector((state) => state.nodeR.nodeOwnerEarning.interval.neunodeETH2);
  const {
    getOrdersLoading,
  } = useSelector((state) => state.loadingR);

  const earningHistory = R.map((eachEarning) => {
    const {
      orderId, nodeNumber, endTime, convertedTotal,
    } = eachEarning;

    return {
      orderId,
      nodeNumber,
      timestamp: endTime,
      earning: convertedTotal,
    };
  })(earnings || []);

  const columnsToUse = nodeOwnerEarningHistoryColumns(
    t,
  );

  return (
    <InvestmentEarningDashboardItem
      titlePadding="21px 32px 16px 32px"
      title={t('Earning History (Locked)')}
      content={(
        <ReactTableV7
          loading={getOrdersLoading}
          data={earningHistory}
          columns={columnsToUse}
          customPageSize={3}
          customPageSizeList={[3, 5, 10, 20, 50]}
        />
)}
    />
  );
};

export default NeunodeETH2EarningHistory;
