import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import ArrowDropDownOutlinedIcon from '@material-ui/icons/ArrowDropDownOutlined';
import ArrowDropUpOutlinedIcon from '@material-ui/icons/ArrowDropUpOutlined';
import { sumFromList } from 'jsutils';
import startCase from 'lodash/fp/startCase';
import toLower from 'lodash/fp/toLower';
import moment from 'moment';
import * as R from 'ramda';
import React from 'react';

// others
import LocalDate from '../../LocalDate/LocalDate';
import { timelineStepNameMapIndex } from '../constants';

export const nodeOwnerDevicesColumns = (
  t,
) => [
  {
    Header: t('Device ID'),
    accessor: 'deviceId',
    Cell: (data) => {
      const { deviceId } = data.row.original;
      const deviceIdToUse = R.pipe(
        R.split('-'),
        R.last,
      )(deviceId);
      return (
        <Grid container direction="row" alignItems="center">
          {deviceIdToUse}
        </Grid>
      );
    },
  },
  {
    Header: t('Started Date'),
    accessor: 'dateCreated',
    Cell: (data) => {
      const { dateActive } = data.row.original;
      return (
        <Grid container direction="row" alignItems="center">
          {dateActive ? moment(new Date(dateActive)).format('MMMM D, YYYY') : '-'}
        </Grid>
      );
    },
  },
  {
    Header: t('Contract End Date'),
    accessor: 'contractEndDate',
    Cell: (data) => {
      const contractEndDate = R.view(R.lensPath(['contract', 'contractEndDate']), data.row.original);
      return (
        <Grid container direction="row" alignItems="center">
          {contractEndDate ? moment(new Date(contractEndDate)).format('MMMM D, YYYY') : '-'}
        </Grid>
      );
    },
  },
  {
    Header: t('Node Status'),
    accessor: 'deviceStatus',
    Cell: (data) => {
      const { deviceStatus } = data.row.original;
      return (
        <Grid container direction="row" alignItems="center">
          {deviceStatus}
        </Grid>
      );
    },
  },
];

export const nodeOwnerEarningHistoryColumns = (
  t,
) => [
  {
    Header: t('Timestamp'),
    id: 'timestamp',
    Cell: (data) => {
      const { timestamp } = data.row.original;
      return (
        <Grid container direction="row" alignItems="center">
          <LocalDate
            date={timestamp}
          />
        </Grid>
      );
    },
  },
  {
    Header: t('Order ID'),
    accessor: 'orderId',
    Cell: (data) => {
      const { orderId } = data.row.original;
      const orderIdToUse = orderId ? R.pipe(
        R.split('-'),
        R.last,
      )(orderId) : '-';
      return (
        <Grid container direction="row" alignItems="center">
          {orderIdToUse}
        </Grid>
      );
    },
  },
  {
    Header: t('Active Node number'),
    accessor: 'nodeNumber',
    Cell: (data) => {
      const { nodeNumber } = data.row.original;
      return (
        <Grid container direction="row" alignItems="center">
          {nodeNumber || '-'}
        </Grid>
      );
    },
  },
  {
    Header: t('Earnings (JPY)'),
    accessor: 'earnings',
    Cell: (data) => {
      const { earning } = data.row.original;
      const earningToUse = earning || 0;
      return (
        <Grid container direction="row" alignItems="center">
          {earningToUse.toLocaleString('en-US', {
            style: 'currency',
            currency: 'JPY',
          })}
        </Grid>
      );
    },
  },
];

export const nodeOwnerOrderHistoryColumns = (
  t,
) => [
  {
    Header: t('Order ID'),
    accessor: 'orderId',
    Cell: (data) => {
      const { orderId } = data.row.original;
      const orderIdToUse = R.pipe(
        R.split('-'),
        R.last,
      )(orderId);
      return (
        <Grid container direction="row" alignItems="center">
          {orderIdToUse}
        </Grid>
      );
    },
  },
  {
    Header: t('Item'),
    accessor: 'Item',
    Cell: (data) => {
      const { quantity } = data.row.original;
      return (
        <Grid container direction="row" alignItems="center">
          {`${quantity} neunode eth2(s)`}
        </Grid>
      );
    },
  },
  {
    Header: t('Total amount'),
    accessor: 'totalAmount',
    Cell: (data) => {
      const { payments } = data.row.original;
      const amount = sumFromList('amount', payments);
      return (
        <Grid container direction="row" alignItems="center">
          {amount ? amount.toLocaleString('en-US', {
            style: 'currency',
            currency: 'JPY',
          }) : '-'}
        </Grid>
      );
    },
  },
  {
    Header: t('Order Status'),
    accessor: 'Order Status',
    Cell: (data) => {
      const { timeline } = data.row.original;

      const timeLineToUse = timeline ? R.sortWith(
        [R.ascend((item) => timelineStepNameMapIndex[item.stepName])],
        timeline,
      ) : [];
      const lastCompletedStepIndex = R.findLastIndex(R.propEq('stepStatus', 'COMPLETE'))(timeLineToUse);
      const currentStepIndex = lastCompletedStepIndex === timeLineToUse.length - 1 ? lastCompletedStepIndex : lastCompletedStepIndex + 1;
      return (
        <Grid container direction="row" alignItems="center">
          {
            currentStepIndex >= 0 ? R.pipe(
              R.view(R.lensPath([currentStepIndex, 'stepName'])),
              toLower,
              startCase,
            )(timeLineToUse)
              : '-'
          }
        </Grid>
      );
    },
  },
  {
    id: 'expander',
    sortable: false,
    Cell: (data) => {
      const { row } = data;
      const { timeline } = data.row.original;
      return timeline && (
      <Grid
        container
        direction="row"
        alignItems="center"
        alignContent="center"
        {...row.getToggleRowExpandedProps()}
      >
        <Box display="flex">
          <Box mt={0.5}>
            <Typography color="primary">
              {t('Details')}
            </Typography>
          </Box>
          <Box mt={0.5}>
            {!row.isExpanded
              ? <ArrowDropDownOutlinedIcon color="primary" />
              : <ArrowDropUpOutlinedIcon color="primary" />}
          </Box>
        </Box>
      </Grid>
      );
    },
  },
];
