import Typography from '@material-ui/core/Typography';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';

import { useAddFunctionToRef } from '../../../hooks/hooks';
import { nodeOperations } from '../../../store/node/index';
import { orderOperations } from '../../../store/order/index';
import CustomTabs from '../../Tabs/CustomTabs';

import NeunodeContent from './NeunodeContent';

// store

const Neunode = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { userId } = useSelector((state) => state.userR.user);

  const getNeunodeOrders = useAddFunctionToRef(async () => {
    await dispatch(orderOperations.getOrders(userId, 'NODE_PROVIDER_NEUNODE'));
  });
  const getIntervalFixedEarning = useAddFunctionToRef(async (userId) => {
    await dispatch(nodeOperations.getIntervalFixedEarning({
      userId,
      earningType: 'NEUNODE',
      interval: '1mo',
      currency: 'jpy',
    }));
  });

  useEffect(() => {
    getNeunodeOrders();
  }, [getNeunodeOrders]);

  useEffect(() => {
    getIntervalFixedEarning(userId);
  }, [getIntervalFixedEarning, userId]);

  const tabs = [
    {
      label: t('Neunode'),
      content: <NeunodeContent />,
      mainTab: true,
    },
  ];

  return (
    <CustomTabs
      tabs={tabs}
      belowTabsComponent={(
        <Typography variant="h3" color="textSecondary">
          {t('Fixed terms, stable return, low-risk product')}
        </Typography>
      )}
    />
  );
};

export default Neunode;
