const { REACT_APP_ENV, REACT_APP_TEST, REACT_APP_WEB_URL } = process.env;
export const testMode = !(REACT_APP_ENV === 'prod' && !REACT_APP_TEST);
export const useTestnet = !(REACT_APP_ENV === 'prod' && !REACT_APP_TEST);
export const webUrl = REACT_APP_WEB_URL || 'http://localhost:3000';
// fiat
export const fiatRateUrl = ' https://api.exchangeratesapi.io/latest?base=USD';
export const defaultFiat = 'USD';
export const dateFormat = 'YYYY-MM-DD';
export const dateFormatWithTime = 'YYYY-MM-DD HH:MM';
// more at: https://www.unicode.org/reports/tr35/tr35-dates.html#Date_Field_Symbol_Table
export const unicodeDateFormat = 'yyyy-MM-dd';
export const regexExp = {
  macAddressRegex: /^([0-9A-Fa-f]{2}[:]){5}([0-9A-Fa-f]{2})$/,
  emailRegex: /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/,
  getNumberRegex: /[^0-9]/g,
  matchNumberRegex: /[0-9]/g,
  onlyNumberRegex: /^[0-9]+\.?[0-9]*$/,
  ununifiAccAddressRegex: /ununifi[0-9A-Za-z]{39}/,
};
export const insecureRoutes = [
  /\/device\/device-cli/,
  /\/user\/signin/,
  /\/user\/signup/,
  /\/user\/forgot/,
  /\/user\/verifyPassword/,
  /\/user\/refresh-token/,
  /\/user\/email-validation\/[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/,
];

export const indexInHdWalletWhenNotExist = -1;
export const firtSigOrderValue = 0;
export const infinite = 10 ** 9;

export const baseQueryOperators = {
  eq: '=',
  gt: 'gt',
  gte: 'gte',
  lt: 'lt',
  lte: 'lte',
  ne: 'ne',
  like: 'like',
  ilike: 'ilike',
  in: 'in',
  nin: 'nin',
};

export const keyToAuthTokenDataInLocalStorage = process.env.REACT_APP_KEY_TO_AUTH_TOKEN_DATA
  || 'auth-token-data';
export const keyToUserDataInLocalStorage = process.env.REACT_APP_KEY_TO_USER_DATA || 'user-data';
export const keyToAuthStatusInLocalStorage = process.env.REACT_APP_KEY_TO_AUTH_STATUS
  || 'isAuthenticated';
export const timeToUpdateAuthToken = process.env.REACT_APP_TIME_TO_UPDATE_AUTH_TOKEN
  || 2 * 60 * 1000; // in milisecond
export const intervalToCheckAuthToken = process.env.REACT_APP_INTERVAL_TO_CHECK_AUTH_TOKEN
  || 10000; // in milisecond
export const fallbackInterval = process.env.REACT_APP_FALL_BACK_INTERVAL || 2000;
export const responseTime = process.env.REACT_APP_RESPONSE_TIME || 1000;

export const insecureOperationNames = [
  'signin',
  'signup',
  'signOut',
  'activateUser',
  'validateEmail',
  'validatePassword',
  'sendForgotPasswordEmail',
  'resetPassword',
  'validateForgotPassword',
  'resendActivation',
  'updateAuthToken',
  'getActivation',
];

// TIME CONSTANTS
// These can be used to convert time from milliseconds to following units
export const SECONDS = 1000;
export const MINUTES = SECONDS * 60;
export const HOURS = MINUTES * 60;
export const DAYS = HOURS * 24;
export const WEEKS = DAYS * 7;
export const MONTHS = DAYS * 30;
export const YEARS = DAYS * 365;
export const TIMER_INTERVAL = 60000;

export const socialLinks = {
  twitter: 'https://twitter.com/neukindinc',
  facebook: 'https://www.facebook.com/neukindinc/',
  telegram: 'https://t.me/neukind',
  discord: 'https://discord.com/invite/MetjNPSERu',
};

export const HttpStatusCodes = {
  BAD_REQUEST: 400,
  GRAPHQL_VALIDATION_FAILED: 455,
  CUSTOM_ERROR: 457,
  PARAMETER_VALIDATION_ERROR: 459,
  AXIOS_CLIENT_ERROR: 481,
  CLIENT_INTERNAL_ERROR: 483,
  INVALID_PARAMS_TYPE: 485,
  INTERNAL_SERVER_ERROR: 500,
  SERVICE_UNAVAILABLE: 503,
  TCP_READ_FAILED: 14,
  INFLUXDB_ERROR: 500,
  API_READ_FAILED: 500,
};

export const graphQLDataTypeMockValues = {
  mockString: 'This Is A String',
  mockId: '4da11f86-7e9a-4227-9b1e-08878e0d6cc6',
  mockInt: 311,
  mockFloat: 311.111,
  mockBoolean: true,
  mockJson: {
    id: 'da6c161a-5653-45d4-b004-bad8c9dce09b',
    type: 'JSON',
    value: 'This Is A JSON Value',
    description: 'This Is A JSON',
    metadata: 'This Is A JSON Metadata',
  },
  mockJsonObject: {
    id: '03d57743-b6cb-44cd-83b5-30c6e87fb07e',
    type: 'JSONObject',
    value: 'This Is A JSONObject Value',
    description: 'This Is A JSONObject',
    metadata: 'This Is A JSONObject Metadata',
  },
};

const { mockFloat } = graphQLDataTypeMockValues;
export const mockPaginationMeta = {
  __typename: 'PaginationMeta',
  totalItems: mockFloat,
  itemCount: mockFloat,
  itemsPerPage: mockFloat,
  totalPages: mockFloat,
  currentPage: mockFloat,
};
