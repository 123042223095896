import gql from 'graphql-tag';

export const getUserNodesQuery = gql`
  query getUserNodes($userId: String!)
  {
    getUserNodes(
      getUserNodesInput: { userId: $userId }
    ) {
      nodes {
        nodeId
        nodeName
        nodeType
        dateCreated
        status
        customerId
        nodeInfo {
          pubKey
          accAddress
          validatorId
          ipv4Domain
          ipv6Domain
          locked
          migrated
          createValidatorAmount
        }
      }
    }
  }
`;

export const getUserNodeQuery = gql`
  query getUserNode($userId: String!, $nodeName: String!)
  {
    getUserNode(
      getUserNodeInput: { 
        userId: $userId 
        nodeName: $nodeName 
      }
    ) {
      nodeType
      status
      diskUsage
      totalDisk
      cpuUsage
      ramUsage
      totalRam
      nodeName
      dateCreated
      errorInfo
      nodeInfo {
        nodeVersion
        pubKey
      },
    }
  }
`;

export const getUserNodeEarningQuery = gql`
  query getUserNodeEarning($getUserNodeEarningInput: GetUserNodeDataInput!)
  {
    getUserNodeEarning(
      getUserNodeEarningInput: $getUserNodeEarningInput
    ) {
      nodeType
      data
   }
 }
`;

export const getNodesEstimateActivationDateQuery = gql`
  query getNodesEstimateActivationDate($getNodesEstimateActivationDateInput: GetNodesEstimateActivationDateInput!)
  {
    getNodesEstimateActivationDate(
      getNodesEstimateActivationDateInput: $getNodesEstimateActivationDateInput
    ) {
      activationDates {
        publicKey
        estimateActivationDate
      }
   }
 }
`;

export const getNodesOnChainStatusQuery = gql`
  query getNodesOnChainStatus($getNodesOnChainStatusInput: GetNodesOnChainStatusInput!)
  {
    getNodesOnChainStatus(
      getNodesOnChainStatusInput: $getNodesOnChainStatusInput
    ) {
      publicKey
      status
      positionInActivationQueue
   }
 }
`;

export const getTotalEarningQuery = gql`
  query getTotalEarning($getTotalEarningInput: GetNodesTotalEarningInput!)
  {
    getTotalEarning(
      getTotalEarningInput: $getTotalEarningInput
    ) {
      epoch
      earnings {
        balance
        earning
        publicKey
        accAddress
        index
        initialAmount
        staked
      }
   }
 }
`;

export const getTotalEarningInPeriodQuery = gql`
  query getTotalEarningInPeriod($getTotalEarningInPeriodInput: GetTotalEarningInPeriodInput!)
  {
    getTotalEarningInPeriod(
      getTotalEarningInPeriodInput: $getTotalEarningInPeriodInput
    ) {
      epoch
      startDate
      endDate
      earnings {
        balance
        earning
        publicKey
      }
   }
 }
`;

export const getIntervalEarningQuery = gql`
  query getIntervalEarning($getIntervalEarningInput: GetIntervalEarningInput!)
  {
    getIntervalEarning(
      getIntervalEarningInput: $getIntervalEarningInput
    ) {
      earnings {
        total
        totalCoin
        currencyPrice
        startTime
        endTime
      }
   }
 }
`;

export const getUserEarningHistoryQuery = gql`
  query getUserEarningHistory($getUserEarningHistoryInput: GetUserEarningHistoryInput!)
  {
    getUserEarningHistory(
      getUserEarningHistoryInput: $getUserEarningHistoryInput
    ) {
      earnings {
        nodeId
        nodeName
        pubKey
        fqdn
        balance
        totalCoin
        total
        currencyPrice
        startTime
        endTime
      }
   }
 }
`;

export const getCoinPriceQuery = gql`
  query getCoinPrice($getCoinPriceInput: GetCoinPriceInput!)
  {
    getCoinPrice(
      getCoinPriceInput: $getCoinPriceInput
    ) {
      coin
      currency
      price
      volume24h
      percentChange1h
      percentChange24h
      percentChange7d
      percentChange30d
      percentChange60d
      percentChange90d
      marketCap
      lastUpdated
   }
 }
`;

export const getCoinPriceChartQuery = gql`
  query getCoinPriceChart($getCoinPriceChartInput: GetCoinPriceChartInput!)
  {
    getCoinPriceChart(
      getCoinPriceChartInput: $getCoinPriceChartInput
    ) {
      coin
      currency
      daysAgo
      prices {
        x
        y
      }
    }
 }
`;

export const getZenBalanceQuery = gql`
  query getZenBalance($getZenBalanceInput: GetZenBalanceInput!)
  {
    getZenBalance(
      getZenBalanceInput: $getZenBalanceInput
    ) {
      userId
      balance
      quantity
   }
 }
`;

export const getTotalEarningInfluxdbQuery = gql`
  query getTotalEarningInfluxdb($getTotalEarningInfluxdbInput: GetTotalEarningInfluxdbInput!)
  {
    getTotalEarningInfluxdb(
      getTotalEarningInfluxdbInput: $getTotalEarningInfluxdbInput
    ) {
      total
      totalCoin
      userId
      nodeType
      currency
      currencyPrice
   }
 }
`;

export const getTotalFixedEarningQuery = gql`
  query getTotalFixedEarning($getTotalFixedEarningInput: GetTotalFixedEarningInput!)
  {
    getTotalFixedEarning(
      getTotalFixedEarningInput: $getTotalFixedEarningInput
    ) {
      userId
      contractId
      originalTotal
      originalCurrency
      convertedTotal
      convertCurrency
   }
 }
`;

export const getIntervalFixedEarningQuery = gql`
  query getIntervalFixedEarning($getIntervalFixedEarningInput: GetIntervalFixedEarningInput!)
  {
    getIntervalFixedEarning(
      getIntervalFixedEarningInput: $getIntervalFixedEarningInput
    ) {
      userId
      earningType
      startTime
      endTime
      interval
      currency
      earnings {
        contractId
        orderId
        originalTotal
        originalCurrentBalance
        originalInitialDeposit
        originalCurrency
        convertedTotal
        convertCurrency
        convertedCurrentBalance
        convertedInitialDeposit
        nodeNumber
        startTime
        endTime
      }
    }
 }
`;

export const getNodeOwnerTotalEarningQuery = gql`
  query getNodeOwnerTotalEarning($getNodeOwnerTotalEarningInput: GetNodeOwnerTotalEarningInput!)
  {
    getNodeOwnerTotalEarning(
      getNodeOwnerTotalEarningInput: $getNodeOwnerTotalEarningInput
    ) {
      total
      totalCoin
      currency
      currencyPrice
      balance
      nodeType
      userId
      startTime
      endTime
   }
 }
`;
