import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Moment from 'react-moment';

import { formatNodeNameFns } from '../MyNodeInfo/utils';

import MyNodeStatus from './MyNodeStatus';
import { styles } from './styles';

const useStyles = makeStyles(styles);

const MyNodeDescription = (props) => {
  const classes = useStyles();
  const {
    nodeName, dateCreated, uptime, showTitles, status, nodeType,
  } = props;
  const { t } = useTranslation();
  const titleFontWeight = 500;

  return (
    <Grid container justifyContent="space-between" alignContent="center" spacing={2}>
      <Grid item xs={12} sm={3} md={3}>
        <Grid container direction="column" className={classes.nodeDescriptionText}>
          {showTitles && (
          <Grid item>
            <Typography variant="body1" style={{ fontWeight: titleFontWeight }}>{t('NODE NAME')}</Typography>
          </Grid>
          )}
          <Grid item>
            <Typography variant="body1">{formatNodeNameFns[nodeType](nodeName)}</Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} sm={3} md={3}>
        <Grid container direction="column" className={classes.nodeDescriptionText}>
          {showTitles && (
          <Grid item>
            <Typography variant="body1" style={{ fontWeight: titleFontWeight }}>{t('CREATED DATE')}</Typography>
          </Grid>
          )}
          <Grid item>
            <Typography variant="body1">
              <Moment format="MM-DD-YYYY hh:mm">
                {dateCreated}
              </Moment>
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      { uptime // quick workaround to disable uptime
        && (
        <Grid item xs={12} sm={3} md={3}>
          <Grid container direction="column" className={classes.nodeDescriptionText}>
            {showTitles && (
            <Grid item>
              <Typography variant="body1" style={{ fontWeight: titleFontWeight }}>{t('UPTIME')}</Typography>
            </Grid>
            )}
            <Grid item>
              <Typography variant="body1">{uptime}</Typography>
            </Grid>
          </Grid>
        </Grid>
        )}
      <Grid item xs={12} sm={3} md={3}>
        <Grid container direction="column">
          {showTitles && (
          <Grid item>
            <Typography variant="body1" style={{ fontWeight: titleFontWeight }}>{t('STATUS')}</Typography>
          </Grid>
          )}
          <Grid item>
            <MyNodeStatus status={status} />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

MyNodeDescription.defaultProps = {
  showTitles: false,
  uptime: null,
};

MyNodeDescription.propTypes = {
  /**
   * Name of the node
  */
  nodeName: PropTypes.string.isRequired,
  /**
   * Date when the node was created
  */
  dateCreated: PropTypes.string.isRequired,
  /**
   * Uptime of the node
  */
  uptime: PropTypes.string,
  /**
   * Uptime of the node
  */
  showTitles: PropTypes.bool,
  /**
   * Status of the node
  */
  status: PropTypes.string.isRequired,
  /**
   * Type of the node
  */
  nodeType: PropTypes.string.isRequired,
};

export default MyNodeDescription;
