export const poolStyles = (theme) => ({
  statusDialog: {
    padding: theme.spacing(4),
  },
  successDialogDetails: {
    width: '100%',
    marginTop: theme.spacing(4),
    padding: theme.spacing(2),
  },
});

export const poolListStyles = (theme) => ({
  root: {
    padding: theme.spacing(2),
  },
  loadingCircle: {
    ...theme.centerComponent,
  },
  customTooltip: {
    backgroundColor: '#f9f9f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    boxShadow: theme.shadows[3],
  },
  customArrow: {
    color: '#f9f9f9',
  },
  menuButton: {
    width: '100%',
  },
  table: {
    padding: theme.spacing(2, 2),
  },
  statusDialog: {
    padding: theme.spacing(4),
  },
  reFetchButton: {
    padding: theme.spacing(0),
  },
});

export const poolDetailsStyles = (theme) => ({
  formGrid: {
    padding: theme.spacing(4),
  },
  actionGrid: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  dialogTitleText: {
    ...theme.typography.h2,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
  },
  formFieldSwitch: {
    ...theme.border.default,
    padding: theme.spacing(1, 2, 2, 1.5),
  },
});
