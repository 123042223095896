// react
import Box from '@material-ui/core/Box';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import ArrowDropDownOutlinedIcon from '@material-ui/icons/ArrowDropDownOutlined';
import ArrowDropUpOutlinedIcon from '@material-ui/icons/ArrowDropUpOutlined';
import DashboardOutlinedIcon from '@material-ui/icons/DashboardOutlined';
import MonetizationOnOutlinedIcon from '@material-ui/icons/MonetizationOnOutlined';
import PaymentOutlined from '@material-ui/icons/PaymentOutlined';
import ReceiptRoundedIcon from '@material-ui/icons/ReceiptRounded';
import ShoppingCartOutlinedIcon from '@material-ui/icons/ShoppingCartOutlined';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import {
  useHistory,
} from 'react-router-dom';

import { useWidth, useQuery } from '../../hooks/hooks';
import {
  paymentPageRoute,
  marketRoute,
  dashboardRoute,
  adminDashboardRoute,
  adminOrdersRoute,
  adminPoolsRoute,
  adminEarningRoute,
  referralRoute,
  nodeOwnerRoute,
  dappStoreRoute,
} from '../../routes';
import { drawerOperations } from '../../store/drawer/index';
import { navigationOperations } from '../../store/navigation/index';
import { routerServices } from '../../store/router/index';
import HomeIconSvg from '../Svg/SidebarIcons/HomeIconSvg';
import PaymentIconSvg from '../Svg/SidebarIcons/PaymentIconSvg';
import ReferralIconSvg from '../Svg/SidebarIcons/ReferralIconSvg';
import ServerIconSvg from '../Svg/SidebarIcons/ServerIconSvg';

import { styles } from './styles';

const useStyles = makeStyles(styles);

const statuses = {
  COMING_SOON: 'COMING_SOON',
  NEW: 'NEW',
};

const MainListItems = ({
  openDrawer,
  isAuthenticated,
  role,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const width = useWidth();
  const dispatch = useDispatch();
  const theme = useTheme();
  const history = useHistory();
  const query = useQuery();
  const queryLng = query.get('lng');

  const [dropDownIndex, setDropDownIndex] = useState(-1);
  const triggerDropDownSubItems = (index) => {
    if (index === dropDownIndex) {
      setDropDownIndex(-1);
    } else {
      setDropDownIndex(index);
    }
  };

  const { selectedIndex } = useSelector((state) => state.navigationR.sidebar);

  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));

  const listItem = [
    {
      to: dashboardRoute,
      Icon: <HomeIconSvg className={classes.icon} />,
      primaryText: t('Home'),
      authenticated: true,
      authorization: ['USER'],
      hiddenWidths: [],
      hidden: false,

    },
    {
      to: adminDashboardRoute,
      Icon: <DashboardOutlinedIcon className={classes.icon} />,
      primaryText: t('Dashboard'),
      authenticated: true,
      authorization: ['ADMIN'],
      hiddenWidths: [],
      hidden: false,
    },
    {
      to: adminOrdersRoute,
      Icon: <ReceiptRoundedIcon />,
      primaryText: t('Orders'),
      authenticated: true,
      authorization: ['ADMIN'],
      hiddenWidths: [],
      hidden: false,
    },
    {
      to: adminEarningRoute,
      Icon: <MonetizationOnOutlinedIcon className={classes.icon} />,
      primaryText: t('Earning'),
      authenticated: true,
      authorization: ['ADMIN'],
      hiddenWidths: [],
      hidden: false,
    },
    {
      to: adminPoolsRoute,
      Icon: <PaymentOutlined className={classes.icon} />,
      primaryText: t('Pools'),
      authenticated: true,
      authorization: ['ADMIN'],
      hiddenWidths: [],
      hidden: false,
    },
    {
      Icon: <ServerIconSvg className={classes.icon} />,
      primaryText: t('Node'),
      authenticated: true,
      authorization: ['USER'],
      hiddenWidths: [],
      hidden: false,
      subItems: [
        {
          primaryText: t('Node as a Service'),
          to: marketRoute,
        },
        {
          primaryText: t('Node owner'),
          to: nodeOwnerRoute,
          status: statuses.NEW,
        },
      ],
    },
    {
      Icon: <img
        src="/img/icon/bitcoin-dark.png"
        alt="bitcoin-icon"
        className={classes.icon}
      />,
      primaryText: t('Crypto'),
      authenticated: true,
      authorization: ['USER'],
      hiddenWidths: [],
      hidden: false,
      status: statuses.COMING_SOON,
    },
    {
      to: dappStoreRoute,
      Icon: <ShoppingCartOutlinedIcon className={classes.icon} />,
      primaryText: t('DApp Store'),
      authenticated: true,
      authorization: ['USER'],
      hiddenWidths: [],
      hidden: true,
      status: statuses.NEW,
    },
    {
      to: paymentPageRoute,
      Icon: <PaymentIconSvg className={classes.icon} />,
      primaryText: t('Payment'),
      authenticated: true,
      authorization: ['USER'],
      hiddenWidths: [],
      hidden: false,
    },
    {
      to: referralRoute,
      Icon: <ReferralIconSvg className={classes.icon} />,
      primaryText: t('Referral'),
      authenticated: true,
      authorization: ['USER'],
      hiddenWidths: [],
      hidden: false,
    },
  ];
  const handleListItemClick = (event, to, index) => {
    if (isMobile) {
      dispatch(drawerOperations.closeDrawer());
    }
    if (to) {
      dispatch(navigationOperations.selectSidebarItem(index));

      routerServices.historyPush(history, { pathname: to, queryLng });
    }
  };
  const handleSubListItemClick = (event, to, index) => {
    if (isMobile) {
      dispatch(drawerOperations.closeDrawer());
    }
    if (to) {
      dispatch(navigationOperations.selectSidebarItem(index));
      setDropDownIndex(-1);

      routerServices.historyPush(history, { pathname: to, queryLng });
    }
  };
  const listToDisplay = !isAuthenticated
    ? listItem.filter((item) => !item.authenticated)
    : listItem.filter((item) => {
      const { authorization } = item;
      return authorization.find((authorizationRole) => authorizationRole === role);
    });
  return (
    <>
      {
          listToDisplay.map((item, index) => {
            const {
              to,
              Icon,
              primaryText,
              hiddenWidths,
              href,
              target,
              component,
              hidden,
              subItems,
              status,
            } = item;
            if (hidden) return null;
            if (R.includes(width, hiddenWidths)) return null;
            const itemKey = primaryText;
            const isItemSelected = R.equals(selectedIndex, index);
            return (
              <Box key={itemKey}>
                <ListItem
                  component={component || null}
                  to={to || null}
                  href={href || null}
                  target={target || null}
                  className={classNames(
                    !openDrawer && classes.closeListItem,
                    openDrawer && classes.openListItem,
                    classes.listItem,
                    isItemSelected && classes.selectedListItem,
                  )}
                  onClick={(event) => {
                    if (subItems) {
                      triggerDropDownSubItems(index);
                    } else {
                      handleListItemClick(event, to, index);
                    }
                  }}
                  classes={{
                    button: classes.listItemBtn,
                  }}
                >
                  <ListItemIcon
                    className={classNames(
                      classes.listItemIcon,
                      isItemSelected && classes.selectedListItemIcon,
                    )}
                  >
                    {Icon}
                  </ListItemIcon>
                  <ListItemText
                    primary={primaryText}
                    className={classNames(
                      !openDrawer && classes.listItemText,
                      openDrawer && classes.openListItemText,
                      isItemSelected && classes.selectedListItemText,
                    )}
                    classes={{
                      primary: classNames(
                        !isItemSelected && classes.primaryListItemText,
                        isItemSelected && classes.selectedPrimaryListItemText,
                        openDrawer && classes.openPrimaryListItemText,
                        !openDrawer && classes.closePrimaryListItemText,
                      ),
                    }}
                  />
                  {subItems && (
                    index === dropDownIndex
                      ? (
                        <ArrowDropUpOutlinedIcon
                          className={classes.arrowDropIcon}
                          color={isItemSelected ? 'primary' : 'inherit'}
                        />
                      )
                      : (
                        <ArrowDropDownOutlinedIcon
                          className={classes.arrowDropIcon}
                          color={isItemSelected ? 'primary' : 'inherit'}
                        />
                      )
                  )}
                  {status === statuses.COMING_SOON
                    && (
                    <Box
                      border={1}
                      className={classes.comingsoonBorder}
                    >
                      <Typography
                        align="center"
                        className={classes.comingsoonText}
                      >
                        {t('coming soon')}
                      </Typography>
                    </Box>
                    )}
                  {status === statuses.NEW
                    && (
                      <Typography
                        align="center"
                        className={classes.newText}
                      >
                        {t('New!')}
                      </Typography>
                    )}
                </ListItem>
                {index === dropDownIndex && (
                <Box className={classes.subItemsRoot}>
                  {R.map((eachSubItem) => {
                    const {
                      to,
                      primaryText,
                      href,
                      target,
                      status,
                    } = eachSubItem;
                    const itemKey = primaryText;
                    return (
                      <ListItem
                        to={to || null}
                        href={href || null}
                        target={target || null}
                        className={classNames(
                          classes.listItem,
                        )}
                        key={itemKey}
                        onClick={(event) => {
                          handleSubListItemClick(event, to, index);
                        }}
                        classes={{
                          button: classes.listItemBtn,
                        }}
                      >
                        <ListItemText
                          primary={primaryText}
                          classes={{
                            primary: classNames(
                              !isItemSelected && classes.subPrimaryListItemText,
                              isItemSelected && classes.selectedSubPrimaryListItemText,
                            ),
                          }}
                        />
                        {status === statuses.COMING_SOON
                    && (
                    <Box
                      border={1}
                      className={classes.comingsoonBorder}
                    >
                      <Typography
                        align="center"
                        className={classes.comingsoonText}
                      >
                        {t('coming soon')}
                      </Typography>
                    </Box>
                    )}
                        {status === statuses.NEW
                    && (
                      <Typography
                        align="center"
                        className={classes.newText}
                      >
                        {t('New!')}
                      </Typography>
                    )}
                      </ListItem>
                    );
                  }, subItems)}
                </Box>
                )}
              </Box>
            );
          })
      }
    </>
  );
};

MainListItems.propTypes = {
  /**
   * Is user signed in or not
   * @type {Boolean}
   */
  isAuthenticated: PropTypes.bool.isRequired,
  /**
   * Role of user i.e. 'USER', 'ADMIN' etc.
   * @type {string}
   */
  role: PropTypes.string.isRequired,
  openDrawer: PropTypes.bool.isRequired,
};

export default MainListItems;
