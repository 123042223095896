import SvgIcon from '@material-ui/core/SvgIcon';
import * as React from 'react';

const PaymentInvoiceIconSvg = ({ ...muiSvgIconProps }) => (
  <SvgIcon
    xmlns="http://www.w3.org/2000/svg"
    width={15.398}
    height={18}
    viewBox="0 0 15.398 18"
    {...muiSvgIconProps}
  >
    <path
      data-name="Icon payment-invoice-sign-alt"
      d="M9.949 6.433h5.453V17.05a.842.842 0 01-.281.668.913.913 0 01-.668.281H.953a.909.909 0 01-.668-.281.84.84 0 01-.283-.668V.95A.84.84 0 01.283.282.911.911 0 01.951.001h8.051V5.45a.882.882 0 00.281.7.912.912 0 00.668.281zm1.617 2.25V8.05a.339.339 0 00-.105-.246.3.3 0 00-.211-.105h-7.1a.3.3 0 00-.211.105.338.338 0 00-.105.246v.633q0 .177.105.211a.3.3 0 00.211.105h7.1a.3.3 0 00.211-.105c.07-.023.105-.094.105-.211zm0 2.566v-.633a.339.339 0 00-.105-.246.288.288 0 00-.211-.07h-7.1a.286.286 0 00-.211.07.338.338 0 00-.105.246v.634a.338.338 0 00.105.246.531.531 0 00.211.07h7.1a.535.535 0 00.211-.07.338.338 0 00.105-.246zm0 2.566v-.633a.339.339 0 00-.105-.246.542.542 0 00-.211-.07h-7.1a.536.536 0 00-.211.07.338.338 0 00-.105.246v.633a.338.338 0 00.105.246.531.531 0 00.211.07h7.1a.535.535 0 00.211-.07.338.338 0 00.105-.246zM14.73 4.78a4.29 4.29 0 01.281.352h-4.746V.386a4.434 4.434 0 01.352.281L14.73 4.78z"
      fill="#515a6a"
    />
  </SvgIcon>
);

PaymentInvoiceIconSvg.defaultProps = {
};

PaymentInvoiceIconSvg.propTypes = {
};

export default PaymentInvoiceIconSvg;
