import {
  pipeAwait,
} from 'jsutils';
import * as R from 'ramda';

import {
  graphqlNormalize,
} from '../../modules/apollo/apollo';
import {
  returnAction,
} from '../store_utils';

import {
  graphqlCreateOrder,
  graphqlGetOrders,
} from './graphql/services';
import * as types from './types';

export const createOrder = R.curry(
  async (
    userId,
    quantity,
    payment,
    detail,
    orderType,
  ) => pipeAwait(
    graphqlCreateOrder,
    graphqlNormalize('createOrder'),
    returnAction(types.CREATE_ORDER_SUCCESS),
  )({
    createOrderInput: {
      userId,
      quantity,
      payment,
      ...detail,
      orderType,
    },
  }),
);

const orderTypeToActionType = {
  NODE_PROVIDER_MINING: types.GET_NODE_PROVIDER_MINING_ORDERS_SUCCESS,
  NODE_PROVIDER_NEUNODE: types.GET_NODE_PROVIDER_NEUNODE_ORDERS_SUCCESS,
  NODE_PROVIDER_NEUNODE_ETH2: types.GET_NODE_PROVIDER_NEUNODE_ETH2_ORDERS_SUCCESS,
  LENDING: types.GET_LENDING_ORDERS_SUCCESS,
};
export const getOrders = R.curry(
  async (
    userId,
    orderType,
  ) => {
    const actionType = orderTypeToActionType[orderType];

    const orderTypeToUse = orderType ? {
      orderType,
    } : {};

    return pipeAwait(
      graphqlGetOrders,
      graphqlNormalize('getOrders'),
      returnAction(actionType || types.GET_ORDERS_SUCCESS),
    )({
      getOrdersInput: {
        data: {
          userId,
          ...orderTypeToUse,
        },
      },
    });
  },
);
