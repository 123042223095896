import { isFunction, isObject, toString } from 'lodash';
import * as R from 'ramda';
import * as RA from 'ramda-adjunct';
import { useState } from 'react';

const convertFunctionsToString = (functions) => R.map(toString)(functions);
const getStateFuntionsProps = (state) => R.filter(isFunction)(state);
const getStateNonFuntionsProps = (state) => R.filter((x) => !isFunction(x))(state);

const getStateFunctionsAsStrings = (state) => R.pipe(getStateFuntionsProps, convertFunctionsToString)(state);
const isStatesObjLike = R.curry((oldState, newState) => RA.isObjLike(oldState) && RA.isObjLike(newState));
const compareStates = R.curry((oldState, newState) => {
  if (!isStatesObjLike(oldState, newState)) return R.equals(oldState, newState);
  const oldStateFuntions = getStateFunctionsAsStrings(oldState);
  const newStateFunctions = getStateFunctionsAsStrings(newState);
  const oldStateNonFunctions = getStateNonFuntionsProps(oldState);
  const newStateNonFunctions = getStateNonFuntionsProps(newState);
  return R.equals(oldStateFuntions, newStateFunctions) && R.equals(oldStateNonFunctions, newStateNonFunctions);
});
export default function useSmartSetState(defState) {
  const [state, setState] = useState(defState);

  const smartSetState = (newState) => {
    const stateToUse = isFunction(newState) ? newState(state) : newState;
    if (!compareStates(state, stateToUse)) {
      if (isObject(state) && !Array.isArray(state)) {
        return setState((oldState) => R.mergeDeepRight(oldState, stateToUse));
      }
      return setState(stateToUse);
    }
  };
  return [state, smartSetState];
}
