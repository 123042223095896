export const EXTRACT_DEPOSIT_KEYS = 'EXTRACT_DEPOSIT_KEYS';

export const UPDATE_TRANSACTION_STATUS = 'UPDATE_TRANSACTION_STATUS';

export const RESET_DEPOSIT = 'RESET_DEPOSIT';

export const CREATE_DEPOSIT_SUCCESS = 'CREATE_DEPOSIT_SUCCESS';

export const INSERT_NODE_DATA = 'INSERT_NODE_DATA';

export const DEPOSIT_SUCCESS = 'DEPOSIT_SUCCESS';
