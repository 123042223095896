import { makeStyles } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import whyDidYouRender from '@welldone-software/why-did-you-render';
import getSymbolFromCurrency from 'currency-symbol-map';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import React from 'react';
import Moment from 'react-moment';

import {
  useIsMobile,
} from '../../../hooks/hooks';
import { CloseIconButton } from '../../MaterialUiCustom/MaterialUiCustom';
import { orderDetailsStyles } from '../styles';

if (process.env.REACT_APP_ENV !== 'prod') {
  whyDidYouRender(React);
}

const useStyles = makeStyles(orderDetailsStyles);

const OrderDetails = (props) => {
  const {
    data: orderData,
    onClose,
    open,
    t,
  } = props;
  const classes = useStyles();
  const isMobileDevice = useIsMobile();
  const orderDataToUse = R.omit(['timeline', 'orderTotalAmount'], orderData);

  const orderDataLabels = {
    orderId: t('Order ID'),
    email: t('User Email'),
    orderType: t('Order Type'),
    quantity: t('Quantity'),
    orderTotalAmount: t('Total Amount'),
    orderDate: t('Order Date'),
    trialDays: t('Trial Days'),
    dateTrialEnd: t('Trial End Date'),
    contractSignDate: t('Contract Date'),
    paymentMethod: t('Payment Method'),
    paymentDate: t('Payment Date'),
    metaData: t('Metadata'),
  };

  const formatMonetaryData = R.curry((currency, amount) => {
    const currencySymbol = getSymbolFromCurrency(currency);
    return `${currencySymbol}${amount}`;
  });

  const formatDate = R.curry((dateInput) => (
    dateInput
      ? (
        <Moment format="MMM D, YYYY">
          {dateInput}
        </Moment>
      ) : 'N/A'
  ));

  const formatOrderDataItemValue = R.curry((
    orderDataItemKey,
    orderDataItemValue,
  ) => {
    const dateTypeData = ['orderDate', 'contractSignDate', 'paymentDate', 'dateTrialEnd'];
    const monetaryData = ['orderTotalAmount'];
    if (R.includes(orderDataItemKey, dateTypeData)) {
      return formatDate(orderDataItemValue);
    }
    if (R.includes(orderDataItemKey, monetaryData)) {
      return formatMonetaryData('JPY', orderDataItemValue);
    }
    return orderDataItemValue || 'N/A';
  });

  const generateUiForOrderDataItem = R.curry((
    orderDataItemValue,
    orderDataItemKey,
  ) => {
    const formatedOrderDataItemValue = formatOrderDataItemValue(
      orderDataItemKey, orderDataItemValue,
    );
    return (
      <Grid container item spacing={7} key={orderDataItemKey}>
        <Grid xs={4} item>
          <Typography color="textSecondary">
            {orderDataLabels[orderDataItemKey]}
          </Typography>
        </Grid>
        <Grid xs={8} item>
          <Typography align="left">
            {formatedOrderDataItemValue}
          </Typography>
        </Grid>
      </Grid>
    );
  });
  const generateUiForOrderData = () => R.pipe(
    R.mapObjIndexed(generateUiForOrderDataItem),
    R.values,
  )(orderDataToUse);

  return (
    <Dialog
      fullScreen={isMobileDevice}
      fullWidth
      maxWidth="md"
      onClose={onClose}
      open={open}
    >
      <Typography variant="h2" className={classes.dialogTitleText}>
        {t('Order Details')}
      </Typography>
      <CloseIconButton
        onClick={onClose}
        color="default"
        className={classes.closeButton}
      />
      <Paper className={classes.orderDetailsContainer}>
        <Grid
          direction="column"
          alignItems="center"
          spacing={2}
          container
          className={classes.orderDetailsGrid}
        >
          {
          generateUiForOrderData()
        }
        </Grid>
      </Paper>
    </Dialog>
  );
};

OrderDetails.propTypes = {
  data: PropTypes.shape({
    email: PropTypes.string,
    orderType: PropTypes.string,
    quantity: PropTypes.number,
    orderTotalAmount: PropTypes.number,
    orderDate: PropTypes.string,
    contractSignDate: PropTypes.string,
    paymentMethod: PropTypes.string,
    paymentDate: PropTypes.string,
    metadata: PropTypes.object,
  }).isRequired,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  t: PropTypes.func.isRequired,
};

OrderDetails.whyDidYouRender = true;
export default React.memo(OrderDetails);
