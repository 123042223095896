import Box from '@material-ui/core/Box';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import isEmpty from 'lodash/isEmpty';
import isFunction from 'lodash/isFunction';
import * as R from 'ramda';
import React, { useEffect, useState, useCallback } from 'react';

import { ButtonLoading, RoundedButton } from '../../../MaterialUiCustom/MaterialUiCustom';

import MiningAddressCreationAddress from './MiningAddressCreationAddress';

import usePrevious from 'hooks/usePrevious';

const MiningAddressCreationAddresses = ({
  accounts,
  userId,
  onCreateCryptoAddress,
  createCryptoAddressLoading,
  enableWeb3Loading,
  showBtn,
  onSelectAddress,
  description,
  web3,
  setActiveStep,
  isExtensionsInjected,
  handleOnCancel,
}) => {
  const [selectedAddress, setSelectedAddress] = useState('');
  const handleSelectAddress = useCallback((address) => {
    setSelectedAddress(address);
    if (isFunction(onSelectAddress)) {
      onSelectAddress(address);
    }
  }, [onSelectAddress]);
  const prevSelectedAddress = usePrevious(selectedAddress);

  const isAccountEmpty = isEmpty(accounts);

  useEffect(() => {
    // only set default in initial render
    // initial prevSelectedAddress can be an undefined or empty string
    if (!isAccountEmpty && !prevSelectedAddress) {
      const firstAddress = R.pipe(R.head, R.prop('address'))(accounts);
      handleSelectAddress(firstAddress);
    }
  }, [accounts, handleSelectAddress, isAccountEmpty, prevSelectedAddress]);
  const extensionsInjectedButEmptyAccounts = isExtensionsInjected && isAccountEmpty;

  useEffect(() => {
    if (web3 && extensionsInjectedButEmptyAccounts) setActiveStep(0);
  }, [extensionsInjectedButEmptyAccounts, setActiveStep, web3]);
  return (
    <Box>
      {description && (
        <Box>
          <Typography>
            {description}
          </Typography>
        </Box>
      )}
      <Box>
        <List>
          {R.map(
            (account) => {
              const { address, meta } = account;
              const { name } = meta;
              return (
                <MiningAddressCreationAddress
                  key={address}
                  name={name}
                  address={address}
                  selectedAddress={selectedAddress}
                  onClick={handleSelectAddress}
                />
              );
            },
            accounts,
          )}
        </List>
      </Box>
      {showBtn && (
        <Box
          display="flex"
          justifyContent="flex-end"
          flexDirection="row"
        >
          <RoundedButton
            color="default"
            variant="contained"
            onClick={handleOnCancel}
          >
            cancel
          </RoundedButton>
          <ButtonLoading
            onClick={() => onCreateCryptoAddress(
              userId,
              selectedAddress,
              'DOT',
              'PHA',
            )}
            rounded
            loading={Boolean(createCryptoAddressLoading)}
            disabled={enableWeb3Loading}
          >
            confirm
          </ButtonLoading>
        </Box>
      )}
    </Box>

  );
};

export default MiningAddressCreationAddresses;
