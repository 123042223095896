import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import BackspaceOutlinedIcon from '@material-ui/icons/BackspaceOutlined';
import toUpper from 'lodash/fp/toUpper';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';

import { paymentOperations } from '../../store/payment/index';
import CouponDialog from '../CouponDialog/CouponDialog';
import { RoundedButton } from '../MaterialUiCustom/MaterialUiCustom';

import { styles } from './styles';

const useStyles = makeStyles(styles);

const Coupon = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [openCoupon, setopenCoupon] = useState(false);
  const { promotionCode } = useSelector((state) => state.paymentR.paymentDetail);

  const getCouponDialogContent = () => {
    if (openCoupon) {
      return (
        <CouponDialog
          open
          onClose={() => setopenCoupon(false)}
        />
      );
    }

    return null;
  };

  const cancelCoupon = async () => {
    await dispatch(
      paymentOperations.updateStripePromotionCode(''),
    );
  };

  const getCouponContent = () => {
    if (promotionCode) {
      return (
        <Box display="flex">
          <Box mt={0.2}>
            <Typography variant="body2">
              {`${t('Coupon')}:`}
            </Typography>
          </Box>
          <Box ml={0.5} mt={0.2}>
            <Typography variant="body2" style={{ fontWeight: 550 }}>
              {toUpper(promotionCode)}
            </Typography>
          </Box>
          <Box ml={1}>
            <IconButton style={{ padding: 0 }} onClick={cancelCoupon}>
              <BackspaceOutlinedIcon color="error" style={{ fontSize: 20 }} />
            </IconButton>
          </Box>
        </Box>
      );
    }

    return (
      <RoundedButton
        variant="text"
        onClick={() => setopenCoupon(true)}
        className={classes.coupon}
        noMargin
      >
        {t('Have a coupon?')}
      </RoundedButton>
    );
  };

  return (
    <>
      {getCouponContent()}
      {getCouponDialogContent()}
    </>
  );
};

Coupon.propTypes = {
};

export default Coupon;
