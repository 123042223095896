import { Divider, Typography } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import toUpper from 'ramda/src/toUpper';
import React from 'react';

import WalletExplorerUrl from './WalletExplorerUrl';
import { accountPropType } from './propTypes';
import { walletInfoStyles } from './styles';
import ChainIcon from 'components/Chain/ChainIcon';

import { convertCryptoToFiat } from 'components/utils/utils';



const useStyles = makeStyles(walletInfoStyles);

const WalletInfo = ({
  t,
  account,
  balance,
  priceData,
  subChain,
  chain,
}) => {
  const classes = useStyles();
  const {
    meta,
    address,
  } = account;
  const name = meta?.name;

  const { free } = balance;
  const { currency } = priceData;
  const fiatBalance = convertCryptoToFiat(undefined, priceData, free);
  return (
    <Grid container spacing={3} className={classes.walletInfoContainer}>
      <Grid item xs={12} className={classes.iconGrid}>
        <ChainIcon
          image="/img/icon/phala.png"
          title="address"
          className={classes.chainIcon}
        />
      </Grid>
      <Grid item xs={12}>
        <Box>
          <Box>
            <Typography align="center" variant="h3">
              {t('Phala wallet')}
            </Typography>
          </Box>
          {name && (
            <Box mt={1 / 2}>
              <Typography align="center">
                {name}
              </Typography>
            </Box>
          )}
          <Box mt={1} display="flex" justifyContent="center">
            <WalletExplorerUrl address={address} chain={chain} subChain={subChain} t={t} />
          </Box>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Divider />
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          flexDirection="column"
          mt={3}
        >
          <Box>
            <Typography align="center" variant="h3">
              {t('Balance')}
            </Typography>
          </Box>
          <Box mt={1}>
            <Typography align="center">
              {`${free} ${toUpper(subChain) || toUpper(chain)}`}
            </Typography>
          </Box>
          <Box mt={1}>
            <Typography align="center">
              {fiatBalance.toLocaleString('en-US', {
                style: 'currency',
                currency: currency || 'jpy',
              })}
            </Typography>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};
WalletInfo.propTypes = {
  t: PropTypes.func.isRequired,
  account: accountPropType.isRequired,
  priceData: PropTypes.object.isRequired,
  chain: PropTypes.string.isRequired,
  subChain: PropTypes.string.isRequired,
};

export default WalletInfo;
