import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { CopyButton, RoundedButton } from '../MaterialUiCustom/MaterialUiCustom';

import { secretPhraseBoxStyles } from './styles';

const useStyles = makeStyles(secretPhraseBoxStyles);

const SecretPhraseBox = (props) => {
  const {
    secretPhraseGenerated,
    onGenerateSecretPhrase,
    secretPhrase,
    onCopySecretPhrase,
    withPrecedeFn,
  } = props;
  const { t } = useTranslation();
  const classes = useStyles();

  const generateDeployNodeConfigLoading = useSelector((state) => {
    const { generateDeployNodeConfigLoading } = state.loadingR;
    return Boolean(generateDeployNodeConfigLoading);
  });

  const handleOnClick = () => withPrecedeFn(onGenerateSecretPhrase);
  const getContent = (secretPhraseGenerated) => {
    if (!secretPhraseGenerated) {
      return (
        <Paper className={classes.secretPhraseBoxPlain}>
          <RoundedButton
            color="primary"
            variant="contained"
            onClick={handleOnClick}
            loading={generateDeployNodeConfigLoading}
            // className={classes.generateSecretPhraseButton}
            align="center"
          >
            {t('Ok')}
          </RoundedButton>
        </Paper>
      );
    }
    return (
      <Box
        display="flex"
        alignItems="center"
        className={classes.secretPhraseBox}
      >
        <Box>
          <Typography>
            {secretPhrase}
          </Typography>
        </Box>
        <Box>
          <CopyButton
            text={secretPhrase}
            onClick={onCopySecretPhrase}
            color="primary"
          />
        </Box>
      </Box>
    );
  };
  return (
    getContent(secretPhraseGenerated)
  );
};

SecretPhraseBox.propTypes = {
  secretPhraseGenerated: PropTypes.bool,
  onGenerateSecretPhrase: PropTypes.func.isRequired,
  secretPhrase: PropTypes.string.isRequired,
  onCopySecretPhrase: PropTypes.func.isRequired,
  withPrecedeFn: PropTypes.func.isRequired,
};

export default SecretPhraseBox;
