import { normalize, schema } from 'normalizr';
import * as R from 'ramda';

export const normalizeNodeListData = R.curry((nodeListData) => {
  const userSchema = new schema.Entity('users', {}, { idAttribute: 'userId' });
  const nodesSchema = new schema.Entity('nodes', { user: userSchema }, { idAttribute: 'nodeId' });
  const nodeListSchmea = { items: [nodesSchema] };

  normalize(nodeListData, nodeListSchmea);
});

export const formatNodeListPayload = R.curry((payload) => {
  const entities = R.view(R.lensProp('entities'), payload);
  const result = R.view(R.lensProp('result'), payload);
  const nodeList = R.pipe(
    R.omit(['meta']),
    (result) => ({ result }),
    R.mergeLeft({ entities }),
  )(result);
  const totalNodes = R.view(R.lensPath(['result', 'meta', 'totalItems']), payload);
  return {
    totalNodes,
    nodeList,
  };
});

export const formatMonthlyReportPayload = R.curry((payload) => {
  const { startTime, endTime, usersEarnings } = payload;
  const result = {};
  R.forEach(
    (eachUserEarning) => {
      const { userId, ununifiDateCreated, earnings } = eachUserEarning;
      const formatedEarnings = R.map(
        (earning) => ({
          ...earning,
          ununifiDateCreated,
          startTime,
          endTime,
        }),
      )(earnings);
      result[userId] = formatedEarnings;
    },
  )(usersEarnings);
  return result;
});
