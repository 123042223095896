import SvgIcon from '@material-ui/core/SvgIcon';
import * as React from 'react';

const ServerIconSvg = (props) => {
  const { ...muiSvgIconProps } = props;
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      width={22.5}
      height={19.5}
      viewBox="0 0 22.5 19.5"
      {...muiSvgIconProps}
    >
      <g
        data-name="Icon feather-server"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
      >
        <path
          data-name="Path 146"
          d="M2.85.75h16.8a1.967 1.967 0 012.1 1.8v3.6a1.967 1.967 0 01-2.1 1.8H2.85a1.967 1.967 0 01-2.1-1.8v-3.6a1.967 1.967 0 012.1-1.8z"
        />
        <path
          data-name="Path 147"
          d="M2.85 11.55h16.8a1.967 1.967 0 012.1 1.8v3.6a1.967 1.967 0 01-2.1 1.8H2.85a1.967 1.967 0 01-2.1-1.8v-3.6a1.967 1.967 0 012.1-1.8z"
        />
        <path data-name="Path 148" d="M4.95 4.35h0" />
        <path data-name="Path 149" d="M4.95 15.15h0" />
      </g>
    </SvgIcon>
  );
};

ServerIconSvg.defaultProps = {
};

ServerIconSvg.propTypes = {
};

export default ServerIconSvg;
