import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';

import {
  useAddFunctionToRef,
} from '../../hooks/hooks';
import { referralOperations } from '../../store/referral/index';
import CustomTabs from '../Tabs/CustomTabs';

import ReferralCommissions from './ReferralCommissions';
import ReferralInfo from './ReferralInfo';
import ReferralReferrals from './ReferralReferrals';

// store

const Referral = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { userId } = useSelector((state) => state.userR.user);
  const {
    currency,
    total,
    paid,
    unpaid,
    visitors,
    leads,
    conversions,
  } = useSelector((state) => state.referralR.affiliate);

  const {
    getAffiliateLoading,
  } = useSelector((state) => state.loadingR);

  const onFetchAffiliate = useAddFunctionToRef(async (userId) => {
    dispatch(referralOperations.getAffiliate(userId));
  });

  useEffect(() => {
    onFetchAffiliate(userId);
  }, [onFetchAffiliate, userId]);

  const getContent = () => (getAffiliateLoading
    ? (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <CircularProgress />
      </Box>
    )
    : (
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <ReferralInfo />
        </Grid>
        <Grid item xs={12}>
          <ReferralCommissions commissionStats={
            {
              total: total.toLocaleString('en-US', {
                style: 'currency',
                currency,
              }),
              paid: paid.toLocaleString('en-US', {
                style: 'currency',
                currency,
              }),
              unpaid: unpaid.toLocaleString('en-US', {
                style: 'currency',
                currency,
              }),
            }
          }
          />
        </Grid>
        <Grid item xs={12}>
          <ReferralReferrals
            visitors={visitors}
            leads={leads}
            conversions={conversions}
          />
        </Grid>

      </Grid>
    ));

  const tabs = [
    {
      label: t('Referral'),
      content: getContent(),
    },
  ];

  return (
    <CustomTabs
      tabs={tabs}
    />
  );
};

export default Referral;
