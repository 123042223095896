import gql from 'graphql-tag';

export const updateUserNodeMutation = gql`
  mutation updateUserNode(
    $updateUserNodeInput: UpdateUserNodeInput!
  )
  {
    updateUserNode(
      updateUserNodeInput: $updateUserNodeInput
    ) {
      nodeId
      nodeName
      nodeName
      dateCreated
      dateUpdated
      status
      nodeType
      subNode
    }
  }
`;

export const updateUserNodesMutation = gql`
  mutation updateUserNodes(
    $updateUserNodesInput: UpdateUserNodesInput!
  )
  {
    updateUserNodes(
      updateUserNodesInput: $updateUserNodesInput
    ) {
      nodeId
      nodeName
      nodeName
      dateCreated
      dateUpdated
      status
      nodeType
      subNode
    }
  }
`;
