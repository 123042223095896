export const sideBarsItemIndices = {
  'admin/dashboard': 0,
  'admin/orders': 1,
  'admin/earning': 2,
  'admin/pools': 3,
  dashboard: 0,
  'my-nodes': 0,
  investment: 0,
  'investment/neunode': 0,
  'investment/neunode-eth2': 0,
  'investment/mining': 0,
  'investment/ununifi-validator': 0,
  'investment/eth2-validator': 0,
  protocols: 1,
  'node-owner': 1,
  'node-owner/neunode': 1,
  'node-owner/phala-mining': 1,
  payment: 4,
  referral: 5,
  wallet: 6,
};
