import Box from '@material-ui/core/Box';
import Checkbox from '@material-ui/core/Checkbox';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import classnames from 'classnames';
import camelCase from 'lodash/camelCase';
import isEmpty from 'lodash/isEmpty';
import isFunction from 'lodash/isFunction';
import isString from 'lodash/isString';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { CustomError } from '../../modules/error_utils';
import { secretPhraseOperations } from '../../store/secret_phrase/index';
import { ButtonLoading, RoundedButton } from '../MaterialUiCustom/MaterialUiCustom';

import SecretPhraseBox from './SecretPhraseBox';
import SecretPhraseConfirmation from './SecretPhraseConfirmation';

// store

import { secretPhraseGeneratorStyles } from './styles';

// others

const useStyles = makeStyles(secretPhraseGeneratorStyles);

const SecretPhraseGeneratorContent = (props) => {
  const {
    t,
    onGenerateSecretPhrase,
    secretPhrase,
    onConfirmSuccess,
    customClasses,
    firstStepSecondaryBtnAction,
    onGenerateSecretPhraseSuccess,
    handleNext,
    handleBack,
    getStepTitle,
    activeStep,
    getStepDescription,
    stepsDescriptions,
    getHintText,
    stepsHintText,
    setSecretPhraseGenerated,
    secretPhraseGenerated,
    extraStepsContent,
    extraStepsActions,
    withExtraSteps,
    regenerateSecretPhraseWhenGoBack,
    stepActionPrecedeFn,
    precedeFnCondition,
    resetSecretPhrase,
    mustCopy,
  } = props;
  const classes = useStyles();
  /*  GENERATE SECRET PHRASE */
  const [secretPhraseSaved, setSecretPhraseSaved] = useState(false);
  const [confirmPhraseSaved, setConfirmPhraseSaved] = useState(false);
  const [inputSecretPhrase, setInputSecretPhrase] = useState('');

  const dispatch = useDispatch();
  const { secretPhrase: secretPhraseHd } = useSelector((state) => state.secretPhraseR);
  const secretPhraseToUse = secretPhrase || secretPhraseHd;
  const theme = useTheme();
  const isMobileDevice = useMediaQuery(theme.breakpoints.down('sm'));
  const handleGenerateSecretPhrase = async () => {
    const onGenerateSecretPhraseToUse = isFunction(onGenerateSecretPhrase)
      ? onGenerateSecretPhrase
      : () => dispatch(secretPhraseOperations.generateSecretPhrase());
    await onGenerateSecretPhraseToUse();
    setSecretPhraseGenerated(true);
    if (isFunction(onGenerateSecretPhraseSuccess)) {
      onGenerateSecretPhraseSuccess();
    }
  };

  /* OTHER METHODS */
  const handleClickBack = (regenerateSecretPhraseWhenGoBack) => {
    if (!regenerateSecretPhraseWhenGoBack) {
      if (isFunction(resetSecretPhrase)) {
        resetSecretPhrase();
      } else {
        dispatch(secretPhraseOperations.resetSecrePhrase());
      }
      setSecretPhraseGenerated(false);
    }
    setInputSecretPhrase('');
    setSecretPhraseSaved(false);
    setConfirmPhraseSaved(false);
    handleBack();
  };

  const onCopySecretPhrase = () => {
    setSecretPhraseSaved(true);
  };

  const onConfirmPhraseSavedBeckBoxClick = (event) => {
    setConfirmPhraseSaved(event.target.checked);
  };

  const onInputSecretPhraseChange = (event) => {
    setInputSecretPhrase(event.target.value);
  };
  /* CONFIRM SECRET PHRASE */
  // start with null then change to boolean
  const [secretPhraseConfirmed, setSecretPhraseConfirmed] = useState(null);
  const handleOnConfirmClick = R.curry(
    (secretPhrase, inputSecretPhrase) => {
      const secretPhraseConfirmed = inputSecretPhrase.trim() === secretPhrase;
      setSecretPhraseConfirmed(secretPhraseConfirmed);
      return secretPhraseConfirmed;
    },
  );

  const onConfirmClick = (secretPhrase, inputSecretPhrase, onConfirmSuccess) => {
    const secretPhraseConfirmed = handleOnConfirmClick(
      secretPhrase,
      inputSecretPhrase,
    );
    if (!secretPhraseConfirmed) return;
    if (isFunction(onConfirmSuccess)) {
      onConfirmSuccess({ secretPhrase, secretPhraseConfirmed });
    }
    if (!isEmpty(extraStepsContent)) {
      handleNext();
    }
  };

  const createWithPrecedeFn = R.curry(
    (stepActionPrecedeFn, precedeFnCondition, stepActionFn) => {
      if (precedeFnCondition) {
        return stepActionPrecedeFn();
      }
      stepActionFn();
    },
  );
  const withPrecedeFn = createWithPrecedeFn(stepActionPrecedeFn, precedeFnCondition);

  const stepContents = {
    0: (
      <SecretPhraseBox
        secretPhraseGenerated={secretPhraseGenerated}
        secretPhrase={secretPhraseToUse}
        onCopySecretPhrase={onCopySecretPhrase}
        onGenerateSecretPhrase={handleGenerateSecretPhrase}
        withPrecedeFn={withPrecedeFn}
      />
    ),
    1: (
      <SecretPhraseConfirmation
        inputSecretPhrase={inputSecretPhrase}
        onInputSecretPhraseChange={onInputSecretPhraseChange}
      />

    ),
  };
  const withStepContents = R.merge(stepContents, extraStepsContent || {});

  const createGetStepContent = R.curry(
    (stepContents, activeStep) => {
      const Content = stepContents[activeStep];
      if (!Content) throw new CustomError('Step content not found');
      return Content;
    },
  );
  const getStepContent = createGetStepContent(withStepContents);

  const enableFirstStepSecondaryBtn = isFunction(firstStepSecondaryBtnAction);

  const disableConfirmButtonWhenConfiming = mustCopy
    ? !confirmPhraseSaved || !secretPhraseSaved
    : !confirmPhraseSaved;
  const stepActions = {
    0: {
      primary: () => withPrecedeFn(handleNext),
      secondary: enableFirstStepSecondaryBtn ? firstStepSecondaryBtnAction : () => { },
      btnTexts: {
        primary: t('confirm'),
        secondary: t('back'),
      },
      disabled: disableConfirmButtonWhenConfiming,
    },
    1: {
      primary: () => withPrecedeFn(
        () => onConfirmClick(
          secretPhraseToUse,
          inputSecretPhrase,
          onConfirmSuccess,
        ),
      ),
      secondary: () => handleClickBack(regenerateSecretPhraseWhenGoBack),
      btnTexts: {
        primary: t('confirm'),
        secondary: t('back'),
      },
      disabled: isEmpty(inputSecretPhrase),

    },
  };

  const withExtraStepActions = R.merge(stepActions, extraStepsActions || {});
  const createGetStepAction = R.curry(
    (stepActions, activeStep) => {
      const stepAction = stepActions[activeStep];
      if (isEmpty(stepAction)) throw new CustomError('Step action not found');
      return stepAction;
    },
  );
  const getStepAction = createGetStepAction(withExtraStepActions);

  const btnActionStringCase = {
    backAction: handleBack,
    nextAction: handleNext,
    none: () => { },
  };
  const createGetStepActionFn = R.curry((key, activeStep) => {
    const stepAction = getStepAction(activeStep);
    const subAction = stepAction[key];
    const { isFormAction } = stepAction;
    const subActionExist = isFunction(subAction) || isString(subAction);
    if (!subActionExist && !isFormAction) throw new CustomError(`Step [${key}] not found`);
    if (isString(subAction)) {
      const camelCaseSubAction = camelCase(subAction);
      const actionFn = btnActionStringCase[camelCaseSubAction];
      return isFunction(actionFn) ? actionFn() : btnActionStringCase.none;
    }
    return subAction();
  });

  const getStepPrimaryAction = createGetStepActionFn('primary');
  const getStepSecondaryAction = createGetStepActionFn('secondary');

  const createGetStepActionProp = R.curry((key, activeStep) => {
    const stepAction = getStepAction(activeStep);
    const item = stepAction[key];
    return item;
  });
  const getIsFormAction = createGetStepActionProp('isFormAction');
  const getFormId = createGetStepActionProp('formId');
  const getIsExtra = createGetStepActionProp('isExtra');
  // if provided this secondPrimaryAction
  // the primary button can perform 2 actions. one after another
  // related props: getPrimaryActionDone, primary2ndAction
  const getSecondPrimaryAction = createGetStepActionProp('secondPrimaryAction');
  const getPrimaryActionDone = createGetStepActionProp('primaryActionDone');
  const getBtnDisabled = createGetStepActionProp('disabled');
  const getBtnLoading = R.pipe(createGetStepActionProp('loading'), Boolean);

  const createGetBtnTexts = R.curry((key, activeStep) => {
    const stepAction = getStepAction(activeStep);
    const btnTextsData = stepAction.btnTexts;
    if (!btnTextsData) throw new CustomError(`btnTexts expected object but got ${btnTextsData}`);
    const btnText = btnTextsData[key];
    return btnText;
  });
  const getPrimaryBtnText = createGetBtnTexts('primary');
  const getSecondaryBtnText = createGetBtnTexts('secondary');
  const getPrimary2ndActionText = createGetBtnTexts('primary2ndAction');

  const handlePrimaryBtnText = (activeStep) => {
    const secondPrimaryAction = getSecondPrimaryAction(activeStep);
    const primaryActionDone = getPrimaryActionDone(activeStep);
    if (!primaryActionDone) return getPrimaryBtnText(activeStep);
    if (secondPrimaryAction) {
      return getPrimary2ndActionText(activeStep);
    }
  };
  const handleActionType = (activeStep) => {
    const isFormAction = getIsFormAction(activeStep);
    const formId = getFormId(activeStep);
    const primaryActionDone = getPrimaryActionDone(activeStep);
    if (primaryActionDone) return () => { };
    return !isFormAction
      ? getStepPrimaryAction(activeStep)
      : document
        .getElementById(formId)
        .dispatchEvent(new Event('submit', { cancelable: true }));
  };

  const handlePrimaryOnClick = (withExtraSteps, activeStep) => {
    handleActionType(activeStep);
    const isExtra = getIsExtra(activeStep);
    const secondPrimaryAction = getSecondPrimaryAction(activeStep);
    const stepNums = R.keys(withExtraSteps);
    const isLastStep = activeStep === stepNums.length - 1;
    const isExtraAndNotLastStep = isExtra && !isLastStep;

    if (isExtraAndNotLastStep && !secondPrimaryAction) {
      return handleNext();
    }
    const primaryActionDone = getPrimaryActionDone(activeStep);

    if (isExtraAndNotLastStep && primaryActionDone) {
      return handleNext();
    }
  };

  const {
    wrapPaper,
  } = customClasses || {};
  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Box elevation={0} className={wrapPaper || classes.wrapPaper}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Box display="flex" alignItems="center" justifyContent="space-between" flexDirection="row">
                <Box>
                  <Typography variant="h3">{getStepTitle(activeStep)}</Typography>
                </Box>
                {/* <Box>
                  <CloseIconButton
                    onClick={onClose}
                    color="default"
                  />
                </Box> */}
              </Box>

            </Grid>
            <Grid item xs={12}>
              <Typography variant="body1">
                {getStepDescription(stepsDescriptions, activeStep, secretPhraseGenerated)}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              {getStepContent(activeStep)}
            </Grid>
            <Grid item xs={12} className={classes.formSection}>
              <Grid
                container
                justifyContent={isMobileDevice ? 'center' : 'space-between'}
                direction={isMobileDevice ? 'column' : 'row'}
                alignItems="flex-end"
                style={{ width: '100%' }}
              >
                <Grid item xs={12}>
                  <Typography
                    variant="body1"
                    className={classnames(classes.drawer, {
                      [classes.savedSecretPhraseSuccess]: secretPhraseSaved,
                      [classes.confirmSecretPhraseError]: secretPhraseConfirmed === false,
                      [{}]: !secretPhraseSaved,
                    })}
                  >
                    {getHintText(stepsHintText, activeStep, secretPhraseSaved, secretPhraseConfirmed)}
                  </Typography>
                  {
                    activeStep === 0 && (
                      <Box display="flex">
                        <Box>
                          <Checkbox
                            checked={confirmPhraseSaved}
                            onChange={onConfirmPhraseSavedBeckBoxClick}
                            disabled={!secretPhraseGenerated}
                          />
                        </Box>
                        <Box mt={1}>
                          <Typography
                            variant="body1"
                          >
                            {t("I've written down my secret phrase and I understand that it can not be retrieved")}
                          </Typography>
                        </Box>
                      </Box>
                    )
                  }
                </Grid>
                <Grid item xs={12} className={classes.buttonsGrid}>
                  <Box
                    display="flex"
                    justifyContent="flex-end"
                    width="100%"
                  >
                    {/* Only show this btn at step 0 */}
                    {/* {secondarybtnText && activeStep === 0 && (
                    <RoundedButton
                      color="default"
                      variant="contained"
                      {...R.omit(['sencondarybtnTexts'], firstStepSecondaryBtnProps)}
                    >
                      {secondarybtnText}
                    </RoundedButton>
                    )} */}
                    {
                      (activeStep > 0 || enableFirstStepSecondaryBtn)
                      && (
                        <Box mr={1}>
                          <RoundedButton
                            color="secondary"
                            onClick={() => getStepSecondaryAction(activeStep)}
                          >
                            {getSecondaryBtnText(activeStep)}
                          </RoundedButton>
                        </Box>

                      )
                    }

                    <Box>
                      <ButtonLoading
                        color="primary"
                        variant="contained"
                        disabled={getBtnDisabled(activeStep)}
                        loading={getBtnLoading(activeStep)}
                        onClick={() => handlePrimaryOnClick(
                          withExtraSteps,
                          activeStep,
                        )}
                        rounded
                      >
                        {handlePrimaryBtnText(activeStep)}
                      </ButtonLoading>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Grid>
    </Grid>
  );
};

SecretPhraseGeneratorContent.propTypes = {
  t: PropTypes.func.isRequired,
  onGenerateSecretPhrase: PropTypes.func,
  secretPhrase: PropTypes.string,
  onConfirmSuccess: PropTypes.func.isRequired,
  customClasses: PropTypes.shape({
    wrapPaper: PropTypes.string.isRequired,
  }),
  firstStepSecondaryBtnAction: PropTypes.func,
  onGenerateSecretPhraseSuccess: PropTypes.func,
  extraSteps: PropTypes.shape({
    2: PropTypes.shape({
      title: PropTypes.string.isRequired,
      descriptions: PropTypes.shape({
        primary: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
    3: PropTypes.shape({
      title: PropTypes.string.isRequired,
      descriptions: PropTypes.shape({
        primary: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
    4: PropTypes.shape({
      title: PropTypes.string.isRequired,
      descriptions: PropTypes.shape({
        primary: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
  }),
  extraStepsContent: PropTypes.shape({
    2: PropTypes.element.isRequired,
    3: PropTypes.element.isRequired,
    4: PropTypes.element.isRequired,
  }),
  extraStepsActions: PropTypes.shape({
    2: PropTypes.shape({
      secondary: PropTypes.string.isRequired,
      isFormAction: PropTypes.bool.isRequired,
      formId: PropTypes.string.isRequired,
      isExtra: PropTypes.bool.isRequired,
      btnTexts: PropTypes.shape({
        primary: PropTypes.string.isRequired,
        secondary: PropTypes.string.isRequired,
      }).isRequired,
      disabled: PropTypes.bool.isRequired,
    }).isRequired,
    3: PropTypes.shape({
      primary: PropTypes.string.isRequired,
      secondary: PropTypes.string.isRequired,
      isExtra: PropTypes.bool.isRequired,
      btnTexts: PropTypes.shape({
        primary: PropTypes.string.isRequired,
        secondary: PropTypes.string.isRequired,
      }).isRequired,
      disabled: PropTypes.bool.isRequired,
    }).isRequired,
    4: PropTypes.shape({
      primary: PropTypes.func.isRequired,
      secondary: PropTypes.string.isRequired,
      isExtra: PropTypes.bool.isRequired,
      btnTexts: PropTypes.shape({
        primary: PropTypes.string.isRequired,
        secondary: PropTypes.string.isRequired,
      }).isRequired,
      loading: PropTypes.bool,
    }),
  }),
  regenerateSecretPhraseWhenGoBack: PropTypes.bool,
  stepActionPrecedeFn: PropTypes.func,
  precedeFnCondition: PropTypes.bool,
  resetSecretPhrase: PropTypes.bool,
  mustCopy: PropTypes.bool,
  handleNext: PropTypes.func.isRequired,
  handleBack: PropTypes.func.isRequired,
  getStepTitle: PropTypes.func.isRequired,
  activeStep: PropTypes.number.isRequired,
  getStepDescription: PropTypes.func.isRequired,
  stepsDescriptions: PropTypes.object,
  getHintText: PropTypes.func.isRequired,
  stepsHintText: PropTypes.object,
  withExtraSteps: PropTypes.shape({
    0: PropTypes.shape({
      title: PropTypes.string.isRequired,
      descriptions: PropTypes.shape({
        primary: PropTypes.string.isRequired,
        secondary: PropTypes.string.isRequired,
      }).isRequired,
      hints: PropTypes.shape({
        hint: PropTypes.string.isRequired,
        successHint: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
    1: PropTypes.shape({
      title: PropTypes.string.isRequired,
      descriptions: PropTypes.shape({
        primary: PropTypes.string.isRequired,
      }).isRequired,
      hints: PropTypes.shape({
        hint: PropTypes.string.isRequired,
        successHint: PropTypes.string.isRequired,
        errorHint: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
    2: PropTypes.shape({
      title: PropTypes.string.isRequired,
      descriptions: PropTypes.shape({
        primary: PropTypes.string.isRequired,
      }).isRequired,
    }),
    3: PropTypes.shape({
      title: PropTypes.string.isRequired,
      descriptions: PropTypes.shape({
        primary: PropTypes.string.isRequired,
      }).isRequired,
    }),
    4: PropTypes.shape({
      title: PropTypes.string.isRequired,
      descriptions: PropTypes.shape({
        primary: PropTypes.string.isRequired,
      }).isRequired,
    }),
  }).isRequired,
  setSecretPhraseGenerated: PropTypes.func.isRequired,
  secretPhraseGenerated: PropTypes.bool.isRequired,
};

SecretPhraseGeneratorContent.defaultProps = {
  onGenerateSecretPhrase: () => {},
  secretPhrase: null,
  customClasses: null,
  firstStepSecondaryBtnAction: () => {},
  onGenerateSecretPhraseSuccess: () => {},
  extraSteps: null,
  extraStepsContent: null,
  extraStepsActions: null,
  regenerateSecretPhraseWhenGoBack: false,
  stepActionPrecedeFn: () => {},
  precedeFnCondition: false,
  resetSecretPhrase: false,
  mustCopy: false,
  stepsDescriptions: null,
  stepsHintText: null,
};

export default SecretPhraseGeneratorContent;
