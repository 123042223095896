import * as R from 'ramda';

export const truncateWalletAddress = R.curry(
  (beginSliceToIndex, endSliceToIndex, address) => (!address
    ? ''
    : (
      `${R.slice(0, beginSliceToIndex, address)}....${R.slice(endSliceToIndex, R.length(address), address)}`
    )),
);

export const formatNodeNameFns = {
  ETH2_VALIDATOR_NODE: R.curry((nodeName) => R.replace(/eth-2-validator-node/, 'e2vn', nodeName)),
  UNUNIFI_VALIDATOR_NODE: R.curry((nodeName) => R.replace(/ununifi-validator-node/, 'ununifivn', nodeName)),
};
