import {
  pipeAwait,
} from 'jsutils';
import * as R from 'ramda';

import {
  graphqlNormalize,
} from '../../modules/apollo/apollo';
import {
  returnAction,
} from '../store_utils';

import {
  graphqlGetUserStripeData,
  graphqlGetUserStripePaymentMethods,
  graphqlCreateUserStripePaymentMethod,
  graphqlDetachStripePaymentMethodMutation,
  graphqlCancelSubscriptionMutation,
  graphqlChangeSubscriptionPlanMutation,
  graphqlValidatePromotionCode,
} from './graphql/services';
import * as types from './types';

export const getUserStripeData = R.curry(
  async (stripeState, userId) => {
    const { stripeCustomerId } = stripeState;
    if (stripeCustomerId) {
      return { type: types.GET_USER_STRIPE_DATA_CANCEL, payload: stripeState };
    }
    return pipeAwait(
      graphqlGetUserStripeData,
      graphqlNormalize('getUserStripeData'),
      returnAction(types.GET_USER_STRIPE_DATA_SUCCESS),
    )({ userId });
  },
);

export const getUserStripePaymentMethods = R.curry(
  async (
    paymentMethodType,
    userId,
  ) => pipeAwait(
    graphqlGetUserStripePaymentMethods,
    graphqlNormalize('getUserStripePaymentMethods'),
    returnAction(types.GET_USER_STRIPE_PAYMENT_METHODS_SUCCESS),
  )({ userId, paymentMethodType }),
);

export const detachStripePaymentMethod = R.curry(
  async (
    paymentMethodType,
    userId,
    stripePaymentMethodId,
  ) => pipeAwait(
    graphqlDetachStripePaymentMethodMutation,
    graphqlNormalize('detachStripePaymentMethod'),
    returnAction(types.DETACH_STRIPE_PAYMENT_METHOD),
  )({
    detachStripePaymentMethodInput: {
      userId,
      stripePaymentMethodId,
    },
  }),
);

export const createUserStripePaymentMethod = R.curry(
  async (
    stripe,
    userId,
    stripeCustomerId,
    card,
  ) => {
    const paymentMethod = await stripe.createPaymentMethod({
      type: 'card',
      card,
      metadata: {
        userId,
      },
    });
    const stripePaymentMethodId = R.view(
      R.lensPath(['paymentMethod', 'id']),
      paymentMethod,
    );
    return pipeAwait(
      graphqlCreateUserStripePaymentMethod,
      graphqlNormalize('createUserStripePaymentMethod'),
      returnAction(types.CREATE_USER_STRIPE_PAYMENT_METHODS_SUCCESS),
    )(
      {
        createUserStripePaymentMethodInput: {
          userId,
          stripePaymentMethodId,
        },
      },
    );
  },
);

export const cancelSubscription = R.curry(
  async (
    subscriptionId,
    userId,
    nodeType,
    orderId,
  ) => pipeAwait(
    graphqlCancelSubscriptionMutation,
    graphqlNormalize('cancelSubscription'),
    returnAction(types.CANCEL_SUBSCRIPTION_SUCCESS),
  )({
    cancelSubscriptionInput: {
      subscriptionId,
      userId,
      nodeType,
      orderId,
    },
  }),
);

export const changeSubscriptionPlan = R.curry(
  async (
    subscriptionId,
    priceId,
  ) => pipeAwait(
    graphqlChangeSubscriptionPlanMutation,
    graphqlNormalize('changeSubscriptionPlan'),
    returnAction(types.CHANGE_SUBSCRIPTION_PLAN_SUCCESS),
  )({
    changeSubscriptionPlanInput: {
      subscriptionId,
      priceId,
    },
  }),
);

export const validatePromotionCode = R.curry(
  async (
    promotionCode,
  ) => pipeAwait(
    graphqlValidatePromotionCode,
    graphqlNormalize('validatePromotionCode'),
    returnAction(types.VALIDATE_PROMOTION_CODE_SUCCESS),
  )({
    validatePromotionCodeInput: {
      promotionCode,
    },
  }),
);
