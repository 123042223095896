const styles = (theme) => ({
  title: {
    width: '100%',
    paddingLeft: theme.spacing(1),
    marginBottom: theme.spacing(-1.5),
  },
  textField: {
    width: '100%',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
    // [theme.breakpoints.down('sm')]: {
    //   width: '100%'
    // }
  },
});

export default styles;
