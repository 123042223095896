import gql from 'graphql-tag';

export const signinMutation = gql`
  mutation signin($email: String!, $password: String!, $role: String!)
  {
    signin(
      signinInput: { 
        email: $email
        password: $password
        role: $role
      }
    ) {
      user {
        userId
        email
        joined
        modified
        lastSignin
        activated
        role
      }
      authTokenData {
        accessToken
        refreshToken
        accessTokenExpiry
        refreshTokenExpiry
        idToken
      }
    }
  }
`;

export const signupMutation = gql`
  mutation signup(
    $email: String!, 
    $password: String!
    $lang: String!
    $adminKey: String!
    $settings: UserSettingsInput!
  )
  {
    signup(
      signupInput: { 
        email: $email
        password: $password
        lang: $lang
        adminKey: $adminKey
        settings: $settings
      }
    ) {
      user {
        userId
        email
        joined
        modified
        lastSignin
        activated
        role
      }
      authTokenData {
        accessToken
        refreshToken
        accessTokenExpiry
        refreshTokenExpiry
        idToken
      }
    }
  }
`;

export const signOutMutation = gql`
  mutation signOut($userId: String!, $idToken: String!)
  {
    signOut(
      signOutInput: {
        userId: $userId
        idToken: $idToken,
      }
    ) {
      isAuthenticated
    }
  }
`;

export const activateUserMutation = gql`
  mutation activateUser($activationId: String!,)
  {
    activateUser(
      activateUserInput: { 
        activationId: $activationId
      }
    ) {
      status
      user {
        userId
        email
        joined
        modified
        lastSignin
        activated
      }
    }
  }
`;

export const validateEmailMutation = gql`
  mutation validateEmail($email: String!,)
  {
    validateEmail(
      validateEmailInput: { 
        email: $email
      }
    ) {
      status
    }
  }
`;

export const validatePasswordMutation = gql`
  mutation validatePassword($activationId: String, $email: String, $password: String!)
  {
    validatePassword(
      validatePasswordInput: { 
        activationId: $activationId
        email: $email
        password: $password
      }
    ) {
      status
    }
  }
`;

export const changeUserEmailMutation = gql`
  mutation changeUserEmail($userId: String!, $newEmail: String!)
  {
    changeUserEmail(
      changeUserEmailInput: { 
        userId: $userId
        newEmail: $newEmail
      }
    ) {
      email
    }
  }
`;

export const changeUserPasswordMutation = gql`
  mutation changeUserPassword($userId: String!, $oldPassword: String!, $newPassword: String!)
  {
    changeUserPassword(
      changeUserPasswordInput: { 
        userId: $userId
        oldPassword: $oldPassword
        newPassword: $newPassword
      }
    ) {
      status
    }
  }
`;

export const sendForgotPasswordEmailMutation = gql`
  mutation sendForgotPasswordEmail($email: String!, $lang: String)
  {
    sendForgotPasswordEmail(
      sendForgotPasswordEmailInput: { 
        email: $email
        lang: $lang
      }
    ) {
      status
    }
  }
`;

export const resetPasswordMutation = gql`
  mutation resetPassword($newPassword: String!, $forgotPasswordId: String!)
  {
    resetPassword(
      resetPasswordInput: { 
        newPassword: $newPassword
        forgotPasswordId: $forgotPasswordId
      }
    ) {
      status
    }
  }
`;

export const validateForgotPasswordMutation = gql`
  mutation validateForgotPassword($forgotPasswordId: String!)
  {
    validateForgotPassword(
      validateForgotPasswordInput: { 
        forgotPasswordId: $forgotPasswordId
      }
    ) {
      status
    }
  }
`;

export const resendActivationMutation = gql`
  mutation resendActivation($userId: String!, $lang: String!)
  {
    resendActivation(
      resendActivationInput: { 
        userId: $userId
        lang: $lang
      }
    ) {
      status
    }
  }
`;

export const createFirstSigninMutation = gql`
  mutation createFirstSignin($createFirstSigninInput: CreateFirstSigninInput!)
  {
    createFirstSignin(
      createFirstSigninInput: $createFirstSigninInput
    ) {
      firstSigninId
      userId
      dateCreated
      dateUpdated
      signedIn
    }
  }
`;

export const setUserFirstSignedInMutation = gql`
  mutation setUserFirstSignedIn($setUserFirstSignedInInput: SetUserFirstSignedInInput!)
  {
    setUserFirstSignedIn(
      setUserFirstSignedInInput: $setUserFirstSignedInInput
    ) {
      firstSigninId
      userId
      email
      dateCreated
      dateUpdated
      signedIn
    }
  }
`;
