import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import whyDidYouRender from '@welldone-software/why-did-you-render';
import isNil from 'lodash/isNil';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';

import { useAddFunctionToRef } from '../../../hooks/hooks';
import { walletConnectOperations } from '../../../store/wallet_connect/index';
import { getENVWhenChainChange } from '../../ConnectToWallet/utils';
import SectionCard from '../../SectionCard/SectionCard';

import { colorSet, explorersUrls } from './constants';
import {
  eth2DeployDepositStyles,
} from './styles';

import WalletAccount from 'components/WalletAccount/WalletAccount';

const { REACT_APP_ENV } = process.env;

if (REACT_APP_ENV !== 'prod') {
  whyDidYouRender(React);
}

const useStyles = makeStyles(eth2DeployDepositStyles);

const { REACT_APP_CONTRACT_ADDRESS } = process.env;
const depositContractAddress = REACT_APP_CONTRACT_ADDRESS;

const Eth2DeployDeposit = (props) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const { t } = useTranslation();

  const {
    quantity,
    nodeType,
    requiredBalance,
    account,
    chainId,
    balance,
  } = props;

  const depositKeys = useSelector((state) => state.depositR.depositData.depositKeys);
  const gasPrices = useSelector((state) => state.walletConnectR.gasPrices);
  const { gasPriceGwei: gasPrice } = gasPrices[nodeType];

  const getLeftContent = (text) => (
    <Typography variant="h6" className={classes.leftContent}>
      {text}
    </Typography>
  );
  const getAddressRightContent = (title, address) => (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Typography color="textSecondary" style={{ fontWeight: 600 }}>
          {title}
        </Typography>
      </Grid>
      <Grid item xs={12} style={{ textOverflow: 'ellipsis' }}>
        {address}
      </Grid>
    </Grid>
  );
  const getSummaryRightContent = (summaryData) => R.map((eachSummaryContent) => (
    <Grid
      container
      justifyContent="space-between"
      spacing={1}
      key={eachSummaryContent.title}
    >
      <Grid item>
        <Typography style={{ fontWeight: 600 }}>
          {eachSummaryContent.title}
        </Typography>
      </Grid>
      <Grid item xs={3}>
        {eachSummaryContent.value}
      </Grid>
    </Grid>
  ), summaryData);

  const summaryData = [
    {
      title: t('Number of Validator'),
      value: quantity,
    },
    {
      title: t('Transaction value'),
      value: `${(requiredBalance).toFixed(0)} ETH`,
    },
    {
      title: t('Gas price'),
      value: `${gasPrice} Gwei`,
    },
  ];

  const getGasPrice = useAddFunctionToRef(async () => {
    await dispatch(walletConnectOperations.getGasPrice(nodeType));
  });

  const getDepositStatusContent = (depositKeys) => R.addIndex(R.map)((eachDeposit, index) => (
    <Grid
      container
      justifyContent="space-between"
      spacing={1}
      key={`${eachDeposit.pubkey.substring(0, 4).title}_${index}`}
    >
      <Grid item>
        <Typography style={{ fontWeight: 500 }}>
          {`node ${index + 1}`}
        </Typography>
      </Grid>
      <Grid item xs={3}>
        <Box color={colorSet[eachDeposit.transactionStatus]}>
          <Typography style={{ fontWeight: 600 }}>
            {eachDeposit.transactionStatus}
          </Typography>
        </Box>
      </Grid>
    </Grid>
  ), depositKeys);

  useEffect(() => {
    getGasPrice();
  }, [getGasPrice]);
  const chains = {
    dev_or_stage: 5,
    prod: 1,
  };
  const handleAddressUrl = R.curry((chainId) => {
    const chainData = explorersUrls[chainId];
    if (!chainData) {
      const envKey = getENVWhenChainChange();
      const chainId = chains[envKey];
      const chainData = explorersUrls[chainId];
      const { address } = chainData;
      return address;
    }
    const { address } = chainData;
    return address;
  });
  const accountTypographyProps = { noWrap: true, styles: { textOverflow: 'ellipsis' }, variant: 'body1' };

  const walletAccountProps = {
    t,
    account,
    truncate: false,
    accountTypographyProps,
  };

  const getAccountLink = (account) => (account
    ? (
      <Link
        href={`${handleAddressUrl(chainId)}/${account}`}
        target="_blank"
      >
        <WalletAccount
          {...walletAccountProps}
        />
      </Link>
    )
    : (
      <WalletAccount
        {...walletAccountProps}
      />
    ));
  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <Typography variant="h4" className={classes.contentTitle}>
          {t('Preview your transaction')}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <SectionCard
          leftContentGridWidths={{ xs: 4 }}
          rightContentGridWidths={{ xs: 8 }}
          leftContent={getLeftContent('FROM')}
          rightContent={getAddressRightContent(
            `${t('Balance')}: ${!isNil(balance) ? balance : 0} ETH`,
            getAccountLink(account),
          )}
          disableBackground
          rootClassName={classes.item}
        />
      </Grid>
      <Grid item xs={12}>
        <Divider />
      </Grid>
      <Grid item xs={12}>
        <SectionCard
          leftContentGridWidths={{ xs: 4 }}
          rightContentGridWidths={{ xs: 8 }}
          leftContent={getLeftContent('TO')}
          rightContent={getAddressRightContent(
            t('Deposit Contract'),
            <Link
              href={`${handleAddressUrl(chainId)}/${depositContractAddress}`}
              target="_blank"
            >
              <WalletAccount
                t={t}
                account={depositContractAddress}
                truncate={false}
                accountTypographyProps={accountTypographyProps}
              />
            </Link>,
          )}
          disableBackground
          rootClassName={classes.item}
        />
      </Grid>
      <Grid item xs={12}>
        <Divider />
      </Grid>
      <Grid item xs={12}>
        <SectionCard
          leftContentGridWidths={{ xs: 12, sm: 4 }}
          rightContentGridWidths={{ xs: 12, sm: 8 }}
          leftContent={getLeftContent(t('SUMMARY'))}
          rightContent={getSummaryRightContent(summaryData)}
          disableBackground
          rootClassName={classes.item}
        />
      </Grid>
      <Grid item xs={12}>
        <Divider />
      </Grid>
      <Grid item xs={12}>
        <SectionCard
          leftContentGridWidths={{ xs: 12, sm: 4 }}
          rightContentGridWidths={{ xs: 12, sm: 8 }}
          leftContent={getLeftContent(t('DEPOSIT STATUS'))}
          rightContent={getDepositStatusContent(depositKeys)}
          disableBackground
          rootClassName={classes.item}
          centerRightContent={depositKeys.length === 1}
        />
      </Grid>
    </Grid>
  );
};

Eth2DeployDeposit.propTypes = {
  quantity: PropTypes.number.isRequired,
  balance: PropTypes.number.isRequired,
  account: PropTypes.string.isRequired,
  nodeType: PropTypes.string.isRequired,
  chainId: PropTypes.number.isRequired,
  requiredBalance: PropTypes.number.isRequired,
};

Eth2DeployDeposit.whyDidYouRender = true;
export default React.memo(Eth2DeployDeposit);
