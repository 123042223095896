import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import whyDidYouRender from '@welldone-software/why-did-you-render';
import isEmpty from 'lodash/fp/isEmpty';
import * as R from 'ramda';
import React, { useState, useEffect } from 'react';
import {
  useLocation,
} from 'react-router-dom';

import NotFoundPage from '../NotFoundPage/NotFoundPage';

import MarketProtocolDeployBasedOnTypes from './MarketProtocolDeployBasedOnTypes';

const { REACT_APP_ENV } = process.env;

if (REACT_APP_ENV !== 'prod') {
  whyDidYouRender(React);
}

const MarketProtocolDeploy = (props) => {
  const location = useLocation();
  const {
    pathname,
  } = location;
  const [protocolName, setProtocolName] = useState('');
  useEffect(() => {
    const [protocolName] = R.pipe(
      R.split('/'),
      R.reject((string) => R.or(
        R.equals('protocols', string),
        R.equals('deploy', string),
      )),
      R.reject(isEmpty),
    )(pathname);
    if (protocolName) setProtocolName(protocolName);
  }, [pathname]);

  const ProtocolToRender = R.view(
    R.lensPath([protocolName]),
    MarketProtocolDeployBasedOnTypes,
  );

  const getContent = () => {
    // make sure it does not crash when comonent mount
    if (!protocolName) {
      return (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <CircularProgress />
        </Box>
      );
      // if no ProtocolToRender render not found component
    } if (protocolName && !ProtocolToRender) {
      return (
        <NotFoundPage />
      );
    }
    return (
      <ProtocolToRender
        protocolName={protocolName}
      />
    );
  };
  return getContent();
};

MarketProtocolDeploy.propTypes = {
};

MarketProtocolDeploy.whyDidYouRender = true;
export default React.memo(MarketProtocolDeploy);
