// mutation
import {
  createQuery,
  createMutation,
} from '../../../modules/apollo/apollo';

import {
  createCryptoAddressMutation,
} from './mutation';
// query
import {
  getCryptoAddressesesQuery,
} from './queries';
// apollo

export const graphqlGetCryptoAddressesQuery = createQuery(getCryptoAddressesesQuery);

export const graphqlCreateCryptoAddress = createMutation(createCryptoAddressMutation);
