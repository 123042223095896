import * as R from 'ramda';

import { errorOperations } from '../error/index';
import { dispatchAction } from '../store_utils';

import * as actions from './actions';

/**
 * Set paginaton meta
 *
 */
export const setPaginationMeta = R.curry(
  (scope, paginationMeta, dispatch) => {
    try {
      const response = dispatchAction(
        dispatch,
        actions.setPaginationMeta,
        scope,
        paginationMeta,
      );
      dispatch(errorOperations.clearError('setPaginationMeta'));
      return response;
    } catch (error) {
      return dispatch(errorOperations.setError(
        'setPaginationMeta',
        { error },
      ));
    }
  },
);

/**
 * Set paginaton input
 *
 */
export const setPaginationInput = R.curry(
  (scope, paginationInput, dispatch) => {
    try {
      const response = dispatchAction(
        dispatch,
        actions.setPaginationInput,
        scope,
        paginationInput,
      );
      dispatch(errorOperations.clearError('setPaginationInput'));
      return response;
    } catch (error) {
      return dispatch(errorOperations.setError(
        'setPaginationInput',
        { error },
      ));
    }
  },
);
