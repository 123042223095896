import { HttpStatusCodes } from './appConstants';

export class CustomError extends Error {
  constructor(message) {
    super(message);
    this.name = 'CustomError';
    this.extensions = {
      statusCode: [HttpStatusCodes.CUSTOM_ERROR],
      status: ['CustomError'],
    };
  }
}
