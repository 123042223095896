import * as secretPhraseConstants from './constants';
import * as secretPhraseOperations from './operations';
import reducer from './reducers';
import * as secretPhraseSelectors from './selectors';
import * as secretPhraseServices from './services';
import * as secretPhraseTypes from './types';

export {
  secretPhraseSelectors,
  secretPhraseOperations,
  secretPhraseTypes,
  secretPhraseServices,
  secretPhraseConstants,
};

export default reducer;
