import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import { makeStyles } from '@material-ui/core/styles';
import HelpOutline from '@material-ui/icons/HelpOutline';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

const styles = (theme) => ({
  helpText: {
    ...theme.typography.caption,
    color: '#fff',
  },
});

const useStyles = makeStyles(styles);

/**
 * Hint button using ClickAwayListener and Tooltip
 *
 * @component
 * @example
 * const initialValues = {...}
 * const userId = 1
 * return (
 *   <HintButton />
 * )
 */
const HintButton = (props) => {
  const classes = useStyles();
  const { helpText } = props;

  const [openHelp, setOpenHelp] = useState(false);

  const handleCloseHelp = () => {
    setOpenHelp(false);
  };

  const hanldeOpenHelp = () => {
    setOpenHelp(true);
  };

  return (
    <ClickAwayListener onClickAway={handleCloseHelp}>
      <Tooltip
        PopperProps={{
          disablePortal: true,
        }}
        open={openHelp}
        disableFocusListener
        disableHoverListener
        disableTouchListener
        title={helpText}
        classes={{
          tooltip: classes.helpText,
        }}
        placement="left"
      >
        <IconButton onClick={hanldeOpenHelp} className={classes.helpButton}>
          <HelpOutline />
        </IconButton>
      </Tooltip>
    </ClickAwayListener>
  );
};

HintButton.propTypes = {
  /**
   * Close handle function
   */
  helpText: PropTypes.string.isRequired,
};

export default HintButton;
