export const styles = (theme) => ({
  chainIcon: {
    width: '30px',
    height: '30px',
    [theme.breakpoints.down('xs')]: {
      width: '50px',
      height: '50px',
    },
  },
  padding: theme.spacing(1),
  nodeContent: {
    margin: theme.spacing(1, 2.5, 0, 1.5),
    [theme.breakpoints.down('xs')]: {
      margin: theme.spacing(1, 2, 0, 2),
    },
    // [theme.breakpoints.down('sm')]: {
    //   margin: theme.spacing(1, 2, 0, 2),
    // },
  },
  header: {
    visibility: 'visible',
    [theme.breakpoints.down('xs')]: {
      marginTop: theme.spacing(0),
      width: '0px',
      height: '0px',
      visibility: 'hidden',
    },
    // [theme.breakpoints.down('sm')]: {
    //   width: '0px',
    //   height: '0px',
    //   visibility: 'hidden',
    // },
  },
  headerContent: {
    margin: theme.spacing(-1, 2.5, -2, 6),
  },
  headerText: {
    fontWeight: 500,
  },
  nodeDescriptionText: {
    overflow: 'auto',
  },

});

export const selectNodesToDepositStyles = (theme) => ({
  nodeItemListHeader: {
    ...theme.border.default,
    borderRadius: 10,
    paddingLeft: theme.spacing(8),
    paddingRight: theme.spacing(2.15),
    width: '80%',
    // minHeight: 86,
    [theme.breakpoints.down('sm')]: {
      width: '100%',

    },
  },
  nodeItem: {
    padding: theme.spacing(1, 2, 1, 2),
    ...theme.border.default,
    borderRadius: 10,
    width: '80%',
    // minHeight: 86,
    [theme.breakpoints.down('sm')]: {
      width: '100%',

    },
  },
  nodeList: {
    ...theme.centerComponent,
  },
  nodeGrid: {
    ...theme.centerComponent,
  },
  chainIcon: {
    width: 40,
    height: 40,
  },

});

export const myNodeListStyles = (theme) => ({
  dialogTitle: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  dialogContent: {
    paddingTop: 0,
  },
  earningDashboard: {
    paddingBottom: theme.spacing(4),
  },
});

export const earningDashBoardStyles = (theme) => {
  const h3Properties = {
    fontSize: theme.typography.h3.fontSize,
    fontWeight: theme.typography.h3.fontWeight,
  };
  return ({
    container: {
      minWidth: '100px',
      ...theme.centerComponent,
    },
    dataWithCircleBg: {
      color: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
    },
    dataWithCircle: {
      strokeLinecap: 'round',
      color: theme.nodeStatus.active,
    },
    loadingFailedBackground: {
      backgroundColor: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
    },
    percent: {
      ...h3Properties,
    },
    totalEarning: {
      fontSize: theme.typography.h2.fontSize,
      fontWeight: theme.typography.h2.fontWeight,
    },
    overviewText: {
      ...h3Properties,
    },
    primaryText: {
      fontSize: theme.typography.h2.fontSize,
      fontWeight: 550,
    },
  });
};
