import gql from 'graphql-tag';

export const getUsersQuery = gql`
  query getUsers(
    $getUsersInput: GetUsersInput!
    $paginationInput: PaginationInput!
  )
  {
    getUsers(
      getUsersInput: $getUsersInput,
      paginationInput: $paginationInput,
    ) {
      meta {
        totalItems
        itemCount
        itemsPerPage
        currentPage
        totalPages
      }
      items {
        userId
        email
        joined
        lastSignin
        modified
        activated
        role
        haveUnUniFiEarning
      }
    }
  }
`;

export const sendFirstSigninEmailsQuery = gql`
  query sendFirstSigninEmails(
    $sendFirstSigninEmailsInput: SendFirstSigninEmailsInput!
  )
  {
    sendFirstSigninEmails(
      sendFirstSigninEmailsInput: $sendFirstSigninEmailsInput,
    ) {
      responses {
        messageId
        from
        to
        response
      }
    }
  }
`;
