import Typography from '@material-ui/core/Typography';
import * as R from 'ramda';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

// react-router

import { useAddFunctionToRef } from '../../../hooks/hooks';
import { isInfluxdbError, isAPIReadFailedError } from '../../../store/error/services';
import { nodeOperations } from '../../../store/node/index';
import { routerServices } from '../../../store/router/index';
import Alert from '../../MaterialUiCustom/Alert/Alert';
import RoundedButton from '../../MaterialUiCustom/Button/RoundedButton';
import CustomTabs from '../../Tabs/CustomTabs';

import ETH2ValidatorContent from './ETH2ValidatorContent';

// store

import { myNodeRoute } from 'routes';

const ETH2Validator = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const nodeType = 'ETH2_VALIDATOR_NODE';

  const nodes = useSelector((state) => state.nodeR.userNodes.nodes);
  const nodesETH2 = R.filter(R.propEq('nodeType', 'ETH2_VALIDATOR_NODE'), nodes);
  const { userId } = useSelector((state) => state.userR.user);
  // DETECT TCP ERROR
  const getTotalEarningError = useSelector((state) => state.errorR.getTotalEarningError);
  const totalEarningTCPError = isAPIReadFailedError(getTotalEarningError || {});
  const getIntervalEarningError = useSelector((state) => state.errorR.getIntervalEarningError);
  const influxdbErrorExists = isInfluxdbError(getIntervalEarningError || {});

  const onGetUserNodes = useAddFunctionToRef(async (userId) => {
    await dispatch(nodeOperations.getUserNodes(userId));
  });

  const redirectToLegacyETH2Page = () => {
    routerServices.historyPush(
      history,
      {
        pathname: `${myNodeRoute}/eth2`,
      },
    );
  };

  const onGetIntervalEarning = useAddFunctionToRef(async (userId, nodeType) => {
    await dispatch(nodeOperations.getIntervalEarning(
      userId,
      nodeType,
      'jpy',
      null,
      null,
      '1d',
      999999, // Have to pass large rows number to get all data, TODO: Remove this then use pagination to optimize
      1,
    ));
  });

  const onGetNodesEarning = useAddFunctionToRef(async (nodes) => {
    const publicKeyList = R.pipe(
      R.filter(R.propEq('nodeType', nodeType)),
      R.map(
        R.view(R.lensPath(['nodeInfo', 'pubKey'])),
      ),
    )(nodes);

    if (R.length(publicKeyList) > 0) {
      await dispatch(nodeOperations.getTotalEarning(userId, publicKeyList, nodeType));
    }
  });

  const onGetCoinPrice = useAddFunctionToRef(
    async () => {
      await dispatch(nodeOperations.getCoinPrice('ETH', 'JPY'));
    },
  );

  const onGetNodesEstimateActivationDate = useAddFunctionToRef(
    async (nodes, nodeType) => {
      const publicKeyList = R.map(
        R.view(R.lensPath(['nodeInfo', 'pubKey'])),
      )(nodes);

      if (R.length(publicKeyList) <= 0) {
        return;
      }

      await dispatch(
        nodeOperations.getNodesEstimateActivationDate(
          publicKeyList,
          nodeType,
        ),
      );
    },
  );

  useEffect(() => {
    onGetUserNodes(userId);
  }, [onGetUserNodes, userId]);

  useEffect(() => {
    onGetIntervalEarning(userId, nodeType);
  }, [onGetIntervalEarning, userId, nodeType]);

  useEffect(() => {
    onGetNodesEarning(nodes);
  }, [onGetNodesEarning, nodes]);

  useEffect(() => {
    onGetCoinPrice();
  }, [onGetCoinPrice]);

  useEffect(() => {
    onGetNodesEstimateActivationDate(nodesETH2, nodeType);
  }, [onGetNodesEstimateActivationDate, nodesETH2, nodeType]);

  const tabs = [
    {
      label: t('ETH2.0 Validator'),
      content: <ETH2ValidatorContent />,
      mainTab: true,
    },
  ];

  return (
    <>
      {
        // TODO: Just for test. Please check TCP error existence where suitable.
        // Start
        influxdbErrorExists
        && (
          <Alert
            severity="error"
            action={(
              <RoundedButton
                variant="contained"
                color="secondary"
                size="small"
                noMargin
                onClick={redirectToLegacyETH2Page}
              >
                {t('switch')}
              </RoundedButton>
          )}
          >
            <Typography variant="body2" color="inherit">
              {t('Our server is busy. Please reload or switch to our classic UI.')}
            </Typography>
          </Alert>
        )
        // End
      }
      {
        (totalEarningTCPError && !influxdbErrorExists)
        && (
          <Alert
            severity="error"
          >
            <Typography variant="body2" color="inherit">
              {t('Our server is busy. Please try again later.')}
            </Typography>
          </Alert>
        )
        // End
      }
      <CustomTabs
        tabs={tabs}
      />
    </>
  );
};

export default ETH2Validator;
