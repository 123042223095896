import * as R from 'ramda';

import * as actions from './actions';
import { shouldSetErrorToRender } from './services';

export const setError = R.curry((scope, errorObj) => {
  const errorAction = actions.setError(scope, errorObj);
  const errorToRender = shouldSetErrorToRender(errorAction);
  if (errorToRender) {
    return actions.setErrorToRender(errorToRender);
  }
  return errorAction;
});

export const { clearError, setErrorToRender, clearErrorToRender } = actions;
