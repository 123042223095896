import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import whyDidYouRender from '@welldone-software/why-did-you-render';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { useAddFunctionToRef } from '../../../hooks/hooks';
import { nodeOwnerOperations } from '../../../store/node_provider/index';
import { paymentOperations } from '../../../store/payment/index';
import NotFoundPage from '../../NotFoundPage/NotFoundPage';

import NodeOwnerBasedOnTypes from './NodeOwnerBasedOnTypes';
// store

const { REACT_APP_ENV } = process.env;

if (REACT_APP_ENV !== 'prod') {
  whyDidYouRender(React);
}

const NodeOwnerForm = (props) => {
  const dispatch = useDispatch();
  const {
    service,
  } = props;

  const ProtocolToRender = R.view(
    R.lensPath([service]),
    NodeOwnerBasedOnTypes,
  );

  const onResetNodeProviderOrderConfig = useAddFunctionToRef(
    () => dispatch(nodeOwnerOperations.resetNodeProviderOrderConfig()),
  );
  useEffect(() => () => onResetNodeProviderOrderConfig(), [onResetNodeProviderOrderConfig]);

  const onResetPromotionCode = useAddFunctionToRef(() => {
    dispatch(
      paymentOperations.updateStripePromotionCode(''),
    );
  });
  // Handle reset coupon after unmount
  useEffect(() => () => onResetPromotionCode(), [onResetPromotionCode]);

  const getContent = () => {
    // make sure it does not crash when comonent mount
    if (!service) {
      return (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <CircularProgress />
        </Box>
      );
      // if no ProtocolToRender render not found component
    } if (service && !ProtocolToRender) {
      return (
        <NotFoundPage />
      );
    }
    return (
      <ProtocolToRender
        protocolName={service}
      />
    );
  };
  return getContent();
};

NodeOwnerForm.propTypes = {
  service: PropTypes.string.isRequired,
};

NodeOwnerForm.whyDidYouRender = true;
export default React.memo(NodeOwnerForm);
