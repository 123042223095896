import * as R from 'ramda';

import { errorOperations } from '../../error/index';
import { loadingOperations } from '../../loading/index';

import * as actions from './actions';
import { promotionCodeErrorMessages } from './constants';

export const adminGetOrders = R.curry(
  async (
    getOdersInput,
    paginationInput,
    dispatch,
  ) => {
    try {
      dispatch(loadingOperations.setLoading('adminGetOrders', true));
      const { type, payload } = dispatch(
        await actions.adminGetOrders(
          getOdersInput,
          paginationInput,
        ),
      );
      dispatch(loadingOperations.setLoading('adminGetOrders', false));
      dispatch(errorOperations.clearError('adminGetOrders'));
      return { type, payload };
    } catch (error) {
      dispatch(loadingOperations.setLoading('adminGetOrders', false));
      return dispatch(errorOperations.setError(
        'adminGetOrders',
        { error, forceMessages: promotionCodeErrorMessages },
      ));
    }
  },
);

export const updateOrderData = R.curry(
  async (
    updateOrderDataInput,
    dispatch,
  ) => {
    try {
      dispatch(loadingOperations.setLoading('updateOrderData', true));
      const { type, payload } = dispatch(
        await actions.updateOrderData(
          updateOrderDataInput,
        ),
      );
      dispatch(loadingOperations.setLoading('updateOrderData', false));
      dispatch(errorOperations.clearError('updateOrderData'));
      return { type, payload };
    } catch (error) {
      dispatch(loadingOperations.setLoading('updateOrderData', false));
      return dispatch(errorOperations.setError(
        'updateOrderData',
        { error },
      ));
    }
  },
);

export const updateOrderTimelines = R.curry(
  async (
    orderId,
    activeDate,
    timelines,
    dispatch,
  ) => {
    const operationName = 'updateOrderTimelines';
    try {
      dispatch(loadingOperations.setLoading(operationName, true));
      const { type, payload } = dispatch(
        await actions.updateOrderTimelines(
          orderId,
          activeDate,
          timelines,
        ),
      );
      dispatch(loadingOperations.setLoading(operationName, false));
      dispatch(errorOperations.clearError(operationName));
      return { type, payload };
    } catch (error) {
      dispatch(loadingOperations.setLoading(operationName, false));
      return dispatch(errorOperations.setError(
        operationName,
        { error },
      ));
    }
  },
);

export const createOrders = R.curry(
  async (
    data,
    dispatch,
  ) => {
    const operationName = 'createOrders';
    try {
      dispatch(loadingOperations.setLoading(operationName, true));
      const { type, payload } = dispatch(
        await actions.createOrders(
          data,
        ),
      );
      dispatch(loadingOperations.setLoading(operationName, false));
      dispatch(errorOperations.clearError(operationName));
      return { type, payload };
    } catch (error) {
      dispatch(loadingOperations.setLoading(operationName, false));
      return dispatch(errorOperations.setError(
        operationName,
        { error, forceMessages: promotionCodeErrorMessages },
      ));
    }
  },
);
