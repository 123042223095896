import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import {
  CardCvcElement,
  CardNumberElement,
  CardExpiryElement,
} from '@stripe/react-stripe-js';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import React from 'react';
import { useTranslation } from 'react-i18next';

import StripeInput from './StripeInput';

const PaymentCardElement = ({
  error,
  handleOnChange,
}) => {
  const { t } = useTranslation();
  const createGetErrorMsg = R.curry((key, error) => {
    const errorMsg = error[key];
    // add a pseudo Box to prevent input from jumping when errorMsg shown
    if (!errorMsg) return <Box minHeight={20} />;
    return <Typography color="error" variant="body2">{errorMsg}</Typography>;
  });
  const getCardNumberErrorMsg = createGetErrorMsg('cardNumber');
  const getCardExpiryErrorMsg = createGetErrorMsg('cardExpiry');
  const getCardCvcErrorMsg = createGetErrorMsg('cardCvc');

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={12}>
        <TextField
          label={t('Credit Card Number')}
          name="ccnumber"
          variant="outlined"
          required
          fullWidth
          InputLabelProps={{ shrink: true }}
          InputProps={{
            inputComponent: StripeInput,
            inputProps: {
              component: CardNumberElement,
            },
          }}
          onChange={handleOnChange}
        />
        {getCardNumberErrorMsg(error)}
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          label={t('Expiration Date')}
          name="ccexp"
          variant="outlined"
          required
          fullWidth
          InputLabelProps={{ shrink: true }}
          InputProps={{
            inputComponent: StripeInput,
            inputProps: {
              component: CardExpiryElement,
            },
          }}
          onChange={handleOnChange}
        />
        {getCardExpiryErrorMsg(error)}
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          label={t('CVC')}
          name="cvc"
          variant="outlined"
          required
          fullWidth
          InputLabelProps={{ shrink: true }}
          InputProps={{
            inputComponent: StripeInput,
            inputProps: {
              component: CardCvcElement,
            },
          }}
          onChange={handleOnChange}
        />
        {getCardCvcErrorMsg(error)}
      </Grid>
    </Grid>
  );
};

PaymentCardElement.defaultProps = {
  error: {},
  handleOnChange: () => {},
};

PaymentCardElement.propTypes = {
  error: PropTypes.object,
  handleOnChange: PropTypes.func,
};

export default PaymentCardElement;
