import gql from 'graphql-tag';

export const updatePoolsMutation = gql`
  mutation updatePools(
    $updatePoolsInput: UpdatePoolsInput!
  )
  {
    updatePools(
      updatePoolsInput: $updatePoolsInput
    ) {
      pools {
        poolId
        pid
        userId
        active
        dateCreated
        dateUpdated
        poolData {
          poolDataId
          poolId
          pid
          userId
          commission
          machineNum
          activeMachineNum
          unitCap
          poolCreated
          commissionSet
          capSet
          payoutAddress
          dateCreated
          dateUpdated
        }
      }
    }
  }
`;

export const updatePoolDatasMutation = gql`
  mutation updatePoolDatas(
    $updatePoolDatasInput: UpdatePoolsInput!
  )
  {
    updatePoolDatas(
      updatePoolDatasInput: $updatePoolDatasInput
    ) {
      pools {
        poolDataId
        poolId
        pid
        userId
        commission
        machineNum
        activeMachineNum
        unitCap
        poolCreated
        commissionSet
        capSet
        payoutAddress
        dateCreated
        dateUpdated
      }
    }
  }
`;
