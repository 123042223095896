// react
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import ListItemText from '@material-ui/core/ListItemText';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import LanguageOutlinedIcon from '@material-ui/icons/LanguageOutlined';
import isFunction from 'lodash/isFunction';
import lowerCase from 'lodash/lowerCase';
import random from 'lodash/random';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import {
  useLocation,
  useHistory,
} from 'react-router-dom';

import {
  useIsMobile, usePrevious, useQuery, useAddFunctionToRef,
} from '../../hooks/hooks';
import { langOperations } from '../../store/lang/index';
import { routerServices } from '../../store/router/index';

import { navigationAppBarLangstyles } from './styles';
// others

const useStyles = makeStyles(navigationAppBarLangstyles);

const langs = {
  en: {
    text: 'English',
    flagCode: 'us',
  },
  ja: {
    text: '日本語',
    flagCode: 'jp',
  },
  vi: {
    text: 'Tiếng Việt',
    flagCode: 'vn',
  },
  'zh-CN': {
    text: '简体中文',
    flagCode: 'cn',
  },
  'zh-TW': {
    text: '繁體中文',
    flagCode: 'tw',
  },
};
const langCodes = R.keys(langs);
const NavigationAppBarLang = ({ onCloseDrawer, iconOnly, langCodeOnly }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const { pathname } = location;
  const [anchorEl, setAnchorEl] = useState(null);
  const openMenu = Boolean(anchorEl);
  const query = useQuery();
  const queryLng = query.get('lng');
  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  // LANGUAGE
  const { i18n } = useTranslation();
  const { lang } = useSelector((state) => state.langR.langSetting);
  const onSetLang = useAddFunctionToRef((pathname, lang) => {
    dispatch(langOperations.setLang(lang));
    return routerServices.historyPush(
      history,
      {
        pathname,
        queryLng: lang,
      },
    );
    // return history.push({ pathname, search: `?lng=${lang}` });
  });
  useEffect(() => {
    i18n.changeLanguage(lang);
  }, [lang, i18n]);

  // Handle when url has lng query
  useEffect(() => {
    if (R.includes(lowerCase(queryLng), langCodes)) onSetLang(pathname, queryLng);
  }, [queryLng, onSetLang, pathname]);

  const handleOnClickChangeLang = (pathname, lang) => {
    setAnchorEl(null);
    onSetLang(pathname, lang);
  };
  const prevLang = usePrevious(lang);
  const isMobile = useIsMobile();
  // HANDLE CLOSE IN MOBILE
  useEffect(() => {
    const langChanged = prevLang && lang !== prevLang;
    const shouldCloseDrawer = isFunction(onCloseDrawer) && langChanged && isMobile;
    if (shouldCloseDrawer) onCloseDrawer();
  }, [lang, isMobile, prevLang, onCloseDrawer]);

  const getMenuItems = R.curry((langs, lang) => R.pipe(
    R.reject((key) => key === lang),
    R.addIndex(R.map)((lang, index) => {
      const { text } = langs[lang];
      const key = `${random(0, 10000)}_${index}`;

      return (
        <MenuItem onClick={() => handleOnClickChangeLang(pathname, lang)} key={key}>
          <ListItemText primary={text} className={classes.listItemText} />
        </MenuItem>
      );
    }),
  )(langCodes));

  const getContent = () => (
    <Box pl={1} pr={1}>

      <Button
        aria-controls="lange-menu-btn"
        aria-haspopup="true"
        variant="outlined"
        color="default"
        onClick={handleMenu}
        className={classes.langBtn}
        startIcon={<LanguageOutlinedIcon className={classes.langIcon} />}
      >
        <Typography>{langs[lang].text}</Typography>
      </Button>
      <Menu
        id="lang-menu"
        anchorEl={anchorEl}
        keepMounted
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        open={openMenu}
        onClose={handleClose}
      >
        {getMenuItems(langs, lang)}
      </Menu>

    </Box>
  );

  return getContent();
};

NavigationAppBarLang.propTypes = {
  onCloseDrawer: PropTypes.func,
  iconOnly: PropTypes.bool,
  langCodeOnly: PropTypes.bool,
};

NavigationAppBarLang.defaultProps = {
  onCloseDrawer: () => {},
  iconOnly: false,
  langCodeOnly: false,
};

export default NavigationAppBarLang;
