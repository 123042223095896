import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import * as R from 'ramda';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';

// store
import { usePrevious, useAddFunctionToRef } from '../../../../hooks/hooks';
import { web3Selectors } from '../../../../store/web3/index';

import MiningAddressCreationMethods from './MiningAddressCreationMethods';
import { addressCreationStyles } from './styles';

import { CloseIconButton } from 'components/MaterialUiCustom/MaterialUiCustom';
import SuccessPage from 'components/SuccessPage/SuccessPage';
import { cryptoAddressOperations } from 'store/crypto_address';

const useStyles = makeStyles(addressCreationStyles);

const MiningAddressCreation = ({
  openAddressCreation,
  handleCloseAddressCreation,
  autoConnect,
  chain,
  ss58Format,
  haveCryptoAccounts,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [accountType, setAccountType] = useState('');
  const [resetAllChildren, setResetAllChildren] = useState(false);
  const [selectedMethod, setSelectedMethod] = useState('');
  const prevOpenAddressCreation = usePrevious(openAddressCreation);
  // HANDLE FIRST OPEN DIALOG
  useEffect(() => {
    // when first open the dialog we dont want to reset children
    if (openAddressCreation && !prevOpenAddressCreation) setResetAllChildren(false);
  }, [openAddressCreation, prevOpenAddressCreation]);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('xs'));
  const dispatch = useDispatch();

  const userId = useSelector((state) => state.userR.user.userId);
  const enableWeb3Loading = useSelector((state) => state.loadingR.enableWeb3Loading);
  const web3 = useSelector((state) => state.web3R.web3);

  const {
    accounts,
    isExtensionsInjected,
  } = web3Selectors.selectInjectedOrGeneratedAccounts(chain, accountType, web3);

  const onCreateCryptoAddress = useAddFunctionToRef(R.curry(async (
    userId,
    address,
    chain,
    subChain,
  ) => {
    await dispatch(cryptoAddressOperations.createCryptoAddress(
      userId,
      address,
      chain,
      subChain,
    ));
  }));

  const getFirstStepTitle = R.curry((selectedMethod, haveCryptoAccounts) => {
    if (haveCryptoAccounts) return '';
    const defaultText = t('Connect wallet');
    if (!selectedMethod) return defaultText;
    return selectedMethod === 'generate'
      ? t('Generate wallet')
      : defaultText;
  });

  const onCancel = useAddFunctionToRef(() => {
    handleCloseAddressCreation();
    setResetAllChildren(true);
  });

  const getDialogContent = (haveCryptoAccounts) => (
    !haveCryptoAccounts
      ? (
        <MiningAddressCreationMethods
          openAddressCreation={openAddressCreation}
          enableWeb3Loading={enableWeb3Loading}
          isExtensionsInjected={isExtensionsInjected}
          accounts={accounts}
          onCancel={onCancel}
          setAccountType={setAccountType}
          autoConnect={autoConnect}
          chain={chain}
          ss58Format={ss58Format}
          resetAllChildren={resetAllChildren}
          setSelectedMethod={setSelectedMethod}
          onCreateCryptoAddress={onCreateCryptoAddress}
          userId={userId}
        />
      )
      : (
        <SuccessPage
          onCancel={onCancel}
          primaryText={t('AWESOME!')}
          message={t('You have successfully connected wallet to Neukind dashboard')}
          btnText={t('done')}
          pb={7}
        />
      )
  );
  const handleCloseDialog = (event, reason) => {
    if (reason === 'backdropClick') return;
    onCancel();
  };
  const getContent = (selectedMethod, haveCryptoAccounts) => {
    const title = getFirstStepTitle(selectedMethod, haveCryptoAccounts);
    return (
      <Dialog
        open={openAddressCreation}
        onClose={handleCloseDialog}
        fullScreen={fullScreen}
        aria-labelledby="address-creation-dialog-title"
        aria-describedby="address-creation-dialog-description"
        className={classes.dialog}
        classes={{ paper: classes.dialogPaper }}
        disableEscapeKeyDown
        fullWidth
        maxWidth="md"
      >
        <DialogTitle
          id="address-creation-dialog-title"
          disableTypography
          className={classes.dialogTitle}
        >
          <Typography variant="h2" className={classes.dialogTitleText}>
            {title}
          </Typography>
          <CloseIconButton
            onClick={onCancel}
            color="default"
            className={classes.closeButton}
          />
        </DialogTitle>
        <DialogContent>
          {getDialogContent(haveCryptoAccounts)}
        </DialogContent>
      </Dialog>
    );
  };

  return getContent(selectedMethod, haveCryptoAccounts);
};

export default MiningAddressCreation;
