import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import lowerCase from 'lodash/fp/lowerCase';
import startCase from 'lodash/fp/startCase';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import React from 'react';
import { useTranslation } from 'react-i18next';

import ChainIcon from '../Chain/ChainIcon';

import MarketCardDescription from './MarketCardDescription';
import MarketCardPrice from './MarketCardPrice';
import { marketCardContentStyles } from './styles';

const useStyles = makeStyles(marketCardContentStyles);

const MarketCardContent = (props) => {
  const classes = useStyles();
  const {
    chain,
  } = props;
  const { t } = useTranslation();
  const handleNodeTypes = (protocolsNumber) => {
    const desc = protocolsNumber === 1
      ? R.pipe(
        R.prop('nodeTypes'),
        R.head,
        R.prop('type'),
        R.split('_'),
        R.tail,
        R.join(' '),
        lowerCase,
        startCase,
      )(chain)
      : `${protocolsNumber} ${t('Node Types')}`;
    return desc;
  };
  const pricing = R.pipe(
    R.prop('nodeTypes'),
    R.map((item) => item.pricing),
    R.flatten,
    // only support month for now
    R.filter(R.propEq('paymentPeriod', 'month')),
    R.addIndex(R.reduce)(
      (acc, item, index) => {
        const { price } = item;
        if (index === 0) return item;
        return (price < acc.price) ? item : acc;
      },
      {},
    ),
  )(chain);
  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
    >
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <ChainIcon
          image={chain.iconPath}
          title={chain.name}
          className={classes.chainIcon}
        />
      </Box>
      <Box
        mt={2}
      >
        <MarketCardDescription
          desc={handleNodeTypes(chain.nodeTypes.length)}
        />
      </Box>
      <Box>
        <MarketCardPrice
          status={chain.status}
          {...pricing}
          hidePrice={chain.hidePrice}
        />
      </Box>
    </Box>
  );
};

MarketCardContent.propTypes = {
  /**
   * chain to show
   */
  chain: PropTypes.shape({
    brief: PropTypes.string.isRequired,
    iconPath: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
    // todo add properties detail use shape
    nodeTypes: PropTypes.array.isRequired,
  }).isRequired,
};
export default MarketCardContent;
