import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
// final form
import { TextField } from 'mui-rff';
import React, { useEffect, useState } from 'react';
import { Form, Field } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import isEmail from 'validator/lib/isEmail';

// store

import {
  useAddFunctionToRef,
  useUserDataSelector,
} from '../../hooks/hooks';
import { contactFormOperations } from '../../store/contact_form/index';
import { SEND_USER_CONTACT_US_EMAIL_SUCCESS } from '../../store/contact_form/types';
import { errorServices, errorOperations } from '../../store/error/index';
import ErrorAlert from '../Error/ErrorAlert';
import ErrorMessage from '../ErrorMessage/ErrorMessage';
import { RoundedButton, CloseIconButton } from '../MaterialUiCustom/MaterialUiCustom';
import SuccessPage from '../SuccessPage/SuccessPage';

import { styles } from './styles';

const { extractErrorMessage } = errorServices;
const useStyles = makeStyles(styles);

const ContactForm = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();
  const onDismount = useAddFunctionToRef(() => dispatch(errorOperations.clearError('sendContactUsEmail')));
  useEffect(() => onDismount, [onDismount]);

  const [isContactSent, setIsContactSent] = useState(false);

  const { email } = useUserDataSelector();
  const initialValues = {
    email,
  };

  const metadata = useSelector((state) => state.contactFormR.metadata);
  const errorR = useSelector((state) => state.errorR);
  const { sendContactUsEmailError } = errorR || {};
  const errorMessage = extractErrorMessage(sendContactUsEmailError);

  const validate = (values) => {
    const errors = {};
    const { name, email, message } = values;

    if (!name) {
      errors.name = t('Required');
    }
    if (!email) {
      errors.email = t('Required');
    } else if (!isEmail(email)) {
      errors.email = t('Invalid email');
    }
    if (!message) {
      errors.message = t('Required');
    }

    return errors;
  };

  const getFieldContent = (title, name, required) => (
    <Grid item xs={12}>
      <Box display="flex" className={classes.title}>
        <Box>
          <Typography
            variant="body2"
            color="textSecondary"
          >
            {title}
          </Typography>
        </Box>
        {required && (
          <Box pl={1}>
            <Typography
              variant="body2"
              color="error"
              className={classes.requiredSymbol}
            >
              *
            </Typography>
          </Box>
        )}
      </Box>
      <Box>
        <TextField
          id={`${name}-input-textfield`}
          type={name === 'email' ? 'email' : 'text'}
          variant="outlined"
          name={name}
          required={required}
          className={classes.conntactFormTextField}
        />
      </Box>
    </Grid>
  );

  const getMessageContent = (title, name, required) => (
    <Grid item xs={12}>
      <Box display="flex" className={classes.title}>
        <Box>
          <Typography
            variant="body2"
            color="textSecondary"
          >
            {title}
          </Typography>
        </Box>
        <Box pl={1}>
          <Typography
            variant="body2"
            color="error"
            className={classes.requiredSymbol}
          >
            *
          </Typography>
        </Box>
      </Box>
      <Box pb={2}>
        <Field
          id={`${name}-input-textfield`}
          variant="outlined"
          name={name}
          required={required}
          component="textarea"
          className={classes.messageField}
          validate={(value) => (value ? undefined : 'Required')}
        />
      </Box>
    </Grid>
  );

  const closeContactForm = () => {
    dispatch(contactFormOperations.closeContactForm());
  };

  const onSendContactMessage = async (values) => {
    const {
      name, company, email, message,
    } = values;
    const { type } = await dispatch(contactFormOperations.sendContactUsEmail(name, company, email, message, metadata));

    if (type === SEND_USER_CONTACT_US_EMAIL_SUCCESS) {
      setIsContactSent(true);
    }
  };

  const getContent = (isContactSent, pristine, submitting, invalid) => {
    if (isContactSent) {
      return (
        <SuccessPage
          align="center"
          onCancel={closeContactForm}
          primaryText={t('THANK YOU')}
          message={t('Your message had been sent. We will reply as soon as possible. Thank you for contacting us.')}
          btnText={t('done')}
        />
      );
    }

    return (
      <>
        <Grid item xs={12}>
          <Typography
            variant="h1"
            align="center"
            className={classes.title}
          >
            {t('Contact Us')}
          </Typography>
          <ErrorMessage errorMessage={errorMessage} align="center" />
        </Grid>
        {getFieldContent(t('Name'), 'name', true)}
        {getFieldContent(t('Company/Organization'), 'company', false)}
        {getFieldContent(t('Email'), 'email', true)}
        {getMessageContent(t('Message'), 'message', true)}
        <Grid item>
          <RoundedButton
            type="submit"
            loading={submitting}
            disabled={submitting || pristine || invalid || isContactSent}
          >
            {t('submit')}
          </RoundedButton>
        </Grid>
        <Grid item>
          <RoundedButton
            color="default"
            onClick={closeContactForm}
          >
            {t('close')}
          </RoundedButton>
        </Grid>
      </>
    );
  };

  return (
    <Box p={4}>
      <ErrorAlert />
      <Form
        initialValues={initialValues}
        onSubmit={onSendContactMessage}
        subscription={{
          submitting: true,
          pristine: true,
          invalid: true,
        }}
        keepDirtyOnReinitialize
        validateOnBlur
        validate={validate}
        render={({
          handleSubmit,
          form,
          pristine,
          submitting,
          invalid,
        }) => (
          <form
            id="contactForm"
            onSubmit={handleSubmit}
          >
            <Grid container justifyContent="center">
              <Grid item xs={12}>
                <CloseIconButton
                  onClick={closeContactForm}
                  color="default"
                  className={classes.closeButton}
                />
              </Grid>
              {getContent(isContactSent, pristine, submitting, invalid)}
            </Grid>
          </form>
        )}
      />
    </Box>
  );
};

ContactForm.propTypes = {
};

export default ContactForm;
