import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Field } from 'redux-form';

import { RenderField } from '../ReduxFormCustom/ReduxFormCustom';

import styles from './styles';

const useStyles = makeStyles(styles);

const EmailField = (props) => {
  const {
    showTitle,
    showLabel,
  } = props;
  const { t } = useTranslation();
  const classes = useStyles();
  return (
    <>
      {showTitle && (
      <Typography
        variant="body2"
        color="textSecondary"
        className={classNames(classes.title)}
      >
        {t('Email')}
      </Typography>
      )}
      <Field
        name="email"
        type="text"
        component={RenderField}
            // component={
            //   renderCase === 'sign in'? RenderField:
            //   AsyncRenderField
            // }
        label={showLabel ? t('Email') : ''}
        variant="outlined"
        className={classNames(classes.textField)}
      />
    </>
  );
};

EmailField.defaultProps = {
  showLabel: true,
  showTitle: false,
};

EmailField.propTypes = {
  showLabel: PropTypes.bool,
  showTitle: PropTypes.bool,
};

export default EmailField;
