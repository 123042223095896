import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';
import ArrowBackOutlinedIcon from '@material-ui/icons/ArrowBackOutlined';
import PropTypes from 'prop-types';
import React from 'react';
import { useHistory } from 'react-router-dom';

import ChainIcon from '../Chain/ChainIcon';
import ChainName from '../Chain/ChainName';

import { styles } from './styles';

const useStyles = makeStyles(styles);

const ChainInfoTitle = (props) => {
  const classes = useStyles();
  const {
    chainData,
  } = props;
  const history = useHistory();

  const handleOnClickBack = () => {
    history.goBack();
  };

  return (
    <>
      <IconButton onClick={handleOnClickBack}>
        <ArrowBackOutlinedIcon />
      </IconButton>

      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
      >
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          mb={2}
          className={classes.headerIcon}
        >
          <ChainIcon
            image={chainData.iconPath}
            title={chainData.name}
            className={classes.chainIcon}
          />
        </Box>
        <Box>
          <ChainName
            title={chainData.name}
            color="textSecondary"
          />
        </Box>
      </Box>
    </>
  );
};

ChainInfoTitle.defaultProps = {
  chainData: {
    name: '',
    iconPath: '',
  },
};

ChainInfoTitle.propTypes = {
  /**
   * chain to show
   */
  chainData: PropTypes.shape({
    name: PropTypes.string,
    iconPath: PropTypes.string,
  }),
};

export default ChainInfoTitle;
