import Box from '@material-ui/core/Box';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import { makeStyles } from '@material-ui/core/styles';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import React, { useState } from 'react';
import { isEqual } from 'react-fast-compare';

import { RoundedButton } from '../MaterialUiCustom/MaterialUiCustom';

import { tableActionStyles } from './styles';

const useStyles = makeStyles(tableActionStyles);

const Actions = (props) => {
  const {
    index,
    buttons,
    data,
  } = props;
  const classes = useStyles();
  const [openMenuIndex, setOpenMenuIndex] = useState(-1);
  const handleMenuClose = () => {
    setOpenMenuIndex(-1);
  };
  const handleMenuOpen = (index) => {
    setOpenMenuIndex(index);
  };

  const createGenerateButtonsUI = R.curry((data, buttonItem, index) => {
    const { label, buttonClickHandler } = buttonItem;
    return (
      <RoundedButton
        key={index}
        color="secondary"
        variant="text"
        className={classes.menuButton}
        onClick={() => buttonClickHandler(data)}
        noMargin
      >
        {label}
      </RoundedButton>
    );
  });

  const generateButtonsUI = createGenerateButtonsUI(data);

  return (
  // <Grid container direction="row" alignItems="center">
    <Tooltip
      placement="right-start"
      interactive
      open={index === openMenuIndex}
      disableFocusListener
      disableHoverListener
      disableTouchListener
      classes={{
        tooltip: classes.customTooltip,
        arrow: classes.customArrow,
      }}
      title={(
        <ClickAwayListener onClickAway={handleMenuClose}>
          <Box>
            {R.addIndex(R.map)(generateButtonsUI, buttons)}
          </Box>
        </ClickAwayListener>
        )}
    >
      <IconButton onClick={() => handleMenuOpen(index)}>
        <MoreHorizIcon />
      </IconButton>
    </Tooltip>
  // </Grid>
  );
};

Actions.propTypes = {
  /**
   * Table row index
   * @type {number}
   */
  index: PropTypes.number.isRequired,
  /**
   * Action buttons
   * @type {object}
   */
  buttons: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string.isRequired,
    buttonClickHandler: PropTypes.func.isRequired,
  })).isRequired,
  data: PropTypes.object,
};

export default React.memo(Actions, isEqual);
