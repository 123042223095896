import isEmpty from 'lodash/fp/isEmpty';
import toLower from 'lodash/fp/toLower';
import * as R from 'ramda';

import {
  keyToAuthTokenDataInLocalStorage,
  keyToUserDataInLocalStorage,
} from '../../modules/appConstants';
import { setItem } from '../../modules/browser_utils';

export const isAdmin = R.curry((payload) => {
  const role = R.view(R.lensPath(['user', 'role']), payload);
  return role === 'ADMIN';
});

/**
 * Separate user and authentication data from server response and store
 * authentication data to browser local storage and return user data.
 *
 */
export const extractAndStoreAuthTokenData = R.curry((payload) => {
  const userData = payload.user;
  const authTokenData = R.pick(
    ['accessToken', 'refreshToken', 'accessTokenExpiry', 'refreshTokenExpiry', 'idToken'],
    payload.authTokenData,
  );
  const userDataToStoreInLocalStorage = R.pick(['userId', 'email'], userData);
  setItem(keyToAuthTokenDataInLocalStorage, authTokenData);
  setItem(keyToUserDataInLocalStorage, userDataToStoreInLocalStorage);
  return userData;
});

export const formatEmail = R.curry((email) => R.pipe(
  R.trim,
  toLower,
)(email));

export const formatGetFirstSigninResponse = (payload) => {
  const { items } = payload;

  if (isEmpty(items)) {
    return {
      items: [],
    };
  }

  return {
    items: R.map(R.omit(['__typename']), items),
  };
};
