import DateFnsUtils from '@date-io/date-fns';
import { makeStyles } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';
import whyDidYouRender from '@welldone-software/why-did-you-render';
import createDecorator from 'final-form-calculate';
import {
  TextField,
  Select,
  KeyboardDatePicker,
} from 'mui-rff';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import React, { useState } from 'react';
import { Form } from 'react-final-form';
import { useSelector } from 'react-redux';

import {
  useIsMobile,
} from '../../../hooks/hooks';
import { unicodeDateFormat } from '../../../modules/appConstants';
import { RoundedButton, CloseIconButton } from '../../MaterialUiCustom/MaterialUiCustom';

import { poolDetailsStyles } from './styles';

// constants

if (process.env.REACT_APP_ENV !== 'prod') {
  whyDidYouRender(React);
}

const useStyles = makeStyles(poolDetailsStyles);

const PoolDetails = (props) => {
  const {
    initialValues,
    onClose,
    open,
    onCancelButtonClick,
    onPoolUpdate,
    t,
  } = props;

  const poolDetailssLoading = useSelector((state) => state.loadingR.poolDetailssLoading);
  const updatePoolsLoading = useSelector((state) => state.loadingR.updatePoolsLoading);

  const { active, pid, poolData } = initialValues;
  const { poolCreated, commissionSet, capSet } = poolData || {};
  const [selectedActive] = useState(active);
  const [selectedPoolCreated] = useState(poolCreated);
  const [selectedCommissionSet] = useState(commissionSet);
  const [selectedCapSet] = useState(capSet);
  const [pidToUse] = useState(pid);

  const classes = useStyles();
  const isMobileDevice = useIsMobile();

  const validate = (values) => {
    const poolData = R.prop('poolData', values) || {};
    const errors = { poolData: {} };
    if (!values.active && values.active !== false) {
      errors.active = t('Required');
    }
    if (!values.dateCreated) {
      errors.dateCreated = t('Required');
    }
    if (!values.dateUpdated) {
      errors.dateUpdated = t('Required');
    }
    if (!poolData.commission) {
      errors.poolData.commission = t('Required');
    }
    if (!poolData.machineNum && poolData.machineNum !== 0) {
      errors.poolData.machineNum = t('Required');
    }
    if (!poolData.activeMachineNum && poolData.activeMachineNum !== 0) {
      errors.poolData.activeMachineNum = t('Required');
    }
    if (!poolData.unitCap && poolData.unitCap !== 0) {
      errors.poolData.unitCap = t('Required');
    }
    if (!poolData.dateCreated) {
      errors.poolData.dateCreated = t('Required');
    }
    if (!poolData.dateUpdated) {
      errors.poolData.dateUpdated = t('Required');
    }

    return errors;
  };

  const getPoolContent = () => (
    <>
      <Grid item xs={10} sm={7}>
        <Typography variant="h2" className={classes.stepFieldTitle}>
          {t('Pool')}
        </Typography>
      </Grid>
      <Grid item xs={10} sm={7}>
        <TextField
          disabled
          label={t('Pool ID')}
          variant="outlined"
          name="poolId"
          required
          type="text"
        />
      </Grid>
      <Grid item xs={10} sm={7}>
        <TextField
          disabled
          label={t('Email')}
          variant="outlined"
          name="user.email"
          type="text"
        />
      </Grid>
      <Grid item xs={10} sm={7}>
        <TextField
          label={t('PID')}
          variant="outlined"
          name="pid"
          type="tel"
          data={pidToUse}
        />
      </Grid>
      <Grid item xs={10} sm={7}>
        <Select
          name="active"
          label={t('Active')}
          data={selectedActive}
          variant="outlined"
        >
          <MenuItem value>{t('Yes')}</MenuItem>
          <MenuItem value={false}>{t('No')}</MenuItem>
        </Select>
      </Grid>
      <Grid item xs={10} sm={7}>
        <KeyboardDatePicker
          variant="inline"
          label={t('Date Created')}
          dateFunsUtils={DateFnsUtils}
          name="dateCreated"
          inputVariant="outlined"
          format={unicodeDateFormat}
          InputAdornmentProps={{ position: 'start' }}
          required
        />
      </Grid>
      <Grid item xs={10} sm={7}>
        <KeyboardDatePicker
          variant="inline"
          label={t('Date Updated')}
          dateFunsUtils={DateFnsUtils}
          name="dateUpdated"
          inputVariant="outlined"
          format={unicodeDateFormat}
          InputAdornmentProps={{ position: 'start' }}
          required
        />
      </Grid>
    </>
  );

  const getPoolDataContent = () => (
    <>
      <Grid item xs={10} sm={7}>
        <Typography variant="h2" className={classes.stepFieldTitle}>
          {t('Pool Data')}
        </Typography>
      </Grid>
      <Grid item xs={10} sm={7}>
        <TextField
          disabled
          label={t('Pool Data ID')}
          variant="outlined"
          name="poolData.poolDataId"
          required
          type="text"
        />
      </Grid>
      <Grid item xs={10} sm={7}>
        <TextField
          label={t('PID')}
          variant="outlined"
          name="poolData.pid"
          type="tel"
          data={pidToUse}
        />
      </Grid>
      <Grid item xs={10} sm={7}>
        <TextField
          label={t('Commission')}
          variant="outlined"
          name="poolData.commission"
          type="tel"
        />
      </Grid>
      <Grid item xs={10} sm={7}>
        <TextField
          label={t('Machine Num')}
          variant="outlined"
          name="poolData.machineNum"
          type="number"
          InputProps={{ inputProps: { min: 1 } }}
        />
      </Grid>
      <Grid item xs={10} sm={7}>
        <TextField
          label={t('Active Machine Num')}
          variant="outlined"
          name="poolData.activeMachineNum"
          type="number"
          InputProps={{ inputProps: { min: 0 } }}
        />
      </Grid>
      <Grid item xs={10} sm={7}>
        <TextField
          label={t('Unit Cap')}
          variant="outlined"
          name="poolData.unitCap"
          type="number"
          InputProps={{ inputProps: { min: 1 } }}
        />
      </Grid>
      <Grid item xs={10} sm={7}>
        <Select
          name="poolData.poolCreated"
          label={t('Pool Created')}
          data={selectedPoolCreated}
          variant="outlined"
        >
          <MenuItem value>{t('Yes')}</MenuItem>
          <MenuItem value={false}>{t('No')}</MenuItem>
        </Select>
      </Grid>
      <Grid item xs={10} sm={7}>
        <Select
          name="poolData.commissionSet"
          label={t('Commission Set')}
          data={selectedCommissionSet}
          variant="outlined"
        >
          <MenuItem value>{t('Yes')}</MenuItem>
          <MenuItem value={false}>{t('No')}</MenuItem>
        </Select>
      </Grid>
      <Grid item xs={10} sm={7}>
        <Select
          name="poolData.capSet"
          label={t('Cap Set')}
          data={selectedCapSet}
          variant="outlined"
        >
          <MenuItem value>{t('Yes')}</MenuItem>
          <MenuItem value={false}>{t('No')}</MenuItem>
        </Select>
      </Grid>
      <Grid item xs={10} sm={7}>
        <TextField
          label={t('Payout Address')}
          variant="outlined"
          name="poolData.payoutAddress"
          required
          type="text"
        />
      </Grid>
      <Grid item xs={10} sm={7}>
        <KeyboardDatePicker
          variant="inline"
          label={t('Date Created')}
          dateFunsUtils={DateFnsUtils}
          name="poolData.dateCreated"
          inputVariant="outlined"
          format={unicodeDateFormat}
          InputAdornmentProps={{ position: 'start' }}
          required
        />
      </Grid>
      <Grid item xs={10} sm={7}>
        <KeyboardDatePicker
          variant="inline"
          label={t('Date Updated')}
          dateFunsUtils={DateFnsUtils}
          name="poolData.dateUpdated"
          inputVariant="outlined"
          format={unicodeDateFormat}
          InputAdornmentProps={{ position: 'start' }}
          required
        />
      </Grid>
    </>
  );

  const calculator = createDecorator(
    {
      field: 'pid', // when minimum changes...
      updates: {
        'poolData.pid': (pid) => pid,
      },
    },
    {
      field: 'poolData.pid', // when minimum changes...
      updates: {
        pid: (pid) => pid,
      },
    },
    {
      field: 'poolData.machineNum', // when minimum changes...
      updates: {
        'poolData.machineNum': (machineNum) => (machineNum >= 1 ? machineNum : 1),
      },
    },
    {
      field: 'poolData.activeMachineNum', // when minimum changes...
      updates: {
        'poolData.activeMachineNum': (activeMachineNum, allValues) => {
          const machineNum = R.view(R.lensPath(['poolData', 'machineNum']), allValues);

          if (activeMachineNum <= 0) {
            return 0;
          }
          if (activeMachineNum > machineNum) {
            return machineNum;
          }

          return activeMachineNum;
        },
      },
    },
    {
      field: 'poolData.unitCap', // when minimum changes...
      updates: {
        'poolData.unitCap': (unitCap) => (unitCap >= 1 ? unitCap : 1),
      },
    },
  );

  const generatePoolDetailsForm = () => (
    <Form
      initialValues={initialValues}
      decorators={[calculator]}
      onSubmit={onPoolUpdate}
      subscription={{ submitting: true, pristine: true, invalid: true }}
      validate={validate}
      render={({
        handleSubmit,
        submitting,
        pristine,
        invalid,
      }) => (
        <form
          id="poolDetailsForm"
          onSubmit={handleSubmit}
        >
          <Grid container justifyContent="center" spacing={4} className={classes.formGrid}>
            {getPoolContent()}
            {getPoolDataContent()}
            <Grid container item xs={10} sm={7} justifyContent="flex-end"/* className={classes.actionGrid} */>
              <Grid item>
                <RoundedButton
                  type="submit"
                  variant="contained"
                  disabled={pristine || submitting || invalid}
                  loading={updatePoolsLoading}
                  color="primary"
                >
                  { t('update')}
                </RoundedButton>
              </Grid>
              <Grid item>
                <RoundedButton
                  variant="contained"
                  color="secondary"
                  disabled={submitting}
                  onClick={onCancelButtonClick}
                >
                  { t('cancel')}
                </RoundedButton>
              </Grid>
            </Grid>
          </Grid>
        </form>
      )}
    />
  );

  return (
    <Dialog
      fullScreen={isMobileDevice}
      fullWidth
      maxWidth="md"
      onClose={onClose}
      open={open}
    >
      <Typography variant="h2" className={classes.dialogTitleText}>
        {t('Pool Details')}
      </Typography>
      <CloseIconButton
        onClick={onClose}
        color="default"
        disabled={poolDetailssLoading || updatePoolsLoading}
        className={classes.closeButton}
      />
      {
        generatePoolDetailsForm()
      }
    </Dialog>
  );
};

PoolDetails.propTypes = {
  initialValues: PropTypes.shape({
    poolId: PropTypes.string,
    pid: PropTypes.number,
    userId: PropTypes.string,
    active: PropTypes.bool,
    dateCreated: PropTypes.object, // moment
    dateUpdated: PropTypes.object, // moment
    poolData: PropTypes.shape({
      poolDataId: PropTypes.string,
      poolId: PropTypes.string,
      pid: PropTypes.number,
      userId: PropTypes.string,
      commission: PropTypes.number,
      machineNum: PropTypes.number,
      activeMachineNum: PropTypes.number,
      unitCap: PropTypes.number,
      poolCreated: PropTypes.bool,
      commissionSet: PropTypes.bool,
      capSet: PropTypes.bool,
      payoutAddress: PropTypes.string,
      dateCreated: PropTypes.object, // moment
      dateUpdated: PropTypes.object, // moment
    }),
  }).isRequired,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  onCancelButtonClick: PropTypes.func.isRequired,
  onPoolUpdate: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

PoolDetails.whyDidYouRender = true;
export default React.memo(PoolDetails);
