import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import React from 'react';
import { useTranslation } from 'react-i18next';

import ChainIcon from '../Chain/ChainIcon';

import { columnWidth, columnsVisibility, columnsToRender } from './constants';
import { styles } from './styles';

// constants

const useStyles = makeStyles(styles);

const OverviewItemDescription = (props) => {
  const classes = useStyles();
  const {
    image, name, data, showTitles, type,
  } = props;
  const { t } = useTranslation();

  const getColumnContent = (title, content, visible) => (
    <Grid item xs={12} sm={columnWidth[type]} md={columnWidth[type]} lg={columnWidth[type]} key={`overview_description_${title}`}>
      <Grid container alignItems="center" className={classes.nodeDescriptionText}>
        {showTitles && (
        <Grid item xs={12}>
          <Typography
            noWrap
            variant="body1"
            color="textSecondary"
            className={classes.nodeDescriptionTitle}
          >
            {title}
          </Typography>
        </Grid>
        )}
        <Grid item xs={12}>
          <Box
            className={classes.nodeDescriptionContent}
          >
            {visible ? content : '-'}
          </Box>
        </Grid>
      </Grid>
    </Grid>
  );

  const titles = {
    apy: t('APY'),
    amount: t('Deposit Amount'),
    quantity: t('Quantity'),
    staked: t('Staked'),
    earning: t('Total Earning'),
    balance: t('Total Balance'),
  };

  const getColumnsContent = (type, columnsToRender) => {
    const columns = columnsToRender[type];

    return R.map((eachColumn) => {
      const title = titles[eachColumn];
      const value = R.prop(eachColumn, data);
      return getColumnContent(title, value, R.prop(eachColumn, columnsVisibility[type]));
    }, columns);
  };

  return (
    <Grid container spacing={1}>
      <Grid item xs={12} sm={4} md={4} lg={4}>
        <Box display="flex" alignItems="center" className={classes.nodeDescriptionText}>
          <Grid
            container
            justifyContent="center"
            alignItems="center"
          >
            <Grid item>
              <ChainIcon
                image={image}
                title={name}
                className={classes.chainIcon}
              />
            </Grid>
            <Grid item xs>
              <Typography
                variant="body1"
                className={classes.nodeDescriptionContentNodeType}
              >
                {name}
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </Grid>
      {getColumnsContent(type, columnsToRender)}
    </Grid>
  );
};

OverviewItemDescription.defaultProps = {
  image: '',
  showTitles: false,
  type: 'naas',
};

OverviewItemDescription.propTypes = {
  data: PropTypes.shape({
    apy: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
      PropTypes.element,
    ]),
    amount: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
      PropTypes.element,
    ]),
    quantity: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
      PropTypes.element,
    ]),
    staked: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
      PropTypes.element,
    ]),
    earning: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
      PropTypes.element,
    ]),
    balance: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
      PropTypes.element,
    ]),
  }).isRequired,
  image: PropTypes.string,
  name: PropTypes.string.isRequired,
  showTitles: PropTypes.bool,
  type: PropTypes.string,
};

export default OverviewItemDescription;
