import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import whyDidYouRender from '@welldone-software/why-did-you-render';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
// toastify
import { toast } from 'react-toastify';

import { useAddFunctionToRef, useIsMobile } from '../../hooks/hooks';
import { paymentOperations } from '../../store/payment/index';
import { stripeOperations } from '../../store/stripe/index';
import { CANCEL_SUBSCRIPTION_SUCCESS } from '../../store/stripe/types';
import ChangeSubscriptionPlanDialog from '../ChangeSubscriptionPlanDialog/ChangeSubscriptionPlanDialog';
import ReactTableV7 from '../Tables/ReactTableV7';
import VerifyPasswordDialog from '../VerifyPasswordDialog/VerifyPasswordDialog';

import PaymentPageSubscriptionDetail from './PaymentPageSubscriptionDetail';
// others
import { paymentPageSubscriptionHistoryColumns } from './columns';
import { paymentPageSubscriptionHistoryStyles } from './styles';

if (process.env.REACT_APP_ENV !== 'prod') {
  whyDidYouRender(React);
}

const useStyles = makeStyles(paymentPageSubscriptionHistoryStyles);

const PaymentPageSubscription = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [subscriptionToViewDetail, setSubscriptionToViewDetail] = useState({});
  const [subscriptionToChangePlan, setSubscriptionToChangePlan] = useState({});
  const [openSubscriptionDetail, setOpenSubscriptionDetail] = useState(false);
  const [openChangePlan, setOpenChangePlan] = React.useState(false);
  const getSubscriptionsLoading = useSelector((state) => state.loadingR.getSubscriptionsLoading);

  const [subscriptionToCancel, setSubscriptionToCancel] = React.useState({});
  const [openVerifyPassword, setOpenVerifyPassword] = React.useState(false);
  const [openMenuIndex, setOpenMenuIndex] = React.useState(-1);

  const userR = useSelector((state) => state.userR);
  const { user } = userR;
  const { userId, email } = user;

  const subscriptions = useSelector((state) => state.paymentR.subscriptions.data);

  const handleMenuClose = () => {
    setOpenMenuIndex(-1);
  };
  const handleMenuOpen = (index) => {
    setOpenMenuIndex(index);
  };

  const handleChangePlanOpen = (index) => {
    setSubscriptionToChangePlan(subscriptions[index]);
    handleMenuClose();
    setOpenChangePlan(true);
  };
  const handleChangePlanClose = () => {
    setOpenChangePlan(false);
  };

  const handleVerifyPasswordClose = () => {
    setOpenVerifyPassword(false);
  };
  const handleVerifyPasswordOpen = (index) => {
    const subscriptionToCancel = subscriptions[index];
    setOpenVerifyPassword(true);
    setSubscriptionToCancel(subscriptionToCancel);
    handleMenuClose();
  };

  const onGetSubscriptionHistory = async () => {
    await dispatch(paymentOperations.getSubscriptions(userId));
  };

  const onGetSubscriptionHistoryWithLoading = useAddFunctionToRef(async () => {
    await onGetSubscriptionHistory();
  });

  useEffect(() => {
    onGetSubscriptionHistoryWithLoading();
  }, [onGetSubscriptionHistoryWithLoading]);

  const handleSubscriptionDetailOpen = (index) => {
    const data = subscriptions[index];
    setSubscriptionToViewDetail(data);
    setOpenSubscriptionDetail(true);
    handleMenuClose();
  };
  const handleSubscriptionDetailClose = () => {
    setOpenSubscriptionDetail(false);
  };

  const cancelSubscription = async (cancelSubscription) => {
    const {
      id: subscriptionId,
      metadata: {
        nodeType,
        orderId,
      },
    } = cancelSubscription;
    const { type } = await dispatch(stripeOperations.cancelSubscription(
      subscriptionId,
      userId,
      nodeType,
      orderId,
    ));
    if (type === CANCEL_SUBSCRIPTION_SUCCESS) {
      toast.success(t('Subscription has been canceled'), {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      toast.error(t('Subscription has been canceled'), {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
    await onGetSubscriptionHistory();
  };

  const columnsToUse = paymentPageSubscriptionHistoryColumns(
    classes,
    openMenuIndex,
    handleMenuClose,
    handleMenuOpen,
    handleSubscriptionDetailOpen,
    handleChangePlanOpen,
    handleVerifyPasswordOpen,
    t,
  );
  const isMobile = useIsMobile();
  const dialogProps = isMobile
    ? {
      fullScreen: true,
    }
    : {
      fullWidth: true,
      maxWidth: 'lg',
    };
  const getContent = () => {
    // NOTE: Need to add loading into ReactTableV7
    if (getSubscriptionsLoading) {
      return (
        <Box className={classes.loadingCircle}>
          <CircularProgress />
        </Box>
      );
    }
    return (
      <Paper className={classes.table}>
        <ReactTableV7 data={subscriptions} columns={columnsToUse} />
        <Dialog
          open={openSubscriptionDetail}
          onClose={handleSubscriptionDetailClose}
          {...dialogProps}
        >
          <DialogContent className={classes.invoiceDetailDialogContent}>
            <PaymentPageSubscriptionDetail
              data={subscriptionToViewDetail}
              onCloseSubscriptionDetail={handleSubscriptionDetailClose}
            />
          </DialogContent>
        </Dialog>
        {openVerifyPassword && (
          <VerifyPasswordDialog
            open={openVerifyPassword}
            onClose={handleVerifyPasswordClose}
            onSuccessCallback={() => cancelSubscription(subscriptionToCancel)}
            email={email}
          />
        )}
        {openChangePlan && (
          <ChangeSubscriptionPlanDialog
            open={openChangePlan}
            onClose={handleChangePlanClose}
            data={subscriptionToChangePlan}
            getSubscriptionsLoading={getSubscriptionsLoading}

          />
        )}
      </Paper>
    );
  };
  return getContent();
};

PaymentPageSubscription.defaultProps = {

};

PaymentPageSubscription.propTypes = {

};

PaymentPageSubscription.whyDidYouRender = true;

export default PaymentPageSubscription;
