import {
  pipeAwait,
} from 'jsutils';
import * as R from 'ramda';

import {
  returnAction,
} from '../store_utils';

import * as services from './services';
import * as types from './types';

export const uploadFileToS3 = R.curry(
  async (nodeType, file) => pipeAwait(
    services.uploadFileToS3(nodeType),
    returnAction(types.UPLOAD_FILE_TO_S3_SUCCESS),
  )(file),
);
