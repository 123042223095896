export const styles = (theme) => ({
  border: {
    ...theme.border.default,
    margin: theme.spacing(2, 0),
    padding: theme.spacing(2, 0),
  },
  container: {
    padding: theme.spacing(2),
  },
  total: {
    fontSize: theme.typography.h1.fontSize,
    fontWeight: theme.typography.h1.fontWeight,
  },
  text: {
    fontWeight: 500,
  },
  detailsItemContent: {
    whiteSpace: 'pre-line',
  },
  statusText: {
    padding: theme.spacing(0, 2),
  },
});
