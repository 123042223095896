import whyDidYouRender from '@welldone-software/why-did-you-render';
import React from 'react';
import { useTranslation } from 'react-i18next';

import CustomTabs from '../../Tabs/CustomTabs';

import PoolList from './PoolList';

if (process.env.REACT_APP_ENV !== 'prod') {
  whyDidYouRender(React);
}

const Pools = () => {
  const { t } = useTranslation();

  const tabs = [
    {
      label: t('Pools'),
      content: <PoolList />,
    },
  ];

  return (
    <CustomTabs
      tabs={tabs}
    />
  );
};

Pools.whyDidYouRender = true;

export default React.memo(Pools);
