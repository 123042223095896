import { useSelector } from 'react-redux';

export default function useUserDataSelector() {
  const user = useSelector((state) => state.userR.user);
  const admin = useSelector((state) => state.userR.admin);
  if (admin.email) {
    return {
      userId: admin.userId,
      email: admin.email,
      activated: admin.activated,
      role: admin.role,
    };
  }
  const {
    userId,
    email,
    activated,
    role,
  } = user;
  return {
    userId,
    email,
    activated,
    role,
  };
}
