import {
  web3Accounts,
  web3Enable,
  isWeb3Injected,
} from '@polkadot/extension-dapp';
import { decodeAddress, encodeAddress } from '@polkadot/util-crypto';
import { convertArrayToObject, sleep } from 'jsutils';
import toLower from 'lodash/toLower';
import * as R from 'ramda';

import { substrateSS58Registry } from './s58-registry';

export const sortAccounts = R.sortWith(
  [R.ascend((item) => item.meta.name)],
);
export const enableWeb3 = async (ss58Format) => {
  try {
    // sleep make sure that the extensions is surely injected
    await sleep(2000);
    const extensions = await web3Enable('NAAS');
    const isExtensionsInjected = !(extensions.length === 0);
    if (!isExtensionsInjected) {
      return {
        accounts: [],
        isWeb3Injected,
        isExtensionsInjected,
      };
    }
    // pass ss58Format for specific type of address. Example: PHA ss58Format = 30
    const allAccounts = await web3Accounts({ ss58Format: ss58Format || 42 });
    return {
      accounts: sortAccounts(allAccounts),
      isWeb3Injected,
      isExtensionsInjected,
    };
  } catch (error) {
    console.log(error);
  }
};

export const getChainInfo = R.curry((ss58Format) => {
  const chainInfo = R.pipe(
    R.prop('registry'),
    convertArrayToObject('prefix'),
    R.prop(ss58Format),
    R.head,
  )(substrateSS58Registry);
  return chainInfo;
});

export const getChainNetworkOrName = R.curry((ss58Format) => R.pipe(
  getChainInfo,
  (chainInfo) => {
    const {
      network,
      symbols,
    } = chainInfo;
    return symbols ? toLower(symbols[0]) : network;
  },
)(ss58Format || 42));

export const convertAddress = R.curry(
  (ss58Format, account) => {
    const { address } = account;
    const encodedAddress = encodeAddress(decodeAddress(address), ss58Format);
    return {
      ...account,
      address: encodedAddress,
    };
  },
);

export const convertAddresses = R.curry(
  (ss58Format, accounts) => R.map(
    convertAddress(ss58Format),
  )(accounts),
);
