import {
  createReducer,
  mergeStateAndPayload,
  updateStateWithKey,
} from '../store_utils';

import * as types from './types';

const secretPhraseIS = {
  secretPhraseValid: false,
  secretPhrase: '',
  publicKey: '',
  walletIndex: 0,
  userId: 0,
  links: {},
};

const secretPhraseRUpdaters = {
  [types.VALIDATE_SECRET_PHRASE_SUCCESS]: mergeStateAndPayload,
  [types.GENERATE_SECRET_PHRASE_SUCCESS]: (state, payload) => updateStateWithKey('secretPhrase', state, payload),
  [types.RESET_SECRET_PHRASE]: (state, payload) => updateStateWithKey('secretPhrase', state, payload),
  [types.SAVE_SECRET_PHRASE_VALIDATION_SUCCESS]: mergeStateAndPayload,
  [types.GET_SECRET_PHRASE]: (state, payload) => updateStateWithKey('secretPhrase', state, payload),

};
const secretPhraseR = createReducer(secretPhraseIS, secretPhraseRUpdaters);

export default secretPhraseR;
