export const referralStyles = (theme) => ({
  claimButton: {
    padding: theme.spacing(1.5, 5),
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
});

export const referralInfoLinkStyles = (theme) => ({
  linkField: {
    width: 500,
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  linkGrid: {
    ...theme.centerComponent,
  },
  textGrid: {
    ...theme.centerComponent,
    marginRight: 55,
  },
});

export const referralReferralDataSectiontyles = (theme) => ({
  referralItem: {
    ...theme.centerComponent,
    height: 200,
  },
  referralItemGrid: {
    ...theme.centerComponent,
    position: 'relative',
    [theme.breakpoints.down('sm')]: {
      display: 'block',
    },
  },
  divider: {
    position: 'absolute',
    top: 0,
    right: 0,
    [theme.breakpoints.down('sm')]: {
      position: 'static',
    },
  },
});

export const referralInfoStyles = (theme) => ({
  cardContent: {
    padding: 50,
  },
});

export const referralWalletAddressStyles = (theme) => ({
  cardContent: {
    padding: 50,
  },
  addressField: {
    margin: '8px 0px',
    width: '60%',
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
  address: {
    marginBottom: '8px',
  },
});
