import * as R from 'ramda';

import { errorOperations } from '../error/index';
import { loadingOperations } from '../loading/index';

import * as actions from './actions';
import * as types from './types';

export const subscribeToEvents = R.curry(
  async (
    connector,
    dispatch,
  ) => {
    try {
      dispatch(loadingOperations.setLoading('subscribeToEvents', true));
      if (!connector) {
        return {
          type: types.WALLET_CONNECT_SUBSCRIBE_TO_CANCEL,
          payload: connector,
        };
      }

      connector.on(
        'session_update',
        async (error, payload) => {
          if (error) {
            throw error;
          }
          const { chainId, accounts } = payload.params[0];
          const address = R.head(accounts);
          return dispatch(
            actions.onSessionUpdate(chainId,
              accounts,
              address),
          );
        },
      );
      connector.on('connect', async (error, payload) => {
        if (error) {
          throw error;
        }
        const { chainId, accounts } = payload.params[0];
        const address = accounts[0];
        dispatch(
          actions.onConnect(
            chainId,
            accounts,
            address,
          ),
        );
        await dispatch(await actions.getAccountAssets(address, chainId));
      });
      connector.on('disconnect', (error, payload) => {
        if (error) {
          throw error;
        }
        return dispatch(
          actions.onDisconnect(payload),
        );
      });

      if (connector.connected) {
        const { chainId, accounts } = connector;
        const address = accounts[0];
        dispatch(
          actions.onConnect(
            chainId,
            accounts,
            address,
          ),
        );
        dispatch(
          actions.onSessionUpdate(
            chainId,
            accounts,
            address,
          ),
        );
        await dispatch(await actions.getAccountAssets(address, chainId));
      }

      const subcribedData = dispatch(actions.subscribeToEvents(connector));
      dispatch(errorOperations.clearError('subscribeToEvents'));
      return subcribedData;
    } catch (error) {
      dispatch(loadingOperations.setLoading('subscribeToEvents', false));
      return dispatch(errorOperations.setError(
        'subscribeToEvents',
        {
          fallbackErrorMessage: 'wallet connect subscribe to event failed',
          error,
        },
      ));
    }
  },
);

export const walletConnectInit = R.curry(
  async (
    WalletConnect,
    QRCodeModal,
    dispatch,
  ) => {
    try {
      dispatch(loadingOperations.setLoading('walletConnectInit', true));
      const data = dispatch(
        await actions.walletConnectInit(
          WalletConnect,
          QRCodeModal,
        ),
      );
      // const data = await dispatch(subscribeToEvents(connector));
      dispatch(errorOperations.clearError('walletConnectInit'));
      return data;
    } catch (error) {
      dispatch(loadingOperations.setLoading('walletConnectInit', false));
      return dispatch(errorOperations.setError(
        'walletConnectInit',
        {
          fallbackErrorMessage: 'wallet connect init failed',
          error,
        },
      ));
    }
  },
);

export const { setTransactionDepositData } = actions;

export const sendTransactionWalletConnect = R.curry(
  async (
    connector,
    address,
    chainId,
    nodeType,
    dispatch,
  ) => {
    try {
      dispatch(loadingOperations.setLoading('sendTransactionWalletConnect', true));
      const data = dispatch(
        await actions.sendTransactionWalletConnect(
          {
            dispatch,
          },
          {
            connector,
            address,
            chainId,
          },
        ),
      );
      const { txid } = data.payload;
      dispatch(loadingOperations.setLoading('sendTransactionWalletConnect', false));
      dispatch(setTransactionDepositData([nodeType, 'txid'], txid));
      dispatch(errorOperations.clearError('sendTransactionWalletConnect'));
      return data;
      // const data = await dispatch(subscribeToEvents(connector));
    } catch (error) {
      dispatch(loadingOperations.setLoading('sendTransactionWalletConnect', false));
      return dispatch(errorOperations.setError(
        'sendTransactionWalletConnect',
        {
          fallbackErrorMessage: 'wallet connect send transaction failed',
          error,
        },
      ));
    }
  },
);

export const getGasPrice = R.curry(
  async (
    nodeType,
    dispatch,
  ) => {
    try {
      dispatch(loadingOperations.setLoading('getGasPrice', true));
      const data = dispatch(
        await actions.getGasPrice(
          nodeType,
        ),
      );
      dispatch(loadingOperations.setLoading('getGasPrice', false));
      dispatch(errorOperations.clearError('getGasPrice'));
      return data;
      // const data = await dispatch(subscribeToEvents(connector));
    } catch (error) {
      dispatch(loadingOperations.setLoading('getGasPrice', false));
      return dispatch(errorOperations.setError(
        'getGasPrice',
        {
          error,
        },
      ));
    }
  },
);

export const { killSession } = actions;

export const { updateWalletConnectorData } = actions;

export const { resetDepositTransactionData } = actions;
