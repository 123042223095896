import * as types from './types';
/**
 * Set testnet or mainet
 * @param  {boolean} useTestnet - if true, address and transaction
 * generator functions will be set to testnet mode
 * @return {import('../typedefs.js').ReduxAction}
 */
export const setBlockchainTestnet = (useTestnet) => (
  {
    type: types.SET_BLOCKCHAIN_TESTNET,
    payload: useTestnet,
  }
);
