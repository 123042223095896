import * as R from 'ramda';
// google-analytics
import ReactGA from 'react-ga';

import { HttpStatusCodes } from '../appConstants';
import { formatGraphqlMockError } from '../utils';

import { client } from './config';
import { mockClient } from './mock-config';

const {
  REACT_APP_ENV,
} = process.env;

export const isGraphQlError = R.curry((errorList) => (
  R.view(R.lensPath([0, 'extensions', 'isGraphQLError']), errorList)
));

/**
 * Check if error is a GRAPHQL_VALIDATION_FAILED type error. This error occurs
 * if the query or mutation schema is not correct.
 * @param  {Object} error Given error object
 * @return {bool}         Decision whether the error is GRAPHQL_VALIDATION_FAILED error or not
 */
export const isGraphQlValidationFailedError = R.curry((error) => (
  R.either(
    R.pipe(
      (error) => (
        // To get object representation of error
        JSON.parse(JSON.stringify(error))
      ),
      R.view(R.lensPath(['networkError', 'statusCode'])),
      R.equals(HttpStatusCodes.BAD_REQUEST),
    ),
    R.pipe(
      R.view(R.lensPath(['extensions', 'statusCode', 0])),
      R.equals(HttpStatusCodes.GRAPHQL_VALIDATION_FAILED),
    ),
  )(error)
));

/**
 * Since in different browser we get different message for network error we format the network
 * error here so we can easily detect network error properly in any browser and handle it
 * accordingly
 * @param  {Object} networkError Newtwork error object
 * @return {Object}              Formatted network error
 */
export const formatNetworkError = R.curry((networkError) => ({
  message: networkError.message,
  extensions: {
    statusCode: [HttpStatusCodes.SERVICE_UNAVAILABLE],
    status: ['NetworkFailure'],
  },
}));

/**
 * Format the GraphQL validation error to detect it easily. It's done to ensure all types of error
 * in FE have same format.
 * @param  {Object} graphQlValidationFailedError Error to format
 * @return {Object}                              Formatted error
 */
export const formatGraphQlValidationFailedError = R.curry((
  graphQlValidationFailedError,
) => ({
  message: graphQlValidationFailedError.message,
  extensions: {
    statusCode: [HttpStatusCodes.GRAPHQL_VALIDATION_FAILED],
    status: ['GraphQLValidationFailed'],
  },
}));

export const createQuery = R.curry(async (iQuery, variables) => {
  try {
    const requestStartTime = new Date().getTime();
    let response = null;
    if (REACT_APP_ENV === 'test') {
      response = await mockClient.query(
        {
          variables,
          query: iQuery,
        },
      );
    } else {
      response = await client.query(
        {
          variables,
          query: iQuery,
        },
      );
    }
    const responseEndTime = new Date().getTime();
    const queryNameLens = R.lensPath(['definitions', 0, 'name', 'value']);
    const queryName = R.view(queryNameLens, iQuery);
    if (queryName) {
      ReactGA.timing({
        category: 'Server Latency',
        variable: queryName,
        value: responseEndTime - requestStartTime,
        label: 'Query Response Time',
      });
    }
    return response;
  } catch (error) {
    if (isGraphQlValidationFailedError(error)) {
      return formatGraphQlValidationFailedError(error);
    }
    return formatNetworkError(error);
  }
});

export const createMutation = R.curry(async (iMutation, variables) => {
  try {
    const requestStartTime = new Date().getTime();
    let response = null;
    if (REACT_APP_ENV === 'test') {
      response = await mockClient.mutate(
        {
          variables,
          mutation: iMutation,
        },
      );
    } else {
      response = await client.mutate(
        {
          variables,
          mutation: iMutation,
        },
      );
    }
    const responseEndTime = new Date().getTime();
    const mutationNameLens = R.lensPath(['definitions', 0, 'name', 'value']);
    const mutationName = R.view(mutationNameLens, iMutation);
    if (mutationName) {
      ReactGA.timing({
        category: 'Server Latency',
        variable: mutationName,
        value: responseEndTime - requestStartTime,
        label: 'Mutation Response Time',
      });
    }
    return response;
  } catch (error) {
    if (isGraphQlValidationFailedError(error)) {
      return formatGraphQlValidationFailedError(error);
    }
    return formatNetworkError(error);
  }
});

export const graphqlNormalize = R.curry(
  (requestName, response) => {
    const data = R.view(R.lensPath(['data', requestName]), response);
    const errors = R.view(R.lensProp('errors'), response);
    if (!data) {
      if (REACT_APP_ENV === 'test') {
        console.log('Errors:', errors);
        throw formatGraphqlMockError(errors);
      }
      throw errors;
    }
    return data;
  },
);

/**
 * Reduce given GraphQL error into a given error object
 * @param  {Object} reducedErrorList Reduce given GraphQL error into this error
 *                                   object. All the GraphQL errors of a
 *                                   query/mutation will be reduced into this
 *                                   error object.
 * @param  {Object} error            GraphQL error to reduce
 * @return {Object}                  Reduced error object
 */
const reduceError = R.curry((reducedErrorList, error) => {
  const messageLens = R.lensProp('message');
  const statusCodeLens = R.lensPath(['extensions', 'statusCode']);
  const statusLens = R.lensPath(['extensions', 'status']);

  const message = R.view(messageLens, error);
  const statusCode = R.view(statusCodeLens, error);
  const status = R.view(statusLens, error);

  const mergedMessage = R.view(messageLens, reducedErrorList);
  const mergedStatusCode = R.view(statusCodeLens, reducedErrorList);
  const mergedStatus = R.view(statusLens, reducedErrorList);

  return R.pipe(
    R.set(messageLens, `${mergedMessage}${message}.`),
    R.set(statusCodeLens, R.append(statusCode, mergedStatusCode)),
    R.set(statusLens, R.append(status, mergedStatus)),
  )(reducedErrorList);
});

/**
 * Reduce all the GraphQL errors of a query/mutation into a single error object
 * @param  {Object} errorList GrapqhQL error list to reduce
 * @return {Object}           Rduced error object
 */
export const handleGraphQlError = R.curry((errorList) => R.reduce(
  reduceError,
  {
    message: '',
    extensions: {
      statusCode: [],
      status: [],
    },
  },
  errorList,
));
