import * as R from 'ramda';

import { errorOperations } from '../error/index';

import * as actions from './actions';

/**
 * Set authenticate redirect url
 *
 * @param      {string}  url
 * @param      {Function}  dispatch  The dispatch function of redux
 * @return     {import('../typedefs').ReduxAction}
 */
export const setAuthenticateRedirectPathName = R.curry(
  async (url, dispatch) => {
    try {
      const response = dispatch(
        await actions.setAuthenticateRedirectPathName(url),
      );
      dispatch(errorOperations.clearError('setAuthenticateRedirectPathName'));
      return response;
    } catch (error) {
      return dispatch(errorOperations.setError(
        'setAuthenticateRedirectPathName',
        { fallbackErrorMessage: 'Set authenticate redirect url failed', error },
      ));
    }
  },
);
