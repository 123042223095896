// constants
import { drawerWidth } from './constants';

export const styles = (theme) => ({
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    [theme.breakpoints.down('xs')]: {
      position: 'absolute',
    },
  },
  drawerPaper: {
    backgroundColor: '#fff',
    ...theme.border.default,
    borderRadius: 0,
    borderTop: 0,
    borderLeft: 0,
    borderBottom: 0,
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(9) + 1,
    [theme.breakpoints.down('xs')]: {
      width: 0,
    },
  },
  logoContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    paddingRight: theme.spacing(0.6),
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  logo: {
    width: '50px',
    height: '50px',
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyItems: 'center',
    justifyContent: 'flex-start',
    paddingLeft: theme.spacing(0.6),
    ...theme.mixins.toolbar,
  },
  mobileDrawer: {
    width: drawerWidth,
  },
  openListItem: {
    ...theme.centerComponent,
    flexDirection: 'row',
    // flexWrap: 'wrap',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    paddingRight: 0,
    paddingLeft: 0,
  },
  closeListItem: {
    ...theme.centerComponent,
    maxWidth: '73px',
    flexDirection: 'column',
    // flexWrap: 'wrap',
    paddingLeft: 0,
    paddingRight: 0,
  },
  listItem: {
    cursor: 'pointer',
  },
  selectedListItem: {
    borderWidth: 0,
    '&::after': {
      content: '""',
      backgroundColor: 'rgba(255, 255, 255, 0.5)',
      zIndex: 1,
      position: 'absolute',
      height: '100%',
      width: '100%',
    },
    '&>*': {
      zIndex: 100,
    },
  },
  listItemIcon: {
    ...theme.centerComponent,
    color: theme.customColor.almostBlack,
    fontWeight: theme,
    paddingLeft: theme.spacing(2),
  },
  selectedListItemIcon: {
    color: theme.palette.primary.main,
    // color: theme.customColor.almostBlack,
  },
  primaryListItemText: {
    fontWeight: 600,
    ...theme.font.title,
  },
  subPrimaryListItemText: {
    fontSize: 14,
    fontWeight: 500,
    ...theme.font.title,
    paddingLeft: theme.spacing(6),
  },
  selectedPrimaryListItemText: {
    fontWeight: 600,
    ...theme.font.title,
    color: theme.palette.primary.main,
  },
  selectedSubPrimaryListItemText: {
    fontSize: 14,
    fontWeight: 500,
    ...theme.font.title,
    paddingLeft: theme.spacing(6),
  },
  openPrimaryListItemText: {
    fontSize: 16,
    whiteSpace: 'nowrap',
  },
  closePrimaryListItemText: {
    fontSize: 8,
  },
  listItemText: {
    ...theme.centerComponent,
  },
  openListItemText: {
    paddingLeft: theme.spacing(2),
  },
  hiddenListItemText: {
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
  selectedListItemText: {
    color: theme.customColor.almostBlack,
  },
  mobileListItems: {
    [theme.breakpoints.down('sm')]: {
      ...theme.centerComponent,
      flexDirection: 'row',
    },
  },
  rootBox: {
    height: '100%',
    overflowY: 'auto',
    overflowX: 'hidden',
  },
  mobileImage: {
    width: 100,
    height: 'auto',
    // display: 'none',
  },
  sideBarHeader: {
    ...theme.mixins.toolbar,
    padding: process.env.REACT_APP_ANNOUNCEMENT_BAR_DISPLAY === 'ON' ? theme.spacing(1.5, 0) : theme.spacing(0),
    [theme.breakpoints.down('xs')]: {
      padding: process.env.REACT_APP_ANNOUNCEMENT_BAR_DISPLAY === 'ON' ? theme.spacing(3.5, 0) : theme.spacing(0),
    },
  },
  comingsoonBorder: {
    borderRadius: 16,
    marginRight: theme.spacing(2),
    borderColor: '#D1D1D1',
  },
  comingsoonText: {
    fontSize: 10,
    fontWeight: 600,
    marginRight: theme.spacing(0.5),
    marginLeft: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.25),
    color: '#B4B4B4',
  },
  newText: {
    fontSize: 15,
    fontWeight: 600,
    marginRight: theme.spacing(0.5),
    marginLeft: theme.spacing(0.5),
    color: theme.palette.green.main,
  },
  subItemsRoot: {
    backgroundColor: '#F4F5F9',
  },
  icon: {
    width: 21,
    height: 21,
  },
  arrowDropIcon: {
    marginRight: theme.spacing(1.5),
  },
});
