import {
  createReducer,
  mergeStateAndPayload,
} from '../store_utils';

import * as types from './types';

const contactForm = {
  open: false,
  metadata: {},
};

const contactFormUpdaters = {
  [types.OPEN_CONTACT_FORM]: (state, payload) => (
    mergeStateAndPayload(state, payload)
  ),
  [types.CLOSE_CONTACT_FORM]: (state, payload) => (
    mergeStateAndPayload(state, payload)
  ),
};
const contactFormR = createReducer(contactForm, contactFormUpdaters);

export default contactFormR;
