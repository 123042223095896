import { useWeb3React, UnsupportedChainIdError } from '@web3-react/core';
import { isNil } from 'lodash';
import isFunction from 'lodash/isFunction';
import * as R from 'ramda';
import { isBoolean } from 'ramda-adjunct';
import { useEffect, useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import { usePrevious, useAddFunctionToRef } from '../../hooks/hooks';
import { web3Operations, web3Selectors } from '../../store/web3/index';
import { truncateWalletAddress } from '../MyNodeInfo/utils';

import { getENVWhenChainChange } from './utils';

const useConnectToWallet = (props) => {
  const {
    onWalletConnect,
    onAccountChange,
    requiredBalance,
  } = props;
  const [walletConnected, setWalletConnected] = useState(false);
  const [activatingConnector, setActivatingConnector] = useState(undefined);
  const [wrongNetwork, setWrongNetwork] = useState(null);

  const dispatch = useDispatch();
  const { t } = useTranslation();
  const context = useWeb3React();
  const {
    connector,
    library,
    chainId,
    account,
    deactivate,
    active,
    error,
  } = context;
  const prevAccount = usePrevious(account);
  const prevContext = usePrevious(context);
  // CHECK if context changed, if not prevent useEffect functions from calling
  const contextChanged = !R.equals(context, prevContext);

  const { balance } = useSelector(web3Selectors.selectBalance(account));
  const enoughBalance = balance >= requiredBalance;
  useEffect(() => {
    if (!activatingConnector && connector && walletConnected) {
      setActivatingConnector(connector);
    }
  }, [activatingConnector, connector, walletConnected]);

  const onWalletConnectRef = useAddFunctionToRef(onWalletConnect);

  const onDisconnectConnector = useCallback(() => {
    setWalletConnected(false);
    if (isFunction(onWalletConnectRef)) onWalletConnectRef(false, () => {});
    setActivatingConnector(undefined);
    deactivate();
    const { close } = connector || {};
    if (isFunction(close)) close();
  }, [
    setWalletConnected,
    onWalletConnectRef,
    deactivate,
    connector,
  ]);
  /* HANDLE NETWORK CHANGE */

  const prevChainId = usePrevious(chainId);

  const chainIdChanged = !R.equals(chainId, prevChainId);

  const networkChangeOptions = {
    dev_or_stage: {
      messages: {
        error: t('We only support Goerli testnet.'),
        success: t('Goerli testnet detected.'),
      },
    },
    prod: {
      messages: {
        error: t('We only support mainnet.'),
        success: t('Mainnet detected.'),
      },
    },
  };

  const createShowPopupWrongNetworkError = (networkChangeOptions) => () => {
    const envKey = getENVWhenChainChange();
    toast.dismiss();
    return toast.error(
      R.view(
        R.lensPath([envKey, 'messages', 'error']),
        networkChangeOptions,
      ),
      {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      },
    );
  };

  const showPopupWrongNetworkError = createShowPopupWrongNetworkError(networkChangeOptions);

  const showPopupRightNetworkError = (networkChangeOptions) => () => {
    const envKey = getENVWhenChainChange();
    toast.dismiss();
    return toast.success(
      R.view(R.lensPath(
        [envKey, 'messages', 'success'],
      ),
      networkChangeOptions),
      {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      },
    );
  };

  const showPopupRightNetworkSelect = showPopupRightNetworkError(networkChangeOptions);

  const createHandleNetworkChange = useAddFunctionToRef(R.curry((
    networkChangeOptions,
    targetChainId,
    chainIdChanged,
    chainId,
  ) => {
    if (!chainId) return;
    if (chainId !== targetChainId && chainIdChanged) {
      setWrongNetwork(true);
      showPopupWrongNetworkError(networkChangeOptions);
      return;
    }
    if (chainId === targetChainId && chainIdChanged) {
      // when user change network (not when user goes to the component)
      setWrongNetwork(false);
      showPopupRightNetworkSelect(networkChangeOptions);
    }
  }));
  const handleNetworkChangeDev = createHandleNetworkChange(
    networkChangeOptions,
    5,
    chainIdChanged,
  );
  const handleNetworkChangeProd = createHandleNetworkChange(
    networkChangeOptions,
    1,
    chainIdChanged,
  );

  const networkHandleFns = {
    dev_or_stage: handleNetworkChangeDev,
    prod: handleNetworkChangeProd,
  };

  const envKey = getENVWhenChainChange();
  const networkHandleFn = networkHandleFns[envKey];

  useEffect(() => {
    if (!error) {
      if (isFunction(networkHandleFn)) networkHandleFn(chainId);
    }
  }, [chainId, envKey, networkHandleFn, error]);

  useEffect(() => {
    if (activatingConnector && active && chainId && isBoolean(wrongNetwork) && !wrongNetwork) {
      const walletConnected = true;
      setWalletConnected(walletConnected);
      if (isFunction(onWalletConnectRef)) {
        onWalletConnectRef({
          walletConnected,
          chainId,
        });
      }
      if (isFunction(onAccountChange)) onAccountChange({ account, chainId });
    }
  },
  [activatingConnector, active, onAccountChange, onWalletConnectRef, account, chainId, wrongNetwork]);

  /* HANDLE ACCOUNT CHANGE */
  const onGetBalance = useAddFunctionToRef(
    async (library, account) => {
      dispatch(web3Operations.getBalance(
        library,
        account,
      ));
    },
  );

  const handleAccountChange = useAddFunctionToRef(
    R.curry(
      async (
        library,
        prevAccount,
        account,
      ) => {
        if (!account) return;
        const accountChanged = !R.equals(prevAccount, account);
        if (prevAccount && accountChanged) {
          toast.dismiss();
          toast.info(
            `Account changed to ${truncateWalletAddress(5, -4, account)}`,
            {
              position: 'top-right',
              autoClose: 5000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            },
          );
          await onGetBalance(library, account);
          if (isFunction(onAccountChange)) onAccountChange({ account, chainId });
        }
      },
    ),
  );
  useEffect(() => {
    handleAccountChange(library, prevAccount, account);
  }, [prevAccount, account, handleAccountChange, library]);

  /* HANDLE BALANCE */

  useEffect(() => {
    if (walletConnected && account && library) {
      onGetBalance(library, account);
    }
  }, [account, library, onGetBalance, walletConnected]);

  const requiredBalanceRounded = requiredBalance.toFixed(0);
  const balanceErrorMsgs = {
    en: `Not enough balance. You need at least ${requiredBalanceRounded} ETH.`,
    ja: `残高が不足しています。少なくとも${requiredBalanceRounded}ETH必要です。`,
    'zh-TW': `餘額不足。您需要至少${requiredBalanceRounded}ETH。`,
    'zh-CN': `余额不足。您需要至少${requiredBalanceRounded}ETH。`,
    vi: `Không đủ số dư. Bạn cần ít nhất ${requiredBalanceRounded}ETH。`,
  };
  const { lang } = useSelector((state) => state.langR.langSetting);

  const getBalanceErrorMsg = R.curry(
    (balanceErrorMsgs, lang) => R.prop(lang, balanceErrorMsgs) || R.prop('en', balanceErrorMsgs),
  );
  const balanceErrorMsg = getBalanceErrorMsg(balanceErrorMsgs, lang);
  useEffect(() => {
    const showBalanceError = !isNil(balance) && !enoughBalance && walletConnected;
    if (showBalanceError) {
      toast.error(
        balanceErrorMsg,
        {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        },
      );
    }
  }, [balance, balanceErrorMsg, enoughBalance, walletConnected]);
  /* HANDLE ERROR */
  const createHandleError = useAddFunctionToRef(R.curry((
    networkChangeOptions,
    contextChanged,
    error,
  ) => {
    if (!contextChanged) return;
    if (!error) return;
    const isUnsupportedChainIdError = error instanceof UnsupportedChainIdError;
    if (isUnsupportedChainIdError) {
      setWrongNetwork(true);
      showPopupWrongNetworkError(networkChangeOptions, networkChangeOptions);
    }
  }));

  const handleError = createHandleError(networkChangeOptions);

  useEffect(() => {
    handleError(contextChanged, error);
  }, [contextChanged, error, handleError]);

  return {
    context,
    walletConnected,
    activatingConnector,
    setActivatingConnector,
    wrongNetwork,
    balance,
    enoughBalance,
    onDisconnectConnector,
    showPopupWrongNetworkError,
  };
};

export default useConnectToWallet;
