import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import AddOutlinedIcon from '@material-ui/icons/AddOutlined';
import RemoveOutlinedIcon from '@material-ui/icons/RemoveOutlined';
import whyDidYouRender from '@welldone-software/why-did-you-render';
import toNumber from 'lodash/toNumber';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';

import { useAddFunctionToRef } from '../../../hooks/hooks';
import { deployConfigOperations } from '../../../store/deploy_config/index';

const { REACT_APP_ENV, REACT_APP_PRICE_PER_VALIDATOR } = process.env;

if (REACT_APP_ENV !== 'prod') {
  whyDidYouRender(React);
}

const ConnectToWalletCalculatorValidatorNum = () => {
  const { t } = useTranslation();

  const quantity = useSelector((state) => state.deployConfigR.deployConfig.quantity);
  const dispatch = useDispatch();
  const onUpdateDeployNodeQuantity = useAddFunctionToRef((value) => (
    dispatch(deployConfigOperations.updateDeployNodeQuantity(toNumber(value)))
  ));

  const setQuantityValue = (value) => {
    if (value > 0 && value <= 10) {
      onUpdateDeployNodeQuantity(value);
    }
  };
  const handleQuantityChange = (event) => {
    const { value } = event.target;

    setQuantityValue(value);
  };

  const requiredAmountPerValidator = toNumber(REACT_APP_PRICE_PER_VALIDATOR);
  const totalCost = quantity * requiredAmountPerValidator;

  return (
    <Box
      display="flex"
    // alignItems="center"
      flexDirection="column"
    >
      <Box
        display="flex"
        flexDirection="column"
      >
        <Box
          display="flex"
          justifyContent="space-between"
        >
          <Box
            display="flex"
            flexDirection="column"
            flex={0.75}
          >
            <Box pl={4} pt={2} pb={1}>
              <Typography variant="body2" color="textSecondary" style={{ fontWeight: 550 }}>
                {t('Validators')}
              </Typography>
            </Box>
            <Box display="flex" flexGrow={1} pb={3}>
              <Box>
                <IconButton onClick={() => setQuantityValue(quantity - 1)} style={{ marginLeft: -16 }}>
                  <RemoveOutlinedIcon />
                </IconButton>
              </Box>
              <Box style={{ width: '60%' }}>
                <TextField
                  name="quantity"
                  type="tel" // use to disable the increase/decrease icon in the input
                  variant="outlined"
                  value={quantity}
                  onChange={handleQuantityChange}
                  InputProps={{ inputProps: { min: 1, max: 10 } }}
                  style={{ width: '100%' }}
                />
              </Box>
              <Box>
                <IconButton onClick={() => setQuantityValue(quantity + 1)}>
                  <AddOutlinedIcon />
                </IconButton>
              </Box>
            </Box>
          </Box>
          <Box />
          <Box
            display="flex"
            flexDirection="column"
            flex={0.25}
          >
            <Box pt={2} pb={1}>
              <Typography variant="body2" color="textSecondary" style={{ fontWeight: 550 }}>
                {t('Cost')}
              </Typography>
            </Box>
            <Box>
              <Typography style={{ fontSize: 24, fontWeight: 550 }}>
                {`${totalCost.toFixed(0)} ETH`}
              </Typography>
            </Box>
          </Box>
        </Box>
        {/* <Box
          pt={2}
          display="flex"
          justifyContent="flex-start"
          alignItems="center"
        >
          <Typography color="primary">
            {
              // i18next-extract-mark-context-next-line ["1", "32"]
              t('You need <X> ETH to run each validator node', { context: requiredAmountPerValidator.toString() })
            }
          </Typography>
        </Box> */}
      </Box>
    </Box>
  );
};

ConnectToWalletCalculatorValidatorNum.propTypes = {
};

ConnectToWalletCalculatorValidatorNum.whyDidYouRender = true;

export default React.memo(ConnectToWalletCalculatorValidatorNum);
