import Box from '@material-ui/core/Box';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ButtonLoading, RoundedButton } from '../../MaterialUiCustom/MaterialUiCustom';
import { accountsPropType } from '../propTypes';

import ConnectWalletAddress from './ConnectWalletAddress';

import useAddFunctionToRef from 'hooks/useAddFunctionToRef';
import usePrevious from 'hooks/usePrevious';

const ConnectWalletAddresses = ({
  accounts,
  onConfirm,
  createCryptoAddressLoading,
  enableWeb3Loading,
  showBtn,
  description,
  web3,
  setActiveStep,
  isExtensionsInjected,
  handleOnCancel,
}) => {
  const [localSelectedAccount, setLocalSelectedAccount] = useState({});
  const { address: selectedAddress } = localSelectedAccount;
  const { t } = useTranslation();

  const handleSelectAddress = useAddFunctionToRef((account) => {
    // onSetWalletSelectedAccount(account);
    setLocalSelectedAccount(account);
  });
  const prevSelectedAddress = usePrevious(selectedAddress);

  const isAccountEmpty = isEmpty(accounts);

  useEffect(() => {
    // only set default in initial render
    // initial prevSelectedAddress can be an undefined or empty string
    // if (!isAccountEmpty && !prevSelectedAddress) {
    //   const firstAddress = R.pipe(R.head)(accounts);
    //   handleSelectAddress(firstAddress);
    // }
  }, [accounts, handleSelectAddress, isAccountEmpty, prevSelectedAddress]);
  const extensionsInjectedButEmptyAccounts = isExtensionsInjected && isAccountEmpty;

  useEffect(() => {
    if (web3 && extensionsInjectedButEmptyAccounts) setActiveStep(0);
  }, [extensionsInjectedButEmptyAccounts, setActiveStep, web3]);
  return (
    <Box>
      {description && (
        <Box>
          <Typography>
            {description}
          </Typography>
        </Box>
      )}
      <Box>
        <List>
          {R.map(
            (account) => {
              const { address } = account;
              return (
                <ConnectWalletAddress
                  key={address}
                  selectedAddress={selectedAddress}
                  onClick={handleSelectAddress}
                  account={account}
                />
              );
            },
            accounts,
          )}
        </List>
      </Box>
      {showBtn && (
        <Box
          display="flex"
          justifyContent="flex-end"
          flexDirection="row"
        >
          <RoundedButton
            color="secondary"
            variant="contained"
            onClick={handleOnCancel}
          >
            {t('cancel')}
          </RoundedButton>
          <ButtonLoading
            onClick={() => onConfirm(
              localSelectedAccount,
            )}
            rounded
            loading={Boolean(createCryptoAddressLoading)}
            disabled={enableWeb3Loading || isEmpty(localSelectedAccount)}
          >
            {t('confirm')}
          </ButtonLoading>
        </Box>
      )}
    </Box>

  );
};

ConnectWalletAddresses.propTypes = {
  accounts: accountsPropType,
  onConfirm: PropTypes.func.isRequired,
  createCryptoAddressLoading: PropTypes.bool,
  enableWeb3Loading: PropTypes.bool,
  showBtn: PropTypes.bool.isRequired,
  description: PropTypes.string,
  web3: PropTypes.bool.isRequired,
  setActiveStep: PropTypes.func.isRequired,
  isExtensionsInjected: PropTypes.bool.isRequired,
  handleOnCancel: PropTypes.func.isRequired,
};

ConnectWalletAddresses.defaultProps = {
  accounts: [],
  createCryptoAddressLoading: undefined,
  enableWeb3Loading: undefined,
  description: undefined,
};

export default ConnectWalletAddresses;
