import * as R from 'ramda';

import { hdWallet } from '../crypto/index';
import {
  returnAction,
} from '../store_utils';

import * as types from './types';

/**
 * Generate secret phrase
 * @return {import('../typedefs').ReduxAction}
 */
export const generateSecretPhrase = R.curry(
  () => R.pipe(
    hdWallet.generateSecretPhrase,
    returnAction(types.GENERATE_SECRET_PHRASE_SUCCESS),
  )(),
);
/**
 * Reset secret phrase to an empty string
 * @return {import('../typedefs').ReduxAction}
 */
export const resetSecrePhrase = R.curry(
  () => ({ type: types.RESET_SECRET_PHRASE, payload: '' }),
);

export const getSecretPhrase = R.curry((secretPhrase) => ({
  type: types.GET_SECRET_PHRASE,
  payload: secretPhrase,
}));
