import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
// import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import ArrowDropDownOutlinedIcon from '@material-ui/icons/ArrowDropDownOutlined';
import ArrowDropUpOutlinedIcon from '@material-ui/icons/ArrowDropUpOutlined';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import toNumber from 'lodash/toNumber';
import * as R from 'ramda';
import React from 'react';

import LocalDate from '../../LocalDate/LocalDate';
import CustomTooltip from '../../Tooltip/CustomTooltip';
import { convertToCryptoNumber } from '../../utils/utils';

export const ununifiValidatorEarningHistoryColumns = (
  t,
) => [
  {
    Header: t('Date'),
    id: 'startTime',
    Cell: (data) => {
      const { startTime } = data.row.original;
      return (
        <Grid container direction="row" alignItems="center">
          <LocalDate
            date={startTime}
          />
        </Grid>
      );
    },
  },
  {
    Header: t('Earning'),
    accessor: 'totalCoin',
    Cell: (data) => {
      const { totalCoin } = data.row.original;
      const prefix = totalCoin >= 0 ? '+' : '';
      return (
        <Grid container direction="row" alignItems="center">
          <Typography style={{ color: '#607BEE' }}>
            {`${prefix}${totalCoin} GUU`}
          </Typography>
        </Grid>
      );
    },
  },
  {
    Header: t('Fiat Equivalent'),
    accessor: 'total',
    Cell: (data) => {
      const { total } = data.row.original;
      return (
        <Grid container direction="row" alignItems="center">
          {toNumber(total).toLocaleString('en-US', {
            style: 'currency',
            currency: 'JPY',
          })}
        </Grid>
      );
    },
  },
  {
    Header: t('Exchange Rate'),
    accessor: 'currencyPrice',
    Cell: (data) => {
      const { currencyPrice } = data.row.original;
      return (
        <Grid container direction="row" alignItems="center">
          {`(1GUU = ${toNumber(currencyPrice).toLocaleString('en-US', {
            style: 'currency',
            currency: 'JPY',
          })})`}
        </Grid>
      );
    },
  },
];

export const ununifiValidatorAllValidatorColumns = (
  t,
) => [
  {
    Header: t('ID'),
    accessor: 'index',
    Cell: (data) => {
      const { validatorId } = data.row.original;
      return (
        <Grid container direction="row" alignItems="center">
          {/* <Link href={ipv4Domain} target="_blank"> */}
          <Typography noWrap>
            {validatorId ? `${R.slice(0, 6, validatorId)}...` : '-'}
          </Typography>
          {/* </Link> */}
        </Grid>
      );
    },
  },
  //   {
  //     Header: (
  //       <Box display="flex" alignItems="center">
  //         <Box mr={1}>
  //           {t('APR')}
  //         </Box>
  //         <Box>
  //           <CustomTooltip
  //             title={t('Estimated average Annual Percentage Rate of your nodes since active')}
  //             content={(
  //               <HelpOutlineOutlinedIcon
  //                 color="disabled"
  //                 style={{ fontSize: 20 }}
  //               />
  // )}
  //           />
  //         </Box>
  //       </Box>
  //     ),
  //     accessor: 'apr',
  //     Cell: (data) => {
  //       const { apr } = data.row.original;
  //       return (
  //         <Grid container direction="row" alignItems="center">
  //           {`${apr || 0}%`}
  //         </Grid>
  //       );
  //     }
  //     ,
  //   },
  {
    Header: t('Staked'),
    accessor: 'staked',
    Cell: (data) => {
      const { row: { original } } = data;
      const { balance, earning, locked } = original;
      const staked = balance > 0 ? `${(balance - earning).toFixed(0)} GUU` : '-';
      return (
        <Grid container direction="row" alignItems="center">
          <Box display="flex" justifyContent="flex-start">
            <Box flexGrow={1}>
              <Typography variant="body2">
                {staked}
              </Typography>
            </Box>
            {locked && (
            <Box pl={1}>
              <CustomTooltip
                title={t('Locked tokens')}
                content={(
                  <LockOutlinedIcon color="disabled" style={{ fontSize: 16 }} />
                )}
              />
            </Box>
            )}
          </Box>
        </Grid>
      );
    },
  },
  {
    Header: t('Earning'),
    accessor: 'earning',
    Cell: (data) => {
      const { earning } = data.row.original;
      return (
        <Grid container direction="row" alignItems="center">
          {earning ? `${convertToCryptoNumber(earning)} GUU` : '-'}
        </Grid>
      );
    },
  },
  {
    Header: t('Balance'),
    accessor: 'balance',
    Cell: (data) => {
      const { balance } = data.row.original;
      return (
        <Grid container direction="row" alignItems="center">
          {balance ? `${convertToCryptoNumber(balance)} GUU` : '-'}
        </Grid>
      );
    },
  },
  {
    Header: t('Status'),
    accessor: 'status',
    Cell: (data) => {
      const { status } = data.row.original;
      return (
        <Grid container direction="row" alignItems="center">
          {status || '-'}
        </Grid>
      );
    },
  },
  {
    id: 'expander',
    sortable: false,
    Cell: (data) => {
      const { row } = data;
      return (
        <Grid
          container
          direction="row"
          alignItems="center"
          alignContent="center"
          {...row.getToggleRowExpandedProps()}
        >
          <Box display="flex">
            <Box mt={0.5}>
              <Typography color="primary">
                {t('Details')}
              </Typography>
            </Box>
            <Box mt={0.5}>
              {!row.isExpanded
                ? <ArrowDropDownOutlinedIcon color="primary" />
                : <ArrowDropUpOutlinedIcon color="primary" />}
            </Box>
          </Box>
        </Grid>
      );
    },
  },
];
