import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { gweiToEther } from 'jsutils';
import round from 'lodash/round';
import * as R from 'ramda';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import Alert from '../../MaterialUiCustom/Alert/Alert';
import { getApr } from '../../utils/utils';

import ETH2ValidatorAllValidators from './ETH2ValidatorAllValidators';
import ETH2ValidatorEarningHistory from './ETH2ValidatorEarningHistory';
import ETH2ValidatorOverview from './ETH2ValidatorOverview';

const { REACT_APP_ETH2_MAINTENANCE } = process.env;
const ETH2ValidatorContent = () => {
  const { t } = useTranslation();
  const nodeType = 'ETH2_VALIDATOR_NODE';
  const nodes = useSelector((state) => state.nodeR.userNodes.nodes);
  const eth2Nodes = R.filter(R.propEq('nodeType', nodeType), nodes);
  const {
    getUserNodesLoading,
    getTotalEarningLoading,
  } = useSelector((state) => state.loadingR);

  const loading = getUserNodesLoading || getTotalEarningLoading;

  const nodesEarning = useSelector((state) => state.nodeR.nodesEarning.eth2);
  const nodeActivatedDate = useSelector((state) => state.nodeR.nodeActivatedDate);
  const { totalEarningData } = nodesEarning;

  const nodesData = loading ? [] : R.map(
    (eachNode) => {
      const {
        nodeId,
        dateCreated,
        status,
        nodeInfo,
      } = eachNode;

      const {
        pubKey,
      } = nodeInfo;
      const earningData = R.find(R.propEq('publicKey', pubKey), totalEarningData);
      const {
        balance: balanceInETH,
        earning: earningInETH,
        index,
      } = earningData || { balance: 0, earning: 0 };

      const balance = gweiToEther(balanceInETH);
      const earning = gweiToEther(earningInETH);

      const estimateActivationDate = R.view(R.lensPath([pubKey, 'estimateActivationDate']), nodeActivatedDate);
      const dateObj = new Date(estimateActivationDate);
      const now = new Date();
      const diff = (now.getTime() - dateObj.getTime()) / (1000 * 60 * 60 * 24);
      const nodeAge = Math.abs(round(diff, 2));

      const apr = estimateActivationDate ? getApr(32, gweiToEther(earningInETH), nodeAge) : 0;

      return {
        nodeId,
        index,
        dateCreated,
        balance,
        earning,
        apr,
        status,
        pubKey,
      };
    },
  )(eth2Nodes);
  // sum all nodes APR
  const totalAPR = R.pipe(
    R.map(R.prop('apr')),
    R.sum,
  )(nodesData);
  // count all nodes which started earning
  const nodesHaveEarningCount = R.pipe(
    R.filter((eachNode) => R.prop('earning', eachNode) > 0),
    R.length,
  )(nodesData);
  const averageAPR = nodesHaveEarningCount ? totalAPR / nodesHaveEarningCount : 0;

  return (
    <Grid container justifyContent="center" spacing={4}>
      {REACT_APP_ETH2_MAINTENANCE === 'ON' && (
      <Grid item xs={12}>
        <Alert
          severity="warning"
        >
          <Typography variant="body2" color="inherit">
            {/* We will undergo general maintenance between 24:00~27:00 JST on the 5th of September to prepare for the merge. During this maintenance, you will see your validators missing epochs. */}
            {/* データをシンク中です。 */}
            {t("ETH validator nodes' earnings feature is under maintenance. Your earning is safe.")}
          </Typography>
        </Alert>
      </Grid>
      )}
      <Grid item xs={12}>
        <ETH2ValidatorOverview averageAPR={averageAPR} />
      </Grid>
      <Grid item xs={12}>
        <ETH2ValidatorAllValidators data={nodesData} />
      </Grid>
      <Grid item xs={12}>
        <ETH2ValidatorEarningHistory />
      </Grid>
    </Grid>
  );
};

export default ETH2ValidatorContent;
