import { isArrayOfStringsOrNumbers } from 'jsutils';
import isBoolean from 'lodash/isBoolean';
import isNumber from 'lodash/isNumber';
import isPlainObject from 'lodash/isPlainObject';
import isString from 'lodash/isString';
import * as R from 'ramda';

import { baseQueryOperators } from './appConstants';
import { CustomError } from './error_utils';

/**
 * enconde uri
 * @param  {string} string
 * @return {string}
 */
export const encodeURIComponentJSON = (data) => encodeURIComponent(JSON.stringify(data));
/**
 * enconde uri object's value
 * @param  {Object} obj
 * @return {string}
 */
export const encodeURIComponentObjProp = (obj) => R.map((x) => encodeURIComponent(x))(obj);
/**
 * @param  {[type]} baseQueryOperators contains all support operator key
 * @param  {string} baseOperatorKey
 * @return {string}
 */
const createGetBaseOperator = R.curry(
  (baseQueryOperators, baseOperatorKey) => {
    const operator = R.prop(baseOperatorKey, baseQueryOperators);
    if (!operator) throw new CustomError('Operator doesn\'t exist.');
    return operator;
  },
);
const getBaseOperator = createGetBaseOperator(baseQueryOperators);
const generateObjectOperator = R.curry((operator) => `[${operator}]=`);

const getObjectOperator = R.curry((baseOperatorKey) => R.pipe(
  getBaseOperator,
  generateObjectOperator,
)(baseOperatorKey));

const checkIfFirstItem = (index) => index === 0;
/**
 * Combine key and value into query string format.
 * @param  {Boolean} isFirstItem true if
 * the query prop is the first item of the query object
 * @param  {strring}  key the of query property
 * @param  {string|number}  value the value of the query property
 * this value can also be the sub value of the query value when the query value is an object
 * @param  {string}  operator query string's operator. Eg. '=', '[]='
 * @return {string}  Eg. '?foo=bar'
 */
const combineQueryKeyValue = R.curry(
  (
    isFirstItem,
    queryKey,
    value,
    operator,
  ) => {
    const combinedKeyValue = `${queryKey}${operator}${encodeURIComponent(value)}`;
    return isFirstItem
      ? `?${combinedKeyValue}`
      : `&${combinedKeyValue}`;
  },
);
/**
 * @param  {Boolean} isFirstItem
 * @param  {string}  queryKey
 * @param  {string|numnber}  queryValue the value of the query property
 * @return {string}
 */
const combineStringQueryKeyValue = R.curry(
  (
    isFirstItem,
    queryKey,
    queryValue,
  ) => R.pipe(
    getBaseOperator,
    combineQueryKeyValue(
      isFirstItem,
      queryKey,
      queryValue,
    ),
  )('eq'),
);
const checkIfIsFirstItemOfAQueryProp = R.curry(
  (isFirstItem, objectItemIndex) => isFirstItem && objectItemIndex === 0,
);
/**
 * @param  {Boolean} isFirstItem
 * @param  {string}  queryKey
 * @param  {string}  acc
 * @param  {[type]}  valuePair the key and value of the value object
 * Eg. query prop {lt:1,gt:0} => valuePair = [[lt,1], [gt,0]]
 * @param  {numberr}  objectItemIndex the index of the item in query prop
 * @return {string}
 */
const combineObjectQueryKeyValue = R.curry(
  (
    isFirstItem,
    queryKey,
    acc,
    valuePair,
    objectItemIndex,
  ) => {
    const [baseOperatorKey, value] = valuePair;
    const isFirstItemOfAQueryProp = checkIfIsFirstItemOfAQueryProp(isFirstItem, objectItemIndex);
    const combinedQuery = R.pipe(
      getObjectOperator,
      combineQueryKeyValue(isFirstItemOfAQueryProp, queryKey, value),
      // R.add(acc), this doesn't work TODO firgure out why
    )(baseOperatorKey);
    return acc + combinedQuery;
  },
);
/**
 * @param  {Boolean} isFirstItem
 * @param  {string}  queryKey the key of the query property
 * @param  {string|number}  queryValue the value of the query property
 * @return {string}
 */
const combineObjectQueryKeysValues = R.curry(
  (isFirstItem, queryKey, queryValue) => R.pipe(
    R.toPairs,
    R.addIndex(R.reduce)(
      combineObjectQueryKeyValue(isFirstItem, queryKey),
      '',
    ),
  )(queryValue),
);
const generateArrayOperator = R.curry(() => '[]=');
const getArrayOperators = R.curry((baseOperatorKey) => R.pipe(
  getBaseOperator,
  generateArrayOperator,
)(baseOperatorKey));
/**
 * @param  {Boolean} isFirstItem true if
 * the query prop is the first item of the query object
 * @param  {string}  queryKey the key of the query property
 * @param  {string}  acc  reduce's acc
 * @param  {[type]}  queryValueItem  the item inside queryValue Array
 * @param  {[type]}  arrayItemIndex  the index of the queryValueItem in queryValue Array
 * @return {string}
 */
const combineArrayQueryKeyValue = R.curry(
  (
    isFirstItem,
    queryKey,
    acc,
    queryValueItem,
    arrayItemIndex,
  ) => {
    const isFirstItemOfAQueryProp = checkIfIsFirstItemOfAQueryProp(isFirstItem, arrayItemIndex);
    const combinedQuery = R.pipe(
      getArrayOperators,
      combineQueryKeyValue(isFirstItemOfAQueryProp, queryKey, queryValueItem),
    )('eq');
    return acc + combinedQuery;
  },
);
/**
 * @param  {Boolean} isFirstItem true if
 * the query prop is the first item of the query object
 * @param  {string}  queryKey the key of the query property
 * @param  {string|string[]|number|number[]|Object}  queryValue the value of the query property
 * @return {string|string[]}
 */
const combineArrayQueryKeysValues = R.curry(
  (
    isFirstItem,
    queryKey,
    queryValue,
  ) => R.addIndex(R.reduce)(
    combineArrayQueryKeyValue(isFirstItem, queryKey),
    '',
  )(queryValue),
);
/**
 * Generate query property
 * @param  {Array} queryPair the key and value of a query property. Eg. [foo, bar]
 * @param  {number} index the index of a query property in query object
 * @return {string|string[]}  Eg. ?foo=bar or ['?foo=bar', '&name=bob']
 */
export const generateQueryProp = R.curry(
  (queryPair, index) => {
    const [queryKey, queryValue] = queryPair;
    const isFirstItem = checkIfFirstItem(index);
    if (isString(queryValue) || isNumber(queryValue) || isBoolean(queryValue)) {
      return combineStringQueryKeyValue(isFirstItem, queryKey, queryValue);
    } if (isPlainObject(queryValue)) {
      return combineObjectQueryKeysValues(isFirstItem, queryKey, queryValue);
    } if (isArrayOfStringsOrNumbers(queryValue)) {
      return combineArrayQueryKeysValues(isFirstItem, queryKey, queryValue);
    }
    throw new Error('Query\'s type is not supported');
  },
);
/**
 * Generate query's properties.
 * @param  {Object} query contains query's properties. Eg. {foo: bar}
 * @return {String}  query string  Eg. ?foo=bar
 */
export const generateQueryProps = R.curry(
  (query) => R.pipe(
    R.toPairs,
    R.addIndex(R.map)(generateQueryProp),
    R.join(''),
  )(query),
);
/**
 * Appending query string to base url
 * @param  {string} baseUrl
 * @param  {string} query
 * @return {string}
 */
const appendQueryToUrl = R.curry(
  (baseUrl, queryString) => baseUrl + queryString,
);
/**
 * @param {string} baseUrl
 * @param {object} query
 * @return {string}
 */
export const generateAndAppendQueryToUrl = R.curry(
  (baseUrl, query) => (
    R.pipe(
      generateQueryProps,
      appendQueryToUrl(baseUrl),
    )(query)
  ),
);
