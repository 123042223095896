import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import CheckCircleOutlineOutlinedIcon from '@material-ui/icons/CheckCircleOutlineOutlined';
import isString from 'lodash/isString';
import PropTypes from 'prop-types';
import React from 'react';

import { RoundedButton } from '../MaterialUiCustom/MaterialUiCustom';

import { successPageStyles } from './styles';

const useStyles = makeStyles(successPageStyles);

const SuccessPage = ({
  align,
  onCancel,
  message,
  btnText,
  primaryText,
  className,
  ...parentBoxProps
}) => {
  const classes = useStyles();

  const getMessageContent = (message) => {
    if (isString(message)) {
      return (
        <Typography
          align={align}
          variant="body1"
          className={classes.secondaryText}
        >
          {message}
        </Typography>
      );
    }

    return (
      <Box className={classes.secondaryText}>
        {message}
      </Box>
    );
  };

  return (
    <Box
      display="flex"
      alignItems="center"
      flexDirection="column"
      width="100%"
      className={className}
      {...parentBoxProps}
    >
      <Box>
        <CheckCircleOutlineOutlinedIcon
          className={classes.icon}
        />
      </Box>
      <Box pt={3}>
        <Typography
          align={align}
          variant="h2"
          className={classes.primaryText}
        >
          {primaryText}
        </Typography>
      </Box>
      <Box pt={1}>
        {getMessageContent(message)}
      </Box>
      <Box pt={3}>
        <RoundedButton
          variant="contained"
          onClick={onCancel}
        >
          {btnText}
        </RoundedButton>
      </Box>
    </Box>
  );
};

SuccessPage.defaultProps = {
  align: 'left',
  className: '',
};

SuccessPage.propTypes = {
  className: PropTypes.string,
  align: PropTypes.string,
  onCancel: PropTypes.func.isRequired,
  primaryText: PropTypes.string.isRequired,
  message: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
  ]).isRequired,
  btnText: PropTypes.string.isRequired,
};

SuccessPage.defaultProps = {
};

export default SuccessPage;
