const contentWidth = (theme) => ({
  maxWidth: 608,
});

export const marketProtocolDeployContentItemStyles = ((theme) => ({
  marketProtocolDeployContent: {
    ...theme.centerComponent,
    ...contentWidth(theme),
    width: '100%',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  title: {
    fontSize: 18,
    fontWeight: 600,
    padding: theme.spacing(0, 1, 4.5, 1),
  },
}));

export const marketProtocolDeployCompleteStyles = ((theme) => ({
  congrat: {
    ...contentWidth(theme),
    fontWeight: 600,
    [theme.breakpoints.down('xs')]: {
      fontSize: 20,
    },
  },
  description: {
    ...contentWidth(theme),
  },
  depositDetailsItem: {
    maxWidth: 352,
  },
}));

export const marketProtocolDeployDepositStyles = ((theme) => ({
  contentTitle: {
    ...theme.centerComponent,
    ...contentWidth(theme),
    fontWeight: 600,
    [theme.breakpoints.down('xs')]: {
      fontSize: 20,
    },
  },
  leftContent: {
    fontWeight: 600,
    [theme.breakpoints.down('xs')]: {
      fontSize: 15,
    },
  },
  item: {
    ...theme.centerComponent,
    padding: theme.spacing(1, 1),
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(0, 1, 0, 1),
    },
  },
}));

export const marketProtocolDeployPaymentStyles = (theme) => ({
  root: {
    ...theme.centerComponent,
    width: '100%',
  },
  payment: {
    ...contentWidth(theme),
    ...theme.border.default,
    width: '100%',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  paymentContent: {
    margin: theme.spacing(4),
  },
  planButton: {
    width: 120,
    textTransform: 'none',
  },
  discountOnButton: {
    color: theme.palette.primary.main,
    position: 'absolute',
    top: '0%',
    left: '80%',
    marginTop: -12,
    marginLeft: -19,
  },
  discountOnButtonText: {
    fontSize: 12,
    fontWeight: 600,
    marginLeft: 6,
    marginRight: 6,
  },
  totalText: {
    fontSize: 36,
    fontWeight: 600,
  },
  currencySymbol: {
    fontSize: 24,
    fontWeight: 600,
  },
  valueText: {
    fontSize: 36,
    fontWeight: 600,
  },
  planList: {
    paddingBottom: theme.spacing(4),
  },
});
