import * as R from 'ramda';

import {
  sessionStorageGetItem,
  sessionStorageSetItem,
  sessionStorageRemoveItem,
} from '../../modules/browser_utils';

export const setImpersonationDataToSessionStorage = R.curry((isImpersonating, impersonateUser) => {
  sessionStorageSetItem('isImpersonating', isImpersonating);
  sessionStorageSetItem('impersonatedUser', impersonateUser);
});

export const getImpersonationDataFromSessionStorage = () => {
  const isImpersonating = sessionStorageGetItem('isImpersonating');
  const impersonatedUser = sessionStorageGetItem('impersonatedUser');
  return {
    isImpersonating,
    impersonatedUser,
  };
};

export const removeImpersonationDataFromSessionStorage = () => {
  sessionStorageRemoveItem('isImpersonating');
  sessionStorageRemoveItem('impersonatedUser');
};
