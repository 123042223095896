import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

// routes

import MyNodeInfoDescriptionItem from '../MyNodeInfoDescriptionItem';

// others

import { stylesDescription } from './styles';

const useStyles = makeStyles(stylesDescription);

const ETH2MyNodeInfoNodeVersion = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { nodeVersion } = props;
  return (
    <Box className={classNames(classes.descriptionContent)}>
      <MyNodeInfoDescriptionItem
        title={t('Node Version')}
        content={
          <Typography variant="body1">{nodeVersion}</Typography>
        }
      />
    </Box>
  );
};

ETH2MyNodeInfoNodeVersion.defaultProps = {
  nodeVersion: '',
};

ETH2MyNodeInfoNodeVersion.propTypes = {
  nodeVersion: PropTypes.string,
};

export default ETH2MyNodeInfoNodeVersion;
