import gql from 'graphql-tag';

export const createNodeProviderOrderMutation = gql`
  query createNodeProviderOrder($createNodeProviderOrderInput: CreateNodeProviderOrderInput!)
  {
    createNodeProviderOrder(
      createNodeProviderOrderInput: CreateNodeProviderOrderInput
    ) {
      nodeProviderOrderId
      userId
      quantity
      amount
      dateCreated
      dateUpdated
      contract {
        contractStartDate
        contractEndDate
        contractPeriodValue
        contractPeriod
        contractIRR
        dateCreated
        dateUpdated
      }
      timeline {
        nodeProviderOrderTimelineId
        dateCreated
        dateUpdated
        dateCompleted
        expectDateCompleted
        stepName
        stepStatus
      }
      devices {
        deviceId
        macAddress
        deviceStatus
        dateCreated
        dateUpdated
        dateActive
        earnings {
          nodeProviderEarningId
          earning
          dateCreated
          dateUpdated
          cryptoPrices
          currencySymbol
          deviceId
        }
      }
    }
  }
`;
