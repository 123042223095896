import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React from 'react';

import { secretPhraseConfirmationStyles } from './styles';

const useStyles = makeStyles(secretPhraseConfirmationStyles);

const SecretPhraseConfirmation = (props) => {
  const classes = useStyles();
  const {
    inputSecretPhrase,
    onInputSecretPhraseChange,
  } = props;
  return (
    <TextField
      id="confirm-secret-phrase"
      variant="outlined"
      name="confirm-secret-phrase"
      value={inputSecretPhrase}
      onChange={onInputSecretPhraseChange}
      className={classes.confirmSecrePhraseField}
      InputProps={
          {
            classes: {
              inputMultiline: classes.inputMultiline,
              multiline: classes.multiline,
            },
          }
        }
      autoComplete="off"
      multiline
    />
  );
};
SecretPhraseConfirmation.propTypes = {
  /**
   * Secret phrase that use input
   */
  inputSecretPhrase: PropTypes.string,
  /**
   * onChange in TextField
   */
  onInputSecretPhraseChange: PropTypes.func,
};
SecretPhraseConfirmation.defaultProps = {
  inputSecretPhrase: '',
  onInputSecretPhraseChange: () => {},
};
export default SecretPhraseConfirmation;
