import moment from 'moment';
import * as R from 'ramda';

const getPriceId = (orderType) => {
  if (orderType === 'NODE_PROVIDER_NEUNODE') {
    return process.env.REACT_APP_NEUNODE_PRICE;
  }

  if (orderType === 'NODE_PROVIDER_MINING') {
    return process.env.REACT_APP_PHALA_MINING_NODE_PRICE;
  }

  return null;
};
export const formatCreateOrdersInput = (data) => R.map(
  (order) => {
    const {
      email,
      quantity,
      orderType,
      ignorePayment,
      contractSignDate,
      contractStartDate,
      contractPeriodValue,
      contractPeriod,
      contractType,
      paymentMethod,
      amount,
      currencySymbol,
      trialDays,
      stripeInvoiceId,
      stripePaymentMethodId,
      stripePriceId,
      paymentType,
      unitCap,
      poolCreated,
      commissionSet,
      capSet,
      payoutAddress,
      commission,
      timelineOrderStepStatus,
      timelinePaymentStepStatus,
      timelineSetupStepStatus,
      timelineCompleteSetupStepStatus,
      timelineOrderStepCompletedDate,
      timelinePaymentStepCompletedDate,
      timelineSetupStepCompletedDate,
      timelineCompleteSetupStepCompletedDate,
      activeDate,
      nodeType,
      nodesName,
    } = order;

    const priceId = getPriceId(orderType) || stripePriceId;
    const contractStartDateToUse = moment(contractStartDate).utc().toISOString();
    const contractEndDate = moment(contractStartDateToUse).add(contractPeriodValue, contractPeriod).toISOString();

    const dateUpdated = moment().toISOString();
    const timeline = [
      {
        stepName: 'ORDER',
        stepStatus: timelineOrderStepStatus,
        dateCompleted: timelineOrderStepStatus === 'COMPLETE' ? timelineOrderStepCompletedDate : null,
        dateUpdated,
      },
      {
        stepName: 'PAYMENT',
        stepStatus: timelinePaymentStepStatus,
        dateCompleted: timelinePaymentStepStatus === 'COMPLETE' ? timelinePaymentStepCompletedDate : null,
        dateUpdated,
      },
      {
        stepName: 'SETUP',
        stepStatus: timelineSetupStepStatus,
        dateCompleted: timelineSetupStepStatus === 'COMPLETE' ? timelineSetupStepCompletedDate : null,
        dateUpdated,
      },
      {
        stepName: 'COMPLETE_SETUP',
        stepStatus: timelineCompleteSetupStepStatus,
        dateCompleted: timelineCompleteSetupStepStatus === 'COMPLETE' ? timelineCompleteSetupStepCompletedDate : null,
        dateUpdated,
      },
    ];
    const nodes = nodesName ? R.pipe(
      R.replace(' ', ''),
      R.split(','),
      R.map((nodeName) => ({
        nodeName,
        nodeType,
        subNode: {},
      })),
    )(nodesName) : null;

    return {
      email,
      quantity,
      orderType,
      activeDate,
      ignorePayment,
      nodes: orderType === 'NAAS' ? nodes : null,
      contract: {
        contractSignDate,
        contractStartDate: contractStartDateToUse,
        contractEndDate,
        contractPeriodValue,
        contractPeriod,
        contractType,
        quantity,
      },
      timeline,
      payment: {
        paymentMethod,
        amount,
        currencySymbol,
        trialDays,
        stripe: {
          stripeInvoiceId,
          stripePaymentMethodId,
          stripePriceId: priceId,
        },
        paymentType,
      },
      phalaPool: {
        email,
        machineNum: quantity,
        unitCap,
        poolCreated,
        commissionSet,
        capSet,
        payoutAddress: payoutAddress === '' ? null : payoutAddress,
        commission,
      },
    };
  },
)(data);
