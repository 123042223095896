// user
export const allNotFoundPageRoute = '*';
export const notFoundPageRoute = '/404';
export const rootRoute = '/';
export const forgotPasswordRoute = '/user/forgot';
export const resetPasswordRoute = '/forgot-password/:forgotPasswordId';
export const signinRoute = '/signin';
export const firstSigninRoute = '/first-signin/:token';
export const signinMultisigRoute = '/signin-ms';
export const signupRoute = '/signup';
export const signupSuccessRoute = `${signupRoute}/success`;
export const accountRoute = '/account';
export const dashboardRoute = '/dashboard';
export const myNodeRoute = '/my-nodes';
export const myNodeListRoute = `${myNodeRoute}/:type`;
export const marketRoute = '/protocols';
export const chainInfoRoute = '/protocols/:chainAbbreviation';
export const paymentPageRoute = '/payment';
export const martketDeployRoute = `${marketRoute}/:protocolName/deploy`;
export const activationRoute = '/activation/:activationId';
export const nodeInfoRoute = `${myNodeRoute}/:nodeName/info`;
export const referralRoute = '/referral';
export const connectToWalletRoute = '/connect-wallet';
export const investmentBaseRoute = '/investment';
export const investmentRoute = `${investmentBaseRoute}/:service`;
export const nodeOwnerRoute = '/node-owner';
export const nodeOwnerServiceRoute = `${nodeOwnerRoute}/:service`;
export const dappStoreRoute = '/dapp-store';
export const walletRoute = '/wallet';

export const excludedRoutes = [
  forgotPasswordRoute,
  resetPasswordRoute,
  signupRoute,
  marketRoute,
  chainInfoRoute,
  `${marketRoute}/ununifi/deploy`,
];

// admin
export const signinAdminRoute = `/signin/${process.env.REACT_APP_ADMIN_SIGN_IN_PAGE_UNLOCK_KEY}`;
export const signupAdminRoute = '/admin/signup';
export const adminDashboardRoute = '/admin/dashboard';
export const adminEarningRoute = '/admin/earning';
export const adminOrdersRoute = '/admin/orders';
export const adminPoolsRoute = '/admin/pools';
