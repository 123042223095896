import {
  pipeAwait,
} from 'jsutils';
import isFunction from 'lodash/isFunction';
import * as R from 'ramda';

import {
  axiosGetUnsecure,
} from '../../modules/axios_utils';
import {
  returnAction,
} from '../store_utils';

import {
  bridge,
  ethApiUrl,
} from './constants';
import * as services from './services';
import * as types from './types';

const createWalletConnectInit = R.curry(
  async (
    bridge,
    WalletConnect,
    QRCodeModal,
  ) => {
    // create new connector
    const connector = new WalletConnect({
      bridge,
      qrcodeModal: QRCodeModal,
    });

    // check if already connected
    if (!connector.connected) {
      // create new session
      await connector.createSession();
    }

    return {
      type: types.INITIALIZE_WALLET_CONNECT,
      payload: connector,
    };
  },
);

export const walletConnectInit = createWalletConnectInit(
  bridge,
);

export const onSessionUpdate = R.curry(
  (chainId,
    accounts,
    address) => ({
    type: types.ON_WALLET_CONNECT_UPDATE,
    payload: {
      chainId,
      accounts,
      address,
    },
  }),
);

export const onConnect = R.curry(
  (chainId,
    accounts,
    address) => ({
    type: types.ON_WALLET_CONNECT_CONNECT,
    payload: {
      connected: true,
      chainId,
      accounts,
      address,
    },
  }),
);

export const onDisconnect = R.curry(
  (disconnectPayload) => ({
    type: types.ON_WALLET_CONNECT_DISCONECT,
    payload: {
      connected: false,
      data: disconnectPayload,
    },
  }),
);

export const killSession = (connector) => {
  if (!connector || !isFunction(connector.killSession)) {
    return {
      type: types.KILL_WALLET_CONNECT_SESSION,
      payload: { killed: false },
    };
  }
  connector.killSession();

  return {
    type: types.KILL_WALLET_CONNECT_SESSION,
    payload: { killed: true },
  };
};

export const createGetAccountAssets = R.curry(
  async (ethApiUrl, address, chainId) => {
    const url = `${ethApiUrl}/account-assets?address=${address}&chainId=${chainId}`;
    return pipeAwait(
      axiosGetUnsecure,
      R.prop('result'),
      returnAction(types.GET_WALLET_CONNECT_ACCOUNT_ASSETS),
    )(url);
  },
);

export const getAccountAssets = createGetAccountAssets(ethApiUrl);

export const subscribeToEvents = R.curry((connector) => ({
  type: types.WALLET_CONNECT_SUBSCRIBE_TO_EVENTS,
  payload: connector,
}));

// export const createGetAccountNonce = R.curry(
//   async (ethApiUrl, address, chainId) => {
//     const url = `${ethApiUrl}/account-nonce?address=${address}&chainId=${chainId}`;
//     return pipeAwait(
//       axiosGetUnsecure,
//       chainAsync(
//         R.prop('result'),
//       ),
//       returnAction(types.GET_WALLET_CONNECT_ACCOUNT_NONCE),
//     )(url);
//   },
// );
//
// export const getAccountNonce = createGetAccountNonce(ethApiUrl);

export const sendTransactionWalletConnect = R.curry(
  async (
    {
      dispatch,
    },
    {
      connector,
      address,
      chainId,
    },
  ) => {
    const rejectType = `REJECT_${types.SEND_TRANSACTION_WALLET_CONNECT_SUCCESS}`;

    if (!connector) {
      return {
        type: rejectType,
        payload: 'Connector null',
      };
    }

    // from
    const from = address;

    // to
    const to = address;

    // nonce
    const nonce = await pipeAwait(
      services.getAccountNonce(address),
      services.handleSanitizeHex,
    )(chainId);

    const gasPrice = await pipeAwait(
      services.getGasPrice,
      services.handleSanitizeHex,
      services.convertAmountToRawNumber(9),
    )(ethApiUrl);

    const gasLimit = services.handleSanitizeHex(21000);

    const value = services.handleSanitizeHex(0);

    // data
    const data = '0x';
    // test transaction
    const tx = {
      from,
      to,
      nonce,
      gasPrice,
      gasLimit,
      value,
      data,
    };
    const txid = await connector.sendTransaction(tx);
    return {
      type: types.SEND_TRANSACTION_WALLET_CONNECT_SUCCESS,
      payload: { txid, connector },
    };
  //     try {
  //       // open modal
  //       this.toggleModal();
  //
  //       // toggle pending request indicator
  //       this.setState({ pendingRequest: true });
  //
  //       // send transaction
  //       const result = await connector.sendTransaction(tx);
  //
  //       // format displayed result
  //       const formattedResult = {
  //         method: "eth_sendTransaction",
  //         txHash: result,
  //         from: address,
  //         to: address,
  //         value: "0 ETH",
  //       };
  //
  //       // display result
  //       this.setState({
  //         connector,
  //         pendingRequest: false,
  //         result: formattedResult || null,
  //       });
  //     } catch (error) {
  //       console.error(error);
  //       this.setState({ connector, pendingRequest: false, result: null });
  //     }
  },
);
export const updateWalletConnectorData = R.curry((updateKey, data) => ({
  type: types.UPDATE_WALLET_CONNECTOR_DATA,
  payload: data,
}));

export const setTransactionDepositData = R.curry((updateKeyPath, data) => ({
  type: types.UPDATE_DEPOSIT_TRANSACTION_DATA,
  payload: { updateKeyPath, data },
}));

export const resetDepositTransactionData = R.curry(
  () => ({
    type: types.RESET_DEPOSIT_TRANSACTION_DATA,
  }),
);

export const getGasPrice = async (nodeType) => {
  const gasPrice = await pipeAwait(
    services.getGasPrice,
    services.handleSanitizeHex,
    services.convertAmountToRawNumber(9),
  )(ethApiUrl);

  return {
    type: types.GET_GAS_PRICE,
    payload: { gasPrice, nodeType },
  };
};
