import * as R from 'ramda';
import { combineReducers } from 'redux';
import { PURGE } from 'redux-persist';

import {
  createReducer,
  mergeStateAndPayload,
  updateStateWithKey,
} from '../store_utils';

import * as types from './types';

/**
 * Load user reducer format
 *
 * @type       {import('./typedefs').LoadUserIS}
 */
export const loadUserIS = {
  email: '',
  userId: '',
  joined: '',
  modified: '',
  lastSignin: '',
  activated: false,
  role: 'USER',
};

const loadUserRUpdaters = {
  [types.UPDATE_EMAIL_SUCCESS]: mergeStateAndPayload,
  [types.LOAD_USER]: mergeStateAndPayload,
  [PURGE]: R.always(loadUserIS),
  [types.RESET_LOAD_USER]: R.always(loadUserIS),
};

/**
 * Load user reducer
 *
 * @param      {import('./typedefs').LoadUserIS}  loadUserIS
 * @param      {object}  loadUserRUpdaters
 * @return     {any} return load user reducer
 */
const loadUserR = createReducer(loadUserIS, loadUserRUpdaters);

/**
 * Load admin reducer format
 *
 * @type       {import('./typedefs').LoadAdminIS}
 */
export const adminDetailsIS = {
  email: '',
  userId: '',
  joined: '',
  modified: '',
  lastSignin: '',
  activated: false,
  role: 'ADMIN',
};

const adminDetailsRUpdaters = {
  [types.LOAD_ADMIN]: mergeStateAndPayload,
  [PURGE]: R.always(adminDetailsIS),
};

export const adminDetailsR = createReducer(adminDetailsIS, adminDetailsRUpdaters);

/**
 * Authentication reducer format
 *
 * @type       {import('./typedefs').AuthenticateIS}
 */
export const authenticateIS = {
  isAuthenticated: false,
};
const authenticateRUpdaters = {
  [types.AUTHENTICATE_USER]: (
    state,
    payload,
  ) => updateStateWithKey('isAuthenticated', state, payload),
  [types.SIGN_OUT]: (
    state,
    payload,
  ) => R.mergeRight(state, payload),
};

/**
 * Authentication reducer
 *
 * @param      {import('./typedefs').AuthenticateIS}  authenticateIS
 * @param      {object}  authenticateRUpdaters
 * @return     {any} return Authentication reducer
 */
const authenticateR = createReducer(authenticateIS, authenticateRUpdaters);

export const firstSigninsIS = {
  items: [],
};
const firstSigninsRUpdaters = {
  [types.GET_FIRST_SIGNINS_SUCCESS]: mergeStateAndPayload,
  [PURGE]: R.always(firstSigninsIS),
};

const firstSigninsR = createReducer(firstSigninsIS, firstSigninsRUpdaters);

/**
 * User reducer
 *
 * @type       {any}
 */
const userR = combineReducers({
  user: loadUserR,
  admin: adminDetailsR,
  authentication: authenticateR,
  firstSignins: firstSigninsR,
});
export default userR;
