import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
// routes

import { useHistory } from 'react-router-dom';

import { useGetQuery } from '../../hooks/hooks';
import { routerServices } from '../../store/router/index';
import ChainName from '../Chain/ChainName';
import { ChainStatus } from '../Market/constants';

import MarketCardAction from './MarketCardAction';
import MarketCardContent from './MarketCardContent';
import { styles } from './styles';

import { marketRoute } from 'routes';

const useStyles = makeStyles(styles);

const MarketCard = (props) => {
  const history = useHistory();
  const queryLng = useGetQuery('lng');
  const classes = useStyles();
  const [elevation, setElevation] = useState(1);
  const {
    chain,
  } = props;
  const { abbreviation, status, nodeTypes } = chain;
  const onMouseEnter = () => {
    setElevation(3);
  };
  const onMouseLeave = () => {
    setElevation(1);
  };

  const handleOnClick = (marketRoute, abbreviation) => {
    routerServices.historyPush(
      history,
      {
        pathname: `${marketRoute}/${abbreviation}`,
        queryLng,
      },
    );
  };
  return (
    <Card
      elevation={elevation}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      className={classes.card}
    >
      <Box display="flex" flexDirection="column" justifyContent="space-between" style={{ height: '100%' }}>
        <Box>
          <CardHeader
            title={(
              <Box className={classes.cardTitle}>
                <ChainName noWrap title={chain.name} />
              </Box>
        )}
            action={(
              <Box mt={0.5} mr={0.5}>
                <IconButton
                  onClick={() => handleOnClick(marketRoute, abbreviation)}
                  disabled={status === ChainStatus.DISABLED || status === ChainStatus.MAINTENANCE}
                  style={{
                    padding: 0,
                  }}
                >
                  <InfoOutlinedIcon />
                </IconButton>
              </Box>
        )}
            className={classes.cardHeader}
          />
          <CardContent
            className={classes.cardContent}
          >
            <MarketCardContent chain={chain} />
          </CardContent>
        </Box>
        <Box>
          <CardActions className={classes.cardActionHover}>
            <MarketCardAction
              chainAbbreviation={abbreviation}
              nodeTypes={nodeTypes}
            />
          </CardActions>
        </Box>
      </Box>
    </Card>
  );
};

MarketCard.propTypes = {
  /**
   * chain to show
   */
  chain: PropTypes.shape({
    name: PropTypes.string.isRequired,
    abbreviation: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
    // todo add properties detail
    brief: PropTypes.string.isRequired,
    iconPath: PropTypes.string.isRequired,
    // todo add properties detail
    nodeTypes: PropTypes.arrayOf(
      PropTypes.shape({
        minUnit: PropTypes.number.isRequired,
        linksSocialMedia: PropTypes.array.isRequired,
        type: PropTypes.string.isRequired,
        pricing: PropTypes.arrayOf(PropTypes.shape({
          price: PropTypes.number.isRequired,
          currency: PropTypes.string.isRequired,
          paymentPeriod: PropTypes.string.isRequired,
        }).isRequired),
      }).isRequired,
    ).isRequired,

  }).isRequired,
};

export default MarketCard;
