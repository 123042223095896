import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

import ChainInfoNodeItem from './ChainInfoNodeItem';
import ChainInfoRequirements from './ChainInfoRequirements';
import ChainInfoSocialLink from './ChainInfoSocialLink';

const ChainInfoNode = (props) => {
  const {
    cryptoUnit,
    nodeType,
    chainDescription,
  } = props;
  const {
    linksSocialMedia, benefit, hardware, stakingAmount,
  } = nodeType;
  const { t } = useTranslation();
  return (
    <Grid
      container
      spacing={8}
    >
      <Grid item xs={12}>
        <Typography variant="h6">{chainDescription}</Typography>
      </Grid>
      <Grid item xs={12}>
        <Grid
          container
          spacing={5}
        >
          <Grid item xs={12}>
            <ChainInfoNodeItem
              title={t('Key features')}
              content={<Typography variant="body1">{benefit}</Typography>}
            />
          </Grid>

          { (hardware && stakingAmount) ? (
            <>
              <Grid item xs={12}>
                <Divider />
              </Grid>
              <Grid item xs={12}>
                <ChainInfoNodeItem
                  title={t('Requirements')}
                  content={(
                    <ChainInfoRequirements
                      hardware={hardware}
                      stakingAmount={stakingAmount}
                      cryptoUnit={cryptoUnit}
                    />
                )}
                />
              </Grid>
            </>
          ) : null}
          {/* <Grid item xs={12}>
            <ChainInfoNodeItem
              title="Min units"
              content={<Typography variant="body1">{nodeType.minUnit}</Typography>}
            />
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid> */}
          {!isEmpty(linksSocialMedia) && (
            <>
              <Grid item xs={12}>
                <Divider />
              </Grid>
              <Grid item xs={12}>
                <ChainInfoNodeItem
                  title={t('Reference')}
                  content={<ChainInfoSocialLink socialLinks={linksSocialMedia} />}
                />
              </Grid>
            </>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

ChainInfoNode.propTypes = {
  cryptoUnit: PropTypes.string.isRequired,
  chainDescription: PropTypes.string.isRequired,
  /**
   * chain node types data
   */
  nodeType: PropTypes.shape({
    minUnit: PropTypes.number,
    linksSocialMedia: PropTypes.array,
    type: PropTypes.string,
    benefit: PropTypes.string,
    stakingAmount: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    hardware: PropTypes.shape({
      CPU: PropTypes.string,
      RAM: PropTypes.string,
      SSD: PropTypes.string,
    }),
  }).isRequired,
};
export default ChainInfoNode;
