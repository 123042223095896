import * as R from 'ramda';
import { combineReducers } from 'redux';
import { PURGE } from 'redux-persist';

import {
  createReducer,
  mergeStateAndPayload,
} from '../store_utils';

import adminNodeR from './node/reducers';
import orderListR from './order/index';
import * as types from './types';
import adminUserR from './user/reducers';

/**
 * Impersonate user reducer format
 *
 * @type       {import('./typedefs').userImpersonationIS}
 */
const userImpersonationIS = {
  isImpersonating: false,
  user: {
    email: '',
    userId: '',
    joined: '',
    modified: '',
    lastSignin: '',
    activated: false,
    role: '',
  },
};

const userImpersonationRUpdaters = {
  [types.IMPERSONATE_USER_SUCCESS]: mergeStateAndPayload,
  [types.RESET_IMPERSONATION]: R.always(userImpersonationIS),
  [PURGE]: R.always(userImpersonationIS),
};

/**
 * Impersonate user reducer
 *
 * @param      {import('./typedefs').userImpersonationIS}  userImpersonationIS
 * @param      {object}  userImpersonationRUpdaters
 * @return     {any} return impoersonate user reducer
 */
const userImpersonationR = createReducer(userImpersonationIS, userImpersonationRUpdaters);

/**
 * Should reload user reducer format
 *
 * @type       {import('./typedefs').reloadUserIS}
 */
const reloadUserIS = {
  shouldReloadUser: true,
};

const reloadUserRUpdaters = {
  [types.CLEAR_RELOAD_USER_SUCCESS]: mergeStateAndPayload,
  [PURGE]: R.always(reloadUserIS),
};

/**
 * Impersonate user reducer
 *
 * @param      {import('./typedefs').reloadUserIS}  reloadUserIS
 * @param      {object}  reloadUserRUpdaters
 * @return     {any} return impoersonate user reducer
 */
const reloadUserR = createReducer(reloadUserIS, reloadUserRUpdaters);

/**
 * Admin reducer
 *
 * @type       {any}
 */
const adminR = combineReducers({
  reloadUser: reloadUserR,
  userImpersonation: userImpersonationR,
  users: adminUserR,
  orders: orderListR,
  adminNode: adminNodeR,
});

export default adminR;
