import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { TextField } from 'mui-rff';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import React from 'react';
import { Form } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import {
  useParams,
} from 'react-router-dom';

import { getChainsData } from '../../Market/constants';
import { ButtonLoading } from '../../MaterialUiCustom/MaterialUiCustom';
import MarketProtocolDeployContentItem from '../MarketProtocolDeployContentItem';
import MarketProtocolDeployPayment from '../MarketProtocolDeployPayment';

const NemDeploy = ({
  paymentSelected,
  quantity,
  protocolName,
}) => {
  const { t } = useTranslation();

  const nodeType = 'NEM_UNUNIFI_VALIDATOR_NODE';
  const { protocolName: protocolNameParam } = useParams();
  const protocolNameToUse = protocolName || protocolNameParam;
  const protocolData = getChainsData(t)[protocolNameToUse];
  const {
    nodeTypes,
  } = protocolData;

  const {
    trialDays,
    pricing,
  } = R.find(
    R.propEq('type', nodeType),
    nodeTypes,
  );
  console.log({
    trialDays,
    pricing,
    nodeType,
    nodeTypes,
  });
  const onSubmit = () => {};
  const DeployForm = () => (
    <Form
      onSubmit={onSubmit}
      render={({
        handleSubmit,
        form,
      }) => (
        <form id="deployForm" onSubmit={handleSubmit}>

          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                name="validatorKey"
                type="text"
                label="validator key"
                variant="outlined"
              />
            </Grid>

          </Grid>
        </form>
      )}
    />
  );
  return (
    <Paper>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <MarketProtocolDeployContentItem
            title="Enter your UnUniFi validator key"
            content={(
              <DeployForm />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={12}>
          <MarketProtocolDeployContentItem
            title={t('Choose a plan')}
            content={(
              <MarketProtocolDeployPayment
                pricing={pricing}
                nodeType={nodeType}
                paymentSelected={paymentSelected}
                quantity={quantity}
                trialDays={trialDays}
              />
          )}
          />
        </Grid>
        <Grid item xs={12}>
          <ButtonLoading
            color="primary"
            variant="contained"
          >
            deploy
          </ButtonLoading>
        </Grid>
      </Grid>

    </Paper>

  );
};

NemDeploy.defaultProps = {
  protocolName: '',
};

NemDeploy.propTypes = {
  protocolName: PropTypes.string,
  paymentSelected: PropTypes.bool.isRequired,
  quantity: PropTypes.number.isRequired,
};

export default NemDeploy;
