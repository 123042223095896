export const styles = (theme) => ({
  paper: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    maxWidth: 800,
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: 'auto',
    marginBottom: 'auto',
  },
  centerComponent: {
    display: 'flex',
    marginLeft: '10%',
    marginRight: '10%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  form: {
    flexDirection: 'column',
  },
  title: {
    paddingBottom: theme.spacing(3),
  },
  description: {
    paddingBottom: theme.spacing(2),
  },
  divider: {
    ...theme.mixins.gutters(),
    margin: theme.spacing(2, 0, 3, 0),
    maxWidth: 512,
    marginLeft: 'auto',
    marginRight: 'auto',
  },
});
