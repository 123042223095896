export const searchBarStyle = (theme) => ({
  searchPaper: {
    width: 320,

    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
  searchInput: {
    width: '100%',
  },
});

export const nodeOwnerContentItemStyles = ((theme) => ({
  content: {
    padding: theme.spacing(4),
  },
  title: {
    fontWeight: 550,
  },
  buyNowButton: {
    fontSize: 12,
    fontWeight: 550,
    minWidth: 100,
  },
  description: {
    fontSize: 12,
    fontWeight: 550,
    whiteSpace: 'pre-line',
  },
  text: {
    fontSize: 12,
    fontWeight: 550,
  },
  price: {
    fontSize: 20,
    fontWeight: 550,
  },
  promotion: {
    position: 'absolute',
    height: 80,
    width: 80,
    borderRadius: '50%',
    backgroundColor: '#D3C454',
    margin: theme.spacing(2),
    zIndex: 5,
  },
  promotionText: {
    whiteSpace: 'pre-line',
    color: '#FFF',
    fontSize: 20,
    fontWeight: 500,
    margin: theme.spacing(1, 1),
  },
}));

export const nodeOwnerOverviewStyles = (theme) => {
  const h3Properties = {
    fontSize: theme.typography.h3.fontSize,
    fontWeight: theme.typography.h3.fontWeight,
  };
  return ({
    container: {
      minWidth: '100px',
    },
    dataWithCircleBg: {
      color: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
    },
    dataWithCircle: {
      strokeLinecap: 'round',
      color: theme.nodeStatus.active,
    },
    percent: {
      ...h3Properties,
    },
    totalEarning: {
      fontSize: theme.typography.h2.fontSize,
      fontWeight: theme.typography.h2.fontWeight,
    },
    overviewText: {
      ...h3Properties,
    },
  });
};
