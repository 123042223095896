import Box from '@material-ui/core/Box';
import Checkbox from '@material-ui/core/Checkbox';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Skeleton from '@material-ui/lab/Skeleton';
import whyDidYouRender from '@welldone-software/why-did-you-render';
import isNaN from 'lodash/isNaN';
import isNil from 'lodash/isNil';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';

import { useAddFunctionToRef } from '../../../hooks/hooks';
import useNonInitialEffect from '../../../hooks/useNonInitialEffect';
// store
import { deployConfigOperations } from '../../../store/deploy_config/index';
import { web3Selectors } from '../../../store/web3/index';

import ConnectToWalletCalculatorValidatorNum from './ConnectToWalletCalculatorValidatorNum';
import { connectToWalletCalculatorStyles } from './styles';

import RoundedButton from 'components/MaterialUiCustom/Button/RoundedButton';
import WalletAccount from 'components/WalletAccount/WalletAccount';

const { REACT_APP_ENV } = process.env;
if (REACT_APP_ENV !== 'prod') {
  whyDidYouRender(React);
}

const useStyles = makeStyles(connectToWalletCalculatorStyles);

const ConnectToWalletCalculator = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const {
    onClickDisconnect,
    account,
  } = props;
  const { t } = useTranslation();
  const getBalanceLoading = useSelector((state) => state.loadingR.getBalanceLoading);
  const { balance, done } = useSelector(web3Selectors.selectBalance(account));

  const [withdrawRuleChecked, setWithdrawRuleChecked] = useState(false);
  const [slashRiskChecked, setSlashRiskChecked] = useState(false);
  const [serviceTermChecked, setServiceTermChecked] = useState(false);

  const toggleRulesAgreed = useAddFunctionToRef((agreed) => {
    dispatch(deployConfigOperations.toggleDeployConfigMetadata({
      updatePath: ['rulesAgreed'],
      metadata: agreed,
    }));
  });

  useEffect(() => {
    const agreed = withdrawRuleChecked && slashRiskChecked && serviceTermChecked;
    toggleRulesAgreed(agreed);
  }, [withdrawRuleChecked, slashRiskChecked, serviceTermChecked, toggleRulesAgreed]);

  const termAndServiceAgreements = {
    en: (
      <Typography
        variant="body2"
      >
        {t("I've read and agreed to ")}
        {' '}
        <Link href="https://www.neukind.com/terms" target="_blank">
          {t('Terms of Service')}
        </Link>
      </Typography>
    ),
    ja: (
      <Typography
        variant="body2"
      >
        私は
        <Link href="https://www.neukind.com/terms" target="_blank">
          利用規約
        </Link>
        を読み、同意します
      </Typography>
    ),
  };
  const { lang } = useSelector((state) => state.langR.langSetting);

  const getTermAndServiceAgreements = R.curry(
    (termAndServiceAgreements, lang) => R.prop(lang, termAndServiceAgreements) || R.prop('en', termAndServiceAgreements),
  );
  const TermAndServiceAgreement = getTermAndServiceAgreements(termAndServiceAgreements, lang);

  const getBalanceContent = () => (getBalanceLoading
    ? (
      <Box
        display="flex"
        flexDirection="column"
      >
        <Box>
          <Skeleton variant="text" width={150} />
        </Box>
        <Box>
          <Skeleton variant="text" width={80} />
        </Box>
      </Box>
    )
    : (
      <Box
        display="flex"
        flexDirection="column"
      >
        <Box>
          <Typography variant="body2" color="textSecondary" style={{ fontWeight: 550 }}>
            {t('Your wallet balance')}
          </Typography>
        </Box>
        <Box>
          <Typography style={{ fontSize: 36, fontWeight: 600 }}>
            {`${!isNil(balance) ? balance : 0} ETH`}
          </Typography>
        </Box>
      </Box>
    ));

  useNonInitialEffect(() => {
    if (!getBalanceLoading && done) {
      if (isNil(balance) || isNaN(balance)) {
        toast.error(
          t('Sorry, we are unable to get your wallet balance. Please reload the browser and try again'),
          {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          },
        );
      }
    }
  }, [getBalanceLoading, balance, t, done]);

  const getRulesContent = () => (
    <Box className={classes.rules}>
      <Box display="flex" mt={2}>
        <Box>
          <Checkbox
            checked={withdrawRuleChecked}
            onChange={(event) => setWithdrawRuleChecked(event.target.checked)}
          />
        </Box>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="flex-start"
          mt={1.3}
        >
          <Typography
            variant="body2"
          >
            {t('I understand that I cannot withdraw my ETH2 funds until phase 1.5, and that its launch day is not announced yet')}
          </Typography>
        </Box>
      </Box>
      <Box display="flex">
        <Box>
          <Checkbox
            checked={slashRiskChecked}
            onChange={(event) => setSlashRiskChecked(event.target.checked)}
          />
        </Box>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="flex-start"
          mt={1.3}
        >
          <Typography
            variant="body2"
          >
            {t('I understand that while staking, there will always be a risk of slashing')}
          </Typography>
        </Box>
      </Box>
      <Box display="flex">
        <Box>
          <Checkbox
            checked={serviceTermChecked}
            onChange={(event) => setServiceTermChecked(event.target.checked)}
          />
        </Box>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="flex-start"
          mt={1.3}
        >
          {TermAndServiceAgreement}
        </Box>
      </Box>
    </Box>
  );

  return (
    <Box className={classes.connectToWalletCalculator}>
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        className={classes.quantityForm}
      >
        <Grid item xs={12}>
          <Box
            display="flex"
            flexDirection="column"
          >
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Box>
                <WalletAccount
                  t={t}
                  account={account}
                />
              </Box>
              <Box>
                <RoundedButton variant="text" onClick={() => onClickDisconnect()}>
                  <Typography color="textSecondary" style={{ fontWeight: 550 }}>
                    {t('disconnect')}
                  </Typography>
                </RoundedButton>
              </Box>
            </Box>
            {getBalanceContent()}

          </Box>
        </Grid>

        <Grid item xs={12}>
          <Box mt={3} mb={3}>
            <Divider />
          </Box>
        </Grid>

        <Grid item xs={12}>
          <Grid
            container
            justifyContent="center"
            alignItems="center"
          >
            <Grid item xs={12}>
              <Typography variant="body1" style={{ fontWeight: 550 }}>
                {t('How many validator would you like to run?')}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <ConnectToWalletCalculatorValidatorNum />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {getRulesContent()}
    </Box>
  );
};

ConnectToWalletCalculator.propTypes = {
  onClickDisconnect: PropTypes.func.isRequired,
  account: PropTypes.string,
};
ConnectToWalletCalculator.defaultProps = {
  account: undefined,
};

ConnectToWalletCalculator.whyDidYouRender = true;
export default React.memo(ConnectToWalletCalculator);
