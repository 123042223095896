import Grid from '@material-ui/core/Grid';
import { TextField } from 'mui-rff';
import PropTypes from 'prop-types';
import React from 'react';

const WalletNewAddress = ({
  t,
}) => (
  <Grid container spacing={2}>
    <Grid item xs={12}>
      <TextField
        id="name-input-textfield"
        variant="outlined"
        name="recipient"
        type="text"
        label={t('Recipient')}
      />
    </Grid>
  </Grid>

);
WalletNewAddress.propTypes = {
  t: PropTypes.func.isRequired,
};
export default WalletNewAddress;
