import BigNumber from 'bignumber.js';

const { REACT_APP_CONTRACT_ADDRESS, REACT_APP_PRICE_PER_VALIDATOR } = process.env;

const pricePerValidator = new BigNumber(REACT_APP_PRICE_PER_VALIDATOR);

export const txValue = pricePerValidator.multipliedBy(1e18).toNumber();

export const contractAddress = REACT_APP_CONTRACT_ADDRESS;

export const TransactionStatus = Object.freeze({
  READY: 'READY',
  PENDING: 'PENDING',
  STARTED: 'STARTED',
  SUCCEEDED: 'SUCCEEDED',
  FAILED: 'FAILED',
  REJECTED: 'REJECTED',
});

export const contractAbiMainnet = [
  {
    name: 'DepositEvent',
    inputs: [
      { type: 'bytes', name: 'pubkey', indexed: false },
      { type: 'bytes', name: 'withdrawal_credentials', indexed: false },
      { type: 'bytes', name: 'amount', indexed: false },
      { type: 'bytes', name: 'signature', indexed: false },
      { type: 'bytes', name: 'index', indexed: false },
    ],
    anonymous: false,
    type: 'event',
  },
  {
    outputs: [],
    inputs: [],
    constant: false,
    payable: false,
    type: 'constructor',
  },
  {
    name: 'get_deposit_root',
    outputs: [{ type: 'bytes32', name: 'out' }],
    inputs: [],
    constant: true,
    payable: false,
    type: 'function',
  },
  {
    name: 'get_deposit_count',
    outputs: [{ type: 'bytes', name: 'out' }],
    inputs: [],
    constant: true,
    payable: false,
    type: 'function',
  },
  {
    name: 'deposit',
    outputs: [],
    inputs: [
      { type: 'bytes', name: 'pubkey' },
      { type: 'bytes', name: 'withdrawal_credentials' },
      { type: 'bytes', name: 'signature' },
      { type: 'bytes32', name: 'deposit_data_root' },
    ],
    constant: false,
    payable: true,
    type: 'function',
  },
  {
    name: 'drain',
    outputs: [],
    inputs: [],
    constant: false,
    payable: false,
    type: 'function',
  },
];

export const contractAbiGoerliPyrmont = [
  {
    inputs: [
      {
        internalType: 'bytes',
        name: 'pubkey',
        type: 'bytes',
      },
      {
        internalType: 'bytes',
        name: 'withdrawal_credentials',
        type: 'bytes',
      },
      {
        internalType: 'bytes',
        name: 'signature',
        type: 'bytes',
      },
      {
        internalType: 'bytes32',
        name: 'deposit_data_root',
        type: 'bytes32',
      },
    ],
    name: 'deposit',
    outputs: [],
    stateMutability: 'payable',
    type: 'function',
  },
  {
    inputs: [],
    stateMutability: 'nonpayable',
    type: 'constructor',
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: 'bytes',
        name: 'pubkey',
        type: 'bytes',
      },
      {
        indexed: false,
        internalType: 'bytes',
        name: 'withdrawal_credentials',
        type: 'bytes',
      },
      {
        indexed: false,
        internalType: 'bytes',
        name: 'amount',
        type: 'bytes',
      },
      {
        indexed: false,
        internalType: 'bytes',
        name: 'signature',
        type: 'bytes',
      },
      {
        indexed: false,
        internalType: 'bytes',
        name: 'index',
        type: 'bytes',
      },
    ],
    name: 'DepositEvent',
    type: 'event',
  },
  {
    inputs: [],
    name: 'get_deposit_count',
    outputs: [
      {
        internalType: 'bytes',
        name: '',
        type: 'bytes',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'get_deposit_root',
    outputs: [
      {
        internalType: 'bytes32',
        name: '',
        type: 'bytes32',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'bytes4',
        name: 'interfaceId',
        type: 'bytes4',
      },
    ],
    name: 'supportsInterface',
    outputs: [
      {
        internalType: 'bool',
        name: '',
        type: 'bool',
      },
    ],
    stateMutability: 'pure',
    type: 'function',
  },
];

const constAbiGoerliMedalla = [
  {
    inputs: [],
    stateMutability: 'nonpayable',
    type: 'constructor',
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: 'bytes',
        name: 'pubkey',
        type: 'bytes',
      },
      {
        indexed: false,
        internalType: 'bytes',
        name: 'withdrawal_credentials',
        type: 'bytes',
      },
      {
        indexed: false,
        internalType: 'bytes',
        name: 'amount',
        type: 'bytes',
      },
      {
        indexed: false,
        internalType: 'bytes',
        name: 'signature',
        type: 'bytes',
      },
      {
        indexed: false,
        internalType: 'bytes',
        name: 'index',
        type: 'bytes',
      },
    ],
    name: 'DepositEvent',
    type: 'event',
  },
  {
    inputs: [
      {
        internalType: 'bytes',
        name: 'pubkey',
        type: 'bytes',
      },
      {
        internalType: 'bytes',
        name: 'withdrawal_credentials',
        type: 'bytes',
      },
      {
        internalType: 'bytes',
        name: 'signature',
        type: 'bytes',
      },
      {
        internalType: 'bytes32',
        name: 'deposit_data_root',
        type: 'bytes32',
      },
    ],
    name: 'deposit',
    outputs: [],
    stateMutability: 'payable',
    type: 'function',
  },
  {
    inputs: [],
    name: 'get_deposit_count',
    outputs: [
      {
        internalType: 'bytes',
        name: '',
        type: 'bytes',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'get_deposit_root',
    outputs: [
      {
        internalType: 'bytes32',
        name: '',
        type: 'bytes32',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'bytes4',
        name: 'interfaceId',
        type: 'bytes4',
      },
    ],
    name: 'supportsInterface',
    outputs: [
      {
        internalType: 'bool',
        name: '',
        type: 'bool',
      },
    ],
    stateMutability: 'pure',
    type: 'function',
  },
];

const contractAbiGoerli = process.REACT_APP_USE_MEDALLA === '0x07b39F4fDE4A38bACe212b546dAc87C58DfE3fDC'
  ? constAbiGoerliMedalla
  : contractAbiGoerliPyrmont;

export const contractAbis = {
  1: contractAbiMainnet,
  5: contractAbiGoerli,
};
