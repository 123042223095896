import Box from '@material-ui/core/Box';
import PropTypes from 'prop-types';
import React from 'react';

const BoxWrapper = ({
  Icon, Text, Action, maxWidth,
}) => (
  <Box
    display="flex"
    justifyContent="flex-end"
    flexDirection="row"
    alignItems="center"
    width={maxWidth ? '100%' : null}
  >
    <Box>
      {Icon}
    </Box>
    <Box ml={1} width="100%">
      {Text}
    </Box>
    {Action && (
    <Box ml={1} width="100%">
      {Action}
    </Box>
    )}
  </Box>
);
BoxWrapper.propTypes = {
  Icon: PropTypes.node.isRequired,
  Text: PropTypes.node.isRequired,
  Action: PropTypes.node,
  maxWidth: PropTypes.bool,
};
BoxWrapper.defaultProps = {
  maxWidth: false,
  Action: undefined,
};
export default BoxWrapper;
