export const searchBarStyle = (theme) => ({
  searchBarRoot: {
    borderWidth: 0,
    backgroundColor: '#F4F5F9',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  searchPaper: {
    justifyContent: 'space-between',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  searchInput: {
    width: '100%',
  },
  searchBarIcon: {
    color: '#515A6A',
  },
});
