import { normalize, schema } from 'normalizr';
import * as R from 'ramda';

export const normalizeOrderListData = R.curry((orderListData) => {
  const userSchema = new schema.Entity('users', {}, { idAttribute: 'userId' });
  const stripeSchema = new schema.Entity('stripe', {}, { idAttribute: 'stripeInvoiceId' });
  const paymentSchema = new schema.Entity('payments', { stripe: stripeSchema }, { idAttribute: 'paymentId' });
  const contractSchema = new schema.Entity('contracts', {}, { idAttribute: 'contractId' });
  const timelineSchema = new schema.Entity('timelines', {}, { idAttribute: 'nodeProviderOrderTimelineId' });
  const earningSchema = new schema.Entity('earnings', {}, { idAttribute: 'nodeProviderEarningId' });
  const deviceSchema = new schema.Entity(
    'devices',
    { contract: contractSchema, earnings: [earningSchema] },
    { idAttribute: 'deviceId' },
  );
  const earningHistorySchema = new schema.Entity('earningHistories', {}, { idAttribute: 'orderId' });
  const orderSchema = new schema.Entity(
    'orders',
    {
      user: userSchema,
      payments: [paymentSchema],
      contract: contractSchema,
      devices: [deviceSchema],
      timeline: [timelineSchema],
      earningHistory: [earningHistorySchema],
    },
    { idAttribute: 'orderId' },
  );
  const orderListSchema = { items: [orderSchema] };
  return normalize(orderListData, orderListSchema);
});

const mergeByProp = R.curry((propName, objectA, objectB) => R.merge(
  R.prop(propName, objectA), R.prop(propName, objectB),
));

export const mergeNormalizedOrderListData = R.curry((ordersA, ordersB) => {
  const { entities: entitiesA, result: resultA } = R.prop('data', ordersA);
  const { entities: entitiesB, result: resultB } = R.prop('data', ordersB);

  return {
    data: {
      entities: {
        orders: mergeByProp('orders', entitiesA, entitiesB),
        users: mergeByProp('users', entitiesA, entitiesB),
        payments: mergeByProp('payments', entitiesA, entitiesB),
        contracts: mergeByProp('contracts', entitiesA, entitiesB),
        devices: mergeByProp('devices', entitiesA, entitiesB),
        timelines: mergeByProp('timelines', entitiesA, entitiesB),
      },
      result: {
        items: R.pipe(
          R.prop('items'),
          R.append(R.prop('items', resultA)),
          R.flatten,
        )(resultB),
      },
    },
  };
});
