import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import HelpOutlineOutlinedIcon from '@material-ui/icons/HelpOutlineOutlined';
import moment from 'moment';
import * as R from 'ramda';
import React from 'react';
import { CSVLink } from 'react-csv';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import ReactTableV7 from '../../Tables/ReactTableV7';
import CustomTooltip from '../../Tooltip/CustomTooltip';
import InvestmentEarningDashboardItem from '../InvestmentNodeDetailsPageItem';

// others
import { eth2ValidatorEarningHistoryColumns } from './columns';
import { eth2ValidatorEarningHistoryStyles } from './styles';

const useStyles = makeStyles(eth2ValidatorEarningHistoryStyles);
const { REACT_APP_DISABLE_ETH2_DAILY_STATISTIC } = process.env;
const ETH2ValidatorEarningHistory = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const {
    getIntervalEarningLoading,
  } = useSelector((state) => state.loadingR);
  const intervalEarning = useSelector((state) => state.nodeR.nodesEarning.eth2.interval);

  const columnsToUse = eth2ValidatorEarningHistoryColumns(
    t,
  );
  const maintenanceDailyStatistic = REACT_APP_DISABLE_ETH2_DAILY_STATISTIC === 'ON';

  const formatedIntervalEarning = R.map((eachData) => ({
    ...eachData,
    date: moment(eachData.startTime).toLocaleString(),
    currency: 'JPY',
  }))(intervalEarning);

  const csvHeaders = [
    { label: 'Date', key: 'date' },
    { label: 'Earning (ETH)', key: 'totalCoin' },
    { label: 'Fiat Equivalent', key: 'total' },
    { label: 'Exchange Rate', key: 'currencyPrice' },
    { label: 'Currency', key: 'currency' },
  ];

  return (
    <InvestmentEarningDashboardItem
      titlePadding="21px 32px 16px 32px"
      title={(
        <Box display="flex" alignItems="center">
          <Box>
            {t('Daily Statistics')}
          </Box>
          <Box ml={0.5} mr={2}>
            <CustomTooltip
              title={t(maintenanceDailyStatistic ? 'Maintenance' : 'Data will be displayed at least one day after your nodes become active')}
              content={(
                <HelpOutlineOutlinedIcon
                  color="disabled"
                  style={{ fontSize: 20 }}
                />
              )}
            />
          </Box>
          <Box>
            <Button disabled={getIntervalEarningLoading} className={classes.downloadButton}>
              <CSVLink
                data={maintenanceDailyStatistic ? [] : formatedIntervalEarning}
                headers={csvHeaders}
                filename="eth2_earning_history.csv"
                style={{ textDecoration: 'none' }}
              >
                <Typography color="primary" className={classes.downloadButtonText}>
                  Download CSV
                </Typography>
              </CSVLink>
            </Button>
          </Box>
        </Box>
)}
      content={(
        <ReactTableV7
          data={maintenanceDailyStatistic ? [] : intervalEarning}
          columns={columnsToUse}
          customPageSize={3}
          customPageSizeList={[3, 5, 10, 20, 50]}
          loading={getIntervalEarningLoading}
        />
)}
    />
  );
};

export default ETH2ValidatorEarningHistory;
