import {
  pipeAwait,
} from 'jsutils';
import * as R from 'ramda';

import {
  graphqlNormalize,
} from '../../modules/apollo/apollo';
import {
  returnAction,
} from '../store_utils';

import {
  graphqlSendContactUsEmail,
} from './graphql/services';
import * as types from './types';

export const openContactForm = (metadata = {}) => ({
  type: types.OPEN_CONTACT_FORM,
  payload: {
    open: true,
    metadata,
  },
});

export const closeContactForm = () => ({
  type: types.CLOSE_CONTACT_FORM,
  payload: {
    open: false,
    metadata: {},
  },
});

export const sendContactUsEmail = R.curry(
  async (
    name,
    company,
    email,
    message,
    metadata = {},
  ) => pipeAwait(
    graphqlSendContactUsEmail,
    graphqlNormalize('sendContactUsEmail'),
    returnAction(types.SEND_USER_CONTACT_US_EMAIL_SUCCESS),
  )({
    sendContactUsEmailInput: {
      name,
      company: company || '',
      email,
      message,
      metadata,
    },
  }),
);
