export const styles = (theme) => ({
  tooltipTitle: {
    fontWeight: 550,
    fontSize: 13,
  },
  tooltipItemTitle: {
    fontWeight: 550,
    fontSize: 13,
  },
  tooltipItemContent: {
    fontSize: 13,
  },
  customTooltip: {
    backgroundColor: '#f9f9f9',
    color: 'rgba(0, 0, 0, 0.87)',
    minWidth: 300,
    boxShadow: theme.shadows[3],
  },
  customArrow: {
    color: '#f9f9f9',
  },
  date: {
    minWidth: 100,
    padding: 0,
    textDecoration: 'underline',
    fontWeight: 400,
    cursor: 'pointer',
  },
});
