export const connectToWalletCalculatorStyles = (theme) => ({
  connectToWalletCalculator: {
    padding: theme.spacing(1, 0, 7, 0),
  },
  quantityForm: {
    ...theme.border.default,
    padding: theme.spacing(1, 4),
  },
  rules: {
    padding: theme.spacing(2, 2.5),
  },
});
