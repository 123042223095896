// query
import {
  createQuery,
} from '../../../modules/apollo/apollo';

import { getPaymentsQuery, getSubscriptionsQuery } from './queries';

// apollo

export const graphqlGetPayments = createQuery(getPaymentsQuery);
export const graphqlGetSubsctiptions = createQuery(getSubscriptionsQuery);
