//  mutations
import {
  createQuery,
  createMutation,
} from '../../../../modules/apollo/apollo';

import {
  sendMonthlyReportEmailMutation,
} from './mutations';
// queries
import {
  getNodesQuery,
  getAdminTotalReportQuery,
} from './queries';
// apollo

export const graphqlGetNodes = createQuery(getNodesQuery);
export const graphqlGetAdminTotalReport = createQuery(getAdminTotalReportQuery);

export const graphqlUpdateUserNode = createMutation(sendMonthlyReportEmailMutation);
