import MenuItem from '@material-ui/core/MenuItem';
import { TextField } from 'mui-rff';
import PropTypes from 'prop-types';
import React from 'react';

import { accountsPropType } from './propTypes';

const WalletSelectAccounts = ({
  accounts,
  t,
}) => (
  <TextField
    id="recipient-select"
    select
    label={t('Recipient')}
    name="recipient"
    variant="outlined"
    helperText={t('Select an address')}
  >
    {accounts.map((account) => {
      const { address, meta } = account;
      const name = meta?.name;
      return (
        <MenuItem key={address} value={address}>
          {name || address}
        </MenuItem>
      );
    })}
  </TextField>
);

WalletSelectAccounts.propTypes = {
  t: PropTypes.func.isRequired,
  accounts: accountsPropType,
};

WalletSelectAccounts.defaultProps = {
  accounts: [],
};

export default WalletSelectAccounts;
