import Box from '@material-ui/core/Box';
import Dialog from '@material-ui/core/Dialog';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import whyDidYouRender from '@welldone-software/why-did-you-render';
import isEmpty from 'lodash/fp/isEmpty';
import * as R from 'ramda';
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';

import { useAddFunctionToRef } from '../../../hooks/hooks';
import { adminUserOperations } from '../../../store/admin/user/index';
import { CREATE_USERS_SUCCESS, SEND_FIRST_SIGNIN_EMAIL_SUCCESS } from '../../../store/admin/user/types';
import { userOperations } from '../../../store/user/index';
import FailPage from '../../FailPage/FailPage';
import Alert from '../../MaterialUiCustom/Alert/Alert';
import { RoundedButton } from '../../MaterialUiCustom/MaterialUiCustom';
import SuccessPage from '../../SuccessPage/SuccessPage';
import CustomTabs from '../../Tabs/CustomTabs';
import { adminDashboardStyles } from '../styles';

import CreateUsers from './CreateUsers';
import CreateUsersSuccess from './CreateUsersSuccess';
import FirstSignins from './FirstSignins';
import UserList from './UserList';

// others
import { formatCreateUsersInput } from './services';

// store

if (process.env.REACT_APP_ENV !== 'prod') {
  whyDidYouRender(React);
}

const useStyles = makeStyles(adminDashboardStyles);

const AdminDashboard = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const createdUsers = useSelector((state) => state.adminR.users.createdUsers.data);
  const firstSignins = useSelector((state) => state.userR.firstSignins.items);

  const [isNewUserCreated, setIsNewUserCreated] = useState(false);

  const [openCreateUsers, setOpenCreateUsers] = useState(false);
  const handleCreateUsersDialogClose = () => {
    setOpenCreateUsers(false);
  };
  const handleCreateUsersDialogOpen = () => {
    setOpenCreateUsers(true);
  };

  const [openFirstSignins, setOpenFirstSignins] = useState(false);
  const handleFirstSigninsDialogClose = () => {
    setOpenFirstSignins(false);
  };
  const handleFirstSigninsDialogOpen = () => {
    setOpenFirstSignins(true);
  };

  const [statusDialog, setStatusDialog] = useState('');
  const [statusDialogName, setStatusDialogName] = useState('');
  const handleStatusDialogClose = () => {
    setStatusDialog('');
    setStatusDialogName('');
  };
  const handleStatusDialogOpen = (status, name) => {
    setStatusDialog(status);
    setStatusDialogName(name);
  };

  const onCreateUsers = R.curry(async (data, clearData) => {
    const formatedData = formatCreateUsersInput(R.values(data));
    const { type } = await dispatch(adminUserOperations.createUsers(formatedData));

    if (type === CREATE_USERS_SUCCESS) {
      handleStatusDialogOpen('success', 'createUserSuccess');
      setIsNewUserCreated(true);
      clearData();
      handleCreateUsersDialogClose();
    } else {
      handleStatusDialogOpen('fail', 'createUserFail');
    }
  });

  const onSendFirstSigninEmails = R.curry(async (firstSignins) => {
    const { type } = await dispatch(adminUserOperations.sendFirstSigninEmails(firstSignins));

    if (type === SEND_FIRST_SIGNIN_EMAIL_SUCCESS) {
      handleStatusDialogOpen('success', 'sendFirstSigninSuccess');
      handleFirstSigninsDialogClose();
    } else {
      handleStatusDialogOpen('fail', 'sendFirstSigninFail');
    }
  });

  const getFirstSignins = useAddFunctionToRef(async () => {
    await dispatch(userOperations.getFirstSignins([{ signedIn: false }]));
  });
  useEffect(() => {
    getFirstSignins();
  }, [getFirstSignins]);

  const statusDialogTexts = {
    createUserSuccess: {
      primaryText: t('THANK YOU!'),
      message: (
        <Box>
          <Typography align="center">
            {t('Create users successfully. Here is the details.')}
          </Typography>
          <Paper className={classes.successDialogDetails}>
            <CreateUsersSuccess data={createdUsers} />
          </Paper>
        </Box>
      ),
    },
    createUserFail: {
      primaryText: t('SORRY!'),
      message: t('Create users failed'),
    },

    sendFirstSigninSuccess: {
      primaryText: t('THANK YOU!'),
      message: t('First signin emails have been sent to users.'),
    },
    sendFirstSigninFail: {
      primaryText: t('SORRY!'),
      message: t('Send first signin email failed'),
    },
  };

  const statusDialogText = R.prop(statusDialogName, statusDialogTexts) || {
    primaryText: '',
    message: '',
  };

  const tabs = [
    {
      label: t('Users'),
      content: <UserList onReload={() => setIsNewUserCreated(false)} />,
    },
  ];

  return (
    <>
      {isNewUserCreated && (
      <Alert
        severity="warning"
        action={(
          <RoundedButton
            variant="contained"
            color="secondary"
            size="small"
            onClick={() => window.location.reload()}
          >
            {t('reload')}
          </RoundedButton>
          )}
      >
        <Typography variant="body2" color="inherit">
          {t('User list data have been updated. Please reload the page.')}
        </Typography>
      </Alert>
      )}
      <CustomTabs
        tabs={tabs}
        extraComponent={(
          <Box display="flex" className={classes.headerButtonContainer}>
            <Box pr={1}>
              <RoundedButton
                variant="contained"
                color="primary"
                className={classes.headerButton}
                onClick={handleCreateUsersDialogOpen}
              >
                {t('create users')}
              </RoundedButton>
            </Box>
            <Box>
              <RoundedButton
                variant="contained"
                color="secondary"
                className={classes.headerButton}
                disabled={isEmpty(firstSignins)}
                onClick={handleFirstSigninsDialogOpen}
              >
                {t('first signin')}
              </RoundedButton>
            </Box>
          </Box>
        )}
      />
      <CreateUsers
        open={openCreateUsers}
        onClose={handleCreateUsersDialogClose}
        onCreateUsers={onCreateUsers}
      />
      <FirstSignins
        data={firstSignins}
        open={openFirstSignins}
        onClose={handleFirstSigninsDialogClose}
        onSendFirstSigninEmails={onSendFirstSigninEmails}
      />
      <Dialog open={statusDialog === 'success'} onClose={handleStatusDialogClose} fullWidth maxWidth="md">
        <Box className={classes.statusDialog}>
          <SuccessPage
            onCancel={handleStatusDialogClose}
            primaryText={R.prop('primaryText', statusDialogText)}
            message={R.prop('message', statusDialogText)}
            btnText={t('close')}
          />
        </Box>
      </Dialog>
      <Dialog open={statusDialog === 'fail'} onClose={handleStatusDialogClose} fullWidth maxWidth="md">
        <Box className={classes.statusDialog}>
          <FailPage
            onCancel={handleStatusDialogClose}
            primaryText={R.prop('primaryText', statusDialogText)}
            message={R.prop('message', statusDialogText)}
            btnText={t('close')}
          />
        </Box>
      </Dialog>
    </>
  );
};

AdminDashboard.whyDidYouRender = true;
export default React.memo(AdminDashboard);
