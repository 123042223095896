import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import ArrowDropDownOutlinedIcon from '@material-ui/icons/ArrowDropDownOutlined';
import ArrowDropUpOutlinedIcon from '@material-ui/icons/ArrowDropUpOutlined';
import { sumFromList } from 'jsutils';
import startCase from 'lodash/fp/startCase';
import toLower from 'lodash/fp/toLower';
import toNumber from 'lodash/toNumber';
import * as R from 'ramda';
import React from 'react';

import LocalDate from '../../LocalDate/LocalDate';

// others
import { convertToCryptoNumber } from '../../utils/utils';
import { timelineStepNameMapIndex } from '../constants';

export const miningDevicesColumns = (
  t,
  convertToPHA,
  // openStatusInfoIndex,
  // handleStatusInfoOpen,
  // handleStatusInfoClose,
) => [
  {
    Header: t('ID'),
    accessor: 'deviceId',
    Cell: (data) => {
      const { deviceId } = data.row.original;
      const deviceIdToUse = R.pipe(
        R.split('-'),
        R.last,
      )(deviceId);
      return (
        <Grid container direction="row" alignItems="center">
          {deviceIdToUse}
        </Grid>
      );
    },
  },
  {
    Header: t('Active Since'),
    accessor: 'dateActive',
    Cell: (data) => {
      const { dateActive } = data.row.original;
      return (
        <Grid container direction="row" alignItems="center">
          {dateActive ? (
            <LocalDate
              date={dateActive}
            />
          ) : '-'}
        </Grid>
      );
    },
  },
  // {
  //   Header: t('Score'),
  //   accessor: 'score',
  //   Cell: () => (
  //     <Grid container direction="row" alignItems="center">
  //       -
  //     </Grid>
  //   ),
  // },
  // {
  //   Header: t('Earning (PHA)'),
  //   accessor: 'earning',
  //   Cell: (data) => {
  //     const { earnings } = data.row.original;
  //     const totalEarning = R.pipe(
  //       R.map(
  //         R.pipe(
  //           R.prop('earning'),
  //           toNumber,
  //         ),
  //       ),
  //       R.sum,
  //     )(earnings);
  //     return (
  //       <Grid container direction="row" alignItems="center">
  //         {`${convertToPHA(totalEarning)} PHA`}
  //       </Grid>
  //     );
  //   },
  // },
  {
    Header: t('Miner Status'),
    accessor: 'deviceStatus',
    Cell: (data) => {
      const { deviceStatus } = data.row.original;
      return (
        <>
          <Box
            display="flex"
            alignItems="center"
            flexDirection="row"
          >
            <Box>
              <Typography variant="body2" align="center">
                {deviceStatus}
              </Typography>
            </Box>
          </Box>
        </>
      );
    },
  },
];

export const miningStatisticsColumns = (
  t,

) => [
  {
    Header: t('Date'),
    id: 'endTime',

    Cell: (data) => {
      const { endTime } = data.row.original;

      return (
        <Grid container direction="row" alignItems="center">
          <LocalDate
            date={endTime}
          />
        </Grid>
      );
    },
  },
  {
    Header: t('Earning'),
    accessor: 'totalCoin',
    Cell: (data) => {
      const { totalCoin } = data.row.original;
      const prefix = totalCoin >= 0 ? '+' : '';
      return (
        <Grid container direction="row" alignItems="center">
          <Typography style={{ color: '#85B501' }}>
            {`${prefix}${convertToCryptoNumber(totalCoin)} PHA`}
          </Typography>
        </Grid>
      );
    },
  },
  {
    Header: t('Fiat Equivalent'),
    accessor: 'total',
    Cell: (data) => {
      const { total } = data.row.original;
      return (
        <Grid container direction="row" alignItems="center">
          {toNumber(total).toLocaleString('en-US', {
            style: 'currency',
            currency: 'JPY',
          })}
        </Grid>
      );
    },
  },
  {
    Header: t('Exchange Rate'),
    accessor: 'currencyPrice',
    Cell: (data) => {
      const { currencyPrice } = data.row.original;
      return (
        <Grid container direction="row" alignItems="center">
          {`(PHA = ${toNumber(currencyPrice).toLocaleString('en-US', {
            style: 'currency',
            currency: 'JPY',
            minimumFractionDigits: 3,
          })})`}
        </Grid>
      );
    },
  },
];

export const miningOrderHistoryColumns = (
  t,
) => [
  {
    Header: t('Order ID'),
    accessor: 'orderId',
    Cell: (data) => {
      const { orderId } = data.row.original;
      const orderIdToUse = R.pipe(
        R.split('-'),
        R.last,
      )(orderId);
      return (
        <Grid container direction="row" alignItems="center">
          {orderIdToUse}
        </Grid>
      );
    },
  },
  {
    Header: t('Item'),
    accessor: 'Item',
    Cell: (data) => {
      const { quantity } = data.row.original;
      return (
        <Grid container direction="row" alignItems="center">
          {`${quantity} Mining machine(s)`}
        </Grid>
      );
    },
  },
  {
    Header: t('Total amount'),
    accessor: 'totalAmount',
    Cell: (data) => {
      const { payments } = data.row.original;
      const amount = sumFromList('amount', payments);
      return (
        <Grid container direction="row" alignItems="center">
          {amount ? amount.toLocaleString('en-US', {
            style: 'currency',
            currency: 'JPY',
          }) : '-'}
        </Grid>
      );
    },
  },
  {
    Header: t('Order Status'),
    accessor: 'Order Status',
    Cell: (data) => {
      const { timeline } = data.row.original;

      const timeLineToUse = timeline ? R.sortWith(
        [R.ascend((item) => timelineStepNameMapIndex[item.stepName])],
        timeline,
      ) : [];
      const lastCompletedStepIndex = R.findLastIndex(R.propEq('stepStatus', 'COMPLETE'))(timeLineToUse);
      const currentStepIndex = lastCompletedStepIndex === timeLineToUse.length - 1 ? lastCompletedStepIndex : lastCompletedStepIndex + 1;
      return (
        <Grid container direction="row" alignItems="center">
          {
            currentStepIndex >= 0 ? R.pipe(
              R.view(R.lensPath([currentStepIndex, 'stepName'])),
              toLower,
              startCase,
            )(timeLineToUse)
              : '-'
          }
        </Grid>
      );
    },
  },
  {
    id: 'expander',
    sortable: false,
    Cell: (data) => {
      const { row } = data;
      const { timeline } = data.row.original;
      return timeline && (
      <Grid
        container
        direction="row"
        alignItems="center"
        alignContent="center"
        {...row.getToggleRowExpandedProps()}
      >
        <Box display="flex">
          <Box mt={0.5}>
            <Typography color="primary">
              {t('Details')}
            </Typography>
          </Box>
          <Box mt={0.5}>
            {!row.isExpanded
              ? <ArrowDropDownOutlinedIcon color="primary" />
              : <ArrowDropUpOutlinedIcon color="primary" />}
          </Box>
        </Box>
      </Grid>
      );
    },
  },
];
