import * as R from 'ramda';
import { combineReducers } from 'redux';
import { PURGE } from 'redux-persist';

import { infinite } from '../../../modules/appConstants';
import {
  createReducer,
  mergeStateAndPayload,
} from '../../store_utils';

import { formatCreateUserPayload } from './services';
import * as types from './types';

export const userListIS = {
  '1-10': [],
  '1-all': [], // to store all users
};
const userListRUpdaters = {
  [types.GET_USERS_SUCCESS]: (state, payload) => (
    R.pipe(
      (payload) => {
        const pageIndex = R.view(R.lensPath(['meta', 'currentPage']), payload);
        const pageSize = R.view(R.lensPath(['meta', 'itemsPerPage']), payload);
        const pageSizeToUse = pageSize === infinite ? 'all' : pageSize;
        const userList = R.view(R.lensProp('items'), payload);
        return {
          [`${pageIndex}-${pageSizeToUse}`]: userList,
        };
      },
      mergeStateAndPayload(state),
    )(payload)
  ),
  [PURGE]: R.always(userListIS),
};

const userListR = createReducer(userListIS, userListRUpdaters);

const createdUsersIS = {
  data: [],
};

const createdUsersRUpdaters = {
  [types.CREATE_USERS_SUCCESS]: (state, payload) => (
    R.pipe(
      formatCreateUserPayload,
      mergeStateAndPayload(state),
    )(payload)
  ),
  [PURGE]: R.always(createdUsersIS),
};

const createdUsersR = createReducer(createdUsersIS, createdUsersRUpdaters);

const adminUserR = combineReducers({
  userList: userListR,
  createdUsers: createdUsersR,
});

export default adminUserR;
