import SvgIcon from '@material-ui/core/SvgIcon';
import * as React from 'react';

const PaymentIconSvg = (props) => {
  const { ...muiSvgIconProps } = props;
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      width={21}
      height={18}
      viewBox="0 0 21 18"
      {...muiSvgIconProps}
    >
      <g data-name="Group 125" transform="translate(0 .282)">
        <g data-name="Group 117" stroke="currentColor" strokeWidth={1.5}>
          <g
            data-name="Rectangle 1571"
            fill="none"
            transform="translate(0 -.282)"
          >
            <rect width={21} height={18} rx={2} stroke="none" />
            <rect x={0.75} y={0.75} width={19.5} height={16.5} rx={1.25} />
          </g>
          <g data-name="Rectangle 1572">
            <path stroke="none" d="M0 5.272h20.443v2H0z" />
            <path fill="none" d="M.75 6.022h18.943v.5H.75z" />
          </g>
        </g>
        <rect
          data-name="Rectangle 1643"
          width={3}
          height={1.5}
          rx={0.3}
          transform="translate(15 12.504)"
        />
      </g>
    </SvgIcon>
  );
};

PaymentIconSvg.defaultProps = {
};

PaymentIconSvg.propTypes = {
};

export default PaymentIconSvg;
