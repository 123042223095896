import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import Switch from '@material-ui/core/Switch';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import AutorenewOutlined from '@material-ui/icons/AutorenewOutlined';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import whyDidYouRender from '@welldone-software/why-did-you-render';
import { sumFromList } from 'jsutils';
import moment from 'moment';
import * as R from 'ramda';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import isEmail from 'validator/lib/isEmail';

import { useAddFunctionToRef, useGetQuery, useUserDataSelector } from '../../../hooks/hooks';
import { infinite } from '../../../modules/appConstants';
import { dashboardRoute } from '../../../routes';
import { adminNodeOperations } from '../../../store/admin/node/index';
import { adminUserOperations } from '../../../store/admin/user/index';
import { routerServices } from '../../../store/router/index';
import ReactTableV7ReactWindow from '../../Tables/ReactTableV7ReactWindow';
import { convertToCryptoNumber } from '../../utils/utils';

import { monthlyReportColumns } from './columns';
import { formatDataToSendEmail } from './services';
import { adminMonthlyEarningTableStyles } from './styles';

if (process.env.REACT_APP_ENV !== 'prod') {
  whyDidYouRender(React);
}

const useStyles = makeStyles(adminMonthlyEarningTableStyles);

const AdminMonthlyEarningTable = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const queryLng = useGetQuery('lng');
  const { t } = useTranslation();

  const [selectedEmails, setSelectedEmails] = useState([]);

  const [startTime, setStartTime] = useState(moment().utc().subtract(1, 'month').startOf('month')
    .format('YYYY-MM-DDTHH:mm:ss'));
  const [startTimeError, setStartTimeError] = useState(' ');
  const [endTime, setEndTime] = useState(moment().utc().subtract(1, 'month').endOf('month')
    .format('YYYY-MM-DDTHH:mm:ss'));
  const [endTimeError, setEndTimeError] = useState(' ');

  const setStartTimeValue = (value) => {
    const startTimeTimstamp = moment(value).unix();
    const endTimeTimstamp = moment(endTime).unix();

    if (startTimeTimstamp < endTimeTimstamp) {
      setStartTime(moment(value).format('YYYY-MM-DDTHH:mm:ss'));
      setStartTimeError(' ');
    } else {
      setStartTimeError(t('Start date have to be before end date'));
    }
  };
  const setEndTimeValue = (value) => {
    const startTimeTimstamp = moment(startTime).unix();
    const endTimeTimstamp = moment(value).unix();

    if (startTimeTimstamp < endTimeTimstamp) {
      setEndTime(moment(value).endOf('date').format('YYYY-MM-DDTHH:mm:ss'));
      setEndTimeError(' ');
    } else {
      setEndTimeError(t('End date have to be after start date'));
    }
  };

  const [testMode, setTestMode] = useState(false);
  const onTestModeToggle = () => {
    setTestMode(!testMode);
  };

  const { email } = useUserDataSelector();
  const [testEmail, setTestEmail] = useState(email);
  const [testEmailError, setTestEmailError] = useState(' ');
  const setTestEmailValue = (value) => {
    setTestEmail(value);
    if (isEmail(value)) {
      setTestEmailError(' ');
    } else {
      setTestEmailError(t('Ivalid email'));
    }
  };

  const { role } = useUserDataSelector();
  const {
    getUsersLoading,
    getAdminTotalReportLoading,
  } = useSelector((state) => state.loadingR);
  const monthlyReport = useSelector((state) => state.adminR.adminNode.monthlyReport);

  const userList = useSelector(
    (state) => state.adminR.users.userList['1-all'],
  );

  const onGetUsers = useAddFunctionToRef(async (userList) => {
    if (R.isEmpty(userList) || !userList) {
      await dispatch(adminUserOperations.getUsers(
        { query: { } },
        { page: 1, limit: infinite },
      ));
    }
  });

  const onGetAdminTotalReport = useAddFunctionToRef(async (userList, startTime, endTime) => {
    if (!R.isEmpty(userList) && userList) {
      const userId = R.map(R.prop('userId'), userList);
      await dispatch(adminNodeOperations.getAdminTotalReport(
        {
          userId,
          startTime: `${startTime}Z`,
          endTime: `${endTime}Z`,
          currency: 'jpy',
        },
      ));
    }
  });

  // If user is not admin redirect user to normal dashboard route
  useEffect(() => {
    if (role !== 'ADMIN') {
      routerServices.historyPush(
        history,
        {
          pathname: dashboardRoute,
          queryLng,
        },
      );
    }
  }, [history, role, queryLng]);

  useEffect(() => {
    onGetUsers(userList);
  }, [onGetUsers, userList]);
  useEffect(() => {
    onGetAdminTotalReport(userList, startTime, endTime);
  }, [onGetAdminTotalReport, userList, startTime, endTime]);

  const [updatedData, setUpdatedData] = useState({});
  const updateData = (original, value) => {
    const { earningType, userId } = original;
    const previousData = R.view(R.lensPath([earningType, userId]), updatedData) || {};
    const newData = R.assocPath([earningType, userId], { ...previousData, total: value }, updatedData);
    setUpdatedData(newData);
  };

  const dataToUse = R.pipe(
    R.map((user) => {
      const { userId, email } = user;
      const earnings = monthlyReport[userId];
      const total = sumFromList('total', earnings);

      const formatedData = formatDataToSendEmail({
        selected: selectedEmails.includes(email),
        userId,
        email,
        startTime: `${startTime}.000Z`,
        endTime: `${endTime}.000Z`,
        total,
        type: '-',
        subRows: earnings || [],
      });

      if (!formatedData) {
        return null;
      }

      const ununifiUpdatedTotal = R.view(R.lensPath(['UNUNIFI_VALIDATOR_NODE', userId, 'total']), updatedData);
      const { totalUnUniFiEarningGUU } = formatedData;
      return {
        ...formatedData,
        totalUnUniFiEarningGUU: ununifiUpdatedTotal ? convertToCryptoNumber(ununifiUpdatedTotal) : totalUnUniFiEarningGUU,
      };
    }),
    R.reject(R.equals(null)),
    R.sortWith(
      [R.descend(R.prop('selected'))],
    ),
  )(userList || []);

  const addSelectedEmail = (email) => {
    const newSelectedEmails = R.append(email, selectedEmails);
    setSelectedEmails(newSelectedEmails);
  };
  const removeSelectedEmail = (email) => {
    const newSelectedEmails = R.reject(R.equals(email), selectedEmails);
    setSelectedEmails(newSelectedEmails);
  };
  const selectAll = () => {
    setSelectedEmails(R.map(R.prop('email'), dataToUse));
  };
  const removeAll = () => {
    setSelectedEmails([]);
  };
  const isSelectedAll = dataToUse.length === selectedEmails.length;

  const updateMonthlyReportEmailConfig = useAddFunctionToRef((data, selectedEmails, testMode, testEmail, testEmailError) => {
    const error = testEmailError !== ' ';
    const formatedData = R.pipe(
      R.map(
        R.pick([
          'email',
          'totalEarningFiat',
          'totalNeunodeEarningFiat',
          'totalEth2EarningFiat',
          'totalEth2Earning',
          'totalPhalaEarningFiat',
          'totalPhalaEarning',
          'totalLendingEarningFiat',
          'totalLendingEarning',
          'totalUnUniFiEarningGUU',
          'startDate',
          'endDate',
          'ununifiDateCreated',
        ]),
      ),
      R.reject((data) => {
        const { email, totalEarningFiat, totalUnUniFiEarningGUU } = data;
        const isEarningEmpty = totalEarningFiat === '¥0' && totalUnUniFiEarningGUU === '0';
        const selected = selectedEmails.includes(email);
        return !selected || isEarningEmpty;
      }),
    )(data);
    dispatch(adminNodeOperations.updateMonthlyReportEmailConfig(
      formatedData, testMode, testEmail, error,
    ));
  });

  useEffect(() => {
    updateMonthlyReportEmailConfig(
      dataToUse, selectedEmails, testMode, testEmail, testEmailError,
    );
  }, [
    updateMonthlyReportEmailConfig, dataToUse, selectedEmails, testMode, testEmail, testEmailError,
  ]);

  const columnsToUse = monthlyReportColumns(
    t,
    selectedEmails,
    addSelectedEmail,
    removeSelectedEmail,
    selectAll,
    removeAll,
    updateData,
    isSelectedAll,
  );

  const loading = getUsersLoading || getAdminTotalReportLoading;

  const getContent = () => {
    // NOTE: Need to add loading into ReactTableV7
    if (getUsersLoading) {
      return (
        <Box className={classes.loadingCircle}>
          <CircularProgress />
        </Box>
      );
    }
    return (
      <>
        <Box>
          <Typography variant="h2" className={classes.subTitle}>
            {t('Test Mode')}
          </Typography>
        </Box>
        <Paper className={classes.table}>
          <Box p={2}>
            <Box display="flex" pl={2} alignItems="center">
              <Box pr={3} flex={0.3}>
                <Typography color="textSecondary">
                  {t('Enable')}
                </Typography>
              </Box>
              <Box flex={0.7}>
                <Switch
                  color="primary"
                  onChange={onTestModeToggle}
                  className={classes.testModeSwitch}
                />
              </Box>
            </Box>
            <Box display="flex" pl={2} alignItems="center">
              <Box pr={3} flex={0.3}>
                <Typography color="textSecondary">
                  {t('Test Email')}
                </Typography>

              </Box>
              <Box pl={2} pt={2} flex={0.7}>
                <TextField
                  variant="outlined"
                  id="test-email"
                  value={testEmail}
                  error={testEmailError !== ' '}
                  helperText={testEmailError}
                  onChange={(event) => setTestEmailValue(event.target.value)}
                  disabled={!testMode}
                />
              </Box>
            </Box>
          </Box>
        </Paper>
        <Box pt={6}>
          <Typography variant="h2" className={classes.subTitle}>
            {t('Earnings')}
          </Typography>
        </Box>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <Box display="flex" pl={2} flexWrap="wrap">
            <Box pr={4} pb={1}>
              <KeyboardDatePicker
                id="start-date-picker"
                label={t('Start Date (UTC)')}
                format="MM/dd/yyyy HH:mm:ss"
                value={startTime}
                error={startTimeError !== ' '}
                helperText={startTimeError}
                onChange={setStartTimeValue}
                InputProps={{ readOnly: true }}
                DialogProps={{ disableBackdropClick: true }}
              />
            </Box>
            <Box pb={1}>
              <KeyboardDatePicker
                id="end-date-picker"
                label={t('End Date (UTC)')}
                format="MM/dd/yyyy HH:mm:ss"
                value={endTime}
                error={endTimeError !== ' '}
                helperText={endTimeError}
                onChange={setEndTimeValue}
                InputProps={{ readOnly: true }}
                DialogProps={{ disableBackdropClick: true }}
              />
            </Box>
          </Box>
        </MuiPickersUtilsProvider>
        <Paper className={classes.table}>
          <Box p={2}>
            <ReactTableV7ReactWindow
              title={
                (
                  <Box display="flex" alignContent="center">
                    <Box pr={1}>
                      {t('Earning List')}
                    </Box>
                    <Box mb={0.25}>
                      <IconButton
                        onClick={() => onGetAdminTotalReport(
                          userList, startTime, endTime,
                        )}
                        className={classes.reFetchButton}
                        disabled={loading}
                      >
                        <AutorenewOutlined color={loading ? 'disabled' : 'primary'} />
                      </IconButton>
                    </Box>
                  </Box>
                )
              }
              showToolbar
              scope="getUsers"
              data={dataToUse}
              columns={columnsToUse}
              loading={loading}
            />
          </Box>
        </Paper>
      </>
    );
  };
  return getContent();
};

AdminMonthlyEarningTable.defaultProps = {

};

AdminMonthlyEarningTable.propTypes = {

};

AdminMonthlyEarningTable.whyDidYouRender = true;

export default React.memo(AdminMonthlyEarningTable);
