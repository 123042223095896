import React from 'react';

import Mining from './Mining';

const Mining6Core = () => (
  <Mining
    getUserRollupRewardsType="DECENTRALIZED"
    machineType="SIX_CORE"
    nodeType="PHALA_MINING_NODE"
  />
);

export default Mining6Core;
