import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React from 'react';
import ImageGallery from 'react-image-gallery';

import RoundedButton from '../MaterialUiCustom/Button/RoundedButton';

import { nodeOwnerContentItemStyles } from './styles';
import './image-gallery.css';

const useStyles = makeStyles(nodeOwnerContentItemStyles);

const NodeOwnerContentItem = (props) => {
  const classes = useStyles();
  const {
    images,
    title,
    description,
    price,
    promotionText,
    onButtonClick,
    disabled,
    btnText,
  } = props;

  return (
    <Paper className={classes.root}>
      {promotionText && (
        <Box className={classes.promotion}>
          <Typography align="center" className={classes.promotionText}>
            {promotionText}
          </Typography>
        </Box>
      )}
      <Grid container justifyContent="center" spacing={2} className={classes.content}>
        <Grid item xs={12} sm={5}>
          {images && (
            <ImageGallery
              showNav={false}
              showPlayButton={false}
              showFullscreenButton={false}
              items={images}
              disableThumbnailScroll
              showThumbnails={false}
            />
          )}
        </Grid>
        <Grid item xs={12} sm={7}>
          <Grid container justifyContent="center" spacing={5}>
            <Grid item xs={12}>
              <Typography color="textSecondary" variant="h2" className={classes.title}>
                {title}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body1" className={classes.description}>
                {description}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Box display="flex" alignItems="center">
                <Box>
                  <Typography variant="body1" className={classes.text}>
                    価格：
                  </Typography>
                </Box>
                <Box>
                  <Typography variant="body1" className={classes.price}>
                    {`${price.toLocaleString('JPY')} 円`}
                  </Typography>
                </Box>
                <Box ml={1}>
                  <Typography variant="body1" className={classes.text}>
                    (税込)
                  </Typography>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <RoundedButton
                noMargin
                className={classes.buyNowButton}
                onClick={onButtonClick}
                disabled={disabled}
              >
                {btnText}
              </RoundedButton>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
};

NodeOwnerContentItem.defaultProps = {
  promotionText: null,
};

NodeOwnerContentItem.propTypes = {
  images: PropTypes.arrayOf(PropTypes.shape({
    original: PropTypes.string,
    thumbnail: PropTypes.string,
  })).isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  price: PropTypes.number.isRequired,
  promotionText: PropTypes.string,
  onButtonClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
  btnText: PropTypes.string.isRequired,
};

export default NodeOwnerContentItem;
