const validateSignin = (values) => {
  const { password } = values;
  const errors = {};
  if (!password) {
    errors.password = 'Required';
  }
  return errors;
};

export {
  validateSignin,
};
