// import * as R from 'ramda';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Skeleton from '@material-ui/lab/Skeleton';
import PropTypes from 'prop-types';
import React from 'react';

import { earningItemStyles } from './styles';

const useStyles = makeStyles(earningItemStyles);

const EarningItem = (props) => {
  const classes = useStyles();

  const {
    title,
    align,
    content,
    loading,
    loadingVariant,
    showSecondLoadingLine,
    disableBackground,
    titleFontSize,
    titleTextColor,
  } = props;

  const getContent = () => {
    if (loading) {
      const alignGrid = align === 'left' ? 'flex-start' : 'center';
      return (
        <Grid
          container
          spacing={3}
          justifyContent={alignGrid}
          alignItems={alignGrid}
          className={classes.loading}
        >
          <Grid item xs={loadingVariant === 'circle' ? 'auto' : 12}>
            <Skeleton
              variant={loadingVariant || 'text'}
              width={loadingVariant === 'circle' ? 100 : '100%'}
              height={loadingVariant === 'circle' ? 100 : '100%'}
            />
          </Grid>
          {loadingVariant === 'text' && showSecondLoadingLine && (
          <Grid item xs={12}>
            <Skeleton
              variant="text"
              width="100%"
            />
          </Grid>
          )}
        </Grid>
      );
    }

    return content;
  };

  const getRender = (disableBackground) => {
    const titleTypographyProps = {
      align,
      color: 'textSecondary',
      style: {
        color: titleTextColor,
        fontSize: titleFontSize,
        fontWeight: 600,
        whiteSpace: 'nowrap',
        fontFamily: [
          'Noto-sans',
          'Sans-serif',
        ].join(','),
      },
    };

    if (disableBackground) {
      return (
        <>
          <CardHeader
            title={title}
            titleTypographyProps={titleTypographyProps}
            className={classes.cardHeader}
          />
          <CardContent className={classes.cardContent}>
            {getContent()}
          </CardContent>
        </>
      );
    }

    return (
      <Card style={{ height: '100%' }}>
        <CardHeader
          title={title}
          titleTypographyProps={titleTypographyProps}
          className={classes.cardHeader}
        />
        <CardContent className={classes.cardContent}>
          {getContent()}
        </CardContent>
      </Card>
    );
  };

  return getRender(disableBackground);
};

EarningItem.defaultProps = {
  title: '',
  loading: false,
  loadingVariant: 'text',
  titleFontSize: 'h3',
  titleTextColor: '',
  showSecondLoadingLine: false,
};

EarningItem.propTypes = {
  title: PropTypes.string,
  align: PropTypes.string.isRequired,
  content: PropTypes.element.isRequired,
  loading: PropTypes.bool,
  loadingVariant: PropTypes.string,
  titleFontSize: PropTypes.number,
  titleTextColor: PropTypes.string,
  showSecondLoadingLine: PropTypes.bool,
  disableBackground: PropTypes.bool.isRequired,
};

export default EarningItem;
