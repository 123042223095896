import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { useGetQuery } from '../../hooks/hooks';
import { navigationOperations } from '../../store/navigation/index';
import { routerServices } from '../../store/router/index';
import DeployNodeButton from '../DeployNodeButton/DeployNodeButton';

import CustomTabs from './CustomTabs';
import { launchNodeTabStyles } from './styles';

import { marketRoute } from 'routes';

const useStyles = makeStyles(launchNodeTabStyles);

const LaunchNodeTabs = (props) => {
  const { tabs, loading, disabled } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const classes = useStyles();
  const history = useHistory();
  const queryLng = useGetQuery('lng');
  const onSelectSidebarItem = () => {
    dispatch(navigationOperations.selectSidebarItem(1));
  };

  const handleOnClick = () => {
    routerServices.historyPush(
      history,
      {
        pathname: marketRoute,
        queryLng,
      },
    );
    onSelectSidebarItem();
  };
  return (
    <CustomTabs
      tabs={tabs}
      loading={loading}
      extraComponent={(
        <DeployNodeButton
          variant="contained"
          color="primary"
          onClick={handleOnClick}
          className={classes.deployButton}
          btnText={t('Launch a node')}
          disabled={disabled}
        />
    )}
    />
  );
};

LaunchNodeTabs.defaultProps = {
  loading: false,
  disabled: false,
};

LaunchNodeTabs.propTypes = {
  /**
   * tabs list
   * {
   *  label: '',
   *  content: </>,
   * }
   */
  tabs: PropTypes.array.isRequired,

  /**
   * if true display loading circle
   */
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
};

export default React.memo(LaunchNodeTabs);
