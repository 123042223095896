import {
  pipeAwait,
} from 'jsutils';
import * as R from 'ramda';

import {
  graphqlNormalize,
} from '../../modules/apollo/apollo';
import {
  returnAction,
  createUpdateAction,
  createResetAction,
} from '../store_utils';

import {
  graphqlGetNodeProviderOrder,
} from './graphql/services';
import * as types from './types';

export const getNodeProviderOrder = R.curry(
  async (userId) => pipeAwait(
    graphqlGetNodeProviderOrder,
    graphqlNormalize('getNodeProviderOrder'),
    returnAction(types.GET_NODE_PROVIDER_ORDER_SUCCESS),
  )({ getNodeProviderOrderInput: { userId } }),
);

export const updateNodeProviderOrderConfig = createUpdateAction(
  types.UPDATE_NODE_PROVIDER_ORDER_CONFIG,
);

export const resetNodeProviderOrderConfig = createResetAction(
  types.RESET_NODE_PROVIDER_ORDER_CONFIG,
);
