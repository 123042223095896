import {
  pipeAwait,
} from 'jsutils';
import * as R from 'ramda';

import {
  graphqlNormalize,
} from '../../../modules/apollo/apollo';
import {
  returnAction,
} from '../../store_utils';

import {
  graphqlGetUsers,
  graphqlSignupUsers,
  graphqlSendFirstSigninEmails,
} from './graphql/services';
import * as types from './types';

export const getUsers = R.curry(
  async (getUsersInput, paginationInput) => pipeAwait(
    graphqlGetUsers,
    graphqlNormalize('getUsers'),
    returnAction(types.GET_USERS_SUCCESS),
  )({
    getUsersInput,
    paginationInput,
  }),
);

export const createUsers = R.curry(
  async (data) => pipeAwait(
    graphqlSignupUsers,
    graphqlNormalize('signupUsers'),
    returnAction(types.CREATE_USERS_SUCCESS),
  )({
    signupUsersInput: {
      data,
    },
  }),
);

export const sendFirstSigninEmails = R.curry(
  async (data) => pipeAwait(
    graphqlSendFirstSigninEmails,
    graphqlNormalize('sendFirstSigninEmails'),
    returnAction(types.SEND_FIRST_SIGNIN_EMAIL_SUCCESS),
  )({
    sendFirstSigninEmailsInput: {
      data,
    },
  }),
);
