import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import React from 'react';

const MarketCardDescription = (props) => {
  const {
    desc,
  } = props;

  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {desc}
    </Typography>
  );
};

MarketCardDescription.propTypes = {
  /**
   * Description
   */
  desc: PropTypes.string.isRequired,
};

export default MarketCardDescription;
