import { camelCase } from 'lodash';
import * as R from 'ramda';

export const selectOwnerRollupRewards = R.curry(
  (machineType, state) => {
    const rewards = state.poolR.rollupRewards.phala[camelCase(machineType)];
    if (!rewards) return 0;
    return rewards;
  },
);
