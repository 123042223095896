import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import React from 'react';

import { CloseIconButton } from '../MaterialUiCustom/MaterialUiCustom';

import Connector from './Connector';
import { connectorsByName, connectorsDisplayName } from './connectors';
import { connectorLogos } from './constants';
import { useEagerConnect, useInactiveListener } from './hooks';
import { selectConnectorsStyles } from './styles';

const useStyles = makeStyles(selectConnectorsStyles);

const SelectConnectors = (props) => {
  const {
    activate,
    activatingConnector,
    setActivatingConnector,
    onCloseConnectToWalletDialog,
    showHeader,
    wrongNetwork,
    showPopupWrongNetworkError,
  } = props;
  const classes = useStyles();

  // handle logic to eagerly connect to the injected ethereum provider, if it exists and has granted access already
  const triedEager = useEagerConnect();

  // handle logic to connect in reaction to certain events on the injected ethereum provider, if it exists
  useInactiveListener(!triedEager || !!activatingConnector);

  const getConnectorButton = R.curry(
    (connectorNames) => R.map(
      (name) => {
        const currentConnector = connectorsByName[name];
        // const activating = currentConnector === activatingConnector;
        // const connected = currentConnector === connector;
        // const disabled = !triedEager || !!activatingConnector || connected || !!error;
        if (name === 'walletconnect') return null;
        const handleOnClick = () => {
          if (wrongNetwork) {
            showPopupWrongNetworkError();
          }
          setActivatingConnector(currentConnector);
          activate(currentConnector);
        };

        return (
          <Connector
            onClick={handleOnClick}
            connectorName={connectorsDisplayName[name]}
            connectorImgSrc={connectorLogos[name]}
            key={name}

          />

        );
      },
    )(connectorNames),
  );

  const getContent = (connectorsByName) => R.pipe(
    R.keys,
    getConnectorButton,
  )(connectorsByName);

  return (
    <Grid container>
      {
        showHeader && (
          <Grid
            item
            xs={12}
          >
            <Box
              display="flex"
              justifyContent="space-between"
              width="100%"
              alignItems="center"
            >
              <Box m={1}>
                <Typography variant="h1">
                  Select a wallet
                </Typography>
              </Box>
              <Box m={1}>
                <CloseIconButton
                  onClick={onCloseConnectToWalletDialog}
                  color="default"
                />
              </Box>
            </Box>
          </Grid>
        )
      }
      <Grid item xs={12}>
        <Box
          display="flex"
          alignItems="center"
          flexDirection="column"
          className={classes.connectorBox}
        >
          {getContent(connectorsByName)}
        </Box>
      </Grid>
    </Grid>

  );
};

SelectConnectors.defaultProps = {
  activatingConnector: null,
  onCloseConnectToWalletDialog: () => {},
  showHeader: false,
};

SelectConnectors.propTypes = {
  activate: PropTypes.func.isRequired,
  activatingConnector: PropTypes.object,
  setActivatingConnector: PropTypes.func.isRequired,
  onCloseConnectToWalletDialog: PropTypes.func,
  showHeader: PropTypes.bool,
};

export default React.memo(SelectConnectors);
