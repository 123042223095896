export const GENERATE_DEPLOY_NODE_CONFIG = 'GENERATE_DEPLOY_NODE_CONFIG';

export const TOGGLE_DEPLOY_NODE_CONFIG_META_DATA = 'TOGGLE_DEPLOY_NODE_CONFIG_META_DATA';

export const UPDATE_CREDENTIALS = 'UPDATE_CREDENTIALS';

export const UPDATE_DEPLOY_NODE_CONFIG = 'UPDATE_DEPLOY_NODE_CONFIG';

export const RESET_DEPLOY_NODE_CONFIG = 'RESET_DEPLOY_NODE_CONFIG';

export const RESET_DEPLOY_NODE_CONFIG_META_DATA = 'RESET_DEPLOY_NODE_CONFIG_META_DATA';

export const UPDATE_DEPLOY_NODE_QUANTITY = 'UPDATE_DEPLOY_NODE_QUANTITY';

export const ADD_SUB_NODES_TO_NODES = 'ADD_SUB_NODES_TO_NODES';

export const FORMAT_SUB_NODE_DATA = 'FORMAT_SUB_NODE_DATA';

export const DEPLOY_NODE_SUCCESS = 'DEPLOY_NODE_SUCCESS';

export const RESET_DEPLOY_NODE_CONFIG_ONE_NODE_TYPE = 'RESET_DEPLOY_NODE_CONFIG_ONE_NODE_TYPE';
