import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

// routes

import { RoundedButton } from '../MaterialUiCustom/MaterialUiCustom';

import CustomTabs from './CustomTabs';
import { depositTabStyles } from './styles';

const useStyles = makeStyles(depositTabStyles);

const DepositTab = (props) => {
  const classes = useStyles();
  const {
    tabs, loading, onClick, buttonProps,
  } = props;
  const { t } = useTranslation();

  return (
    <CustomTabs
      tabs={tabs}
      loading={loading}
      extraComponent={(
        <RoundedButton
          variant="contained"
          color="primary"
          onClick={onClick}
          {...buttonProps}
          className={classes.button}
          noMargin
        >
          {t('Launch a node')}
        </RoundedButton>
    )}
    />
  );
};

DepositTab.defaultProps = {
  loading: false,
  onClick: () => {},
  buttonProps: {},
};

DepositTab.propTypes = {
  /**
   * tabs list
   * {
   *  label: '',
   *  content: </>,
   * }
   */
  tabs: PropTypes.array.isRequired,

  /**
   * if true display loading circle
   */
  loading: PropTypes.bool,
  onClick: PropTypes.func,
  buttonProps: PropTypes.object,
};

export default DepositTab;
