import CloudDownloadOutlinedIcon from '@material-ui/icons/CloudDownloadOutlined';
import PropTypes from 'prop-types';
import React from 'react';

import RoundedButton from './RoundedButton';

const DownloadButton = ({
  buttonProps,
  text,
  t,
  onClick,
}) => (
  <RoundedButton
    variant="contained"
    color="secondary"
    onClick={onClick}
    startIcon={<CloudDownloadOutlinedIcon />}
    {...buttonProps}
  >
    {text || t('download')}
  </RoundedButton>
);

DownloadButton.propTypes = {
  text: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  variant: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
};

export default DownloadButton;
