import * as R from 'ramda';

export const comparePropsWithTranslaitonMethod = R.curry((prevProps, nextProps) => {
  if (R.isEmpty(prevProps) && R.isEmpty(nextProps)) {
    return true;
  }
  if (prevProps.t.name === nextProps.t.name) {
    return true;
  }
  return false;
});
