import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import React from 'react';

const MyNodeInfoDescriptionItem = (props) => {
  const {
    title, content,
  } = props;
  return (
    <Grid container alignItems="center">
      <Grid zeroMinWidth item xs={12} sm={12} md={3} lg={3}>
        <Typography noWrap variant="h6" color="textSecondary">{title}</Typography>
      </Grid>
      <Grid zeroMinWidth item xs={12} sm={12} md={9} lg={9}>
        {content}
      </Grid>
    </Grid>
  );
};

MyNodeInfoDescriptionItem.propTypes = {
  /**
   * item title (left side)
   */
  title: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
  ]).isRequired,
  /**
   * item content (right side)
   */
  content: PropTypes.object.isRequired,
};
export default MyNodeInfoDescriptionItem;
