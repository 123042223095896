import * as R from 'ramda';

import { errorOperations } from '../error/index';
import { loadingOperations } from '../loading/index';

import * as actions from './actions';

export const getUserPools = R.curry(
  async (
    userId,
    dispatch,
  ) => {
    const operationName = 'getUserPools';
    try {
      dispatch(loadingOperations.setLoading(operationName, true));
      const { type, payload } = dispatch(
        await actions.getUserPools(
          userId,
        ),
      );
      dispatch(loadingOperations.setLoading(operationName, false));
      dispatch(errorOperations.clearError(operationName));
      return { type, payload };
    } catch (error) {
      dispatch(loadingOperations.setLoading(operationName, false));
      return dispatch(errorOperations.setError(
        operationName,
        { error },
      ));
    }
  },
);

export const getUserRollupRewards = R.curry(
  async (
    userId,
    getUserRollupRewardsType,
    machineType,
    dispatch,
  ) => {
    const operationName = 'getUserRollupRewards';
    try {
      dispatch(loadingOperations.setLoading(operationName, true));
      const { type, payload } = dispatch(
        await actions.getUserRollupRewards(
          userId,
          getUserRollupRewardsType,
          machineType,
        ),
      );
      dispatch(loadingOperations.setLoading(operationName, false));
      dispatch(errorOperations.clearError(operationName));
      return { type, payload };
    } catch (error) {
      dispatch(loadingOperations.setLoading(operationName, false));
      return dispatch(errorOperations.setError(
        operationName,
        { error },
      ));
    }
  },
);

export const getPools = R.curry(
  async (
    query,
    pagination,
    dispatch,
  ) => {
    const operationName = 'getPools';
    try {
      dispatch(loadingOperations.setLoading(operationName, true));
      const { type, payload } = dispatch(
        await actions.getPools(
          query,
          pagination,
        ),
      );
      dispatch(loadingOperations.setLoading(operationName, false));
      dispatch(errorOperations.clearError(operationName));
      return { type, payload };
    } catch (error) {
      dispatch(loadingOperations.setLoading(operationName, false));
      return dispatch(errorOperations.setError(
        operationName,
        { error },
      ));
    }
  },
);

export const updatePools = R.curry(
  async (
    data,
    dispatch,
  ) => {
    const operationName = 'updatePools';
    try {
      dispatch(loadingOperations.setLoading(operationName, true));
      const { type, payload } = dispatch(
        await actions.updatePools(
          data,
        ),
      );
      dispatch(loadingOperations.setLoading(operationName, false));
      dispatch(errorOperations.clearError(operationName));
      return { type, payload };
    } catch (error) {
      dispatch(loadingOperations.setLoading(operationName, false));
      return dispatch(errorOperations.setError(
        operationName,
        { error },
      ));
    }
  },
);

export const updatePoolDatas = R.curry(
  async (
    data,
    dispatch,
  ) => {
    const operationName = 'updatePoolDatas';
    try {
      dispatch(loadingOperations.setLoading(operationName, true));
      const { type, payload } = dispatch(
        await actions.updatePoolDatas(
          data,
        ),
      );
      dispatch(loadingOperations.setLoading(operationName, false));
      dispatch(errorOperations.clearError(operationName));
      return { type, payload };
    } catch (error) {
      dispatch(loadingOperations.setLoading(operationName, false));
      return dispatch(errorOperations.setError(
        operationName,
        { error },
      ));
    }
  },
);
