import toUpper from 'lodash/toUpper';
import * as R from 'ramda';
import { earningTypeMap } from './constants';

export const selectNodeData = R.curry(
  (nodeName, state) => {
    const nodeData = R.view(
      R.lensPath(['nodeR', 'userNodeData', nodeName]),
      state,
    );
    return nodeData || R.view(
      R.lensPath(['nodeR', 'userNodeData', 'default-node-name']),
      state,
    );
  },
);

export const selectNodeDataFromChain = R.curry(
  (nodeName, state) => {
    const nodeData = R.view(
      R.lensPath(['nodeR', 'userNodeDataFromChain', nodeName]),
      state,
    );
    return nodeData || R.view(
      R.lensPath(['nodeR', 'userNodeDataFromChain', 'default-node-name']),
      state,
    );
  },
);

export const selectNodeR = R.view(R.lensPath(['nodeR']));
export const selectCoinPriceR = R.pipe(
  selectNodeR,
  R.view(R.lensPath(['coinPrice'])),
);
export const selectCoinPrice = R.curry((coin, state) => R.pipe(
  selectCoinPriceR,
  R.view(R.lensPath([toUpper(coin)])),
  (priceObj) => priceObj || { price: 0 },
)(state));

export const selectNodeOwnerTotalEarning = R.curry((nodeType, state) => {
  const earningType = earningTypeMap[nodeType];
  const totalEarning =  R.view(R.lensPath(
    ['nodeR', 'nodeOwnerEarning', 'total', earningType, 'totalInCoin'],
  ), state);

  return totalEarning || 0;
});
