import gql from 'graphql-tag';

export const updateOrderDataMutation = gql`
  mutation updateOrderData(
    $updateOrderDataInput: UpdateOrderDataInput!
  )
  {
    updateOrderData(
      updateOrderDataInput: $updateOrderDataInput
    ) {
      items {
        orderId
        totalEarning
        orderType
        orderDate
        quantity
        trialDays
        dateTrialEnd
        metadata
        dateCreated
        dateUpdated
        user{
          userId
          email
        }
        payments {
          amount
          paymentId
          paymentType
          dateCreated
          dateUpdated
          paymentMethod
          currencySymbol
          stripe {
            stripePaymentMethodId
            stripePriceId
            stripeInvoice
            stripeInvoiceId
            stripeCustomerId
            stripeInvoicePdf
            stripeSubscriptionId
          }
        }
        contract {
          contractPeriodValue
          contractId
          contractIRR
          dateCreated
          dateUpdated
          contractType
          contractPeriod
          contractEndDate
          contractSignDate
          contractStartDate
        }
        timeline {
          dateCompleted
          expectDateCompleted
          stepName
          metadata
          stepStatus
          dateCreated
          dateUpdated
          nodeProviderOrderTimelineId
          description
        }
        devices {
          contract {
            contractPeriodValue
            contractId
            contractIRR
            dateCreated
            dateUpdated
            contractType
            contractPeriod
            contractEndDate
            contractSignDate
            contractStartDate
          }
          earnings {
            earning
            dateCreated
            dateUpdated
            currencySymbol
            nodeProviderEarningId
            deviceId
            cryptoPrices
          }
          macAddress
          dateActive
          dateCreated
          dateUpdated
          deviceStatus
          deviceId
        }
        earningHistory {
          orderId
          activeNodesCount
          timestamp
          earning
        }
      }
      meta {
        totalItems
        itemCount
        itemsPerPage
        totalPages
        currentPage
      }
    }
  }
`;

export const updateOrderTimelinesMutation = gql`
  mutation updateTimelines(
    $updateTimelinesInput: UpdateOrderTimelinesInput!
  )
  {
    updateTimelines(
      updateTimelinesInput: $updateTimelinesInput
    ) {
      data {
        nodeProviderOrderTimelineId
        dateCreated
        dateUpdated
        dateCompleted
        expectDateCompleted
        stepName
        stepStatus
        description
        metadata
      }
    }
  }
`;

export const createOrdersMutation = gql`
  mutation createOrders(
    $createOrdersInput: CreateOrdersInput!
  )
  {
    createOrders(
      createOrdersInput: $createOrdersInput
    ) {
      items {
        orderId
        totalEarning
        orderType
        orderDate
        quantity
        trialDays
        dateTrialEnd
        metadata
        dateCreated
        dateUpdated
        user {
          userId
          email
        }
        payments {
          amount
          paymentId
          paymentType
          dateCreated
          dateUpdated
          paymentMethod
          currencySymbol
          stripe {
            stripePaymentMethodId
            stripePriceId
            stripeInvoice
            stripeInvoiceId
            stripeCustomerId
            stripeInvoicePdf
            stripeSubscriptionId
          }
        }
        contract {
          contractPeriodValue
          contractId
          contractIRR
          dateCreated
          dateUpdated
          contractType
          contractPeriod
          contractEndDate
          contractSignDate
          contractStartDate
        }
        timeline {
          dateCompleted
          expectDateCompleted
          stepName
          metadata
          stepStatus
          dateCreated
          dateUpdated
          nodeProviderOrderTimelineId
          description
        }
        devices {
          contract {
            contractPeriodValue
            contractId
            contractIRR
            dateCreated
            dateUpdated
            contractType
            contractPeriod
            contractEndDate
            contractSignDate
            contractStartDate
          }
          earnings {
            earning
            dateCreated
            dateUpdated
            currencySymbol
            nodeProviderEarningId
            deviceId
            cryptoPrices
          }
          macAddress
          dateActive
          dateCreated
          dateUpdated
          deviceStatus
          deviceId
        }
        earningHistory {
          orderId
          activeNodesCount
          timestamp
          earning
        }
      }
    }
  }
`;
