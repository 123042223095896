// react

import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React from 'react';

import { announcementBarContentStyles } from './styles';

const useStyles = makeStyles(announcementBarContentStyles);

const AnnouncementBarContent = (props) => {
  const classes = useStyles();

  const {
    text, action,
  } = props;

  return (
    <Box display="flex" className={classes.root}>
      <Box mr={2}>
        <Typography variant="body1" className={classes.text}>
          {text}
        </Typography>
      </Box>
      <Box>
        {action}
      </Box>
    </Box>
  );
};

AnnouncementBarContent.defaultProps = {
};

AnnouncementBarContent.propTypes = {
  text: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
  ]).isRequired,
  action: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
  ]).isRequired,
};

export default AnnouncementBarContent;
