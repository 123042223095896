export const styles = (theme) => ({
  item: {
    backgroundColor: null,
    cursor: 'pointer',
    transition: 'background-color 200ms linear',
    '&:hover': {
      backgroundColor: '#F4F6F9',
      transition: 'background-color 200ms linear',
    },
  },
  loadingItem: {
    backgroundColor: null,
    padding: theme.spacing(2),
  },
  itemHeader: {
    padding: theme.spacing(2, 12.7, 1, 2),
    [theme.breakpoints.down('xs')]: {
      display: 'none',
      height: 0,
    },
  },
  itemHeaderDivider: {
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
  chainIconMobile: {
    width: '50px',
    height: '50px',
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  chainIcon: {
    width: '30px',
    height: '30px',
    marginRight: theme.spacing(1),
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
  padding: theme.spacing(1),
  itemContent: {
    padding: theme.spacing(2),
  },
  header: {
    marginTop: theme.spacing(2),
    visibility: 'visible',
    [theme.breakpoints.down('xs')]: {
      marginTop: theme.spacing(0),
      width: '0px',
      height: '0px',
      visibility: 'hidden',
    },
    // [theme.breakpoints.down('sm')]: {
    //   width: '0px',
    //   height: '0px',
    //   visibility: 'hidden',
    // },
  },
  headerContent: {
    margin: theme.spacing(-1, 2.5, -2, 6),
  },
  headerText: {
    fontWeight: 500,
  },
  nodeDescriptionText: {
    overflowX: 'auto',
    overflowY: 'hidden',
    height: '100%',
  },
  nodeDescriptionTitle: {
    fontSize: 12,
    fontWeight: 600,
  },
  nodeDescriptionContent: {
    fontSize: 12,
    fontWeight: 500,
  },
  nodeDescriptionSubContent: {
    fontSize: 10,
    fontWeight: 500,
  },
  nodeDescriptionContentNodeType: {
    fontSize: 12,
    fontWeight: 600,
  },
  nodeDescriptionDetailsButtonText: {
    fontSize: 12,
    fontWeight: 600,
    cursor: 'pointer',
  },
  nodeDescriptionDetailsButton: {
    marginRight: theme.spacing(2),
    textTransform: 'none',
  },
});

export const selectNodesToDepositStyles = (theme) => ({
  nodeItemListHeader: {
    ...theme.border.default,
    borderRadius: 10,
    paddingLeft: theme.spacing(8),
    paddingRight: theme.spacing(2.15),
    width: '80%',
    // minHeight: 86,
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  nodeItem: {
    padding: theme.spacing(1, 2, 1, 2),
    ...theme.border.default,
    borderRadius: 10,
    width: '80%',
    // minHeight: 86,
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  nodeList: {
    ...theme.centerComponent,
  },
  nodeGrid: {
    ...theme.centerComponent,
  },
  chainIcon: {
    width: 40,
    height: 40,
  },
});

export const overviewStyles = (theme) => ({
  dialogTitle: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  dialogContent: {
    paddingTop: 0,
  },
  myNodeTitle: {
    margin: theme.spacing(6, 0, 0, 0),
  },
  myNodeItemTitle: {
    fontWeight: 500,
    fontSize: 16,
    margin: theme.spacing(2, 0, 2, 0),
  },
  myNodeItemContent: {
    marginBottom: theme.spacing(4),
  },
  amountCard: {
    background: 'transparent linear-gradient(12deg, #000F1A 25%, #C0CCF9 200%) 0% 0% no-repeat padding-box',
  },
  chart: {
    width: '100%',
    height: '100%',
  },
});

export const earningDashBoardStyles = (theme) => {
  const h3Properties = {
    fontSize: theme.typography.h3.fontSize,
    fontWeight: theme.typography.h3.fontWeight,
  };
  return ({
    container: {
      minWidth: '100px',
      ...theme.centerComponent,
    },
    dataWithCircleBg: {
      color: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
    },
    dataWithCircle: {
      strokeLinecap: 'round',
      color: theme.nodeStatus.active,
    },
    percent: {
      ...h3Properties,
    },
    totalEarning: {
      fontSize: theme.typography.h2.fontSize,
      fontWeight: theme.typography.h2.fontWeight,
    },
    overviewText: {
      ...h3Properties,
    },
    primaryText: {
      fontSize: theme.typography.h2.fontSize,
      fontWeight: 550,
    },
  });
};

export const overviewEmptyItemStyles = (theme) => ({
  emptyItem: {
    borderColor: '#E8E8E8',
    backgroundColor: null,
    padding: theme.spacing(6, 0),
  },
  emptyItemContent: {
    fontSize: 12,
    fontWeight: 600,
  },
});

export const overviewItemStyles = (theme) => ({
  emptyItem: {
    borderColor: '#E8E8E8',
    backgroundColor: null,
    padding: theme.spacing(6, 0),
  },
  emptyItemContent: {
    fontSize: 12,
    fontWeight: 600,
  },
});

export const overviewPieChartStyles = (theme) => ({
  chart: {
    height: 260,
    width: '100%',
    margin: 'auto',
  },
  loading: {
    marginLeft: '11%',
  },
  loadingPieContainer: {
    position: 'relative',
    content: '',
    display: 'block',
    paddingBottom: 'calc(100% - 30px)',
    width: 'calc(100% - 30px)',
    marginLeft: 'auto',
    marginRight: 'auto',
    maxWidth: 260,
    maxHeight: 260,
  },
  loadingPie: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    height: '100%',
    maxWidth: 230,
    maxHeight: 230,
    margin: 'auto',
  },
  loadingLegend: {
    width: '100%',
    maxWidth: 200,
  },
  legends: {
    width: '100%',
    maxWidth: 200,
  },
  legendSymbol: {
    borderRadius: 2,
    width: 10,
    height: 10,
  },
  legendText: {
    fontSize: 14,
    fontWeight: 600,
  },
  centerComponent: {
    ...theme.centerComponent,
  },
});

export const overviewPieChartTooltipStyles = (theme) => ({
  rowTitle: {
    fontSize: 12,
  },
  rowContent: {
    fontSize: 12,
    fontWeight: 550,
  },
  root: {
    padding: theme.spacing(1),
  },
  divider: {
    marginBottom: theme.spacing(1),
  },
});
