import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/styles';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

import WalletTransferBoard from './WalletTransferBoard';
import { accountPropType, accountsPropType, balancePropType } from './propTypes';
import { walletFeaturesStyles } from './styles';

import TabHeaders from 'components/Tabs/TabHeaders';
import { getTabContent } from 'components/Tabs/utils';

const useStyles = makeStyles(walletFeaturesStyles);
const WalletFeatures = ({
  t,
  walletConnectMethod,
  selectedAccount,
  chain,
  subChain,
  api,
  balance,
  priceData,
  onGoBackToSelectMethod,
  ss58Format,
  accounts,
}) => {
  const [currentTabIndex, setCurrentTabIndex] = useState(0);
  const classes = useStyles();
  const tabs = [
    {
      name: t('Transfer'),
      onClick: () => setCurrentTabIndex(0),
      content: <WalletTransferBoard
        t={t}
        walletConnectMethod={walletConnectMethod}
        selectedAccount={selectedAccount}
        chain={chain}
        subChain={subChain}
        api={api}
        balance={balance}
        priceData={priceData}
        onGoBackToSelectMethod={onGoBackToSelectMethod}
        ss58Format={ss58Format}
        accounts={accounts}
      />,
    },
    // {
    //   name: t('Transactions'),
    //   onClick: () => setCurrentTabIndex(1),
    //   content: <Grid />,
    // },
  ];
  return (
    <Grid container>
      <Grid item xs={12} className={classes.tabHeaders}>
        <TabHeaders
          tabs={tabs}
          currentTabIndex={currentTabIndex}
        />
      </Grid>
      <Grid item xs={12}>
        {getTabContent(tabs, currentTabIndex)}
      </Grid>
    </Grid>
  );
};

WalletFeatures.propTypes = {
  t: PropTypes.func.isRequired,
  walletConnectMethod: PropTypes.string.isRequired,
  selectedAccount: accountPropType,
  chain: PropTypes.string.isRequired,
  subChain: PropTypes.string.isRequired,
  balance: balancePropType.isRequired,
  // priceData and api too complex
  priceData: PropTypes.object.isRequired,
  api: PropTypes.object,
  onGoBackToSelectMethod: PropTypes.func.isRequired,
  accounts: accountsPropType,
  ss58Format: PropTypes.number.isRequired,
};

WalletFeatures.defaultProps = {
  accounts: [],
  selectedAccount: {},
  api: undefined,
};
export default WalletFeatures;
