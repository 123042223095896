import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

// routes

import MyNodeInfoDescriptionItem from '../MyNodeInfoDescription/MyNodeInfoDescriptionItem';

import MyNodeInfoUsageItem from './MyNodeInfoUsageItem';
import MyNodeInfoUsageItemCircular from './MyNodeInfoUsageItemCircular';
// others

import { stylesDescription } from './styles';

const useStyles = makeStyles(stylesDescription);

const MyNodeInfoResourceUsage = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const { cpuUsage, ramUsage, diskUsage } = props;

  return (
    <Box className={classNames(classes.descriptionContent)}>
      <MyNodeInfoDescriptionItem
        title={t('Resource Usage')}
        content={(
          <Grid
            container
            alignItems="center"
            justifyContent="space-between"
          >
            <Grid item xs sm={3} className={classes.circularGrid}>
              <MyNodeInfoUsageItemCircular
                value={cpuUsage}
                unit="%"
                title={t('CPU load')}
              />
            </Grid>
            <Grid item xs sm={3} className={classes.circularGrid}>
              <MyNodeInfoUsageItemCircular
                value={ramUsage}
                unit="%"
                title={t('RAM GB')}
              />
            </Grid>
            <Grid item xs sm={3} className={classes.circularGrid}>
              <MyNodeInfoUsageItem
                value={diskUsage}
                unit="GB"
                title={t('Disk Usage')}
              />
            </Grid>
          </Grid>
        )}
      />
    </Box>
  );
};

MyNodeInfoResourceUsage.defaultProps = {
  cpuUsage: '',
  ramUsage: '',
  diskUsage: '',
};

MyNodeInfoResourceUsage.propTypes = {
  cpuUsage: PropTypes.string,
  ramUsage: PropTypes.string,
  diskUsage: PropTypes.string,
};

export default MyNodeInfoResourceUsage;
