import Box from '@material-ui/core/Box';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import ErrorOutlineOutlinedIcon from '@material-ui/icons/ErrorOutlineOutlined';
import FiberManualRecordOutlinedIcon from '@material-ui/icons/FiberManualRecordOutlined';
import {
  startCase,
  toLower,
} from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { useIsMobile } from '../../hooks/hooks';

const MyNodeStatus = (props) => {
  const { t } = useTranslation();
  const { status } = props;
  const isMobileDevice = useIsMobile();

  const [open, setOpen] = React.useState(false);

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    setOpen(true);
  };

  const colorSet = {
    PENDING_DEPOSIT: '#FF980C',
    CONFIRMING_DEPOSIT: '#FFDA0C',
    RUNNING: '#C7ED63',
    STOPPED: '#EB5135',
    DEPLOYING: '#9BE5D5',
    DELETED: '#9F988E',
  };

  const color = colorSet[status];

  const getTooltipContent = () => {
    if (isMobileDevice) {
      return (
        <ClickAwayListener onClickAway={handleTooltipClose}>
          <Tooltip
            onClose={handleTooltipClose}
            open={open}
            title={t('Please deposit 32 ETH to activate your validator node.')}
            placement="top"
          >
            <ErrorOutlineOutlinedIcon onClick={handleTooltipOpen} fontSize="small" color="disabled" />
          </Tooltip>
        </ClickAwayListener>
      );
    }

    return (
      <Tooltip
        title={t('Please deposit 32 ETH to activate your validator node.')}
        placement="top"
      >
        <ErrorOutlineOutlinedIcon onClick={handleTooltipOpen} fontSize="small" color="disabled" />
      </Tooltip>
    );
  };

  return (
    <Box
      display="flex"
      style={{ borderRadius: 5 }}
    >
      <Box color={color}>
        <FiberManualRecordOutlinedIcon style={{ fontSize: 10 }} />
      </Box>
      <Box ml={0.5} mr={1} color={color}>
        <Typography variant="subtitle2">{startCase(toLower(status))}</Typography>
      </Box>
      <Box>
        {status === 'PENDING_DEPOSIT' && (
          getTooltipContent()
        )}
      </Box>
    </Box>
  );
};

MyNodeStatus.defaultProps = {
};

MyNodeStatus.propTypes = {
  /**
   * MyNodeStatus status
   */
  status: PropTypes.string.isRequired,
};

export default MyNodeStatus;
