import Fade from '@material-ui/core/Fade';
import Grid from '@material-ui/core/Grid';
import toLower from 'lodash/fp/toLower';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import React from 'react';
import { useTranslation } from 'react-i18next';

import MarketCard from '../MarketCard/MarketCard';

// others
import { getChainsData, ChainStatus } from './constants';

const MarketTabPanel = (props) => {
  const { searchString } = props;
  const { t } = useTranslation();

  const chainsData = getChainsData(t);

  const filterNodeTypes = R.curry(
    (chainsData, searchString) => R.pipe(
      R.values,
      R.map((item) => item.nodeTypes),
      R.flatten,
      R.map((item) => R.pipe(
        R.split('_'),
        R.tail,
        R.join(' '),
        toLower,
      )(item.type)),
      R.join(' '),
      R.includes(toLower(searchString)),
    )(chainsData),
  );

  const protocolsList = R.pipe(
    R.filter((each) => {
      const { name } = each;
      const nameFound = R.pipe(
        toLower,
        R.flip(R.includes)(toLower(name)),
      )(searchString);
      const nodeTypeFound = filterNodeTypes(chainsData, searchString);

      return nameFound || nodeTypeFound;
    }),
    R.reject((eachProtocol) => eachProtocol.status === ChainStatus.HIDDEN),
    R.values,
    R.map((each) => (
      <Fade in key={each.name}>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <MarketCard
            chain={each}
          />
        </Grid>
      </Fade>
    )),
  )(chainsData);
  return (
    <Grid container spacing={4}>
      {protocolsList}
    </Grid>
  );
};

MarketTabPanel.defaultProps = {
  searchString: '',
};

MarketTabPanel.propTypes = {
  /**
   * search string
   */
  searchString: PropTypes.string,
};

export default MarketTabPanel;
