// react-table

import Table from '@material-ui/core/Table';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import { makeStyles } from '@material-ui/core/styles';
import Skeleton from '@material-ui/lab/Skeleton';
import whyDidYouRender from '@welldone-software/why-did-you-render';
import { nanoid } from 'nanoid';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import React from 'react';
import {
  useTable,
  useSortBy,
  useFilters,
  useExpanded,
  useGlobalFilter,
  useBlockLayout,
} from 'react-table';
import { FixedSizeList } from 'react-window';

import { Filter } from './Filter';
import TableToolbar from './ReactTableV7Toolbar';
import { tableStyles } from './styles';

const useStyles = makeStyles(tableStyles);

if (process.env.REACT_APP_ENV !== 'prod') {
  whyDidYouRender(React);
}

const ReactTableV7ReactWindow = (props) => {
  const classes = useStyles();

  const {
    title,
    columns,
    data,
    filter,
    sort,
    renderRowSubComponent,
    loading,
    showToolbar,
    hiddenColumns,
  } = props;

  const defaultColumn = React.useMemo(
    () => ({
      width: 150,
    }),
    [],
  );

  const setRowKey = () => nanoid(10);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    visibleColumns,
    state: { globalFilter },
    preGlobalFilteredRows,
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data,
      // defaultColumn: { Filter: DefaultColumnFilter },
      defaultColumn,
      initialState: {
        hiddenColumns: hiddenColumns || [],
      },
      autoResetExpanded: false,
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    useExpanded,
    useBlockLayout,
  );

  const RenderRow = React.useCallback(
    ({ index, style }) => {
      const row = rows[index];
      if (!row) {
        if (loading) {
          return (
            <TableRow key={`empty_row_${index}_`}>
              {R.addIndex(R.map)((each, index) => (
                <TableCell key={`empty_cell_${index}`} style={{ width: each.width }}>
                  <Skeleton />
                </TableCell>
              ), columns)}
            </TableRow>
          );
        }
        return null;
      }

      prepareRow(row);

      const rowStyle = row.original.rowStyle || {};

      return (
        <>
          <TableRow {...row.getRowProps({ style: { ...style, ...rowStyle } })}>
            {row.cells.map(
              (cell) => (
                <TableCell {...cell.getCellProps()}>
                  {loading ? <Skeleton /> : cell.render('Cell')}
                </TableCell>
              ),
            )}
          </TableRow>
          {row.isExpanded && (
          <TableRow style={{ backgroundColor: '#FAFAFA', width: '100%' }}>
            <TableCell colSpan={visibleColumns.length}>{renderRowSubComponent(row)}</TableCell>
          </TableRow>
          )}
        </>
      );
    },
    [rows, prepareRow, loading, visibleColumns.length, renderRowSubComponent, columns],
  );

  return (
    <div className={classes.root}>
      {showToolbar && (
      <TableToolbar
        preGlobalFilteredRows={preGlobalFilteredRows}
        setGlobalFilter={setGlobalFilter}
        globalFilter={globalFilter}
        title={title}
      />
      )}
      <Table
        {...getTableProps()}
      >
        <TableHead className={classes.header}>
          {headerGroups.map((headerGroup) => (
            <TableRow {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <TableCell {...column.getHeaderProps()}>
                  {
                    sort && (column.sortable !== false)
                      ? (
                        <TableSortLabel
                          active={column.isSorted}
                          direction={column.isSortedDesc ? 'desc' : 'asc'}
                          className={classes.headerText}
                        >
                          {sort && (
                          <div {...column.getSortByToggleProps()}>
                            {column.render('Header')}
                          </div>
                          )}
                          {!sort && (
                          <div>
                            {column.render('Header')}
                          </div>
                          )}
                        </TableSortLabel>
                      )
                      : (
                        <div>
                          {column.render('Header')}
                        </div>
                      )
                  }

                  {filter && <Filter column={column} />}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableHead>
        <tbody {...getTableBodyProps()}>
          <FixedSizeList
            height={420}
            itemCount={rows.length || 10}
            itemSize={70}
            itemKey={setRowKey}
          >
            {RenderRow}
          </FixedSizeList>
        </tbody>
      </Table>
    </div>
  );
};

ReactTableV7ReactWindow.defaultProps = {
  filter: false,
  sort: false,
  renderRowSubComponent: () => {},
  loading: false,
  showToolbar: false,
  hiddenColumns: [],
};

ReactTableV7ReactWindow.propTypes = {
  title: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
  ]).isRequired,
  filter: PropTypes.bool,
  sort: PropTypes.bool,
  /**
   * function that take row data as arg
   * then return component render
   */
  renderRowSubComponent: PropTypes.func,
  columns: PropTypes.array.isRequired,
  data: PropTypes.array.isRequired,
  loading: PropTypes.bool,
  showToolbar: PropTypes.bool,
  hiddenColumns: PropTypes.arrayOf(PropTypes.string),
};

ReactTableV7ReactWindow.whyDidYouRender = true;

export default ReactTableV7ReactWindow;
