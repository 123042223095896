import Box from '@material-ui/core/Box';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import * as moment from 'moment';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { serviceTermsDrawerStyles } from './styles';

const useStyles = makeStyles(serviceTermsDrawerStyles);

const ServiceTermsDrawer = () => {
  const { t } = useTranslation();
  const classes = useStyles();

  const { isAuthenticated } = useSelector((state) => state.userR.authentication);

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems={isAuthenticated ? 'left' : 'center'}
      className={classes.rootBox}
      ml={2.5}
      mb={3}
    >
      <Box>
        <Typography
          variant="caption"
          color="textSecondary"
          className={classes.text}
        >
          {t('Neukind')}
          {' '}
          &copy;
          {' '}
          {moment().year()}
        </Typography>
      </Box>
      <Box>
        <Box
          display="flex"
          direction="row"
          alignItems="center"
        >
          <Box>
            <Typography
              className={classes.text}
              variant="caption"
              color="textSecondary"
              noWrap
            >
              <Link href="https://www.neukind.com/terms" target="_blank" color="inherit">
                {t('Terms of Service')}
              </Link>
            </Typography>
          </Box>
          <Box mt={0.3}>
            <Typography className={classes.dot} variant="caption" color="textSecondary">
              •
            </Typography>
          </Box>
          <Box>
            <Typography
              className={classes.text}
              variant="caption"
              color="textSecondary"
              noWrap
            >
              <Link href="https://www.neukind.com/privacy" target="_blank" color="inherit">
                {t('Privacy Policy')}
              </Link>
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default ServiceTermsDrawer;
