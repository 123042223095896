import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React from 'react';

import { RoundedButton } from '../MaterialUiCustom/MaterialUiCustom';

import { styles } from './styles';

const useStyles = makeStyles(styles);

const DeployNodeButton = ({
  component,
  to,
  className,
  disabled,
  btnText,
  target,
  onClick,
}) => {
  const classes = useStyles();

  return (
    <RoundedButton
      variant="contained"
      color="primary"
      component={component || 'button'}
      to={to || null}
      className={className || classes.deployButton}
      disabled={disabled}
      target={target || null}
      onClick={onClick}
      noMargin
    >
      {btnText}
    </RoundedButton>
  );
};
DeployNodeButton.propTypes = {
  component: PropTypes.elementType,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  btnText: PropTypes.string.isRequired,
  target: PropTypes.string,
  onClick: PropTypes.func,
};
DeployNodeButton.defaultProps = {
  component: null,
  className: '',
  disabled: false,
  onClick: null,
  target: null,
};
export default DeployNodeButton;
