// queries
import {
  createQuery,
  createMutation,
} from '../../../../modules/apollo/apollo';

import {
  signupUsersMutation,
} from './mutations';
import {
  getUsersQuery,
  sendFirstSigninEmailsQuery,
} from './queries';
// mutations
// apollo

export const graphqlGetUsers = createQuery(getUsersQuery);
export const graphqlSendFirstSigninEmails = createQuery(sendFirstSigninEmailsQuery);

export const graphqlSignupUsers = createMutation(signupUsersMutation);
