import * as R from 'ramda';

import {
  keyToAuthTokenDataInLocalStorage,
} from '../../modules/appConstants';
import {
  getItem,
} from '../../modules/browser_utils';
import { errorOperations } from '../error/index';
import { loadingOperations } from '../loading/index';

import * as actions from './actions';
import * as services from './services';
import { generateErrorMessageConverter } from './utils';

/**
 * Signin operation
 *
 * @param      {string}    email     The email
 * @param      {string}    password  The password
 * @param      {Function}  dispatch  The dispatch function of redux
 * @return     {import('../typedefs').ReduxAction}
 */
export const signin = R.curry(
  async (option, email, password, role, dispatch) => {
    try {
      dispatch(loadingOperations.setLoading('signin', true));
      const response = dispatch(
        await actions.signin(
          R.trim(email),
          R.trim(password),
          role,
        ),
      );
      const userData = services.extractAndStoreAuthTokenData(response.payload);
      dispatch(actions.loadUser(userData));
      if (services.isAdmin(response.payload)) {
        dispatch(actions.loadAdmin(userData));
      } /* else {
        dispatch(actions.loadUser(userData));
      } */
      dispatch(actions.authenticateUser());
      dispatch(actions.setAuthTokenStatusUpdated());
      dispatch(errorOperations.clearError('signin'));
      return response;
    } catch (error) {
      dispatch(loadingOperations.setLoading('signin', false));
      const { t } = option;
      const errorMessageConverterObj = generateErrorMessageConverter(t);
      return dispatch(errorOperations.setError(
        'signin',
        {
          fallbackErrorMessage: 'Something went wrong with your sign in',
          error,
          errorMessageConverterObj,
        },
      ));
    }
  },
);

/**
 * Signup operation
 *
 * @param      {import('./typedefs').SignupBody}  body
 * @param      {Function}  dispatch  The dispatch function of redux
 * @return     {import('../typedefs').ReduxAction}
 */
export const signup = R.curry(
  async (body, dispatch) => {
    try {
      dispatch(loadingOperations.setLoading('signup', true));
      const trimmedBody = R.mergeLeft({
        email: R.trim(body.email),
        password: R.trim(body.password),
      }, body);
      const response = dispatch(await actions.signup(trimmedBody));
      const userData = services.extractAndStoreAuthTokenData(response.payload);
      dispatch(actions.loadUser(userData));
      if (services.isAdmin(response.payload)) {
        dispatch(actions.loadAdmin(userData));
      }
      // dispatch(actions.authenticateUser());
      dispatch(actions.setAuthTokenStatusUpdated());
      dispatch(errorOperations.clearError('signup'));
      return response;
    } catch (error) {
      dispatch(loadingOperations.setLoading('signup', false));
      return dispatch(errorOperations.setError(
        'signup',
        {
          fallbackErrorMessage: 'Something went wrong with your sign up',
          error,
        },
      ));
    }
  },
);

/**
 * Sign out operation
 *
 * @param      {string}    userId
 * @return     {import('../typedefs').ReduxAction}
 */
export const signOut = R.curry(async (userId, dispatch) => {
  try {
    dispatch(loadingOperations.setLoading('signout', true));
    const idToken = R.pipe(
      getItem,
      R.view(R.lensProp('idToken')),
    )(keyToAuthTokenDataInLocalStorage);
    // We used this to prevent from redirecting to dashboard page
    // after clicking on sign out
    dispatch(actions.signOutSync());
    const response = dispatch(
      await actions.signOut(
        userId,
        idToken,
      ),
    );
    dispatch(errorOperations.clearError('signout'));
    dispatch(loadingOperations.setLoading('signout', false));
    return response;
  } catch (error) {
    dispatch(loadingOperations.setLoading('signout', false));
    // If for any reason async signout (sending signout request to BE to update)
    // fails just sign out the user in FE.
    dispatch(actions.signOutSync());
    return dispatch(errorOperations.setError(
      'signout',
      { error },
    ));
  }
});

/**
 * Activate user
 *
 * @param      {uuid}  activationId
 * @param      {Function}  dispatch  The dispatch function of redux
 * @return     {import('../typedefs').ReduxAction}
 */
export const activateUser = R.curry(
  async (activationId, dispatch) => {
    try {
      dispatch(loadingOperations.setLoading('activateUser', true));
      const response = dispatch(await actions.activateUser(activationId));
      dispatch(errorOperations.clearError('activateUser'));
      return response;
    } catch (error) {
      dispatch(loadingOperations.setLoading('activateUser', false));
      return dispatch(errorOperations.setError(
        'activateUser',
        { error },
      ));
    }
  },
);

/**
 * Validate email
 *
 * @param      {string}  email
 * @param      {Function}  dispatch  The dispatch function of redux
 * @return     {import('../typedefs').ReduxAction}
 */
export const validateEmail = R.curry(
  async (email, dispatch) => {
    try {
      dispatch(loadingOperations.setLoading('validateEmail', true));
      const response = dispatch(await actions.validateEmail(email));
      dispatch(errorOperations.clearError('validateEmail'));
      return response;
    } catch (error) {
      dispatch(loadingOperations.setLoading('validateEmail', false));
      return dispatch(errorOperations.setError(
        'validateEmail',
        { error },
      ));
    }
  },
);

/**
 * Validate password
 *
 * @param      {string}  email
 * @param      {string}  password
 * @param      {Function}  dispatch  The dispatch function of redux
 * @return     {import('../typedefs').ReduxAction}
 */
export const validatePassword = R.curry(
  async (body, dispatch) => {
    try {
      dispatch(loadingOperations.setLoading('validatePassword', true));
      const response = dispatch(await actions.validatePassword(body));
      dispatch(errorOperations.clearError('validatePassword'));
      return response;
    } catch (error) {
      dispatch(loadingOperations.setLoading('validatePassword', false));
      return dispatch(errorOperations.setError(
        'validatePassword',
        { error },
      ));
    }
  },
);

/**
 * Change user email
 *
 * @param      {string}  userId
 * @param      {string}  newEmail
 * @param      {Function}  dispatch  The dispatch function of redux
 * @return     {import('../typedefs').ReduxAction}
 */
export const changeUserEmail = R.curry(
  async (userId, newEmail, dispatch) => {
    try {
      dispatch(loadingOperations.setLoading('changeUserEmail', true));
      const response = dispatch(await actions.changeUserEmail(userId, newEmail));
      dispatch(errorOperations.clearError('changeUserEmail'));
      return response;
    } catch (error) {
      dispatch(loadingOperations.setLoading('changeUserEmail', false));
      return dispatch(errorOperations.setError(
        'changeUserEmail',
        { error },
      ));
    }
  },
);

/**
 * Change user password
 *
 * @param      {string}  userId
 * @param      {string}  oldPassword
 * @param      {string}  newPassword
 * @param      {Function}  dispatch  The dispatch function of redux
 * @return     {import('../typedefs').ReduxAction}
 */
export const changeUserPassword = R.curry(
  async (userId, oldPassword, newPassword, dispatch) => {
    try {
      dispatch(loadingOperations.setLoading('changeUserPassword', true));
      const response = dispatch(
        await actions.changeUserPassword(userId, oldPassword, newPassword),
      );
      dispatch(errorOperations.clearError('changeUserPassword'));
      return response;
    } catch (error) {
      dispatch(loadingOperations.setLoading('changeUserPassword', false));
      return dispatch(errorOperations.setError(
        'changeUserPassword',
        { error },
      ));
    }
  },
);

/**
 * Send forgot password email
 *
 * @param      {string}  email
 * @param      {Function}  dispatch  The dispatch function of redux
 * @return     {import('../typedefs').ReduxAction}
 */
export const sendForgotPasswordEmail = R.curry(
  async (email, lang, dispatch) => {
    try {
      dispatch(loadingOperations.setLoading('sendForgotPasswordEmail', true));
      const response = dispatch(
        await actions.sendForgotPasswordEmail(email, lang),
      );
      dispatch(errorOperations.clearError('sendForgotPasswordEmail'));
      return response;
    } catch (error) {
      dispatch(loadingOperations.setLoading('sendForgotPasswordEmail', false));
      return dispatch(errorOperations.setError(
        'sendForgotPasswordEmail',
        { error },
      ));
    }
  },
);

/**
 * Reset user password
 *
 * @param      {string}  newPassword
 * @param      {string}  forgotPasswordId
 * @param      {Function}  dispatch  The dispatch function of redux
 * @return     {import('../typedefs').ReduxAction}
 */
export const resetPassword = R.curry(
  async (newPassword, forgotPasswordId, dispatch) => {
    try {
      dispatch(loadingOperations.setLoading('resetPassword', true));
      const response = dispatch(
        await actions.resetPassword(newPassword, forgotPasswordId),
      );
      dispatch(errorOperations.clearError('resetPassword'));
      return response;
    } catch (error) {
      dispatch(loadingOperations.setLoading('resetPassword', false));
      return dispatch(errorOperations.setError(
        'resetPassword',
        { error },
      ));
    }
  },
);

/**
 * Validate forgot password request
 *
 * @param      {string}  forgotPasswordId
 * @param      {Function}  dispatch  The dispatch function of redux
 * @return     {import('../typedefs').ReduxAction}
 */
export const validateForgotPassword = R.curry(
  async (forgotPasswordId, dispatch) => {
    try {
      dispatch(loadingOperations.setLoading('validateForgotPassword', true));
      const response = dispatch(
        await actions.validateForgotPassword(forgotPasswordId),
      );
      dispatch(errorOperations.clearError('validateForgotPassword'));
      return response;
    } catch (error) {
      dispatch(loadingOperations.setLoading('validateForgotPassword', false));
      return dispatch(errorOperations.setError(
        'validateForgotPassword',
        { error },
      ));
    }
  },
);

/**
 * Resend activation email
 *
 * @param      {string}  userId
 * @param      {Function}  dispatch  The dispatch function of redux
 * @return     {import('../typedefs').ReduxAction}
 */
export const resendActivation = R.curry(
  async (userId, lang, dispatch) => {
    try {
      dispatch(loadingOperations.setLoading('resendActivation', true));
      const response = dispatch(
        await actions.resendActivation(userId, lang),
      );
      dispatch(errorOperations.clearError('resendActivation'));
      return response;
    } catch (error) {
      dispatch(loadingOperations.setLoading('resendActivation', false));
      return dispatch(errorOperations.setError(
        'resendActivation',
        { error },
      ));
    }
  },
);

/**
 * Get activation
 *
 * @param      {string}  activationId
 * @param      {Function}  dispatch  The dispatch function of redux
 * @return     {import('../typedefs').ReduxAction}
 */
export const getActivation = R.curry(
  async (activationId, dispatch) => {
    try {
      dispatch(loadingOperations.setLoading('getActivation', true));
      const response = dispatch(
        await actions.getActivation(activationId),
      );
      dispatch(errorOperations.clearError('getActivation'));
      return response;
    } catch (error) {
      dispatch(loadingOperations.setLoading('getActivation', false));
      return dispatch(errorOperations.setError(
        'getActivation',
        { error },
      ));
    }
  },
);

export const createFirstSignin = R.curry(
  async (userId, dispatch) => {
    try {
      dispatch(loadingOperations.setLoading('createFirstSignin', true));
      const response = dispatch(
        await actions.createFirstSignin(userId),
      );
      dispatch(errorOperations.clearError('createFirstSignin'));
      return response;
    } catch (error) {
      dispatch(loadingOperations.setLoading('createFirstSignin', false));
      return dispatch(errorOperations.setError(
        'createFirstSignin',
        { error },
      ));
    }
  },
);

export const getFirstSignin = R.curry(
  async (firstSigninId, dispatch) => {
    try {
      dispatch(loadingOperations.setLoading('getFirstSignin', true));
      const response = dispatch(
        await actions.getFirstSignin(firstSigninId),
      );
      dispatch(errorOperations.clearError('getFirstSignin'));
      return response;
    } catch (error) {
      dispatch(loadingOperations.setLoading('getFirstSignin', false));
      return dispatch(errorOperations.setError(
        'getFirstSignin',
        { error },
      ));
    }
  },
);

export const getFirstSignins = R.curry(
  async (query, dispatch) => {
    const operationName = 'getFirstSignin';
    try {
      dispatch(loadingOperations.setLoading(operationName, true));
      const response = dispatch(
        await actions.getFirstSignins(query),
      );
      dispatch(errorOperations.clearError(operationName));
      return response;
    } catch (error) {
      dispatch(loadingOperations.setLoading(operationName, false));
      return dispatch(errorOperations.setError(
        operationName,
        { error },
      ));
    }
  },
);

export const setUserFirstSignedIn = R.curry(
  async (firstSigninId, dispatch) => {
    try {
      dispatch(loadingOperations.setLoading('setUserFirstSignedIn', true));
      const response = dispatch(
        await actions.setUserFirstSignedIn(firstSigninId),
      );
      dispatch(errorOperations.clearError('setUserFirstSignedIn'));
      return response;
    } catch (error) {
      dispatch(loadingOperations.setLoading('setUserFirstSignedIn', false));
      return dispatch(errorOperations.setError(
        'setUserFirstSignedIn',
        { error },
      ));
    }
  },
);

export const validateUser = R.curry(
  async (userId, dispatch) => {
    const operationName = 'validateUser';
    try {
      dispatch(loadingOperations.setLoading(operationName, true));
      const response = dispatch(
        await actions.validateUser(userId),
      );
      dispatch(errorOperations.clearError(operationName));
      return response;
    } catch (error) {
      dispatch(loadingOperations.setLoading(operationName, false));
      return dispatch(errorOperations.setError(
        operationName,
        { error },
      ));
    }
  },
);

export const { loadUser, resetLoadUser } = actions;
