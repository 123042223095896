import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import { useTranslation } from 'react-i18next';

import Alert from '../../MaterialUiCustom/Alert/Alert';

import NeunodeDevices from './NeunodeDevices';
import NeunodeEarningHistory from './NeunodeEarningHistory';
import NeunodeOrderHistory from './NeunodeOrderHistory';
import NeunodeOverview from './NeunodeOverview';

const { REACT_APP_NEUNODE_MAINTENANCE } = process.env;
const NeunodeContent = () => {
  const { t } = useTranslation();
  return (
    <Grid container justifyContent="center" spacing={4}>
      {REACT_APP_NEUNODE_MAINTENANCE === 'ON' && (
      <Grid item xs={12}>
        <Alert
          severity="warning"
        >
          <Typography variant="body2" color="inherit">
            {t('Neunode earning is under maintenance. Your earning is safe.')}
          </Typography>
        </Alert>
      </Grid>
      )}
      <Grid item xs={12}>
        <NeunodeOverview />
      </Grid>
      <Grid item xs={12}>
        <NeunodeEarningHistory />
      </Grid>
      <Grid item xs={12}>
        <NeunodeDevices />
      </Grid>
      <Grid item xs={12}>
        <NeunodeOrderHistory />
      </Grid>
    </Grid>
  );
};

export default NeunodeContent;
