import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import PaymentPagePayment from './PaymentPagePayment';
import PaymentPagePaymentHistory from './PaymentPagePaymentHistory';
import PaymentPageSubscription from './PaymentPageSubscription';
import { paymentPageContentStyles } from './styles';

import TabHeaders from 'components/Tabs/TabHeaders';
import { getTabContent } from 'components/Tabs/utils';

const useStyles = makeStyles(paymentPageContentStyles);

const PaymentPageContent = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  const [currentTabIndex, setCurrentTabIndex] = useState(0);

  const tabs = [
    {
      name: t('Transaction'),
      onClick: () => setCurrentTabIndex(0),
      content: <PaymentPagePaymentHistory />,
    },
    {
      name: t('Subscription'),
      onClick: () => setCurrentTabIndex(1),
      content: <PaymentPageSubscription />,
    },
    {
      name: t('Method'),
      onClick: () => setCurrentTabIndex(2),
      content: <PaymentPagePayment />,
    },
  ];

  return (
    <Grid container alignItems="center">
      <Grid item xs={12} className={classes.tabList}>
        <TabHeaders
          tabs={tabs}
          currentTabIndex={currentTabIndex}
        />
      </Grid>
      <Grid item xs={12}>
        {getTabContent(tabs, currentTabIndex)}
      </Grid>
    </Grid>
  );
};

export default PaymentPageContent;
