import green from '@material-ui/core/colors/green';
import red from '@material-ui/core/colors/red';

export const styles = (theme) => ({
  card: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    width: '80%',
    marginRight: 'auto',
    marginLeft: 'auto',
  },
  cardTitle: {
    fontSize: 16,
    fontWeight: 500,
    marginLeft: theme.spacing(2),
  },
  spacing4: {
    marginLeft: theme.spacing(4),
  },
  fieldNameEdit: {
    fontSize: 16,
    fontWeight: 500,
    marginTop: theme.spacing(4),
    marginLeft: theme.spacing(2),
  },
  fieldNameButton: {
    fontSize: 16,
    fontWeight: 500,
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(2),
  },
  margin: {
    margin: theme.spacing(1),
  },
  textFieldContainer: {
    width: '80%',
  },
  textField: {
    width: '100%',
  },
  passwordField: {
    height: 'auto',
  },
  cardContent: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    // flexWrap: 'wrap',
  },
  profileContainer: {
    padding: 0,
  },
  formHelperText: {
    color: red[500],
    marginLeft: theme.spacing(1) * 1.5,
    marginRight: theme.spacing(1) * 1.5,
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  check: {
    color: green[500],
  },
  successOutline: {
    borderColor: green[500],
  },
  successText: {
    color: green[500],
  },
  button: {
    margin: theme.spacing(1),
  },
  error: {
    marginBottom: theme.spacing(2),
  },
  couponDialog: {
    paddingBottom: theme.spacing(2.5),
  },
});
