export const styles = (theme) => ({
  paper: {
    ...theme.mixins.gutters(),
    padding: theme.spacing(3, 2, 2, 2),
    maxWidth: 512,
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  button: {
    width: '100%',
    margin: theme.spacing(1, 0),
    padding: theme.spacing(1, 0),
  },
  centerComponent: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  form: {
    flexDirection: 'column',
  },
  middleElements: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  divider: {
    ...theme.mixins.gutters(),
    marginBottom: theme.spacing(1),
    maxWidth: '29em',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  signInButton: {
    fontSize: 16,
  },
  icon: {
    fontSize: '70px',
  },
  textField: {
    width: '50%',
    [theme.breakpoints.down('md')]: {
      width: '80%',
    },
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  title: {
    fontWeight: 600,
    textTransform: 'capitalize',
  },
});
