import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { gweiToEther } from 'jsutils';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { convertCryptoToFiat } from '../utils/utils';

import EarningDataCard from './EarningDataCard';
import { earningDashBoardStyles } from './styles';

const useStyles = makeStyles(earningDashBoardStyles);

const EarningDashboard = (props) => {
  const classes = useStyles();
  const { nodeList } = props;
  const { t } = useTranslation();
  const nodeCount = R.length(nodeList);
  const activeNodeCount = R.length(R.filter(R.propEq('status', 'RUNNING'), nodeList));

  const coinPrice = useSelector((state) => state.nodeR.coinPrice);
  const nodesEarning = useSelector((state) => state.nodeR.nodesEarning.eth2);
  const { totalEarningData, totalEarningIn24hData, totalEarningIn7dData } = nodesEarning;

  const publicKeyList = R.map(
    R.view(R.lensPath(['nodeInfo', 'pubKey'])),
    nodeList,
  );

  // TODO bring this to selector
  const getTotalEarningByNodeList = (earningData) => R.pipe(
    R.filter((eachEarning) => publicKeyList.includes(eachEarning.publicKey)),
    R.map(
      R.prop('earning'),
    ),
    R.sum,
    gweiToEther,
  )(earningData);

  const totalEarning = getTotalEarningByNodeList(totalEarningData);
  const totalEarningIn24h = getTotalEarningByNodeList(totalEarningIn24hData);
  const totalEarningIn7Days = getTotalEarningByNodeList(totalEarningIn7dData);
  const {
    getCoinPriceLoading,
    getTotalEarningLoading,
    getTotalEarningIn24hLoading,
    getTotalEarningIn7dLoading,
  } = useSelector((state) => state.loadingR);

  const getFormattedPrimaryText = R.curry((text) => (
    <Typography
      className={classes.primaryText}
      align="center"
    >
      {text}
    </Typography>
  ));

  const getFormattedSecondaryText = R.curry((text) => (
    <Typography
      align="center"
      variant="button"
      color="textSecondary"
    >
      {text}
    </Typography>
  ));
  const getActiveNodeContent = (value, max) => {
    const percent = (value / max) * 100;
    return (
      <Box className={classes.container}>
        <Box position="relative" display="inline-flex">
          <CircularProgress
            variant="determinate"
            value={100}
            size={100}
            className={classes.dataWithCircleBg}
          />
          <Box
            top={0}
            left={0}
            bottom={0}
            right={0}
            position="absolute"
          >
            <CircularProgress
              variant="determinate"
              value={percent}
              size={100}
              className={classes.dataWithCircle}
            />
          </Box>
          <Box
            top={0}
            left={0}
            bottom={0}
            right={0}
            position="absolute"
            display="flex"
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
          >
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <Typography variant="body1" className={classes.percent}>{`${value}/${max}`}</Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    );
  };

  return (
    <Grid
      container
      spacing={1}
      alignItems="stretch"
    >
      <Grid item xs={6} sm={4}>
        <EarningDataCard
          title={t('Active Nodes')}
          content={getActiveNodeContent(activeNodeCount, nodeCount)}
        />
      </Grid>
      <Grid item xs={6} sm={4}>
        <EarningDataCard
          title={t('ETH Price')}
          loading={getCoinPriceLoading}
          content={
            (
              <Grid container spacing={2} direction="column" alignItems="center">
                <Grid item>
                  {getFormattedPrimaryText(`${coinPrice.ETH.price.toLocaleString('en-US', {
                    style: 'currency',
                    currency: coinPrice.ETH.currency,
                  })}`)}
                </Grid>
                <Grid item>
                  {getFormattedSecondaryText('1 ETH')}
                </Grid>
              </Grid>
            )
          }
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <EarningDataCard
          title={t('Total Earning')}
          loading={getTotalEarningLoading}
          content={
            (
              <Grid container spacing={2} direction="column" alignItems="center">
                <Grid item>
                  {getFormattedPrimaryText(`${convertCryptoToFiat('ETH', coinPrice, totalEarning).toLocaleString('en-US', {
                    style: 'currency',
                    currency: coinPrice.ETH.currency,
                  })}`)}
                </Grid>
                <Grid item>
                  {getFormattedSecondaryText(`≈ ${(totalEarning).toFixed(3)} ETH`)}
                </Grid>
              </Grid>
            )
          }
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <EarningDataCard
          title={t('Last 7 days earning')}
          loading={getTotalEarningIn7dLoading}
          content={(
            <Grid container spacing={2} direction="column" alignItems="center">
              <Grid item>
                {getFormattedPrimaryText(`${convertCryptoToFiat('ETH', coinPrice, totalEarningIn7Days).toLocaleString('en-US', {
                  style: 'currency',
                  currency: coinPrice.ETH.currency,
                })}`)}
              </Grid>
              <Grid item>
                {getFormattedSecondaryText(`≈ ${(totalEarningIn7Days).toFixed(3)} ETH`)}
              </Grid>
            </Grid>
          )}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <EarningDataCard
          title={t('Last 24h earning')}
          loading={getTotalEarningIn24hLoading}
          content={(
            <Grid container spacing={2} direction="column" alignItems="center">
              <Grid item>
                {getFormattedPrimaryText(`${convertCryptoToFiat('ETH', coinPrice, totalEarningIn24h).toLocaleString('en-US', {
                  style: 'currency',
                  currency: coinPrice.ETH.currency,
                })}`)}
              </Grid>
              <Grid item>
                {getFormattedSecondaryText(`≈ ${(totalEarningIn24h).toFixed(3)} ETH`)}
              </Grid>
            </Grid>
          )}
        />
      </Grid>
    </Grid>
  );
};

EarningDashboard.propTypes = {
  nodeList: PropTypes.arrayOf(PropTypes.shape({
    nodeId: PropTypes.string,
    nodeName: PropTypes.string,
    dateCreated: PropTypes.string,
    status: PropTypes.string,
    cusotmerId: PropTypes.string,
    nodeInfo: PropTypes.shape({
      pubKey: PropTypes.string.isRequired,
    }).isRequired,
  })).isRequired,
};

export default EarningDashboard;