import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import whyDidYouRender from '@welldone-software/why-did-you-render';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';

import { useAddFunctionToRef, useIsMobile } from '../../hooks/hooks';
import { paymentOperations } from '../../store/payment/index';
import ReactTableV7 from '../Tables/ReactTableV7';

import PaymentPagePaymentHistoryDetail from './PaymentPagePaymentHistoryDetail';
// others
import { paymentPagePaymentHistoryColumns } from './columns';
import { paymentPagePaymentHistoryStyles } from './styles';

if (process.env.REACT_APP_ENV !== 'prod') {
  whyDidYouRender(React);
}

const useStyles = makeStyles(paymentPagePaymentHistoryStyles);

const PaymentPagePaymentHistory = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const userR = useSelector((state) => state.userR);
  const { user } = userR;
  const { userId } = user;
  const getPaymentHistoryLoading = useSelector((state) => state.loadingR.getPaymentHistoryLoading);

  const paymentR = useSelector((state) => state.paymentR);
  const { paymentHistory } = paymentR;

  const onGetPaymentHistory = async () => {
    await dispatch(paymentOperations.getPaymentHistory(userId));
  };

  const onGetPaymentHistoryWithLoading = useAddFunctionToRef(async () => {
    await onGetPaymentHistory();
  });

  useEffect(() => {
    onGetPaymentHistoryWithLoading();
  }, [onGetPaymentHistoryWithLoading]);

  const [paymentToViewDetail, setPaymentToViewDetail] = useState({});
  const [openPaymentDetail, setOpenPaymentDetail] = useState(false);

  const handlePaymentDetailOpen = (index) => {
    const data = paymentHistory.items[index];
    setPaymentToViewDetail(data);
    setOpenPaymentDetail(true);
  };
  const handlePaymentDetailClose = () => {
    setOpenPaymentDetail(false);
  };

  const columnsToUse = paymentPagePaymentHistoryColumns(
    t,
    handlePaymentDetailOpen,
  );
  const isMobile = useIsMobile();
  const dialogProps = isMobile
    ? {
      fullScreen: true,
    }
    : {
      fullWidth: true,
      maxWidth: 'lg',
    };
  const getContent = () => {
    // NOTE: Need to add loading into ReactTableV7
    if (getPaymentHistoryLoading) {
      return (
        <Box className={classes.loadingCircle}>
          <CircularProgress />
        </Box>
      );
    }
    return (
      <Paper className={classes.table}>
        <Box className={classes.tableContainer}>
          <ReactTableV7 data={paymentHistory.items} columns={columnsToUse} />
        </Box>

        <Dialog
          open={openPaymentDetail}
          onClose={handlePaymentDetailClose}
          {...dialogProps}
        >
          <DialogContent className={classes.invoiceDetailDialogContent}>
            <PaymentPagePaymentHistoryDetail
              data={paymentToViewDetail}
              onClosePaymentDetail={handlePaymentDetailClose}
            />
          </DialogContent>
        </Dialog>
      </Paper>
    );
  };
  return getContent();
};

PaymentPagePaymentHistory.defaultProps = {

};

PaymentPagePaymentHistory.propTypes = {

};

PaymentPagePaymentHistory.whyDidYouRender = true;

export default PaymentPagePaymentHistory;
