import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import * as R from 'ramda';
import React, { useState } from 'react';

import SearchBar from '../SearchBar/SearchBar';

import { DAppStoreContentStyles } from './styles';

const useStyles = makeStyles(DAppStoreContentStyles);

const DAppStoreContent = (props) => {
  const classes = useStyles();

  const [currentTabIndex, setCurrentTabIndex] = useState(0);

  const tabs = [
    {
      name: 'All Categories',
      onClick: () => setCurrentTabIndex(0),
    },
    {
      name: 'Defi',
      onClick: () => setCurrentTabIndex(1),
    },
    {
      name: 'NFT',
      onClick: () => setCurrentTabIndex(2),
    },
    {
      name: 'Staking',
      onClick: () => setCurrentTabIndex(3),
    },
    {
      name: 'Lending',
      onClick: () => setCurrentTabIndex(4),
    },
  ];

  const getTabListContent = (tabs) => R.addIndex(R.map)((eachTab, index) => (
    <>
      <Box>
        <Typography
          color={currentTabIndex === index ? 'textPrimary' : 'textSecondary'}
          className={classes.tabName}
          noWrap
          onClick={eachTab.onClick}
        >
          {eachTab.name}
        </Typography>
      </Box>
      {index !== tabs.length - 1 && (
      <Box ml={2} mr={2}>
        <Typography color="textSecondary">
          |
        </Typography>
      </Box>
      )}
    </>
  ))(tabs);

  return (
    <Grid container alignItems="center" spacing={2}>
      <Grid item xs={12} sm={12} md={12} lg={8}>
        <Box display="flex">
          {getTabListContent(tabs)}
        </Box>
      </Grid>
      <Grid item xs={12} sm="auto" md="auto" lg={4}>
        <SearchBar
          placeholder="Search for a project"
        />
      </Grid>
    </Grid>
  );
};

export default DAppStoreContent;
