import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import isFunction from 'lodash/isFunction';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import {
  reduxForm, reset,
} from 'redux-form';

import { useAddFunctionToRef } from '../../hooks/hooks';
import { errorOperations } from '../../store/error/index';
import { userOperations } from '../../store/user/index';
import ErrorAlert from '../Error/ErrorAlert';
import ErrorMessage from '../ErrorMessage/ErrorMessage';
import { RoundedButton } from '../MaterialUiCustom/MaterialUiCustom';
import PasswordField from '../PasswordField/PasswordField';
// store

import { styles } from './styles';

const useStyles = makeStyles(styles);
const formName = 'verifyPassword';

/**
 * Verify password when edit user info
 *
 * @component
 * @example
 * const initialValues = {...}
 * const userId = 1
 * return (
 *   <VerifyPasswordDialog />
 * )
 */
const VerifyPasswordDialog = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { onClose, open, handleSubmit } = props;
  const { submitting, pristine, invalid } = props;
  const { email, onSuccessCallback } = props;
  const validatePasswordError = useSelector((state) => state.errorR.validatePasswordError);

  const [errorMessage, setErrorMessage] = useState('');
  const [showPassword, setShowPassword] = useState(false);

  const onDismount = useAddFunctionToRef(() => {
    dispatch(errorOperations.clearError('validatePassword'));
    // dispatch(errorOperations.clearErrorToRender());
  });

  useEffect(() => {
    const validatePasswordErrorMessage = R.view(R.lensProp('message'), validatePasswordError);
    setErrorMessage(validatePasswordErrorMessage);
  }, [validatePasswordError]);

  useEffect(() => onDismount, [onDismount]);

  /**
   * Toggle showPassword when user click the show passwrod button
   */
  const handleClickShowPassword = () => setShowPassword(!showPassword);

  const handleClose = () => {
    dispatch(reset(formName));
    onClose();
    setErrorMessage('');
  };

  const handleVerifyPassword = async (formValues) => {
    setErrorMessage('');
    const { password } = formValues;
    const response = await dispatch(userOperations.validatePassword({ email, password }));
    const errorMessage = R.view(R.lensPath(['payload', 'value', 'error', 'message']), response);
    if (response.type === 'VERIFY_PASSWORD_SUCCESS') {
      if (isFunction(onSuccessCallback)) onSuccessCallback();
      handleClose();
    } else if (errorMessage) {
      // If error is Service Unavailable or GraphQL Validation Failed error then error object
      // will be different. And so in that case 'errorMessage' will have falsy value. And for
      // Service Unavailable or GraphQL Validation Failed error {@link ErrorAlert} will be
      // displayed only. For these errors we must not show the following error message.
      setErrorMessage(t('Wrong password'));
    }
  };

  return (
    <Dialog onClose={handleClose} open={open} fullWidth>
      <ErrorAlert />
      <form onSubmit={handleSubmit(handleVerifyPassword)}>
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
          className={classes.verifyPasswordDialog}
        >
          <DialogTitle id="simple-dialog-title" className={classes.dialogTitle}>{t('Please enter your password to continue')}</DialogTitle>
          <ErrorMessage errorMessage={errorMessage} />
          <PasswordField
            handleClickShowPassword={handleClickShowPassword}
            showPassword={showPassword}
            customClassName={classNames(classes.textField)}
          />
          <RoundedButton
            variant="contained"
            color="primary"
            type="submit"
            disabled={submitting || pristine || invalid}
            className={classNames(classes.button)}
          >
            {t('Confirm')}
          </RoundedButton>
        </Grid>
      </form>
    </Dialog>
  );
};

VerifyPasswordDialog.propTypes = {
  /**
   * Close handle function
   */
  onClose: PropTypes.func.isRequired,
  /**
   * Show dialog if true, hide if false
   */
  open: PropTypes.bool.isRequired,
  /**
   * Submit function
   */
  handleSubmit: PropTypes.func.isRequired,
  /**
   * Function to call when password verified
   */
  onSuccessCallback: PropTypes.func.isRequired,
  /**
   * Is redux form submitting
   */
  submitting: PropTypes.bool.isRequired,
  /**
   * Redux form props
   */
  pristine: PropTypes.bool.isRequired,
  /**
   * Redux form props
   */
  invalid: PropTypes.bool.isRequired,
  /**
   * The email to verify
   */
  email: PropTypes.string.isRequired,
};

export default reduxForm({
  form: formName,
})(VerifyPasswordDialog);
