import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import withWidth from '@material-ui/core/withWidth';
import Alert from '@material-ui/lab/Alert';
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';

// routes

import {
  useHistory,
} from 'react-router-dom';

import { useAddFunctionToRef } from '../../hooks/hooks';
// store
import { nodeOperations } from '../../store/node/index';
import { isAPIReadFailedError } from 'store/error/services';

import RoundedButton from '../MaterialUiCustom/Button/RoundedButton';
import DepositTab from '../Tabs/DepositTab';

import EarningDashboard from './EarningDashboard';
import MyNode from './MyNode';
import MyNodesListHeader from './MyNodesListHeader';
import { myNodeListStyles } from './styles';

import { marketRoute, investmentBaseRoute } from 'routes';

const useStyles = makeStyles(myNodeListStyles);

const { REACT_APP_DISABLE_ETH2_ORDER } = process.env;
const MyNodesList = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const history = useHistory();
  const tabDataConstants = {
    eth2: {
      title: t('ETH2 Validator Node'),
      showEarningDashboard: true,
      nodeType: 'ETH2_VALIDATOR_NODE',
    },
    ununifi: {
      title: t('UnUniFi Validator Node'),
      showEarningDashboard: false,
      nodeType: 'UNUNIFI_VALIDATOR_NODE',
    },
  };
  const {
    match,
  } = props;
  const { params } = match;
  const { type } = params;
  const tabData = tabDataConstants[type];
  const { title, showEarningDashboard, nodeType } = tabData;

  const dispatch = useDispatch();
  const userR = useSelector((state) => state.userR);
  const { user } = userR;
  const { userId } = user;
  const { userNodes } = useSelector((state) => state.nodeR);
  const { nodes } = userNodes;
  const filteredNodes = R.filter(R.propEq('nodeType', nodeType), nodes);
  const getUserNodesLoading = useSelector((state) => state.loadingR.getUserNodesLoading);
  const {
    getTotalEarningError,
    getTotalEarningIn7dError,
    getTotalEarningIn24hError
  } = useSelector((state) => state.errorR);

  const doesTotalEarningAPIErrorExists = isAPIReadFailedError(getTotalEarningError);
  const doesTotalEarningIn7dAPIErrorExists = isAPIReadFailedError(getTotalEarningIn7dError);
  const doesTotalEarningIn24hAPIErrorExists = isAPIReadFailedError(getTotalEarningIn24hError);

  const doesApiReadFailedErrorExists = doesTotalEarningAPIErrorExists
    || doesTotalEarningIn7dAPIErrorExists
    || doesTotalEarningIn24hAPIErrorExists;

  const isEth2OrderDisabled = type === 'eth2' && REACT_APP_DISABLE_ETH2_ORDER === 'ON';

  const onGetUserNodes = useAddFunctionToRef(async () => {
    await dispatch(nodeOperations.getUserNodes(userId));
  });

  const onGetNodesEarning = useAddFunctionToRef(async (nodes) => {
    if (!showEarningDashboard) {
      return;
    }

    const publicKeyList = R.pipe(
      R.filter(R.propEq('nodeType', nodeType)),
      R.map(
        R.view(R.lensPath(['nodeInfo', 'pubKey'])),
      ),
    )(nodes);

    if (R.length(publicKeyList) > 0) {
      await dispatch(nodeOperations.getTotalEarning(userId, publicKeyList, nodeType));
    }
  });

  const onGetNodesEarningIn24h = useAddFunctionToRef(async (nodes) => {
    if (!showEarningDashboard) {
      return;
    }

    const publicKeyList = R.pipe(
      R.filter(R.propEq('nodeType', nodeType)),
      R.map(
        R.view(R.lensPath(['nodeInfo', 'pubKey'])),
      ),
    )(nodes);

    if (R.length(publicKeyList) > 0) {
      await dispatch(nodeOperations.getTotalEarningInPeriod(publicKeyList, nodeType, '24_h'));
    }
  });

  const getTotalEarningIn7Days = useAddFunctionToRef(async (nodes) => {
    if (!showEarningDashboard) {
      return;
    }

    const publicKeyList = R.pipe(
      R.filter(R.propEq('nodeType', nodeType)),
      R.map(
        R.view(R.lensPath(['nodeInfo', 'pubKey'])),
      ),
    )(nodes);

    if (R.length(publicKeyList) > 0) {
      await dispatch(nodeOperations.getTotalEarningInPeriod(publicKeyList, nodeType, '7_d'));
    }
  });

  const onGetCoinPrice = useAddFunctionToRef(
    async () => {
      await dispatch(nodeOperations.getCoinPrice('ETH', 'JPY'));
    },
  );

  useEffect(() => {
    onGetUserNodes();
  }, [onGetUserNodes]);

  useEffect(() => {
    onGetNodesEarning(nodes);
  }, [onGetNodesEarning, nodes]);

  useEffect(() => {
    onGetNodesEarningIn24h(nodes);
  }, [onGetNodesEarningIn24h, nodes]);

  useEffect(() => {
    getTotalEarningIn7Days(nodes);
  }, [getTotalEarningIn7Days, nodes]);

  useEffect(() => {
    onGetCoinPrice();
  }, [onGetCoinPrice]);

  const items = R.map((each) => (
    <Grid item xs={12} key={each.nodeName}>
      <MyNode node={each} showTitles={props.width === 'xs'} />
    </Grid>
  ), filteredNodes);
  const getContent = (items) => (
    <>
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        spacing={1}
      > {
        isEmpty(items)
          ? <Typography>{t('You don\'t have any node')}</Typography>
          : (
            <>
              {showEarningDashboard && (
              <Grid
                item
                xs={12}
              >
                <Box className={classes.earningDashboard}>
                  <EarningDashboard nodeList={filteredNodes} />
                </Box>
              </Grid>
              )}
              <Grid item xs={12}>
                <MyNodesListHeader />
              </Grid>
              {items}
            </>
          )
        }
      </Grid>
    </>
  );

  const onRedirectToProtocol = () => {
    history.push(marketRoute);
  };
  const redirectToETH2Page = () => {
    history.push({
      pathname: `${investmentBaseRoute}/eth2-validator`,
    });
  };

  const tabs = [
    {
      label: title,
      content: getContent(items),
    },
  ];

  return (
    <>
      {
        doesApiReadFailedErrorExists && (
          <Alert
            severity="error"
            style={{
              marginBottom: '8px',
            }}
          >
            <Typography variant="body2" color="inherit">
              {t('Our server is busy. Please reload or try again later.')}
            </Typography>
          </Alert>
        )
      }
      {type === 'eth2' && (
      <Alert
        severity="info"
        action={(
          <RoundedButton
            variant="contained"
            color="secondary"
            size="small"
            noMargin
            onClick={redirectToETH2Page}
          >
            {t('back')}
          </RoundedButton>
        )}
      >
        <Typography variant="body2" color="inherit">
          {t('You are viewing our classic UI.')}
        </Typography>
      </Alert>
      )}
      {isEth2OrderDisabled && (
        <Grid item xs={12}>
          <Alert
            severity="warning"
          >
            <Typography variant="body2" color="inherit">
              {t('Eth2 validator deployment is currently unavailable.')}
            </Typography>
          </Alert>
        </Grid>
      )}
      <DepositTab
        tabs={tabs}
        loading={getUserNodesLoading}
        onClick={onRedirectToProtocol}
        buttonProps={{
          disabled: Boolean(isEth2OrderDisabled),
        }}
      />
    </>
  );
};

MyNodesList.defaultProps = {
};

MyNodesList.propTypes = {
  width: PropTypes.string.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      type: PropTypes.string,
    }).isRequired,
  }).isRequired,
};

export default withWidth()(MyNodesList);
