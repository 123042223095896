import MyNodeInfoKeyDot from './MyNodeInfoKeyDot';
import MyNodeInfoKeyEth20 from './MyNodeInfoKeyEth20';

const exportObj = {
  eth2: {
    ETH2_VALIDATOR_NODE: MyNodeInfoKeyEth20,
    ETH_FULL_NODE: MyNodeInfoKeyEth20,
  },
  dot: {
    DOT_VALIDATOR_NODE: MyNodeInfoKeyDot,
  },

};
export default exportObj;
