import TextField from '@material-ui/core/TextField';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import { useInitialStateFromProps } from '../../hooks/hooks';

const RenderField = ({
  input,
  meta: { touched, error, invalid },
  label,
  type,
  className,
  variant,
  id,
  helperText,
  placeholder,
  ...rest
}) => {
  const [isError, setIsError] = useState(false);
  const [helperTextToUse, setHelperTextToUse] = useInitialStateFromProps('', helperText);
  useEffect(() => {
    setIsError(touched && invalid);
  }, [touched, invalid]);

  useEffect(() => {
    if (isError) {
      setHelperTextToUse(error);
    } else {
      setHelperTextToUse(helperText);
    }
  }, [isError, error, setHelperTextToUse, helperText]);
  return (
    <TextField
      {...input}
      {...rest}
      id={id}
      variant={variant}
      helperText={helperTextToUse || ' '}
      type={type}
      label={label}
      placeholder={placeholder}
      className={className}
      error={isError}
      margin="normal"
    />
  );
};
RenderField.defaultProps = {
  type: 'text',
  className: '',
  variant: 'outlined',
  id: 'render-field-id',
  helperText: '',
  placeholder: '',
};
RenderField.propTypes = {
  input: PropTypes.shape({
    name: PropTypes.string.isRequired,
    onBlur: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    onDragStart: PropTypes.func.isRequired,
    onDrop: PropTypes.func.isRequired,
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
  }).isRequired,
  meta: PropTypes.shape({
    touched: PropTypes.bool.isRequired,
    error: PropTypes.string,
    invalid: PropTypes.bool.isRequired,
  }).isRequired,
  label: PropTypes.string.isRequired,
  type: PropTypes.string,
  className: PropTypes.string,
  variant: PropTypes.string,
  id: PropTypes.string,
  helperText: PropTypes.string,
  placeholder: PropTypes.string,
  // name
};
export default RenderField;
