export const styles = (theme) => ({
  dialogTitle: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  dialogContent: {
    paddingTop: 0,
    paddingBottom: theme.spacing(3),
  },
});

export const paymentPeriodStyles = (theme) => ({
  planListItemGrid: {
    width: '100%',
  },
  loadingCircle: {
    ...theme.centerComponent,
  },
});

export const planItemStyles = (theme) => ({
  planItemRoot: {
    width: 272,
    height: 136,
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  planItemPrice: {
    paddingBottom: theme.spacing(0.7),
    fontSize: 25,
    fontWeight: 450,
  },
  planItemDetailText: {
    fontSize: 12,
  },
  label: {
    fontWeight: 800,
  },
  discountText: {
    margin: theme.spacing(0, 1, 0, 1),
  },
  priceSection: {
    paddingLeft: 12,
  },
});
