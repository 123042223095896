import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React from 'react';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
  },
  buttonProgress: {
    color: theme.palette.primary.main,
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  roundedButton: {
    borderRadius: 10,
    fontWeight: theme.typography.fontWeightBold,
  },
}));

export default function ButtonLoading({
  loading,
  onClick,
  className,
  color,
  variant,
  children,
  type,
  loadingSize,
  disabled,
  rounded,
}) {
  const classes = useStyles();
  const btnClassName = className || (rounded ? classes.roundedButton : null);
  return (
    <Box className={classes.root}>
      <Box className={classes.wrapper}>
        <Button
          variant={variant}
          color={color}
          className={btnClassName}
          disabled={disabled || loading}
          onClick={onClick}
          type={type}
        >
          {children}
        </Button>
        {loading && <CircularProgress size={loadingSize} className={classes.buttonProgress} />}
      </Box>
    </Box>
  );
}
ButtonLoading.defaultProps = {
  variant: 'contained',
  color: 'primary',
  loadingSize: 24,
};

ButtonLoading.propTypes = {
  loading: PropTypes.bool.isRequired,
};
