import {
  web3FromAddress,
} from '@polkadot/extension-dapp';
import { pipeAwait } from 'jsutils';
import WAValidator from 'multicoin-address-validator';
import * as R from 'ramda';

import { getNonce, increaseNonce } from './address';
import { generateSenderAccount } from './sender';
import {
  checkUntil,
  planckToToken,
  tokenToPlanck,
} from './utils';

export const waitTxAccepted = R.curry(
  async (api, message, account, nonce) => checkUntil(
    { timeout: 300000, message, stopWhenTimeout: true }, async () => pipeAwait(
      getNonce(api),
      R.equals(nonce + 1),
    )(account),
  ),
);
const prepareTx = R.curry(
  (
    api,
    amount,
    address,
  ) => api
    .tx.balances
    .transfer(address, amount),
);

const prepareTxs = R.curry(
  (
    api,
    amount,
    accounts,
  ) => R.map((account) => R.pipe(
    R.prop('address'),
    prepareTx(api, amount),
  )(account), accounts),
);

const formatTransactions = R.curry((sender, recipients, amount) => R.map(
  (recipient) => ({
    recipient,
    sender,
    amount,
  }),
));

export const broadcastTransactionBatch = R.curry(
  async (
    api,
    sender,
    amount,
    recipients,
  ) => {
    const senderAccount = generateSenderAccount(sender);
    const { address: senderAddress } = senderAccount;
    const increasedNonce = await increaseNonce(api, senderAddress, 1);
    const txs = prepareTxs(api, amount, recipients);
    const txsHash = await api.tx.utility
      .batch(txs)
      .signAndSend(senderAccount, { nonce: increasedNonce });
    const txHashHex = txsHash.toHex();
    await waitTxAccepted(
      api,
      `Transaction done!, tx: ${txHashHex}`,
      senderAddress,
      increasedNonce,
    );
    return formatTransactions(sender, recipients, amount);
  },
);

const transferMethods = R.curry(
  (transferMethod, api, recipient, amount, keepAlive) => {
    const methods = {
      transfer: () => api.tx.balances.transfer(recipient, amount),
      transferKeepAlive: () => api.tx.balances.transferKeepAlive(recipient, amount),
      transferAll: () => api.tx.balances.transferAll(recipient, keepAlive),
    };
    const method = methods[transferMethod] || methods.transferKeepAlive;
    return method();
  },
);
const broadcastTransactionWhenConnect = async (
  {
    api,
    sender,
    amount,
    recipient,
    keepAlive,
    transferMethod,
  },
) => {
  const increasedNonce = await increaseNonce(api, sender, 0);
  const senderInjector = await web3FromAddress(sender);
  // console.log('senderInjector', senderInjector);
  // console.log(transferMethod,
  //   api,
  //   recipient,
  //   tokenToPlanck(amount).toString(),
  //   keepAlive);
  const txHash = await transferMethods(
    transferMethod,
    api,
    recipient,
    tokenToPlanck(amount).toString(),
    keepAlive,
  ).signAndSend(sender, { signer: senderInjector.signer, nonce: increasedNonce });

  // await waitTxAccepted(
  //   api,
  //   `Transaction done!, tx: ${txHash.toString()}`,
  //   sender,
  //   increasedNonce,
  // );
  return txHash.toString();
};
const walletAccessMethodsFns = {
  connect: broadcastTransactionWhenConnect,
};

const createGetWalletAccessMethodsFn = R.curry(
  async (walletAccessMethodsFns, selectedMethod, data) => {
    const fn = walletAccessMethodsFns[selectedMethod];
    if (!fn) throw new Error(`"${selectedMethod}" wallet access method is supported`);
    return fn(data);
  },
);
const getWalletAccessMethodsFn = createGetWalletAccessMethodsFn(walletAccessMethodsFns);
export const broadcastTransaction = R.curry(
  async (
    selectedMethod,
    {
      api,
      sender,
      amount,
      recipient,
      keepAlive,
      transferMethod,
    },
  ) => {
    console.log('selectedMethod in tx', selectedMethod);
    const txHash = await getWalletAccessMethodsFn(
      selectedMethod,
      {
        api,
        sender,
        amount,
        recipient,
        keepAlive,
        transferMethod,
      },
    );
    return {
      sender,
      recipient,
      amount,
      txHash,
    };
  },
);

export const getTransactionInfo = R.curry(
  async ({
    api,
    recipient,
    sender,
    amount,
  }) => {
    if (!WAValidator.validate(recipient, 'dot')) {
      return {
        class: '',
        weight: '',
        transactionFee: 0,
      };
    }
    const info = await api.tx.balances
      .transfer(recipient, amount)
      .paymentInfo(sender);
    const {
      class: infoClass,
      weight,
      partialFee,
    } = info;
    return {
      class: infoClass.toString(),
      weight: weight.toString(),
      transactionFee: planckToToken(partialFee.toString()),
    };
  },
);
