import * as R from 'ramda';

export const calculateOrderTotalAmount = R.curry((paymentIds, payments) => (
  R.reduce((orderTotalAmount, paymentId) => (
    R.pipe(
      R.view(R.lensPath([paymentId, 'amount'])),
      R.add(orderTotalAmount),
    )(payments)), 0, paymentIds || []) // payments array may be empty
));

// TODO: Since for now we have only single payment element in payments array we are
// taking first element as temproary solution. We will need to update it for multiple
// elements in payments array.
const extractPaymentData = R.curry((paymentIds, payments) => R.pipe(
  R.head,
  R.flip(R.prop)(payments),
  (paymentItem) => (paymentItem || {}),
  R.pick(['dateCreated', 'paymentMethod']),
)(paymentIds || [])); // payments array may be empty

const extractData = R.curry((ids, data) => R.map(
  R.flip(R.prop)(data),
)(ids || []));

const extractOrderData = R.curry((orderId, orders) => R.pipe(
  R.prop(orderId),
  R.pick([
    'orderId',
    'orderType',
    'quantity',
    'orderDate',
    'dateCreated',
    'trialDays',
    'dateTrialEnd',
    'metaData',
    'user',
    'contract',
    'payments',
    'timeline',
    'devices',
  ]),
  (order) => {
    const { orderDate, dateCreated } = order;
    const orderDateToUse = orderDate || dateCreated;
    return R.pipe(
      R.omit(['dateCreated']),
      R.mergeLeft({ orderDate: orderDateToUse }),
    )(order);
  },
)(orders));

const extractContractData = R.curry((contractId, contracts) => {
  if (!contractId) {
    return null;
  }
  return R.view(R.lensPath([contractId, 'contractSignDate']), contracts);
});

const extractUserData = R.curry((userId, users) => {
  if (!userId) {
    return null;
  }
  return R.view(R.lensPath([userId, 'email']), users);
});

const createAppendOrderToOrderList = R.curry((entities, orderList, orderId) => {
  const {
    orders, contracts, payments, users, timelines, devices,
  } = entities;
  const {
    orderType,
    quantity,
    trialDays,
    dateTrialEnd,
    orderDate,
    metaData,
    user: userId,
    contract: contractId,
    payments: paymentIds,
    timeline: timelineIds,
    devices: deviceIds,
  } = extractOrderData(orderId, orders);
  const {
    dateCreated: paymentDate, paymentMethod,
  } = extractPaymentData(paymentIds, payments);
  const timelinesData = extractData(timelineIds, timelines);
  const devicesData = extractData(deviceIds, devices);
  const contractSignDate = extractContractData(contractId, contracts);
  const email = extractUserData(userId, users);
  const orderTotalAmount = calculateOrderTotalAmount(paymentIds, payments);
  return R.append(
    {
      orderId,
      email,
      orderType,
      quantity,
      orderDate,
      trialDays,
      dateTrialEnd,
      contractSignDate,
      orderTotalAmount,
      paymentDate,
      paymentMethod,
      timeline: timelinesData,
      devices: devicesData,
      metaData,
    },
    orderList,
  );
});

export const extractOrderList = R.curry((orderList) => {
  const { result, entities } = orderList;
  const appendOrderToOrderList = createAppendOrderToOrderList(entities);
  const { items } = result || {};
  return R.reduce(appendOrderToOrderList, [], items || []);
});
