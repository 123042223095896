import MuiIconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/Add';
import CloseIcon from '@material-ui/icons/Close';
import RemoveIcon from '@material-ui/icons/Remove';
import PropTypes from 'prop-types';
import React from 'react';

const IconButton = React.forwardRef((props, ref) => {
  const {
    ariaLabel,
    onClick,
    color,
    className,
    children,
    disabled,
  } = props;
  return (
    <MuiIconButton
      ref={ref}
      aria-label={ariaLabel}
      className={className}
      onClick={onClick}
      color={color}
      disabled={disabled}
    >
      {children}
    </MuiIconButton>
  );
});

IconButton.defaultProps = {
  ariaLabel: 'icon-button',
  color: 'primary',
  onClick: () => {},
  className: '',
  disabled: false,
};
IconButton.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.elementType,
    PropTypes.object,
  ]).isRequired,
  ariaLabel: PropTypes.string,
  color: PropTypes.string,
  onClick: PropTypes.func,
  className: PropTypes.string,
  disabled: PropTypes.bool,
};

const CloseIconButton = (props) => (
  <IconButton
    {...props}
  >
    <CloseIcon />
  </IconButton>
);

const AddIconButton = React.forwardRef((props, ref) => (
  <IconButton
    ref={ref}
    {...props}
  >
    <AddIcon />
  </IconButton>
));
const RemoveIconButton = React.forwardRef((props, ref) => (
  <IconButton
    ref={ref}
    {...props}
  >
    <RemoveIcon />
  </IconButton>
));

// const RefAddIconButton = React.forwardRef((props, ref) => <AddIconButton {...props} ref={ref}/>)
const IconButtons = {
  IconButton,
  CloseIconButton,
  AddIconButton,
  RemoveIconButton,
};

export default IconButtons;
