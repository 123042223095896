import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';
import {
  useHistory,
} from 'react-router-dom';

import { investmentBaseRoute } from '../../../routes';
import OverviewItem from '../OverviewItem';
import { getFiatContent } from '../utils';

const OverviewNeunodeItem = (props) => {
  const history = useHistory();
  const {
    nodes,
    nodeCount,
    totalEarning,
    totalBalance,
    showTitles,
  } = props;

  const {
    getOrdersLoading,
    getIntervalFixedEarningLoading,
  } = useSelector((state) => state.loadingR);

  const { dateCreated, contract } = nodes[0];
  const { contractPeriodValue, contractPeriod, contractIRR } = contract;
  const plan = `${contractPeriodValue} ${contractPeriod} Fixed-term, ${(contractIRR * 100).toFixed(0)}%`;

  if (!getOrdersLoading) {
    if (!nodeCount) {
      return null;
    }
  }

  const redirectToNeunodePage = () => {
    history.push({
      pathname: `${investmentBaseRoute}/neunode`,
    });
  };

  const isLoading = getIntervalFixedEarningLoading;
  const formatedData = {
    image: '/img/neukind-logo-dark-circle.png',
    name: 'Neunode',
    plan,
    dateCreated,
    quantity: nodeCount,
    earning: getFiatContent(totalEarning, isLoading),
    balance: getFiatContent(totalBalance, isLoading),
    staked: getFiatContent(totalBalance - totalEarning, isLoading),
  };
  return (
    <Grid item xs={12}>
      <OverviewItem
        type="neunode"
        data={formatedData}
        showTitles={showTitles}
        onClick={redirectToNeunodePage}
        loading={getOrdersLoading}
      />
    </Grid>
  );
};

OverviewNeunodeItem.propTypes = {
  nodes: PropTypes.array,
  nodeCount: PropTypes.number,
  totalEarning: PropTypes.number,
  totalBalance: PropTypes.number,
  showTitles: PropTypes.bool,
};

OverviewNeunodeItem.defaultProps = {
  nodes: [],
  totalEarning: 0,
  nodeCount: 0,
  totalBalance: 0,
  showTitles: false,
};

export default React.memo(OverviewNeunodeItem);
