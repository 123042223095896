import * as R from 'ramda';
import { combineReducers } from 'redux';
import { PURGE } from 'redux-persist';

import {
  createReducer,
} from '../store_utils';

import * as types from './types';

import { SET_ERROR } from 'store/error/types';

const addressesIS = {
  done: false,
  dot: {
    pha: {
      accounts: [],
    },
  },
};
const cryptoAddressRUpdaters = {
  [types.CREATE_CRYPTO_ADDRESS_SUCCESS]: (
    state,
    payload,
  ) => {
    const {
      data,
      chain,
      lensPath,
    } = payload;
    const lensPathToUse = lensPath || [chain];
    return R.set(R.lensPath(lensPathToUse), data, state);
  },
  [types.GET_CRYPTO_ADDRESS_SUCCESS]: (
    state,
    payload,
  ) => {
    const {
      data,
      chain,
      lensPath,
    } = payload;
    const lensPathToUse = lensPath || [chain];
    return R.pipe(
      R.set(R.lensPath(['done']), true),
      R.set(R.lensPath(lensPathToUse), data),
    )(state);
  },
  [PURGE]: R.always(addressesIS),

  [SET_ERROR]: (
    state,
    payload,
  ) => {
    const {
      scope,
    } = payload;
    return scope === 'getCryptoAddresses'
      ? R.set(R.lensPath(['done']), true, state)
      : state;
  },
};

const addressR = createReducer(addressesIS, cryptoAddressRUpdaters);

// const accountsIS = {
//   done: false,
//   dot: {
//     pha: {
//       accounts: [],
//     },
//   },
// };
// const accountsRUpdaters = {
//   [types.GENEREATE_ACCOUNTS_SUCCESS]: (
//     state,
//     payload,
//   ) => {
//     const {
//       data,
//       chain,
//       lensPath,
//     } = payload;
//     const lensPathToUse = lensPath || [chain];
//     return R.set(R.lensPath(lensPathToUse), data, state);
//   },
//   [PURGE]: R.always(accountsIS),

// };

// const accountsR = createReducer(accountsIS, accountsRUpdaters);

const cryptoAddressR = combineReducers({
  addresses: addressR,
  // accounts: accountsR,
});
export default cryptoAddressR;
