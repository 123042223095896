import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import MobileStepper from '@material-ui/core/MobileStepper';
import Step from '@material-ui/core/Step';
import StepConnector from '@material-ui/core/StepConnector';
import StepLabel from '@material-ui/core/StepLabel';
import Stepper from '@material-ui/core/Stepper';
import { withStyles, createTheme, makeStyles } from '@material-ui/core/styles';
import whyDidYouRender from '@welldone-software/why-did-you-render';
import * as R from 'ramda';
import React, { useState, useMemo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { useGetQuery, useAddFunctionToRef, useIsMobile } from '../../../hooks/hooks';
import {
  marketRoute,
  signinRoute,
} from '../../../routes';
import { nodeOperations } from '../../../store/node/index';
import { routerServices } from '../../../store/router/index';

import UnUniFiDeployContent from './UnUniFiDeployContent';
import {
  ununifiDeployStyles,
} from './styles';

import FirstSignin from 'components/FirstSignin/FirstSignin';

// routes
// store

const { REACT_APP_ENV } = process.env;

if (REACT_APP_ENV !== 'prod') {
  whyDidYouRender(React);
}
const useStyles = makeStyles(ununifiDeployStyles);

const theme = createTheme();
const Connector = withStyles({
  active: {
    '& $line': {
      borderColor: '#1A2433',
    },
  },
  completed: {
    '& $line': {
      borderColor: '#1A2433',
    },
  },
  line: {
    marginLeft: 'auto',
    marginRight: 'auto',
    margin: 7,
    maxWidth: '50%',
    borderTopWidth: 3,
    borderRadius: 10,
    [theme.breakpoints.down('xs')]: {
      maxWidth: '90%',
      marginLeft: 'auto',
      marginRight: 'auto',
      margin: 0,
    },
  },
})(StepConnector);

const UnUniFiDeploy = () => {
  const classes = useStyles();
  const history = useHistory();
  const isMobile = useIsMobile();
  const queryLng = useGetQuery('lng');
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const user = useSelector((state) => state.userR.user);
  const { userId } = user;
  const onGetUserNodes = useAddFunctionToRef(async (userId) => {
    await dispatch(nodeOperations.getUserNodes(userId));
  });

  useEffect(() => {
    onGetUserNodes(userId);
  }, [onGetUserNodes, userId]);

  const [activeStep, setActiveStep] = useState(0);

  const { isAuthenticated } = useSelector((state) => state.userR.authentication);

  const windowUrl = window.location.search;
  const params = new URLSearchParams(windowUrl);
  const firstSigninId = params.get('id');

  const steps = [
    {
      label: t('Upload public-wallet file'),
      title: t('Upload public-wallet file'),
    },
    {
      label: t('Complete setup'),
      title: t('Complete'),
    },
  ];

  const memoSteps = useMemo(() => steps, [steps]);
  const getStepper = () => {
    if (isMobile) {
      return (
        <MobileStepper
          variant="dots"
          steps={4}
          position="top"
          activeStep={activeStep}
          className={classes.mobileStepper}
          nextButton={(
            <Box className={classes.fakeButton} />
        )}
          backButton={(
            <Box className={classes.fakeButton} />
        )}
          classes={{
            dot: classes.mobileStepperDot,
            dotActive: classes.mobileStepperDotActive,
          }}
        />
      );
    }
    return (
      <Stepper
        activeStep={activeStep}
        alternativeLabel
        style={{ backgroundColor: 'transparent' }}
        connector={<Connector />}
      >
        {memoSteps.map((item) => (
          <Step key={item.label}>
            <StepLabel classes={{ label: classes.stepLabel }}>{item.label}</StepLabel>
          </Step>
        ))}
      </Stepper>
    );
  };

  const handleStepperNext = useAddFunctionToRef(() => {
    setActiveStep((step) => {
      const nextStep = step + 1;
      if (nextStep < memoSteps.length) return nextStep;
      return step;
    });
  });

  const getContent = R.curry((firstSigninId) => {
    if (firstSigninId) {
      return (
        <FirstSignin
          redirectPathName={`${marketRoute}/ununifi/deploy`}
          customFirstSigninId={firstSigninId}
        />
      );
    }
    return (
      <Grid container>
        <Grid item xs={12}>
          {getStepper()}
        </Grid>
        <Grid item xs={12}>
          <UnUniFiDeployContent
            steps={memoSteps}
            activeStep={activeStep}
            handleStepperNext={handleStepperNext}
          />
        </Grid>
      </Grid>
    );
  });

  if (!isAuthenticated && !firstSigninId) {
    return routerServices.historyPush(
      history,
      {
        pathname: signinRoute,
        queryLng,
      },
    );
  }

  return getContent(firstSigninId);
};

UnUniFiDeploy.propTypes = {
};

UnUniFiDeploy.whyDidYouRender = true;
export default React.memo(UnUniFiDeploy);
