import isBoolean from 'lodash/isBoolean';
import * as R from 'ramda';
import { combineReducers } from 'redux';
import { PURGE } from 'redux-persist';

import {
  createReducer,
  updateStateWithKey,
} from '../store_utils';

import * as types from './types';

const web3AccountIS = {
  sampleAccount: {
    balance: 0,
    done: false,
  },
};
const web3AccountRUpdaters = {
  [types.GET_BALANCE_SUCCESS]: (
    state,
    payload,
  ) => {
    const {
      account,
      balance,
      done,
    } = payload;
    return updateStateWithKey(
      account,
      state,
      {
        balance,
        done,
      },
    );
  },

  // this reset the state to initial state
  [PURGE]: R.always(web3AccountIS),

  // not update state
};

const web3AccountR = createReducer(web3AccountIS, web3AccountRUpdaters);

// POLKADOT

const web3IS = {
  isWeb3Injected: false,
  isExtensionsInjected: false,
  injectedAccounts: {
    dot: {
      activeChain: 'pha',
      pha: {
        accounts: [],
      },
    },
  },
  generatedAccounts: {
    dot: {
      activeChain: 'pha',
      pha: {
        accounts: [],
      },
    },
  },
};

const updateBoolValue = R.curry((lensPath, value, state) => (isBoolean(value)
  ? R.set(R.lensPath(lensPath), value, state)
  : state));
const updateWeb3State = R.curry((state, payload) => {
  const {
    data,
    chain,
    isWeb3Injected,
    isExtensionsInjected,
    lensPath, // optional to update nested data
  } = payload;
  const lensPathToUse = lensPath || ['injectedAccounts', chain];
  return R.pipe(
    updateBoolValue(['isWeb3Injected'], isWeb3Injected),
    updateBoolValue(['isExtensionsInjected'], isExtensionsInjected),
    R.set(R.lensPath(lensPathToUse), data),
  )(state);
});

const web3RUpdaters = {
  [types.ENABLE_WEB3]: (
    state,
    payload,
  ) => updateWeb3State(state, payload),
  [types.ON_WEB3_ACCOUNT_CHANGE]: (
    state,
    payload,
  ) => updateWeb3State(state, payload),
  [types.GENEREATE_ACCOUNTS_SUCCESS]: (
    state,
    payload,
  ) => {
    const {
      data,
      chain,
      lensPath,
    } = payload;
    const lensPathToUse = lensPath || ['generatedAccounts', chain];
    return R.set(R.lensPath(lensPathToUse), data, state);
  },
  // this reset the state to initial state
  [PURGE]: R.always(web3IS),

  // not update state
};

const web3R = createReducer(web3IS, web3RUpdaters);

const createdAccountsIS = {
  done: false,
  dot: {
    pha: {
      accounts: [],
    },
  },
};
const createdAccountsRUpdaters = {
  [types.GENEREATE_ACCOUNTS_SUCCESS]: (
    state,
    payload,
  ) => {
    const {
      data,
      chain,
      lensPath,
    } = payload;
    const lensPathToUse = lensPath || [chain];
    return R.set(R.lensPath(lensPathToUse), data, state);
  },
  [PURGE]: R.always(createdAccountsIS),

};

const createdAccountsR = createReducer(createdAccountsIS, createdAccountsRUpdaters);

const transactionsIS = {
  done: false,
  dot: {
    txInfo: { transactionFee: 0, class: '', weight: '' },
    pha: {
      txs: {}, // {}
    },
  },
};
const transactionsRUpdaters = {
  [types.BROADCAST_TRANSACTION_SUCCESS]: (
    state,
    payload,
  ) => {
    const {
      data,
      chain,
      subChain,
      lensPath,
      done,
    } = payload;
    const lensPathToUse = lensPath || [chain, subChain, 'txs'];
    const txsInState = R.view(R.lensPath(lensPathToUse), state);
    const { txHash } = data;
    return R.pipe(
      R.set(R.lensPath(lensPathToUse), { ...txsInState, [txHash]: data }),
      R.set(R.lensPath(['done']), done),
    )(state);
  },
  [types.GET_TRANSACTION_INFO_SUCCESS]: (
    state,
    payload,
  ) => {
    const {
      data,
      chain,
      lensPath,
    } = payload;
    const lensPathToUse = lensPath || [chain, 'txInfo'];
    return R.set(R.lensPath(lensPathToUse), data)(state);
  },
  [types.RESET_TRANSACTION_INFO]: (
    state,
    payload,
  ) => {
    const {
      chain,
      lensPath,
    } = payload;
    const lensPathToUse = lensPath || [chain, 'txInfo'];
    return R.set(R.lensPath(lensPathToUse), {})(state);
  },
  [types.RESET_TRANSACTION_HASH]: (
    state,
    payload,
  ) => {
    const {
      chain,
      subChain,
      lensPath,
    } = payload;
    const lensPathToUse = lensPath || [chain, subChain, 'txs'];
    return R.set(R.lensPath(lensPathToUse), {})(state);
  },
  [types.RESET_TRANSACTION_DONE]: (state) => R.set(R.lensPath(['done']), false)(state),
  [PURGE]: R.always(transactionsIS),
};

const transactionsR = createReducer(transactionsIS, transactionsRUpdaters);

const accountsInfoIS = {};

const accountsInfoRUpdaters = {
  [types.GET_ACCOUNT_INFO_SUCCESS]: (
    state,
    payload,
  ) => {
    const {
      address,
      data,
      chain,
      subChain,
      lensPath,
    } = payload;
    const lensPathToUse = lensPath || [chain, subChain, address];
    return R.set(R.lensPath(lensPathToUse), data)(state);
  },
  [PURGE]: R.always(accountsInfoIS),
};

const accountsInfoR = createReducer(accountsInfoIS, accountsInfoRUpdaters);

const reducers = combineReducers({
  web3Account: web3AccountR,
  web3: web3R,
  createdAccounts: createdAccountsR,
  transactions: transactionsR,
  accountsInfo: accountsInfoR,
});

export default reducers;
