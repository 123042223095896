import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import React from 'react';

const ChainInfoNodeItem = (props) => {
  const {
    title, content,
  } = props;

  return (
    <Grid container>
      <Grid item xs={12} sm={12} md={5} lg={5}>
        <Typography variant="h6" color="textSecondary">{title}</Typography>
      </Grid>
      <Grid item xs={12} sm={12} md={7} lg={7}>
        {content}
      </Grid>
    </Grid>
  );
};

ChainInfoNodeItem.propTypes = {
  /**
   * item title (left side)
   */
  title: PropTypes.string.isRequired,
  /**
   * item content (right side)
   */
  content: PropTypes.element.isRequired,
};
export default ChainInfoNodeItem;
