import BigNumber from 'bignumber.js';
import { sleep } from 'jsutils';
import * as R from 'ramda';

// REF: https://github.com/Phala-Network/phala-blockchain/blob/2e1eea5ac4cfec815770cd8bbbd964c152fee363/e2e/utils.js
export const checkUntil = R.curry(
  async ({ timeout, message }, asyncFn) => {
    const t0 = new Date().getTime();
    while (true) {
      if (await asyncFn()) {
        console.log(message || 'done!');
        return true;
      }
      const t = new Date().getTime();
      if (t - t0 >= timeout) {
        return false;
      }
      sleep(100);
    }
  },
);

// REF: https://github.com/Phala-Network/phala-blockchain/blob/7941e7c79832c429a6f7864843c4ad53fb8441a4/scripts/js/src/setupTestEnvPoC3II.js#L9
export const tokenToPlanck = (n) => {
  const multiFactor = new BigNumber(1e12);
  return new BigNumber(n).times(multiFactor);
};

export const miliToToken = (n) => {
  const multiFactor = new BigNumber(1e3);
  return new BigNumber(n).div(multiFactor).decimalPlaces(12).toNumber();
};

export const planckToToken = (n) => {
  if (!n) {
    return 0;
  }

  const multiFactor = new BigNumber(1e12);
  return new BigNumber(n).div(multiFactor).decimalPlaces(12).toNumber();
};
