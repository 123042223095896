import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import { useTranslation } from 'react-i18next';

import Alert from '../../MaterialUiCustom/Alert/Alert';

import NeunodeETH2Devices from './NeunodeETH2Devices';
import NeunodeETH2EarningHistory from './NeunodeETH2EarningHistory';
import NeunodeETH2OrderHistory from './NeunodeETH2OrderHistory';
import NeunodeETH2Overview from './NeunodeETH2Overview';

const { REACT_APP_NEUNODE_MAINTENANCE } = process.env;
const NeunodeETH2Content = () => {
  const { t } = useTranslation();
  return (
    <Grid container justifyContent="center" spacing={4}>
      {REACT_APP_NEUNODE_MAINTENANCE === 'ON' && (
      <Grid item xs={12}>
        <Alert
          severity="warning"
        >
          <Typography variant="body2" color="inherit">
            {t('NeunodeETH2 earning is under maintenance. Your earning is safe.')}
          </Typography>
        </Alert>
      </Grid>
      )}
      <Grid item xs={12}>
        <NeunodeETH2Overview />
      </Grid>
      <Grid item xs={12}>
        <NeunodeETH2EarningHistory />
      </Grid>
      <Grid item xs={12}>
        <NeunodeETH2Devices />
      </Grid>
      <Grid item xs={12}>
        <NeunodeETH2OrderHistory />
      </Grid>
    </Grid>
  );
};

export default NeunodeETH2Content;
