// import * as R from 'ramda';

import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import AmountCardItem from './AmountCardItem';
import { amountCardStyles } from './styles';

const useStyles = makeStyles(amountCardStyles);

const AmountCard = (props) => {
  const classes = useStyles();

  const {
    data,
    loading,
    className,
    titleTextColor,
    earningTextColor,
    earningExchangedTextColor,
  } = props;

  const {
    balance,
    balanceTitle,
    balanceExchanged,
    earning,
    earningTitle,
    earningExchanged,
    staked,
    stakedTitle,
    stakedExchanged,
  } = data;

  const items = {
    balance: {
      title: balanceTitle,
      titleFontSize: 16,
      value: balance,
      valueFontSize: 36,
      valueExchanged: balanceExchanged,
      className: classes.totalBalance,
    },
    staked: {
      title: stakedTitle,
      titleFontSize: 14,
      value: staked,
      valueFontSize: 24,
      valueExchanged: stakedExchanged,
      className: classes.totalEarning,
      shown: !!stakedTitle,
    },
    earning: {
      title: earningTitle,
      titleFontSize: 14,
      value: earning,
      valueFontSize: 24,
      valueExchanged: earningExchanged,
      className: classes.totalEarning,
      shown: !!earningTitle,
    },
  };

  const isEarningShown = items.earning.shown;
  const isStakedShown = items.staked.shown;
  return (
    <Paper
      className={classNames(
        className,
        classes.amountCard,
      )}
    >
      <Grid container justifyContent="space-between">
        <Grid item xs={12} className={classes.balance}>
          <AmountCardItem
            data={items.balance}
            loading={loading}
            titleTextColor={titleTextColor}
            earningTextColor={earningTextColor}
            earningExchangedTextColor={earningExchangedTextColor}
          />
        </Grid>
        {isStakedShown && (
        <Grid item>
          <AmountCardItem
            data={items.staked}
            loading={loading}
            titleTextColor={titleTextColor}
            earningTextColor={earningTextColor}
            earningExchangedTextColor={earningExchangedTextColor}
          />
        </Grid>
        )}
        { (isStakedShown && isEarningShown) && (
        <Grid item className={classes.dividerContainer}>
          <Divider orientation="vertical" className={classes.divider} />
        </Grid>
        )}
        {isEarningShown && (
        <Grid item>
          <AmountCardItem
            data={items.earning}
            loading={loading}
            titleTextColor={titleTextColor}
            earningTextColor={earningTextColor}
            earningExchangedTextColor={earningExchangedTextColor}
          />
        </Grid>
        )}
      </Grid>
    </Paper>
  );
};

AmountCard.defaultProps = {
  className: '',
  loading: false,
  earningTextColor: '#000000',
  titleTextColor: '',
  earningExchangedTextColor: '',
};

AmountCard.propTypes = {
  data: PropTypes.shape({
    balance: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    balanceExchanged: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    balanceTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    earning: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    earningExchanged: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    earningTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    staked: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    stakedExchanged: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    stakedTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  }).isRequired,
  loading: PropTypes.bool,
  className: PropTypes.string,
  earningTextColor: PropTypes.string,
  titleTextColor: PropTypes.string,
  earningExchangedTextColor: PropTypes.string,
};

export default AmountCard;
