const contentWidth = (theme) => ({
  maxWidth: 720,
});

export const phalaMiningOrderFormStyle = (theme) => ({
  mobileStepperDot: {
    margin: '0px 8px',
  },
  mobileStepperDotActive: {
    backgroundColor: theme.palette.secondary.main,
  },
  mobileStepper: {
    marginTop: 55,
    flexGrow: 1,
  },
  fakeButton: {
    height: 40,
  },
  marginContentTop: {
    marginBottom: 50,
  },
  stepLabel: {
    [theme.breakpoints.down('xs')]: {
      fontSize: 12,
      '&.MuiStepLabel-alternativeLabel': {
        marginTop: 0,
      },
    },
  },
});

export const neunodeOrderFormContentStyles = (theme) => ({
  root: {
    padding: theme.spacing(2, 10),
    [theme.breakpoints.down('xs')]: {
      padding: '22px 0px',
    },
  },
  centerComponent: {
    ...theme.centerComponent,
  },
  content: {
    padding: theme.spacing(2, 2, 7, 2),
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(2, 2, 6, 2),
    },
  },
  encryptionPasswordField: {
    width: '100%',
  },
  encryptionPassword: {
    padding: theme.spacing(6, 1, 2, 1),
  },
  continueButton: {
    ...theme.centerComponent,
    width: '100%',
  },
  footerButton: {
    padding: theme.spacing(1.5, 6, 1.5, 6),
  },
  orderAndAgreement: {
    ...contentWidth(theme),
    ...theme.border.default,
  },
  orderAndAgreementContent: {
    margin: theme.spacing(2),
  },
  orderComplete: {
    ...contentWidth(theme),
  },
  orderCompleteContent: {
    margin: theme.spacing(2, 0, 2, 0),
  },
});
