import PropTypes from 'prop-types';

export const accountPropType = PropTypes.shape({
  meta: PropTypes.shape({ name: PropTypes.string }),
  address: PropTypes.string,
});

export const accountsPropType = PropTypes.arrayOf(accountPropType);

export const balancePropType = PropTypes.shape({
  free: PropTypes.number.isRequired,
  reserved: PropTypes.number.isRequired,
});
