export const styles = (theme) => ({
  signinNav: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    backgroundColor: 'transparent',
    border: '1px solid rgba(14,41,57,.1)',
    boxShadow: 'inset 0 1px 0 hsla(0,0%,100%,.5), 0 1px 0 hsla(0,0%,100%,.5)',
    marginTop: theme.spacing(1),
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  signinNavCont: {
    maxWidth: 512,
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  paper: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(2),
    maxWidth: 512,
    marginBottom: theme.spacing(2),
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  button: {
    width: '100%',
    margin: theme.spacing(1, 0),
    padding: theme.spacing(1, 0),
  },
  centerComponent: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  form: {
    flexDirection: 'column',
  },
  forgotPasswordText: {
    margin: 0,
    textDecoration: 'underline',
    color: theme.palette.text.secondary,
  },
  title: {
    fontWeight: 600,
  },
  content: {
    marginBottom: theme.spacing(2),
    width: '100%',
  },
  divider: {
    ...theme.mixins.gutters(),
    marginBottom: theme.spacing(1),
    maxWidth: 464,
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  signUpButton: {
    fontSize: 16,
    padding: 0,
  },
});
