/**
 * Bridge url used in wallet connector
 * @type {String}
 */
export const bridge = 'https://bridge.walletconnect.org';

export const ethApiUrl = 'https://ethereum-api.xyz';

export const explorers = {
  1: 'https://etherscan.io/tx',
  42: 'https://kovan.etherscan.io/tx',
  5: 'https://goerli.etherscan.io/tx',
};
