import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import toLower from 'lodash/fp/toLower';
import PropTypes from 'prop-types';
import * as R from 'ramda';
// import startCase from 'lodash/fp/startCase';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

// react-router
import {
  useHistory,
} from 'react-router-dom';

import { useGetQuery } from '../../hooks/hooks';
import { myNodeRoute } from '../../routes';
import { routerServices } from '../../store/router/index';
import ChainIcon from '../Chain/ChainIcon';
import { getChainsData } from '../Market/constants';

import MyNodeDescription from './MyNodeDescription';
// import MyNodeStatus from './MyNodeStatus';
// others

import { styles } from './styles';

const useStyles = makeStyles(styles);
const MyNode = (props) => {
  const history = useHistory();
  const classes = useStyles();
  const queryLng = useGetQuery('lng');
  const { t } = useTranslation();

  const { node, disabled, showTitles } = props;
  const [elevation, setElevation] = useState(1);
  const { nodeType } = node;
  const chainAbbreviation = R.pipe(
    R.split('_'),
    R.head,
    toLower,
  )(nodeType);
  const chain = getChainsData(t)[chainAbbreviation];
  const onMouseEnter = () => {
    if (!disabled) {
      setElevation(3);
    }
  };
  const onMouseLeave = () => {
    if (!disabled) {
      setElevation(1);
    }
  };

  const onCardClicked = () => {
    if (!disabled) {
      routerServices.historyPush(
        history,
        {
          pathname: `${myNodeRoute}/${node.nodeName}/info`,
          queryLng,
          state: { fromMyNodeList: true },
        },
      );
    }
  };

  return (
    <Card
      elevation={elevation}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      style={disabled ? {} : {
        cursor: 'pointer',
      }}
      onClick={onCardClicked}
      className={classes.node}
    >
      <CardContent className={classes.nodeContent}>
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          spacing={1}
        >
          <Grid item>
            <ChainIcon
              image={chain.iconPath}
              title={node.nodeName}
              className={classes.chainIcon}
            />
          </Grid>
          <Grid item xs>
            <MyNodeDescription
              nodeName={node.nodeName}
              dateCreated={node.dateCreated}
              showTitles={showTitles}
              status={node.status}
              nodeType={node.nodeType}
            />
          </Grid>
          {/* <Grid item xs={2}>
            <MyNodeStatus
              status={node.status}
              showTitles={disabled}
            />
          </Grid> */}
        </Grid>
      </CardContent>
    </Card>
  );
};

MyNode.propTypes = {
  /**
   * node data to display
   */
  node: PropTypes.shape({
    nodeName: PropTypes.string.isRequired,
    dateCreated: PropTypes.string.isRequired,
    /**
     * chain type of the node
     */
    nodeType: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
  }).isRequired,
  /**
   * Disable on click function
   */
  disabled: PropTypes.bool,
  showTitles: PropTypes.bool,
};

MyNode.defaultProps = {
  disabled: false,
  showTitles: false,
};

export default React.memo(MyNode);
