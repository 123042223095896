import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import MobileStepper from '@material-ui/core/MobileStepper';
import Step from '@material-ui/core/Step';
import StepConnector from '@material-ui/core/StepConnector';
import StepLabel from '@material-ui/core/StepLabel';
import Stepper from '@material-ui/core/Stepper';
import { withStyles, createTheme, makeStyles } from '@material-ui/core/styles';
import whyDidYouRender from '@welldone-software/why-did-you-render';
import toNumber from 'lodash/toNumber';
import React, { useState, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { useAddFunctionToRef, useIsMobile } from '../../../hooks/hooks';
import { deployConfigOperations } from '../../../store/deploy_config/index';
import { paymentOperations } from '../../../store/payment/index';

import Eth2DeployContent from './Eth2DeployContent';
import {
  eth2DeployStyles,
} from './styles';

const { REACT_APP_ENV } = process.env;

if (REACT_APP_ENV !== 'prod') {
  whyDidYouRender(React);
}

const theme = createTheme();
const Connector = withStyles({
  active: {
    '& $line': {
      borderColor: '#1A2433',
    },
  },
  completed: {
    '& $line': {
      borderColor: '#1A2433',
    },
  },
  line: {
    marginLeft: 'auto',
    marginRight: 'auto',
    margin: 7,
    maxWidth: '50%',
    borderTopWidth: 3,
    borderRadius: 10,
    [theme.breakpoints.down('xs')]: {
      maxWidth: '90%',
      marginLeft: 'auto',
      marginRight: 'auto',
      margin: 0,
    },
  },
})(StepConnector);

const useStyles = makeStyles(eth2DeployStyles);

const Eth2Deploy = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [activeStep, setActiveStep] = useState(0);
  const isMobile = useIsMobile();
  const dispatch = useDispatch();
  const onUpdateDeployNodeQuantity = useAddFunctionToRef((value) => (
    dispatch(deployConfigOperations.updateDeployNodeQuantity(toNumber(value)))
  ));
  // Handle reset quantity to 1 after unmount
  useEffect(() => () => {
    onUpdateDeployNodeQuantity(1);
  }, [onUpdateDeployNodeQuantity]);

  const onResetDeployNodeConfigMetatData = useAddFunctionToRef(() => {
    dispatch(deployConfigOperations.resetDeployNodeConfigMetatData());
  });

  const onResetDeployNodeConfig = useAddFunctionToRef(() => {
    dispatch(deployConfigOperations.resetDeployConfig());
  });

  // Handle reset deploy config meta data after unmount
  useEffect(() => () => onResetDeployNodeConfigMetatData(), [onResetDeployNodeConfigMetatData]);
  useEffect(() => () => onResetDeployNodeConfig(), [onResetDeployNodeConfig]);

  const onResetPromotionCode = useAddFunctionToRef(() => {
    dispatch(
      paymentOperations.updateStripePromotionCode(''),
    );
  });
  // Handle reset coupon after unmount
  useEffect(() => () => onResetPromotionCode(), [onResetPromotionCode]);

  const steps = [
    {
      label: t('Connect wallet'),
      title: t('Connect your wallet'),
    },
    {
      label: t('Generate key pairs'),
      title: t('Generate key pairs'),
    },
    {
      label: t('Deposit'),
      title: t('Deposit contract'),
    },
    {
      label: t('Complete setup'),
      title: t('Complete'),
    },
  ];

  const memoSteps = useMemo(() => steps, [steps]);
  const getStepper = () => {
    if (isMobile) {
      return (
        <MobileStepper
          variant="dots"
          steps={4}
          position="top"
          activeStep={activeStep}
          className={classes.mobileStepper}
          nextButton={(
            <Box className={classes.fakeButton} />
        )}
          backButton={(
            <Box className={classes.fakeButton} />
        )}
          classes={{
            dot: classes.mobileStepperDot,
            dotActive: classes.mobileStepperDotActive,
          }}
        />
      );
    }
    return (
      <Stepper
        activeStep={activeStep}
        alternativeLabel
        style={{ backgroundColor: 'transparent' }}
        connector={<Connector />}
      >
        {memoSteps.map((item) => (
          <Step key={item.label}>
            <StepLabel classes={{ label: classes.stepLabel }}>{item.label}</StepLabel>
          </Step>
        ))}
      </Stepper>
    );
  };

  const handleStepperNext = useAddFunctionToRef(() => {
    setActiveStep((step) => {
      const nextStep = step + 1;
      if (nextStep < memoSteps.length) return nextStep;
      return step;
    });
  });

  return (
    <Grid container>
      <Grid item xs={12}>
        {getStepper()}
      </Grid>
      <Grid item xs={12}>
        <Eth2DeployContent
          steps={memoSteps}
          activeStep={activeStep}
          handleStepperNext={handleStepperNext}
          t={t}
        />
      </Grid>
    </Grid>
  );
};

Eth2Deploy.propTypes = {
};

Eth2Deploy.whyDidYouRender = true;
export default React.memo(Eth2Deploy);
