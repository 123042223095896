export const HttpStatusCodes = {
  BAD_REQUEST: 400,
  AXIOS_CLIENT_ERROR: 481,
  CLIENT_INTERNAL_ERROR: 483,
  INVALID_PARAMS_TYPE: 485,
  SERVICE_UNAVAILABLE: 503,
  TCP_READ_FAILED: 515,
};

export const generalErrorMessageConverterObj = {
  'Bad Request Exception': '', // It's converted to empty string to show fallback or default error message
};
