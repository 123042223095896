import whyDidYouRender from '@welldone-software/why-did-you-render';
import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';

import UserProfileSection from './UserProfileSection';

if (process.env.REACT_APP_ENV !== 'prod') {
  whyDidYouRender(React);
}

const UserInfo = (props) => {
  const {
    isMainAccount, data, requesterId, requesterEmail,
  } = props;
  let userData;
  const userR = useSelector((state) => state.userR);
  if (isMainAccount) {
    userData = userR;
  } else {
    userData = data;
  }

  const {
    userId,
    email,
    userName,
  } = userData.user;

  const initialValues = {
    profile: {
      userId,
      email,
      // role,
      userName,
    },
  };

  return (
    <>
      <UserProfileSection
        initialValues={initialValues.profile}
        userId={userId}
        isMainAccount={isMainAccount}
        requesterId={requesterId}
        requesterEmail={requesterEmail}
      />
    </>
  );
};

UserInfo.defaultProps = {
  isMainAccount: true,
  data: null,
  requesterId: 0,
  requesterEmail: '',
};

UserInfo.propTypes = {
  /**
   * Trigger main account mode
   */
  isMainAccount: PropTypes.bool,
  /**
   * Trigger main account mode
   */
  data: PropTypes.object,
  /**
 * Requester id use for sub-user list
 */
  requesterId: PropTypes.number,
  /**
 * Requester email user for sub-user list
 */
  requesterEmail: PropTypes.string,
};

UserInfo.whyDidYouRender = true;

export default UserInfo;
