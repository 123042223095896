import * as R from 'ramda';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import ReactTableV7 from '../../Tables/ReactTableV7';
import InvestmentEarningDashboardItem from '../InvestmentNodeDetailsPageItem';

// others
import { nodeOwnerDevicesColumns } from './columns';

const MiningDevices = () => {
  const { t } = useTranslation();

  const { items: orders } = useSelector((state) => state.nodeOwnerR.nodeOwnerOrder.neunode);
  const {
    getOrdersLoading,
  } = useSelector((state) => state.loadingR);

  const dataToUse = R.pipe(
    R.map(
      R.prop('devices'),
    ),
    R.flatten,
    R.map((item) => {
      const { contract } = item;
      const { contractEndDate: endDate } = contract;
      const today = new Date();
      const contractEndDate = new Date(endDate);

      const diff = today.getTime() - contractEndDate.getTime();
      return diff < 0
        ? item
        : {
          ...item,
          deviceStatus: 'INACTIVE',
        };
    }),
  )(orders);

  const columnsToUse = nodeOwnerDevicesColumns(
    t,
  );

  return (
    <InvestmentEarningDashboardItem
      titlePadding="21px 32px 16px 32px"
      title={t('All Devices')}
      content={(
        <ReactTableV7
          loading={getOrdersLoading}
          data={dataToUse}
          columns={columnsToUse}
          customPageSize={3}
          customPageSizeList={[3, 5, 10, 20, 50]}
        />
)}
    />
  );
};

export default MiningDevices;
