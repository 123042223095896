import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import React from 'react';

const ChainInfoSocialLinkItem = (props) => {
  const {
    title, link,
  } = props;
  const isPortrait = window.screen.height > window.screen.width;
  const linkText = (link) => {
    if (isPortrait && link.length > 30) {
      return `${link.substring(0, 30)}...`;
    }
    return link;
  };
  return (
    <Grid container>
      <Grid item xs={12} sm={12} md={12} lg={6}>
        <Typography variant="body1">{title}</Typography>
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={6}>
        <Link href={link} target="_blank">
          <Typography variant="body1" noWrap style={{ textOverflow: 'ellipsis' }}>
            {linkText(link)}
          </Typography>
        </Link>
      </Grid>
    </Grid>
  );
};

ChainInfoSocialLinkItem.propTypes = {
  /**
   * social link title
   */
  title: PropTypes.string.isRequired,
  /**
   * link
   */
  link: PropTypes.string.isRequired,
};
export default ChainInfoSocialLinkItem;
