import Avatar from '@material-ui/core/Avatar';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import { makeStyles } from '@material-ui/core/styles';
import CheckOutlinedIcon from '@material-ui/icons/CheckOutlined';
import BaseIdentityIcon from '@polkadot/react-identicon';
import React from 'react';

import { addressStyles } from './styles';

const useStyles = makeStyles(addressStyles);

const MiningAddressCreationAddress = ({
  address,
  name,
  onClick,
  selectedAddress,
}) => {
  const classes = useStyles();

  const showCheckmark = selectedAddress === address;
  return (
    <ListItem onClick={() => onClick(address)} className={classes.listItem}>
      <ListItemAvatar>
        <Avatar>
          <BaseIdentityIcon
            size={48}
            value={address}
          />
        </Avatar>
      </ListItemAvatar>
      <ListItemText
        primary={name}
        secondary={address}
      />
      {showCheckmark && (
      <ListItemSecondaryAction>
        <CheckOutlinedIcon />
      </ListItemSecondaryAction>
      )}
    </ListItem>
  );
};

export default MiningAddressCreationAddress;
