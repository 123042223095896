import * as adminOrderOperations from './operations';
import reducer from './reducers';
import * as adminOrderSelectors from './selectors';
import * as adminOrderTypes from './types';

export {
  adminOrderOperations,
  adminOrderSelectors,
  adminOrderTypes,
};

export default reducer;
