import grey from '@material-ui/core/colors/grey';

export const connectToWalletStyles = (theme) => ({

});

export const selectConnectorsStyles = (theme) => ({
  rootBox: {
    ...theme.border.default,
  },
});

export const connectorStyles = (theme) => ({
  itemBox: {
    ...theme.border.default,
    cursor: 'pointer',
    width: 640,
    [theme.breakpoints.down('lg')]: {
      width: 480,
    },
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  connectorLogo: {
    width: 50,
  },
});
export const connectorWrapperStyles = (theme) => ({
  connectorBox: {
    width: '100%',
    [theme.breakpoints.down('xs')]: {
      height: 'auto',
      border: 'none',
    },
  },
});

export const transactionDetailStyle = (theme) => ({
  body1Bold: {
    fontWeight: 500,
  },
  noMarginButton: {
    margin: theme.spacing(0),
    padding: theme.spacing(0),
  },
  addressOverflow: {
    [theme.breakpoints.down('xs')]: {
      width: '75%',
      textOverflow: 'ellipsis',
    },
  },
  depositContractGrid: {
    width: '100%',
  },
});

export const yourWalletStyles = (theme) => ({
  cardHeader: {
    padding: theme.spacing(0),
  },
  stepLabel: {
    fontWeight: 500,
  },
  cardActions: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  root: {
    position: 'relative',
    margin: theme.spacing(1),
    cursor: 'pointer',
  },
  card: {
    position: 'absolute',
    top: 50,
    right: 0,
    left: 0,
    zIndex: 1,
    padding: theme.spacing(1),
    backgroundColor: grey[200],
  },
  walletAddress: {
    wordBreak: ' break-all',
  },
  paper: {
    padding: theme.spacing(1),
    backgroundColor: grey[300],
    borderRadius:　40,
  },
});
