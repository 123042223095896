import * as dot from './dot/index';
import * as zen from './zen';

const coins = {
  zen,
  dot,
};
export {
  coins,
};
