/**
 *************************************************************************
 * Following code snippet must only be used during unit tests.           *
 * Using the code snippet while running app will cause build time error. *
 *************************************************************************
 */

/* import { addMocksToSchema } from '@graphql-tools/mock';
import { ApolloClient, InMemoryCache } from '@apollo/client';
import { SchemaLink } from '@apollo/client/link/schema';
import GraphQLJSON, { GraphQLJSONObject } from 'graphql-type-json';
import { GraphQLUpload } from 'graphql-upload';
import { schema } from './schema/schema';
import { userMutationResolver } from './schema/user_schema';
import { graphQLDataTypeMockValues } from '../appConstants';

const {
  mockId,
  mockString,
  mockInt,
  mockFloat,
  mockBoolean,
  mockJson,
  mockJsonObject,
} = graphQLDataTypeMockValues;

const mocks = {
  ID: () => mockId,
  Int: () => mockInt,
  Float: () => mockFloat,
  String: () => mockString,
  Boolean: () => mockBoolean,
  JSON: () => mockJson,
  JSONObject: () => mockJsonObject,
  UserRole: () => 'USER',
  NodeType: () => 'ETH2_VALIDATOR_NODE',
  OrderTypeEnum: () => 'NAAS',
  TimelineStepName: () => 'ORDER',
  TimelineStepStatus: () => 'COMPLETE',
  CurrencySymbol: () => 'USD',
  PaymentMethod: () => 'FIAT',
  PaymentTypeEnum: () => 'ONETIME',
  DeviceStatus: () => 'ACTIVE',
  CoinType: () => 'USDT',
};

const schemaWithMocks = addMocksToSchema({
  schema,
  mocks,
  resolvers: () => ({
    JSON: GraphQLJSON,
    JSONObject: GraphQLJSONObject,
    Upload: GraphQLUpload,
    Mutation: {
      ...userMutationResolver,
    },
  }),
});

const apolloCache = new InMemoryCache(window.__APOLLO_STATE__);

const defaultOptions = {
  query: {
    fetchPolicy: 'network-only',
    errorPolicy: 'all',
  },
  mutate: {
    errorPolicy: 'all',
  },
};

export const mockClient = new ApolloClient({
  cache: apolloCache,
  link: new SchemaLink({ schema: schemaWithMocks }),
  defaultOptions,
}); */

/**
 *******************************************************************
 * Following code snippet must only be used while running the app. *
 * For unit testing the code snippet above must be used.           *
 *******************************************************************
 */
export const mockClient = {
  query: (param) => param,
  mutate: (param) => param,
};
