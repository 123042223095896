import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormLabel from '@material-ui/core/FormLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import * as RA from 'ramda-adjunct';
import React from 'react';

const useStyles = makeStyles((theme) => ({
  formControl: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  radioGroup: {
    display: 'flex',
    flexDirection: 'row',
  },
}));

export default function RadioButtonCustom(props) {
  const classes = useStyles();
  const {
    data, formLabel, ariaLable, radioGroupName, helperText, defaultValue, onChange,
  } = props;
  const [value, setValue] = React.useState('');
  const handleChange = (event) => {
    const { value } = event.target;
    setValue(value);
    onChange(value);
  };
  React.useEffect(() => {
    if (defaultValue) setValue(defaultValue);
  }, [defaultValue]);
  return (
    <FormControl component="fieldset" className={classes.formControl}>
      { formLabel
          && <FormLabel component="legend">{formLabel}</FormLabel>}
      <RadioGroup className={classes.radioGroup} aria-label={ariaLable} name={radioGroupName} value={value} onChange={handleChange}>
        {
          RA.toArray(data).map((item) => {
            const { disabled, value, label } = item;
            const disableToUse = disabled === undefined ? false : disabled;
            return (
              <FormControlLabel
                key={`radio_button_form_control_label_${label}`}
                value={value}
                disabled={disableToUse}
                control={<Radio color="primary" />}
                label={label}
              />
            );
          })
        }

      </RadioGroup>
      { helperText
          && <FormHelperText>{helperText}</FormHelperText>}

    </FormControl>
  );
}
RadioButtonCustom.defaultProps = {
  ariaLable: 'radio group',
  radioGroupName: 'radio-group',
  defaultValue: '',
  data: [],
  helperText: '',
  onChange: () => {},
};
RadioButtonCustom.propTypes = {
  formLabel: PropTypes.string.isRequired,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      disabled: PropTypes.bool,
      value: PropTypes.string,
      label: PropTypes.string,
    }),
  ),
  ariaLable: PropTypes.string,
  radioGroupName: PropTypes.string,
  helperText: PropTypes.string,
  defaultValue: PropTypes.string,
  onChange: PropTypes.func,
};
