import { combineReducers } from 'redux';

import {
  createReducer,
  updateStateWithKey,
} from '../store_utils';

import * as types from './types';

const langSettingIS = {
  lang: 'en',
};

const langSettingRUpdaters = {
  [types.SET_LANG]: (
    state,
    payload,
  ) => updateStateWithKey(
    'lang',
    state,
    payload,
  ),
};

const langSetting = createReducer(langSettingIS, langSettingRUpdaters);

const reducers = combineReducers({
  langSetting,
});

export default reducers;
