import {
  pipeAwait,
} from 'jsutils';
import isEmpty from 'lodash/isEmpty';
import * as R from 'ramda';

import { generateHash } from '../../components/MarketProtocolDeploy/utils';
import {
  graphqlNormalize,
} from '../../modules/apollo/apollo';
import { setItem } from '../../modules/browser_utils';
import {
  returnAction,
} from '../store_utils';

import {
  graphqlUpdateAuthToken,
} from './graphql/services';
import * as types from './types';

/**
 * Returns action to set auth token status as outdated
 * @return     {import('../typedefs').ReduxAction}
 */
export const setAuthTokenStatusOutdated = () => ({
  type: types.SET_AUTH_TOKENS_STATUS_OUTDATED,
  payload: true,
});

/**
 * Returns action to set auth token status as updated
 * @return     {import('../typedefs').ReduxAction}
 */
export const setAuthTokenStatusUpdated = () => ({
  type: types.SET_AUTH_TOKENS_STATUS_UPDATED,
  payload: false,
});

/**
 * Returns action to set 'fetchingNewAuthToken' to true
 * @return     {import('../typedefs').ReduxAction}
 */
export const setFetchNewAuthTokenRequestStatus = () => ({
  type: types.SET_FETCH_NEW_AUTH_TOKEN_REQUEST_STATUS,
  payload: 'LOADING',
});

/**
 * Returns action to set 'fetchingNewAuthToken' to true
 * @return     {import('../typedefs').ReduxAction}
 */
export const clearFetchNewAuthTokenRequestStatus = () => ({
  type: types.CLEAR_FETCH_NEW_AUTH_TOKEN_REQUEST_STATUS,
  payload: {},
});

/**
 * Update the auth tokens data from server
 * @param  {import('./typedefs').RefrehTokenUrl} refreshTokenUrl
 * @param  {import('./typedefs').UserId} userId
 * @return {import('../typedefs').ReduxAction}
 */
export const updateAuthTokenData = R.curry(async (userId, email, refreshToken, idToken) => {
  if (!userId || !email || !refreshToken || !idToken) {
    throw new Error('Parameter is empty');
  }

  return pipeAwait(
    graphqlUpdateAuthToken,
    graphqlNormalize('updateAuthToken'),
    returnAction(types.UPDATE_AUTH_TOKEN_SUCCESS),
  )({
    userId, email, refreshToken, idToken,
  });
});

/**
 * Update tokens data in browser local storage
 * @param  {import('./typedefs').ItemKey}  key
 * @param  {import('./typedefs').AuthTokenDataToStore} authTokenData
 * @return {import('../typedefs').ReduxAction}
 */
export const updateTokenDataInStorage = R.curry((key, authTokenData) => {
  if (isEmpty(authTokenData)) {
    // Since this action is not passed into dispatchAsync method returnRejectAction method
    // will not lead to throwing an error from dispatchAsync. So we need to directly throw
    // from here.
    throw new Error('authTokenData must not be empty');
  }
  R.pipe(
    (data) => data.authTokenData,
    R.pick(['accessToken', 'refreshToken', 'accessTokenExpiry', 'refreshTokenExpiry', 'idToken']),
    setItem(key),
  )(authTokenData);

  return setAuthTokenStatusUpdated();
});

/**
 * Auth token update failure action.
 *
 * @return     {import('../typedefs').ReduxAction}
 */
export const updateAuthTokenFailed = () => ({
  type: types.UPDATE_AUTH_TOKEN_FAILED,
  payload: false,
});

export const electLeaderTab = () => ({
  type: types.ELECT_LEADER_TAB,
  payload: `tab-${generateHash()}`,
});
