import Box from '@material-ui/core/Box';
import Dialog from '@material-ui/core/Dialog';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import AutorenewOutlined from '@material-ui/icons/AutorenewOutlined';
import whyDidYouRender from '@welldone-software/why-did-you-render';
import toNumber from 'lodash/fp/toNumber';
import moment from 'moment';
import * as R from 'ramda';
import React, {
  useEffect,
  useState,
  useRef,
  useCallback,
  useMemo,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

// others
// store

import {
  useAddFunctionToRef,
  useIsMobile,
  useGetQuery,
  useUserDataSelector,
} from '../../../hooks/hooks';
import { infinite } from '../../../modules/appConstants';

// columns

// routes
import { dashboardRoute } from '../../../routes';
import { poolOperations } from '../../../store/pool/index';
import { routerServices } from '../../../store/router/index';
import FailPage from '../../FailPage/FailPage';
import SuccessPage from '../../SuccessPage/SuccessPage';
import Actions from '../../Tables/Actions';
import ReactTableV7ReactWindow from '../../Tables/ReactTableV7ReactWindow';
import { comparePropsWithTranslaitonMethod } from '../utils';

import PoolDetails from './PoolDetails';
import { poolListColumns } from './columns';
import { poolListStyles } from './styles';

import { UPDATE_POOLS_SUCCESS } from 'store/pool/types';

if (process.env.REACT_APP_ENV !== 'prod') {
  whyDidYouRender(React);
}

const useStyles = makeStyles(poolListStyles);

const PoolList = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();
  const isMobileDevice = useIsMobile();
  const history = useHistory();
  const queryLng = useGetQuery('lng');
  const { role } = useUserDataSelector();
  const [poolDetailsDialogOpen, setPoolDetailsDialogOpen] = useState(false);
  const [poolToViewDetail, setPoolToViewDetail] = useState({});

  const onGetPools = useAddFunctionToRef(async () => {
    await dispatch(poolOperations.getPools(
      [{}],
      {
        page: 1,
        limit: infinite,
      },
    ));
  }, []);

  const getPoolsLoading = useSelector((state) => state.loadingR.getPoolsLoading);
  const poolList = useSelector((state) => state.poolR.pools.phala.items);

  const [statusDialogName, setStatusDialogName] = useState('');
  const [statusDialog, setStatusDialog] = useState(false);
  const handleStatusDialogClose = () => {
    setStatusDialog('');
  };
  const handleStatusDialogOpen = (status) => {
    setStatusDialog(status);
  };

  const handlePoolDetailsDialogClose = useCallback(() => {
    setPoolDetailsDialogOpen(false);
  }, []);

  const handlePoolDetailsButtonClick = useCallback((poolData) => {
    const pool = {
      ...poolData,
      dateCreated: moment(poolData.dateCreated),
      dateUpdated: moment(poolData.dateCreated),
      poolData: {
        ...poolData.poolData,
        machineNum: toNumber(R.view(R.lensPath(['poolData', 'machineNum']), poolData)),
        activeMachineNum: toNumber(R.view(R.lensPath(['poolData', 'activeMachineNum']), poolData)),
        commission: toNumber(R.view(R.lensPath(['poolData', 'commission']), poolData)),
        unitCap: toNumber(R.view(R.lensPath(['poolData', 'unitCap']), poolData)),
        dateCreated: moment(R.view(R.lensPath(['poolData', 'dateCreted']), poolData)),
        dateUpdated: moment(R.view(R.lensPath(['poolData', 'dateUpdated']), poolData)),
      },
    };
    setPoolToViewDetail(pool);
    setPoolDetailsDialogOpen(true);
  }, []);

  const actionButtons = useMemo(() => (
    [
      {
        label: t('view details'),
        buttonClickHandler: handlePoolDetailsButtonClick,
      },
    ]
  ), [t, handlePoolDetailsButtonClick]);

  const viewPoolActions = useCallback((data, index) => (
    <Actions
      index={index}
      buttons={actionButtons}
      data={data}
    />
  ),
  [actionButtons]);

  const columnsToUse = useMemo(() => poolListColumns(
    t,
    isMobileDevice,
    viewPoolActions,
  ), [t, isMobileDevice, viewPoolActions]);

  // If user is not admin redirect user to normal dashboard route
  useEffect(() => {
    if (role !== 'ADMIN') {
      routerServices.historyPush(
        history,
        {
          pathname: dashboardRoute,
          queryLng,
        },
      );
    }
  }, [history, role, queryLng]);

  useEffect(() => {
    onGetPools();
  }, [onGetPools]);

  const ref = useRef({
    emptyArray: [],
    columnsToHide: [],
    rerenderCount: 0,
  });
  const {
    emptyArray,
    columnsToHide,
  } = ref.current;
  const poolListToUse = !R.isEmpty(poolList) ? R.pipe(
    R.sort(R.curry((poolA, poolB) => {
      const { poolData: poolDataA } = poolA;
      const { machineNum: machineNumA, activeMachineNum: activeMachinNumA } = poolDataA;
      const { poolData: poolDataB } = poolB;
      const { machineNum: machineNumB, activeMachineNum: activeMachinNumB } = poolDataB;

      return (machineNumB - activeMachinNumB) - (machineNumA - activeMachinNumA);
    })),
    R.map((pool) => {
      const { poolData, user } = pool;
      const { email } = user;
      const { commission, machineNum, activeMachineNum } = poolData;

      const result = {
        email,
        commission,
        machineNum,
        activeMachineNum,
        ...pool,
      };

      if (machineNum > activeMachineNum) {
        return R.assoc('rowStyle', { backgroundColor: 'bisque' }, result);
      }

      return result;
    }),
  )(poolList) : emptyArray;
  const loading = getPoolsLoading;
  const hiddenColumns = isMobileDevice ? columnsToHide : emptyArray;
  const getContent = () => (
    <Paper className={classes.table}>
      <ReactTableV7ReactWindow
        showToolbar
        loading={loading}
        title={(
          <Box display="flex" alignContent="center">
            <Box pr={1}>
              {t('Pool List')}
            </Box>
            <Box mb={0.25}>
              <IconButton
                onClick={onGetPools}
                className={classes.reFetchButton}
                disabled={loading}
              >
                <AutorenewOutlined color={loading ? 'disabled' : 'primary'} />
              </IconButton>
            </Box>
          </Box>
            )}
        scope="getPools"
        data={poolListToUse}
        columns={columnsToUse}
        hiddenColumns={hiddenColumns}
      />
      {/* <PoolDetails
        data={poolToViewDetail}
        open={poolDetailsDialogOpen}
        onClose={handlePoolDetailsDialogClose}
        t={t}
      /> */}
    </Paper>
  );

  const statusDialogTexts = {
    updatePoolSuccess: {
      primaryText: t('THANK YOU!'),
      message: t('Pool has been updated.'),
    },
    updatePoolFail: {
      primaryText: t('SORRY!'),
      message: t('Pool update failed. Please try again.'),
    },
  };

  const statusDialogText = R.prop(statusDialogName, statusDialogTexts) || {
    primaryText: '',
    message: '',
  };

  const handleUpdatePoolSubmit = useCallback(async (data) => {
    const dataToUpdate = R.omit([
      'poolId',
      'user',
      '__typename',
      'rowStyle',
      'email',
      'commission',
      'machineNum',
      'activeMachineNum',
    ], data);
    const { poolId, poolData, dateCreated: poolDateCreated } = data;
    const {
      poolDataId, commission, unitCap, machineNum, activeMachineNum, dateCreated: poolDataDateCreated, payoutAddress,
    } = poolData;

    const { type } = await dispatch(poolOperations.updatePools([
      {
        whereQuery: {
          poolId,
          poolData: {
            poolDataId,
          },
        },
        data: {
          ...dataToUpdate,
          dateCreated: poolDateCreated.toISOString(),
          dateUpdated: moment().toISOString(),
          poolData: {
            ...R.omit(['poolDataId', 'poolId', 'userId', '__typename'], poolData),
            dateCreated: poolDataDateCreated.toISOString(),
            dateUpdated: moment().toISOString(),
            commission: commission.toString(),
            unitCap: unitCap.toString(),
            machineNum: toNumber(machineNum),
            activeMachineNum: toNumber(activeMachineNum),
            payoutAddress: payoutAddress === '' ? null : payoutAddress,
          },
        },
      },
    ]));

    if (type === UPDATE_POOLS_SUCCESS) {
      onGetPools();
      handlePoolDetailsDialogClose();
      handleStatusDialogOpen('success');
      setStatusDialogName('updatePoolSuccess');
    } else {
      handleStatusDialogOpen('failed');
      setStatusDialogName('updatePoolFail');
    }
  }, [dispatch, handlePoolDetailsDialogClose, onGetPools]);

  return (
    <>
      {getContent()}
      <PoolDetails
        initialValues={poolToViewDetail}
        onClose={handlePoolDetailsDialogClose}
        open={poolDetailsDialogOpen}
        onCancelButtonClick={handlePoolDetailsDialogClose}
        onPoolUpdate={handleUpdatePoolSubmit}
        t={t}
      />
      <Dialog open={statusDialog === 'success'} onClose={handleStatusDialogClose} fullWidth maxWidth="sm">
        <Box className={classes.statusDialog}>
          <SuccessPage
            onCancel={handleStatusDialogClose}
            primaryText={R.prop('primaryText', statusDialogText)}
            message={R.prop('message', statusDialogText)}
            btnText={t('close')}
          />
        </Box>
      </Dialog>
      <Dialog open={statusDialog === 'fail'} onClose={handleStatusDialogClose} fullWidth maxWidth="sm">
        <Box className={classes.statusDialog}>
          <FailPage
            onCancel={handleStatusDialogClose}
            primaryText={R.prop('primaryText', statusDialogText)}
            message={R.prop('message', statusDialogText)}
            btnText={t('close')}
          />
        </Box>
      </Dialog>
    </>
  );
};

PoolList.defaultProps = {

};

PoolList.propTypes = {
};

PoolList.whyDidYouRender = true;

export default React.memo(PoolList, comparePropsWithTranslaitonMethod);
