// lodash

import Box from '@material-ui/core/Box';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
// import Button from '@material-ui/core/Button';
// import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import ErrorOutlineRounded from '@material-ui/icons/ErrorOutlineOutlined';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
// import GetAppOutlinedIcon from '@material-ui/icons/GetAppOutlined';

// others
import getSymbolFromCurrency from 'currency-symbol-map';
import startCase from 'lodash/startCase';
import toLower from 'lodash/toLower';
import toNumber from 'lodash/toNumber';
import upperCase from 'lodash/upperCase';
import * as R from 'ramda';
import React from 'react';
import Moment from 'react-moment';

import PaymentInvoiceIconSvg from '../Svg/SidebarIcons/PaymentInvoiceIconSvg';

export const paymentPagePaymentHistoryColumns = (
  t,
  handlePaymentDetailOpen,
) => [
  {
    Header: t('Id'),
    accessor: 'id',
    Cell: (data) => {
      const {
        id,
      } = data.row.original;
      return (
        <Grid container direction="row" alignItems="center">
          {id}
        </Grid>
      );
    },
  },
  {
    Header: t('Total Amount'),
    accessor: 'amount',
    Cell: (data) => {
      const { amount, currency } = data.row.original;
      return (
        <Grid container direction="row" alignItems="center">
          {`${toNumber(amount).toLocaleString('en-US', {
            style: 'currency',
            currency: currency || 'JPY',
          })}`}
        </Grid>
      );
    },
  },
  {
    Header: t('Payment Method'),
    accessor: 'paymentMethod',
    Cell: (data) => {
      const {
        metadata: {
          paymentMethod,
        },
      } = data.row.original;
      return (
        <Grid container direction="row" alignItems="center">
          {startCase(toLower(paymentMethod))}
        </Grid>
      );
    },
  },
  {
    Header: t('Order date'),
    accessor: 'created',
    Cell: (data) => {
      const { created } = data.row.original;
      return (
        <Grid container direction="row" alignItems="center">
          <Moment format="MMM D, YYYY">
            {created}
          </Moment>
        </Grid>
      );
    },
  },
  {
    Header: t('Payment date'),
    accessor: 'paymenDate',
    Cell: (data) => {
      const {
        invoice: {
          statusTransitions,
          status,
        },
      } = data.row.original;
      const {
        paidAt,
      } = statusTransitions;
      return (
        <Grid container direction="row" alignItems="center">
          {paidAt ? (
            <Moment format="MMM D, YYYY">
              {paidAt}
            </Moment>
          ) : status}
        </Grid>
      );
    },
  },
  {
    Header: '',
    accessor: 'actions',
    disableSortBy: true,
    disableFilters: true,
    Cell: (data) =>
      // const {
      //   invoice: {
      //     invoicePdf,
      //   },
      // } = data.row.original;
      (
        <Grid container alignItems="center">
          <Grid item>
            <IconButton onClick={() => handlePaymentDetailOpen(data.row.index)}>
              <PaymentInvoiceIconSvg style={{ width: '24px', height: '24px' }} />
            </IconButton>
          </Grid>
          {/* <Grid item>
            <IconButton onClick={() => window.open(invoicePdf, '_blank')}>
              <GetAppOutlinedIcon />
            </IconButton>
          </Grid> */}
        </Grid>
      )
    ,
  },
];

export const paymentPagePaymentDetailColumns = (
  t,
  lang,
  trialEnd,
  openAmmountInfoIndex,
  handleAmmountInfoOpen,
  handleAmmountInfoClose,
) => [
  {
    Header: t('Description'),
    accessor: 'order',
    Cell: (data) => {
      const { periodStart, periodEnd, metadata } = data.row.original;
      const { name } = metadata;
      const periodEndDate = new Date(periodEnd).getTime() / 1000;
      const trialEndDate = new Date(trialEnd).getTime() / 1000;
      return (
        <Grid container direction="column">
          <Grid item>
            <Box
              display="flex"
              alignItems="center"
              color="text.secondary"
            >
              <Box>
                <Moment format="MMM D, YYYY">
                  {periodStart}
                </Moment>
              </Box>
              <Box ml={0.5} mr={0.5}>
                ~
              </Box>
              <Box>
                <Moment format="MMM D, YYYY">
                  {periodEndDate <= trialEndDate ? trialEnd : periodEnd}
                </Moment>
              </Box>
            </Box>
          </Grid>
          <Grid item>
            {periodEndDate <= trialEndDate ? t('Trial period') : name}
          </Grid>
        </Grid>
      );
    },
  },
  {
    Header: t('QTY'),
    accessor: 'quantity',
    Cell: (data) => {
      const { quantity } = data.row.original;
      return (
        <Grid container direction="row" alignItems="center">
          {quantity}
        </Grid>
      );
    },
  },
  {
    Header: t('Unit price'),
    accessor: 'pricePerUnit',
    Cell: (data) => {
      const { currency, pricePerUnit } = data.row.original;
      return (
        <Grid container direction="row" alignItems="center">
          {`${getSymbolFromCurrency(upperCase(currency))}${pricePerUnit}`}
        </Grid>
      );
    },
  },
  {
    Header: t('Amount'),
    accessor: 'amount',
    Cell: (data) => {
      const { currency, amount } = data.row.original;
      const currencySymbol = getSymbolFromCurrency(upperCase(currency));
      const hasAmount = toNumber(amount) >= 0;
      const minus = hasAmount
        ? ''
        : '-';
      const ammountToDisplay = `${minus}${currencySymbol}${Math.abs(amount)}`;
      const enHint = hasAmount
        ? `You paid ${ammountToDisplay}`
        : `A discount coupon or free trial code was applied. We refunded ${currencySymbol}${Math.abs(amount)}`;
      const viHint = hasAmount
        ? `Bạn thanh toán ${ammountToDisplay}`
        : `Mã coupon hoặc dùng thử đã được áp dụng. Chúng tôi hoàn lại ${currencySymbol}${Math.abs(amount)}`;
      const zhCNHint = hasAmount
        ? `您付了 ${ammountToDisplay}`
        : `您使用了优惠券或免费试用代码。我们已退还 ${currencySymbol}${Math.abs(amount)}`;
      const zhTWHint = hasAmount
        ? `您付了 ${ammountToDisplay}`
        : `您使用了優惠券或免費試用代碼。我們已退還 ${currencySymbol}${Math.abs(amount)}`;
      const jaHint = hasAmount
        ? `${ammountToDisplay} がお支払い済み`
        : `割引クーポンまたは無料トライアルコードが適用されました。${currencySymbol}${Math.abs(amount)}が返金されました`;

      const hints = {
        en: enHint,
        vi: viHint,
        ja: jaHint,
        'zh-CN': zhCNHint,
        'zh-TW': zhTWHint,
      };
      const getHints = R.curry(
        (hints, lang) => hints[lang] || hints.en,
      );
      return (
        <>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            flexDirection="row"
          >
            <Box
              m={1 / 2}
            >
              <Typography variant="body2" align="center">
                {ammountToDisplay}
              </Typography>
            </Box>
            <Box
              m={1 / 2}
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <Tooltip
                placement="top"
                interactive
                open={data.row.index === openAmmountInfoIndex}
                disableFocusListener
                disableHoverListener
                disableTouchListener
                title={(
                  <ClickAwayListener onClickAway={handleAmmountInfoClose}>
                    <Typography>
                      {getHints(hints, lang)}
                    </Typography>
                  </ClickAwayListener>
              )}
              >
                <ErrorOutlineRounded
                  color="disabled"
                  onClick={() => handleAmmountInfoOpen(data.row.index)}
                  onMouseOver={() => handleAmmountInfoOpen(data.row.index)}
                  onMouseOut={handleAmmountInfoClose}
                  style={{ fontSize: 20 }}
                />
              </Tooltip>

            </Box>
          </Box>

        </>
      );
    },
  },
  {
    Header: t('Status'),
    accessor: 'status',
    Cell: (data) => (
      <Grid container direction="row" alignItems="center">
        {data.value}
      </Grid>
    ),
  },
];

export const paymentPageSubscriptionHistoryColumns = (
  classes,
  openMenuIndex,
  handleMenuClose,
  handleMenuOpen,
  handleSubscriptionDetailOpen,
  handleChangePlanOpen,
  handleVerifyPasswordOpen,
  t,
) => [
  {
    Header: t('Subscription'),
    accessor: 'name',
    Cell: (data) => {
      const {
        metadata: {
          name,
        },
      } = data.row.original;
      return (
        <Grid container direction="row" alignItems="center">
          {name}
        </Grid>
      );
    },
  },
  {
    Header: t('QTY'),
    accessor: 'quantity',
    Cell: (data) => {
      const { quantity } = data.row.original;
      return (
        <Grid container direction="row" alignItems="center">
          {quantity}
        </Grid>
      );
    },
  },
  {
    Header: t('Start date'),
    accessor: 'startDate',
    Cell: (data) => {
      const { startDate } = data.row.original;
      return (
        <Grid container direction="row" alignItems="center">
          <Moment format="MMM D, YYYY">
            {startDate}
          </Moment>
        </Grid>
      );
    },
  },
  {
    Header: t('Next invoice'),
    accessor: 'nextInvoice',
    Cell: (data) => {
      const { upcomingInvoice, status } = data.row.original;
      const {
        amountDue,
        currency,
        created,
      } = upcomingInvoice;
      const NextInvoice = !created || status === 'paused'
        ? (

          <Box>
            <span>
              -
            </span>
          </Box>
        )
        : (
          <Box
            display="flex"
            flexDirection="column"
          >
            <Box mr={0.5}>
              {`${toNumber(amountDue).toLocaleString('en-US', {
                style: 'currency',
                currency: currency || 'JPY',
              })}`}
            </Box>
            <Box>
              on
              {' '}
              <Moment format="MMM D, YYYY">
                {created}
              </Moment>
            </Box>
          </Box>

        );
      return (
        <Grid container direction="row" alignItems="center">
          {NextInvoice}
        </Grid>
      );
    },
  },
  {
    Header: t('Status'),
    accessor: 'status',
    Cell: (data) => (
      <Grid container direction="row" alignItems="center">
        {data.value}
      </Grid>
    ),
  },
  {
    Header: '',
    accessor: 'actions',
    disableSortBy: true,
    disableFilters: true,
    Cell: (data) => (
      <Grid container direction="row" alignItems="center">
        {/* <Tooltip
            placement="right-start"
            interactive
            open={data.row.index === openMenuIndex}
            disableFocusListener
            disableHoverListener
            disableTouchListener
            classes={{
              tooltip: classes.customTooltip,
              arrow: classes.customArrow,
            }}
            title={(
              <ClickAwayListener onClickAway={handleMenuClose}>
                <Box>
                  <Button
                    onClick={() => handleSubscriptionDetailOpen(data.row.index)}
                    className={classes.menuButton}
                  >
                    <Typography
                      variant="body1"
                      style={{ overflow: 'visible' }}
                    >
                      {t('Invoices details')}
                    </Typography>
                  </Button>
                  <Button
                    onClick={() => handleChangePlanOpen(data.row.index)}
                    className={classes.menuButton}
                    disabled={status === 'canceled'}
                  >
                    <Typography
                      variant="body1"
                      style={{ overflow: 'visible' }}
                    >
                      {t('Change plan')}
                    </Typography>
                  </Button>
                  <Divider />
                  <Button
                    onClick={() => handleVerifyPasswordOpen(data.row.index)}
                    className={classes.menuButton}
                    disabled={status === 'canceled'}
                  >
                    <Typography
                      variant="caption"
                      noWrap
                      color={status === 'canceled' ? 'inherit' : 'error'}
                      style={{ overflow: 'visible' }}
                    >
                      {t('Cancel subscription')}
                    </Typography>
                  </Button>}
                </Box>
              </ClickAwayListener>
          )}
          > */}
        <IconButton onClick={() => handleSubscriptionDetailOpen(data.row.index)}>
          <MoreHorizIcon />
        </IconButton>
        {/* </Tooltip> */}
      </Grid>
    ),
  },
];

export const paymentPageSubscriptionDetailColumns = (
  t,
  lang,
  name,
  trialEnd,
  openAmmountInfoIndex,
  handleAmmountInfoOpen,
  handleAmmountInfoClose,
) => [
  {
    Header: t('Description'),
    accessor: 'order',
    Cell: (data) => {
      const { start: periodStart, end: periodEnd } = R.view(R.lensPath(['lines', 'data', 0, 'period']), data.row.original);
      const periodEndDate = toNumber(periodEnd) * 1000;
      const periodStartDate = toNumber(periodStart) * 1000;
      const trialEndDate = new Date(trialEnd).getTime() / 1000;
      return (
        <Grid container direction="column">
          <Grid item>
            <Box
              display="flex"
              alignItems="center"
              color="text.secondary"
            >
              <Box>
                <Moment format="MMM D, YYYY">
                  {periodStartDate}
                </Moment>
              </Box>
              <Box ml={0.5} mr={0.5}>
                ~
              </Box>
              <Box>
                <Moment format="MMM D, YYYY">
                  {toNumber(periodEnd) <= trialEndDate ? trialEnd : periodEndDate}
                </Moment>
              </Box>
            </Box>
          </Grid>
          <Grid item>
            {toNumber(periodEnd) <= trialEndDate ? t('Trial period') : name}
          </Grid>
        </Grid>
      );
    },
  },
  {
    Header: t('QTY'),
    accessor: 'quantity',
    Cell: (data) => {
      const quantity = R.view(R.lensPath(['lines', 'data', 0, 'quantity']), data.row.original);
      return (
        <Grid container direction="row" alignItems="center">
          {quantity}
        </Grid>
      );
    },
  },
  {
    Header: t('Unit price'),
    accessor: 'pricePerUnit',
    Cell: (data) => {
      const { currency, unitAmount } = R.view(R.lensPath(['lines', 'data', 0, 'price']), data.row.original);
      return (
        <Grid container direction="row" alignItems="center">
          {toNumber(unitAmount).toLocaleString('en-US', {
            style: 'currency',
            currency,
          })}
        </Grid>
      );
    },
  },
  {
    // i18next-extract-mark-context-next-line ["invoice"]
    Header: t('Amount', { context: 'invoice' }),
    accessor: 'amount',
    Cell: (data) => {
      const { currency, amountDue } = data.row.original;
      const price = toNumber(amountDue).toLocaleString('en-US', {
        style: 'currency',
        currency,
      });
      const hasAmount = toNumber(amountDue) >= 0;
      const minus = hasAmount
        ? ''
        : '-';
      const ammountToDisplay = `${minus}${price}`;
      const enHint = hasAmount
        ? `You paid ${ammountToDisplay}`
        : `A discount coupon or free trial code was applied. We refunded ${price}`;
      const viHint = hasAmount
        ? `Bạn thanh toán ${ammountToDisplay}`
        : `Mã coupon hoặc dùng thử đã được áp dụng. Chúng tôi hoàn lại ${price}`;
      const zhCNHint = hasAmount
        ? `您付了 ${ammountToDisplay}`
        : `您使用了优惠券或免费试用代码。我们已退还 ${price}`;
      const zhTWHint = hasAmount
        ? `您付了 ${ammountToDisplay}`
        : `您使用了優惠券或免費試用代碼。我們已退還 ${price}`;
      const jaHint = hasAmount
        ? `${ammountToDisplay} がお支払い済み`
        : `割引クーポンまたは無料トライアルコードが適用されました。${price}が返金されました`;

      const hints = {
        en: enHint,
        vi: viHint,
        ja: jaHint,
        'zh-CN': zhCNHint,
        'zh-TW': zhTWHint,
      };
      const getHints = R.curry(
        (hints, lang) => hints[lang] || hints.en,
      );
      return (
        <>
          <Box
            display="flex"
            flexDirection="row"
          >
            <Box
              mr={0.5}
            >
              <Typography variant="body2" align="center">
                {ammountToDisplay}
              </Typography>
            </Box>
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <Tooltip
                placement="top"
                interactive
                open={data.row.index === openAmmountInfoIndex}
                disableFocusListener
                disableHoverListener
                disableTouchListener
                title={(
                  <ClickAwayListener onClickAway={handleAmmountInfoClose}>
                    <Typography>
                      {getHints(hints, lang)}
                    </Typography>
                  </ClickAwayListener>
              )}
              >
                <ErrorOutlineRounded
                  color="disabled"
                  onClick={() => handleAmmountInfoOpen(data.row.index)}
                  onMouseOver={() => handleAmmountInfoOpen(data.row.index)}
                  onMouseOut={handleAmmountInfoClose}
                  style={{ fontSize: 20 }}
                />
              </Tooltip>

            </Box>
          </Box>

        </>
      );
    },
  },
  {
    Header: t('Status'),
    accessor: 'status',
    Cell: (data) => (
      <Grid container direction="row" alignItems="center">
        {data.value}
      </Grid>
    ),
  },
];
