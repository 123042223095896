export const ununifiValidatorOverviewStyles = (theme) => {
  const h3Properties = {
    fontSize: theme.typography.h3.fontSize,
    fontWeight: theme.typography.h3.fontWeight,
  };
  return ({
    container: {
      padding: theme.spacing(0, 2, 1, 2),
      minWidth: '100px',
      ...theme.centerComponent,
    },
    dataWithCircleBg: {
      color: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
    },
    dataWithCircle: {
      strokeLinecap: 'round',
      color: theme.nodeColors.UnUniFi,
    },
    percent: {
      ...h3Properties,
    },
    totalEarning: {
      fontSize: theme.typography.h2.fontSize,
      fontWeight: theme.typography.h2.fontWeight,
    },
    overviewText: {
      ...h3Properties,
    },
    percentTextAsc: {
      ...h3Properties,
      color: theme.palette.green.main,
    },
    percentTextDesc: {
      ...h3Properties,
      color: theme.palette.error.main,
    },
    stack2Top: {
      paddingBottom: theme.spacing(0.5),
      [theme.breakpoints.down('xs')]: {
        padding: theme.spacing(0, 0.5, 0, 0),
      },
    },
    stack2Bottom: {
      paddingTop: 4,
      [theme.breakpoints.down('xs')]: {
        padding: theme.spacing(0, 0, 0, 0.5),
      },
    },
    amountCard: {
      height: '100%',
      margin: 0,
      background: 'transparent linear-gradient(20deg, #51698D 50%, #95ADCF 120%) 0% 0% no-repeat padding-box',
    },
    totalStakedLockedIcon: {
      color: '#fff',
    },
  });
};

export const ununifiValidatorAllValidatorsStyles = (theme) => ({
  pubKeyText: {
    fontSize: 14,
    maxWidth: 200,
    textOverflow: 'ellipsis',
  },
  rowExpandedContentItemTitle: {
    fontSize: 14,
    fontWeight: 600,
    minWidth: 150,
  },
  rowExpandedContent: {
    paddingBottom: theme.spacing(2),
  },
  downloadButton: {
    padding: 0,
    margin: 0,
  },
});

export const ununifiValidatorEarningHistoryStyles = (theme) => ({
  downloadButton: {
    padding: 0,
    margin: 0,
  },
  downloadButtonText: {
    fontSize: 14,
    fontWeight: 500,
  },
});

export const ununifiValidatorStyles = (theme) => ({
  migrateButton: {
    margin: 0,
    padding: theme.spacing(1.5, 5),
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
});
