import gql from 'graphql-tag';

export const createOrderMutation = gql`
  mutation createOrder(
    $createOrderInput: CreateOrderInput!
  )
  {
    createOrder(
      createOrderInput: $createOrderInput
    ) {
      orderId
      dateCreated
      dateUpdated
      nodes {
        nodeType
        nodeName
        dateCreated
        dateUpdated
        nodeId
        subNode
      }
      contract {
        contractId
        contractStartDate
        contractEndDate
        contractSignDate
        contractPeriodValue
        contractPeriod
        contractIRR
        contractType
        dateCreated
        dateUpdated
      }
      timeline {
        nodeProviderOrderTimelineId
        dateCreated
        dateUpdated
        dateCompleted
        expectDateCompleted
        stepName
        stepStatus
      }
      devices {
        deviceId
        macAddress
        deviceStatus
        dateCreated
        dateUpdated
        dateActive
        earnings {
          nodeProviderEarningId
          earning
          dateCreated
          dateUpdated
          cryptoPrices
          currencySymbol
          deviceId
        }
      }
      metadata
      payment {
        paymentMethod
        dateCreated
        dateUpdated
        amount
        currencySymbol
        stripe {
          stripePaymentMethodId
          stripeCustomerId
          stripeSubscriptionId
          stripeInvoiceId
          # stripeInvoicePdf
          stripeInvoice
        }
      }
    }
  }
`;
