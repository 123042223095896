import { IconButton, InputAdornment } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

const PasswordTextField = ({
  id,
  type,
  label,
  variant,
  onChange,
  className,
  value,
}) => {
  const { t } = useTranslation();
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  return (
    <TextField
      id={id}
      type={showPassword ? 'text' : 'password'}
      label={label || t('Password')}
      variant="outlined"
      onChange={onChange}
      className={className}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              aria-label={t('Toggle password visibility')}
              onClick={handleClickShowPassword}
            >
              {showPassword ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
};
PasswordTextField.propTypes = {
  id: PropTypes.string,
  type: PropTypes.string,
  label: PropTypes.string.isRequired,
  variant: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  className: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
};

PasswordTextField.defaultProps = {
  id: 'password-field',
  type: 'password',
  variant: PropTypes.string,
  className: null,
};

export default PasswordTextField;
