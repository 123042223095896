import Promise from 'bluebird';
import _ from 'lodash';

import { userOperations, userTypes } from '../../store/user/index';

const asyncValidate = async (values, dispatch) => {
  const { email } = values;
  const errors = {};
  if (email) {
    const response = await dispatch(userOperations.validateEmail(email));
    if (response.type === userTypes.VALIDATE_EMAIL_SUCCESS) {
      errors.email = 'Email not found';
    }
    if (!_.isEmpty(errors)) throw errors;
  }
  Promise.resolve();
};

export {
  asyncValidate,
};
