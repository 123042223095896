import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

const useStyles = makeStyles((theme) => ({
  buttonProgress: {
    color: theme.palette.primary.main,
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  roundedButton: {
    borderRadius: 10,
    fontSize: 14,
    margin: theme.spacing(1),
    textTransform: 'capitalize',
    fontWeight: theme.typography.fontWeightBold,
  },
  noMarginRoundedButton: {
    borderRadius: 10,
    fontSize: 14,
    textTransform: 'capitalize',
    fontWeight: theme.typography.fontWeightBold,
  },
  primaryColor: {
    padding: theme.spacing(1, 4),
    background: 'linear-gradient(180deg, #FF9C84 10%, #FF491D 90%)',
    '&:hover': {
      background: 'linear-gradient(180deg, #FF9C84 30%, #FF491D 90%)',
    },
    '&:active': {
      background: 'linear-gradient(180deg, #FF9C84 30%, #FF491D 90%)',
    },
    '&:focus': {
      background: 'linear-gradient(180deg, #FF9C84 30%, #FF491D 90%)',
    },
    '&:disabled': {
      background: '#eeeeee',
    },
  },
  secondaryColor: {
    color: '#ffffff',
    padding: theme.spacing(1, 4),
    background: 'linear-gradient(180deg, #627086 10%, #1A2433 90%)',
    '&:hover': {
      background: 'linear-gradient(180deg, #627086 30%, #1A2433 90%)',
    },
    '&:active': {
      background: 'linear-gradient(180deg, #627086 30%, #1A2433 90%)',
    },
    '&:focus': {
      background: 'linear-gradient(180deg, #627086 30%, #1A2433 90%)',
    },
    '&:disabled': {
      background: '#eeeeee',
    },
  },
  centerButton: {
    ...theme.centerComponent,
  },
}));

export default function RoundedButton({
  loading,
  onClick,
  className,
  color,
  variant,
  size,
  children, // Allow anything so please don't add proptypes for this
  type,
  loadingSize,
  disabled,
  component,
  to,
  target,
  disableElevation,
  overidingClasses,
  noMargin,
  align,
  ...otherButtonProps
}) {
  const classes = useStyles();
  return (
    <>
      <Button
        variant={variant}
        disabled={disabled || loading}
        onClick={onClick}
        type={type}
        color={color}
        size={size}
        component={component}
        to={to || null}
        target={target || null}
        className={classNames(
          !noMargin && classes.roundedButton,
          noMargin && classes.noMarginRoundedButton,
          className,
        )}
        disableElevation={disableElevation}
        classes={overidingClasses}
        {...otherButtonProps}
      >
        {loading && <CircularProgress size={loadingSize} className={classes.buttonProgress} />}
        {children}
      </Button>
    </>
  );
}
RoundedButton.defaultProps = {
  loading: false,
  onClick: () => {},
  className: '',
  variant: 'contained',
  color: 'primary',
  loadingSize: 24,
  size: 'medium',
  type: 'button',
  disabled: false,
  component: 'button',
  to: null,
  target: null,
  disableElevation: false,
  noMargin: false,
  align: '',
};

RoundedButton.propTypes = {
  loading: PropTypes.bool,
  onClick: PropTypes.func,
  className: PropTypes.string,
  color: PropTypes.string,
  variant: PropTypes.string,
  size: PropTypes.string,
  type: PropTypes.string,
  loadingSize: PropTypes.number,
  disabled: PropTypes.bool,
  component: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
  to: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
  target: PropTypes.string,
  disableElevation: PropTypes.bool,
  noMargin: PropTypes.bool,
  align: PropTypes.string,
};
