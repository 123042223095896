import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import whyDidYouRender from '@welldone-software/why-did-you-render';
import moment from 'moment';
import * as R from 'ramda';
import React from 'react';
import { CSVLink } from 'react-csv';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';

import { adminOrderSelectors } from '../../../store/admin/order/index';
import ReactTableV7 from '../../Tables/ReactTableV7';
import { createOrdersSuccessColumns } from '../columns';
import { createOrdersSuccessStyles } from '../styles';

if (process.env.REACT_APP_ENV !== 'prod') {
  whyDidYouRender(React);
}

const useStyles = makeStyles(createOrdersSuccessStyles);
const selectCreatedOrder = createSelector(
  (state) => state.adminR.orders.createdOrders.data,
  (orderList) => adminOrderSelectors.extractOrderList(orderList),
);

const CreateOrdersSuccess = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  const createdOrders = useSelector(selectCreatedOrder);
  const csvHeaders = [
    { label: 'Order Id', key: 'orderId' },
    { label: 'Email', key: 'email' },
    { label: 'Order Type', key: 'orderType' },
    { label: 'Order Date', key: 'orderDate' },
    { label: 'Quantity', key: 'quantity' },
    { label: 'Order Total Amount', key: 'orderTotalAmount' },
    { label: 'Currency', key: 'currency' },
    { label: 'Payment Method', key: 'paymentMethod' },
    { label: 'Payment Date', key: 'paymentDate' },
    { label: 'Trial Days', key: 'trialDays' },
    { label: 'Payment Date', key: 'dateTrialEnd' },
  ];

  const formatedDataForDownloading = R.map((eachData) => ({
    ...eachData,
    orderDate: moment(eachData.orderDate).toLocaleString(),
    currency: 'JPY',
  }))(createdOrders);

  const columnsToUse = createOrdersSuccessColumns(t);

  return (
    <>
      <Button className={classes.downloadButton}>
        <CSVLink
          data={formatedDataForDownloading}
          headers={csvHeaders}
          filename="created_orders.csv"
          style={{ textDecoration: 'none' }}
        >
          <Typography color="primary" className={classes.downloadButtonText}>
            {t('Download CSV')}
          </Typography>
        </CSVLink>
      </Button>
      <ReactTableV7
        data={createdOrders}
        columns={columnsToUse}
        customPageSize={createdOrders.length}
        customPageSizeList={[createdOrders.length]}
        disablePaging
      />
    </>
  );
};

CreateOrdersSuccess.defaultProps = {
};

CreateOrdersSuccess.propTypes = {
};

CreateOrdersSuccess.whyDidYouRender = true;
export default React.memo(CreateOrdersSuccess);
