import * as R from 'ramda';

import { CustomError } from '../../modules/error_utils';
import { errorOperations } from '../error/index';
import { loadingOperations } from '../loading/index';

import * as actions from './actions';
import * as chainsWeb3 from './chains/index';

export const { updateTransactionStatus } = actions;

export const getBalance = R.curry(
  async (
    library,
    account,
    dispatch,
  ) => {
    try {
      dispatch(loadingOperations.setLoading('getBalance', true));
      const response = dispatch(
        await actions.getBalance(
          library,
          account,
        ),
      );
      dispatch(loadingOperations.setLoading('getBalance', false));
      dispatch(errorOperations.clearError('getBalance'));
      return response;
    } catch (error) {
      dispatch(loadingOperations.setLoading('getBalance', false));
      return dispatch(errorOperations.setError(
        'getBalance',
        { fallbackErrorMessage: 'Get balance failed', error },
      ));
    }
  },
);

export const enableWeb3 = R.curry(
  async (chain, data, dispatch) => {
    try {
      dispatch(loadingOperations.setLoading('enableWeb3', true));

      const chainWeb3Fns = chainsWeb3[chain];

      if (!chainWeb3Fns) throw new CustomError(`Web3 doesnot support ${chain}`);
      const enableActionResult = dispatch(
        await chainWeb3Fns.enableWeb3(data),
      ); // this is operations
      dispatch(loadingOperations.setLoading('enableWeb3', false));

      return enableActionResult;
    } catch (error) {
      dispatch(loadingOperations.setLoading('enableWeb3', false));
      return dispatch(errorOperations.setError(
        'enableWeb3',
        { fallbackErrorMessage: 'Enable web3 failed', error },
      ));
    }
  },
);

export const onWeb3AccountsChange = R.curry(
  async (chain, data, dispatch) => {
    try {
      dispatch(loadingOperations.setLoading('onWeb3AccountsChange', true));

      const chainWeb3Fns = chainsWeb3[chain];

      if (!chainWeb3Fns) throw new CustomError(`Web3 doesnot support ${chain}`);
      const { payload, type } = dispatch(
        await chainWeb3Fns.onWeb3AccountsChange(data),
      ); // this is operations
      dispatch(loadingOperations.setLoading('onWeb3AccountsChange', false));

      return { payload, type };
    } catch (error) {
      dispatch(loadingOperations.setLoading('onWeb3AccountsChange', false));
      return dispatch(errorOperations.setError(
        'onWeb3AccountsChange',
        { fallbackErrorMessage: 'Enable web3 failed', error },
      ));
    }
  },
);

export const generateAccounts = R.curry(
  async (
    coin,
    data,
    dispatch,
  ) => {
    try {
      dispatch(loadingOperations.setLoading('generateAccounts', true));
      const { type, payload } = dispatch(actions.generateAccounts(coin, data));
      dispatch(errorOperations.clearError('generateAccounts'));
      return { type, payload };
    } catch (error) {
      dispatch(loadingOperations.setLoading('generateAccounts', false));
      return dispatch(errorOperations.setError(
        'generateAccounts',
        { fallbackErrorMessage: 'generate accounts failed', error },
      ));
    }
  },
);

export const backupAccount = R.curry(
  (
    coin,
    data,
    dispatch,
  ) => {
    try {
      dispatch(loadingOperations.setLoading('backupAccount', true));
      const { type, payload } = dispatch(actions.backupAccount(coin, data));
      dispatch(errorOperations.clearError('backupAccount'));
      return { type, payload };
    } catch (error) {
      dispatch(loadingOperations.setLoading('backupAccount', false));
      return dispatch(errorOperations.setError(
        'backupAccount',
        { fallbackErrorMessage: 'backup accounts failed', error },
      ));
    }
  },
);

export const broadcastTransaction = R.curry(
  async (
    t,
    chain,
    subChain,
    ss58Format,
    selectedMethod,
    data,
    dispatch,
  ) => {
    try {
      dispatch(loadingOperations.setLoading('broadcastTransaction', true));
      if (selectedMethod === 'connect') {
        await dispatch(enableWeb3(chain, { ss58Format }));
      }
      // await dispatch(await onWeb3AccountsChange(chain, {}));

      const { type, payload } = dispatch(await actions.broadcastTransaction(
        chain,
        subChain,
        selectedMethod,
        data,
      ));
      dispatch(errorOperations.clearError('broadcastTransaction'));
      return { type, payload };
    } catch (error) {
      dispatch(loadingOperations.setLoading('broadcastTransaction', false));
      const { message } = error;
      error.custom = true;
      console.log(message);
      if (R.includes('Cancelled', message)) {
        error.message = t('Transaction cancelled');
      } else if (R.includes('low priority', message)) {
        error.message = t('Some transactions are not finalized. Please wait until they are finalized before make new transactions.');
      } else {
        error.message = t('Transaction failed. Please wait 10 minutes and try again.');
      }
      return dispatch(errorOperations.setError(
        'broadcastTransaction',
        { message: t('broadcast transaction failed'), error },
      ));
    }
  },
);

export const resetTransactionDone = () => (
  dispatch,
) => {
  dispatch(actions.resetTransactionDone());
  dispatch(loadingOperations.setLoading('broadcastTransaction', false));
  dispatch(errorOperations.clearError('broadcastTransaction'));
};

export const { resetTransactionHash } = actions;

export const { resetTransactionInfo } = actions;
export const initializeApi = R.curry(
  async (
    coin,
    endpoint,
    dispatch,
  ) => {
    try {
      dispatch(loadingOperations.setLoading('initializeApi', true));
      const { type, payload } = dispatch(await actions.initializeApi(coin, endpoint));
      dispatch(loadingOperations.setLoading('initializeApi', false));
      dispatch(errorOperations.clearError('initializeApi'));
      return { type, payload };
    } catch (error) {
      dispatch(loadingOperations.setLoading('initializeApi', false));
      return dispatch(errorOperations.setError(
        'initializeApi',
        { message: 'initialize api failed', error },
      ));
    }
  },
);

export const getTransactionInfo = R.curry(
  async (
    chain,
    {
      api,
      recipient,
      sender,
      amount,
    },
    dispatch,
  ) => {
    try {
      dispatch(loadingOperations.setLoading('getTransactionInfo', true));
      const { type, payload } = dispatch(await actions.getTransactionInfo(
        chain,
        {
          api,
          recipient,
          sender,
          amount,
        },
      ));
      dispatch(errorOperations.clearError('getTransactionInfo'));
      return { type, payload };
    } catch (error) {
      dispatch(loadingOperations.setLoading('getTransactionInfo', false));
      return dispatch(errorOperations.setError(
        'getTransactionInfo',
        { message: 'get transaction api failed', error },
      ));
    }
  },
);

export const getAccountInfo = R.curry(
  async (
    chain,
    subChain,
    {
      api,
      address,
    },
    dispatch,
  ) => {
    try {
      dispatch(loadingOperations.setLoading('getAccountInfo', true));
      const { type, payload } = dispatch(await actions.getAccountInfo(
        chain,
        subChain,
        {
          api,
          address,
        },
      ));
      dispatch(loadingOperations.setLoading('getAccountInfo', false));
      dispatch(errorOperations.clearError('getAccountInfo'));
      return { type, payload };
    } catch (error) {
      dispatch(loadingOperations.setLoading('getAccountInfo', false));
      return dispatch(errorOperations.setError(
        'getAccountInfo',
        { message: 'get account api failed', error },
      ));
    }
  },
);

export const { setWalletSelectedAccount } = actions;
