import gql from 'graphql-tag';

export const generateDeployNodeConfigMutation = gql`
  mutation generateDeployNodeConfig(
    $generateDeployNodeConfigInput: GenerateDeployNodeConfigInput!
  )
  {
    generateDeployNodeConfig(
      generateDeployNodeConfigInput: $generateDeployNodeConfigInput
    ) {
      nodeType
      data
    }
  }
`;
