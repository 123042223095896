export const customTabstyles = (theme) => ({
  tabContent: {
    ...theme.centerComponent,
  },
  wrapper: {
    ...theme.typography.h1,
    color: theme.customColor.almostBlack,
    fontWeight: 'unset',
    ...theme.font.title,
    textTransform: 'none',
    textAlign: 'left',
  },
  tabIndicator: {
    display: 'none',
  },
  selectedTab: {
    ...theme.typography.h1,
    color: `${theme.customColor.almostBlack} !important`,
    ...theme.font.title,
    textTransform: 'none',
  },
  firstTab: {
    maxWidth: '100%',
    paddingLeft: 0,
    minWidth: 0,
  },
  tab: {
    maxWidth: '100%',
  },
  tabs: {
    ...theme.font.title,
    [theme.breakpoints.down('xs')]: {
      maxWidth: window.innerWidth * 0.95,
    },
  },
});

export const launchNodeTabStyles = (theme) => ({
  buttonContainer: {
    flexGrow: 1,
  },
  deployButton: {
    padding: theme.spacing(1.5, 5),
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
});

export const depositTabStyles = (theme) => ({
  buttonContainer: {
    flexGrow: 1,
  },
  button: {
    padding: theme.spacing(1.5, 5),
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
});

export const tabHeadersStyles = (theme) => ({
  tabName: {
    cursor: 'pointer',
    fontWeight: 600,
    fontSize: 14,
    color: '#515A6A',
    ...theme.font.title,
  },
  divider: {
    backgroundColor: '#515A6A',
    width: 1,
    opacity: 0.22,
  },
  tabList: {
    paddingBottom: theme.spacing(4),
  },
});
