import { makeStyles } from '@material-ui/core/styles';
import startCase from 'lodash/fp/startCase';
import toLower from 'lodash/fp/toLower';
import toUpper from 'lodash/fp/toUpper';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import {
  useHistory,
} from 'react-router-dom';

import { useQuery } from '../../hooks/hooks';
import { marketRoute, signinRoute } from '../../routes';
import { contactFormOperations } from '../../store/contact_form/index';
import { routerOperations, routerServices } from '../../store/router/index';
import { getChainsData } from '../Market/constants';
import { RoundedButton } from '../MaterialUiCustom/MaterialUiCustom';

import { marketCardActionStyles } from './styles';

// store
// constants

const useStyles = makeStyles(marketCardActionStyles);

const MarketCardAction = (props) => {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const query = useQuery();

  const deployButtonText = {
    ACTIVE: t('deploy'),
    DISABLED: t('coming soon'),
    CONTACT_REQUIRED: t('contact us'),
    TRIAL: t('free trial'),
    MAINTENANCE: t('Maintenance'),
  };

  const queryLng = query.get('lng');
  const { chainAbbreviation, nodeTypes } = props;
  const nodeType = R.view(R.lensPath([0, 'type']), nodeTypes);
  const classes = useStyles();
  const { isAuthenticated } = useSelector((state) => state.userR.authentication);
  const chainStatus = getChainsData(t)[chainAbbreviation].status;
  const deployButtonTextToDisplay = deployButtonText[chainStatus];
  const clickAction = async () => {
    if (isAuthenticated) {
      return routerServices.historyPush(
        history,
        {
          pathname: `${marketRoute}/${chainAbbreviation}/deploy`,
          queryLng,
        },
      );
    }
    await dispatch(routerOperations.setAuthenticateRedirectPathName(marketRoute));

    return routerServices.historyPush(
      history,
      {
        pathname: signinRoute,
        queryLng,
      },
    );
  };

  const openContactForm = (metadata) => {
    dispatch(contactFormOperations.openContactForm(metadata));
  };

  const buttonProps = {
    ACTIVE: {
      component: 'button',
      onClick: clickAction,
      target: null,
      to: null,
      disabled: false,
    },
    DISABLED: {
      component: 'button',
      onClick: null,
      target: null,
      to: null,
      disabled: true,
    },
    CONTACT_REQUIRED: {
      component: 'button',
      onClick: isAuthenticated ? () => openContactForm(
        { subject: `${toUpper(chainAbbreviation)} (${startCase(toLower(nodeType))})` },
      ) : clickAction,
      disabled: false,
    },
    TRIAL: {
      component: 'button',
      onClick: clickAction,
      target: null,
      to: null,
      disabled: false,
    },
    MAINTENANCE: {
      component: 'button',
      onClick: clickAction,
      target: null,
      to: null,
      disabled: true,
    },
  };
  const getButtonProps = R.curry((buttonProps, chainStatus) => {
    if (!buttonProps[chainStatus]) return buttonProps.DISABLED;
    return buttonProps[chainStatus];
  });
  return (
    <RoundedButton
      variant="outlined"
      className={classes.deployButton}
      {...getButtonProps(buttonProps, chainStatus)}
      color="default"
      align="center"
    >
      {deployButtonTextToDisplay}
    </RoundedButton>
  );
};

MarketCardAction.propTypes = {
  /**
   * chain name for open deploy link
   */
  chainAbbreviation: PropTypes.string.isRequired,
  nodeTypes: PropTypes.arrayOf(
    PropTypes.shape({
      minUnit: PropTypes.number.isRequired,
      linksSocialMedia: PropTypes.array.isRequired,
      type: PropTypes.string.isRequired,
      pricing: PropTypes.arrayOf(PropTypes.shape({
        price: PropTypes.number.isRequired,
        currency: PropTypes.string.isRequired,
        paymentPeriod: PropTypes.string.isRequired,
      }).isRequired),
    }).isRequired,
  ).isRequired,
};

export default React.memo(MarketCardAction);
