import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React from 'react';

const styles = (theme) => ({
  strongText: {
    fontWeight: 500,
  },
  subtitle1Text: {
    color: 'rgba(0, 0, 0, 0.87)',
    fontSize: '1rem',
    fontWeight: '400',
    lineHeight: 1.75,
    lineSpacing: '0.00938em',
  },
  listItem: {
    padding: 0,
  },
  exchangeCaptionText: {
    fontSize: '1rem',
  },
  powerdByChangelly: {
    backgroundColor: '#eeeeee',
  },
});
const useStyles = makeStyles(styles);

const Hint = ({ hintText, hintTitle, divider }) => {
  const classes = useStyles();
  return (
    <Grid container spacing={2}>
      {hintTitle
                && (
                <Grid item xs={12}>
                  <Typography variant="h6">
                    {hintTitle}
                  </Typography>
                </Grid>
                )}
      <Grid item xs={12}>
        <span className={classes.subtitle1Text}>
          {hintText}
        </span>
        {divider && <Divider />}
      </Grid>
    </Grid>
  );
};
Hint.propTypes = {
  hintText: PropTypes.string.isRequired,
  hintTitle: PropTypes.string,
  divider: PropTypes.bool,
};
// Hint.defaultProps = {
//   statuses: [],
//   status: ''
// }
export default Hint;
