import * as routerOperations from './operations';
import reducer from './reducers';
import * as routerSelectors from './selectors';
import * as routerServices from './services';
import * as routerTypes from './types';

export {
  routerSelectors,
  routerOperations,
  routerTypes,
  routerServices,
};

export default reducer;
