import * as contactFormOperations from './operations';
import reducer from './reducers';
import * as contactFormTypes from './types';

export {
  contactFormOperations,
  contactFormTypes,
};

export default reducer;
