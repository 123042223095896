export const validate = (values) => {
  const { newPassword, confirmPassword } = values;
  const errors = {};

  if (newPassword && confirmPassword) {
    if (newPassword !== confirmPassword) {
      errors.confirmPassword = 'Passwords are not matched';
      errors.newPassword = 'Passwords are not matched';
    }
  }
  return errors;
};
