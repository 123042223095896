import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import PropTypes from 'prop-types';
import React from 'react';

import PaymentStripe from './PaymentStripe';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY || 'pk_test_4AlyDaJP56agcQ3r9yYIMF5Z');

// todo add other payment methods
const Payment = (props) => {
  const {
    btnText,
    onSelectStripePaymentMethod,
    onRenderCardForm,
    enableCoupon,
  } = props;
  return (
    <Elements stripe={stripePromise}>
      <PaymentStripe
        btnText={btnText}
        onSelectStripePaymentMethod={onSelectStripePaymentMethod}
        onRenderCardForm={onRenderCardForm}
        enableCoupon={enableCoupon}
      />
    </Elements>

  );
};

Payment.propTypes = {
  /**
   * text displayed in action button
   */
  btnText: PropTypes.string,
  onSelectStripePaymentMethod: PropTypes.func,
  onRenderCardForm: PropTypes.func,
  enableCoupon: PropTypes.bool,
};

Payment.defaultProps = {
  btnText: 'add',
  onSelectStripePaymentMethod: () => {},
  onRenderCardForm: () => {},
  enableCoupon: false,
};

export default Payment;
