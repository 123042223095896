import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import * as R from 'ramda';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { CustomError } from '../../../../modules/error_utils';

import MiningAddressCreationAddresses from './MiningAddressCreationAddresses';
import MiningAddressCreationConnectWeb3 from './MiningAddressCreationConnectWeb3';

const MiningAddressCreationConnect = ({
  enableWeb3Loading,
  isExtensionsInjected,
  accounts,
  autoConnect,
  openAddressCreation,
  chain,
  ss58Format,
  handleOnCancel,
  resetAllChildren,
  userId,
  setResetAllChildren,
  onCreateCryptoAddress,
  createCryptoAddressLoading,
}) => {
  const { t } = useTranslation();
  const [activeStep, setActiveStep] = useState(0);
  const steps = {
    0: {
      title: '',
    },
    1: {
      title: t('Select an address'),
      description: t('Your mining rewards will be sent to this address. It cannot be changed once added'),
    },
  };

  const stepsContents = {
    0: (
      <MiningAddressCreationConnectWeb3
        enableWeb3Loading={enableWeb3Loading}
        isExtensionsInjected={isExtensionsInjected}
        accounts={accounts}
        openAddressCreation={openAddressCreation}
        chain={chain}
        ss58Format={ss58Format}
        setActiveStep={setActiveStep}
        activeStep={activeStep}
        handleOnCancel={handleOnCancel}
        resetAllChildren={resetAllChildren}
        userId={userId}
      />
    ),
    1: (
      <MiningAddressCreationAddresses
        accounts={accounts}
        onCreateCryptoAddress={onCreateCryptoAddress}
        userId={userId}
        createCryptoAddressLoading={createCryptoAddressLoading}
        enableWeb3Loading={enableWeb3Loading}
        handleOnCancel={handleOnCancel}
        showBtn
        setActiveStep={setActiveStep}
        isExtensionsInjected={isExtensionsInjected}
        web3
      />
    ),
  };

  const createGetStepdata = R.curry((steps, activeStep) => {
    const stepData = steps[activeStep];
    if (!stepData) throw new CustomError(`Step data expected an object but ${stepData} found`);
    return stepData;
  });

  const getStepData = createGetStepdata(steps);

  const createGetStepItem = R.curry((key, activeStep) => {
    const stepData = getStepData(activeStep);
    const item = stepData[key];
    return item;
  });
  const getStepDescription = createGetStepItem('description');

  const getStepContent = (activeStep) => {
    const stepContent = stepsContents[activeStep];
    return stepContent;
  };
  return (
    <Box>
      <Box>
        <Typography>
          {getStepDescription(activeStep)}
        </Typography>
      </Box>
      <Box>
        {getStepContent(activeStep) }
      </Box>
    </Box>
  );
};

export default MiningAddressCreationConnect;
