export const investmentContentItemStyles = (theme) => ({
  content: {
    padding: theme.spacing(6),
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(6, 1),
    },
  },
  placeHolder: {
    width: 150,
    height: 150,
    borderRadius: 10,
    borderColor: '#aaa',
    [theme.breakpoints.down('sm')]: {
      width: 100,
      height: 100,
    },
  },
  viewMoreButton: {
    padding: 0,
  },
  viewMoreButtonLabel: {
    color: '#C6C6C6',
  },
  specialTag: {
    maxWidth: 150,
    borderRadius: 15,
    borderColor: theme.palette.primary.main,
  },
  specialTagText: {
    padding: theme.spacing(1, 2),
  },
  title: {
    color: '#BABABA',
  },
});

export const investmentNodeDetailsPageItemStyles = (theme) => ({
  cardContent: {
    paddingTop: 0,
    paddingBottom: theme.spacing(3),
    '&:last-child': {
      paddingBottom: theme.spacing(3),
    },
    [theme.breakpoints.down('xs')]: {
      maxWidth: window.innerWidth * 0.95,
      overflow: 'auto',
    },
  },
});
