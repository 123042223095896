import {
  pipeAwait,
  gweiToEther,
} from 'jsutils';
import toNumber from 'lodash/fp/toNumber';
import isFunction from 'lodash/isFunction';
import * as R from 'ramda';
import { isArray } from 'ramda-adjunct';

import {
  graphqlNormalize,
} from '../../modules/apollo/apollo';
import {
  returnAction,
} from '../store_utils';

import { periodUnitToSeconds, mapNodeTypeToCoin } from './constants';
import {
  graphqlGetUserNodes,
  graphqlGetUserNode,
  graphqlGetUserNodeData,
  graphalUpdateUserNode,
  graphalUpdateUserNodes,
  graphqlGetNodesEstimateActivationDate,
  graphqlGetNodesOnChainStatus,
  graphqlGetTotalEarning,
  graphqlGetTotalEarningInPeriod,
  graphqlGetIntervalEarning,
  graphqlGetCoinPrice,
  graphqlGetCoinPriceChart,
  graphqlGetZenBalance,
  graphqlGetUserEarningHistory,
  graphqlGetTotalEarningInfluxdb,
  graphqlGetIntervalFixedEarning,
  graphqlGetTotalFixedEarning,
  graphqlGetNodeOwnerTotalEarning,
} from './graphql/services';
import * as services from './services';
import { saveNodeDeployInputsExtraFns } from './services';
import * as types from './types';

/**
 * Get user nodes
 *
 * @param      {string}  userId
 * @return     {object}
 */
export const getUserNodes = R.curry(
  async (userId) => pipeAwait(
    graphqlGetUserNodes,
    graphqlNormalize('getUserNodes'),
    returnAction(types.GET_USER_NODES_SUCCESS),
  )({ userId }),
);

/**
 * Get node status by node name
 *
 * @param      {string}  userId
 * @param      {string}  nodeId
 * @return     {object}
 */
export const getUserNode = R.curry(
  async (userId, nodeName) => pipeAwait(
    graphqlGetUserNode,
    graphqlNormalize('getUserNode'),
    returnAction(types.GET_USER_NODE_SUCCESS),
  )({ userId, nodeName }),
);

export const createSaveNodeDeployInputsExtra = R.curry(
  async (saveNodeDeployInputsExtraFns, nodeType, data) => {
    const fn = saveNodeDeployInputsExtraFns[nodeType];

    if (isFunction(fn)) {
      return pipeAwait(
        fn,
        returnAction(types.SAVE_NODE_DEPLOY_INPUTS),
      )(data);
    }
    return returnAction(types.SAVE_NODE_DEPLOY_INPUTS, {});
  },
);

export const saveNodeDeployInputsExtra = createSaveNodeDeployInputsExtra(
  saveNodeDeployInputsExtraFns,
);

export const formatSubNodeData = R.curry(
  (nodeType, data) => R.pipe(
    services.formatSubNodeData(nodeType),
    returnAction(types.FORMAT_SUB_NODE_DATA),
  )(data),
);

/**
 * Get node data (from chain)
 *
 * @param      {string}  userId
 * @param      {string}  nodeId
 * @return     {object}
 */
export const getUserNodeEarning = R.curry(
  async (nodeType, nodeName, publicKey) => {
    const getUserNodeEarningInput = {
      nodeType, publicKey,
    };
    return pipeAwait(
      graphqlGetUserNodeData,
      graphqlNormalize('getUserNodeEarning'),
      (response) => {
        const {
          balance,
          effectiveBalance,
          income,
        } = response.data;

        const toEther = R.pipe(
          toNumber,
          gweiToEther,
        );

        return {
          ...response,
          nodeName,
          data: {
            ...response.data,
            balance: toEther(balance),
            effectiveBalance: toEther(effectiveBalance),
            income: toEther(income),
          },
        };
      },
      returnAction(types.GET_USER_NODE_EARNING_SUCCESS),
    )({ getUserNodeEarningInput });
  },
);

export const updateUserNode = R.curry(
  async (
    userId,
    nodeName,
    nodeType,
    parentNode,
    subNode,
  ) => pipeAwait(
    graphalUpdateUserNode,
    graphqlNormalize('updateUserNode'),
    returnAction(types.UPDATE_USER_NODE_SUCCESS),
  )({
    updateUserNodeInput: {
      userId,
      nodeName,
      nodeType,
      parentNode,
      subNode,
    },
  }),
);
export const updateUserNodes = R.curry(
  async (
    nodes,
  ) => pipeAwait(
    graphalUpdateUserNodes,
    graphqlNormalize('updateUserNodes'),
    returnAction(types.UPDATE_USER_NODES_SUCCESS),
  )({
    updateUserNodesInput: { nodes },
  }),
);

export const getNodesEstimateActivationDate = R.curry(
  async (
    publicKeys,
    nodeType,
  ) => pipeAwait(
    graphqlGetNodesEstimateActivationDate,
    graphqlNormalize('getNodesEstimateActivationDate'),
    returnAction(types.GET_NODES_ESTIMATE_ACTIVATION_DATE_SUCCESS),
  )({
    getNodesEstimateActivationDateInput: {
      publicKeys,
      nodeType,
    },
  }),
);

export const getNodesOnChainStatus = R.curry(
  async (
    publicKeys,
    nodeType,
  ) => pipeAwait(
    graphqlGetNodesOnChainStatus,
    graphqlNormalize('getNodesOnChainStatus'),
    returnAction(types.GET_NODES_ON_CHAIN_STATUS_SUCCESS),
  )({
    getNodesOnChainStatusInput: {
      publicKeys,
      nodeType,
    },
  }),
);

export const getTotalEarning = R.curry(
  async (
    userId,
    publicKeys,
    nodeType,
  ) => pipeAwait(
    graphqlGetTotalEarning,
    graphqlNormalize('getTotalEarning'),
    (data) => R.merge(data, { coin: mapNodeTypeToCoin[nodeType] }),
    returnAction(types.GET_NODES_TOTAL_EARNING_SUCCESS),
  )({
    getTotalEarningInput: {
      userId,
      publicKeys,
      nodeType,
    },
  }),
);

export const getTotalEarningInPeriod = R.curry(
  async (
    publicKeys,
    nodeType,
    period,
  ) => {
    const periodData = R.split('_', period);
    const number = periodData[0];
    const unit = periodData[1];
    if (!number || !unit) {
      throw new Error('Invalid period');
    }

    const isOneNode = !isArray(publicKeys);

    return pipeAwait(
      graphqlGetTotalEarningInPeriod,
      graphqlNormalize('getTotalEarningInPeriod'),
      (data) => R.merge(
        data,
        {
          period: `${number}${unit}`,
          isOneNode,
          coin: mapNodeTypeToCoin[nodeType],
        },
      ),
      returnAction(types.GET_NODES_TOTAL_EARNING_IN_PERIOD_SUCCESS),
    )({
      getTotalEarningInPeriodInput: {
        publicKeys: isOneNode ? [publicKeys] : publicKeys,
        nodeType,
        period: toNumber(number) * periodUnitToSeconds[unit],
      },
    });
  },
);

export const getIntervalEarning = R.curry(
  async (
    userId,
    nodeType,
    currency,
    startTime,
    endTime,
    interval,
    rows,
    page,
  ) => pipeAwait(
    graphqlGetIntervalEarning,
    graphqlNormalize('getIntervalEarning'),
    (data) => R.merge(
      data,
      {
        startTime,
        endTime,
        interval,
        coin: mapNodeTypeToCoin[nodeType],
      },
    ),
    returnAction(types.GET_NODES_INTERVAL_EARNING_SUCCESS),
  )({
    getIntervalEarningInput: {
      userId,
      nodeType,
      currency,
      startTime,
      endTime,
      interval,
      rows,
      page,
    },
  }),
);

export const getUserEarningHistory = R.curry(
  async (
    userId,
    nodeType,
    currency,
    startTime,
    endTime,
    interval,
  ) => pipeAwait(
    graphqlGetUserEarningHistory,
    graphqlNormalize('getUserEarningHistory'),
    returnAction(types.GET_EARNING_HISTORY_SUCCESS),
  )({
    getUserEarningHistoryInput: {
      userId,
      nodeType,
      currency,
      startTime,
      endTime,
      interval,
    },
  }),
);

export const getCoinPrice = R.curry(
  async (
    coinSymbol,
    currency,
  ) => pipeAwait(
    graphqlGetCoinPrice,
    graphqlNormalize('getCoinPrice'),
    returnAction(types.GET_COIN_PRICE_SUCCESS),
  )({
    getCoinPriceInput: {
      coinSymbol,
      currency,
    },
  }),
);

export const getCoinPriceChart = R.curry(
  async (
    coinSymbol,
    currency,
    days,
  ) => pipeAwait(
    graphqlGetCoinPriceChart,
    graphqlNormalize('getCoinPriceChart'),
    returnAction(types.GET_COIN_PRICE_CHART_SUCCESS),
  )({
    getCoinPriceChartInput: {
      coinSymbol,
      currency,
      days,
    },
  }),
);

export const getZenBalance = R.curry(
  async (
    userId,
  ) => pipeAwait(
    graphqlGetZenBalance,
    graphqlNormalize('getZenBalance'),
    returnAction(types.GET_ZEN_BALANCE_SUCCESS),
  )({
    getZenBalanceInput: {
      userId,
    },
  }),
);

export const getTotalEarningInfluxdb = R.curry(
  async (
    userId,
    nodeType,
    currency,
    duration,
  ) => pipeAwait(
    graphqlGetTotalEarningInfluxdb,
    graphqlNormalize('getTotalEarningInfluxdb'),
    (data) => R.merge(data, { duration }),
    returnAction(types.GET_NODES_TOTAL_EARNING_INFLUXDB_SUCCESS),
  )({
    getTotalEarningInfluxdbInput: {
      userId,
      nodeType,
      currency,
      duration,
    },
  }),
);

export const getIntervalFixedEarning = R.curry(
  async (
    userId,
    earningType,
    startTime,
    endTime,
    interval,
    contractId,
    currency,
    page,
    rows,
  ) => pipeAwait(
    graphqlGetIntervalFixedEarning,
    graphqlNormalize('getIntervalFixedEarning'),
    returnAction(types.GET_INTERVAL_FIXED_EARNING_SUCCESS),
  )({
    getIntervalFixedEarningInput: {
      userId,
      earningType,
      startTime: startTime || null,
      endTime: endTime || null,
      interval: interval || '1mo',
      contractId: contractId || null,
      currency: currency || 'usd',
      page: page || 1,
      rows: rows || 999999, // Temporary, waiting for Mehedi to implement get all when passing null rows
    },
  }),
);

export const getTotalFixedEarning = R.curry(
  async (
    userId,
    startTime,
    endTime,
    contractId,
    currency,
  ) => pipeAwait(
    graphqlGetTotalFixedEarning,
    graphqlNormalize('getTotalFixedEarning'),
    returnAction(types.GET_TOTAL_FIXED_EARNING_SUCCESS),
  )({
    getTotalFixedEarningInput: {
      userId,
      startTime: startTime || null,
      endTime: endTime || null,
      contractId: contractId || null,
      currency: currency || 'usd',
    },
  }),
);

export const getNodeOwnerTotalEarning = R.curry(
  async (userId, nodeType) => pipeAwait(
    graphqlGetNodeOwnerTotalEarning,
    graphqlNormalize('getNodeOwnerTotalEarning'),
    returnAction(types.GET_NODE_OWNER_TOTAL_EARNING_SUCCESS),
  )({
    getNodeOwnerTotalEarningInput: {
      userId,
      nodeType,
    },
  }),
);
