export const FETCH_DISTRIBUTOR_LOADING = 'FETCH_DISTRIBUTOR_LOADING';
export const FETCH_DISTRIBUTOR_SUCCESS = 'FETCH_DISTRIBUTOR_SUCCESS';
export const FETCH_DISTRIBUTOR_FAIL = 'FETCH_DISTRIBUTOR_FAIL';
export const SIGNIN_SUCCESS = 'SIGNIN_SUCCESS';
export const GET_STAKING_COMMISSION_SUCCESS = 'GET_STAKING_COMMISSION_SUCCESS';
export const SIGNUP_SUCCESS = 'SIGNUP_SUCCESS';
export const ACTIVATE_SUCCESS = 'ACTIVATE_SUCCESS';
export const VERIFY_PASSWORD_SUCCESS = 'VERIFY_PASSWORD_SUCCESS';
export const GET_SUB_USERS_SUCCESS = 'GET_SUB_USERS_SUCCESS';
export const GET_USERS_SUCCESS = 'GET_USERS_SUCCESS';
export const GET_USERS_TABLE_SUCCESS = 'GET_USERS_TABLE_SUCCESS';
export const UPDATE_USER_CONTACT_SUCCESS = 'UPDATE_USER_CONTACT_SUCCESS';
export const UPDATE_STAKING_COMMISSION_SUCCESS = 'UPDATE_STAKING_COMMISSION_SUCCESS';
export const UPDATE_USERNAME_SUCCESS = 'UPDATE_USERNAME_SUCCESS';
export const UPDATE_EMAIL_SUCCESS = 'UPDATE_EMAIL_SUCCESS';
export const UPDATE_PASSWORD_SUCCESS = 'UPDATE_PASSWORD_SUCCESS';
export const UPDATE_UNDER_AUTHORITY_USER_CONTACT_SUCCESS = 'UPDATE_UNDER_AUTHORITY_USER_CONTACT_SUCCESS';
export const UPDATE_UNDER_AUTHORITY_USER_STAKING_COMMISSION_SUCCESS = 'UPDATE_UNDER_AUTHORITY_USER_STAKING_COMMISSION_SUCCESS';
export const UPDATE_UNDER_AUTHORITY_USER_USERNAME_SUCCESS = 'UPDATE_UNDER_AUTHORITY_USER_USERNAME_SUCCESS';
export const UPDATE_UNDER_AUTHORITY_USER_EMAIL_SUCCESS = 'UPDATE_UNDER_AUTHORITY_USER_EMAIL_SUCCESS';
export const LOAD_USER = 'LOAD_USER';
export const LOAD_STAKING_COMMSSION = 'LOAD_STAKING_COMMSSION';
export const AUTHENTICATE_USER = 'AUTHENTICATE_USER';
export const SIGN_OUT = 'SIGN_OUT';
export const DELETE_USER_RELATIONSHIP_SUCCESS = 'DELETE_USER_RELATIONSHIP_SUCCESS';
export const ADD_USER_RELATIONSHIP_SUCCESS = 'ADD_USER_RELATIONSHIP_SUCCESS';
export const GET_USER_RELATIONSHIP_SUCCESS = 'GET_USER_RELATIONSHIP_SUCCESS';
export const CREATE_SUB_USER_SUCCESS = 'CREATE_SUB_USER_SUCCESS';
export const SEND_FORGOT_PASSWORD_SUCCESS = 'SEND_FORGOT_PASSWORD_SUCCESS';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const VALIDATE_FORGOT_PASSWORD_REQUEST_SUCCESS = 'VALIDATE_FORGOT_PASSWORD_REQUEST_SUCCESS';
export const VALIDATE_EMAIL_SUCCESS = 'VALIDATE_EMAIL_SUCCESS';
export const RESEND_ACTIVATION = 'RESEND_ACTIVATION';
export const GET_ACTIVATION = 'GET_ACTIVATION';
export const SET_AUTH_TOKENS_STATUS_UPDATED = 'SET_AUTH_TOKENS_STATUS_UPDATED';
export const CREATE_FIRST_SIGNIN_SUCCESS = 'CREATE_FIRST_SIGNIN_SUCCESS';
export const GET_FIRST_SIGNIN_SUCCESS = 'GET_FIRST_SIGNIN_SUCCESS';
export const GET_FIRST_SIGNINS_SUCCESS = 'GET_FIRST_SIGNINS_SUCCESS';
export const SET_USER_FIRST_SIGNED_IN_SUCCESS = 'SET_USER_FIRST_SIGNED_IN_SUCCESS';
export const LOAD_ADMIN = 'LOAD_ADMIN';
export const RESET_LOAD_USER = 'RESET_LOAD_USER';
export const VALIDATE_USER_SUCCESS = 'VALIDATE_USER_SUCCESS';
