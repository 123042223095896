import Avatar from '@material-ui/core/Avatar';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import { makeStyles } from '@material-ui/core/styles';
import CheckOutlinedIcon from '@material-ui/icons/CheckOutlined';
import BaseIdentityIcon from '@polkadot/react-identicon';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import React from 'react';

import { accountPropType } from '../propTypes';

import { connectAddressStyles } from './styles';

import { truncateWalletAddress } from 'components/MyNodeInfo/utils';
import useIsMobile from 'hooks/useIsMobile';

const useStyles = makeStyles(connectAddressStyles);

const ConnectWalletAddress = ({
  onClick,
  selectedAddress,
  account,
}) => {
  const classes = useStyles();
  const { address, meta } = account;
  const { name } = meta;
  const showCheckmark = selectedAddress === address;
  const isMobile = useIsMobile();

  const getAddress = R.curry((isMobile, address) => (isMobile ? truncateWalletAddress(5, -5, address) : address));
  return (
    <ListItem onClick={() => onClick(account, address)} className={classes.listItem}>
      <ListItemAvatar>
        <Avatar>
          <BaseIdentityIcon
            size={48}
            value={address}
          />
        </Avatar>
      </ListItemAvatar>
      <ListItemText
        primary={name}
        secondary={getAddress(isMobile, address)}
      />
      {showCheckmark && (
      <ListItemSecondaryAction>
        <CheckOutlinedIcon />
      </ListItemSecondaryAction>
      )}
    </ListItem>
  );
};

ConnectWalletAddress.propTypes = {
  onClick: PropTypes.func.isRequired,
  selectedAddress: PropTypes.string,
  account: accountPropType.isRequired,
};

ConnectWalletAddress.defaultProps = {
  selectedAddress: undefined,
};

export default ConnectWalletAddress;
