import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import LocalDate from '../LocalDate/LocalDate';

import ReferralDataSection from './ReferralDataSection';

const ReferralCommissions = (props) => {
  const { t } = useTranslation();
  const { commissionStats } = props;

  const {
    datePaid,
  } = useSelector((state) => state.referralR.affiliate);

  const explanations = {
    paid: t("The commissions you've been paid."),
    unpaid: t('The commissions you are entitled to receive.'),
    total: t('Total commissions includes paid and unpaid.'),
  };

  const desciptions = {
    paid: (
      datePaid
        ? (
          <Box display="flex" alignItems="center">
            <Box pr={0.5}>
              <Typography color="textSecondary">
                {
                  // i18next-extract-mark-context-next-line ["referral_paid"]
                  t('on', { context: 'referral_paid' })
                }
              </Typography>
            </Box>
            <Box>
              <Typography color="textSecondary">
                <LocalDate date={datePaid} />
              </Typography>
            </Box>
          </Box>
        )
        : null
    ),
  };

  const {
    total,
    paid,
    unpaid,
  } = commissionStats;
  const data = {
    total: {
      title: t('total'),
      value: total,
    },
    paid: {
      title: t('paid'),
      value: paid,
    },
    unpaid: {
      title: t('unpaid'),
      value: unpaid,
    },
  };
  return <ReferralDataSection data={data} explanations={explanations} desciptions={desciptions} />;
};

ReferralCommissions.defaultProps = {
};

ReferralCommissions.propTypes = {
  commissionStats: PropTypes.shape(
    {
      paid: PropTypes.string,
      unpaid: PropTypes.string,
      total: PropTypes.string,
    },
  ).isRequired,
};

export default ReferralCommissions;
