export const adminEarningTableStyles = (theme) => ({
  title: {
    width: '100%',
    paddingLeft: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
  },
  statusDialog: {
    padding: theme.spacing(4),
  },
  confirmDialog: {
    padding: theme.spacing(4, 4, 0, 4),
  },
  sendEmailButton: {
    padding: theme.spacing(1.5, 5),
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  confirmTableDivider: {
    margin: theme.spacing(0, 4),
  },
  sendButtonContainer: {
    padding: theme.spacing(4, 0),
    ...theme.centerComponent,
  },
});

export const adminMonthlyEarningTableStyles = (theme) => ({
  root: {
    padding: theme.spacing(2),
  },
  loadingCircle: {
    ...theme.centerComponent,
  },
  chainIcon: {
    width: 20,
    height: 20,
  },
  chainIconContainer: {
    ...theme.centerComponent,
  },
  testModeTitle: {
    fontSize: 12,
  },
  subTitle: {
    padding: theme.spacing(0, 0, 4, 2),
  },
  reFetchButton: {
    padding: theme.spacing(0),
  },
});
