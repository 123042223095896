import * as R from 'ramda';

const { REACT_APP_ENV } = process.env;

export const explorerUrls = {
  dot: {
    pha: {
      dev: 'https://phala-testnet.subscan.io',
      stage: 'https://phala-testnet.subscan.io',
      prod: 'https://khala.subscan.io',
    },
  },
};

export const createGetExplorerUrl = R.curry((REACT_APP_ENV, explorerUrls, chain, subChain) => {
  const chainUrls = explorerUrls[chain];
  if (!chainUrls) throw new Error('Chain explorer not found');
  return explorerUrls[chain][subChain][REACT_APP_ENV] || explorerUrls[chain][subChain].dev;
});

export const getExplorerUrl = createGetExplorerUrl(REACT_APP_ENV, explorerUrls);
