import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Grid from '@material-ui/core/Grid';
import Skeleton from '@material-ui/lab/Skeleton';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import React from 'react';

const EarningDataCard = (props) => {
  const {
    title,
    content,
    loading,
    loadingVariant,
  } = props;

  const getContent = () => {
    if (loading) {
      return (
        <Grid
          container
          spacing={3}
          justifyContent="center"
          alignItems="center"
        >
          <Grid item xs={loadingVariant === 'circle' ? 'auto' : 12}>
            <Skeleton
              variant={loadingVariant || 'text'}
              width={loadingVariant === 'circle' ? 100 : '100%'}
              height={loadingVariant === 'circle' ? 100 : '100%'}
            />
          </Grid>
          {loadingVariant === 'text' && (
          <Grid item xs={12}>
            <Skeleton
              variant="text"
              width="100%"
            />
          </Grid>
          )}
        </Grid>
      );
    }

    return content;
  };

  return (
    <Card style={{ height: '100%' }}>
      <CardHeader
        title={R.toUpper(title)}
        titleTypographyProps={{
          align: 'center',
          variant: 'h3',
          color: 'textSecondary',
        }}
        style={{
          fontWeight: 600,
          padding: '16px 0px',
          fontFamily: [
            'Noto-sans',
            'Sans-serif',
          ].join(','),
        }}
      />
      <CardContent>
        {getContent()}
      </CardContent>
    </Card>
  );
};

EarningDataCard.defaultProps = {
  loading: false,
  loadingVariant: 'text',
};

EarningDataCard.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.element.isRequired,
  loading: PropTypes.bool,
  loadingVariant: PropTypes.string,
};

export default EarningDataCard;
