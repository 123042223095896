export const GET_USER_STRIPE_DATA_SUCCESS = 'GET_USER_STRIPE_DATA_SUCCESS';
export const GET_USER_STRIPE_PAYMENT_METHODS_SUCCESS = 'GET_USER_STRIPE_PAYMENT_METHODS_SUCCESS';
export const GET_USER_STRIPE_DATA_CANCEL = 'GET_USER_STRIPE_DATA_CANCEL';
export const SET_STRIPE_PRICE = 'SET_STRIPE_PRICE';
export const CREATE_USER_STRIPE_PAYMENT_METHODS_SUCCESS = 'CREATE_USER_STRIPE_PAYMENT_METHODS_SUCCESS';
export const DETACH_STRIPE_PAYMENT_METHOD = 'DETACH_STRIPE_PAYMENT_METHOD';
export const UPDATE_STRIPE_PROMOTION_CODE = 'UPDATE_STRIPE_PROMOTION_CODE';
export const CANCEL_SUBSCRIPTION_SUCCESS = 'CANCEL_SUBSCRIPTION_SUCCESS';
export const CHANGE_SUBSCRIPTION_PLAN_SUCCESS = 'CHANGE_SUBSCRIPTION_PLAN_SUCCESS';
export const VALIDATE_PROMOTION_CODE_SUCCESS = 'VALIDATE_PROMOTION_CODE_SUCCESS';
