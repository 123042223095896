import * as langActions from './actions';
import * as langOperations from './operations';
import reducer from './reducers';
import * as langTypes from './types';

export {
  langOperations,
  langActions,
  langTypes,
};

export default reducer;
