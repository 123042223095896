import * as R from 'ramda';
import storage from 'redux-persist/lib/storage';

const { REACT_APP_ENV, REACT_APP_TEST } = process.env;

const baseConfig = {
  key: 'root',
  storage,
};

const baseWhiteList = ['userR', 'langR', 'walletR'];
const whitelist = REACT_APP_ENV === 'dev' || REACT_APP_TEST
  ? R.concat([], baseWhiteList)
  : baseWhiteList;

export const persistConfig = {
  ...baseConfig,
  whitelist,
  blacklist: ['secretPhraseR'],
};
