import * as R from 'ramda';
import { combineReducers } from 'redux';
import { PURGE } from 'redux-persist';

import {
  createReducer,
} from '../store_utils';

import * as types from './types';
// utils

const walletIS = {
  selectedAccount: {},
  walletConnectMethod: '',
};

const walletRUpdaters = {
  [types.SET_WALLET_SELECTED_ACCOUNT]: (
    state,
    payload,
  ) => R.set(R.lensPath(['selectedAccount']), payload)(state),
  [PURGE]: R.always(walletIS),
  [types.RESET_WALLET_SELECTED_ACCOUNT]: (state) => R.set(R.lensPath(['selectedAccount']), {})(state),
  [types.SET_WALLET_CONNECT_METHOD]: (state, payload) => R.set(R.lensPath(['walletConnectMethod']), payload)(state),
};

const walletR = createReducer(walletIS, walletRUpdaters);

const reducers = combineReducers({
  wallet: walletR,
});

export default reducers;
