import { formatEther } from '@ethersproject/units';
import toNumber from 'lodash/toNumber';
import * as R from 'ramda';

import { utils } from '../crypto/index';

import * as types from './types';

export const updateTransactionStatus = R.curry((
  pubkey,
  status,
  txHash,
) => ({
  type: types.UPDATE_TRANSACTION_STATUS,
  payload: {
    pubkey,
    status,
    txHash,
  },
}));

export const getBalance = R.curry(
  async (library, account) => {
    const amount = await library.getBalance(account);

    const formattedBalance = toNumber(
      parseFloat(formatEther(amount)).toPrecision(5),
    );

    return {
      type: types.GET_BALANCE_SUCCESS,
      payload: {
        account,
        balance: formattedBalance,
        done: true, // to determine whether the fetching done
      },
    };
  },
);

export const generateAccounts = R.curry((chain, data) => {
  const { generateAccounts } = utils.getCoinMethods(chain).accounts;
  const accountsData = generateAccounts(data);
  return {
    type: types.GENEREATE_ACCOUNTS_SUCCESS,
    payload: {
      done: true,
      chain,
      data: accountsData,
    },
  };
});

export const backupAccount = R.curry((chain, data) => {
  const { backupAccount } = utils.getCoinMethods(chain).accounts;
  const json = backupAccount(data);
  return {
    type: types.BACKUP_ACCOUNTS_SUCCESS,
    payload: json,
  };
});

export const broadcastTransaction = R.curry(
  async (
    chain,
    subChain,
    selectedMethod,
    data,
  ) => {
    const { broadcastTransaction } = utils.getCoinMethods(chain).transactions;
    const {
      api,
      sender,
      amount,
      recipient,
      keepAlive,
      transferMethod,
    } = data;
    const txdata = await broadcastTransaction(
      selectedMethod,
      {
        api,
        sender,
        amount,
        recipient,
        keepAlive,
        transferMethod,
      },
    );
    return {
      type: types.BROADCAST_TRANSACTION_SUCCESS,
      payload: {
        data: txdata,
        chain,
        subChain,
        done: true,
      },
    };
  },
);

export const resetTransactionDone = () => ({
  type: types.RESET_TRANSACTION_DONE,
});

export const resetTransactionHash = R.curry((chain, subChain) => ({
  type: types.RESET_TRANSACTION_HASH,
  payload: {
    chain,
    subChain,
  },
}));

export const resetTransactionInfo = R.curry((chain, subChain) => ({
  type: types.RESET_TRANSACTION_INFO,
  payload: {
    chain,
    subChain,
  },
}));
export const initializeApi = R.curry(
  async (chain, endpoint) => {
    const { intializeApi } = utils.getCoinMethods(chain).api;
    const api = await intializeApi(endpoint);
    await api.isReady;
    return { payload: api, type: types.INITIALIZE_API_SUCCESS };
  },
);

export const getTransactionInfo = R.curry(
  async (chain, data) => {
    const { getTransactionInfo } = utils.getCoinMethods(chain).transactions;
    const txInfo = await getTransactionInfo(data);
    return { payload: { data: txInfo, chain }, type: types.GET_TRANSACTION_INFO_SUCCESS };
  },
);

export const getAccountInfo = R.curry(
  async (chain, subChain, data) => {
    const { getAccountInfo } = utils.getCoinMethods(chain).accounts;
    const accountInfo = await getAccountInfo(data);
    const { address } = data;
    return {
      payload: {
        data: accountInfo, chain, subChain, address,
      },
      type: types.GET_ACCOUNT_INFO_SUCCESS,
    };
  },
);

export const setWalletSelectedAccount = R.curry(
  (payload) => ({
    payload,
    type: types.SET_WALLET_SELECTED_ACCOUNT,
  }),
);
