/* eslint-disable no-shadow */
import { ApiPromise, WsProvider } from '@polkadot/api';
import { pipeAwait } from 'jsutils';
import * as R from 'ramda';

import { typedefs } from './typedefs';

const createWsProvider = R.curry((enpoint) => {
  const wsProvider = new WsProvider(enpoint || process.env.REACT_APP_PHALA_WS_ENDPOINT);
  return wsProvider;
});

const createApi = R.curry(async (typedefs, wsProvider) => {
  const api = await ApiPromise.create({ provider: wsProvider, types: typedefs });
  return api;
});

const createIntializeApi = R.curry(
  async (typedefs, enpoint) => pipeAwait(
    createWsProvider,
    createApi(typedefs),
  )(enpoint),
);

export const intializeApi = createIntializeApi(typedefs);

export const getExistentialDeposit = (api) => api.consts.balances.existentialDeposit;
