import * as R from 'ramda';

import { errorOperations } from '../error/index';
import { loadingOperations } from '../loading/index';

import * as actions from './actions';

export const sendContactUsEmail = R.curry(
  async (
    name,
    company,
    email,
    message,
    metadata,
    dispatch,
  ) => {
    const operationName = 'sendContactUsEmail';
    try {
      dispatch(loadingOperations.setLoading(operationName, true));
      const { type, payload } = dispatch(
        await actions.sendContactUsEmail(
          name,
          company,
          email,
          message,
          metadata,
        ),
      );
      dispatch(loadingOperations.setLoading(operationName, false));
      dispatch(errorOperations.clearError(operationName));
      return { type, payload };
    } catch (error) {
      dispatch(loadingOperations.setLoading(operationName, false));
      return dispatch(errorOperations.setError(
        operationName,
        { error, message: 'Send user contact email failed' },
      ));
    }
  },
);

export const { openContactForm, closeContactForm } = actions;
