import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import classnames from 'classnames';
import * as R from 'ramda';
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
// toastify
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

import {
  useAddFunctionToRef,
  useGetQuery,
  useUserDataSelector,
} from '../../hooks/hooks';
import { notFoundPageRoute, marketRoute, adminDashboardRoute } from '../../routes';
import { errorServices } from '../../store/error/index';
import { navigationOperations } from '../../store/navigation/index';
import { routerServices } from '../../store/router/index';
import { userOperations } from '../../store/user/index';
import {
  RESEND_ACTIVATION,
} from '../../store/user/types';

import { styles } from './styles';
// store

const useStyles = makeStyles(styles);

const SignupSuccess = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();

  const history = useHistory();
  const queryLng = useGetQuery('lng');
  const user = useUserDataSelector();
  const {
    activated, email, userId, role,
  } = user;

  const [disableButton, setDisableButton] = useState(false);

  const { lang } = useSelector((state) => state.langR.langSetting);

  const resendActivation = R.curry(async (userId, lang) => {
    setDisableButton(true);
    const response = await dispatch(userOperations.resendActivation(userId, lang));
    const errorMessage = R.either(
      R.view(R.lensPath(['payload', 'value', 'error', 'message'])),
      R.view(R.lensPath(['payload', 'message'])),
    )(response);

    if (response.type !== RESEND_ACTIVATION) {
      if (!errorServices.shouldRenderErrorAlert(response.payload)) {
        toast.error(`${t('Resend activation email failed')} - ${errorMessage}`, {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    } else {
      toast.success(t('Resend activation email success'), {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }

    setDisableButton(false);
  });

  const onSelectSidebarItem = useAddFunctionToRef((index) => {
    dispatch(navigationOperations.selectSidebarItem(index));
  });
  useEffect(() => {
    if (!email) {
      return routerServices.historyPush(
        history,
        {
          pathname: notFoundPageRoute,
          queryLng,
        },
      );
    }
  }, [onSelectSidebarItem, queryLng, email, history]);

  useEffect(() => {
    if (activated) {
      const sideBarItemIndex = role === 'ADMIN' ? 0 : 1;
      const redirectRoute = role === 'ADMIN' ? adminDashboardRoute : marketRoute;
      onSelectSidebarItem(sideBarItemIndex);
      return routerServices.historyPush(
        history,
        {
          pathname: redirectRoute,
          queryLng,
        },
      );
    }
  }, [onSelectSidebarItem, queryLng, activated, history, role]);

  return (
    <Box className={classnames(classes.paper)}>
      <Typography variant="h2" align="center" className={classnames(classes.centerComponent, classes.title)}>
        <Box fontWeight={600} display="inline">{t('Confirm your email')}</Box>
      </Typography>
      <Box align="center" className={classnames(classes.centerComponent, classes.description)}>
        <Typography component="div">
          {t('We created an account for you. Please check')}
          {' '}
          <Box fontWeight={600} display="inline">{email}</Box>
          {' '}
          {t('to confirm your email address.')}
        </Typography>
      </Box>
      <Divider className={classnames(classes.divider)} />
      <Grid container direction="row" justifyContent="center" alignItems="center" spacing={0}>
        <Typography variant="body1" align="right">{t('Do not receive an email?')}</Typography>
        <Button
          color="primary"
          onClick={() => resendActivation(userId, lang)}
          disabled={disableButton}
        >
          {t('Send again')}
        </Button>
      </Grid>
    </Box>
  );
};

export default SignupSuccess;
