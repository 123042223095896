import { createStore, applyMiddleware } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';

import { middleware } from './store_middleware';
import { persistConfig } from './store_persist';
import { rootReducer } from './store_reducers';

const persistedReducer = persistReducer(persistConfig, rootReducer);
export const store = createStore(persistedReducer, applyMiddleware(...middleware));
export const persistor = persistStore(store);
