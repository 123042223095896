import {
  sumFromList,
} from 'jsutils';
import * as R from 'ramda';

import { fileServices } from '../file/index';

import {
  earningTypeMap,
} from './constants';

export const saveEthValidatorNodeInputs = R.curry(
  async (inputs) => {
    const {
      nodeType,
      keystoreFile,
      keystoreFilePassword,
    } = inputs;
    const fileToStoreToUse = R.head(keystoreFile);
    const { keystoreFilePath } = await fileServices.uploadFileToS3(
      nodeType,
      fileToStoreToUse,
    );
    return {
      keystoreFilePath,
      keystoreFilePassword,
    };
  },
);

export const saveNodeDeployInputsExtraFns = {
  ETH2_VALIDATOR_NODE: saveEthValidatorNodeInputs,
  DOT_VALIDATOR_NODE: null,
};

export const formatSubNodeDataFns = {
  ETH2_VALIDATOR_NODE: (data) => R.pick(['credentials', 'keystoreFilePassword'], data),
};

export const createFormatSubNodeData = R.curry(
  (formatSubNodeDataFns, nodeType, data) => {
    const fn = formatSubNodeDataFns[nodeType];
    return fn(data);
  },
);

export const formatSubNodeData = createFormatSubNodeData(formatSubNodeDataFns);

const calNeunodeEarning = R.curry((state, earningData) => {
  const {
    earnings,
    earningType,
  } = earningData;
  const parsedEarningType = earningTypeMap[earningType];

  const total = sumFromList('convertedTotal', earnings || []);
  const totalInCoin = sumFromList('originalTotal', earnings);
  const totalBalance = sumFromList('convertedCurrentBalance', earnings);
  const totalBalanceInCoin = sumFromList('originalCurrentBalance', earnings);

  const previousInterval = R.view(R.lensPath(['interval']), state) || {};
  const previousIntervalToUse = R.view(R.lensPath(['interval', parsedEarningType]), state) || {};

  const previousTotal = R.view(R.lensPath(['total']), state) || {};
  return {
    total,
    totalInCoin,
    totalBalance,
    totalBalanceInCoin,
    previousInterval,
    previousIntervalToUse,
    previousTotal,
  };
});

const extractLatestLendingEarnings = (earnings) => R.pipe(
  R.sortWith(
    [
      R.descend(R.prop('orderId')),
      R.descend(R.prop('endTime')),
    ],
  ),
  R.groupWith((item, nextItem) => R.prop('orderId', item) === R.prop('orderId', nextItem)),
  R.map((group) => R.head(group)),
)(earnings);

const calLendingEarning = R.curry((state, earningData) => {
  const {
    earnings,
    earningType,
  } = earningData;
  const parsedEarningType = earningTypeMap[earningType];
  // take only latest earning from each orderId items
  const latestEarnings = extractLatestLendingEarnings(earnings);
  // total, totalInCoin from earning because they are not cummulative
  const total = sumFromList('convertedTotal', earnings || []);

  const totalInCoin = sumFromList('originalTotal', earnings);
  // totalBalance, totalBalanceInCoin sum from latest earning because they are cummulative
  const totalBalance = sumFromList('convertedCurrentBalance', latestEarnings);
  const totalBalanceInCoin = sumFromList('originalCurrentBalance', latestEarnings);

  const previousInterval = R.view(R.lensPath(['interval']), state) || {};
  const previousIntervalToUse = R.view(R.lensPath(['interval', parsedEarningType]), state) || {};

  const previousTotal = R.view(R.lensPath(['total']), state) || {};
  return {
    total,
    totalInCoin,
    totalBalance,
    totalBalanceInCoin,
    previousInterval,
    previousIntervalToUse,
    previousTotal,
  };
});
const calEarningsFns = {
  NEUNODE: calNeunodeEarning,
  LENDING: calLendingEarning,
};
const createHandleTotalEarningBaseOnType = R.curry(
  (calEarningsFns, state, earningType, earningData) => {
    const handleTotalEarningFn = calEarningsFns[earningType] || calEarningsFns.NEUNODE;
    return handleTotalEarningFn(state, earningData);
  },
);

export const handleTotalEarningBaseOnType = createHandleTotalEarningBaseOnType(calEarningsFns);
