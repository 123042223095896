import {
  pipeAwait,
} from 'jsutils';
import * as R from 'ramda';

import {
  graphqlNormalize,
} from '../../modules/apollo/apollo';
import {
  returnAction,
} from '../store_utils';

import {
  graphqlGetAffiliateQuery,
} from './graphql/services';
import * as types from './types';

export const getAffiliate = R.curry(
  async (userId) => (
    pipeAwait(
      graphqlGetAffiliateQuery,
      graphqlNormalize('getAffiliate'),
      returnAction(types.GET_AFFLIATE_SUCCESS),
    )({
      getAffiliateInput: {
        query: {
          user: {
            userId,
          },
        },
      },
    })
  ),
);
