import DateFnsUtils from '@date-io/date-fns';
import { makeStyles } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Dialog from '@material-ui/core/Dialog';
import Divider from '@material-ui/core/Divider';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';
import Select from '@material-ui/core/Select';
import Switch from '@material-ui/core/Switch';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import AddCircleOutlineOutlinedIcon from '@material-ui/icons/AddCircleOutlineOutlined';
import DeleteForeverOutlinedIcon from '@material-ui/icons/DeleteForeverOutlined';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import whyDidYouRender from '@welldone-software/why-did-you-render';
import {
  pipeAwait,
} from 'jsutils';
import { startCase } from 'lodash';
import isEmpty from 'lodash/fp/isEmpty';
import toNumber from 'lodash/fp/toNumber';
import moment from 'moment';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';

import {
  useIsMobile,
} from '../../../hooks/hooks';
import { RoundedButton, CloseIconButton } from '../../MaterialUiCustom/MaterialUiCustom';
import { createOrdersStyles } from '../styles';

// others
import { validateCreateOrders } from './validate';

if (process.env.REACT_APP_ENV !== 'prod') {
  whyDidYouRender(React);
}

const useStyles = makeStyles(createOrdersStyles);

const CreateOrders = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const isMobileDevice = useIsMobile();
  const { t } = useTranslation();

  const priceIds = useSelector((state) => state.stripeR.stripePrice.priceIds);
  const allNodes = useSelector((state) => R.view(R.lensPath(['adminR', 'adminNode', 'nodeList', 'nodeList', 'entities', 'nodes']), state)) || [];

  const {
    onClose,
    open,
    onCreateOrders,
  } = props;

  const [data, setData] = useState({});
  const [errors, setErrors] = useState({});
  const clearData = () => setData({});

  const createOrdersLoading = useSelector((state) => state.loadingR.createOrdersLoading);

  const handleOnClose = () => {
    onClose();
    setData({});
    setErrors({});
  };

  const updateData = R.curry((index, propName, value) => {
    pipeAwait(
      R.assocPath([index, propName], value),
      (updatedData) => {
        setData(updatedData);
        return updatedData;
      },
      async (updatedData) => {
        const error = await validateCreateOrders(t, dispatch, R.values(updatedData), updatedData[index], R.values(allNodes));
        const errorsToUpdate = R.assocPath([index], error, errors);
        setErrors(errorsToUpdate);
      },
    )(data);
  });
  const updateDataSwitch = R.curry((index, propName, event) => {
    event.persist();
    const { checked } = event.target;
    R.pipe(
      R.assocPath([index, propName], checked),
      setData,
    )(data);
  });

  const addEmptyItem = () => {
    const index = R.length(R.values(data));
    setData(R.assocPath(
      [index],
      {
        email: '',
        quantity: 1,
        orderType: 'NODE_PROVIDER_MINING',
        ignorePayment: false,
        nodeType: 'UNUNIFI_VALIDATOR_NODE',
        contractType: 'PHALA_NETWORK_MINING',
        contractPeriodValue: 1,
        contractPeriod: 'year',
        contractSignDate: moment(),
        contractStartDate: moment(),
        amount: 0,
        currencySymbol: 'JPY',
        paymentMethod: 'BANK_TRANSFER',
        trialDays: 0,
        paymentType: 'ONETIME',
        unitCap: 5000,
        poolCreated: false,
        commissionSet: false,
        capSet: false,
        payoutAddress: null,
        commission: '',
        timelineOrderStepStatus: 'COMPLETE',
        timelinePaymentStepStatus: 'PROCESSING',
        timelineSetupStepStatus: 'NOT_REACHED',
        timelineCompleteSetupStepStatus: 'NOT_REACHED',
        timelineOrderStepCompletedDate: moment(),
        timelinePaymentStepCompletedDate: null,
        timelineSetupStepCompletedDate: null,
        timelineCompleteSetupStepCompletedDate: null,
        activeDate: null,
      },
      data,
    ));
    setErrors(R.assocPath(
      [index],
      {
        new: true,
      },
      errors,
    ));
  };

  const removeItem = (index) => {
    R.pipe(
      R.values,
      R.remove(index, 1),
      R.addIndex(R.map)((item, index) => [index, item]),
      R.fromPairs,
      setData,
    )(data);
    R.pipe(
      R.values,
      R.remove(index, 1),
      R.addIndex(R.map)((item, index) => [index, item]),
      R.fromPairs,
      setErrors,
    )(errors);
  };

  const getFieldContent = R.curry((index, errors, type, label, name, value, required, props = {}, placeholder = null) => (
    <Grid item xs={8}>
      <TextField
        defaultValue={value}
        error={!!errors[name]}
        helperText={errors[name]}
        placeholder={placeholder}
        label={label}
        variant="outlined"
        name={name}
        required={required}
        type={type}
        className={classes.formField}
        onBlur={(event) => updateData(index, name, type === 'number' ? toNumber(event.target.value) : event.target.value)}
        {...props}
      />
    </Grid>
  ));

  const getSwitchFieldContent = R.curry((index, label, name, value) => (
    <Grid item xs={8}>
      <Box className={classes.formFieldSwitch}>
        <Box display="flex" alignItems="center">
          <Box pr={3} flex={0.9}>
            <Typography color="textSecondary">
              {label}
            </Typography>
          </Box>
          <Box flex={0.1}>
            <Switch
              checked={value}
              name={name}
              color="primary"
              onChange={updateDataSwitch(index, name)}
            />
          </Box>
        </Box>
        <Typography color="textSecondary" className={classes.formFieldSwitchHelperText}>
          {t('False if create a new order')}
        </Typography>
      </Box>
    </Grid>
  ));

  const getSelectFieldContent = R.curry((index, label, name, value, required, items) => (
    <Grid item xs={8}>
      <FormControl required={required} variant="outlined" className={classes.formField}>
        <InputLabel>{label}</InputLabel>
        <Select
          value={value}
          name={name}
          label={label}
          variant="outlined"
          onChange={(event) => updateData(index, name, event.target.value)}
        >
          {R.map((item) => {
            const { key, value } = item;
            return <MenuItem value={value} key={`select_item_${key}`}>{key}</MenuItem>;
          })(items)}
        </Select>
      </FormControl>
    </Grid>
  ));

  const getDatePickerFieldContent = R.curry((index, label, name, value, required) => (
    <Grid item xs={8}>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <KeyboardDatePicker
          value={value}
          label={label}
          format="MM/dd/yyyy HH:mm:ss"
          InputProps={{ readOnly: true }}
          inputVariant="outlined"
          required={required}
          onChange={(value) => updateData(index, name, value)}
          DialogProps={{ disableBackdropClick: true }}
          className={classes.formField}
        />
      </MuiPickersUtilsProvider>
    </Grid>
  ));

  const getTimelineFieldContent = (index, data) => {
    const {
      errors,
      label,
      name,
      timelineStepStatus,
      timelineStepCompletedDate,
      required,
      activeDate,
    } = data;
    return (
      <>
        <Grid item xs={8}>
          <Typography color="textSecondary" className={classes.stepFieldTitle}>
            {startCase(label)}
          </Typography>
        </Grid>
        <Grid item xs={8}>
          <Select
            name={`timeline${name}StepStatus`}
            value={timelineStepStatus}
            label={t('Status')}
            variant="outlined"
            required={required}
            className={classes.formField}
            onChange={(event) => updateData(index, `timeline${name}StepStatus`, event.target.value)}
          >
            <MenuItem value="NOT_REACHED">NOT_REACHED</MenuItem>
            <MenuItem value="PROCESSING">PROCESSING</MenuItem>
            <MenuItem value="COMPLETE">COMPLETE</MenuItem>
          </Select>
        </Grid>
        <Grid item xs={8}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              value={timelineStepCompletedDate}
              error={!!errors[`timeline${name}StepCompletedDate`]}
              helperText={errors[`timeline${name}StepCompletedDate`]}
              label={t('Completed Date')}
              format="MM/dd/yyyy HH:mm:ss"
              InputProps={{ readOnly: true }}
              inputVariant="outlined"
              required={timelineStepStatus === 'COMPLETE'}
              disabled={timelineStepStatus !== 'COMPLETE'}
              onChange={(value) => updateData(index, `timeline${name}StepCompletedDate`, value)}
              DialogProps={{ disableBackdropClick: true }}
              className={classes.formField}
            />
          </MuiPickersUtilsProvider>
        </Grid>
        {name === 'CompleteSetup' && (
        <Grid item xs={8}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              value={activeDate}
              error={!!errors.activeDate}
              helperText={errors.activeDate}
              label={t('Active Date')}
              format="MM/dd/yyyy HH:mm:ss"
              InputProps={{ readOnly: true }}
              inputVariant="outlined"
              required={timelineStepStatus === 'COMPLETE'}
              disabled={timelineStepStatus !== 'COMPLETE'}
              onChange={(value) => updateData(index, 'activeDate', value)}
              className={classes.formField}
            />
          </MuiPickersUtilsProvider>
        </Grid>
        )}
      </>
    );
  };

  const getContractFieldsContent = R.curry((
    index,
    data,
  ) => {
    const {
      errors,
      orderType,
      contractSignDate,
      contractStartDate,
      contractPeriodValue,
      contractPeriod,
      contractType,
      timelineOrderStepStatus,
      timelinePaymentStepStatus,
      timelineSetupStepStatus,
      timelineCompleteSetupStepStatus,
      timelineOrderStepCompletedDate,
      timelinePaymentStepCompletedDate,
      timelineSetupStepCompletedDate,
      timelineCompleteSetupStepCompletedDate,
      activeDate,
    } = data;

    if (orderType === 'NAAS') {
      return null;
    }
    return (
      <>
        <Grid item xs={8}>
          <Typography
            variant="h3"
            color="textSecondary"
            className={classes.orderSubtitle}
          >
            {`${t('Contract')}`}
          </Typography>
        </Grid>
        {getDatePickerFieldContent(
          index,
          t('Contract Sign Date (UTC)'),
          'contractSignDate',
          contractSignDate,
          true,
        )}
        {getDatePickerFieldContent(
          index,
          t('Contract Start Date (UTC)'),
          'contractStartDate',
          contractStartDate,
          true,
        )}
        {getFieldContent(
          index,
          errors,
          'number',
          t('Contract Period Value'),
          'contractPeriodValue',
          contractPeriodValue,
          true,
          { InputProps: { inputProps: { min: 1 } } },
        )}
        {getSelectFieldContent(
          index,
          t('Contract Preiod'),
          'contractPeriod',
          contractPeriod,
          true,
          [
            { key: 'year', value: 'year' },
            { key: 'month', value: 'month' },
          ],
        )}
        {getSelectFieldContent(
          index,
          t('Contract Type'),
          'contractType',
          contractType,
          true,
          [
            { key: 'NODE_PROVIDER_110', value: 'NODE_PROVIDER_110' },
            { key: 'NODE_PROVIDER_30', value: 'NODE_PROVIDER_30' },
            { key: 'PHALA_NETWORK_MINING', value: 'PHALA_NETWORK_MINING' },
            { key: 'LENDING', value: 'LENDING' },
          ],
        )}
        <Grid item xs={8}>
          <Typography
            variant="h3"
            color="textSecondary"
            className={classes.orderSubtitle}
          >
            {`${t('Timeline')}`}
          </Typography>
        </Grid>
        {getTimelineFieldContent(index, {
          errors,
          label: t('Order'),
          name: 'Order',
          timelineStepStatus: timelineOrderStepStatus,
          timelineStepCompletedDate: timelineOrderStepCompletedDate,
          required: true,
        })}
        {getTimelineFieldContent(index, {
          errors,
          label: t('Payment'),
          name: 'Payment',
          timelineStepStatus: timelinePaymentStepStatus,
          timelineStepCompletedDate: timelinePaymentStepCompletedDate,
          required: true,
        })}
        {getTimelineFieldContent(index, {
          errors,
          label: t('Setup'),
          name: 'Setup',
          timelineStepStatus: timelineSetupStepStatus,
          timelineStepCompletedDate: timelineSetupStepCompletedDate,
          required: true,
        })}
        {getTimelineFieldContent(index, {
          errors,
          label: t('Complete Setup'),
          name: 'CompleteSetup',
          timelineStepStatus: timelineCompleteSetupStepStatus,
          timelineStepCompletedDate: timelineCompleteSetupStepCompletedDate,
          required: true,
          activeDate,
        })}
      </>
    );
  });

  const getPaymentFieldsContent = R.curry((
    index,
    data,
  ) => {
    const {
      errors,
      orderType,
      nodeType,
      paymentMethod,
      amount,
      currencySymbol,
      trialDays,
      stripeInvoiceId,
      stripePaymentMethodId,
      paymentType,
    } = data;

    if (orderType === 'LENDING') {
      return null;
    }

    return (
      <>
        <Grid item xs={8}>
          <Typography
            variant="h3"
            color="textSecondary"
            className={classes.orderSubtitle}
          >
            {`${t('Payment')}`}
          </Typography>
        </Grid>
        {getSelectFieldContent(
          index,
          t('Payment Method'),
          'paymentMethod',
          paymentMethod,
          true,
          orderType === 'NAAS' && nodeType === 'ETH2_VALIDATOR_NODE' ? [
            { key: 'FIAT', value: 'FIAT' },
            { key: 'CRYPTO', value: 'CRYPTO' },
            { key: 'STRIPE_CARD', value: 'STRIPE_CARD' },
            { key: 'BANK_TRANSFER', value: 'BANK_TRANSFER' },
          ] : [
            { key: 'FIAT', value: 'FIAT' },
            { key: 'CRYPTO', value: 'CRYPTO' },
            { key: 'BANK_TRANSFER', value: 'BANK_TRANSFER' },
          ],
        )}
        {getFieldContent(
          index,
          errors,
          'number',
          t('Amount'),
          'amount',
          amount,
          true,
          { InputProps: { inputProps: { min: 0 } } },
        )}
        {getSelectFieldContent(
          index,
          t('Currency'),
          'currencySymbol',
          currencySymbol,
          true,
          [
            { key: 'JPY', value: 'JPY' },
            { key: 'USD', value: 'USD' },
          ],
        )}
        {getFieldContent(
          index,
          errors,
          'number',
          t('Trial Days'),
          'trialDays',
          trialDays,
          false,
          { InputProps: { inputProps: { min: 0 } } },
        )}
        {paymentMethod !== 'STRIPE_CARD' && getFieldContent(index, errors, 'text', t('Stripe InvoiceId Id'), 'stripeInvoiceId', stripeInvoiceId, true)}
        {paymentMethod === 'STRIPE_CARD' && getFieldContent(index, errors, 'text', t('Stripe Payment Method Id'), 'stripePaymentMethodId', stripePaymentMethodId, true)}
        {getSelectFieldContent(
          index,
          t('Payment Type'),
          'paymentType',
          paymentType,
          true,
          [
            { key: 'UNUSED', value: 'UNUSED' },
            { key: 'ONETIME', value: 'ONETIME' },
            { key: 'SUBSCRIPTION', value: 'SUBSCRIPTION' },
          ],
        )}
      </>
    );
  });

  const getNaasContent = (index, data) => {
    const {
      errors, nodeType, stripePriceId, nodesName,
    } = data;
    return (
      <>
        <Grid item xs={8}>
          <Typography
            variant="h3"
            color="textSecondary"
            className={classes.orderSubtitle}
          >
            {`${t('Nodes Info')}`}
          </Typography>
        </Grid>
        {getSelectFieldContent(
          index,
          t('Node Type'),
          'nodeType',
          nodeType,
          true,
          [
            // { key: 'ETH2_VALIDATOR_NODE', value: 'ETH2_VALIDATOR_NODE' },
            { key: 'UNUNIFI_VALIDATOR_NODE', value: 'UNUNIFI_VALIDATOR_NODE' },
          ],
        )}
        {nodeType === 'ETH2_VALIDATOR_NODE' && getSelectFieldContent(
          index,
          t('Period'),
          'stripePriceId',
          stripePriceId,
          true,
          R.pipe(
            R.toPairs,
            R.map((pricing) => {
              const key = pricing[0];
              const value = pricing[1];

              return { key, value };
            }),
          )(priceIds[nodeType] || {}),
        )}
        {nodeType === 'UNUNIFI_VALIDATOR_NODE' && getFieldContent(
          index,
          errors,
          'text',
          t('Nodes Name'),
          'nodesName',
          nodesName,
          true,
          {},
          'nodeName1, nodeName2,...',
        )}
      </>
    );
  };

  const getPhalaPoolFieldsContent = R.curry((
    index,
    data,
  ) => {
    const {
      errors,
      orderType,
      unitCap,
      poolCreated,
      commissionSet,
      capSet,
      payoutAddress,
      commission,
    } = data;

    if (orderType !== 'NODE_PROVIDER_MINING') {
      return null;
    }

    return (
      <>
        <Grid item xs={8}>
          <Typography
            variant="h3"
            color="textSecondary"
            className={classes.orderSubtitle}
          >
            {`${t('Phala Pool Data')}`}
          </Typography>
        </Grid>
        {getFieldContent(
          index,
          errors,
          'number',
          t('Unit Cap'),
          'unitCap',
          unitCap,
          true,
          { InputProps: { inputProps: { min: 1 } } },
        )}
        {getSwitchFieldContent(index, t('Pool Created'), 'poolCreated', poolCreated)}
        {getSwitchFieldContent(index, t('Commission Set'), 'commissionSet', commissionSet)}
        {getSwitchFieldContent(index, t('Cap Set'), 'capSet', capSet)}
        {getFieldContent(
          index,
          errors,
          'text',
          t('Payout Address'),
          'payoutAddress',
          payoutAddress,
          false,
        )}
        {getFieldContent(
          index,
          errors,
          'text',
          t('Commission'),
          'commission',
          commission,
          true,
        )}
      </>
    );
  });

  const getItemContent = (index, data) => {
    const {
      email,
      quantity,
      orderType,
      ignorePayment,
      nodesName,
      nodeType,
      contractSignDate,
      contractStartDate,
      contractPeriodValue,
      contractPeriod,
      contractType,
      paymentMethod,
      amount,
      currencySymbol,
      trialDays,
      stripeInvoiceId,
      stripePaymentMethodId,
      stripePriceId,
      paymentType,
      unitCap,
      poolCreated,
      commissionSet,
      capSet,
      payoutAddress,
      commission,
      timelineOrderStepStatus,
      timelinePaymentStepStatus,
      timelineSetupStepStatus,
      timelineCompleteSetupStepStatus,
      timelineOrderStepCompletedDate,
      timelinePaymentStepCompletedDate,
      timelineSetupStepCompletedDate,
      timelineCompleteSetupStepCompletedDate,
      activeDate,
    } = data;
    const error = errors[index];

    return (
      <Grid container justifyContent="center" spacing={4} className={classes.formGrid}>
        <Grid item xs={8}>
          <Box display="flex" alignItems="flex-end">
            <Box>
              <Typography
                variant="h2"
              >
                {`${t('Order')} ${index + 1}: ${startCase(orderType)}`}
              </Typography>
            </Box>
            <Box pl={1} mb={0.4}>
              <IconButton style={{ padding: 0 }} onClick={() => removeItem(index)}>
                <DeleteForeverOutlinedIcon color="error" />
              </IconButton>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={8}>
          <Typography
            variant="h3"
            color="textSecondary"
            className={classes.orderSubtitle}
          >
            {`${t('Info')}`}
          </Typography>
        </Grid>
        {getFieldContent(index, error, 'text', t('Email'), 'email', email, true)}
        {getFieldContent(
          index,
          error,
          'number',
          t('Quantity'),
          'quantity',
          quantity,
          true,
          { InputProps: { inputProps: { min: 1 } } },
        )}
        {getSelectFieldContent(
          index,
          t('Order Type'),
          'orderType',
          orderType,
          true,
          [
            { key: 'NAAS', value: 'NAAS' },
            { key: 'NODE_PROVIDER_NEUNODE', value: 'NODE_PROVIDER_NEUNODE' },
            { key: 'NODE_PROVIDER_NEUNODE_ETH2', value: 'NODE_PROVIDER_NEUNODE_ETH2' },
            { key: 'NODE_PROVIDER_MINING', value: 'NODE_PROVIDER_MINING' },
            { key: 'LENDING', value: 'LENDING' },
          ],
        )}
        {getSelectFieldContent(
          index,
          t('Ignore Payment'),
          'ignorePayment',
          ignorePayment,
          true,
          [
            { key: 'yes', value: true },
            { key: 'no', value: false },
          ],
        )}
        {orderType === 'NAAS' && getNaasContent(
          index,
          {
            errors: error,
            nodeType,
            stripePriceId,
            nodesName,
          },
        )}
        {getContractFieldsContent(
          index,
          {
            errors: error,
            orderType,
            contractSignDate,
            contractStartDate,
            contractPeriodValue,
            contractPeriod,
            contractType,
            timelineOrderStepStatus,
            timelinePaymentStepStatus,
            timelineSetupStepStatus,
            timelineCompleteSetupStepStatus,
            timelineOrderStepCompletedDate,
            timelinePaymentStepCompletedDate,
            timelineSetupStepCompletedDate,
            timelineCompleteSetupStepCompletedDate,
            activeDate,
          },
        )}
        {getPhalaPoolFieldsContent(
          index,
          {
            errors: error,
            orderType,
            unitCap,
            poolCreated,
            commissionSet,
            capSet,
            payoutAddress,
            commission,
          },
        )}
        {!ignorePayment && getPaymentFieldsContent(
          index,
          {
            errors: error,
            orderType,
            nodeType,
            paymentMethod,
            amount,
            currencySymbol,
            trialDays,
            stripeInvoiceId,
            stripePaymentMethodId,
            paymentType,
          },
        )}
      </Grid>
    );
  };

  const getItemsContent = R.addIndex(R.map)(
    (eachData, index) => (
      <Box key={`create_order_item_divider_${index}`}>
        {index > 1 && <Divider />}
        <Box className={classes.itemContainer}>
          {getItemContent(index, eachData)}
        </Box>
      </Box>
    ),
  );

  const getEmptyContent = () => (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      className={classes.emptyItem}
    >
      <Box>
        <Typography
          color="textSecondary"
          align="center"
          className={classes.emptyItemContent}
        >
          {t('Don\'t have any order to create')}
        </Typography>
      </Box>
    </Box>
  );
  const getAddButtonContent = () => (
    <Box onClick={addEmptyItem} className={classes.addButtonContainer}>
      <AddCircleOutlineOutlinedIcon color="primary" className={classes.addButton} />
    </Box>
  );

  const isError = R.pipe(
    R.values,
    R.map(
      R.pipe(
        (errors) => !R.isEmpty(errors),
      ),
    ),
    R.any(R.equals(true)),
  )(errors) || R.isEmpty(errors);

  return (
    <Dialog
      fullScreen={isMobileDevice}
      fullWidth
      maxWidth="md"
      onClose={handleOnClose}
      open={open}
    >
      <Typography variant="h2" className={classes.dialogTitleText}>
        {t('Create Orders')}
      </Typography>
      <CloseIconButton
        onClick={handleOnClose}
        color="default"
        disabled={createOrdersLoading}
        className={classes.closeButton}
      />
      <Paper className={classes.content}>
        {getItemsContent(R.values(data))}
        {isEmpty(data) && getEmptyContent()}
        <Divider />
        {getAddButtonContent()}
      </Paper>
      <Grid container justifyContent="center" className={classes.actionGrid}>
        <Grid item>
          <RoundedButton
            variant="contained"
            color="primary"
            onClick={() => onCreateOrders(data, clearData)}
            loading={createOrdersLoading}
            disabled={isError}
          >
            { t('create')}
          </RoundedButton>
        </Grid>
        <Grid item>
          <RoundedButton
            variant="contained"
            color="secondary"
            disabled={createOrdersLoading}
            onClick={handleOnClose}
          >
            { t('cancel')}
          </RoundedButton>
        </Grid>
      </Grid>
    </Dialog>
  );
};

CreateOrders.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  onCreateOrders: PropTypes.func.isRequired,
};

CreateOrders.whyDidYouRender = true;
export default React.memo(CreateOrders);
