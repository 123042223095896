import ETH2MyNodeInfoDescriptionValidator from './ETH2MyNodeInfoDescriptionValidator/ETH2MyNodeInfoDescriptionValidator';
import UnUniFiMyNodeInfoDescriptionValidator from './UnUniFiMyNodeInfoDescriptionValidator/UnUniFiMyNodeInfoDescriptionValidator';

const exportObj = {
  ETH2_VALIDATOR_NODE: ETH2MyNodeInfoDescriptionValidator,
  ETH_FULL_NODE: () => null,
  DOT_VALIDATOR_NODE: ETH2MyNodeInfoDescriptionValidator,
  UNUNIFI_VALIDATOR_NODE: UnUniFiMyNodeInfoDescriptionValidator,
};

export default exportObj;
