import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import React from 'react';

import { tabHeadersStyles } from './styles';

const useStyles = makeStyles(tabHeadersStyles);

const TabHeaders = ({
  currentTabIndex,
  tabs,
}) => {
  const classes = useStyles();
  const getTabListContent = (tabs) => R.addIndex(R.map)((eachTab, index) => (
    <React.Fragment key={eachTab.name}>
      <Grid item>
        <Typography
          className={classes.tabName}
          noWrap
          onClick={eachTab.onClick}
          style={{
            opacity: currentTabIndex === index ? 1 : 0.35,
          }}
        >
          {eachTab.name}
        </Typography>
      </Grid>
      {index !== tabs.length - 1 && (
      <Grid item>
        <Divider orientation="vertical" className={classes.divider} />
      </Grid>
      )}
    </React.Fragment>
  ))(tabs);

  return (
    <Grid container spacing={2} direction="row">
      {getTabListContent(tabs)}
    </Grid>
  );
};

TabHeaders.propTypes = {
  currentTabIndex: PropTypes.number.isRequired,
  tabs: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
    content: PropTypes.node.isRequired,
  })).isRequired,
};
export default TabHeaders;
