import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

import ReferralInfoLink from './ReferralInfoLink';
import {
  referralInfoStyles,
} from './styles';

const useStyles = makeStyles(referralInfoStyles);

const ReferralInfo = (props) => {
  const classes = useStyles();

  const { links } = props;
  const { t } = useTranslation();
  return (
    <Card>
      <CardContent className={classes.cardContent}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography variant="h4">
              {t('Invite friends. Earn Crypto')}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1">
              {t(`When your friends deploy a node on our service, you'll get 20% commission fee in crypto.
              There is no limitation for the amount of commission you can earn via referrals.`)}
            </Typography>

          </Grid>
          <Grid item xs={12}>
            <ReferralInfoLink links={links} />
          </Grid>
        </Grid>
      </CardContent>
    </Card>

  );
};

ReferralInfo.defaultProps = {
  links: [
    {
      url: '',
    },
  ],
};

ReferralInfo.propTypes = {
  links: PropTypes.arrayOf(
    PropTypes.shape({
      url: PropTypes.string,
    }),
  ),
};

export default ReferralInfo;
