import {
  pipeAwait,
} from 'jsutils';
import * as R from 'ramda';

import {
  graphqlNormalize,
} from '../../modules/apollo/apollo';
import { formatParameterValidationError } from '../../modules/utils';

import {
  graphqlUploadFileToS3,
} from './graphql/services';

export const uploadFileToS3 = R.curry(
  async (nodeType, file) => (
    pipeAwait(
      graphqlUploadFileToS3,
      graphqlNormalize('uploadFileToS3'),
      (data) => {
        const { keystoreFilePath } = data;
        if (!keystoreFilePath) {
          throw formatParameterValidationError('Upload file failed');
        }
        return data;
      },
    )({ nodeType, file })
  ),
);
