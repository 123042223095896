import { makeStyles } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Grid from '@material-ui/core/Grid';
import BigNumber from 'bignumber.js';
import toNumber from 'lodash/toNumber';
import WAValidator from 'multicoin-address-validator';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import React, { useEffect, useState } from 'react';
import { Form, FormSpy } from 'react-final-form';
import { useDispatch, useSelector } from 'react-redux';

import { Alert, RoundedButton } from '../MaterialUiCustom/MaterialUiCustom';

import WalletTransferBoardAmount from './WalletTransferBoardAmount';
import WalletTransferBoardRecipient from './WalletTransferBoardRecipient';
import WalletTransferBoardSuccess from './WalletTransferBoardSuccess';
import WalletTransferFee from './WalletTransferFee';
import { accountPropType, accountsPropType, balancePropType } from './propTypes';
import { walletTransferBoardStyles } from './styles';

import useAddFunctionToRef from 'hooks/useAddFunctionToRef';
import { planckToToken } from 'store/crypto/dot/utils';
import { web3Operations, web3Selectors } from 'store/web3';

const useStyles = makeStyles(walletTransferBoardStyles);

const WalletTransferBoard = ({
  t,
  walletConnectMethod,
  selectedAccount,
  chain,
  subChain,
  ss58Format,
  api,
  balance,
  priceData,
  onGoBackToSelectMethod,
  accounts,
}) => {
  const [recipient, setRecipient] = useState('');
  const [amount, setAmount] = useState(0);
  const [transferMethod, setTransferMethod] = useState('transferKeepAlive');
  const [keepAlive, setKeepAlive] = useState(true);
  const [openMaxDialog, setOpenMaxDialog] = useState(false);
  const [openSuccessDialog, setOpenSuccessDialog] = useState(false);
  // const [resetForm, setResetForm] = useState(() => {});

  const handleOpenSuccessDialog = useAddFunctionToRef(() => {
    setOpenSuccessDialog(true);
  });

  const handleCloseSuccessDialog = useAddFunctionToRef(() => {
    setOpenSuccessDialog(false);
  });
  const closeSuccessDialog = (event, reason) => {
    if (reason === 'backdropClick') return;
    handleCloseSuccessDialog();
  };
  const handleOpenMaxDialog = () => {
    setOpenMaxDialog(true);
  };

  const handleCloseMaxDialog = () => {
    setOpenMaxDialog(false);
  };
  const dispatch = useDispatch();
  const classes = useStyles();
  const transactionDone = useSelector(web3Selectors.selectTransactionDone);
  const { address: sender } = selectedAccount;
  const { transactionFee } = useSelector(web3Selectors.selectTransactionInfo(chain));
  const broadcastTransactionLoading = useSelector((state) => state.loadingR.broadcastTransactionLoading);
  const broadcastTransactionError = useSelector((state) => state.errorR.broadcastTransactionError);
  const { message: errorMessage } = broadcastTransactionError || {};
  const { txHash } = useSelector(web3Selectors.selectFirstTransaction(chain, subChain));
  const existentialDeposit = api ? planckToToken(api.consts.balances.existentialDeposit.toNumber()) : 0;
  const onGetTransactionInfo = useAddFunctionToRef(
    async (
      chain,
      {
        api,
        recipient,
        sender,
        amount,
      },
    ) => dispatch(await web3Operations.getTransactionInfo(chain, {
      api,
      recipient,
      sender,
      amount,
    })),
  );

  const calMaxAvailable = R.curry(async (setMaxAvailableForm, freeBalance, transactionFee) => {
    handleOpenMaxDialog();
    //  get transaction fee here when user has not typed the amount
    const { payload } = await onGetTransactionInfo(
      chain,
      {
        api,
        recipient,
        sender,
        amount,
      },
    );
    const transactionFeeToUse = transactionFee > 0 ? transactionFee : R.view(R.lensPath(['data', 'transactionFee']), payload);
    const maxAvailable = new BigNumber(freeBalance)
      .minus(new BigNumber(transactionFeeToUse))
      .minus(new BigNumber(existentialDeposit))
      .toNumber();

    const maxAvailableToUse = maxAvailable >= 0 ? maxAvailable : 0;
    setTransferMethod('transferAll');
    // later we can allow user to set keep alive false
    setKeepAlive(true);
    setMaxAvailableForm(maxAvailableToUse);
  });

  const handleResetTransferMethod = (transferMethod) => {
    if (transferMethod === 'transferAll') {
      setTransferMethod('transferKeepAlive');
    }
  };

  const { free } = balance;

  const onBroadcastTransation = R.curry(
    async (
      t,
      chain,
      subChain,
      ss58Format,
      walletConnectMethod,
      data,
    ) => {
      dispatch(web3Operations.broadcastTransaction(t, chain, subChain, ss58Format, walletConnectMethod, data));
    },
  );

  const createOnSubmit = R.curry(
    async (t, chain, subChain, ss58Format, walletConnectMethod, sender, transferMethod, keepAlive, values) => {
      const {
        amount,
        recipient,
      } = values;
      return onBroadcastTransation(
        t,
        chain,
        subChain,
        ss58Format,
        walletConnectMethod,
        {
          api,
          recipient,
          sender,
          amount: toNumber(amount),
          transferMethod,
          keepAlive,
        },
      );
    },
  );

  const onSubmit = createOnSubmit(t, chain, subChain, ss58Format, walletConnectMethod, sender, transferMethod, keepAlive);

  // use react useState to get resetForm result in an error
  // use a mutate variable as onResetTransactionDone function always be called after transaction success
  // resetForm is always a function
  let resetForm = null;

  const onResetTransactionInfo = (chain, subChain) => {
    dispatch(web3Operations.resetTransactionInfo(chain, subChain));
  };
  const onResetTransactionDone = () => {
    dispatch(web3Operations.resetTransactionDone());
    handleCloseSuccessDialog();
    dispatch(web3Operations.resetTransactionHash(chain, subChain));
    resetForm();
    onResetTransactionInfo(chain, subChain);
  };
  const validateAmount = R.curry((balance, existentialDeposit, amount) => {
    if (balance - existentialDeposit <= amount) return false;
    return true;
  });
  const validate = R.curry((chain, balance, existentialDeposit, values) => {
    const errors = {};
    const { amount, recipient } = values;
    if (!amount) {
      errors.amount = t('Required');
    }
    if (!validateAmount(balance, existentialDeposit, amount)) {
      errors.amount = t('Not enough balance');
    }
    if (!recipient) {
      errors.recipient = t('Required');
    }
    if (!WAValidator.validate(recipient, chain) && recipient) {
      errors.recipient = t('Invalid address');
    }
    return errors;
  });
  useEffect(() => {
    if (transactionDone) handleOpenSuccessDialog();
  }, [handleOpenSuccessDialog, transactionDone]);
  return (
    <>
      <Form
        onSubmit={onSubmit}
        subscription={{
          submitting: true,
          pristine: true,
          invalid: true,
          reset: true,
        }}
        validateOnBlur
        validate={validate(chain, free, existentialDeposit)}
        mutators={{
          setMaxAvailable: ([maxAvailable], state, utils) => {
            utils.changeValue(state, 'amount', () => maxAvailable);
          },
          // prefix underscore to disbale eslint warning
          clearRecipient: (_args, state, utils) => {
            utils.changeValue(state, 'recipient', () => null);
          },
          clearAmount: (_args, state, utils) => {
            utils.changeValue(state, 'amount', () => null);
          },
        }}
        render={(formProps) => {
          const {
            handleSubmit,
            pristine,
            form,
            invalid,
          } = formProps;
          const {
            mutators: {
              clearRecipient,
              setMaxAvailable,
              clearAmount,
            },
            resetFieldState,
          } = form;

          resetForm = () => {
            resetFieldState('amount');
            resetFieldState('recipient');
            clearAmount();
            clearRecipient();
          };

          return (
            <form
              id="transferForm"
              onSubmit={handleSubmit}
            >
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <WalletTransferBoardRecipient
                    t={t}
                    accounts={accounts}
                    clearRecipient={clearRecipient}
                    resetFieldState={resetFieldState}
                    selectedAccount={selectedAccount}
                  />
                </Grid>
                <Grid item xs={12}>
                  <WalletTransferBoardAmount
                    t={t}
                    calMaxAvailable={calMaxAvailable}
                    freeBalance={free}
                    transactionFee={transactionFee}
                    setMaxAvailable={setMaxAvailable}
                    amount={amount}
                    priceData={priceData}
                    api={api}
                    transferMethod={transferMethod}
                    keepAlive={keepAlive}
                    existentialDeposit={existentialDeposit}
                    handleOpenMaxDialog={handleOpenMaxDialog}
                    handleCloseMaxDialog={handleCloseMaxDialog}
                    openMaxDialog={openMaxDialog}
                    chain={chain}
                    subChain={subChain}
                    recipient={recipient}
                  />
                </Grid>
                <Grid item xs={12}>
                  <WalletTransferFee
                    t={t}
                    api={api}
                    recipient={recipient}
                    sender={sender}
                    amount={amount}
                    chain={chain}
                    transactionFee={transactionFee}
                    invalid={invalid}
                  />
                </Grid>
                <Grid item xs={12} className={classes.actionGrid}>
                  <RoundedButton
                    variant="contained"
                    color="secondary"
                    onClick={() => onGoBackToSelectMethod()}
                  >
                    {t('back')}
                  </RoundedButton>
                  <RoundedButton
                    variant="contained"
                    disabled={pristine || invalid}
                    loading={broadcastTransactionLoading}
                    color="primary"
                    type="submit"
                  >
                    {t('transfer')}
                  </RoundedButton>
                </Grid>
                {
                  errorMessage && (
                    <Grid item xs={12}>
                      <Alert severity="error">{errorMessage}</Alert>
                    </Grid>
                  )
                }
              </Grid>
              <FormSpy subscription={{ values: true }}>
                {({ values }) => {
                  const { recipient, amount } = values;
                  // setTimeout an hacky way to avoid FormSpy warning
                  setTimeout(() => setRecipient(recipient), 0);
                  const amountToSet = amount ? toNumber(amount) : 0;
                  setTimeout(() => setAmount(amountToSet || 0), 0);
                  setTimeout(() => handleResetTransferMethod(transferMethod), 0);
                  return null;
                }}
              </FormSpy>
            </form>
          );
        }}
      />
      <Dialog open={openSuccessDialog} onClose={closeSuccessDialog}>
        <DialogContent className={classes.dialogContent}>
          <WalletTransferBoardSuccess
            chain={chain}
            subChain={subChain}
            txHash={txHash}
            t={t}
            onClick={onResetTransactionDone}
            amount={amount}
          />
        </DialogContent>
      </Dialog>
    </>
  );
};

WalletTransferBoard.propTypes = {
  t: PropTypes.func.isRequired,
  walletConnectMethod: PropTypes.string.isRequired,
  chain: PropTypes.string.isRequired,
  subChain: PropTypes.string.isRequired,
  ss58Format: PropTypes.number.isRequired,
  api: PropTypes.object,
  accounts: accountsPropType,
  selectedAccount: accountPropType.isRequired,
  onGoBackToSelectMethod: PropTypes.func.isRequired,
  balance: balancePropType.isRequired,
  priceData: PropTypes.object.isRequired,
};

WalletTransferBoard.defaultProps = {
  accounts: [],
  api: undefined,
};

export default WalletTransferBoard;
