export const styles = (theme) => ({
  deployButton: {
    padding: 0,
    margin: theme.spacing(2, 0, 2, 0),
    width: '100%',
    [theme.breakpoints.down('md')]: {
      margin: theme.spacing(2, 0, 0, 0),
    },
    [theme.breakpoints.down('lg')]: {
      margin: theme.spacing(2, 0, 0, 0),
    },
  },
});
