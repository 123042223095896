import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import MobileStepper from '@material-ui/core/MobileStepper';
import Step from '@material-ui/core/Step';
import StepConnector from '@material-ui/core/StepConnector';
import StepLabel from '@material-ui/core/StepLabel';
import Stepper from '@material-ui/core/Stepper';
import { withStyles, createTheme, makeStyles } from '@material-ui/core/styles';
import whyDidYouRender from '@welldone-software/why-did-you-render';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useIsMobile } from '../../../../hooks/hooks';

import NeunodeOrderFormContent from './NeunodeOrderFormContent';
import {
  neunodeOrderFormStyle,
} from './styles';

const { REACT_APP_ENV } = process.env;

if (REACT_APP_ENV !== 'prod') {
  whyDidYouRender(React);
}

const theme = createTheme();
const Connector = withStyles({
  active: {
    '& $line': {
      borderColor: '#1A2433',
    },
  },
  completed: {
    '& $line': {
      borderColor: '#1A2433',
    },
  },
  line: {
    marginLeft: 'auto',
    marginRight: 'auto',
    margin: 7,
    maxWidth: '50%',
    borderTopWidth: 3,
    borderRadius: 10,
    [theme.breakpoints.down('xs')]: {
      maxWidth: '90%',
      marginLeft: 'auto',
      marginRight: 'auto',
      margin: 0,
    },
  },
})(StepConnector);

const useStyles = makeStyles(neunodeOrderFormStyle);

const NeunodeOrderForm = () => {
  const classes = useStyles();

  const [activeStep, setActiveStep] = useState(1);
  const { t } = useTranslation();
  const isMobile = useIsMobile();

  const steps = [
    {
      label: t('Choose Node Type'),
      title: t('Choose Node Type'),
    },
    {
      label: t('Order & Agreement'),
      title: t('Order & Agreement'),
    },
    {
      label: t('Payment Method'),
      title: t('Payment Method'),
    },
    {
      label: t('Order Complete'),
      title: t('Order Complete'),
    },
  ];
  const getStepper = () => {
    if (isMobile) {
      return (
        <MobileStepper
          variant="dots"
          steps={4}
          position="top"
          activeStep={activeStep}
          className={classes.mobileStepper}
          nextButton={(
            <Box className={classes.fakeButton} />
        )}
          backButton={(
            <Box className={classes.fakeButton} />
        )}
          classes={{
            dot: classes.mobileStepperDot,
            dotActive: classes.mobileStepperDotActive,
          }}
        />
      );
    }
    return (
      <Stepper
        activeStep={activeStep}
        alternativeLabel
        style={{ backgroundColor: 'transparent' }}
        connector={<Connector />}
      >
        {steps.map((item) => (
          <Step key={item.label}>
            <StepLabel classes={{ label: classes.stepLabel }}>{item.label}</StepLabel>
          </Step>
        ))}
      </Stepper>
    );
  };

  const handleStepperNext = () => {
    setActiveStep((step) => step + 1);
  };

  const handleStepperPrevious = () => {
    setActiveStep((step) => step - 1);
  };

  return (
    <Grid container spacing={6}>
      <Grid item xs={12}>
        {getStepper()}
      </Grid>
      <Grid item xs={12}>
        <NeunodeOrderFormContent
          steps={steps}
          activeStep={activeStep}
          handleStepperNext={handleStepperNext}
          handleStepperPrevious={handleStepperPrevious}
        />
      </Grid>
    </Grid>
  );
};

NeunodeOrderForm.propTypes = {
};

NeunodeOrderForm.whyDidYouRender = true;
export default React.memo(NeunodeOrderForm);
