import DateFnsUtils from '@date-io/date-fns';
import { makeStyles, Typography } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import whyDidYouRender from '@welldone-software/why-did-you-render';
import startCase from 'lodash/fp/startCase';
import moment from 'moment';
import {
  Select,
  KeyboardDatePicker,
} from 'mui-rff';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import React from 'react';
import { Form } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import {
  useIsMobile,
} from '../../../hooks/hooks';
import { unicodeDateFormat } from '../../../modules/appConstants';
import { RoundedButton, CloseIconButton } from '../../MaterialUiCustom/MaterialUiCustom';
import { updateOrderDataStyles } from '../styles';
// constants

// others
import { updateTimelineValidate } from './validate';

if (process.env.REACT_APP_ENV !== 'prod') {
  whyDidYouRender(React);
}

const useStyles = makeStyles(updateOrderDataStyles);

const UpdateOrderTimelineData = (props) => {
  const classes = useStyles();
  const isMobileDevice = useIsMobile();
  const { t } = useTranslation();

  const {
    data,
    onClose,
    open,
    onCancel,
    onUpdate,
  } = props;

  const orderId = R.prop('orderId', data);
  const timelines = R.prop('timeline', data) || [];
  const activeDate = R.view(R.lensPath(['devices', 0, 'dateActive']), data);
  const updateOrderTimelinesLoading = useSelector((state) => state.loadingR.updateOrderTimelinesLoading);

  const groupedData = R.groupBy(R.prop('stepName'), timelines);
  const orderStepData = R.view(R.lensPath(['ORDER', 0]), groupedData);
  const paymentStepData = R.view(R.lensPath(['PAYMENT', 0]), groupedData);
  const setupStepData = R.view(R.lensPath(['SETUP', 0]), groupedData);
  const completeSetupStepData = R.view(R.lensPath(['COMPLETE_SETUP', 0]), groupedData);
  const dataToUse = {
    orderStepStatus: R.prop('stepStatus', orderStepData),
    orderStepCompletedDate: R.prop('dateCompleted', orderStepData),
    paymentStepStatus: R.prop('stepStatus', paymentStepData),
    paymentStepCompletedDate: R.prop('dateCompleted', paymentStepData),
    setupStepStatus: R.prop('stepStatus', setupStepData),
    setupStepCompletedDate: R.prop('dateCompleted', setupStepData),
    completeSetupStepStatus: R.prop('stepStatus', completeSetupStepData),
    completeSetupStepCompletedDate: R.prop('dateCompleted', completeSetupStepData),
    activeDate,
  };

  const handleUpdate = (values) => {
    const {
      orderStepStatus,
      orderStepCompletedDate,
      paymentStepStatus,
      paymentStepCompletedDate,
      setupStepStatus,
      setupStepCompletedDate,
      completeSetupStepStatus,
      completeSetupStepCompletedDate,
      activeDate,
    } = values;
    const dateUpdated = moment().toISOString();

    const newTimelines = [
      {
        ...R.omit(['__typename'], orderStepData),
        stepStatus: orderStepStatus,
        dateCompleted: orderStepCompletedDate,
        dateUpdated,
      },
      {
        ...R.omit(['__typename'], paymentStepData),
        stepStatus: paymentStepStatus,
        dateCompleted: paymentStepCompletedDate,
        dateUpdated,
      },
      {
        ...R.omit(['__typename'], setupStepData),
        stepStatus: setupStepStatus,
        dateCompleted: setupStepCompletedDate,
        dateUpdated,
      },
      {
        ...R.omit(['__typename'], completeSetupStepData),
        stepStatus: completeSetupStepStatus,
        dateCompleted: completeSetupStepCompletedDate,
        dateUpdated,
      },
    ];

    return onUpdate(orderId, activeDate, newTimelines);
  };

  const getStepFieldsContent = (stepName, errors) => (
    <>
      <Grid item xs={10} sm={7}>
        <Typography variant="h2" className={classes.stepFieldTitle}>
          {startCase(stepName)}
        </Typography>
      </Grid>
      <Grid item xs={10} sm={7}>
        <Select
          name={`${stepName}StepStatus`}
          label={t('Status')}
          variant="outlined"
          required
        >
          <MenuItem value="NOT_REACHED">NOT_REACHED</MenuItem>
          <MenuItem value="PROCESSING">PROCESSING</MenuItem>
          <MenuItem value="COMPLETE">COMPLETE</MenuItem>
        </Select>
      </Grid>
      <Grid item xs={10} sm={7}>
        <KeyboardDatePicker
          variant="inline"
          label={t('Completed Date')}
          dateFunsUtils={DateFnsUtils}
          name={`${stepName}StepCompletedDate`}
          inputVariant="outlined"
          format={unicodeDateFormat}
          InputAdornmentProps={{ position: 'start' }}
          error={!!errors[`${stepName}StepCompletedDate`]}
          DialogProps={{ disableBackdropClick: true }}
          helperText={errors[`${stepName}StepCompletedDate`]}
        />
      </Grid>
      {stepName === 'completeSetup' && (
      <Grid item xs={10} sm={7}>
        <KeyboardDatePicker
          variant="inline"
          label={t('Active Date')}
          dateFunsUtils={DateFnsUtils}
          name="activeDate"
          inputVariant="outlined"
          format={unicodeDateFormat}
          InputAdornmentProps={{ position: 'start' }}
          error={!!errors.activeDate}
          helperText={errors.activeDate}
        />
      </Grid>
      )}
    </>
  );
  const generateUpdateOrderTimelineForm = () => (
    <Form
      initialValues={dataToUse}
      onSubmit={handleUpdate}
      subscription={{
        submitting: true, pristine: true, invalid: true, errors: true,
      }}
      validate={updateTimelineValidate(t)}
      render={({
        handleSubmit,
        submitting,
        pristine,
        invalid,
        errors,
      }) => (
        <form
          id="updateOrderDataForm"
          onSubmit={handleSubmit}
        >
          <Grid container justifyContent="center" spacing={4} className={classes.formGrid}>
            {getStepFieldsContent('order', errors)}
            {getStepFieldsContent('payment', errors)}
            {getStepFieldsContent('setup', errors)}
            {getStepFieldsContent('completeSetup', errors)}
            <Grid container item xs={10} sm={7} justifyContent="flex-end"/* className={classes.actionGrid} */>
              <Grid item>
                <RoundedButton
                  type="submit"
                  variant="contained"
                  disabled={pristine || submitting || invalid}
                  color="primary"
                >
                  { t('update')}
                </RoundedButton>
              </Grid>
              <Grid item>
                <RoundedButton
                  variant="contained"
                  color="secondary"
                  disabled={submitting}
                  onClick={onCancel}
                >
                  { t('cancel')}
                </RoundedButton>
              </Grid>
            </Grid>
          </Grid>
        </form>
      )}
    />
  );

  return (
    <Dialog
      fullScreen={isMobileDevice}
      fullWidth
      maxWidth="md"
      onClose={onClose}
      open={open}
    >
      <Typography variant="h2" className={classes.dialogTitleText}>
        {t('Update Order Timeline')}
      </Typography>
      <CloseIconButton
        onClick={onClose}
        color="default"
        disabled={updateOrderTimelinesLoading}
        className={classes.closeButton}
      />
      {
        generateUpdateOrderTimelineForm()
      }
    </Dialog>
  );
};

UpdateOrderTimelineData.propTypes = {
  data: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
};

UpdateOrderTimelineData.whyDidYouRender = true;
export default React.memo(UpdateOrderTimelineData);
