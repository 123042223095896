export const naasNodeTypes = [
  {
    nodeType: 'ETH2_VALIDATOR_NODE',
    keyName: 'pubKey',
  },
  {
    nodeType: 'UNUNIFI_VALIDATOR_NODE',
    keyName: 'accAddress',
  },
];

export const columnWidth = {
  naas: 2,
  mining: 2,
  neunode: 2,
  neunodeETH2: 2,
  horizen: 2,
  busd: 2,
};

export const columnsVisibility = {
  naas: {
    plan: true,
    quantity: true,
    staked: true,
    earning: true,
    balance: true,
  },
  mining: {
    plan: true,
    quantity: true,
    staked: true,
    earning: true,
    balance: true,
  },
  neunode: {
    plan: true,
    quantity: true,
    staked: true,
    earning: true,
    balance: true,
  },
  neunodeETH2: {
    plan: true,
    quantity: true,
    staked: true,
    earning: true,
    balance: true,
  },
  horizen: {
    plan: true,
    quantity: true,
    staked: true,
    earning: true,
    balance: true,
  },
  busd: {
    apy: true,
    amount: true,
    earning: true,
    balance: true,
  },
};

export const columnsToRender = {
  naas: [
    'quantity',
    'staked',
    'earning',
    'balance',
  ],
  'node-owner': [
    'quantity',
    'staked',
    'earning',
    'balance',
  ],
  mining: [
    'quantity',
    'staked',
    'earning',
    'balance',
  ],
  neunode: [
    'quantity',
    'staked',
    'earning',
    'balance',
  ],
  neunodeETH2: [
    'quantity',
    'staked',
    'earning',
    'balance',
  ],
  horizen: [
    'quantity',
    'staked',
    'earning',
    'balance',
  ],
  lending: [
    'apy',
    'amount',
    'earning',
    'balance',
  ],
  busd: [
    'apy',
    'amount',
    'earning',
    'balance',
  ],
};
