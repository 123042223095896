import isEmpty from 'lodash/fp/isEmpty';
import * as R from 'ramda';
import isEmail from 'validator/lib/isEmail';

import { userOperations, userTypes } from '../../../store/user/index';

export const updateTimelineValidate = R.curry((t, values) => {
  const errors = {};

  if (values.orderStepStatus === 'COMPLETE' && !values.orderStepCompletedDate) {
    errors.orderStepCompletedDate = t('Required');
  }

  if (values.paymentStepStatus === 'COMPLETE' && !values.paymentStepCompletedDate) {
    errors.paymentStepCompletedDate = t('Required');
  }

  if (values.setupStepStatus === 'COMPLETE' && !values.setupStepCompletedDate) {
    errors.setupStepCompletedDate = t('Required');
  }

  if (values.completeSetupStepStatus === 'COMPLETE') {
    if (!values.completeSetupStepCompletedDate) {
      errors.completeSetupStepCompletedDate = t('Required');
    }
    if (!values.activeDate) {
      errors.activeDate = t('Required');
    }
  }

  return errors;
});

export const validateCreateOrders = R.curry(async (t, dispatch, formData, values, ownedNodes) => {
  const errors = {};
  const {
    email,
    stripeInvoiceId,
    stripePaymentMethodId,
    ignorePayment,
    orderType,
    commission,
    unitCap,
    timelineOrderStepStatus,
    timelinePaymentStepStatus,
    timelineSetupStepStatus,
    timelineCompleteSetupStepStatus,
    timelineOrderStepCompletedDate,
    timelinePaymentStepCompletedDate,
    timelineSetupStepCompletedDate,
    timelineCompleteSetupStepCompletedDate,
    activeDate,
    nodeType,
    nodesName: nodesNameIn,
    quantity,
    paymentMethod,
  } = values;

  if (timelineOrderStepStatus === 'COMPLETE' && !timelineOrderStepCompletedDate) {
    errors.timelineOrderStepCompletedDate = t('Required');
  }

  if (timelinePaymentStepStatus === 'COMPLETE' && !timelinePaymentStepCompletedDate) {
    errors.timelinePaymentStepCompletedDate = t('Required');
  }

  if (timelineSetupStepStatus === 'COMPLETE' && !timelineSetupStepCompletedDate) {
    errors.timelineSetupStepCompletedDate = t('Required');
  }

  if (timelineCompleteSetupStepStatus === 'COMPLETE') {
    if (!timelineCompleteSetupStepCompletedDate) {
      errors.timelineCompleteSetupStepCompletedDate = t('Required');
    }

    if (!activeDate) {
      errors.activeDate = t('Required');
    }
  }

  if (email !== '') {
    const response = await dispatch(userOperations.validateEmail(email));
    if (response.type === userTypes.VALIDATE_EMAIL_SUCCESS) {
      errors.email = t('Email is not existed');
    }
    if (!isEmail(email)) {
      errors.email = t('Invalid email');
    }
  } else {
    errors.email = t('Required');
  }
  if (paymentMethod === 'STRIPE_CARD') {
    if (!ignorePayment && !stripePaymentMethodId) {
      errors.stripePaymentMethodId = t('Required');
    }
  } else if (!ignorePayment && !stripeInvoiceId) {
    errors.stripeInvoiceId = t('Required');
  }

  if (orderType === 'NAAS') {
    const nodesName = nodesNameIn ? R.split(',', R.replace(' ', '', nodesNameIn)) : '';

    if (nodeType === 'UNUNIFI_VALIDATOR_NODE') {
      if (isEmpty(nodesName)) {
        errors.nodesName = t('Required');
      } else {
        const nodesNameNum = R.length(nodesName);

        if (nodesNameNum < quantity) {
          errors.nodesName = t('Need to enter all nodes name');
        } else if (nodesNameNum > quantity) {
          errors.nodesName = t('Too many nodes name');
        }

        const ununifiOrders = R.filter(R.propEq('nodeType', 'UNUNIFI_VALIDATOR_NODE'), formData);
        const allNodesNameInForm = R.pipe(
          R.map(R.prop('nodesName')),
          R.join(','),
          R.replace(' ', ''),
          R.split(','),
        )(ununifiOrders);

        if (allNodesNameInForm.length !== R.uniq(allNodesNameInForm).length) {
          errors.nodesName = t('Duplicated node name');
        } else {
          R.forEach((nodeName) => {
            const isExisted = !!R.find(R.propEq('nodeName', nodeName), ownedNodes || []);
            if (isExisted) { errors.nodesName = `${t('Existed node name')}: ${nodeName}`; }
          })(nodesName);
        }
      }
    }
  }

  if (orderType === 'NODE_PROVIDER_MINING') {
    if (!commission) { errors.commission = t('Required'); }
    if (!unitCap) { errors.unitCap = t('Required'); }
  }

  return errors;
});
