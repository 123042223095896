import PropTypes from 'prop-types';
import React from 'react';

// import Radio from '@material-ui/core/Radio';
// import RadioGroup from '@material-ui/core/RadioGroup';
// import FormControlLabel from '@material-ui/core/FormControlLabel';
// import FormControl from '@material-ui/core/FormControl';

import ProtolConfigEth20WalletInputs from './ProtolConfigEth20WalletInputs';

const ProtocolConfigEth20Wallet = ({
  keystoreFilePassword,
  nodeType,
  quantity,
  network,
  onSuccessCallback,
  showPopupWrongNetworkError,
  wrongNetwork,
  withNetworkError,
}) => (
  <ProtolConfigEth20WalletInputs
    keystoreFilePassword={keystoreFilePassword}
    nodeType={nodeType}
    quantity={quantity}
    network={network}
    onSuccessCallback={onSuccessCallback}
    showPopupWrongNetworkError={showPopupWrongNetworkError}
    wrongNetwork={wrongNetwork}
    withNetworkError={withNetworkError}
  />
);

ProtocolConfigEth20Wallet.defaultProps = {
  onSuccessCallback: () => {},
};

ProtocolConfigEth20Wallet.propTypes = {
  keystoreFilePassword: PropTypes.string.isRequired,
  nodeType: PropTypes.string.isRequired,
  quantity: PropTypes.number.isRequired,
  network: PropTypes.number.isRequired,
  onSuccessCallback: PropTypes.func,
  showPopupWrongNetworkError: PropTypes.func.isRequired,
  wrongNetwork: PropTypes.bool.isRequired,
  withNetworkError: PropTypes.func.isRequired,
};

export default ProtocolConfigEth20Wallet;
