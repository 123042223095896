import { sumFromList } from 'jsutils';
import { camelCase } from 'lodash';
import * as R from 'ramda';

// utils
import { planckToToken } from 'store/crypto/dot/utils';

export const formatPhalaPoolRewardData = (payload) => {
  const { items } = payload;

  const formatedItems = R.map((poolRewards) => {
    const ownerReward = R.prop('ownerReward', poolRewards);
    return {
      ...poolRewards,
      ownerReward: planckToToken(ownerReward),
    };
  })(items || []);

  return {
    ...payload,
    items: formatedItems,
    total: sumFromList('ownerReward', formatedItems),
  };
};

export const formatPhalaRollupRewardData = (payload) => {
  const { ownerRollupRewards, machineType } = payload;

  return {
    // ownerRollupRewards: planckToToken(ownerRollupRewards),
    [camelCase(machineType)]: planckToToken(ownerRollupRewards),
  };
};
