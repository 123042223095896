import * as R from 'ramda';

import { errorOperations } from '../../error/index';
import { loadingOperations } from '../../loading/index';

import * as actions from './actions';

export const { updateMonthlyReportEmailConfig } = actions;

export const getNodes = R.curry(async (query, dispatch) => {
  try {
    dispatch(loadingOperations.setLoading('getNodes', true));
    const response = dispatch(await actions.getNodes(query));
    dispatch(loadingOperations.setLoading('getNodes', false));
    dispatch(errorOperations.clearError('getNodes'));
    return response;
  } catch (error) {
    dispatch(loadingOperations.setLoading('getNodes', false));
    return dispatch(errorOperations.setError(
      'getNodes',
      { error },
    ));
  }
});

export const getAdminTotalReport = R.curry(async (query, dispatch) => {
  const operationName = 'getAdminTotalReport';
  try {
    dispatch(loadingOperations.setLoading(operationName, true));
    const response = dispatch(await actions.getAdminTotalReport(query));
    dispatch(loadingOperations.setLoading(operationName, false));
    dispatch(errorOperations.clearError(operationName));
    return response;
  } catch (error) {
    dispatch(loadingOperations.setLoading(operationName, false));
    return dispatch(errorOperations.setError(
      operationName,
      { error },
    ));
  }
});

export const sendMonthlyReportEmail = R.curry(async (data, dispatch) => {
  const operationName = 'sendMonthlyReportEmail';
  try {
    dispatch(loadingOperations.setLoading(operationName, true));
    const response = dispatch(await actions.sendMonthlyReportEmail(data));
    dispatch(loadingOperations.setLoading(operationName, false));
    dispatch(errorOperations.clearError(operationName));
    return response;
  } catch (error) {
    dispatch(loadingOperations.setLoading(operationName, false));
    return dispatch(errorOperations.setError(
      operationName,
      { error },
    ));
  }
});
