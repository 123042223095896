import gql from 'graphql-tag';

export const createCryptoAddressMutation = gql`
  mutation createCryptoAddress(
    $createCryptoAddressInput: CreateCryptoAddressInput!
  )
  {
    createCryptoAddress(
      createCryptoAddressInput: $createCryptoAddressInput
    ) {
      cryptoAddressId
      address
      chain
      subChain
      userId
    }
  }
`;
