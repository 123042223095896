export const checkoutFormStyles = () => ({
  actionGrid: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
});

export const stripeCardStyles = (theme) => ({
  cardIcon: {
    height: 'auto',
    width: 50,
  },
  stripeCard: {
    cursor: 'pointer',
    padding: theme.spacing(2),
  },
  selectedStripeCard: {
    cursor: 'pointer',
    padding: theme.spacing(2),
    borderColor: theme.palette.secondary.main,
  },

});
export const stripeCardListStyles = (theme) => ({
  actionGrid: {
    ...theme.centerComponent,
  },
  dialogTitle: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  buttonField: {
    maxWidth: 350,
  },
});
