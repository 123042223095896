import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import React from 'react';

import ChainInfoSocialLinkItem from './ChainInfoSocialLinkItem';

const ChainInfoSocialLink = (props) => {
  const {
    socialLinks,
  } = props;

  const content = R.addIndex(R.map)((each, index) => (
    <Grid item xs={12} key={each.title}>
      <ChainInfoSocialLinkItem
        title={each.title}
        link={each.link}
      />
    </Grid>
  ), socialLinks);
  return (
    <Grid container direction="column" spacing={1}>
      {content}
    </Grid>
  );
};

ChainInfoSocialLink.propTypes = {
  socialLinks: PropTypes.array.isRequired,
};
export default ChainInfoSocialLink;
