import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import {
  useStripe,
  useElements,
  CardNumberElement,
} from '@stripe/react-stripe-js';
import isFunction from 'lodash/isFunction';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

// stipe

import { useSelector, useDispatch } from 'react-redux';

import { stripeOperations } from '../../store/stripe/index';
import Coupon from '../Coupon/Coupon';
import { RoundedButton } from '../MaterialUiCustom/MaterialUiCustom';

import PaymentStripeCardElement from './PaymentStripeCardElement';
import { checkoutFormStyles } from './styles';

// operations

import useSmartSetState from 'hooks/useSmartSetState';

const useStyles = makeStyles(checkoutFormStyles);

const PaymentStripeCheckoutForm = (props) => {
  const [error, setError] = useSmartSetState({
    cardNumber: '',
    cardExpiry: '',
    cardCvc: '',
  });
  const [fieldsEmpty, setFieldsEmpty] = useSmartSetState({
    cardNumber: true,
    cardExpiry: true,
    cardCvc: true,
  });
  const [disableBtn, setDisableBtn] = useState(true);

  const { t } = useTranslation();

  const errorMsgs = {
    cardNumber: t('Card number invalid'),
    cardExpiry: t("Card's expiration date invalid"),
    cardCvc: t("Card's security code invalid"),
  };

  const updateState = R.curry(
    (elementType, value, state) => R.set(R.lensPath([elementType]), value, state),
  );

  const handleSetError = R.curry((elementType, error) => {
    if (!error) return setError((state) => updateState(elementType, '', state));
    const errorMsg = errorMsgs[elementType] || t('Invalid');
    return setError((state) => updateState(elementType, errorMsg, state));
  });

  const handleSetFieldsEmpty = R.curry(
    (elementType, empty) => setFieldsEmpty(
      (state) => updateState(elementType, Boolean(empty), state),
    ),
  );

  const handleOnChange = R.curry((event) => {
    const { error, empty, elementType } = event;
    handleSetError(elementType, error);
    handleSetFieldsEmpty(elementType, empty);
  });

  const checkIfValueTruthy = R.pipe(
    R.values,
    R.any((value) => value),
  );
  const isError = checkIfValueTruthy(error);
  const isEmptyField = checkIfValueTruthy(fieldsEmpty);

  useEffect(() => {
    setDisableBtn(isError || isEmptyField);
  }, [isError, isEmptyField]);

  const {
    btnText,
    stripeCustomerId,
    onSubmitExtra,
    enableCoupon,
  } = props;

  const classes = useStyles();
  const stripe = useStripe();
  const elements = useElements();
  const { userId } = useSelector((state) => state.userR.user);
  const dispatch = useDispatch();
  const [addCardLoading, setAddCardLoading] = React.useState(false);
  const handleSubmit = async (event) => {
    event.preventDefault();
    setAddCardLoading(true);
    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return;
    }
    const card = elements.getElement(CardNumberElement);

    await dispatch(
      stripeOperations.createUserStripePaymentMethod(
        stripe,
        userId,
        stripeCustomerId,
        card,
      ),
    );
    if (isFunction(onSubmitExtra)) await onSubmitExtra();
    setAddCardLoading(false);
  };

  return (
    <form onSubmit={handleSubmit}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <PaymentStripeCardElement
            error={error}
            handleSetError={handleSetError}
            handleOnChange={handleOnChange}
          />
        </Grid>
        <Grid item xs={12} className={classes.actionGrid}>
          <Grid container spacing={2} justifyContent="space-between" alignItems="center">
            <Grid item>
              {enableCoupon && (
              <Coupon />
              )}
            </Grid>
            <Grid item>
              <RoundedButton
                color="secondary"
                variant="contained"
                type="submit"
                loading={addCardLoading}
                disabled={addCardLoading || disableBtn}
                noMargin
              >
                {btnText}
              </RoundedButton>
            </Grid>
          </Grid>
        </Grid>

      </Grid>
    </form>
  );
};

PaymentStripeCheckoutForm.defaultProps = {
  enableCoupon: false,
};

PaymentStripeCheckoutForm.propTypes = {
  /**
   * text displayed in action button
   */
  btnText: PropTypes.string.isRequired,
  stripeCustomerId: PropTypes.string.isRequired,
  onSubmitExtra: PropTypes.func.isRequired,
  enableCoupon: PropTypes.bool,
};

export default PaymentStripeCheckoutForm;
