export const styles = (theme) => ({});

const makeSecretPhraseBoxBaseStyle = (theme) => ({
  backgroundColor: '#eeeeee',
  borderStyle: 'solid',
  borderWidth: 0,
  padding: theme.spacing(1),
  // marginTop: theme.spacing(1),
});
const makeWordBoxBaseStyle = (theme) => ({
  ...theme.centerComponent,
  height: 100,
  backgroundColor: '#fff',
  cursor: 'move',
  [theme.breakpoints.down('xs')]: {
    height: 50,
    padding: theme.spacing(1),
  },
});
const secretBoxPlain = {
  minHeight: 85,
};
export const secretPhraseBoxStyles = (theme) => ({
  secretPhraseBoxPlain: {
    ...makeSecretPhraseBoxBaseStyle(theme),
    ...theme.centerComponent,
    ...secretBoxPlain,
  },
  secretPhraseBox: {
    ...makeSecretPhraseBoxBaseStyle(theme),
    borderRadius: 10,
  },
  wordBox: makeWordBoxBaseStyle(theme),
  disabledWordBox: {
    ...makeWordBoxBaseStyle(theme),
    cursor: 'default',
  },
  draggingWordBox: {
    ...makeWordBoxBaseStyle(theme),
    backgroundColor: theme.palette.secondary.light,
    color: 'white',
    cursor: 'move',
    zIndex: 999999,
    [theme.breakpoints.down('xs')]: {
      height: 50,
    },
  },
  word: {
    padding: theme.spacing(1),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  generateSecretPhraseButton: {
    padding: '6px 0px 6px 0px',
  },
});

export const secretPhraseGeneratorStyles = (theme) => ({
  wrapPaper: {
    padding: theme.spacing(1, 1, 3, 3),
  },
  paperWord: {
    padding: theme.spacing(1),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  titleGrid: {
    display: 'flex',
  },
  saveButton: {
    marginLeft: theme.spacing(1),
  },
  secretPhraseInput: {
    width: '100%',
  },
  secretPhraseInputPaper: {
    padding: theme.spacing(1),
  },
  centerComponent: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  savedSecretPhraseSuccess: {
    color: theme.palette.success.main,
  },
  confirmSecretPhraseError: {
    color: theme.palette.error.main,
  },
  formSection: {
    padding: 0,
  },
  buttonsGrid: {
    padding: 0,
  },
});
export const secretPhraseConfirmationStyles = (theme) => ({
  confirmSecrePhraseField: {
    width: '100%',
  },
  inputMultiline: {
    minHeight: 56,
    lineHeight: theme.typography.body1.lineHeight,
  },
  multiline: {
    padding: theme.spacing(1),
  },
});
