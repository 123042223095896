import PropTypes from 'prop-types';
import * as R from 'ramda';
import React from 'react';

import { generateReactGaException } from '../../modules/utils';
import ErrorFallBackPage from '../NotFoundPage/ErrorFallBackPage';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
      error: null,
    };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true, error };
  }

  // the method is used to log error information
  // eslint-disable-next-line no-unused-vars
  componentDidCatch(error, info) {
    generateReactGaException(error, true);
  }

  render() {
    const { hasError, error } = this.state;
    const { children } = this.props;
    const errorMessage = R.view(R.lensProp('message'), error);

    return hasError
      ? <ErrorFallBackPage errorMessage={errorMessage} />
      : children;
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.element.isRequired,
};

export default ErrorBoundary;
