import Box from '@material-ui/core/Box';
import Dialog from '@material-ui/core/Dialog';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import AutorenewOutlined from '@material-ui/icons/AutorenewOutlined';
import whyDidYouRender from '@welldone-software/why-did-you-render';
import * as _ from 'lodash';
import isEmpty from 'lodash/fp/isEmpty';
import * as R from 'ramda';
import React, {
  useEffect,
  useState,
  useRef,
  useCallback,
  useMemo,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { createSelector } from 'reselect';

import {
  useAddFunctionToRef,
  useIsMobile,
  useGetQuery,
  useUserDataSelector,
} from '../../../hooks/hooks';
import { infinite } from '../../../modules/appConstants';
import { dashboardRoute } from '../../../routes';
import { adminOrderOperations, adminOrderSelectors } from '../../../store/admin/order/index';
import { UPDATE_ORDER_DATA_SUCCESS, UPDATE_ORDER_TIMELINES_SUCCESS } from '../../../store/admin/order/types';
import { routerServices } from '../../../store/router/index';
import FailPage from '../../FailPage/FailPage';
import SuccessPage from '../../SuccessPage/SuccessPage';
import Actions from '../../Tables/Actions';
import ReactTableV7ReactWindow from '../../Tables/ReactTableV7ReactWindow';
import { orderListColumns } from '../columns';
import { orderListStyles } from '../styles';
import { comparePropsWithTranslaitonMethod } from '../utils';

import OrderDetails from './OrderDetails';
import UpdateOrderData from './UpdateOrderData';
import UpdateOrderTimelineData from './UpdateOrderTimelineData';

// others
// store

// routes

if (process.env.REACT_APP_ENV !== 'prod') {
  whyDidYouRender(React);
}

const useStyles = makeStyles(orderListStyles);
const selectOrderList = createSelector(
  (state) => state.adminR.orders.orderList.data,
  (orderList) => adminOrderSelectors.extractOrderList(orderList),
);

const OrderList = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();
  const isMobileDevice = useIsMobile();
  const history = useHistory();
  const queryLng = useGetQuery('lng');
  const { role } = useUserDataSelector();
  const [orderDetailsDialogOpen, setOrderDetailsDialogOpen] = useState(false);
  const [updateOrderDateDialogOpen, setUpdateOrderDataDialogOpen] = useState(false);
  const [updateOrderTimelineDialogOpen, setUpdateOrderTimelineDialogOpen] = useState(false);
  const [orderToViewDetail, setOrderToViewDetail] = useState({});
  const [orderDataToUpdate, setOrderDataToUpdate] = useState({});
  const [timelineToUpdate, setTimelineToUpdate] = useState({});

  const onGetOrders = useAddFunctionToRef(async () => {
    await dispatch(adminOrderOperations.adminGetOrders(
      [{}],
      {
        page: 1,
        limit: infinite,
      },
    ));
  }, []);

  const adminGetOrdersLoading = useSelector((state) => state.loadingR.adminGetOrdersLoading);
  const updateOrderDataLoading = useSelector((state) => state.loadingR.updateOrderDataLoading);
  const orderList = useSelector(selectOrderList);

  const [statusDialogName, setStatusDialogName] = useState('');
  const [statusDialog, setStatusDialog] = useState(false);
  const handleStatusDialogClose = () => {
    setStatusDialog('');
  };
  const handleStatusDialogOpen = (status) => {
    setStatusDialog(status);
  };

  const orderDataToUpdateTransformation = {
    orderDate: (dateInput) => (dateInput ? new Date(dateInput) : new Date()),
    dateTrialEnd: (dateInput) => (dateInput ? new Date(dateInput) : null),
  };
  const updatedOrderDataTransformations = {
    quantity: _.parseInt,
    trialDays: _.parseInt,
  };

  const ref = useRef({
    emptyArray: [],
    columnsToHide: ['orderDate', 'orderTotalAmount'],
    rerenderCount: 0,
  });
  const {
    emptyArray,
    columnsToHide,
  } = ref.current;
  const hiddenColumns = isMobileDevice ? columnsToHide : emptyArray;

  const handleOrderDetailsDialogClose = useCallback(() => {
    setOrderDetailsDialogOpen(false);
  }, []);

  const handleOrderDetailsButtonClick = useCallback((orderData) => {
    setOrderToViewDetail(R.omit(['devices'], orderData));
    setOrderDetailsDialogOpen(true);
  }, []);

  const handleUpdateOrderDataDialogClose = useCallback(() => {
    setUpdateOrderDataDialogOpen(false);
  }, []);

  const handleUpdateOrderDataCancelButtonClick = useCallback(() => {
    setUpdateOrderDataDialogOpen(false);
  }, []);

  const handleUpdateOrderDataButtonClick = useCallback((orderData) => {
    const orderDataToUpdate = R.pipe(
      R.pick([
        'orderId',
        'email',
        'orderType',
        'orderDate',
        'quantity',
        'trialDays',
        'dateTrialEnd',
      ]),
      R.evolve(orderDataToUpdateTransformation),
    )(orderData);

    setOrderDataToUpdate(orderDataToUpdate);
    setUpdateOrderDataDialogOpen(true);
  }, [orderDataToUpdateTransformation]);

  const handleUpdateOrderTimelineClose = () => {
    setUpdateOrderTimelineDialogOpen(false);
  };

  const handleUpdateOrderTimelineOpen = (orderData) => {
    setTimelineToUpdate(orderData);
    setUpdateOrderTimelineDialogOpen(true);
  };

  const handleUpdateOrderTimelineSubmit = async (orderId, activeDate, timelines) => {
    const { type } = await dispatch(adminOrderOperations.updateOrderTimelines(orderId, activeDate, timelines));

    if (type === UPDATE_ORDER_TIMELINES_SUCCESS) {
      onGetOrders();
      handleStatusDialogOpen('success');
      setStatusDialogName('updateTimelinesSuccess');
    } else {
      handleStatusDialogOpen('fail');
      setStatusDialogName('updateTimelinesFail');
    }

    setUpdateOrderTimelineDialogOpen(false);
  };

  const handleUpdatedOrderDataSubmit = useCallback(async (updateOrderDataFormValues) => {
    const updateOrderDataInput = R.pipe(
      // email was only added to make it admin easy for user to understand which users
      // order is being updated.
      R.omit(['email']),
      R.evolve(updatedOrderDataTransformations),
    )(updateOrderDataFormValues);
    const { type } = await dispatch(adminOrderOperations.updateOrderData(updateOrderDataInput));

    if (type === UPDATE_ORDER_DATA_SUCCESS) {
      onGetOrders();
      handleStatusDialogOpen('success');
      setStatusDialogName('updateOrderSuccess');
    } else {
      handleStatusDialogOpen('fail');
      setStatusDialogName('updateOrderFail');
    }

    setUpdateOrderDataDialogOpen(false);
  }, [dispatch, onGetOrders, updatedOrderDataTransformations]);

  const actionButtons = useMemo(() => (
    [
      {
        label: t('view details'),
        buttonClickHandler: handleOrderDetailsButtonClick,
      },
      {
        label: t('edit order'),
        buttonClickHandler: handleUpdateOrderDataButtonClick,
      },
    ]
  ), [t, handleOrderDetailsButtonClick, handleUpdateOrderDataButtonClick]);

  const viewOrderActions = useCallback((data, index) => {
    const { timeline } = data;
    const actionButtonsToUse = isEmpty(timeline) ? actionButtons : R.append({
      label: t('edit timeline'),
      buttonClickHandler: handleUpdateOrderTimelineOpen,
    }, actionButtons);
    return (
      <Actions
        index={index}
        buttons={actionButtonsToUse}
        data={data}
      />
    );
  },
  [actionButtons, t]);

  const columnsToUse = useMemo(() => orderListColumns(
    t,
    isMobileDevice,
    viewOrderActions,
  ), [t, isMobileDevice, viewOrderActions]);

  // If user is not admin redirect user to normal dashboard route
  useEffect(() => {
    if (role !== 'ADMIN') {
      routerServices.historyPush(
        history,
        {
          pathname: dashboardRoute,
          queryLng,
        },
      );
    }
  }, [history, role, queryLng]);

  useEffect(() => {
    onGetOrders();
  }, [onGetOrders]);

  const orderListToUse = !R.isEmpty(orderList) ? R.sort(R.descend(R.prop('orderDate')), orderList) : emptyArray;
  const loading = adminGetOrdersLoading || updateOrderDataLoading;
  const getContent = () => (
    <Paper className={classes.table}>
      <ReactTableV7ReactWindow
        showToolbar
        loading={loading}
        title={(
          <Box display="flex" alignContent="center">
            <Box pr={1}>
              {t('Order List')}
            </Box>
            <Box mb={0.25}>
              <IconButton
                onClick={onGetOrders}
                className={classes.reFetchButton}
                disabled={loading}
              >
                <AutorenewOutlined color={loading ? 'disabled' : 'primary'} />
              </IconButton>
            </Box>
          </Box>
        )}
        data={orderListToUse}
        columns={columnsToUse}
        hiddenColumns={hiddenColumns}
      />
      <OrderDetails
        data={orderToViewDetail}
        open={orderDetailsDialogOpen}
        onClose={handleOrderDetailsDialogClose}
        t={t}
      />
      <UpdateOrderData
        initialValues={orderDataToUpdate}
        open={updateOrderDateDialogOpen}
        onClose={handleUpdateOrderDataDialogClose}
        onCancelButtonClick={handleUpdateOrderDataCancelButtonClick}
        onUpdateOrderData={handleUpdatedOrderDataSubmit}
        t={t}
      />
      <UpdateOrderTimelineData
        data={timelineToUpdate}
        open={updateOrderTimelineDialogOpen}
        onClose={handleUpdateOrderTimelineClose}
        onCancel={handleUpdateOrderTimelineClose}
        onUpdate={handleUpdateOrderTimelineSubmit}
        t={t}
      />
    </Paper>
  );

  const statusDialogTexts = {
    updateOrderSuccess: {
      primaryText: t('THANK YOU!'),
      message: t('Order has been updated. Thank you.'),
    },
    updateOrderFail: {
      primaryText: t('SORRY!'),
      message: t('Order update failed. Please try again.'),
    },

    updateTimelinesSuccess: {
      primaryText: t('THANK YOU!'),
      message: t('Order timeline has been updated. Thank you.'),
    },
    updateTimelinesFail: {
      primaryText: t('SORRY!'),
      message: t('Order timeline update failed. Please try again.'),
    },
  };

  const statusDialogText = R.prop(statusDialogName, statusDialogTexts) || {
    primaryText: '',
    message: '',
  };
  return (
    <>
      {getContent()}
      <Dialog open={statusDialog === 'success'} onClose={handleStatusDialogClose} fullWidth maxWidth="sm">
        <Box className={classes.statusDialog}>
          <SuccessPage
            onCancel={handleStatusDialogClose}
            primaryText={R.prop('primaryText', statusDialogText)}
            message={R.prop('message', statusDialogText)}
            btnText={t('close')}
          />
        </Box>
      </Dialog>
      <Dialog open={statusDialog === 'fail'} onClose={handleStatusDialogClose} fullWidth maxWidth="sm">
        <Box className={classes.statusDialog}>
          <FailPage
            onCancel={handleStatusDialogClose}
            primaryText={R.prop('primaryText', statusDialogText)}
            message={R.prop('message', statusDialogText)}
            btnText={t('close')}
          />
        </Box>
      </Dialog>
    </>
  );
};

OrderList.defaultProps = {

};

OrderList.propTypes = {
};

OrderList.whyDidYouRender = true;

export default React.memo(OrderList, comparePropsWithTranslaitonMethod);
