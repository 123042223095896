import { makeStyles } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Dialog from '@material-ui/core/Dialog';
import Divider from '@material-ui/core/Divider';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';
import Select from '@material-ui/core/Select';
import Switch from '@material-ui/core/Switch';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import AddCircleOutlineOutlinedIcon from '@material-ui/icons/AddCircleOutlineOutlined';
import DeleteForeverOutlinedIcon from '@material-ui/icons/DeleteForeverOutlined';
import whyDidYouRender from '@welldone-software/why-did-you-render';
import {
  pipeAwait,
} from 'jsutils';
import isEmpty from 'lodash/fp/isEmpty';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';

import {
  useIsMobile,
} from '../../../hooks/hooks';
import { RoundedButton, CloseIconButton } from '../../MaterialUiCustom/MaterialUiCustom';
import { createUsersStyles } from '../styles';

// others
import { validateCreateUser } from './validate';

if (process.env.REACT_APP_ENV !== 'prod') {
  whyDidYouRender(React);
}

const useStyles = makeStyles(createUsersStyles);

const CreateUsers = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const isMobileDevice = useIsMobile();
  const { t } = useTranslation();

  const {
    onClose,
    open,
    onCreateUsers,
  } = props;

  const [data, setData] = useState({});
  const [errors, setErrors] = useState({});

  const createUsersLoading = useSelector((state) => state.loadingR.createUsersLoading);

  const handleOnClose = () => {
    onClose();
    setData({});
    setErrors({});
  };
  const clearData = () => setData({});
  const handleOnCreateUsers = async () => {
    await onCreateUsers(data, clearData);
  };

  const updateData = R.curry(async (index, propName, event) => {
    const { value } = event.target;
    pipeAwait(
      R.assocPath([index, propName], value),
      (updatedData) => {
        setData(updatedData);
        return updatedData;
      },
      async (updatedData) => {
        const error = await validateCreateUser(t, dispatch, updatedData, updatedData[index]);
        const errorsToUpdate = R.assocPath([index], error, errors);
        setErrors(errorsToUpdate);
      },
    )(data);
  });
  const updateDataSwitch = R.curry((index, propName, event) => {
    event.persist();
    const { checked } = event.target;
    R.pipe(
      R.assocPath([index, propName], checked),
      setData,
    )(data);
  });

  const addEmptyItem = () => {
    const index = R.length(R.values(data));
    setData(R.assocPath(
      [index],
      {
        email: '',
        isAdmin: false,
        createFirstSignin: true,
        skipActivationEmail: true,
        newsletter: false,
      },
      data,
    ));
    setErrors(R.assocPath(
      [index],
      {
        new: true,
      },
      errors,
    ));
  };

  const removeItem = (index) => {
    R.pipe(
      R.values,
      R.remove(index, 1),
      R.addIndex(R.map)((item, index) => [index, item]),
      R.fromPairs,
      setData,
    )(data);
    R.pipe(
      R.values,
      R.remove(index, 1),
      R.addIndex(R.map)((item, index) => [index, item]),
      R.fromPairs,
      setErrors,
    )(errors);
  };

  const getFieldContent = R.curry((index, errors, type, label, name, value, required) => (
    <Grid item xs={8}>
      <TextField
        defaultValue={value}
        error={!!errors[name]}
        helperText={errors[name] || ''}
        label={label}
        variant="outlined"
        name={name}
        required={required}
        type={type}
        className={classes.formField}
        onBlur={updateData(index, name)}
      />
    </Grid>
  ));

  const getSwitchFieldContent = R.curry((index, label, name, value, disabled = false) => (
    <Grid item xs={8}>
      <Box display="flex" pl={2} alignItems="center" className={classes.formFieldSwitch}>
        <Box pr={3} flex={0.9}>
          <Typography color="textSecondary">
            {label}
          </Typography>
        </Box>
        <Box flex={0.1}>
          <Switch
            checked={value}
            name={name}
            color="primary"
            disabled={disabled}
            onChange={updateDataSwitch(index, name)}
          />
        </Box>
      </Box>
    </Grid>
  ));

  const getSelectFieldContent = R.curry((index, label, name, value, required, items) => (
    <Grid item xs={8}>
      <FormControl required={required} variant="outlined" className={classes.formField}>
        <InputLabel>{label}</InputLabel>
        <Select
          value={value}
          name={name}
          label={label}
          variant="outlined"
          onChange={updateData(index, name)}
        >
          {R.map((item) => {
            const { key, value } = item;
            return <MenuItem value={value} key={`select_item_${key}`}>{key}</MenuItem>;
          })(items)}
        </Select>
      </FormControl>
    </Grid>
  ));

  const getItemContent = (index, data) => {
    const {
      email,
      isAdmin,
      createFirstSignin,
      skipActivationEmail,
      newsletter,
    } = data;
    const error = errors[index];

    return (
      <Grid container justifyContent="center" spacing={4} className={classes.formGrid}>
        <Grid item xs={8}>
          <Box display="flex" alignItems="flex-end">
            <Box>
              <Typography
                variant="h2"
              >
                {`${t('User')} ${index + 1}`}
              </Typography>
            </Box>
            <Box pl={1} mb={0.4}>
              <IconButton style={{ padding: 0 }} onClick={() => removeItem(index)}>
                <DeleteForeverOutlinedIcon color="error" />
              </IconButton>
            </Box>
          </Box>
        </Grid>
        {getFieldContent(index, error, 'text', t('Email'), 'email', email, true)}
        {getSelectFieldContent(
          index,
          t('Role'),
          'isAdmin',
          isAdmin,
          true,
          [
            { key: 'User', value: false },
            { key: 'Admin', value: true },
          ],
        )}
        {getSwitchFieldContent(index, t('Create First Signin'), 'createFirstSignin', createFirstSignin)}
        {getSwitchFieldContent(index, t('Skip Activation Email'), 'skipActivationEmail', createFirstSignin || skipActivationEmail, createFirstSignin)}
        {getSwitchFieldContent(index, t('Subscribe To Newsletter'), 'newsletter', newsletter)}
      </Grid>
    );
  };

  const getItemsContent = R.addIndex(R.map)(
    (eachData, index) => (
      <form key={`create_user_item_divider_${index}`}>
        <Box>
          {index > 1 && <Divider />}
          <Box className={classes.itemContainer}>
            {getItemContent(index, eachData)}
          </Box>
        </Box>
      </form>
    ),
  );

  const getEmptyContent = () => (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      className={classes.emptyItem}
    >
      <Box>
        <Typography
          color="textSecondary"
          align="center"
          className={classes.emptyItemContent}
        >
          {t('Don\'t have any users to create')}
        </Typography>
      </Box>
    </Box>
  );
  const getAddButtonContent = () => (
    <Box onClick={addEmptyItem} className={classes.addButtonContainer}>
      <AddCircleOutlineOutlinedIcon color="primary" className={classes.addButton} />
    </Box>
  );

  const isError = R.pipe(
    R.values,
    R.map(
      R.pipe(
        (errors) => !R.isEmpty(errors),
      ),
    ),
    R.any(R.equals(true)),
  )(errors) || R.isEmpty(errors);

  return (
    <Dialog
      fullScreen={isMobileDevice}
      fullWidth
      maxWidth="md"
      onClose={handleOnClose}
      open={open}
    >
      <Typography variant="h2" className={classes.dialogTitleText}>
        {t('Create Users')}
      </Typography>
      <CloseIconButton
        onClick={handleOnClose}
        color="default"
        disabled={createUsersLoading}
        className={classes.closeButton}
      />
      <Paper className={classes.content}>
        {getItemsContent(R.values(data))}
        {isEmpty(data) && getEmptyContent()}
        <Divider />
        {getAddButtonContent()}
      </Paper>
      <Grid container justifyContent="center" className={classes.actionGrid}>
        <Grid item>
          <RoundedButton
            variant="contained"
            color="primary"
            loading={createUsersLoading}
            onClick={() => handleOnCreateUsers(data)}
            disabled={isError}
          >
            { t('create')}
          </RoundedButton>
        </Grid>
        <Grid item>
          <RoundedButton
            variant="contained"
            color="secondary"
            disabled={createUsersLoading}
            onClick={handleOnClose}
          >
            { t('cancel')}
          </RoundedButton>
        </Grid>
      </Grid>
    </Dialog>
  );
};

CreateUsers.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  onCreateUsers: PropTypes.func.isRequired,
};

CreateUsers.whyDidYouRender = true;
export default React.memo(CreateUsers);
