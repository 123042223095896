import Box from '@material-ui/core/Box';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import ErrorOutlineOutlinedIcon from '@material-ui/icons/ErrorOutlineOutlined';
import random from 'lodash/random';
import startCase from 'lodash/startCase';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import React from 'react';

import { useIsMobile } from '../../hooks/hooks';

import { referralReferralDataSectiontyles } from './styles';

const useStyles = makeStyles(referralReferralDataSectiontyles);

const ReferralDataSection = (props) => {
  const classes = useStyles();
  const {
    data, gridItemWidths, explanations, desciptions,
  } = props;
  const isMobile = useIsMobile();
  const dividerOrientation = isMobile ? 'horizontal' : 'vertical';

  const [openTooltipIndex, setOpenTooltipIndex] = React.useState(-1);
  const handleTooltipIndexClose = () => {
    setOpenTooltipIndex(-1);
  };
  const handleTooltipIndexOpen = (index) => {
    setOpenTooltipIndex(index);
  };

  const handleContent = R.curry((item, index) => {
    const [key, value] = item;
    const mapKey = `${random(0, 10000)}_${index}`;
    const explanationText = explanations ? explanations[key] : '';
    const desciptionsContent = desciptions ? desciptions[key] : '';
    const { title, value: valueText } = value;
    return (
      <Grid item {...gridItemWidths} className={classes.referralItemGrid} key={mapKey}>
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          className={classes.referralItem}
        >
          <Box>
            <Typography variant="h6">
              {valueText}
            </Typography>
          </Box>
          <Box
            mt={1}
            display="flex"
            flexDirection="row"
            alignItems="center"
            justifyContent="center"
          >
            <Box>
              <Typography variant="h6">
                {startCase(title)}
              </Typography>
            </Box>
            {
              explanationText
              && (
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  ml={1 / 2}
                >
                  <ClickAwayListener onClickAway={handleTooltipIndexClose}>
                    <Tooltip
                      open={openTooltipIndex === index}
                      title={<Typography>{explanations[key]}</Typography>}
                      placement="bottom"
                    >
                      <ErrorOutlineOutlinedIcon
                        fontSize="small"
                        color="disabled"
                        onClick={() => handleTooltipIndexOpen(index)}
                        onMouseOver={() => handleTooltipIndexOpen(index)}
                        onMouseOut={handleTooltipIndexClose}
                      />
                    </Tooltip>
                  </ClickAwayListener>
                </Box>
              )
              }

          </Box>
          <Box>
            {desciptionsContent}
          </Box>
        </Box>
        {index !== R.toPairs(data).length - 1 && (
        <Divider orientation={dividerOrientation} className={classes.divider} />
        )}
      </Grid>
    );
  });
  return (
    <Paper>
      <Grid container>
        {R.pipe(
          R.toPairs,
          R.addIndex(R.map)(handleContent),
        )(data)}
      </Grid>
    </Paper>
  );
};

ReferralDataSection.defaultProps = {
  gridItemWidths: {
    xs: 12,
    md: 4,
  },
  desciptions: null,
};

ReferralDataSection.propTypes = {
  data: PropTypes.object.isRequired,
  gridItemWidths: PropTypes.object,
  explanations: PropTypes.object.isRequired,
  desciptions: PropTypes.object,
};

export default ReferralDataSection;
