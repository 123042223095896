/* eslint-disable jsx-a11y/anchor-is-valid */
import { Button, Checkbox } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import LaunchOutlinedIcon from '@material-ui/icons/LaunchOutlined';
import { Alert } from '@material-ui/lab';
import whyDidYouRender from '@welldone-software/why-did-you-render';
import { isEmpty, uniqueId } from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

import { marketRoute, dashboardRoute } from '../../../routes';
import { nodeOperations } from '../../../store/node/index';
import { UPDATE_USER_NODES_SUCCESS } from '../../../store/node/types';
import BackToHomeButton from '../../MaterialUiCustom/Button/BackToHomeButton';
import {
  CloseIconButton,
  RoundedButton,
} from '../../MaterialUiCustom/MaterialUiCustom';
import OverviewEmptyItem from '../../Overview/OverviewEmptyItem';
import MarketProtocolDeployContentItem from '../MarketProtocolDeployContentItem';

import UnUniFiDeployComplete from './UnUniFiDeployComplete';
import UnUniFiDeployFile from './UnUniFiDeployFile';
import { ununifiDeployContentStyles } from './styles';

const { REACT_APP_ENV } = process.env;

if (REACT_APP_ENV !== 'prod') {
  whyDidYouRender(React);
}

const useStyles = makeStyles(ununifiDeployContentStyles);

const UnUniFiDeployContent = (props) => {
  const [data, setData] = useState({});
  const [error, setError] = useState('');
  const [errorIn, setErrorIn] = useState({});
  const [filled, setFilled] = useState(false);
  const [checkedState, setChecked] = useState({ saved: false, notkeep: false });
  const handleSetCheck = (key, checked) => {
    setChecked((state) => ({
      ...state,
      [key]: checked,
    }));
  };
  const history = useHistory();
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { activeStep, handleStepperNext, steps } = props;
  const { updateUserNodesLoading } = useSelector((state) => state.loadingR);
  const userId = useSelector((state) => state.userR.user.userId);
  const { userNodes } = useSelector((state) => state.nodeR);
  const { nodes: rawNodes } = userNodes;
  const nodesUnUniFi = R.pipe(
    R.filter((node) => {
      const { nodeType, dateCreated } = node;
      const accAddress = R.view(R.lensPath(['nodeInfo', 'accAddress']), node);
      const migrated = R.view(R.lensPath(['nodeInfo', 'migrated']), node);
      return (
        nodeType === 'UNUNIFI_VALIDATOR_NODE'
      && (!accAddress || (moment(dateCreated).unix() < 1636588800 && !migrated))
      );
    }),
    R.sort(R.descend(R.prop('nodeName'))),
  )(rawNodes);

  useEffect(() => {
    if (!isEmpty(data) && nodesUnUniFi.length > 0) {
      const dataLen = R.values(data).length;
      if (dataLen === nodesUnUniFi.length) {
        setFilled(true);
      } else {
        setFilled(false);
      }
    }
  }, [data, nodesUnUniFi.length]);
  const updateData = R.curry(
    ({ nodeId, nodeName, nodeType }, walletFiles, fileContent) => {
      // setErrorIn((state) => R.assoc(nodeId, false, state));
      setError('');
      const { name } = walletFiles[0];
      setData((data) => R.assoc(
        nodeId,
        {
          nodeId,
          nodeName,
          nodeType,
          walletFiles,
          fileContent,
          fileName: name,
        },
        data,
      ));
    },
  );
  const removeFile = (nodeId) => setData((data) => R.omit([nodeId], data));

  const onClickGenerateWallet = () => {
    const url = 'https://ununifi.io/portal/accounts/create/simple';
    if (window.open) {
      window.open(url);
    }
  };
  const getAddAddressContent = () => {
    if (!nodesUnUniFi.length) {
      const onRedirectToProtocol = () => {
        history.push(marketRoute);
      };
      return (
        <Box className={classes.connectToWallet}>
          <Box className={classes.connectToWalletContent}>
            <OverviewEmptyItem
              text={(
                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  flexDirection="column"
                >
                  <Box>{t("You don't have any subscription")}</Box>
                  <Box pt={1}>
                    <RoundedButton
                      color="secondary"
                      variant="outlined"
                      onClick={onRedirectToProtocol}
                    >
                      {t('Deploy a Node')}
                    </RoundedButton>
                  </Box>
                </Box>
              )}
            />
          </Box>
        </Box>
      );
    }

    return (
      <Box className={classes.connectToWallet}>
        {error && (
          <Box>
            <Alert severity="error">
              {error || 'Something goes wrong. Try again'}
            </Alert>
          </Box>
        )}
        <Box className={classes.connectToWalletContent}>
          <Box>
            <Typography>
              {t('To migrate, you need 2 files for each node, public-wallet-upload-file-2022xxxxxxxxx.txt and wallet-backup-file-2022xxxxxxxxx.txt. Click generate button on each node, download wallet files then upload your public-wallet-upload-file-2022xxxxxxxxx.txt file(s)')}
            </Typography>
          </Box>
          {R.addIndex(R.map)((node, index) => {
            const { nodeName, nodeType, nodeId } = node;
            return (
              <Box
                key={uniqueId()}
                className={classes.accAddressFieldContainer}
              >
                <Box mb={1}>
                  <Typography variant="h3">
                    {`Node ${index + 1}`}
                  </Typography>
                </Box>
                <Box>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={onClickGenerateWallet}
                    endIcon={<LaunchOutlinedIcon />}
                    disabled={Boolean(data[nodeId])}
                  >
                    {t('generate')}
                  </Button>
                  <Box display="flex" justifyContent="space-between" mt={1}>
                    <UnUniFiDeployFile
                      setError={setError}
                      onFileChange={updateData({ nodeId, nodeName, nodeType })}
                      data={data}
                      isError={errorIn[nodeId]}
                      nodeId={nodeId}
                      setErrorIn={setErrorIn}
                      removeFile={removeFile}
                    />
                  </Box>
                </Box>
              </Box>
            );
          })(nodesUnUniFi)}
        </Box>
      </Box>
    );
  };

  const onConfirm = async (data) => {
    const formatedData = R.pipe(
      R.values,
      R.map((node) => {
        const {
          nodeName, nodeType, fileContent, fileName,
        } = node;
        return {
          userId,
          nodeName,
          nodeType,
          subNode: {
            publicWalletFileContent: JSON.stringify(fileContent),
            publicWalletFileName: fileName,
            migrated: true,
          },
        };
      }),
    )(data);
    const { type } = await dispatch(
      nodeOperations.updateUserNodes(formatedData),
    );

    if (type === UPDATE_USER_NODES_SUCCESS) {
      handleStepperNext();
    } else {
      toast.error(t('Failed! Please try again'), {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  const handleOnCompletedAndClose = () => {
    history.push({
      pathname: dashboardRoute,
    });
  };

  const createGetStepTitle = R.curry(
    (steps, activeStep) => steps[activeStep].title,
  );
  const getStepTitle = createGetStepTitle(steps);

  const getStepContent = (step) => {
    switch (step) {
      case 0: {
        return (
          <Grid container>
            <Grid item xs={12}>
              <MarketProtocolDeployContentItem
                title={getStepTitle(activeStep)}
                headerWrap
                content={getAddAddressContent()}
              />
            </Grid>
          </Grid>
        );
      }
      case 1:
        return (
          <MarketProtocolDeployContentItem
            title={getStepTitle(activeStep)}
            headerAction={(
              <CloseIconButton
                color="default"
                onClick={handleOnCompletedAndClose}
              />
            )}
            content={(
              <Box className={classes.completeSetup}>
                <Box className={classes.completeSetupContent}>
                  <UnUniFiDeployComplete />
                </Box>
              </Box>
            )}
          />
        );
      default:
        return undefined;
    }
  };

  const getFooterContent = (step) => {
    switch (step) {
      case 0:
        return !nodesUnUniFi.length ? null : (
          <Box>

            <Box display="flex" alignItems="center">

              <Checkbox
                checked={checkedState.saved}
                onChange={(event) => handleSetCheck('saved', event.target.checked)}
              />

              <Typography
                variant="body1"
              >
                {t('I am responsible for saving wallet-backup file')}
              </Typography>
            </Box>
            <Box display="flex" alignItems="center">

              <Checkbox
                checked={checkedState.notkeep}
                onChange={(event) => handleSetCheck('notkeep', event.target.checked)}
              />

              <Box mt="2px">
                <Typography
                  variant="body1"
                >
                  {t('I am acknowledge that Neukind does not store my wallet-backup file')}
                </Typography>
              </Box>
            </Box>
            <Box display="flex" justifyContent="center">
              <RoundedButton
                variant="contained"
                color="primary"
                onClick={() => onConfirm(data)}
                loading={updateUserNodesLoading}
                disabled={Boolean(!filled || !!error || !checkedState.saved || !checkedState.notkeep)}
                className={classes.footerButton}
              >
                {t('confirm')}
              </RoundedButton>
            </Box>

          </Box>

        );
      case 1:
        return <BackToHomeButton variant="contained" color="secondary" />;
      default:
        return null;
    }
  };

  return (
    <Box className={classes.ununifiDeployContentRoot}>
      <Box className={classes.ununifiDeployContent}>
        <Grid container justifyContent="center" spacing={2}>
          <Grid item xs={12} className={classes.contentGridItem}>
            {getStepContent(activeStep)}
          </Grid>
          <Grid item xs={12} className={classes.contentGridItem}>
            <Box mt={2}>{getFooterContent(activeStep)}</Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

UnUniFiDeployContent.propTypes = {
  activeStep: PropTypes.number.isRequired,
  handleStepperNext: PropTypes.func.isRequired,
  steps: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
    }),
  ).isRequired,
};

UnUniFiDeployContent.whyDidYouRender = true;

export default React.memo(UnUniFiDeployContent);
