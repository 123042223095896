import Box from '@material-ui/core/Box';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import CheckCircleOutlineOutlinedIcon from '@material-ui/icons/CheckCircleOutlineOutlined';
import LaunchOutlinedIcon from '@material-ui/icons/LaunchOutlined';
import { makeStyles } from '@material-ui/styles';
import PropTypes from 'prop-types';
import toUpper from 'ramda/src/toUpper';
import React from 'react';

import { getExplorerUrl } from './constants';
import { walletSuccessStyles } from './styles';

import { RoundedButton } from 'components/MaterialUiCustom/MaterialUiCustom';

const useStyles = makeStyles(walletSuccessStyles);
const WalletTransferBoardSuccess = ({
  txHash,
  chain,
  subChain,
  t,
  onClick,
  amount,
}) => {
  const classes = useStyles();
  const explorerUrl = getExplorerUrl(chain, subChain);

  const url = `${explorerUrl}/extrinsic/${txHash}`;
  return (
    <Box p={2}>
      <Box display="flex" justifyContent="center">
        <Typography variant="h2">
          {t('Transaction broadcasted')}
        </Typography>
      </Box>
      <Box display="flex" justifyContent="center" mt={2}>
        <CheckCircleOutlineOutlinedIcon className={classes.icon} />
      </Box>
      <Box display="flex" justifyContent="center" mt={2}>
        <Typography variant="body1" className={classes.transferedDesciption}>
          {t('{{amount}} {{chain}} transferred', {
            amount,
            chain: toUpper(subChain) || toUpper(chain),
          })}
          {/* {`${amount} ${toUpper(subChain) || toUpper(chain)} transferred`} */}
        </Typography>
      </Box>
      <Box mt={2}>
        <Typography variant="body2">
          {t('it takes more than 10 minutes until the transaction is shown and finalized on the blockchain. Please be patient')}
        </Typography>
      </Box>
      <Box mt={2} display="flex" justifyContent="center">
        <Link href={url} target="_blank">
          <Box display="flex" alignItems="center">
            <Box mr={1}>
              <LaunchOutlinedIcon />
            </Box>
            <Box mb={1}>
              <Typography>
                {t('View on Subscan')}
              </Typography>
            </Box>
          </Box>
        </Link>
      </Box>
      <Box mt={2} display="flex" justifyContent="center">
        <RoundedButton color="primary" onClick={onClick}>
          {t('got it!')}
        </RoundedButton>
      </Box>
    </Box>
  );
};

WalletTransferBoardSuccess.propTypes = {
  txHash: PropTypes.string,
  chain: PropTypes.string.isRequired,
  subChain: PropTypes.string.isRequired,
  t: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired,
  amount: PropTypes.number.isRequired,
};
WalletTransferBoardSuccess.defaultProps = {
  txHash: '',
};

export default WalletTransferBoardSuccess;
