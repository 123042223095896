import * as R from 'ramda';

import { errorOperations } from '../error/index';
import { loadingOperations } from '../loading/index';

import * as actions from './actions';

export const getAffiliate = R.curry(
  async (userId, dispatch) => {
    const operationName = 'getAffiliate';
    try {
      dispatch(loadingOperations.setLoading(operationName, true));
      const response = dispatch(
        await actions.getAffiliate(userId),
      );
      dispatch(loadingOperations.setLoading(operationName, false));
      dispatch(errorOperations.clearError(operationName));
      return response;
    } catch (error) {
      dispatch(loadingOperations.setLoading(operationName, false));
      return dispatch(errorOperations.setError(
        operationName,
        { fallbackErrorMessage: 'Get affiliates failed', error },
      ));
    }
  },
);
