import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import React from 'react';

import { styles } from './styles';

import Alert from 'components/MaterialUiCustom/Alert/Alert';
import { truncateWalletAddress } from 'components/MyNodeInfo/utils';
import useIsMobile from 'hooks/useIsMobile';

const useStyles = makeStyles(styles);

const WalletAccount = ({
  account,
  t,
  onlyTruncatedInMobile,
  truncatedStartIndex,
  truncatedEndIndex,
  accountTypographyProps,
  truncate,
}) => {
  const [openTooltip, setOpenTooltip] = React.useState(false);
  const classes = useStyles();
  const isMobile = useIsMobile();

  const handleTooltipClose = () => {
    setOpenTooltip(false);
  };
  const handleTooltipOpen = () => {
    setOpenTooltip(true);
  };
  const truncatedAccount = truncateWalletAddress(truncatedStartIndex || 5, truncatedEndIndex || -4, account);

  const getAccount = R.curry((truncate, onlyTruncatedInMobile, account) => {
    if (!truncate) return account;
    if (onlyTruncatedInMobile) return isMobile ? truncatedAccount : account;
    return truncatedAccount;
  });
  const getAccountContent = (account) => {
    if (!account) {
      return (
        <Alert
          severity="error"
          className={classes.alert}
        >
          {t('Unable to get address')}
        </Alert>
      );
    }
    return (
      <ClickAwayListener onClickAway={handleTooltipClose}>
        <Tooltip
          open={openTooltip}
          title={(
            <Typography variant="body2">
              {account}
            </Typography>
          )}
          placement="top"
        >
          <Typography
            variant="body2"
            onClick={handleTooltipOpen}
            onMouseOver={handleTooltipOpen}
            onMouseOut={handleTooltipClose}
            {...accountTypographyProps}
          >
            {getAccount(truncate, onlyTruncatedInMobile, account)}
          </Typography>
        </Tooltip>
      </ClickAwayListener>
    );
  };
  return getAccountContent(account);
};

WalletAccount.propTypes = {
  account: PropTypes.string,
  t: PropTypes.func.isRequired,
  onlyTruncatedInMobile: PropTypes.bool,
  truncate: PropTypes.bool,
  truncatedStartIndex: PropTypes.number,
  truncatedEndIndex: PropTypes.number,
  accountTypographyProps: PropTypes.shape({
    // add more props here when you need
    noWrap: PropTypes.bool,
  }),
};

WalletAccount.defaultProps = {
  account: '',
  onlyTruncatedInMobile: false,
  truncate: true,
  truncatedStartIndex: undefined,
  truncatedEndIndex: undefined,
  accountTypographyProps: {},
};

export default WalletAccount;
