import gql from 'graphql-tag';

export const getNodesQuery = gql`
  query getNodes($getNodesInput: GetNodesInput!)
  {
    getNodes(
      getNodesInput: $getNodesInput
    ) {
      meta {
        totalItems
        itemCount
      }
      items {
        nodeId
        nodeName
        status
        nodeType
        subNode
        user {
          userId
          email
        }        
      }
    }
  }
`;

export const getAdminTotalReportQuery = gql`
  query getAdminTotalReport($getAdminTotalReportInput: GetAdminTotalReportInput!)
  {
    getAdminTotalReport(
      getAdminTotalReportInput: $getAdminTotalReportInput
    ) {
      startTime
      endTime
      usersEarnings {
        userId
        ununifiDateCreated
        earnings
      }
    }
  }
`;
