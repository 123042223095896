import AppBar from '@material-ui/core/AppBar';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import AccountCircle from '@material-ui/icons/AccountCircle';
import ExitToAppRounded from '@material-ui/icons/ExitToAppOutlined';
import MenuIcon from '@material-ui/icons/Menu';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { useIsMobile, useGetQuery, useUserDataSelector } from '../../hooks/hooks';
import {
  signinRoute, signupRoute, accountRoute, rootRoute, dashboardRoute,
} from '../../routes';
import { drawerOperations } from '../../store/drawer/index';
import { routerServices } from '../../store/router/index';
import { persistor } from '../../store/store';
import { userOperations } from '../../store/user/index';
import RoundedButton from '../MaterialUiCustom/Button/RoundedButton';

import AnnouncementBarContent from './AnnouncementBarContent';
import NavigationAppBarLang from './NavigationAppBarLang';
import { styles } from './styles';

const useStyles = makeStyles(styles);

const NavigationAppBar = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const history = useHistory();

  const queryLng = useGetQuery('lng');

  const [anchorEl, setAnchorEl] = useState(null);
  const { userId, email } = useUserDataSelector();

  const onSignout = async () => {
    await dispatch(userOperations.signOut(userId));
    await persistor.purge();
  };

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const isMobile = useIsMobile();

  const {
    isAuthenticated,
    isErrorPage,
  } = props;
  const openMenu = Boolean(anchorEl);

  const drawerR = useSelector((state) => state.drawerR);
  const { openDrawer } = drawerR;

  const onOpenDrawer = async () => {
    await dispatch(drawerOperations.openDrawer());
  };
  const signinText = t('Sign in');
  const signupText = t('Sign up');

  const crateHandleLinkBtnClick = R.curry((queryLng, pathname) => {
    handleClose();
    routerServices.historyPush(
      history,
      {
        pathname,
        queryLng,
      },
    );
  });
  const handleLinkBtnClick = crateHandleLinkBtnClick(queryLng);
  const handleCloseSignout = (rootRoute) => {
    setAnchorEl(null);
    onSignout();
    handleLinkBtnClick(rootRoute);
  };
  const getLangComponent = (isMobile) => (!isMobile
    ? (
      <Box>
        <NavigationAppBarLang />
      </Box>
    )
    : null);
  const getNavAccessContent = (isMobile) => {
    if (!isMobile) {
      return (
        <>
          <Box>
            <RoundedButton
              onClick={() => handleLinkBtnClick(signinRoute)}
              className={classes.menuBtn}
              variant="text"
              color="secondary"
            >
              {signinText}
            </RoundedButton>
          </Box>
          <Box>

            <RoundedButton
              onClick={() => handleLinkBtnClick(signupRoute)}
              className={classes.menuBtn}
              variant="contained"
            >
              {signupText}
            </RoundedButton>
          </Box>
          {getLangComponent(isMobile)}
        </>
      );
    }

    return (
      <>
        <Box>
          <NavigationAppBarLang iconOnly />
        </Box>
        <Box>
          <IconButton
            aria-owns={openMenu ? 'access-appbar' : null}
            aria-haspopup="true"
            onClick={handleMenu}
            color="inherit"
          >
            <ExitToAppRounded
              className={classes.exitToApp}
            />
          </IconButton>

          <Menu
            id="menu-appbar"
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            open={openMenu}
            onClose={handleClose}
          >
            <MenuItem
              onClick={() => handleLinkBtnClick(signinRoute)}
            >
              {signinText}
            </MenuItem>
            <MenuItem
              onClick={() => handleLinkBtnClick(signupRoute)}
            >
              {signupText}
            </MenuItem>
          </Menu>
        </Box>

      </>
    );
  };

  const handleLogoCLick = () => {
    if (isAuthenticated) {
      history.push({
        pathname: dashboardRoute,
      });
    } else {
      history.push({
        pathname: signinRoute,
      });
    }
  };
  const getNeukindIcon = () => {
    if (isMobile) {
      if (!openDrawer) {
        return (
          <img
            className={classNames(
              classes.mobileImage,
              !isAuthenticated && classes.mobileNotAuthenticatedImage,
            )}
            color="inherit"
            alt="Neukind"
            src="/img/neukindLogoMarkDark.png"
          />
        );
      }

      return null;
    }

    return (
      <img
        className={classNames(
          classes.image,
          !isAuthenticated && classes.notAuthenticatedImage,
          isAuthenticated && classes.authenticatedImage,
        )}
        color="inherit"
        alt="Neukind"
        src="/img/neukindLogoMarkDark.png"
      />
    );
  };

  const getLeftSideContent = () => {
    if (isAuthenticated) {
      return (
        <>
          <Box>
            <IconButton
              aria-owns={openMenu ? 'menu-appbar' : null}
              aria-haspopup="true"
              onClick={handleMenu}
              color="inherit"
            >
              <AccountCircle
                className={classes.accountCircle}
              />
            </IconButton>

            <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={openMenu}
              onClose={handleClose}
            >
              <MenuItem className="pv3">
                <AccountCircle />
                <span className="mh1">{email}</span>
              </MenuItem>
              <MenuItem onClick={() => handleLinkBtnClick(accountRoute)}>{t('My account')}</MenuItem>
              <MenuItem onClick={() => handleCloseSignout(rootRoute)}>{t('Sign out')}</MenuItem>
            </Menu>
          </Box>
          {getLangComponent(isMobile)}
        </>
      );
    }

    if (isErrorPage) {
      return (
        <Box>
          <NavigationAppBarLang iconOnly />
        </Box>
      );
    }

    return getNavAccessContent(isMobile);
  };
  return (
    <AppBar
      position="fixed"
      className={
        classNames(classes.appBar)
        }
    >
      <Toolbar className={classes.toolbar}>
        <Grid container>
          {process.env.REACT_APP_ANNOUNCEMENT_BAR_DISPLAY === 'ON' && (
          <Grid
            item
            xs={12}
            className={classes.announcementBar}
          >
            <AnnouncementBarContent
              text="Phala Network マイニングマシン先行販売"
              action={(
                <Typography variant="body1" className={classes.announcementBarContentActionText}>
                  Sold out!
                </Typography>
              )}
            />
            {/* <AnnouncementBarContent */}
            {/*   text={t('Our infrastructure provider is having an outage. We are sorry for this inconvenience')} */}
            {/*   action={( */}
            {/*     <Typography variant="body1" className={classes.announcementBarContentActionText}> */}
            {/*       {t('Incident')} */}
            {/*     </Typography> */}
            {/*   )} */}
            {/* /> */}
          </Grid>
          )}
          <Grid
            item
            xs={12}
            className={classes.toolbarContent}
          >
            {isMobile && isAuthenticated && (
            <IconButton color="inherit" onClick={onOpenDrawer} disabled={openDrawer || !isAuthenticated} className={classes.menuButton}>
              {!openDrawer && isAuthenticated && (
              <MenuIcon
                className={classes.menuIcon}
              />
              )}
            </IconButton>
            )}
            <Box
              display="flex"
              justifyContent="center"
              flexDirection="row"
              alignItems="center"
              style={{
                cursor: 'pointer',
              }}
              onClick={handleLogoCLick}
            >
              {getNeukindIcon()}
            </Box>

            <Box
              display="flex"
              justifyContent="center"
              flexDirection="row"
              alignItems="center"
            >
              {getLeftSideContent()}

              {/*           {isAuthenticated && ( */}
              {/*           <Box> */}
              {/*  */}
              {/*             <IconButton */}
              {/*               aria-owns={openMenu ? 'menu-appbar' : null} */}
              {/*               aria-haspopup="true" */}
              {/*               onClick={handleMenu} */}
              {/*               color="inherit" */}
              {/*             > */}
              {/*               <AccountCircle */}
              {/*                 className={classes.accountCircle} */}
              {/*               /> */}
              {/*             </IconButton> */}
              {/*  */}
              {/*             <Menu */}
              {/*               id="menu-appbar" */}
              {/*               anchorEl={anchorEl} */}
              {/*               anchorOrigin={{ */}
              {/*                 vertical: 'top', */}
              {/*                 horizontal: 'right', */}
              {/*               }} */}
              {/*               transformOrigin={{ */}
              {/*                 vertical: 'top', */}
              {/*                 horizontal: 'right', */}
              {/*               }} */}
              {/*               open={openMenu} */}
              {/*               onClose={handleClose} */}
              {/*             > */}
              {/*               <MenuItem className="pv3"> */}
              {/*                 <AccountCircle /> */}
              {/*                 <span className="mh1">{email}</span> */}
              {/*               </MenuItem> */}
              {/*               <MenuItem onClick={() => handleLinkBtnClick(accountRoute)}>My account</MenuItem> */}
              {/*               <MenuItem onClick={() => handleCloseSignout(rootRoute)}>Sign out</MenuItem> */}
              {/*             </Menu> */}
              {/*           </Box> */}
              {/*  */}
              {/*           ) */}
              {/*           } */}
              {/*  */}
              {/*           {!isMobile && <NavigationAppBarLang />} */}
              {/*  */}
            </Box>
          </Grid>
        </Grid>

        {/*         { !isAuthenticated */}
        {/*               && getNavAccessContent(isMobile) */}
        {/*  */}
        {/*          } */}
      </Toolbar>
    </AppBar>
  );
};

NavigationAppBar.propTypes = {
  /**
   * Is user signed in or not
   * @type {Boolean}
   */
  isAuthenticated: PropTypes.bool.isRequired,
  /**
   * User email
   * @type {string}
   */
  email: PropTypes.string.isRequired,
  /**
   * Is it error page. We will not show sign in and sign up button in error page since
   * it causes some issues.
   * @type {Boolean}
   */
  isErrorPage: PropTypes.bool,
};

NavigationAppBar.defaultProps = {
  isErrorPage: false,
};

export default NavigationAppBar;
