import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

// routes

// others

import { stylesUsageItem } from './styles';

const useStyles = makeStyles(stylesUsageItem);

const MyNodeInfoUsageItem = (props) => {
  const classes = useStyles();
  const { value, title, unit } = props;

  const valueToRender = (!value || value === 'NaN') ? '0' : value;

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
      className={classNames(classes.container)}
    >
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <Typography className={classNames(classes.value)}>{valueToRender}</Typography>
        <Typography className={classNames(classes.unit)}>{unit}</Typography>
      </Box>
      <Box>
        <Typography className={classNames(classes.title)}>{title}</Typography>
      </Box>
    </Box>
  );
};

MyNodeInfoUsageItem.defaultProps = {
  value: '',
  title: '',
  unit: '',
};

MyNodeInfoUsageItem.propTypes = {
  value: PropTypes.string,
  title: PropTypes.string,
  unit: PropTypes.string,
};

export default MyNodeInfoUsageItem;
