// react-table

import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import NativeSelect from '@material-ui/core/NativeSelect';
import Table from '@material-ui/core/Table';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import { makeStyles } from '@material-ui/core/styles';
import NavigateBeforeOutlinedIcon from '@material-ui/icons/NavigateBeforeOutlined';
import NavigateNextOutlinedIcon from '@material-ui/icons/NavigateNextOutlined';
import Skeleton from '@material-ui/lab/Skeleton';
import whyDidYouRender from '@welldone-software/why-did-you-render';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import React from 'react';
import {
  useTable,
  useSortBy,
  useFilters,
  useExpanded,
  usePagination,
} from 'react-table';
import { useGlobalFilter } from 'react-table/dist/react-table.development';

import { Filter, DefaultColumnFilter } from './Filter';
import TableToolbar from './ReactTableV7Toolbar';
import { tableStyles } from './styles';

const useStyles = makeStyles(tableStyles);

if (process.env.REACT_APP_ENV !== 'prod') {
  whyDidYouRender(React);
}

const ReactTableV7 = (props) => {
  const classes = useStyles();

  const {
    columns,
    data,
    filter,
    sort,
    renderRowSubComponent,
    customPageSize,
    customPageSizeList,
    loading,
    showToolbar,
    hiddenColumns,
    toolbarTitle,
    disablePaging,
  } = props;
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    visibleColumns,
    canPreviousPage,
    canNextPage,
    // pageOptions,
    // pageCount,
    // gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize, globalFilter },
    preGlobalFilteredRows,
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data,
      defaultColumn: { Filter: DefaultColumnFilter },
      initialState: {
        pageIndex: 0,
        pageSize: customPageSize || 10,
        hiddenColumns: hiddenColumns || [],
      },
      autoResetExpanded: false,
      autoResetPage: false,
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    useExpanded,
    usePagination,
  );

  const onChangeInSelect = (event) => {
    setPageSize(Number(event.target.value));
  };

  const startItemIndex = pageSize * pageIndex;
  const endItemIndex = pageSize * (pageIndex + 1);

  const customPageSizeListToUse = customPageSizeList;
  const minimumPageSize = customPageSizeListToUse[0];
  return (
    <Box
      className={
        classes.root
      }
    >
      {showToolbar && (
      <TableToolbar
        title={toolbarTitle}
        preGlobalFilteredRows={preGlobalFilteredRows}
        setGlobalFilter={setGlobalFilter}
        globalFilter={globalFilter}
      />
      )}
      <Table {...getTableProps()}>
        <TableHead className={classes.header}>
          {headerGroups.map((headerGroup) => (
            <TableRow {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <TableCell {...column.getHeaderProps()}>
                  {
                    sort && (column.sortable !== false)
                      ? (
                        <TableSortLabel
                          active={column.isSorted}
                          direction={column.isSortedDesc ? 'desc' : 'asc'}
                          className={classes.headerText}
                        >
                          {sort && (
                          <Box {...column.getSortByToggleProps()}>
                            {column.render('Header')}
                          </Box>
                          )}
                          {!sort && (
                          <Box>
                            {column.render('Header')}
                          </Box>
                          )}
                        </TableSortLabel>
                      )
                      : (
                        <Box className={classes.headerText}>
                          {column.render('Header')}
                        </Box>
                      )
                  }

                  {filter && <Filter column={column} />}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableHead>
        <tbody {...getTableBodyProps()}>
          {page.map((row) => {
            prepareRow(row);
            return (
              <React.Fragment key={row.getRowProps().key}>
                <TableRow>
                  {row.cells.map((cell) => <TableCell {...cell.getCellProps()}>{loading ? <Skeleton /> : cell.render('Cell')}</TableCell>)}
                </TableRow>
                {row.isExpanded && (
                <TableRow className={classes.expandContent}>
                  <TableCell colSpan={visibleColumns.length}>{renderRowSubComponent(row)}</TableCell>
                </TableRow>
                )}
              </React.Fragment>
            );
          })}
          {R.addIndex(R.map)((each, index) => (
            <TableRow key={`empty_row_${index}_`}>
              {R.addIndex(R.map)((each, index) => (
                <TableCell key={`empty_cell_${index}`}>
                  {loading ? <Skeleton /> : <Box height={20} />}
                </TableCell>
              ), columns)}
            </TableRow>
          ), R.range(0, minimumPageSize - page.length))}
        </tbody>
      </Table>
      {!disablePaging && (
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="flex-end"
        alignItems="center"
      >
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="center"
          alignItems="center"
          m={1}
        >
          <Box m={1}>
            Rows per page:
            {' '}
          </Box>
          <Box m={1}>
            <NativeSelect id="page-size-selection" variant="standard" type="select" value={pageSize} onChange={onChangeInSelect}>
              {customPageSizeListToUse.map((pageSize) => (
                <option key={pageSize} value={pageSize}>
                  {pageSize}
                </option>
              ))}
            </NativeSelect>
          </Box>
        </Box>
        <Box m={1}>
          <strong>
            {startItemIndex}
            -
            {endItemIndex <= data.length ? endItemIndex : data.length}
          </strong>
          {' '}
          of
          {' '}
          <strong>
            {data.length}
          </strong>
        </Box>
        <Box>
          <IconButton
            color="primary"
            onClick={previousPage}
            disabled={!canPreviousPage}
          >
            <NavigateBeforeOutlinedIcon />
          </IconButton>
        </Box>
        <Box>
          <IconButton color="primary" onClick={nextPage} disabled={!canNextPage}>
            <NavigateNextOutlinedIcon />
          </IconButton>
        </Box>
      </Box>
      )}
    </Box>
  );
};

ReactTableV7.defaultProps = {
  toolbarTitle: '',
  className: '',
  filter: false,
  sort: false,
  renderRowSubComponent: () => {},
  customPageSize: 10,
  loading: false,
  showToolbar: false,
  customPageSizeList: [10, 20, 30, 40, 50],
  hiddenColumns: [],
};

ReactTableV7.propTypes = {
  toolbarTitle: PropTypes.string,
  className: PropTypes.string,
  filter: PropTypes.bool,
  sort: PropTypes.bool,
  /**
   * function that take row data as arg
   * then return component render
   */
  renderRowSubComponent: PropTypes.func,
  columns: PropTypes.array.isRequired,
  data: PropTypes.array.isRequired,
  customPageSize: PropTypes.number,
  customPageSizeList: PropTypes.arrayOf(PropTypes.number),
  loading: PropTypes.bool,
  showToolbar: PropTypes.bool,
  hiddenColumns: PropTypes.arrayOf(PropTypes.string),
  disablePaging: PropTypes.bool,
};

ReactTableV7.whyDidYouRender = true;

export default React.memo(ReactTableV7);
