import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import { makeStyles } from '@material-ui/core/styles';
import CloseOutlinedIcon from '@material-ui/icons/CloseOutlined';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { useIsMobile } from '../../hooks/hooks';
import { drawerOperations } from '../../store/drawer/index';
import NavigationAppBarLang from '../NavigationAppBar/NavigationAppBarLang';
import ServiceTermsDrawer from '../ServiceTerms/ServiceTermsDrawer';
import SocialIcons from '../SocialIcons/SocialIcons';

import MainListItems from './MainListItems';
import { styles } from './styles';

const useStyles = makeStyles(styles);

const NavigationDrawer = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const {
    userId,
    isAuthenticated,
    role,
    email,
  } = props;
  const isMobile = useIsMobile();

  const drawerR = useSelector((state) => state.drawerR);

  const { openDrawer } = drawerR;
  const openDrawerToUse = isMobile ? openDrawer : true;

  const onCloseDrawer = async () => {
    await dispatch(drawerOperations.closeDrawer());
  };

  const onOpenDrawer = async () => {
    await dispatch(drawerOperations.openDrawer());
  };

  const handleClickAway = async () => {
    if (isMobile) {
      await dispatch(drawerOperations.closeDrawer());
    }
  };

  return (
    <>
      <SwipeableDrawer
        variant={isMobile ? 'temporary' : 'permanent'}
        anchor="left"
        className={classNames(classes.drawer, {
          [classes.drawerOpen]: openDrawerToUse,
          [classes.drawerClose]: !openDrawerToUse,
        })}
        classes={{
          paper: classNames(classes.drawerPaper, {
            [classes.drawerOpen]: openDrawerToUse,
            [classes.drawerClose]: !openDrawerToUse,
          }),
        }}
        open={openDrawerToUse}
        onClose={handleClickAway}
        onOpen={onOpenDrawer}
      >
        <Box display="flex" flexDirection="column" className={classes.rootBox}>
          <Box flex={1}>
            <Box display="flex" flexGrow={1} className={classes.sideBarHeader}>
              <Box className={classes.toolbarIcon}>
                <IconButton onClick={onCloseDrawer}>
                  <CloseOutlinedIcon />
                </IconButton>
              </Box>
            </Box>
            <Box pl={1} pr={1}>
              <Divider />
            </Box>
            <List
              style={{ padding: 0 }}
            >
              <MainListItems
                userId={userId}
                isAuthenticated={isAuthenticated}
                openDrawer={openDrawerToUse}
                role={role}
                email={email}
              />
              {/* <Box pl={1} pr={1}>
                <Divider />
              </Box>
              <SecondaryListItems
                userId={userId}
                isAuthenticated={isAuthenticated}
                openDrawer={openDrawerToUse}
                role={role}
              /> */}
            </List>
          </Box>
          {openDrawerToUse && (
            <Box>
              {isMobile
                  && (
                  <Box>
                    <NavigationAppBarLang onCloseDrawer={onCloseDrawer} />
                  </Box>
                  )}
              <Box mt={1} mb={1}>
                <SocialIcons />
              </Box>
              <Box mt={isMobile ? 1 : 0}>
                <ServiceTermsDrawer />
              </Box>
            </Box>
          )}
        </Box>

      </SwipeableDrawer>
      {/* </Hidden> */}
    </>
  );
};

NavigationDrawer.propTypes = {
  /**
   * Is user signed in or not
   * @type {Boolean}
   */
  isAuthenticated: PropTypes.bool.isRequired,
  /**
   * User Id
   * @type {string|number}
   */
  userId: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]).isRequired,
  /**
   * Role of user i.e. 'USER', 'ADMIN' etc.
   * @type {string}
   */
  role: PropTypes.string,
  email: PropTypes.string.isRequired,
};

NavigationDrawer.defaultProps = {
  role: 'USER',
};

export default NavigationDrawer;
