import {
  pipeAwait,
} from 'jsutils';
import * as R from 'ramda';

import {
  graphqlNormalize,
} from '../../modules/apollo/apollo';
import {
  returnAction,
  createUpdateAction,
} from '../store_utils';

import {
  graphqlGetPayments,
  graphqlGetSubsctiptions,
} from './graphql/services';
import * as types from './types';

export const updatePaymentData = R.curry(
  (amount) => ({
    type: types.UPDATE_PAYMENT_DATA,
    payload: {
      amount,
    },
  }),
);

export const updatePaymentStripe = R.curry(
  (stripePriceId, stripePaymentMethodId) => ({
    type: types.UPDATE_PAYMENT_STRIPE,
    payload: {
      stripePriceId,
      stripePaymentMethodId,
    },
  }),
);

/**
 * Get payment history
 *
 * @param      {string}  userId
 * @return     {object}
 */
export const getPaymentHistory = R.curry(
  async (userId) => pipeAwait(
    graphqlGetPayments,
    graphqlNormalize('getPayments'),
    returnAction(types.GET_PAYMENT_HISTORY),
  )({ getPaymentsInput: { userId } }),
);

export const getSubscriptions = R.curry(
  async (userId) => pipeAwait(
    graphqlGetSubsctiptions,
    graphqlNormalize('getSubscriptions'),
    returnAction(types.GET_SUBSCRIPTIONS_SUCCESS),
  )({ getSubscriptionsInput: { userId } }),
);

export const updateStripePromotionCode = createUpdateAction(types.UPDATE_PROMOTION_CODE, ['promotionCode']);
export const updateStripeTrialDays = createUpdateAction(types.UPDATE_PROMOTION_CODE, ['trialDays']);
