import Grid from '@material-ui/core/Grid';
import isEmpty from 'lodash/isEmpty';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import WalletAccess from './WalletAccess';
import WalletFeatures from './WalletFeatures';
import WalletInfo from './WalletInfo';

import useAddFunctionToRef from 'hooks/useAddFunctionToRef';
import { nodeOperations, nodeSelectors } from 'store/node';
import { walletOperations, walletSelectors } from 'store/wallet';
import { web3Operations, web3Selectors } from 'store/web3';

// import { Alert } from '@material-ui/lab';
// import { Typography } from '@material-ui/core';

const { REACT_APP_PHALA_WS_ENDPOINT } = process.env;
// hardcode chain and subChain. TODO dry
const chain = 'dot';
const subChain = 'pha';
// HARD code for now. DRY later
const ss58Format = 30;
const Wallet = () => {
  const [api, setApi] = useState(null);
  // const [selectedAccount, setSelectedAccount] = useState({});

  const { t } = useTranslation();
  const walletConnectMethod = useSelector(walletSelectors.selectWalletConnectMethod);
  const selectedAccount = useSelector(walletSelectors.selectWalletSelectedAccount);
  const { address } = selectedAccount;
  const isSelectedAccountEmpty = isEmpty(selectedAccount);
  const dispatch = useDispatch();
  const priceData = useSelector(nodeSelectors.selectCoinPrice('PHA'));

  const onSetWalletConnectMethod = (walletConnectMethod) => {
    dispatch(walletOperations.setWalletConnectMethod(walletConnectMethod));
  };
  const onSetWalletSelectedAccount = (selectedAccount) => {
    dispatch(walletOperations.setWalletSelectedAccount(selectedAccount));
  };
  const onResetSelectedAccount = () => {
    dispatch(walletOperations.resetWalletSelectedAccount());
  };
  const balance = useSelector(
    web3Selectors.selectAccountBalance(chain, subChain, address),
  ); // an object {free, reversed}
  const onGetAccountInfo = useAddFunctionToRef(
    async (chain, subChain, { api, address }) => {
      dispatch(await web3Operations.getAccountInfo(
        chain,
        subChain,
        {
          api,
          address,
        },
      ));
    },
  );
  const onGetCoinPrice = useAddFunctionToRef(
    async () => {
      await dispatch(nodeOperations.getCoinPrice('PHA', 'JPY'));
    },
  );

  const onGoBackToSelectMethod = () => {
    onResetSelectedAccount();
  };
  useEffect(() => {
    if (address && api) {
      onGetAccountInfo(chain, subChain, { api, address });
    }
  }, [address, api, onGetAccountInfo]);

  useEffect(() => {
    if (address) {
      onGetCoinPrice();
    }
  }, [address, onGetCoinPrice]);
  const onInitializeApi = useAddFunctionToRef(async (chain, endpoint) => {
    const { payload } = await dispatch(web3Operations.initializeApi(chain, endpoint));
    return payload;
  });
  useEffect(() => {
    const handleAsync = async () => {
      const api = await onInitializeApi('dot', REACT_APP_PHALA_WS_ENDPOINT);
      setApi(api);
    };
    handleAsync();
  }, [onInitializeApi]);
  const {
    accounts,
    isExtensionsInjected,
  } = useSelector((state) => web3Selectors.selectInjectedOrGeneratedAccounts(chain, 'injectedAccounts', state.web3R.web3));

  const onEnableWeb3 = useAddFunctionToRef(async (chain, ss58Format) => {
    dispatch(await web3Operations.enableWeb3(chain, { ss58Format }));
  });

  useEffect(() => {
    if (!isEmpty(selectedAccount) && !isExtensionsInjected && walletConnectMethod === 'connect') {
      onEnableWeb3(chain, ss58Format);
    }
  }, [isExtensionsInjected, onEnableWeb3, selectedAccount, walletConnectMethod]);

  const getContent = () => {
    if (isSelectedAccountEmpty) {
      return (
        <Grid container spacing={1}>
          {/* <Grid item xs={12} >
          <Alert
            severity="info"
          >
            <Typography variant="body2" color="inherit">
            We are updating our wallet, it will be available soon. Your money is safe.
            </Typography>
          </Alert>
          </Grid> */}
          <Grid item xs={12}>
            <WalletAccess
              t={t}
              onSetWalletConnectMethod={onSetWalletConnectMethod}
              onSetWalletSelectedAccount={onSetWalletSelectedAccount}
              walletConnectMethod={walletConnectMethod}
              subChain={subChain}
              chain={chain}
              ss58Format={ss58Format}
              selectedAccount={selectedAccount}
              accounts={accounts}
              isExtensionsInjected={isExtensionsInjected}
            />
          </Grid>
        </Grid>
      );
    }
    return (
      <Grid container spacing={4}>
        <Grid item xs={12} md={12}>
          <WalletInfo
            t={t}
            account={selectedAccount}
            balance={balance}
            priceData={priceData}
            subChain={subChain}
            chain={chain}
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <WalletFeatures
            t={t}
            walletConnectMethod={walletConnectMethod}
            selectedAccount={selectedAccount}
            chain={chain}
            subChain={subChain}
            api={api}
            balance={balance}
            priceData={priceData}
            onGoBackToSelectMethod={onGoBackToSelectMethod}
            ss58Format={ss58Format}
            accounts={accounts}
          />
        </Grid>
      </Grid>
    );
  };
  return (
    getContent()
  );
};

export default Wallet;
