import {
  pipeAwait,
} from 'jsutils';
import * as R from 'ramda';

import { hdWallet } from '../crypto/index';

const { generateKeyPairsFromSecretPhrase } = hdWallet;

const getFirstKeyPair = (keyPairs) => R.pipe(
  R.head,
  R.pick(['walletIndex', 'publicKey']),
)(keyPairs);

export const generateSecretPhraseValidation = R.curry(
  async (secretPhrase) => {
    // To validate secret phrase one only needs the first address
    const startIndex = 0;
    const endIndex = 0;
    return pipeAwait(
      generateKeyPairsFromSecretPhrase(
        secretPhrase,
      ),
      getFirstKeyPair,
    )({
      startIndex,
      endIndex,
    });
  },
);
