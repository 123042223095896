import React from 'react';
import { useTranslation } from 'react-i18next';

import CustomTabs from '../Tabs/CustomTabs';

import WalletContent from './WalletContent';

const Wallet = () => {
  const { t } = useTranslation();
  const tabs = [
    {
      label: t('Wallet'),
      content: <WalletContent />,
    },
  ];

  return (
    <CustomTabs
      tabs={tabs}
    />
  );
};

export default Wallet;
