import camelCase from 'lodash/camelCase';
import * as R from 'ramda';

import { CustomError } from '../../modules/error_utils';
import { errorOperations } from '../error/index';
import { loadingOperations } from '../loading/index';
import { orderOperations } from '../order/index';

import * as actions from './actions';
import * as services from './services';

export const generateDeployNodeConfig = R.curry(
  async (
    nodeType,
    configData,
    dispatch,
  ) => {
    try {
      dispatch(loadingOperations.setLoading('generateDeployNodeConfig', true));
      const { type, payload } = dispatch(
        await actions.generateDeployNodeConfig(
          nodeType,
          configData,
        ),
      );
      dispatch(loadingOperations.setLoading('generateDeployNodeConfig', false));
      dispatch(errorOperations.clearError('generateDeployNodeConfig'));
      return { type, payload };
    } catch (error) {
      dispatch(loadingOperations.setLoading('generateDeployNodeConfig', false));
      return dispatch(errorOperations.setError(
        'generateDeployNodeConfig',
        { fallbackErrorMessage: 'generate deploy node config failed', error },
      ));
    }
  },
);

export const { toggleDeployConfigMetadata } = actions;
export const { updateCredentials } = actions;

export const { updateDeployConfigNetWork } = actions;
export const { updateDeployConfigNodes } = actions;

export const { resetDeployConfig } = actions;

export const toogleDisableDeployBtnByNodeType = R.curry(
  (
    nodeType,
    dispatch,
    getState,
  ) => {
    const state = getState();
    const metadata = R.view(R.lensPath(['deployConfigR', 'metadata']), state);
    const { paymentSelected } = metadata;
    const nodeMetadata = R.pipe(
      R.view(R.lensPath([nodeType])),
      services.metadataDisableBtnPickers[nodeType],
    )(metadata);

    const allNodeTypeConditionsPassed = R.pipe(
      R.values,
      R.all(R.equals(true)),
    )(nodeMetadata);

    const deployBtnDisabled = !(allNodeTypeConditionsPassed && paymentSelected);
    return dispatch(toggleDeployConfigMetadata({ updatePath: ['deployBtnDisabled'], metadata: deployBtnDisabled }));
  },
);

export const { resetDeployNodeConfigMetatData } = actions;

export const { updateDeployNodeQuantity } = actions;
export const { updateDeployConfig } = actions;

export const addSubNodesToNodes = R.curry((nodeType, nodes, subNodeData, dispatch) => {
  try {
    const { payload: formatedSubNode } = dispatch(actions.formatSubNodeData(
      nodeType,
      {
        ...subNodeData,
      },
    ));

    const newNodes = R.addIndex(R.map)(
      (item, index) => R.merge(nodes[index], { subNode: { [camelCase(nodeType)]: item } }),
      formatedSubNode,
    );
    return dispatch(actions.addSubNodesToNodes(newNodes));
  } catch (error) {
    return dispatch(errorOperations.setError(
      'add subnodes to nodes',
      { message: 'Failed to add subnodes to nodes', error },
    ));
  }
});

export const deploy = R.curry(
  async (
    userId,
    quantity,
    paymentDetail,
    detail,
    orderType,
    dispatch,
  ) => {
    try {
      dispatch(loadingOperations.setLoading('deploy', true));
      dispatch(errorOperations.clearError('deploy'));
      const { type, payload } = await dispatch(
        orderOperations.createOrder(
          userId,
          quantity,
          paymentDetail,
          detail,
          orderType,
        ),
      );

      if (!R.includes('SUCCESS', type)) {
        throw new CustomError('Deploy failed');
      }
      dispatch(loadingOperations.setLoading('deploy', false));
      return dispatch(actions.deploy(payload));
    } catch (error) {
      dispatch(loadingOperations.setLoading('deploy', false));
      return dispatch(errorOperations.setError(
        'deploy',
        { fallbackErrorMessage: 'deploy failed', error },
      ));
    }
  },
);

export const { resetDeployConfigOneNodeType } = actions;
