// mutation
import {
  createMutation,
} from '../../../modules/apollo/apollo';

import {
  sendContactUsEmailMutation,
} from './mutation';
// apollo

export const graphqlSendContactUsEmail = createMutation(sendContactUsEmailMutation);
