import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';
import DoneAllOutlinedIcon from '@material-ui/icons/DoneAllOutlined';
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';
import isFunction from 'lodash/isFunction';
import React from 'react';

import { useCopyToClipboard } from '../../../hooks/hooks';

export const styles = (theme) => ({
  doneIcon: {
    color: theme.palette.success.main,
  },
});
const useStyles = makeStyles(styles);
export default function CopyButton({
  text,
  onClick,
  buttonProps,
  color,
  size,
  ...props
}) {
  const classes = useStyles();
  // isCopied is reset after 3 second timeout
  const [isCopied, handleCopy] = useCopyToClipboard(3000);
  const handleOnclick = (text) => {
    if (isFunction(onClick)) onClick();
    return handleCopy(text);
  };
  return (
    <IconButton onClick={() => handleOnclick(text)} size={size} {...props}>
      {
        isCopied
          ? <DoneAllOutlinedIcon className={classes.doneIcon} />
          : <FileCopyOutlinedIcon color={color} />
        }
    </IconButton>
  );
}
