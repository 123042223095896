import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import whyDidYouRender from '@welldone-software/why-did-you-render';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { CloseIconButton } from '../MaterialUiCustom/MaterialUiCustom';
import ReactTableV7 from '../Tables/ReactTableV7';

// others
import { paymentPageSubscriptionDetailColumns } from './columns';
import { paymentPageSubscriptionDetailStyles } from './styles';

const useStyles = makeStyles(paymentPageSubscriptionDetailStyles);

if (process.env.REACT_APP_ENV !== 'prod') {
  whyDidYouRender(React);
}

const PaymentPageSubscriptionDetail = (props) => {
  const {
    data,
    onCloseSubscriptionDetail,
  } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  const { lang } = useSelector((state) => state.langR.langSetting);

  const [openAmmountInfoIndex, setOpenAmmountInfoIndex] = React.useState(-1);
  const handleAmmountInfoClose = () => {
    setOpenAmmountInfoIndex(-1);
  };
  const handleAmmountInfoOpen = (index) => {
    setOpenAmmountInfoIndex(index);
  };

  const {
    metadata: {
      name,
    },
    trialEnd,
  } = data;
  const columnsToUse = paymentPageSubscriptionDetailColumns(
    t,
    lang,
    name,
    trialEnd,
    openAmmountInfoIndex,
    handleAmmountInfoOpen,
    handleAmmountInfoClose,
  );

  return (
    <Card
      className={classes.card}
    >
      {/* <Box ml={2} mt={2}>
        <Typography variant="h5">Payment detail</Typography>
      </Box>
      <Box ml={2} mb={3}>
        <Typography variant="h6">{data.nodeName}</Typography>
      </Box> */}
      <CardHeader
        title={t('Invoices')}
        action={(
          <CloseIconButton
            onClick={onCloseSubscriptionDetail}
            color="default"
          />
        )}
      />
      <CardContent>
        <Paper className={classes.table}>
          <ReactTableV7 data={data.invoices} columns={columnsToUse} />
        </Paper>
      </CardContent>
    </Card>
  );
};

PaymentPageSubscriptionDetail.defaultProps = {
};

PaymentPageSubscriptionDetail.propTypes = {
  data: PropTypes.shape({
    metadata: PropTypes.shape({
      name: PropTypes.string,
    }),
    trialEnd: PropTypes.string,
  }).isRequired,
  onCloseSubscriptionDetail: PropTypes.func.isRequired,
};

PaymentPageSubscriptionDetail.whyDidYouRender = true;

export default PaymentPageSubscriptionDetail;
