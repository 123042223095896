import {
  createReducer,
  mergeStateAndPayload,
} from '../store_utils';

import * as types from './types';

const blockchainConfigIs = {
  useTestnet: false,
};

const blockchainConfigRUpdaters = {
  [types.SET_BLOCKCHAIN_TESTNET]: mergeStateAndPayload,
};
const blockchainConfigR = createReducer(blockchainConfigIs, blockchainConfigRUpdaters);

export default blockchainConfigR;
