import { sleep } from 'jsutils';
import * as R from 'ramda';

import {
  timeToUpdateAuthToken,
  insecureOperationNames,
} from './appConstants';
import { getItem, doesItemWithKeyExistsInLocalStorage } from './browser_utils';

const { REACT_APP_JWT_GUARD } = process.env;
/**
 * Returns the remaining time after which current JWT token will expire
 * @param  {import('./typedefs').ItemKey} - keyToAuthTokenDataInLocalStorage
 * @return {number} time in seconds after which current JWT token will expire
 */
export const getRemainingAccessTokenExpiryTime = R.curry((keyToAuthTokenDataInLocalStorage) => {
  const accessTokenExpiry = R.pipe(
    getItem,
    R.view(R.lensProp('accessTokenExpiry')),
  )(keyToAuthTokenDataInLocalStorage);
  const accessTokenExpiryTime = accessTokenExpiry
    ? new Date(accessTokenExpiry).getTime()
    : new Date().getTime();
  const remainingAccessTokenExpiryTime = accessTokenExpiryTime - new Date().getTime();
  return remainingAccessTokenExpiryTime;
});

/**
 * Decide whether authentication tokens need to be updated or not
 * @param  {import('./jwt/typedefs').ItemKey}  keyToAuthTokenDataInLocalStorage
 * @return {boolean} true if token should update and false otherwise
 */
export const shouldUpdateAuthToken = R.curry((keyToAuthTokenDataInLocalStorage) => {
  if (REACT_APP_JWT_GUARD === 'OFF') {
    return false;
  }

  const remainingAccessTokenExpiryTime = getRemainingAccessTokenExpiryTime(
    keyToAuthTokenDataInLocalStorage,
  );
  if (remainingAccessTokenExpiryTime <= timeToUpdateAuthToken) {
    return true;
  }
  return false;
});

/**
 * Get auth token from browser local storage
 * @param  {import('./jwt/typedefs').ItemKey} - keyToAuthTokenDataInLocalStorage
 * @return {string} access token
 */
export const getAccessToken = R.curry(async (
  keyToAuthTokenDataInLocalStorage,
  operationName,
) => {
  if (
    R.includes(operationName, insecureOperationNames)
    // if auth token data does not exist in localstorage it indicates
    // that user is already logged out
    || !doesItemWithKeyExistsInLocalStorage(keyToAuthTokenDataInLocalStorage)
  ) {
    return '';
  }

  if (!shouldUpdateAuthToken(keyToAuthTokenDataInLocalStorage)) {
    const accessToken = R.pipe(
      getItem,
      R.view(R.lensProp('accessToken')),
    )(keyToAuthTokenDataInLocalStorage);
    return accessToken;
  }

  // if auth token need to be updated wait until auth token is updated
  while (
    shouldUpdateAuthToken(keyToAuthTokenDataInLocalStorage)
      // if auth token data does not exist in localstorage it indicates
      // that user is already logged out
      && doesItemWithKeyExistsInLocalStorage(keyToAuthTokenDataInLocalStorage)
  ) {
    // eslint-disable-next-line no-await-in-loop
    await sleep(1000);
  }

  const accessToken = R.pipe(
    getItem,
    R.view(R.lensProp('accessToken')),
  )(keyToAuthTokenDataInLocalStorage);
  return accessToken;
});
