export const walletInfoStyles = (theme) => ({

  iconGrid: {
    ...theme.centerComponent,
  },
});

export const walletTransferBoardStyles = (theme) => ({
  actionGrid: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
});

export const walletTransferBoardFeeStyles = (theme) => ({
  feeOuterBox: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: theme.spacing(2.25, 1.75), // The values are from MUI input padding
  },

});

export const walletTransferBoardAmountStyles = (theme) => ({
  fiatAmount: {
    display: 'flex',
    justifyContent: 'space-between',
  },
});

export const walletFeaturesStyles = (theme) => ({
  tabHeaders: {
    margin: theme.spacing(1, 0),
  },
});

export const walletTransferTransactionUrlStyles = (theme) => ({
  address: {
    wordWrap: 'break-word',
  },
});

export const walletAccessStyles = (theme) => ({
  titleSection: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: theme.spacing(3),
  },
});

export const walletSuccessStyles = (theme) => ({
  icon: {
    color: theme.palette.success.main,
    fontSize: '5rem',
  },
  transferedDesciption: {
    fontWeight: theme.typography.h3.fontWeight,
  },
});
