export const styles = () => ({
  chainIcon: {
    width: 80,
    height: 80,
  },
});

export const chainNameStyles = () => ({
  title: {
    fontSize: '1rem',
  },
});
