import Grid from '@material-ui/core/Grid';
import startCase from 'lodash/fp/startCase';
import toLower from 'lodash/fp/toLower';
import React from 'react';
import { useDispatch } from 'react-redux';
import {
  useHistory,
} from 'react-router-dom';

import { contactFormOperations } from '../../store/contact_form/index';

import NodeOwnerContentItem from './NodeOwnerContentItem';

import { nodeOwnerRoute } from 'routes';

const imagesMining = [
  {
    original: '/img/devices/phala_mining_device.png',
    thumbnail: '/img/devices/phala_mining_device.png',
  },
  {
    original: '/img/devices/phala_mining_device.png',
    thumbnail: '/img/devices/phala_mining_device.png',
  },
  {
    original: '/img/devices/phala_mining_device.png',
    thumbnail: '/img/devices/phala_mining_device.png',
  },
  {
    original: '/img/devices/phala_mining_device.png',
    thumbnail: '/img/devices/phala_mining_device.png',
  },
];

const imagesNeunode = [
  {
    original: '/img/devices/neunode_device.png',
    thumbnail: '/img/devices/neunode_device.png',
  },
  {
    original: '/img/devices/neunode_device.png',
    thumbnail: '/img/devices/neunode_device.png',
  },
  {
    original: '/img/devices/neunode_device.png',
    thumbnail: '/img/devices/neunode_device.png',
  },
  {
    original: '/img/devices/neunode_device.png',
    thumbnail: '/img/devices/neunode_device.png',
  },
];

const { REACT_APP_ENABLE_NODE_ONWER_PURCHASE } = process.env;
const enableNodeOwnerPurchase = REACT_APP_ENABLE_NODE_ONWER_PURCHASE === 'ON';

const NodeOwnerContent = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const openContactForm = (metadata) => {
    dispatch(contactFormOperations.openContactForm(metadata));
  };
  const onRedirectToNodeOwnerOrder = (service) => (!enableNodeOwnerPurchase
    ? openContactForm({ subject: startCase(toLower(service)) })
    : history.push(`${nodeOwnerRoute}/${service}`));

  return (
    <Grid container justifyContent="center" direction="column" spacing={4}>
      <Grid item xs={12}>
        <NodeOwnerContentItem
          title="Phala Network マイニングマシン"
          description={`> 日本で唯一！Phala Networkの提携販売事業者！
          > マイニングマシンの稼働場所の設置、運用・保守は全てお任せ！
          > 暗号資産を保有していなくても、どなたでも参加可能！
          > マイニング報酬データはアプリでいつでも確認！
          > Polkadotメインネット移行前のプレマイニングから参加可能！`}
          price={2580000}
          images={imagesMining}
          // intentionally new line to make 残り\n7台
          promotionText="Sold out"
          onButtonClick={() => onRedirectToNodeOwnerOrder('phala-mining')}
          disabled={!enableNodeOwnerPurchase}
          btnText={enableNodeOwnerPurchase ? 'purchase' : 'お問い合わせ'}
        />
      </Grid>
      <Grid item xs={12}>
        <NodeOwnerContentItem
          title="NeuNode"
          description={`> ノードマシンを購入後、レンタルするだけ。面倒な手続きは不要！
          > ノードの運用・メンテナンスも不要。アプリから稼働状況を確認できるので安心！
          > どなたでも暗号資産を保有せずに参加可能！ > 購入代金は、100%即時償却可能！
          > 想定年利110%！レンタル収益は日本円/BTC/ETHで受け取り可能！`}
          price={99000}
          images={imagesNeunode}
          onButtonClick={() => onRedirectToNodeOwnerOrder('neunode')}
          disabled={!enableNodeOwnerPurchase}
          btnText={enableNodeOwnerPurchase ? 'purchase' : 'お問い合わせ'}

        />
      </Grid>
    </Grid>
  );
};

export default NodeOwnerContent;
