import isEmpty from 'lodash/isEmpty';

import { regexExp } from '../../modules/appConstants';
import { valueValidate } from '../../modules/validates';

const isOnlyAlphabetNumberic = (value) => !/[^\w]/g.test(value);

const validateProfile = (values) => {
  const { email, password, userName } = values;
  const { emailRegex } = regexExp;
  let errors = {};
  errors = { ...errors, ...valueValidate(email, 'email', values, emailRegex) };
  if (!password) {
    errors.password = 'Required';
  }
  if (!userName) {
    errors.userName = 'Required';
  }
  if (!isOnlyAlphabetNumberic(userName)) {
    errors.userName = 'Invalid user name';
  }
  if (userName && userName.length > 20) {
    errors.userName = 'Maximum character is 20';
  }
  return errors;
};

const validateChangePassword = (values) => {
  const {
    oldPassword, newPassword,
  } = values;
  const errors = {};
  const passwordsEmpty = isEmpty(oldPassword) || isEmpty(newPassword);
  const passwordsTheSame = oldPassword === newPassword;
  if (!passwordsEmpty && passwordsTheSame) {
    errors.newPassword = 'Passwords cannot be the same';
  }
  if (!oldPassword) {
    errors.oldPassword = 'Required';
  }
  if (!newPassword) {
    errors.newPassword = 'Required';
  }

  return errors;
};

export {
  validateProfile,
  validateChangePassword,
};
