import * as R from 'ramda';
import { createLogger } from 'redux-logger';
import thunk from 'redux-thunk';

const logger = createLogger();
const baseMiddleware = [thunk];
const { REACT_APP_ENABLE_REDUX_LOGGER } = process.env;

const middleware = REACT_APP_ENABLE_REDUX_LOGGER === 'ON'
  ? R.concat(baseMiddleware, [logger])
  : baseMiddleware;

export {
  middleware,
};
