export const styles = (theme) => ({
  card: {
    height: '100%',
  },
  cardHeader: {
    padding: theme.spacing(2, 2, 0, 2),
  },
  cardTitle: {
    display: 'flex',
    justifyContent: 'center',
    marginLeft: 17,
  },
  cardHeaderHover: {
    marginLeft: 'auto',
    marginRight: 'auto',
    width: '10%',
    height: '10%',
    transition: theme.transitions.create(['width', 'height'], {
      easing: theme.transitions.easing.sharp,
      duration: 500,
    }),
  },
  cardContent: {
    padding: theme.spacing(2, 2, 0, 2),
  },
  dividerHorizon: {
    marginLeft: theme.spacing(1.5),
    marginRight: theme.spacing(1.5),
    marginTop: theme.spacing(8),
  },
  dividerVertical: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
  cardAction: {
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  cardActionHover: {
    ...theme.centerComponent,
    padding: theme.spacing(2),
  },
  deployItemName: {
    marginLeft: theme.spacing(1),
  },
  deployItemButtons: {
    marginRight: theme.spacing(1),
  },
});

export const marketCardActionStyles = (theme) => ({
  deployButton: {
    color: '#515A6A',
    fontWeight: 500,
    width: '100%',
    minWidth: 150,
    maxWidth: 200,
    borderColor: theme.borderColor.default,
  },
  deployButtonDisabled: {
    pointerEvents: 'none',
  },
});

export const marketCardContentStyles = () => ({
  chainIcon: {
    width: 65,
    height: 65,
  },
});
