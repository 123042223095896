import * as R from 'ramda';

export const historyPush = R.curry(
  (history, options) => {
    const { pathname, queryLng } = options;
    const optionsToUse = {
      ...R.omit(['queryLng'], options),
      pathname,
      search: queryLng ? `?lng=${queryLng}` : null,
    };
    return history.push(optionsToUse);
  },
);
