import Eth2Deploy from './Eth2Deploy/Eth2Deploy';
import NemDeploy from './NemDeploy/NemDeploy';
import UnUniFiDeploy from './UnUniFiDeploy/UnUniFiDeploy';

const nodeBasedOnTypes = {
  eth2: Eth2Deploy,
  ununifi: UnUniFiDeploy,
  nem: NemDeploy,
};
export default nodeBasedOnTypes;
