import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import ArrowDropDownOutlinedIcon from '@material-ui/icons/ArrowDropDownOutlined';
import ArrowDropUpOutlinedIcon from '@material-ui/icons/ArrowDropUpOutlined';
import HelpOutlineOutlinedIcon from '@material-ui/icons/HelpOutlineOutlined';
import toNumber from 'lodash/toNumber';
import moment from 'moment';
import * as R from 'ramda';
import React from 'react';

import LocalDate from '../../LocalDate/LocalDate';
import CustomTooltip from '../../Tooltip/CustomTooltip';
import { convertToCryptoNumber } from '../../utils/utils';

export const eth2ValidatorDevicesColumns = (
  t,
) => [
  {
    Header: t('Device ID'),
    accessor: 'deviceId',
    Cell: (data) => {
      const { deviceId } = data.row.original;
      const deviceIdToUse = R.pipe(
        R.split('-'),
        R.last,
      )(deviceId);
      return (
        <Grid container direction="row" alignItems="center">
          {deviceIdToUse}
        </Grid>
      );
    },
  },
  {
    Header: t('Started Date'),
    accessor: 'dateCreated',
    Cell: (data) => {
      const { dateActive } = data.row.original;
      return (
        <Grid container direction="row" alignItems="center">
          {dateActive ? moment(new Date(dateActive)).format('MMMM D, YYYY') : '-'}
        </Grid>
      );
    },
  },
  {
    Header: t('Contract End Date'),
    accessor: 'contractEndDate',
    Cell: (data) => {
      const contractEndDate = R.view(R.lensPath(['contract', 'contractEndDate']), data.row.original);
      return (
        <Grid container direction="row" alignItems="center">
          {contractEndDate ? moment(new Date(contractEndDate)).format('MMMM D, YYYY') : '-'}
        </Grid>
      );
    },
  },
  {
    Header: t('Node Status'),
    accessor: 'deviceStatus',
    Cell: (data) => {
      const { deviceStatus } = data.row.original;
      return (
        <Grid container direction="row" alignItems="center">
          {deviceStatus}
        </Grid>
      );
    },
  },
];

export const eth2ValidatorEarningHistoryColumns = (
  t,
) => [
  {
    Header: t('Date'),
    id: 'endTime',
    Cell: (data) => {
      const { endTime } = data.row.original;
      return (
        <Grid container direction="row" alignItems="center">
          <LocalDate
            date={endTime}
          />
        </Grid>
      );
    },
  },
  {
    Header: t('Earning'),
    accessor: 'totalCoin',
    Cell: (data) => {
      const { totalCoin } = data.row.original;
      const prefix = totalCoin >= 0 ? '+' : '';
      return (
        <Grid container direction="row" alignItems="center">
          <Typography style={{ color: '#607BEE' }}>
            {`${prefix}${totalCoin} ETH`}
          </Typography>
        </Grid>
      );
    },
  },
  {
    Header: t('Fiat Equivalent'),
    accessor: 'total',
    Cell: (data) => {
      const { total } = data.row.original;
      return (
        <Grid container direction="row" alignItems="center">
          {toNumber(total).toLocaleString('en-US', {
            style: 'currency',
            currency: 'JPY',
          })}
        </Grid>
      );
    },
  },
  {
    Header: t('Exchange Rate'),
    accessor: 'currencyPrice',
    Cell: (data) => {
      const { currencyPrice } = data.row.original;
      return (
        <Grid container direction="row" alignItems="center">
          {`(1ETH = ${toNumber(currencyPrice).toLocaleString('en-US', {
            style: 'currency',
            currency: 'JPY',
          })})`}
        </Grid>
      );
    },
  },
];

export const eth2ValidatorAllValidatorColumns = (
  t,
) => [
  {
    Header: t('ID'),
    accessor: 'index',
    Cell: (data) => {
      const { index, pubKey } = data.row.original;
      const link = `https://beaconcha.in/validator/${pubKey}`;
      return (
        <Grid container direction="row" alignItems="center">
          <Link href={link} target="_blank">
            <Typography noWrap>
              {index ? `#${index}` : '-'}
            </Typography>
          </Link>
        </Grid>
      );
    },
  },
  {
    Header:
  <Box display="flex" alignItems="center">
    <Box mr={1}>
      {t('APR')}
    </Box>
    <Box>
      <CustomTooltip
        title={t('Estimated average Annual Percentage Rate of your nodes since active')}
        content={(
          <HelpOutlineOutlinedIcon
            color="disabled"
            style={{ fontSize: 20 }}
          />
)}
      />
    </Box>
  </Box>,
    accessor: 'apr',
    Cell: (data) => {
      const { apr } = data.row.original;
      return (
        <Grid container direction="row" alignItems="center">
          {`${apr || 0}%`}
        </Grid>
      );
    },
  },
  {
    Header: t('Staked'),
    accessor: 'staked',
    Cell: (data) => {
      const { balance } = data.row.original;
      return (
        <Grid container direction="row" alignItems="center">
          {balance > 0 ? '32 ETH' : '-'}
        </Grid>
      );
    },
  },
  {
    Header: t('Earning'),
    accessor: 'earning',
    Cell: (data) => {
      const { earning } = data.row.original;
      return (
        <Grid container direction="row" alignItems="center">
          {earning ? `${convertToCryptoNumber(earning)} ETH` : '-'}
        </Grid>
      );
    },
  },
  {
    Header: t('Balance'),
    accessor: 'balance',
    Cell: (data) => {
      const { balance } = data.row.original;
      return (
        <Grid container direction="row" alignItems="center">
          {balance ? `${convertToCryptoNumber(balance)} ETH` : '-'}
        </Grid>
      );
    },
  },
  {
    Header: t('Status'),
    accessor: 'status',
    Cell: (data) => {
      const { status } = data.row.original;
      return (
        <Grid container direction="row" alignItems="center">
          {status || '-'}
        </Grid>
      );
    },
  },
  {
    id: 'expander',
    sortable: false,
    Cell: (data) => {
      const { row } = data;
      return (
        <Grid
          container
          direction="row"
          alignItems="center"
          alignContent="center"
          {...row.getToggleRowExpandedProps()}
        >
          <Box display="flex">
            <Box mt={0.5}>
              <Typography color="primary">
                {t('Details')}
              </Typography>
            </Box>
            <Box mt={0.5}>
              {!row.isExpanded
                ? <ArrowDropDownOutlinedIcon color="primary" />
                : <ArrowDropUpOutlinedIcon color="primary" />}
            </Box>
          </Box>
        </Grid>
      );
    },
  },
];
