import Promise from 'bluebird';
import * as R from 'ramda';
import zencashjs from 'zencashjs';

const { config, address: addressFn, transaction } = zencashjs;

export const configureWifHash = R.curry(
  (config, useTestnet) => {
    const wifHash = useTestnet
      ? config.testnet.wif
      : config.mainnet.wif;
    const pubKeyHash = useTestnet
      ? config.testnet.pubKeyHash
      : config.mainnet.pubKeyHash;
    return {
      wifHash,
      pubKeyHash,
    };
  },
);
export const createConfigueMultisigScriptHash = R.curry(
  (config, useTestnet) => {
    const multisigScriptHash = useTestnet
      ? config.testnet.scriptHash
      : config.mainnet.scriptHash;
    return multisigScriptHash;
  },
);
export const configueMultisigScriptHash = createConfigueMultisigScriptHash(config);
/**
 * Generate a wallet from a keyPair
 * @param  {import('./typedefs').KeyPair}   keyPair
 * @param  {import('./typedefs').UseTestnet} UseTestnet
 * @return {import('./typedefs').Wallet}
 */
export const createGenerateWallet = R.curry(
  (config, useTestnet, index, keyPair) => {
    const { publicKey } = keyPair;
    const { pubKeyHash } = configureWifHash(config, useTestnet);
    const address = addressFn.pubKeyToAddr(publicKey, pubKeyHash);
    return {
      address,
      ...keyPair,
    };
  },
);
export const generateWallet = createGenerateWallet(config);
/**
 * Generate wallets from keyPairs
 * @param  {import('./typedefs').UseTestnet} UseTestnet
 * @param  {import('./typedefs').KeyPairsResult}   keyPairsResult
 * @return {import('./typedefs').WalletResult}
 */
export const generateWallets = R.curry(
  async (useTestnet, keyPairs) => {
    const wallets = await Promise.map(
      keyPairs,
      (keyPair, index) => generateWallet(useTestnet, index, keyPair),
    );
    return wallets;
  },
);
export const generateRedeemScript = addressFn.mkMultiSigRedeemScript;
export const multiSigRedeemScriptToAddress = addressFn.multiSigRSToAddress;

export const {
  createRawTx,
  signTx,
  multisigSignTx,
  serializeTx,
  deserializeTx,
  applyMultiSignatures,
} = transaction;
