import Box from '@material-ui/core/Box';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';
import LaunchOutlinedIcon from '@material-ui/icons/LaunchOutlined';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import { truncateWalletAddress } from '../../../utils';
import MyNodeInfoDescriptionItem from '../../MyNodeInfoDescriptionItem';
import { stylesDescription } from '../styles';

const useStyles = makeStyles(stylesDescription);

const MyNodeInfoKeyEth20 = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const [openTooltip, setOpenTooltip] = React.useState(false);
  const handleTooltipClose = () => {
    setOpenTooltip(false);
  };
  const handleTooltipOpen = () => {
    setOpenTooltip(true);
  };

  const { nodeKey } = props;

  const validatorKeyToDisplay = nodeKey ? truncateWalletAddress(15, -5, nodeKey) : '';

  const copyToClipboard = () => {
    const tempInput = document.createElement('input');
    tempInput.value = nodeKey;
    document.body.appendChild(tempInput);
    tempInput.select();
    document.execCommand('copy');
    document.body.removeChild(tempInput);

    toast.success(t('Copied to clipboard'), {
      position: 'top-right',
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  const openInNewTab = (url) => {
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
    if (newWindow) newWindow.opener = null;
  };

  return (
    <Box className={classNames(classes.descriptionContent)}>
      <MyNodeInfoDescriptionItem
        title={t('Validator Key')}
        content={(
          <Grid container alignItems="center" spacing={2}>
            <Grid item>
              <ClickAwayListener onClickAway={handleTooltipClose}>
                <Tooltip
                  open={openTooltip}
                  title={nodeKey}
                >
                  <Typography
                    variant="body1"
                    onClick={handleTooltipOpen}
                    onMouseOver={handleTooltipOpen}
                    onMouseOut={handleTooltipClose}
                  >
                    {validatorKeyToDisplay}
                  </Typography>
                </Tooltip>
              </ClickAwayListener>

            </Grid>
            <Grid item>
              <IconButton onClick={copyToClipboard}>
                <FileCopyOutlinedIcon />
              </IconButton>
            </Grid>
            <Grid item>
              <IconButton onClick={() => openInNewTab(`https://beaconcha.in/validator/${nodeKey}`)}>
                <LaunchOutlinedIcon />
              </IconButton>
            </Grid>
          </Grid>
      )}
      />
    </Box>
  );
};

MyNodeInfoKeyEth20.propTypes = {
  nodeKey: PropTypes.string.isRequired,
};

export default MyNodeInfoKeyEth20;
