export const promotionCodeErrorMessages = [
  'Invalid promotion code',
  'This promotion code is not specified for use with this customer',
  'This promotion code has expired',
];

export const createOrderErrorMessage = 'Create Order Error';

export const messagesToShow = {
  'Invalid promotion code': 'Your code could not be redeemed',
  'This promotion code is not specified for use with this customer': 'Your code could not be redeemed',
  'This promotion code has expired': 'This code has expired',
};
