import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import getSymbolFromCurrency from 'currency-symbol-map';
import upperCase from 'lodash/upperCase';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

const MarketCardPrice = (props) => {
  const {
    currency,
    price,
    paymentPeriod,
    status,
    hidePrice,
  } = props;

  const { t } = useTranslation();
  const currencySymbol = getSymbolFromCurrency(upperCase(currency));

  // i18next-extract-mark-context-next-line ["month"]
  const paymentPeriodToDisplay = t('payment period card', { context: paymentPeriod.toString() });

  const shouldShowPrice = status !== 'DISABLED' && !hidePrice;
  // todo check by status
  const displayPrice = shouldShowPrice
    ? `${currencySymbol}${price}${paymentPeriodToDisplay}`
    : t('$--/month', { escapeValue: false });
  return (
    <Box
      display="flex"
      flexDirection="row"
      justifyContent="center"
      alignItems="center"
    >
      { // temposry disable
        /* <Box mr={0.4}>
              <Typography variant="subtitle2" color="textSecondary">From</Typography>
            </Box> */
            }
      <Box>
        <Typography variant="subtitle2">{displayPrice}</Typography>
      </Box>
    </Box>
  );
};

MarketCardPrice.propTypes = {
  /**
   * currency
   */
  currency: PropTypes.string.isRequired,
  /**
   * protocol price
   */
  price: PropTypes.number.isRequired,
  /**
   * payment period for example: week, month, year...
   */
  paymentPeriod: PropTypes.string.isRequired,
  /**
   * Hide price and show "--" instead
   */
  hidePrice: PropTypes.bool,
  status: PropTypes.string.isRequired,
};

MarketCardPrice.defaultProps = {
  hidePrice: false,
};

export default MarketCardPrice;
