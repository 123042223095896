import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React from 'react';

const styles = () => ({
  spinner: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
});

const Loading = (props) => {
  const { classes } = props;
  return (
    <Box className={classes.spinner}>
      <CircularProgress />
    </Box>
  );
};

Loading.defaultProps = {
  classes: {},
};

Loading.propTypes = {
  classes: PropTypes.object,
};

export default withStyles(styles)(Loading);
