import Box from '@material-ui/core/Box';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React from 'react';

import { getExplorerUrl } from './constants';
import { walletTransferTransactionUrlStyles } from './styles';

import CopyButton from 'components/MaterialUiCustom/Button/CopyButton';

const useStyles = makeStyles(walletTransferTransactionUrlStyles);

const WalletExplorerUrl = ({
  chain,
  subChain,
  address,
  t,
}) => {
  const classes = useStyles();

  const explorerUrl = getExplorerUrl(chain, subChain);

  const url = `${explorerUrl}/account/${address}`;

  return (
    <Box
      display="flex"
      alignItems="center"
    >
      <Box>
        <Link href={url} target="_blank">
          <Typography variant="body2" className={classes.address}>
            {address}
          </Typography>
        </Link>
      </Box>
      <Box ml={1}>
        <CopyButton
          text={address}
          color="primary"
        />
      </Box>
    </Box>
  );
};

WalletExplorerUrl.propTypes = {
  t: PropTypes.func.isRequired,
  chain: PropTypes.string.isRequired,
  subChain: PropTypes.string.isRequired,
  address: PropTypes.string.isRequired,
};

export default WalletExplorerUrl;
