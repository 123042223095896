import gql from 'graphql-tag';

export const uploadFileToS3Mutation = gql`
mutation($nodeType: NodeType!, $file: Upload!){
  uploadFileToS3(
    nodeType: $nodeType, 
    file: $file,
  ){
    keystoreFilePath
  }
}
`;
