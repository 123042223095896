import gql from 'graphql-tag';

export const updateAuthTokenMutation = gql`
  mutation updateAuthToken($userId: String!, $email: String!, $refreshToken: String!, $idToken: String!)
  {
    updateAuthToken(
      updateAuthTokenInput: { 
        userId: $userId
        email: $email
        refreshToken: $refreshToken
        idToken: $idToken
      }
    ) {
      authTokenData {
        accessToken
        refreshToken
        accessTokenExpiry
        refreshTokenExpiry
        idToken
      }
    }
  }
`;
