import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import whyDidYouRender from '@welldone-software/why-did-you-render';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { reduxForm, reset } from 'redux-form';

import { userOperations } from '../../store/user/index';
import ErrorMessage from '../ErrorMessage/ErrorMessage';
import { RoundedButton } from '../MaterialUiCustom/MaterialUiCustom';
import VerifyPasswordDialog from '../VerifyPasswordDialog/VerifyPasswordDialog';

import FieldGridItem from './FieldGridItem';
import UserPasswordChange from './UserPasswordChange';
import { accountAsyncValidate } from './asyncValidate';
import { styles } from './styles';
import { validateProfile } from './validate';

if (process.env.REACT_APP_ENV !== 'prod') {
  whyDidYouRender(React);
}
const useStyles = makeStyles(styles);
const formName = 'userProfile';

const FormedFieldGridItem = reduxForm({
  form: formName,
  validate: validateProfile,
  asyncValidate: accountAsyncValidate,
  asyncBlurFields: ['email', 'userName'],
})(FieldGridItem);

/**
 * Profile section
 *
 * @component
 * @example
 * const initialValues = {...}
 * const userId = 1
 * return (
 *   <UserProfileSection initialValues={initialValues} userId={userId} />
 * )
 */
const UserProfileSection = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();
  const { handleSubmit, initialValues } = props;
  const {
    submitting, pristine, invalid, isMainAccount, requesterEmail,
  } = props;
  const { userId } = initialValues;
  const [email, setEmail] = useState(initialValues.email);
  const [openChangePassword, setOpenChangePassword] = useState(false);
  const [openVerifyPassword, setOpenVerifyPassword] = useState(false);
  const [editMode, SetEditMode] = useState(false);

  const [errorMessage, setErrorMessage] = useState('');

  const onChangePasswordSuccess = () => {
    toast.success(t('Password changed'), {
      position: 'top-right',
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  const toggleEditMode = () => SetEditMode(!editMode);

  /**
   * Dispatch the update profile operation
   * @param      {object}  formValues  The form values that contain profile information
   */
  const handleChangeProfile = async (formValues) => {
    let error = '';

    if (formValues.email !== email) {
      const payload = await dispatch(userOperations.changeUserEmail(
        userId,
        formValues.email,
      ));
      if (payload.type === 'UPDATE_EMAIL_SUCCESS') {
        setEmail(formValues.email);
        toast.success(t('Email changed'), {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else {
        error += t('Update email failed');
      }
    }

    setErrorMessage(error);
  };

  /**
   * Reset form values to init values and toggle edit mode
   */
  const cancelEdit = () => {
    dispatch(reset(formName));
    toggleEditMode();
    setErrorMessage('');
  };

  /**
   * Close change password
   */
  const onCloseChangePassword = () => {
    setOpenChangePassword(false);
  };

  /**
   * Open change password
   */
  const showChangePassword = () => {
    setOpenChangePassword(true);
  };

  /**
   * Close change password
   */
  const onCloseVerifyPassword = () => {
    setOpenVerifyPassword(false);
  };

  /**
   * Open change password
   */
  const showVerifyPassword = () => {
    setOpenVerifyPassword(true);
  };

  return (
    <>
      <Card elevation={2} className={classNames(classes.card)}>
        <form onSubmit={handleSubmit(handleChangeProfile)}>
          <CardHeader
            title={t('Profile')}
            classes={{
              title: classes.cardTitle,
            }}
            className={classes.cardHeader}
            action={(
              <Box
                display="flex"
                alignItems="center"
              >
                {!editMode && (
                <RoundedButton
                  id="editBtn"
                  variant="contained"
                  className={classNames(classes.button)}
                  onClick={toggleEditMode}
                  color="primary"
                >
                  {t('Edit')}
                </RoundedButton>
                )}
                {editMode && (
                <>
                  <RoundedButton
                    id="cancelBtn"
                    color="default"
                    variant="contained"
                    className={classNames(classes.button)}
                    onClick={cancelEdit}
                    disabled={submitting}
                  >
                    {t('Cancel')}
                  </RoundedButton>
                  <RoundedButton
                    id="saveBtn"
                    variant="contained"
                    color="primary"
                    className={classNames(classes.button)}
                    disabled={submitting || pristine || invalid}
                    onClick={showVerifyPassword}
                  >
                    {t('Save')}
                  </RoundedButton>
                </>
                )}
              </Box>
           )}
          />
          <Divider />
          <CardContent className={classes.cardContent}>
            <Grid container direction="column" justifyContent="center" alignItems="center" spacing={2}>
              <ErrorMessage
                errorMessage={errorMessage}
                id="error"
                color="error"
                className={classNames(classes.error)}
              />
              {/* <FormedFieldGridItem name="User Id" value={userId.toString()} /> */}
              {/* <Grid container item xs className={classes.formGridItem}>
                <FormedFieldGridItem
                  name="Username"
                  value={userName}
                  editMode={editMode}
                  inputFieldName="userName"
                  inputFieldLabel="User Name"
                  initialValues={{ userId, userName }}
                />
              </Grid> */}
              <Grid container item xs={12} className={classes.formGridItem}>
                <FormedFieldGridItem
                  name={t('Email')}
                  value={email}
                  editMode={editMode}
                  inputFieldName="email"
                  inputFieldLabel={t('Email')}
                  initialValues={{ userId, email }}
                />
              </Grid>
              <Grid container item xs={12} className={classes.formGridItem}>
                {isMainAccount && editMode && (
                <>
                  <Grid item xs={4}>
                    <Typography className={classNames(classes.fieldNameButton)}>{`${t('Password')}:`}</Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <RoundedButton
                      variant="contained"
                      color="primary"
                      onClick={showChangePassword}
                      noMargin
                    >
                      {t('Change password')}

                    </RoundedButton>
                  </Grid>
                </>
                )}
                {isMainAccount && !editMode && (
                <>
                  <Grid item xs={4}>
                    <Typography className={classNames(classes.cardTitle)}>{`${t('Password')}:`}</Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <Typography>***********</Typography>
                  </Grid>
                </>
                )}
              </Grid>
              {/* <FormedFieldGridItem name="Role" value={role} /> */}
            </Grid>
          </CardContent>
        </form>

        {openChangePassword && (
        <UserPasswordChange
          open={openChangePassword}
          onClose={onCloseChangePassword}
          userId={userId}
          email={email}
          onChangePasswordSuccess={onChangePasswordSuccess}
        />
        )}

        {isMainAccount && openVerifyPassword && (
        <VerifyPasswordDialog
          open={openVerifyPassword}
          onClose={onCloseVerifyPassword}
          onSuccessCallback={handleSubmit(handleChangeProfile)}
          email={email}
          parrentFormName={formName}
        />
        )}
        {!isMainAccount && openVerifyPassword && (
        <VerifyPasswordDialog
          open={openVerifyPassword}
          onClose={onCloseVerifyPassword}
          // onSuccessCallback={onChangeEmailSuccess}
          email={requesterEmail}
          parrentFormName={formName}
        />
        )}
      </Card>
    </>
  );
};

UserProfileSection.defaultProps = {
  isMainAccount: true,
  requesterEmail: '',
};

UserProfileSection.propTypes = {
  /**
   * Submit function
   */
  handleSubmit: PropTypes.func.isRequired,
  /**
   * init values
   * TODO file proptype for object
   */
  initialValues: PropTypes.object.isRequired,
  /**
   * Is redux form submitting
   */
  submitting: PropTypes.bool.isRequired,
  /**
   * Redux form props
   */
  pristine: PropTypes.bool.isRequired,
  /**
   * Redux form props
   */
  invalid: PropTypes.bool.isRequired,
  /**
   * Trigger main account mode
   */
  isMainAccount: PropTypes.bool,
  /**
   * Requester email user for sub-user list
   */
  requesterEmail: PropTypes.string,
};

UserProfileSection.whyDidYouRender = true;

export default reduxForm({
  form: formName,
  enableReinitialize: true,
})(UserProfileSection);
