import Grid from '@material-ui/core/Grid';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React from 'react';

import GlobalFilter from './ReactTableV7GlobalFilter';

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  title: {
    flex: '1 1 100%',
  },
  searchBarGrid: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
}));

const TableToolbar = (props) => {
  const classes = useToolbarStyles();
  const {
    title,
    preGlobalFilteredRows,
    setGlobalFilter,
    globalFilter,
  } = props;
  return (
    <Toolbar
      className={classes.root}
    >
      <Grid container spacing={1}>
        <Grid item xs={12} sm={6}>
          <Typography className={classes.title} variant="h6" id="tableTitle">
            {title}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6} className={classes.searchBarGrid}>
          <GlobalFilter
            preGlobalFilteredRows={preGlobalFilteredRows}
            globalFilter={globalFilter}
            setGlobalFilter={setGlobalFilter}
          />
        </Grid>
      </Grid>
    </Toolbar>
  );
};

TableToolbar.defaultProps = {
  numSelected: 0,
  addUserHandler: () => {},
  deleteUserHandler: () => {},
  globalFilter: '',
};

TableToolbar.propTypes = {
  title: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
  ]).isRequired,
  numSelected: PropTypes.number,
  addUserHandler: PropTypes.func,
  deleteUserHandler: PropTypes.func,
  setGlobalFilter: PropTypes.func.isRequired,
  preGlobalFilteredRows: PropTypes.array.isRequired,
  globalFilter: PropTypes.string,
};

export default TableToolbar;
