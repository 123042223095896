import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import whyDidYouRender from '@welldone-software/why-did-you-render';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { reduxForm, reset } from 'redux-form';

import {
  useAddFunctionToRef, useGetQuery, useSmartSetState,
} from '../../hooks/hooks';
import { minLength8 } from '../../modules/validates';
import { signupSuccessRoute, dashboardRoute, signinRoute } from '../../routes';
import { errorServices, errorOperations } from '../../store/error/index';
import { routerServices } from '../../store/router/index';
import { userOperations } from '../../store/user/index';
import {
  SIGNUP_SUCCESS,
} from '../../store/user/types';
import EmailField from '../EmailField/EmailField';
import ErrorMessage from '../ErrorMessage/ErrorMessage';
import { RoundedButton } from '../MaterialUiCustom/MaterialUiCustom';
import PasswordField from '../PasswordField/PasswordField';
import ServiceTerms from '../ServiceTerms/ServiceTerms';
import SocialIcons from '../SocialIcons/SocialIcons';

import SignupAgreements from './SignupAgreements';
import { accountAsyncValidate } from './asyncValidate';
import { singupStyles } from './styles';
// others
import { validateAccount } from './validate';
// store
// hook
// routes

if (process.env.REACT_APP_ENV !== 'prod') {
  whyDidYouRender(React);
}
const useStyles = makeStyles(singupStyles);
const { extractErrorMessage } = errorServices;
const formName = 'signup';

/**
 * Signup account form
 *
 * @component
 * @example
 * const handleNextStep = Function
 * return (
 *   <SignupForm OnClickNext={handleNextStep} />
 * )
 */
const SignupForm = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();

  const history = useHistory();
  const queryLng = useGetQuery('lng');
  const { handleSubmit } = props;
  const { submitting, pristine, invalid } = props;

  const [showPassword, setShowPassword] = useState(false);
  const [signupSuccessed, setSignupSuccessed] = useState(false);
  const [agreements, setAgreements] = useSmartSetState({
    termsAndService: false,
    newsletter: true,
  });
  const {
    termsAndService,
    newsletter,
  } = agreements;
  const authentication = useSelector((state) => state.userR.authentication);
  const { isAuthenticated } = authentication;
  const errorR = useSelector((state) => state.errorR);
  const { signupError } = errorR || {};
  const message = extractErrorMessage(signupError);
  const [errorMessage, setErrorMessage] = useState(message);
  const { lang } = useSelector((state) => state.langR.langSetting);

  const onDismount = useAddFunctionToRef(() => {
    dispatch(reset(formName));
    dispatch(errorOperations.clearError('signup'));
    dispatch(errorOperations.clearError('validateEmail'));
  });

  useEffect(() => {
    setErrorMessage(message);
  }, [message]);

  useEffect(() => onDismount, [onDismount]);
  const handleClickSignup = R.curry(async (lang, newsletter, formValues) => {
    const signupData = {
      ...formValues,
      lang,
      adminKey: 'N/A',
      settings: {
        emailSubscriptions: {
          newsletter,
        },
      },
    };
    const signupResponse = await dispatch(userOperations.signup(signupData));
    if (signupResponse.type === SIGNUP_SUCCESS) {
      setSignupSuccessed(true);
    }
    return signupResponse;
  });

  const onPolicyCheckBoxClick = R.curry((key, event) => {
    setAgreements((state) => ({
      ...state,
      [key]: event.target.checked,
    }));
  });

  const handleClickShowPassword = () => setShowPassword(!showPassword);

  /**
   * REDIRECT
   */
  useEffect(() => {
    if (signupSuccessed) {
      return routerServices.historyPush(
        history,
        {
          pathname: signupSuccessRoute,
          queryLng,
        },
      );
    }
  }, [signupSuccessed, queryLng, history]);

  useEffect(() => {
    if (isAuthenticated) {
      return routerServices.historyPush(
        history,
        {
          pathname: dashboardRoute,
          queryLng,
        },
      );
    }
  }, [isAuthenticated, queryLng, history]);

  const crateHandleBtnClick = R.curry((queryLng, pathname) => {
    routerServices.historyPush(
      history,
      {
        pathname,
        queryLng,
      },
    );
  });
  const handleBtnClick = crateHandleBtnClick(queryLng);

  const getContent = () => (
    <>
      <Box className={classnames(classes.paper)}>
        <form
          className={classnames(classes.centerComponent, classes.form)}
          onSubmit={handleSubmit(handleClickSignup(lang, newsletter))}
        >
          <Typography
            variant="h1"
            align="center"
            color="secondary"
            className={classes.title}
          >
            {t('Sign Up')}
          </Typography>
          <ErrorMessage errorMessage={errorMessage} />
          <EmailField showLabel={false} showTitle />
          <PasswordField
            handleClickShowPassword={handleClickShowPassword}
            showPassword={showPassword}
            validate={minLength8}
            showLabel={false}
            showTitle
          />
          <SignupAgreements
            onPolicyCheckBoxClick={onPolicyCheckBoxClick}
            lang={lang}
            agreements={agreements}
          />
          <Box width="100%">
            <RoundedButton
              id="nextBtn"
              variant="contained"
              color="primary"
              className={classnames(classes.button)}
              disabled={submitting || pristine || invalid || !termsAndService}
              type="submit"
              noMargin
            >
              {t('Sign up')}
            </RoundedButton>
          </Box>
        </form>
      </Box>
      <Divider className={classnames(classes.divider)} />
      <Box className={classnames(classes.paper)} elevation={1}>
        <Grid container direction="row" justifyContent="center" alignItems="center" spacing={1}>
          <Grid item>
            <Typography variant="body1" align="right">{t('Already have an account?')}</Typography>
          </Grid>
          <Grid item>
            <RoundedButton
              variant="text"
              color="primary"
              onClick={() => handleBtnClick(signinRoute)}
              className={classes.signInButton}
              noMargin
            >
              {t('Sign in')}
            </RoundedButton>
          </Grid>
        </Grid>
      </Box>
    </>
  );
  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyItems="center"
      alignItems="center"
    >
      <Box
        className={classes.content}
      >
        {getContent()}
      </Box>
      <Box>
        <Box mb={3}>
          <SocialIcons />
        </Box>
        <Box>
          <ServiceTerms />
        </Box>
      </Box>
    </Box>
  );
};

SignupForm.defaultProps = {
  submitting: false,
  pristine: false,
  invalid: false,
};

SignupForm.propTypes = {
  /**
   * Redux form handle submit function
   */
  handleSubmit: PropTypes.func.isRequired,
  /**
   * Is redux form submitting
   */
  submitting: PropTypes.bool,
  /**
   * Redux form props
   */
  pristine: PropTypes.bool,
  /**
   * Redux form props
   */
  invalid: PropTypes.bool,
};

SignupForm.whyDidYouRender = true;

export default reduxForm({
  form: formName,
  //  onSubmitSuccess: (result, dispatch, props) => {
  //   props.onNext()
  // },
  validate: validateAccount,
  asyncValidate: accountAsyncValidate,
  asyncBlurFields: ['email', 'userName'],
  destroyOnUnmount: false,
})(SignupForm);
