import * as R from 'ramda';
import { combineReducers } from 'redux';
import { PURGE } from 'redux-persist';

import {
  createReducer,
  mergeStateAndPayload,
  updateStateWithKeyPath,
} from '../store_utils';

import {
  earningTypeMap,
} from './constants';
import { handleTotalEarningBaseOnType } from './services';
import * as types from './types';

export const nodesIS = {
  nodes: [],
};
const nodesRUpdaters = {
  [types.GET_USER_NODES_SUCCESS]: (
    state,
    payload,
  ) => {
    const {
      nodes,
    } = payload;
    const filtedNodes = R.reject(R.propEq('status', 'DELETED'), nodes);

    return R.merge(state, { nodes: filtedNodes });
  },
  [PURGE]: R.always(nodesIS),
  // [types.UPDATE_USER_NODES_SUCCESS]: (
  //   state,
  //   payload,
  // ) => updateStateWithKey('nodes', state, payload),
};

const nodesR = createReducer(nodesIS, nodesRUpdaters);

const nodesEarningDefault = {
  totalEarning: 0,
  totalEarningData: [],
  totalEarningIn24h: 0,
  totalEarningIn24hData: [],
  totalEarningIn7d: 0,
  totalEarningIn7dData: [],
  totalEarningIn24hOneNode: 0,
  totalEarningIn7dOneNode: 0,
  interval: [],
  history: [],
};

export const nodesEarningIS = {
  eth2: nodesEarningDefault,
  ununifi: nodesEarningDefault,
  pha: nodesEarningDefault,
};

const nodesEarningRUpdaters = {
  [types.GET_NODES_TOTAL_EARNING_SUCCESS]: (
    state,
    payload,
  ) => {
    const totalEarning = R.sum(R.map(R.prop('earning'), payload.earnings));
    const { coin } = payload;

    return R.pipe(
      R.assocPath([coin, 'totalEarning'], totalEarning),
      R.assocPath([coin, 'totalEarningData'], payload.earnings),
    )(state);
  },
  [types.GET_NODES_TOTAL_EARNING_IN_PERIOD_SUCCESS]: (
    state,
    payload,
  ) => {
    const totalEarning = R.sum(R.map(R.prop('earning'), payload.earnings));
    const { period, isOneNode, coin } = payload;

    const oneNodePostFix = isOneNode ? 'OneNode' : '';

    return R.pipe(
      R.assocPath([coin, `totalEarningIn${period}${oneNodePostFix}`], totalEarning),
      R.assocPath([coin, `totalEarningIn${period}Data${oneNodePostFix}`], payload.earnings),
    )(state);
  },
  [types.GET_NODES_INTERVAL_EARNING_SUCCESS]: (
    state,
    payload,
  ) => {
    const { earnings, coin } = payload;

    return R.pipe(
      R.assocPath([coin, 'interval'], earnings),
    )(state);
  },
  [types.GET_EARNING_HISTORY_SUCCESS]: (
    state,
    payload,
  ) => {
    const { earnings } = payload;

    return R.merge(state, { history: earnings });
  },
  [PURGE]: R.always(nodesEarningIS),

};

const nodesEarningR = createReducer(nodesEarningIS, nodesEarningRUpdaters);

/**
 * Data vary among node types
 * @type {Object}
 */
const nodeDeployInputsIS = {};

const nodeDeployInputsRUpdaters = {
  [types.SAVE_NODE_DEPLOY_INPUTS]: mergeStateAndPayload,
  [PURGE]: R.always(nodesIS),
};
const nodeDeployInputsR = createReducer(nodeDeployInputsIS, nodeDeployInputsRUpdaters);

/**
 * Node data from chain
 * @type {Object}
 */
const userNodeDataIS = {
  nodeType: '',
  data: {},
  'default-node-name': {
    status: '',
    diskUsage: '',
    totalDisk: '',
    cpuUsage: '',
    ramUsage: '',
    totalRam: '',
    nodeName: '',
    dateCreated: '',
    errorInfo: '',
    nodeInfo: {
      nodeVersion: '',
      pubKey: '',
    },
    nodeType: 'ETH2_VALIDATOR_NODE',
  },
};

const userNodeDataRUpdaters = {
  [types.GET_USER_NODE_SUCCESS]: (state, payload) => {
    const { nodeName } = payload;
    return R.assoc(
      [nodeName],
      {
        ...state[nodeName],
        ...payload,
      },
      state,
    );
  },

  [PURGE]: R.always(userNodeDataIS),
};
const userNodeDataR = createReducer(userNodeDataIS, userNodeDataRUpdaters);

/**
 * Node data from chain
 * @type {Object}
 */
const userNodeDataFromChainIS = {
  'default-node-name': {
    balance: 0,
    effectiveBalance: 0,
    income: 0,
  },
};

const userNodeDataFromChainRUpdaters = {
  [types.GET_USER_NODE_EARNING_SUCCESS]: (state, payload) => {
    const { nodeName, data } = payload;
    return R.assoc(
      [nodeName],
      {
        ...state[nodeName],
        ...data,
      },
      state,
    );
  },
  [PURGE]: R.always(userNodeDataFromChainIS),
};
const userNodeDataFromChainR = createReducer(
  userNodeDataFromChainIS,
  userNodeDataFromChainRUpdaters,
);

const coinPriceIS = {
  ETH: {
    coin: 'ETH',
    currency: 'USD',
    price: 0,
    volume24h: 0,
    percentChange1h: 0,
    percentChange24h: 0,
    percentChange7d: 0,
    percentChange30d: 0,
    percentChange60d: 0,
    percentChange90d: 0,
    marketCap: 0,
    lastUpdated: '',
    prices: [],
  },
};

const coinPriceRUpdaters = {
  [types.GET_COIN_PRICE_SUCCESS]: (state, payload) => {
    const { coin } = payload;
    const coinPrice = {};
    coinPrice[coin] = payload;
    return R.merge(state, coinPrice);
  },
  [types.GET_COIN_PRICE_CHART_SUCCESS]: (state, payload) => {
    const { coin, prices } = payload;
    const previousCoinPrice = R.prop(coin, state) || {};
    const coinPrice = {};
    coinPrice[coin] = {
      ...previousCoinPrice,
      prices,
    };
    return R.merge(state, coinPrice);
  },
  [PURGE]: R.always(coinPriceIS),
};
const coinPriceR = createReducer(
  coinPriceIS,
  coinPriceRUpdaters,
);

const zenEarningIS = {
  earning: 0,
  balance: 0,
  quantity: 0,
};

const zenEarningRUpdaters = {
  [types.GET_ZEN_BALANCE_SUCCESS]: (state, payload) => {
    const { balance, quantity } = payload;
    const earning = balance - 4200000000 * quantity;
    const balanceInZen = balance / 100000000;
    const earningInZen = earning / 100000000;
    return {
      earning: earningInZen,
      balance: balanceInZen,
      quantity,
    };
  },
  [PURGE]: R.always(zenEarningIS),
};
const zenEarningR = createReducer(
  zenEarningIS,
  zenEarningRUpdaters,
);

const nodeActivatedDateIS = {
};

const nodeActivatedDateRUpdaters = {
  [types.GET_NODES_ESTIMATE_ACTIVATION_DATE_SUCCESS]: (state, payload) => {
    const { activationDates } = payload;
    const result = R.pipe(
      R.map((eachData) => {
        const { publicKey, estimateActivationDate } = eachData;

        const newActiveDate = {};
        newActiveDate[publicKey] = { estimateActivationDate };

        return newActiveDate;
      }),
      R.mergeAll,
    )(activationDates);

    return result;
  },
  [PURGE]: R.always(nodeActivatedDateIS),
};
const nodeActivatedDateR = createReducer(
  nodeActivatedDateIS,
  nodeActivatedDateRUpdaters,
);

const nodeOwnerEarningIS = {
  interval: {
    neunode: {},
    neunodeETH2: {},
    busd: {},
  },
  total: {
    neunode: {
      total: 0,
      totalInCoin: 0,
      totalBalance: 0,
      totalBalanceInCoin: 0,
      currency: 'jpy',
    },
    neunodeETH2: {
      total: 0,
      totalInCoin: 0,
      totalBalance: 0,
      totalBalanceInCoin: 0,
      currency: 'jpy',
    },
    busd: {
      total: 0,
      totalInCoin: 0,
      totalBalance: 0,
      totalBalanceInCoin: 0,
      currency: 'jpy',
    },
    phalaSixCore: {
      total: 0,
      totalInCoin: 0,
      balance: 0,
      currency: 'jpy',
    },
    phalaFourCore: {
      total: 0,
      totalInCoin: 0,
      balance: 0,
      currency: 'jpy',
    },
  },
};

const nodeOwnerEarninRUpdaters = {
  [types.GET_INTERVAL_FIXED_EARNING_SUCCESS]: (state, payload) => {
    const {
      interval,
      earnings,
      currency,
      earningType,
    } = payload;
    const parsedEarningType = earningTypeMap[earningType];

    const {
      total,
      totalInCoin,
      totalBalance,
      totalBalanceInCoin,
      previousInterval,
      previousIntervalToUse,
      previousTotal,
    } = handleTotalEarningBaseOnType(state, earningType, payload);

    const result = {
      interval: R.assoc(
        parsedEarningType,
        R.assoc(`interval_${interval}`, earnings, previousIntervalToUse),
        previousInterval,
      ),
      total:
      R.assoc(
        parsedEarningType,
        {
          total,
          totalInCoin,
          totalBalance,
          totalBalanceInCoin,
          currency,
        },
        previousTotal,
      ),
    };
    return result;
  },
  [types.GET_NODE_OWNER_TOTAL_EARNING_SUCCESS]: (state, payload) => {
    const {
      total,
      totalCoin: totalInCoin,
      currency,
      balance,
      nodeType,
    } = payload;
    const parsedEarningType = earningTypeMap[nodeType];
    return updateStateWithKeyPath(['total', parsedEarningType], {
      total,
      totalInCoin,
      currency,
      balance,
    }, state);
  },
  [PURGE]: R.always(nodeOwnerEarningIS),
};
const nodeOwnerEarningR = createReducer(
  nodeOwnerEarningIS,
  nodeOwnerEarninRUpdaters,
);

const nodeR = combineReducers({
  userNodes: nodesR,
  nodesEarning: nodesEarningR,
  nodeDeployInputs: nodeDeployInputsR,
  userNodeData: userNodeDataR,
  userNodeDataFromChain: userNodeDataFromChainR,
  coinPrice: coinPriceR,
  zenEarning: zenEarningR,
  nodeActivatedDate: nodeActivatedDateR,
  nodeOwnerEarning: nodeOwnerEarningR,
});
export default nodeR;
