import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React from 'react';

import { connectorStyles } from './styles';

const useStyles = makeStyles(connectorStyles);

const Connector = (props) => {
  const {
    onClick,
    connectorName,
    connectorImgSrc,
  } = props;
  const classes = useStyles();
  return (
    <Box
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      onClick={onClick}
      p={1}
      ml={1}
      mr={1}
      mb={1}
      className={classes.itemBox}
      key={connectorName}
    >
      <Box p={1}>
        <Typography>
          {connectorName}
        </Typography>
      </Box>
      <Box p={1}>
        <img
          alt={connectorName}
          src={connectorImgSrc}
          className={classes.connectorLogo}
        />
      </Box>
    </Box>
  );
};

Connector.propTypes = {
  onClick: PropTypes.func.isRequired,
  connectorName: PropTypes.string.isRequired,
  connectorImgSrc: PropTypes.string.isRequired,
};

export default React.memo(Connector);
