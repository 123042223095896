import Box from '@material-ui/core/Box';
import Link from '@material-ui/core/Link';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';

import { socialLinks } from '../../modules/appConstants';

import styles from './styles';

const useStyles = makeStyles(styles);

const SocialIcons = () => {
  const classes = useStyles();

  return (
    <Box
      display="flex"
      flexDirection="row"
      justifyContent="center"
      alignItems="center"
    >
      <Box className={classes.icon}>
        <Link href={socialLinks.twitter} target="_blank">
          <img
            src="/img/icon/twitter-dark.png"
            alt="twitter-dark-icon"
            className={classes.iconImage}
          />
        </Link>
      </Box>
      <Box className={classes.icon}>
        <Link href={socialLinks.facebook} target="_blank">
          <img
            src="/img/icon/facebook-dark.png"
            alt="twitter-dark-icon"
            className={classes.iconImage}
          />
        </Link>
      </Box>
      <Box className={classes.icon}>
        <Link href={socialLinks.telegram} target="_blank">
          <img
            src="/img/icon/telegram-dark.png"
            alt="twitter-dark-icon"
            className={classes.iconImage}
          />
        </Link>
      </Box>
      <Box className={classes.icon}>
        <Link href={socialLinks.discord} target="_blank">
          <img
            src="/img/icon/discord-dark.png"
            alt="twitter-dark-icon"
            className={classes.iconImage}
          />
        </Link>
      </Box>
    </Box>
  );
};

export default SocialIcons;
