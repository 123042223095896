import * as R from 'ramda';

export const selectWalletR = R.view(R.lensPath(['walletR']));
export const selectWallet = R.pipe(
  selectWalletR,
  R.view(R.lensPath(['wallet'])),
);

export const selectWalletSelectedAccount = (state) => R.pipe(
  selectWallet,
  R.view(R.lensPath(['selectedAccount'])),
)(state);

export const selectWalletConnectMethod = (state) => R.pipe(
  selectWallet,
  R.view(R.lensPath(['walletConnectMethod'])),
)(state);
