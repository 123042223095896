import { errorOperations } from '../error/index';

import * as actions from './actions';

export const generateSecretPhrase = () => (dispatch) => {
  try {
    const secretPhraseData = dispatch(actions.generateSecretPhrase());
    dispatch(errorOperations.clearError('generateSecretPhrase'));
    return secretPhraseData;
  } catch (error) {
    return dispatch(errorOperations.setError(
      'generateSecretPhrase',
      { fallbackErrorMessage: 'Generate secret phrase failed', error },
    ));
  }
};
export const resetSecrePhrase = () => (dispatch) => {
  try {
    const secretPhraseData = dispatch(actions.resetSecrePhrase());
    dispatch(errorOperations.clearError('resetSecretPhrase'));
    return secretPhraseData;
  } catch (error) {
    return dispatch(errorOperations.setError(
      'resetSecrePhrase',
      { fallbackErrorMessage: 'Generate secret phrase failed', error },
    ));
  }
};

export const { getSecretPhrase } = actions;
