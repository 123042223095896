import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import HelpOutlineOutlinedIcon from '@material-ui/icons/HelpOutlineOutlined';
import { gweiToEther } from 'jsutils';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import AmountCard from '../../AmountCard/AmountCard';
import CustomTooltip from '../../Tooltip/CustomTooltip';
import { convertCryptoToFiat, convertToCryptoNumber } from '../../utils/utils';
import InvestmentEarningDashboardItem from '../InvestmentNodeDetailsPageItem';

import { nodeOwnerOverviewStyles } from './styles';

const useStyles = makeStyles(nodeOwnerOverviewStyles);

const ETH2ValidatorOverview = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const { averageAPR } = props;

  const {
    getUserNodesLoading,
    getCoinPriceLoading,
    getTotalEarningLoading,
  } = useSelector((state) => state.loadingR);

  const coinPrice = useSelector((state) => state.nodeR.coinPrice);
  const { ETH } = coinPrice;
  const { price, currency, percentChange24h } = ETH || {};

  const { userNodes } = useSelector((state) => state.nodeR);
  const { nodes: rawNodes } = userNodes;
  const nodesETH2 = R.filter(R.propEq('nodeType', 'ETH2_VALIDATOR_NODE'), rawNodes);
  const nodesCount = R.length(nodesETH2);
  const activeNodesCount = getUserNodesLoading
    ? null
    : R.length(R.filter(R.propEq('status', 'RUNNING'), nodesETH2));

  const nodesEarning = useSelector((state) => state.nodeR.nodesEarning.eth2);
  const { totalEarning: totalEarningETH2NodesInETH, totalEarningData } = nodesEarning;
  const totalETH2BalanceInETH = R.pipe(
    R.map(R.prop('balance')),
    R.sum,
  )(totalEarningData);
  const oneNodeStakingAmountInETH = 32;
  const totalETH2StakedInETH = R.pipe(
    R.map((eachEarning) => (R.prop('balance', eachEarning) > 0 ? 1 : 0)),
    R.sum,
    R.multiply(oneNodeStakingAmountInETH),
  )(totalEarningData);

  const totalBalance = convertCryptoToFiat('ETH', coinPrice, gweiToEther(totalETH2BalanceInETH));

  const getActiveNodeContent = (value, max) => {
    const percent = (value / max) * 100;
    return (
      <Box className={classes.container}>
        <Box position="relative" display="inline-flex">
          <CircularProgress
            variant="determinate"
            value={100}
            size={100}
            className={classes.dataWithCircleBg}
          />
          <Box
            top={0}
            left={0}
            bottom={0}
            right={0}
            position="absolute"
          >
            <CircularProgress
              variant="determinate"
              value={percent}
              size={100}
              className={classes.dataWithCircle}
            />
          </Box>
          <Box
            top={0}
            left={0}
            bottom={0}
            right={0}
            position="absolute"
            display="flex"
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
          >
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <Typography variant="body1" className={classes.percent}>{`${value}/${max}`}</Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    );
  };

  const getCoinPriceContent = (price, change) => (
    <Box display="flex" justifyContent="center" flexWrap="wrap">
      <Box mr={1}>
        <Typography variant="body1" className={classes.overviewText}>
          {price ? price.toLocaleString('en-US', {
            style: 'currency',
            currency,
          }) : '-'}
        </Typography>
      </Box>
      {change && (
      <Box>
        <Typography variant="body1" className={change >= 0 ? classes.percentTextAsc : classes.percentTextDesc}>
          {change >= 0 ? `+${change.toFixed(2)}%` : `${change.toFixed(2)}%`}
        </Typography>
      </Box>
      )}
    </Box>
  );

  return (
    <Grid container alignItems="stretch" spacing={2}>
      <Grid item xs={12} sm={6}>
        <AmountCard
          data={{
            balanceTitle: t('Total Balance'),
            earningTitle: t('Total Earnings'),
            stakedTitle: t('Total Staked'),
            balance: `${convertToCryptoNumber(gweiToEther(totalETH2BalanceInETH))} ETH`,
            balanceExchanged: `≈ ${totalBalance.toLocaleString('en-US', {
              style: 'currency',
              currency,
            })}`,
            earning: `${convertToCryptoNumber(gweiToEther(totalEarningETH2NodesInETH))} ETH`,
            staked: `${totalETH2StakedInETH.toFixed(0)} ETH`,
          }}
          loading={getTotalEarningLoading || getUserNodesLoading}
          className={classes.amountCard}
          titleTextColor="#000000"
          earningTextColor="#ffffff"
          earningExchangedTextColor="#F5F5F5"
        />
      </Grid>
      <Grid item xs={12} sm={3}>
        <Grid container style={{ height: '100%', margin: 0 }}>
          <Grid item xs={6} sm={12} className={classes.stack2Top}>
            <InvestmentEarningDashboardItem
              align="center"
              title={t('ETH Price')}
              content={getCoinPriceContent(price, percentChange24h)}
              loading={getCoinPriceLoading}
            />
          </Grid>
          <Grid item xs={6} sm={12} className={classes.stack2Bottom}>
            <InvestmentEarningDashboardItem
              align="center"
              title={(
                <Box display="flex" justifyContent="center" alignItems="center">
                  <Box mr={1}>
                    <Typography
                      variant="body1"
                      align="center"
                      className={classes.overviewText}
                    >
                      {t('Est. APR')}
                    </Typography>
                  </Box>
                  <Box>
                    <CustomTooltip
                      title={t('Estimated average Annual Percentage Rate of your nodes since active')}
                      content={(
                        <HelpOutlineOutlinedIcon
                          color="disabled"
                          style={{ fontSize: 20 }}
                        />
)}
                    />
                  </Box>
                </Box>
              )}
              content={(
                <Typography
                  variant="body1"
                  align="center"
                  className={classes.overviewText}
                >
                  {`${averageAPR.toFixed(3)}%`}
                </Typography>
              )}
              loading={
                getUserNodesLoading
                || getCoinPriceLoading
                || getTotalEarningLoading
              }
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} sm={3}>
        <InvestmentEarningDashboardItem
          align="center"
          title={t('Active Node')}
          content={getActiveNodeContent(activeNodesCount, nodesCount)}
          loading={getUserNodesLoading}
          loadingVariant="circle"
        />
      </Grid>
    </Grid>
  );
};

ETH2ValidatorOverview.defaultProps = {
  averageAPR: 0,
};
ETH2ValidatorOverview.propTypes = {
  averageAPR: PropTypes.number,
};

export default ETH2ValidatorOverview;
