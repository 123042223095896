// matrial ui
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { reduxForm } from 'redux-form';

// store

import { useAddFunctionToRef, useGetQuery, useUserDataSelector } from '../../hooks/hooks';

// other
import { dashboardRoute, signinRoute } from '../../routes';
import { errorServices } from '../../store/error/index';
import { routerServices } from '../../store/router/index';
import { persistor } from '../../store/store';
import { userOperations } from '../../store/user/index';
import {
  RESET_PASSWORD_SUCCESS,
  VALIDATE_FORGOT_PASSWORD_REQUEST_SUCCESS,
} from '../../store/user/types';
import ErrorMessage from '../ErrorMessage/ErrorMessage';
import { RoundedButton } from '../MaterialUiCustom/MaterialUiCustom';
import PasswordField from '../PasswordField/PasswordField';

import { styles } from './styles';
import { validate } from './validate';

const { extractErrorMessage } = errorServices;
const useStyles = makeStyles(styles);

const ResetPassword = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const history = useHistory();
  const queryLng = useGetQuery('lng');
  const {
    handleSubmit,
  } = props;
  const {
    submitting, pristine, invalid, match,
  } = props;
  const { params } = match;
  const { forgotPasswordId } = params;

  const dispatch = useDispatch();

  const [disableButton, setDisableButton] = useState(false);
  const [done, setDone] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [isError, setIsError] = useState(false);
  const [loading, setLoading] = useState(true);

  const authentication = useSelector((state) => state.userR.authentication);
  const { userId } = useUserDataSelector();
  const { isAuthenticated } = authentication;
  const errorR = useSelector((state) => state.errorR);
  const { resetPasswordError } = errorR || {};
  const message = extractErrorMessage(resetPasswordError);
  const [errorMessage, setErrorMessage] = useState('');
  useEffect(() => {
    setErrorMessage(message);
  }, [message]);

  const handleResetPassword = async (formValues) => {
    const { newPassword } = formValues;
    const response = await dispatch(userOperations.resetPassword(newPassword, forgotPasswordId));
    if (response.type === RESET_PASSWORD_SUCCESS) {
      setDisableButton(true);
      setDone(true);
    }
  };
  const onSignout = async () => {
    await dispatch(userOperations.signOut(userId));
    await persistor.purge();
    // await persistor.flush();
    // await persistor.pause();
  };
  const onValidateForgotPassword = useAddFunctionToRef(async () => {
    const response = await dispatch(
      userOperations.validateForgotPassword(forgotPasswordId),
    );
    if (response.type === VALIDATE_FORGOT_PASSWORD_REQUEST_SUCCESS) {
      setLoading(false);
      setIsError(false);
      return onSignout();
    }
    return setIsError(true);
  });

  useEffect(() => {
    onValidateForgotPassword();
  }, [onValidateForgotPassword]);

  const handleOnClickSignin = useAddFunctionToRef((signinRoute) => {
    routerServices.historyPush(
      history,
      {
        pathname: signinRoute,
        queryLng,
      },
    );
  });
  useEffect(() => {
    if (isError) {
      handleOnClickSignin(signinRoute);
    }
  }, [isError, queryLng, history, handleOnClickSignin]);

  /**
   * Toggle showPassword when user click the show passwrod button
   */
  const handleClickShowPassword = () => setShowPassword(!showPassword);

  useEffect(() => {
    if (isAuthenticated) {
      return routerServices.historyPush(
        history,
        {
          pathname: dashboardRoute,
          queryLng,
        },
      );
    }
  }, [isAuthenticated, queryLng, history]);

  return (
    <Box className={classnames(classes.paper)} elevation={1}>
      <form
        className={classnames(classes.centerComponent, classes.form)}
        onSubmit={handleSubmit(handleResetPassword)}
      >
        <Typography
          variant="h2"
          align="center"
          color="secondary"
          className={classes.title}
        >
          {t('Reset your password')}
        </Typography>
        <ErrorMessage errorMessage={errorMessage} />
        {!done && (
          <Grid container direction="column" justifyContent="center" alignItems="center" className={classnames(classes.middleElements)}>
            <PasswordField
              handleClickShowPassword={handleClickShowPassword}
              showPassword={showPassword}
              customClassName={classnames(classes.textField)}
              name="newPassword"
              label={t('New Password')}
              showLabel={false}
              showTitle
            />
            <PasswordField
              handleClickShowPassword={handleClickShowPassword}
              showPassword={showPassword}
              customClassName={classnames(classes.textField)}
              name="confirmPassword"
              label={t('Confirm Password')}
              showLabel={false}
              showTitle
            />
          </Grid>
        )}
        {done && (
          <Grid container direction="column" justifyContent="center" alignItems="center" className={classnames(classes.middleElements)}>
            <CheckCircleIcon color="primary" style={{ fontSize: 40 }} />
            <Typography className={classes.doneText}>
              {t('Your password has been reset.')}
            </Typography>
          </Grid>
        )}
        <Box width="100%">
          <Box className="flex flex-row justify-center">
            {!done && (
              <RoundedButton
                variant="contained"
                color="primary"
                type="submit"
                className={classes.button}
                disabled={disableButton || loading || submitting || pristine || invalid}
                noMargin
              >
                {t('Confirm')}
              </RoundedButton>
            )}
            {done && (
              <RoundedButton
                variant="contained"
                color="primary"
                onClick={() => handleOnClickSignin(signinRoute)}
                noMargin
                className={classes.button}
              >
                {t('Sign in')}
              </RoundedButton>
            )}
          </Box>
        </Box>
      </form>
    </Box>
  );
};

ResetPassword.defaultProps = {
  forgotPasswordId: '',
  match: {},
  params: {},
};

// validate prop' type
ResetPassword.propTypes = {
  submitting: PropTypes.bool.isRequired,
  pristine: PropTypes.bool.isRequired,
  invalid: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  forgotPasswordId: PropTypes.string,
  match: PropTypes.object,
  params: PropTypes.object,
};

export default reduxForm({
  form: 'ResetPassword',
  validate,
})(ResetPassword);
