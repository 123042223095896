import Box from '@material-ui/core/Box';
import Dialog from '@material-ui/core/Dialog';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import whyDidYouRender from '@welldone-software/why-did-you-render';
import * as R from 'ramda';
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { useAddFunctionToRef } from '../../../hooks/hooks';
import { adminNodeOperations } from '../../../store/admin/node/index';
import { adminOrderOperations } from '../../../store/admin/order/index';
import { CREATE_ORDERS_SUCCESS } from '../../../store/admin/order/types';
import FailPage from '../../FailPage/FailPage';
import { RoundedButton } from '../../MaterialUiCustom/MaterialUiCustom';
import SuccessPage from '../../SuccessPage/SuccessPage';
import CustomTabs from '../../Tabs/CustomTabs';
import { orderStyles } from '../styles';

import CreateOrders from './CreateOrders';
import CreateOrdersSuccess from './CreateOrdersSuccess';
import OrderList from './OrderList';

// store

// others
import { formatCreateOrdersInput } from './services';

if (process.env.REACT_APP_ENV !== 'prod') {
  whyDidYouRender(React);
}

const useStyles = makeStyles(orderStyles);

const Orders = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const onGetUserNodes = useAddFunctionToRef(async () => {
    await dispatch(adminNodeOperations.getNodes({ query: [{}] }));
  });
  useEffect(() => {
    onGetUserNodes();
  }, [onGetUserNodes]);

  const [openCreateOrders, setOpenCreateOrders] = useState(false);
  const handleCreateOrdersDialogClose = () => {
    setOpenCreateOrders(false);
  };
  const handleCreateOrdersDialogOpen = () => {
    setOpenCreateOrders(true);
  };

  const [statusDialog, setStatusDialog] = useState('');
  const handleStatusDialogClose = () => {
    setStatusDialog('');
  };
  const handleStatusDialogOpen = (status) => {
    setStatusDialog(status);
  };

  const onCreateOrders = async (data, clearData) => {
    const formatedData = formatCreateOrdersInput(R.values(data));

    const { type } = await dispatch(adminOrderOperations.createOrders(formatedData));

    if (type === CREATE_ORDERS_SUCCESS) {
      handleStatusDialogOpen('success');
      handleCreateOrdersDialogClose();
      clearData();
    } else {
      handleStatusDialogOpen('fail');
    }
  };

  const tabs = [
    {
      label: t('Orders'),
      content: <OrderList t={t} />,
    },
  ];

  return (
    <>
      <CustomTabs
        tabs={tabs}
        extraComponent={(
          <RoundedButton
            variant="contained"
            color="primary"
            className={classes.createOrdersButton}
            onClick={handleCreateOrdersDialogOpen}
          >
            {t('create')}
          </RoundedButton>
      )}
      />
      <CreateOrders
        open={openCreateOrders}
        onClose={handleCreateOrdersDialogClose}
        onCreateOrders={onCreateOrders}
      />
      <Dialog open={statusDialog === 'success'} onClose={handleStatusDialogClose} fullWidth maxWidth="md">
        <Box className={classes.statusDialog}>
          <SuccessPage
            onCancel={handleStatusDialogClose}
            primaryText={t('THANK YOU!')}
            message={(
              <Box>
                <Typography align="center">
                  {t('Create orders successfully. Here is the details')}
                </Typography>
                <Paper className={classes.successDialogDetails}>
                  <CreateOrdersSuccess />
                </Paper>
              </Box>
            )}
            btnText={t('close')}
          />
        </Box>
      </Dialog>
      <Dialog open={statusDialog === 'fail'} onClose={handleStatusDialogClose} fullWidth maxWidth="sm">
        <Box className={classes.statusDialog}>
          <FailPage
            onCancel={handleStatusDialogClose}
            primaryText={t('OH NO!')}
            message={t('Create orders fail')}
            btnText={t('close')}
          />
        </Box>
      </Dialog>
    </>
  );
};

Orders.whyDidYouRender = true;

export default React.memo(Orders);
