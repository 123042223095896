import * as R from 'ramda';

import { errorOperations } from '../../error/index';
import { loadingOperations } from '../../loading/index';
import { paginationOperations } from '../../pagination/index';

import * as actions from './actions';

export const getUsers = R.curry(async (getUsersInput, paginationInput, dispatch) => {
  try {
    dispatch(loadingOperations.setLoading('getUsers', true));
    const response = dispatch(
      await actions.getUsers(
        getUsersInput,
        paginationInput,
      ),
    );
    const paginationMeta = R.view(R.lensPath(['payload', 'meta']), response);
    dispatch(paginationOperations.setPaginationMeta('getUsers', paginationMeta));

    dispatch(loadingOperations.setLoading('getUsers', false));
    dispatch(errorOperations.clearError('getUsers'));
    return response;
  } catch (error) {
    dispatch(loadingOperations.setLoading('getUsers', false));
    return dispatch(errorOperations.setError(
      'getUsers',
      { error },
    ));
  }
});

export const createUsers = R.curry(async (data, dispatch) => {
  const operationName = 'createUsers';
  try {
    dispatch(loadingOperations.setLoading(operationName, true));
    const response = dispatch(
      await actions.createUsers(
        data,
      ),
    );
    dispatch(loadingOperations.setLoading(operationName, false));
    dispatch(errorOperations.clearError(operationName));
    return response;
  } catch (error) {
    dispatch(loadingOperations.setLoading(operationName, false));
    return dispatch(errorOperations.setError(
      operationName,
      { error },
    ));
  }
});

export const sendFirstSigninEmails = R.curry(async (data, dispatch) => {
  const operationName = 'sendFirstSigninEmails';
  try {
    dispatch(loadingOperations.setLoading(operationName, true));
    const response = dispatch(
      await actions.sendFirstSigninEmails(
        data,
      ),
    );
    dispatch(loadingOperations.setLoading(operationName, false));
    dispatch(errorOperations.clearError(operationName));
    return response;
  } catch (error) {
    dispatch(loadingOperations.setLoading(operationName, false));
    return dispatch(errorOperations.setError(
      operationName,
      { error },
    ));
  }
});
