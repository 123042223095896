import React, { useEffect, useCallback } from 'react';
import './App.css';
// utils lib
// material-ui
import Box from '@material-ui/core/Box';
import CssBaseline from '@material-ui/core/CssBaseline';
import Dialog from '@material-ui/core/Dialog';
import Toolbar from '@material-ui/core/Toolbar';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import {
  BroadcastChannel,
  createLeaderElection,
} from 'broadcast-channel';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import * as R from 'ramda';
// redux
// google-analytics
import ReactGA from 'react-ga';
import { useSelector, useDispatch } from 'react-redux';
// TOASTIFY

// react-router
import {
  Route,
  Switch,
  useLocation,
  useHistory,
} from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
// routes

// components
import SignupForm from './components/SignupForm/SignupForm';
import SignupAdminForm from './components/SignupForm/SignupAdminForm';
import SignupSuccess from './components/SignupSuccess/SignupSuccess';
import MarketProtocolDeploy from './components/MarketProtocolDeploy/MarketProtocolDeploy';
import MyNodesList from './components/MyNodesList/MyNodesList';
import ErrorAlert from './components/Error/ErrorAlert';
import Market from './components/Market/Market';
import ChainInfo from './components/ChainInfo/ChainInfo';
import PaymentPage from './components/PaymentPage/PaymentPage';
import Overview from './components/Overview/Overview';
import MyNodeInfo from './components/MyNodeInfo/MyNodeInfo';
import Activation from './components/Activation/Activation';
import Referral from './components/Referral/Referral';
import ConnectToWallet from './components/ConnectToWallet/ConnectToWallet';
import Investment from './components/Investment/Investment';
import NodeOwner from './components/NodeOwner/NodeOwner';
import DAppStore from './components/DAppStore/DAppStore';
import AdminDashboard from './components/Admin/User/AdminDashboard';
import ImpersonationAlert from './components/Admin/User/ImpersonationAlert';
import Orders from './components/Admin/Order/Orders';
import Pools from './components/Admin/Pool/Pools';
import AdminEarningTable from './components/Admin/EarningTable/AdminEarningTable';

import ContactForm from './components/ContactForm/ContactForm';
import FirstSignin from './components/FirstSignin/FirstSignin';
import ForgotPassword from './components/ForgotPassword/ForgotPassword';
import Navigation from './components/Navigation/Navigation';
import NotFoundPage from './components/NotFoundPage/NotFoundPage';
import { ScrollToTop } from './components/ReactRouterCustom/ReactRouterCustom';
import ResetPassword from './components/ResetPassword/ResetPassword';
import Signin from './components/Signin/Signin';
import SigninAdmin from './components/SigninAdmin/SigninAdmin';
import UserInfo from './components/UserInfo/UserInfo';
// operations and services
// types
import { UPDATE_AUTH_TOKEN_SUCCESS, SET_AUTH_TOKENS_STATUS_OUTDATED, SET_AUTH_TOKENS_STATUS_UPDATED } from './store/jwt/types';
// custom hooks
import {
  useAddFunctionToRef,
  useInterval,
  useQuery,
  useUserDataSelector,
} from './hooks/hooks';
import {
  getItem,
  doesItemWithKeyExistsInLocalStorage,
  isPageReloaded,
} from './modules/browser_utils';
import {
  keyToAuthTokenDataInLocalStorage,
  keyToUserDataInLocalStorage,
  keyToAuthStatusInLocalStorage,
  intervalToCheckAuthToken,
  fallbackInterval,
  responseTime,
} from './modules/appConstants';
import {
  shouldUpdateAuthToken,
} from './modules/jwt_utils';
import {
  excludedRoutes,
  rootRoute,
  forgotPasswordRoute,
  resetPasswordRoute,
  signinRoute,
  firstSigninRoute,
  signinMultisigRoute,
  signupRoute,
  signinAdminRoute,
  signupAdminRoute,
  signupSuccessRoute,
  accountRoute,
  allNotFoundPageRoute,
  notFoundPageRoute,
  martketDeployRoute,
  dashboardRoute,
  adminDashboardRoute,
  adminOrdersRoute,
  adminPoolsRoute,
  adminEarningRoute,
  myNodeListRoute,
  marketRoute,
  chainInfoRoute,
  activationRoute,
  nodeInfoRoute,
  paymentPageRoute,
  referralRoute,
  connectToWalletRoute,
  investmentRoute,
  nodeOwnerRoute,
  nodeOwnerServiceRoute,
  dappStoreRoute,
  investmentBaseRoute,
  walletRoute,
  // myNodeRoute,
} from './routes';
import { adminOperations, adminUtils } from './store/admin/index';
import { contactFormOperations } from './store/contact_form/index';
import { errorServices } from './store/error/index';
import { jwtOperations } from './store/jwt/index';
import { navigationOperations } from './store/navigation/index';
import { routerServices } from './store/router/index';
import { persistor } from './store/store';
import { userOperations } from './store/user/index';

import Wallet from 'components/Wallet/Wallet';

const styles = (theme) => ({
  root: {
    display: 'flex',
    [theme.breakpoints.up('lg')]: {
      justifyContent: 'center',
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    [theme.breakpoints.up('lg')]: {
      maxWidth: 1280,
    },
    flexGrow: 1,
    padding: process.env.REACT_APP_ANNOUNCEMENT_BAR_DISPLAY === 'ON' ? theme.spacing(10, 12) : theme.spacing(4, 12),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    [theme.breakpoints.down('sm')]: {
      padding: process.env.REACT_APP_ANNOUNCEMENT_BAR_DISPLAY === 'ON' ? theme.spacing(10, 5) : theme.spacing(4, 5),
    },
    [theme.breakpoints.down('xs')]: {
      padding: process.env.REACT_APP_ANNOUNCEMENT_BAR_DISPLAY === 'ON' ? theme.spacing(10, 1) : theme.spacing(4, 1),
    },
    // marginLeft: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    // marginLeft: 0,
  },
});
const useStyles = makeStyles(styles);

const App = () => {
  const history = useHistory();
  const location = useLocation();
  const { pathname } = location;
  const query = useQuery();
  const queryLng = query.get('lng');
  const dispatch = useDispatch();
  const classes = useStyles();

  const {
    REACT_APP_ENV,
    REACT_APP_JWT_GUARD,
    REACT_APP_GA_MEASUREMENT_ID,
    REACT_APP_SITE_SPEED_SAMPLE_RATE,
  } = process.env;

  const { isAuthenticated } = useSelector((state) => state.userR.authentication);
  const isContactFormOpen = useSelector((state) => state.contactFormR.open);
  // User data in 'userR.user' reducer. This may be either signed in user data
  // or impersonated user data. If the role here is not 'ADMIN' and user is being
  // impersonated admin will get an <Alert> that he is impersonating a user.
  // const userRole = useSelector((state) => state.userR.user.role);
  // Select data of user who actually signed in. If admin is signed the 'userR.user'
  // reducer sometimes contain the impersonated user data rather than signed in user
  // data. So we use the hook to get actual signed is user data.
  const { userId, role, email } = useUserDataSelector();
  const {
    isImpersonating: isImpersonatingInSession,
    impersonatedUser: impersonatedUserInSession,
  } = adminUtils.getImpersonationDataFromSessionStorage();
  const errorToRender = useSelector((state) => state.errorR.errorToRender);

  const theme = useTheme();
  const isMobileDevice = useMediaQuery(theme.breakpoints.down('sm'));

  const shouldRenderErrorPageRef = useAddFunctionToRef(errorServices.shouldRenderErrorPage);

  const broadcastChannel = new BroadcastChannel('naas');
  const leaderTabElector = createLeaderElection(broadcastChannel, {
    fallbackInterval, // optional configuration for how often will renegotiation for leader occur
    responseTime, // optional configuration for how long will instances have to respond
  });
  leaderTabElector.awaitLeadership().then(() => {
    if (REACT_APP_JWT_GUARD === 'ON') {
      dispatch(jwtOperations.electLeaderTab());
    }
  });

  // const isAdminRoute = R.includes('admin', pathname);
  const { user: impersonatedUser } = useSelector((state) => state.adminR.userImpersonation);
  // When admin impersonates any user we load the impersonated user data to

  const onPageInitialLoad = () => {
    const { email: impersonatedUserEmail } = impersonatedUser;
    if (role === 'ADMIN' && impersonatedUserEmail) {
      adminUtils.setImpersonationDataToSessionStorage('true', impersonatedUser);
      dispatch(userOperations.loadUser(impersonatedUser));
    }
  };

  const onPageReload = () => {
    if (role === 'ADMIN' && isImpersonatingInSession) {
      dispatch(userOperations.loadUser(impersonatedUserInSession));
    }
  };
  const onUserImpersonation = useAddFunctionToRef(() => {
    if (!isPageReloaded()) {
      return onPageInitialLoad();
    }
    onPageReload();
  });

  useEffect(() => {
    onUserImpersonation();
  }, [onUserImpersonation]);

  const onStopImpersonation = () => {
    dispatch(adminOperations.stopImpersonation());
  };
  // To stop impersonating when user close the tab
  if (role === 'ADMIN') {
    window.addEventListener('beforeunload', () => {
      onStopImpersonation();
    });
  }

  ReactGA.initialize(REACT_APP_GA_MEASUREMENT_ID, {
    gaOptions: {
      debug: REACT_APP_ENV !== 'prod',
      siteSpeedSampleRate: REACT_APP_SITE_SPEED_SAMPLE_RATE,
    },
  });

  const checkAppLoadTime = (entryList) => (
    entryList.getEntries().forEach((entry) => {
      ReactGA.timing({
        category: 'Server Latency',
        variable: entry.name,
        value: entry.responseStart - entry.requestStart,
        label: 'Page Load Time',
      });
    })
  );

  const appLoadTimeObserver = new PerformanceObserver(checkAppLoadTime);
  appLoadTimeObserver.observe({ entryTypes: ['navigation'] });

  const closeContactForm = () => {
    dispatch(contactFormOperations.closeContactForm());
  };

  const onSignout = useCallback(async () => {
    await dispatch(userOperations.signOut(userId));
    await persistor.purge();
  }, [dispatch, userId]);

  /**
   * Update auth tokens before the current ones expires
   * @param  {string} key key that was used to store auth token data in browser local storage
   * @return {void}
   */
  const updateAuthTokenDataWhenNecessary = async (authTokenDataKey, userDataKey, authStatusKey) => {
    if (shouldUpdateAuthToken(authTokenDataKey)) {
      const { type } = await dispatch(jwtOperations.updateAuthTokenData(
        authTokenDataKey, userDataKey, authStatusKey,
      ));

      if (
        type !== UPDATE_AUTH_TOKEN_SUCCESS
        && type !== SET_AUTH_TOKENS_STATUS_UPDATED
        && type !== SET_AUTH_TOKENS_STATUS_OUTDATED
        && isAuthenticated
      ) {
        onSignout();
      }
    }
    return null;
  };

  /**
   * Get indexes of a route's params
   * @param  {[string]} splittedRoute - route splited by ":"
   * @return {[number]}
   */
  const getParamsIndexes = (splittedRoute) => R.addIndex(R.reduce)(
    (acc, item, index) => {
      if (R.includes(':', item)) {
        acc.push(index);
      }
      return acc;
    },
    [],
    splittedRoute,
  );
  /**
   * Remove params by their indexes
   * @param  {[string]} splittedPathName - pathname splited by ":"
   * @param  {number} indexes - array of params' indexes
   * @return {[string]}
   */
  const removeParams = R.curry(
    (splittedPathName, indexes) => R.addIndex(R.reduce)(
      (acc, indexItem) => R.remove(indexItem, 1, acc),
      splittedPathName,
      indexes,
    ),
  );
  /**
   * compare pathname and route
   * @param  {[string]} splittedPathName  - pathname splited by ":"
   * @param  {string} route - route name
   * @return {boolean]}
   */
  const comparePathnameAndRoute = R.curry(
    (splittedPathName, route) => {
      const splittedRoute = R.split('/')(route);
      // remove params from splittedPathName
      const removedParamsPathname = R.pipe(
        getParamsIndexes,
        removeParams(splittedPathName),
      )(splittedRoute);
      // remove params from splittedRoute
      const removedParamsRoute = R.filter((x) => !R.includes(':', x), splittedRoute);
      return R.equals(removedParamsPathname, removedParamsRoute);
    },
  );
  /**
  * Handle redirect path when user is authenticated
  * @param  {number} userId
  * @param  {string} pathname - location.pathname
  * @return {Null}
  */
  const handleRedirectWhenAuthencatied = R.curry(
    (userId, role, pathname) => {
      const redirectRoute = role === 'ADMIN' ? adminDashboardRoute : dashboardRoute;
      if (pathname === '/') {
        return routerServices.historyPush(
          history,
          {
            pathname: redirectRoute,
            queryLng,
          },
        );
        // history.push({ pathname: dashboardRoute });
      }
    },
  );
  /**
   * Handle redirect to signin and homepage
   * @param  {import('./store/user/typedefs').IsAuthenticated}   isAuthenticated
   * @param  {number}  userId
   * @param  {[string]} excludedRoutes -  exclude routes from being redirected
   * @param  {[string]} otherRoutes -  all other routes
   * @return {Null|Redirect}
   */
  const handleRedirect = (
    pathname,
    isAuthenticated,
    userId,
    role,
    excludedRoutes,
    ...otherRoutes
  ) => {
    const splittedPathName = R.split('/')(pathname);
    const isExluded = R.any(
      (route) => comparePathnameAndRoute(splittedPathName, route),
      excludedRoutes,
    );
    if (isExluded) {
      return null;
    }
    // If user clear browser local storage, the jwt and refresh tokens and relevant data
    // will be removed. So we first check if auth tokens exist in browser local storage.
    if (
      isAuthenticated
      && !doesItemWithKeyExistsInLocalStorage(keyToAuthTokenDataInLocalStorage)
      && REACT_APP_JWT_GUARD === 'ON'
    ) {
      onSignout();
    }
    if (
      isAuthenticated
      && doesItemWithKeyExistsInLocalStorage(keyToAuthTokenDataInLocalStorage)
    ) {
      updateAuthTokenDataWhenNecessary(
        keyToAuthTokenDataInLocalStorage,
        keyToUserDataInLocalStorage,
        keyToAuthStatusInLocalStorage,
      );
    }

    const isValid = R.any(
      (route) => comparePathnameAndRoute(splittedPathName, route),
      otherRoutes,
    );
    if (!isValid) {
      return null;
    }
    return (!isAuthenticated)
      ? routerServices.historyPush(
        history,
        {
          pathname: signinRoute,
          queryLng,
        },
      )
      : handleRedirectWhenAuthencatied(userId, role, pathname);
  };
  // add handleRedirectRef to ref before passing to useEffect to prevent rerender
  const handleRedirectRef = useAddFunctionToRef(handleRedirect);
  useEffect(() => {
    handleRedirectRef(
      pathname,
      isAuthenticated,
      userId,
      role,
      excludedRoutes,
      rootRoute,
      forgotPasswordRoute,
      resetPasswordRoute,
      signinRoute,
      signinMultisigRoute,
      signupRoute,
      accountRoute,
      martketDeployRoute,
      dashboardRoute,
      adminDashboardRoute,
      adminEarningRoute,
      paymentPageRoute,
      referralRoute,
      connectToWalletRoute,
      dashboardRoute,
      myNodeListRoute,
      chainInfoRoute,
      paymentPageRoute,
      nodeInfoRoute,
      referralRoute,
      connectToWalletRoute,
      investmentBaseRoute,
      investmentRoute,
      nodeOwnerRoute,
      nodeOwnerServiceRoute,
      dappStoreRoute,
      walletRoute,
    );
  }, [
    handleRedirectRef,
    pathname,
    isAuthenticated,
    userId,
    role,
  ]);

  // We only start refresh token timer if user is authenticated and has auth token data in
  // browser local storage.
  useInterval(() => {
    const authStatusInLocalStorage = getItem(keyToAuthStatusInLocalStorage);
    if (
      (isAuthenticated || authStatusInLocalStorage)
      && doesItemWithKeyExistsInLocalStorage(keyToAuthTokenDataInLocalStorage)
    ) {
      updateAuthTokenDataWhenNecessary(
        keyToAuthTokenDataInLocalStorage,
        keyToUserDataInLocalStorage,
        keyToAuthStatusInLocalStorage,
      );
    }
    if (
      (isAuthenticated || authStatusInLocalStorage)
      && !doesItemWithKeyExistsInLocalStorage(keyToAuthTokenDataInLocalStorage)
      && REACT_APP_JWT_GUARD === 'ON'
    ) {
      onSignout();
    }
  }, intervalToCheckAuthToken);

  const onSelectSidebarIndexByRoute = useAddFunctionToRef((
    pathname,
  ) => {
    dispatch(navigationOperations.selectSidebarIndexByRoute(pathname));
  });
  useEffect(() => {
    onSelectSidebarIndexByRoute(pathname);
  }, [onSelectSidebarIndexByRoute, pathname]);

  useEffect(() => {
    shouldRenderErrorPageRef(errorToRender);
  }, [shouldRenderErrorPageRef, errorToRender]);

  useEffect(() => {
    // prevent empty email
    // somehow when the local storage is cleared, the app still keep isAuthenticated true
    // TODO investigate this
    if (!email && isAuthenticated) onSignout();
  }, [email, isAuthenticated, onSignout]);
  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        style={{
          marginTop: 50,
          width: isMobileDevice ? '97.5%' : '',
        }}
      />
      <CssBaseline />
      <ScrollToTop />
      <Box className={classes.root}>
        <Navigation />
        <main className={classNames(classes.content)}>
          <Box className={classes.appBarSpacer} />
          {
              (isImpersonatingInSession)
              && (
              <ImpersonationAlert
                email={impersonatedUserInSession.email}
                onStopImpersonation={onStopImpersonation}
              />
              )
            }
          <ErrorAlert />
          <Switch>
            <Route
              path={[signinRoute, signinMultisigRoute]}
              exact
              render={
                        (props) => {
                          ReactGA.pageview(props.location.pathname);
                          return (
                            <Signin
                              {...props}
                            />
                          );
                        }
                }
            />
            <Route
              path={firstSigninRoute}
              exact
              render={
                        (props) => {
                          ReactGA.pageview(props.location.pathname);
                          return (
                            <FirstSignin
                              {...props}
                            />
                          );
                        }
                }
            />
            <Route
              path={signinAdminRoute}
              exact
              render={
                        (props) => (
                          <SigninAdmin
                            {...props}
                          />
                        )

                }
            />
            <Route
              path={signupRoute}
              exact
              render={
                        (props) => {
                          ReactGA.pageview(props.location.pathname);
                          return (
                            <SignupForm
                              {...props}
                            />
                          );
                        }
                }
            />
            <Route
              path={signupAdminRoute}
              exact
              render={
                        (props) => (
                          <SignupAdminForm
                            {...props}
                          />
                        )

                }
            />
            <Route
              path={signupSuccessRoute}
              exact
              render={
                        (props) => {
                          ReactGA.pageview(props.location.pathname);
                          return (
                            <SignupSuccess
                              {...props}
                            />
                          );
                        }
                }
            />
            <Route
              path={accountRoute}
              exact
              render={
                      (props) => {
                        ReactGA.pageview(props.location.pathname);
                        return (
                          <UserInfo
                            {...props}
                          />
                        );
                      }
                }
            />
            <Route
              path={forgotPasswordRoute}
              exact
              render={
                        (props) => {
                          ReactGA.pageview(props.location.pathname);
                          return (
                            <ForgotPassword
                              {...props}
                            />
                          );
                        }
                }
            />
            <Route
              path={resetPasswordRoute}
              exact
              render={
                        (props) => {
                          ReactGA.pageview(props.location.pathname);
                          return (
                            <ResetPassword
                              {...props}
                            />
                          );
                        }
                }
            />
            <Route
              path={martketDeployRoute}
              exact
              render={
                        (props) => {
                          ReactGA.pageview(props.location.pathname);
                          return (
                            <MarketProtocolDeploy
                              {...props}
                            />
                          );
                        }
                }
            />
            <Route
              path={[rootRoute, marketRoute]}
              exact
              render={
                        (props) => {
                          ReactGA.pageview(props.location.pathname);
                          return (
                            <Market
                              {...props}
                            />
                          );
                        }
                }
            />
            <Route
              path={chainInfoRoute}
              exact
              render={
                        (props) => {
                          ReactGA.pageview(props.location.pathname);
                          return (
                            <ChainInfo
                              {...props}
                            />
                          );
                        }
                }
            />
            <Route
              path={paymentPageRoute}
              exact
              render={
                        (props) => {
                          ReactGA.pageview(props.location.pathname);
                          return <PaymentPage />;
                        }
                }
            />
            <Route
              path={dashboardRoute}
              exact
              render={
                        (props) => {
                          ReactGA.pageview(props.location.pathname);
                          return (
                            <Overview
                              {...props}
                            />
                          );
                        }
                }
            />
            <Route
              path={adminDashboardRoute}
              exact
              render={
                        (props) => {
                          ReactGA.pageview(props.location.pathname);
                          return (
                            <AdminDashboard />
                          );
                        }
                }
            />
            <Route
              path={adminOrdersRoute}
              exact
              render={
                        (props) => {
                          ReactGA.pageview(props.location.pathname);
                          return (
                            <Orders />
                          );
                        }
                }
            />
            <Route
              path={adminPoolsRoute}
              exact
              render={
                        (props) => {
                          ReactGA.pageview(props.location.pathname);
                          return (
                            <Pools />
                          );
                        }
                }
            />
            <Route
              path={myNodeListRoute}
              exact
              render={
                        (props) => {
                          ReactGA.pageview(props.location.pathname);
                          return (
                            <MyNodesList
                              {...props}
                            />
                          );
                        }
                }
            />
            <Route
              path={nodeInfoRoute}
              exact
              render={
                        (props) => {
                          ReactGA.pageview(props.location.pathname);
                          return (
                            <MyNodeInfo
                              {...props}
                            />
                          );
                        }
                }
            />
            <Route
              path={activationRoute}
              exact
              render={
                        (props) => {
                          ReactGA.pageview(props.location.pathname);
                          return (
                            <Activation
                              {...props}
                            />
                          );
                        }
                }
            />
            <Route
              path={referralRoute}
              exact
              render={
                        (props) => {
                          ReactGA.pageview(props.location.pathname);
                          return (
                            <Referral
                              {...props}
                            />
                          );
                        }
                }
            />
            <Route
              path={connectToWalletRoute}
              exact
              render={
                        (props) => {
                          ReactGA.pageview(props.location.pathname);
                          return (
                            <ConnectToWallet
                              {...props}
                            />
                          );
                        }
                }
            />
            <Route
              path={investmentRoute}
              exact
              render={
                        (props) => {
                          ReactGA.pageview(props.location.pathname);
                          return (
                            <Investment
                              {...props}
                            />
                          );
                        }
                }
            />
            <Route
              path={[nodeOwnerRoute, nodeOwnerServiceRoute]}
              exact
              render={
                        (props) => (
                          <NodeOwner
                            {...props}
                          />
                        )
                    }
            />
            <Route
              path={forgotPasswordRoute}
              exact
              render={
                      (props) => {
                        ReactGA.pageview(props.location.pathname);
                        return (
                          <ForgotPassword
                            {...props}
                          />
                        );
                      }
              }
            />
            <Route
              path={resetPasswordRoute}
              exact
              render={
                      (props) => {
                        ReactGA.pageview(props.location.pathname);
                        return (
                          <ResetPassword
                            {...props}
                          />
                        );
                      }
              }
            />
            <Route
              path={martketDeployRoute}
              exact
              render={
                      (props) => {
                        ReactGA.pageview(props.location.pathname);
                        return (
                          <MarketProtocolDeploy
                            {...props}
                          />
                        );
                      }
              }
            />
            <Route
              path={[rootRoute, marketRoute]}
              exact
              render={
                      (props) => {
                        ReactGA.pageview(props.location.pathname);
                        return (
                          <Market
                            {...props}
                          />
                        );
                      }
              }
            />
            <Route
              path={chainInfoRoute}
              exact
              render={
                      (props) => {
                        ReactGA.pageview(props.location.pathname);
                        return (
                          <ChainInfo
                            {...props}
                          />
                        );
                      }
              }
            />
            <Route
              path={paymentPageRoute}
              exact
              render={
                      (props) => {
                        ReactGA.pageview(props.location.pathname);
                        return <PaymentPage />;
                      }
              }
            />
            <Route
              path={dashboardRoute}
              exact
              render={
                      (props) => {
                        ReactGA.pageview(props.location.pathname);
                        return (
                          <Overview
                            {...props}
                          />
                        );
                      }
              }
            />
            <Route
              path={adminDashboardRoute}
              exact
              render={
                      (props) => {
                        ReactGA.pageview(props.location.pathname);
                        return (
                          <AdminDashboard
                            {...props}
                          />
                        );
                      }
              }
            />
            <Route
              path={adminEarningRoute}
              exact
              render={
                      (props) => {
                        ReactGA.pageview(props.location.pathname);
                        return (
                          <AdminEarningTable
                            {...props}
                          />
                        );
                      }
              }
            />
            <Route
              path={myNodeListRoute}
              exact
              render={
                      (props) => {
                        ReactGA.pageview(props.location.pathname);
                        return (
                          <MyNodesList
                            {...props}
                          />
                        );
                      }
              }
            />
            <Route
              path={nodeInfoRoute}
              exact
              render={
                      (props) => {
                        ReactGA.pageview(props.location.pathname);
                        return (
                          <MyNodeInfo
                            {...props}
                          />
                        );
                      }
              }
            />
            <Route
              path={activationRoute}
              exact
              render={
                      (props) => {
                        ReactGA.pageview(props.location.pathname);
                        return (
                          <Activation
                            {...props}
                          />
                        );
                      }
              }
            />
            <Route
              path={referralRoute}
              exact
              render={
                      (props) => {
                        ReactGA.pageview(props.location.pathname);
                        return (
                          <Referral
                            {...props}
                          />
                        );
                      }
              }
            />
            <Route
              path={connectToWalletRoute}
              exact
              render={
                      (props) => {
                        ReactGA.pageview(props.location.pathname);
                        return (
                          <ConnectToWallet
                            {...props}
                          />
                        );
                      }
              }
            />
            <Route
              path={investmentRoute}
              exact
              render={
                      (props) => {
                        ReactGA.pageview(props.location.pathname);
                        return (
                          <Investment
                            {...props}
                          />
                        );
                      }
              }
            />
            <Route
              path={[nodeOwnerRoute, nodeOwnerServiceRoute]}
              exact
              render={
                      (props) => (
                        <NodeOwner
                          {...props}
                        />
                      )
                  }
            />
            <Route
              path={dappStoreRoute}
              exact
              render={
                      (props) => (
                        <DAppStore
                          {...props}
                        />
                      )
                  }
            />
            <Route
              path={walletRoute}
              exact
              render={
                      (props) => (
                        <Wallet
                          {...props}
                        />
                      )
                  }
            />

            <Route
              path={notFoundPageRoute}
              exact
              render={
                      (props) => {
                        ReactGA.pageview(props.location.pathname);
                        return (
                          <NotFoundPage
                            {...props}
                          />
                        );
                      }
              }
            />
            <Route
              path={allNotFoundPageRoute}
              exact
              render={
                      (props) => {
                        ReactGA.pageview(props.location.pathname);
                        return (
                          <NotFoundPage
                            {...props}
                          />
                        );
                      }
              }
            />
          </Switch>
          {
            isMobileDevice && <Toolbar />
          }
        </main>
      </Box>
      <Dialog open={isContactFormOpen} onClose={closeContactForm} fullScreen={isMobileDevice}>
        <ContactForm />
      </Dialog>
    </>
  );
};
Route.propTypes = {
  path: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
};
export default App;
