import { web3Services } from '../web3';

const { convertArrayToObject } = require('jsutils');
const R = require('ramda');

const formatDepositData = (nodes) => R.map((item) => {
  const {
    pubkey, txHash, nodeId, nodeName,
  } = item;
  return {
    nodeId,
    nodeName,
    depositStepData: {
      validatorKey: web3Services.prefix0X(pubkey),
      txHash,
    },
  };
}, nodes);

export const mergeDepositKeysAndSavedNodes = R.curry((depositKeys, savedNodes) => {
  const depositKeysObj = convertArrayToObject('pubkey', depositKeys);
  return R.pipe(
    R.map((node) => {
      // HARD CODE eth2ValidatorNode TODO DRY this
      const pubkey = R.view(R.lensPath(['subNode', 'eth2ValidatorNode', 'pubkey']))(node);
      return {
        ...R.pick(['nodeId', 'nodeName'])(node),
        pubkey,
      };
    }),
    R.map((node) => {
      const { pubkey } = node;
      // the pubkey from database have 0x
      const pubkey0x = R.slice(2, pubkey.length, pubkey);
      const depositKeyItem = R.pipe(R.prop(pubkey0x), R.head)(depositKeysObj);
      return R.merge(node, depositKeyItem);
    }),
    formatDepositData,
  )(savedNodes || []);
});
