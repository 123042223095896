import * as R from 'ramda';
import { combineReducers } from 'redux';
import { PURGE } from 'redux-persist';

import {
  createReducer,
  mergeStateAndPayload,
} from '../store_utils';

import * as types from './types';

const affiliate = {
  affiliateId: '',
  datePaid: '',
  dateUpdated: '',
  currency: 'jpy',
  total: 0,
  paid: 0,
  unpaid: 0,
  visitors: 0,
  leads: 0,
  conversions: 0,
  metadata: null,
};
const affiliateIS = affiliate;
const affiliateRUpdaters = {
  [types.GET_AFFLIATE_SUCCESS]: (
    state,
    payload,
  ) => mergeStateAndPayload(state, payload),

  // this reset the state to initial state
  [PURGE]: R.always(affiliateIS),
};

const affiliateR = createReducer(affiliateIS, affiliateRUpdaters);

const referralR = combineReducers({
  affiliate: affiliateR,
});

export default referralR;
