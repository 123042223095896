import { useEffect } from 'react';

import useSmartSetState from './useSmartSetState';

export default function useInitialStateFromProps(initialState, props) {
  const [state, setState] = useSmartSetState(initialState);
  useEffect(() => {
    if (props) setState(props);
  }, [setState, props]);
  return [state, setState];
}
