export const paymentPagePaymentStyles = (theme) => ({
  dialogTitle: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  root: {
    padding: theme.spacing(2, 2),
  },
});

export const paymentPagePaymentDetailStyles = (theme) => ({
  card: {
    oveflow: 'auto',
  },
  table: {
    padding: theme.spacing(2, 2),
  },
});
export const paymentPagePaymentHistoryStyles = (theme) => ({
  root: {
    padding: theme.spacing(2),
  },
  loadingCircle: {
    ...theme.centerComponent,
  },
  customTooltip: {
    backgroundColor: '#f9f9f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    boxShadow: theme.shadows[3],
  },
  customArrow: {
    color: '#f9f9f9',
  },
  menuButton: {
    width: '100%',
  },
  invoiceDetailDialogContent: {
    padding: 0,
    '&:first-child': {
      padding: 0,
    },
  },
  table: {
    padding: theme.spacing(2, 2),
    [theme.breakpoints.down('xs')]: {
      maxWidth: window.innerWidth * 0.95,
      overflow: 'auto',
    },
  },
  tableContainer: {
    [theme.breakpoints.down('xs')]: {
      maxWidth: window.innerWidth * 0.95,
      overflow: 'auto',
    },
  },
});

export const paymentPageSubscriptionStyles = (theme) => ({
  dialogTitle: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },

});

export const paymentPageSubscriptionDetailStyles = (theme) => ({
  card: {
    oveflow: 'auto',
  },
  table: {
    padding: theme.spacing(2, 2),
  },
});
export const paymentPageSubscriptionHistoryStyles = (theme) => ({
  loadingCircle: {
    ...theme.centerComponent,
  },
  customTooltip: {
    backgroundColor: '#f9f9f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    boxShadow: theme.shadows[3],
  },
  customArrow: {
    color: '#f9f9f9',
  },
  menuButton: {
    width: '100%',
  },
  invoiceDetailDialogContent: {
    padding: 0,
    '&:first-child': {
      padding: 0,
    },
  },
  table: {
    padding: theme.spacing(2, 2),
  },
});

export const paymentPageContentStyles = (theme) => ({
  tabList: {
    paddingBottom: theme.spacing(4),
  },
});
