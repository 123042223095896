import * as R from 'ramda';

import { convertToCryptoNumber } from '../../utils/utils';

export const getPropByEarningType = (propName, earningType, data) => R.pipe(
  R.find(R.propEq('type', earningType)),
  R.prop(propName),
)(data);

export const formatDataToSendEmail = (userData) => {
  const {
    selected,
    userId,
    email,
    total,
    startTime,
    endTime,
    subRows,
  } = userData;
  const totalNeunodeEarningFiat = getPropByEarningType('total', 'NEUNODE', subRows) || 0;
  const totalEth2EarningFiat = getPropByEarningType('total', 'ETH2_VALIDATOR_NODE', subRows) || 0;
  const totalEth2Earning = getPropByEarningType('totalOrigin', 'ETH2_VALIDATOR_NODE', subRows) || 0;
  const totalPhalaEarningFiat = getPropByEarningType('total', 'PHALA_MINING_NODE', subRows) || 0;
  const totalPhalaEarning = getPropByEarningType('totalOrigin', 'PHALA_MINING_NODE', subRows) || 0;
  const totalLendingEarningFiat = getPropByEarningType('total', 'LENDING', subRows) || 0;
  const totalLendingEarning = getPropByEarningType('totalOrigin', 'LENDING', subRows) || 0;
  const totalUnUniFiEarningGUU = getPropByEarningType('totalOrigin', 'UNUNIFI_VALIDATOR_NODE', subRows) || 0;
  const ununifiDateCreated = getPropByEarningType('ununifiDateCreated', 'UNUNIFI_VALIDATOR_NODE', subRows) || null;

  if (!total && !totalUnUniFiEarningGUU) {
    return null;
  }

  return {
    selected,
    userId,
    email,
    totalEarningFiat: total.toLocaleString('en-US', {
      style: 'currency',
      currency: 'JPY',
    }),
    totalNeunodeEarningFiat: totalNeunodeEarningFiat.toLocaleString('en-US', {
      style: 'currency',
      currency: 'JPY',
    }),
    totalEth2EarningFiat: totalEth2EarningFiat.toLocaleString('en-US', {
      style: 'currency',
      currency: 'JPY',
    }),
    totalEth2Earning: `${convertToCryptoNumber(totalEth2Earning)} ETH`,
    totalPhalaEarningFiat: totalPhalaEarningFiat.toLocaleString('en-US', {
      style: 'currency',
      currency: 'JPY',
    }),
    totalPhalaEarning: `${convertToCryptoNumber(totalPhalaEarning)} PHA`,
    totalLendingEarningFiat: totalLendingEarningFiat.toLocaleString('en-US', {
      style: 'currency',
      currency: 'JPY',
    }),
    totalLendingEarning: `${convertToCryptoNumber(totalLendingEarning)} BUSD`,
    totalUnUniFiEarningGUU: convertToCryptoNumber(totalUnUniFiEarningGUU),
    startDate: startTime,
    endDate: endTime,
    ununifiDateCreated,
  };
};
