const explorersBaseUrls = {
  1: 'https://etherscan.io',
  5: 'https://goerli.etherscan.io',

};

export const explorersUrls = {
  1: {
    address: `${explorersBaseUrls[1]}/address`,
  },
  5: {
    address: `${explorersBaseUrls[5]}/address`,
  },
};

export const colorSet = {
  READY: '#FF980C',
  PENDING: '#FFDA0C',
  SUCCEEDED: '#C7ED63',
  FAILED: '#EB5135',
  STARTED: '#9BE5D5',
  REJECTED: '#EB5135',
};
