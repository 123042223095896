import Alert from './Alert/Alert';
import Button from './Button/Button';
import Dialog from './Dialog/Dialog';
import Hint from './Hint/Hint';
import RadioButton from './RadioButton/RadioButton';
import Snackbar from './Snackbar/Snackbar';
import TextField from './TextField/TextField';

const { DialogWrapped } = Dialog;

const {
  CloseIconButton,
  ButtonLoading,
  AddIconButton,
  RemoveIconButton,
  HintButton,
  CopyButton,
  RoundedButton,
  RedirectButton,
} = Button;

const { PasswordTextField } = TextField;

export {
  RadioButton,
  Snackbar,
  DialogWrapped,
  Hint,
  CloseIconButton,
  ButtonLoading,
  AddIconButton,
  RemoveIconButton,
  HintButton,
  CopyButton,
  RoundedButton,
  PasswordTextField,
  Alert,
  RedirectButton,
};
