import * as R from 'ramda';

export const selectAffiliatesData = R.curry(
  (state) => {
    const data = R.pipe(
      R.view(
        R.lensPath(['referralR', 'affiliates', 'data']),
      ),
      R.head, // support 1 campaign for now
    )(state);
    return data;
  },
);
