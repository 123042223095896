import Checkbox from '@material-ui/core/Checkbox';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import toNumber from 'lodash/toNumber';
import React from 'react';

const EditableCell = ({
  value: initialValue,
  row: { original },
  column: { id },
  updateData, // This is a custom function that we supplied to our table instance
  earningType,
}) => {
  // We need to keep and update the state of the cell normally
  const [value, setValue] = React.useState(initialValue);
  const onChange = (e) => {
    setValue(e.target.value);
  };

  // We'll only update the external data when the input is blurred
  const onBlur = () => {
    updateData({ ...original, earningType }, toNumber(value));
  };

  // If the initialValue is changed external, sync it up with our state
  React.useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  return (
    <input
      value={value}
      onChange={onChange}
      onBlur={onBlur}
      type="number"
      style={{ width: '100%', maxWidth: 100 }}
    />
  );
};

export const monthlyReportColumns = (
  t,
  selectedEmails,
  addSelectedEmail,
  removeSelectedEmail,
  selectAll,
  removeAll,
  updateData,
  isSelectedAll,
) => ([
  {
    accessor: 'selected',
    Header: (
      <Grid container direction="row" alignItems="center">
        <Checkbox
          checked={isSelectedAll}
          onChange={isSelectedAll ? removeAll : selectAll}
          style={{ padding: 0 }}
        />
      </Grid>
    ),
    width: 55,
    Cell: (data) => {
      const { email, selected } = data.row.original;
      return (
        <Grid container direction="row" alignItems="center">
          <Checkbox
            checked={selected}
            onChange={selected ? () => removeSelectedEmail(email) : () => addSelectedEmail(email)}
          />
        </Grid>
      );
    },
  },
  {
    accessor: 'email',
    Header: t('Email'),
    width: 300,
    Cell: (data) => {
      const { email } = data.row.original;
      return (
        <Grid container direction="row" alignItems="center">
          { email }
        </Grid>
      );
    },
  },
  {
    accessor: 'totalEarningFiat',
    Header: t('Total Earning'),
    width: 150,
    Cell: (data) => {
      const { totalEarningFiat } = data.row.original;
      return (
        <Grid container direction="row" alignItems="center">
          { totalEarningFiat }
        </Grid>
      );
    },
  },
  {
    accessor: 'totalNeunodeEarning',
    Header: t('Neunode'),
    width: 150,
    Cell: (data) => {
      const { totalNeunodeEarningFiat } = data.row.original;
      return (
        <Grid container direction="row" alignItems="center">
          { totalNeunodeEarningFiat }
        </Grid>
      );
    },
  },
  {
    accessor: 'totalEth2Earning',
    Header: t('ETH 2.0'),
    width: 150,
    Cell: (data) => {
      const { totalEth2EarningFiat, totalEth2Earning } = data.row.original;
      return (
        <Grid container direction="row" alignItems="center">
          <Grid item>
            <Typography variant="body2">
              {totalEth2EarningFiat}
            </Typography>
            <Typography variant="body2" color="textSecondary">
              {`≈ ${totalEth2Earning}`}
            </Typography>
          </Grid>
        </Grid>
      );
    },
  },
  {
    accessor: 'totalPhalaEarning',
    Header: t('Phala'),
    width: 150,
    Cell: (data) => {
      const { totalPhalaEarningFiat, totalPhalaEarning } = data.row.original;
      return (
        <Grid container direction="row" alignItems="center">
          <Grid item>
            <Typography variant="body2">
              {totalPhalaEarningFiat}
            </Typography>
            <Typography variant="body2" color="textSecondary">
              {`≈ ${totalPhalaEarning}`}
            </Typography>
          </Grid>
        </Grid>
      );
    },
  },
  {
    accessor: 'totalLendingEarning',
    Header: t('BUSD'),
    width: 150,
    Cell: (data) => {
      const { totalLendingEarning, totalLendingEarningFiat } = data.row.original;
      return (
        <Grid container direction="row" alignItems="center">
          <Grid item>
            <Typography variant="body2">
              {totalLendingEarningFiat}
            </Typography>
            <Typography variant="body2" color="textSecondary">
              {`≈ ${totalLendingEarning}`}
            </Typography>
          </Grid>
        </Grid>
      );
    },
  },
  {
    accessor: 'totalUnUniFiEarningGUU',
    Header: t('UnUniFi'),
    width: 150,
    Cell: (data) => EditableCell({ ...data, updateData, earningType: 'UNUNIFI_VALIDATOR_NODE' }),
  },
]);

export const monthlyReportConfirmColumns = (t) => ([
  {
    accessor: 'email',
    Header: t('Email will be sent to the following users'),
    Cell: (data) => {
      const { email } = data.row.original;
      return (
        <Grid container direction="row" alignItems="center">
          { email }
        </Grid>
      );
    },
  },
]);
