import {
  createReducer,
  mergeStateAndPayload,
} from '../store_utils';

import * as types from './types';

const drawerIS = {
  openDrawer: false,
};

const reducerUpdaters = {
  [types.OPEN_DRAWER]: mergeStateAndPayload,
  [types.CLOSE_DRAWER]: mergeStateAndPayload,
};
const drawerR = createReducer(drawerIS, reducerUpdaters);

export default drawerR;
