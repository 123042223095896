import * as R from 'ramda';
import { combineReducers } from 'redux';
import { PURGE } from 'redux-persist';

import {
  formatNormalizedItemListPayload,
} from '../../../modules/utils';
import {
  createReducer,
  mergeStateAndPayload,
} from '../../store_utils';

import {
  mergeNormalizedOrderListData,
} from './services';
import * as types from './types';

const orderListIS = {
  data: {},
};

const orderListRUpdaters = {
  [types.ADMIN_GET_ORDERS_SUCCESS]: (
    state,
    payload,
  ) => (
    R.pipe(
      formatNormalizedItemListPayload('data'),
      mergeStateAndPayload(state),
    )(payload)
  ),
  [types.UPDATE_ORDER_DATA_SUCCESS]: (
    state,
    payload,
  ) => (
    R.pipe(
      formatNormalizedItemListPayload('data'),
      (formatedNormalizedPayload) => {
        const updatedOrders = R.view(
          R.lensPath(
            ['data', 'entities', 'orders'],
          ), formatedNormalizedPayload,
        );
        const updatedOrderId = R.pipe(
          R.keys,
          R.head,
        )(updatedOrders);
        return R.assocPath(
          ['data', 'entities', 'orders', updatedOrderId],
          updatedOrders[updatedOrderId],
          state,
        );
      },
    )(payload)
  ),
  [types.UPDATE_ORDER_TIMELINES_SUCCESS]: (
    state,
    payload,
  ) => {
    const { data: timelines } = payload;
    const updatedTimelines = R.pipe(
      R.map((timeline) => {
        const { nodeProviderOrderTimelineId } = timeline;
        const formatedTimeline = {};
        formatedTimeline[nodeProviderOrderTimelineId] = timeline;
        return formatedTimeline;
      }),
      R.mergeAll,
    )(timelines);
    const previousTimelines = R.view(R.lensPath(['data', 'entities', 'timelines']), state);
    return R.assocPath(
      ['data', 'entities', 'timelines'],
      R.merge(
        previousTimelines,
        updatedTimelines,
      ),
      state,
    );
  },
  [types.CREATE_ORDERS_SUCCESS]: (
    state,
    payload,
  ) => (
    R.pipe(
      formatNormalizedItemListPayload('data'),
      mergeNormalizedOrderListData(state),
    )(payload)
  ),
  [PURGE]: R.always(orderListIS),
};

const orderListR = createReducer(orderListIS, orderListRUpdaters);

const createdOrdersIS = {
  data: {},
};

const createdOrdersRUpdaters = {
  [types.CREATE_ORDERS_SUCCESS]: (
    state,
    payload,
  ) => formatNormalizedItemListPayload('data', payload),
  [PURGE]: R.always(createdOrdersIS),
};

const createdOrdersR = createReducer(createdOrdersIS, createdOrdersRUpdaters);

const adminOrderR = combineReducers({
  orderList: orderListR,
  createdOrders: createdOrdersR,
});

export default adminOrderR;
