import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import HelpOutlineOutlinedIcon from '@material-ui/icons/HelpOutlineOutlined';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

import { cryptoAddressSelectors } from '../../../store/crypto_address/index';
import { web3Selectors } from '../../../store/web3';
import CustomTooltip from '../../Tooltip/CustomTooltip';
import { convertCryptoToFiat } from '../../utils/utils';
import InvestmentEarningDashboardItem from '../InvestmentNodeDetailsPageItem';

import { miningOverviewStyles } from './styles';

import { RoundedButton } from 'components/MaterialUiCustom/MaterialUiCustom';
import useQuery from 'hooks/useQuery';
import { investmentBaseRoute, walletRoute } from 'routes';
import { selectOwnerRollupRewards } from 'store/pool/selectors';
import { routerServices } from 'store/router';
import { selectNodeOwnerTotalEarning } from 'store/node/selectors';

// store

const useStyles = makeStyles(miningOverviewStyles);

// hardcode chain and subChain. TODO dry
const chain = 'dot';
const subChain = 'pha';

const MiningOverview = ({ orders, nodeType, machineType }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const query = useQuery();
  const queryLng = query.get('lng');
  const history = useHistory();

  const addresses = useSelector((state) => state.cryptoAddressR.addresses);
  const accounts = cryptoAddressSelectors.selectCryptoAddressAccounts(chain, subChain, addresses);
  const address = R.pipe(
    R.head,
    (account) => (account ? R.prop('address', account) : ''),
  )(accounts);

  const totalRollupEarning = useSelector(selectOwnerRollupRewards(machineType));
  const totalPaidOutEarning = useSelector(selectNodeOwnerTotalEarning(nodeType));

  const { free: accountFreeBalance } = useSelector(
    web3Selectors.selectAccountBalance(chain, subChain, address),
  );

  const {
    getOrdersLoading,
    getCoinPriceLoading,
    getUserRollupRewardsLoading,
    getNodeOwnerTotalEarningLoading,
    initializeApiLoading,
    getAccountInfoLoading,
  } = useSelector((state) => state.loadingR);
  const coinPrice = useSelector((state) => state.nodeR.coinPrice);
  const { PHA } = coinPrice;
  const { price, currency, percentChange24h } = PHA || {};
  const createRedirectToAnotherRoute = R.curry((history, queryLng, pathname) => {
    routerServices.historyPush(
      history,
      {
        pathname,
        queryLng,
      },
    );
  });
  const redirectToAnotherRoute = createRedirectToAnotherRoute(history, queryLng);

  const getOverviewItemContent = (text) => (
    <Typography
      variant="body1"
      align="center"
      className={classes.overviewText}
    >
      {text}
    </Typography>
  );

  const transferable = accountFreeBalance || 0;
  // for 4 core we should show only totalRollupEarning + intervalEarning
  const totalEarning = totalPaidOutEarning + totalRollupEarning;

  const currencySymbol = currency || 'JPY';
  const activeNodesCount = R.pipe(
    R.map(
      R.pipe(
        R.prop('devices'),
        R.filter(R.propEq('deviceStatus', 'ACTIVE')),
        (devices) => devices.length,
      ),
    ),
    R.sum,
  )(orders);
  const nodesCount = R.pipe(
    R.map(
      R.pipe(
        R.prop('devices'),
        R.length,
      ),
    ),
    R.sum,
  )(orders);

  const getActiveNodeContent = (value, max) => {
    const percent = (value / max) * 100;
    return (
      <Box className={classes.container}>
        <Box position="relative" display="inline-flex">
          <CircularProgress
            variant="determinate"
            value={100}
            size={100}
            className={classes.dataWithCircleBg}
          />
          <Box
            top={0}
            left={0}
            bottom={0}
            right={0}
            position="absolute"
          >
            <CircularProgress
              variant="determinate"
              value={percent}
              size={100}
              className={classes.dataWithCircle}
            />
          </Box>
          <Box
            top={0}
            left={0}
            bottom={0}
            right={0}
            position="absolute"
            display="flex"
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
          >
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <Typography variant="body1" className={classes.percent}>{`${value}/${max}`}</Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    );
  };
  const getCoinPriceContent = (price, change) => (
    <Box display="flex" justifyContent="center">
      <Box mr={1}>
        <Typography variant="body1" className={classes.overviewText}>
          {price ? price.toLocaleString('en-US', {
            style: 'currency',
            currency: currencySymbol,
            minimumFractionDigits: 3,
          }) : '-'}
        </Typography>
      </Box>
      {change && (
      <Box>
        <Typography variant="body1" className={change >= 0 ? classes.percentTextAsc : classes.percentTextDesc}>
          {change >= 0 ? `+${change.toFixed(2)}%` : `${change.toFixed(2)}%`}
        </Typography>
      </Box>
      )}
    </Box>
  );

  const getTotalorRollupEarningsTitle = (machineType) => {
    if (machineType === 'FOUR_CORE') {
      return t('Rollup Earnings');
    }
    // default six core
    return t('Total Earnings');
  };
  const getTotalEarningSectionContent = (machineType) => {
    if (machineType === 'SIX_CORE') {
      return (
        <Grid container direction="column" alignItems="center">
          <Grid item>
            <Typography
              variant="body1"
              align="center"
              className={classes.totalEarning}
            >
              {`${convertCryptoToFiat('PHA', coinPrice, totalEarning).toLocaleString('en-US', {
                style: 'currency',
                currency: currencySymbol,
              })}`}
            </Typography>
          </Grid>
          <Grid item>
            <Typography
              variant="body1"
              align="center"
              color="textSecondary"
            >
              {`≈ ${totalEarning.toFixed(3)} PHA`}
            </Typography>
          </Grid>
          <Grid item>
            <Paper className={classes.rollupEarnings}>
              <Box pb={1} display="flex" flexWrap="wrap" justifyContent="center">
                <Box pr={1}>
                  <Typography color="textSecondary" className={classes.transferableTitle}>
                    {t('Rollup Earnings')}
                  </Typography>
                </Box>
                <Box>
                  <CustomTooltip
                    title={t('Earnings rolled up to the weekly payout')}
                    content={(
                      <HelpOutlineOutlinedIcon
                        color="disabled"
                        style={{ fontSize: 20 }}
                      />
                )}
                  />
                </Box>
              </Box>
              <Box display="flex" flexWrap="wrap" justifyContent="center">
                <Box pr={0.5}>
                  {convertCryptoToFiat('PHA', coinPrice, totalRollupEarning).toLocaleString('en-US', {
                    style: 'currency',
                    currency: currencySymbol,
                  })}
                </Box>
                <Box>
                  <Typography color="textSecondary">
                    {`≈ ${totalRollupEarning.toFixed(3)} PHA`}
                  </Typography>
                </Box>
              </Box>
            </Paper>
          </Grid>
        </Grid>
      );
    } if (machineType === 'FOUR_CORE') {
      return (
        <Grid container direction="column" alignItems="center">
          <Grid item>
            <Typography
              variant="body1"
              align="center"
              className={classes.totalEarning}
            >
              {`${convertCryptoToFiat('PHA', coinPrice, totalRollupEarning).toLocaleString('en-US', {
                style: 'currency',
                currency: currencySymbol,
              })}`}
            </Typography>
          </Grid>
          <Grid item>
            <Typography
              variant="body1"
              align="center"
              color="textSecondary"
            >
              {`≈ ${totalRollupEarning.toFixed(3)} PHA`}
            </Typography>
          </Grid>
          {/* <Grid item>
            <Paper className={classes.rollupEarnings}>
              <Box pb={1} display="flex" flexWrap="wrap" justifyContent="center">
                <Box pr={1}>
                  <Typography color="textSecondary" className={classes.transferableTitle}>
                    {t('Rollup Earnings')}
                  </Typography>
                </Box>
                <Box>
                  <CustomTooltip
                    title={t('Earnings rolled up to the weekly payout')}
                    content={(
                      <HelpOutlineOutlinedIcon
                        color="disabled"
                        style={{ fontSize: 20 }}
                      />
            )}
                  />
                </Box>
              </Box>
              <Box display="flex" flexWrap="wrap" justifyContent="center">
                <Box pr={0.5}>
                  {convertCryptoToFiat('PHA', coinPrice, totalRollupEarning).toLocaleString('en-US', {
                    style: 'currency',
                    currency: currencySymbol,
                  })}
                </Box>
                <Box>
                  <Typography color="textSecondary">
                    {`≈ ${totalRollupEarning.toFixed(3)} PHA`}
                  </Typography>
                </Box>
              </Box>
            </Paper>
          </Grid> */}
        </Grid>
      );
    }
  };

  const getTransferrableSectionContent = (machineType) => {
    if (machineType === 'FOUR_CORE') {
      return (
        <Grid container direction="column" alignItems="center">
          <Grid item>
            <Typography
              variant="body1"
              align="center"
            >
              { t('View on 6-core machines mining page')}
            </Typography>
          </Grid>
          <Grid item>
            <RoundedButton
              variant="contained"
              color="primary"
              onClick={() => redirectToAnotherRoute(`${investmentBaseRoute}/mining`)}
            >
              {t('view')}
            </RoundedButton>
          </Grid>

        </Grid>
      );
    }

    return (
      <Grid container direction="column" alignItems="center">
        <Grid item>
          <Typography
            variant="body1"
            align="center"
            className={classes.totalEarning}
          >
            {`${convertCryptoToFiat('PHA', coinPrice, transferable).toLocaleString('en-US', {
              style: 'currency',
              currency: currencySymbol,
            })}`}
          </Typography>
        </Grid>
        <Grid item>
          <Typography
            variant="body1"
            align="center"
            color="textSecondary"
          >
            {`≈ ${transferable.toFixed(3)} PHA`}
          </Typography>
        </Grid>
        <Grid item>
          <RoundedButton
            variant="contained"
            color="primary"
            onClick={() => redirectToAnotherRoute(walletRoute)}
            disabled={transferable <= 0}
          >
            {t('transfer')}
          </RoundedButton>
        </Grid>
      </Grid>
    );
  };
  return (

    <Grid container alignItems="stretch" spacing={2}>
      <Grid item xs={6} sm={6} md={6} lg={3}>
        <InvestmentEarningDashboardItem
          align="center"
          title={t('Active Node')}
          content={getActiveNodeContent(activeNodesCount, nodesCount)}
          loading={getCoinPriceLoading || getOrdersLoading}
          loadingVariant="circle"
        />
      </Grid>
      <Grid item xs={6} sm={6} md={6} lg={3}>
        <Grid container style={{ height: '100%', margin: 0 }}>
          <Grid item xs={12} style={{ marginBottom: '4px' }}>
            <InvestmentEarningDashboardItem
              align="center"
              title={t('PHA Price')}
              content={getCoinPriceContent(price, percentChange24h)}
              loading={getCoinPriceLoading || getOrdersLoading}
            />
          </Grid>
          <Grid item xs={12}>
            <InvestmentEarningDashboardItem
              align="center"
              title={t('Est. ROI')}
              content={getOverviewItemContent('-')}
              loading={getOrdersLoading}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} sm={6} md={6} lg={3}>
        <InvestmentEarningDashboardItem
          align="center"
          showSecondLoadingLine
          titlePadding="21px 0px 8px 0px"
          title={(
            <Box display="flex" justifyContent="center" alignItems="center">
              <Box pr={1}>
                <Typography
                  variant="body1"
                  className={classes.transferableTitle}
                >
                  {getTotalorRollupEarningsTitle(machineType)}
                </Typography>
              </Box>
              <Box>
                <CustomTooltip
                  title={t('Total of paid out earnings and rollup earings')}
                  content={(
                    <HelpOutlineOutlinedIcon
                      color="disabled"
                      style={{ fontSize: 20 }}
                    />
                  )}
                />
              </Box>
            </Box>
          )}
          content={getTotalEarningSectionContent(machineType)}
          loading={getCoinPriceLoading
            || initializeApiLoading
            || getAccountInfoLoading
            || getUserRollupRewardsLoading
            || getNodeOwnerTotalEarningLoading
          }
        />
      </Grid>
      <Grid item xs={12} sm={6} md={6} lg={3}>
        <InvestmentEarningDashboardItem
          align="center"
          showSecondLoadingLine
          titlePadding="21px 0px 8px 0px"
          title={(
            <Box display="flex" justifyContent="center" alignItems="center">
              <Box pr={1}>
                <Typography
                  variant="body1"
                  className={classes.transferableTitle}
                >
                  {t('Transferable')}
                </Typography>
              </Box>
              {false && (
              <Box>
                <CustomTooltip
                  title={t('Transfering has not been enabled on PHALA mainnet')}
                  content={(
                    <HelpOutlineOutlinedIcon
                      color="disabled"
                      style={{ fontSize: 20 }}
                    />
                  )}
                />
              </Box>
              )}
            </Box>
          )}
          content={getTransferrableSectionContent(machineType)}
          loading={getCoinPriceLoading || initializeApiLoading || getAccountInfoLoading}
        />
      </Grid>
    </Grid>
  );
};

MiningOverview.defaultProps = {
};

MiningOverview.propTypes = {
  machineType: PropTypes.oneOf(['FOUR_CORE', 'SIX_CORE']).isRequired,
  nodeType: PropTypes.oneOf(['PHALA_MINING_NODE', 'PHALA_MINING_NODE_4_CORES']).isRequired,
};

export default MiningOverview;
