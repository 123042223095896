import PropTypes from 'prop-types';
import React from 'react';

import SearchBar from '../SearchBar/SearchBar';

const GlobalFilter = ({
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
}) => {
  const count = preGlobalFilteredRows.length;

  // Global filter only works with pagination from the first page.
  // This may not be a problem for server side pagination when
  // only the current page is downloaded.
  const inputBaseProps = {
    value: globalFilter || '',
    onChange: (e) => {
      setGlobalFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
    },
    placeholder: `${count} records...`,
    inputProps: { 'aria-label': 'search' },
  };
  return (
    <SearchBar
      inputBaseProps={inputBaseProps}
    />
  );
};

GlobalFilter.defaultProps = {
  globalFilter: '',
};

GlobalFilter.propTypes = {
  preGlobalFilteredRows: PropTypes.array.isRequired,
  globalFilter: PropTypes.string,
  setGlobalFilter: PropTypes.func.isRequired,
};

export default GlobalFilter;
