export const stylesUsageItem = (theme) => ({
  container: {
    minWidth: '150px',
  },
  dataWithCircleBg: {
    color: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
  },
  dataWithCircle: {
    strokeLinecap: 'round',
    color: theme.nodeStatus.active,
  },
  value: {
    fontSize: 45,
  },
  unit: {
    fontSize: 15,
  },
  title: {
    fontSize: 15,
  },
});

export const stylesDescription = (theme) => ({
  descriptionContent: {
    margin: theme.spacing(2),
  },
  dialog: {
    maxWidth: 'sm',
  },
  circularGrid: {
    ...theme.centerComponent,
  },
  description: {
    fontSize: theme.typography.h2.fontSize,
    padding: theme.spacing(2, 0),
  },
});
