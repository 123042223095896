import { Buffer } from 'buffer';

import { fromSeed } from 'bip32';
import { mnemonicToSeedSync, generateMnemonic } from 'bip39';
import Promise from 'bluebird';
import { pipeAwait, sleep } from 'jsutils';
import isEmpty from 'lodash/isEmpty';
import isString from 'lodash/isString';
import * as R from 'ramda';

import { formatParameterValidationError } from '../../modules/utils';

/**
 * Generate 12-word secret phrase
 * TODO: add 24 words option.
 * @return {import('./typedefs').SecretPhrase}
 */
export const generateSecretPhrase = () => {
  const secretPhrase = generateMnemonic();
  if (!isString(secretPhrase)) {
    throw formatParameterValidationError('Generate secret phrase false, expected a string');
  }
  const isSecretPhraseLength = R.pipe(
    R.split(' '),
    R.length,
  )(secretPhrase);
  const isSecretPhraseLengthValid = isSecretPhraseLength === 12;
  if (!isSecretPhraseLengthValid) {
    throw formatParameterValidationError(
      'Generate secret phrase false, secret phrase length is invalid',
    );
  }
  return secretPhrase;
};
/**
 * check if secret phrase is valid
 * conditions:
 * 1 - is a string
 * 2 - is not an empty string (isEmpty return false for nil type also
 * @param  {import('./typedefs').SecretPhrase} secretPhrase
 * @return {boolean}
 */
const checkIfSecretPhraseValid = R.curry(
  (secretPhrase) => R.and(
    isString(secretPhrase),
    !isEmpty(secretPhrase),
  ),
);
const generateSeedBuffer = (secretPhrase) => (
  R.pipe(
    mnemonicToSeedSync,
    (seedBuffer) => {
      if (Buffer.isBuffer(seedBuffer)) {
        return seedBuffer;
      }
      throw formatParameterValidationError('Seed buffer invalid');
    },
  )(secretPhrase)
);
const seedToHdNode = (seedBuffer) => fromSeed(seedBuffer);

/**
 * Generate HdNode
 * @param {import('./typedefs').SecretPhrase} secretPhrase
 * @return {import('./typedefs').HdNodeResult}
 */
export const generateHdNode = (secretPhrase) => {
  if (!checkIfSecretPhraseValid(secretPhrase)) {
    throw formatParameterValidationError('Secret phrase invalid');
  }
  // trim space from both ends
  const secretPhraseToUse = secretPhrase.trim();
  return R.pipe(
    generateSeedBuffer,
    seedToHdNode,
  )(secretPhraseToUse);
  // return hdNode;
};
/**
 * Genreate a private key from seed
 * @param  {import('./typedefs').HdNode} hdNode
 * @param  {number} index - wallet index
 * @return {string}
 */
export const generatekeyPairFromSeed = R.curry(
  (hdNode, index) => ({
    publicKey: hdNode.derive(index).publicKey.toString('hex'),
    privateKey: hdNode.derive(index).privateKey.toString('hex'),
    walletIndex: index,
  }),
);

/**
 * Derive private keys from HdNode
 * @param {import('./typedefs').StartIndex} startIndex
 * @param {import('./typedefs').EndIndex} endIndex
 * @param {import('./typedefs').HdNodeResult} hdNodeResult - result from generateHdNode
 * @return {import('./typedefs').PrivateKeyResult} privateKeyResult
 */

export const deriveKeyPairsFromHdNode = R.curry(
  async (
    {
      startIndex,
      endIndex,
    },
    hdNode,
  ) => {
    if (isEmpty(hdNode)) {
      throw formatParameterValidationError('hdNode must not be empty');
    }
    if (startIndex > endIndex) {
      throw formatParameterValidationError('startIndex must be smaller than endIndex');
    }
    // make a tempArray to loop through
    const tempArray = R.range(startIndex, endIndex + 1);
    const privateKeys = await Promise.map(tempArray, async (indexValue) => {
      await sleep(200);// prevent browser from frozen
      return generatekeyPairFromSeed(hdNode)(indexValue);
    });
    return privateKeys;
  },
);
/**
 * Gererate an array of private keys from a secret phrase
 * @param {import('./typedefs').KeyPairRange} keyPairRange
 * @return {import('./typedefs').PrivateKeyResult}
 */
export const generateKeyPairsFromSecretPhrase = R.curry(
  async (
    secretPhrase,
    {
      startIndex,
      endIndex,
    },
  ) => pipeAwait(
    generateHdNode,
    deriveKeyPairsFromHdNode({ startIndex, endIndex }),
  )(secretPhrase),
);
