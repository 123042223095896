import Box from '@material-ui/core/Box';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Tooltip from '@material-ui/core/Tooltip';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

import { useIsMobile } from '../../hooks/hooks';

import { styles } from './styles';

const useStyles = makeStyles(styles);

const CustomTooltip = (props) => {
  const classes = useStyles();
  const isMobile = useIsMobile();
  const { title, content } = props;

  const [openTooltip, setOpenTooltip] = useState(false);

  const handleTooltipClose = () => {
    setOpenTooltip(false);
  };
  const handleTooltipOpen = () => {
    setOpenTooltip(true);
  };
  const getTooltipContent = (title, content) => (
    <ClickAwayListener onClickAway={handleTooltipClose}>
      <Tooltip
        placement="right-start"
        interactive
        open={openTooltip}
        disableFocusListener
        disableHoverListener
        disableTouchListener
        title={title}
      >
        <Box
          onClick={isMobile ? handleTooltipOpen : null}
          onMouseEnter={isMobile ? null : handleTooltipOpen}
          onMouseLeave={isMobile ? null : handleTooltipClose}
          className={classes.tooltipContent}
        >
          {content}
        </Box>
      </Tooltip>
    </ClickAwayListener>
  );

  return getTooltipContent(title, content);
};

CustomTooltip.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.object.isRequired,
};

export default CustomTooltip;
