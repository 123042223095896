import CircularProgress from '@material-ui/core/CircularProgress';
import isFunction from 'lodash/isFunction';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import useDeepCompareEffect from 'use-deep-compare-effect';

import { useAddFunctionToRef } from '../../hooks/hooks';
import { adminUtils } from '../../store/admin/index';
import { stripeOperations } from '../../store/stripe/index';

import PaymentStripeCardList from './PaymentStripeCardList';
import PaymentStripeCheckoutForm from './PaymentStripeCheckoutForm';

// hook

// store

// only support card for now
const paymentMethodType = 'card';
const PaymentStripe = (props) => {
  const {
    btnText,
    onSelectStripePaymentMethod,
    onRenderCardForm,
    enableCoupon,
  } = props;
  // const stripe = useStripe();
  const {
    isImpersonating,
  } = adminUtils.getImpersonationDataFromSessionStorage();
  const { userId } = useSelector((state) => state.userR.user);
  const { stripePaymentMethods, stripeCustomer } = useSelector((state) => state.stripeR);
  const { getUserStripePaymentMethodsLoading } = useSelector((state) => state.loadingR);
  const [renderCardList, setRenderCardList] = React.useState(false);
  const [reGet, setReGet] = React.useReducer((x) => x + 1, 1);
  const {
    stripeCustomerId,
  } = stripeCustomer;
  const dispatch = useDispatch();
  const onGetUserStripePaymentMethod = useAddFunctionToRef(
    async (userId, paymentMethodType) => {
      if (!isImpersonating) {
        await dispatch(
          stripeOperations.getUserStripePaymentMethods(
            paymentMethodType,
            userId,
          ),
        );
      }
    },
  );
  const onDetachStripePaymentMethod = R.curry(
    async (
      paymentMethodType,
      userId,
      stripePaymentMethodId,
    ) => dispatch(stripeOperations.detachStripePaymentMethod(
      paymentMethodType,
      userId,
      stripePaymentMethodId,
    )),
  );
  const handleOnSubmitExtraInForm = async () => {
    await onGetUserStripePaymentMethod(userId, paymentMethodType);
  };
  React.useEffect(() => {
    const handleAsync = async () => {
      if (reGet) {
        await onGetUserStripePaymentMethod(userId, paymentMethodType);
      }
    };
    handleAsync();
  }, [onGetUserStripePaymentMethod, userId, reGet]);

  useDeepCompareEffect(() => {
    if (stripePaymentMethods.data.length !== 0) {
      setRenderCardList(true);
    }
    if (stripePaymentMethods.data.length === 0) {
      setRenderCardList(false);
      if (isFunction(onRenderCardForm)) onRenderCardForm();
    }
  }, [stripePaymentMethods]);

  const onGetUserStripeData = useAddFunctionToRef(
    async (userId) => {
      await dispatch(
        stripeOperations.getUserStripeData(
          userId,
        ),
      );
    },
  );

  React.useEffect(() => {
    const handleAsync = async () => {
      await onGetUserStripeData(userId);
    };
    handleAsync();
  }, [
    onGetUserStripeData,
    userId,
  ]);
  const getContent = () => {
    if (getUserStripePaymentMethodsLoading) return <CircularProgress />;
    return (
      !renderCardList
        ? (
          <PaymentStripeCheckoutForm
            btnText={btnText}
            stripeCustomerId={stripeCustomerId}
            onSubmitExtra={handleOnSubmitExtraInForm}
            enableCoupon={enableCoupon}
          />
        )
        : (
          <PaymentStripeCardList
            paymentMethods={stripePaymentMethods.data}
            onSelectStripePaymentMethod={onSelectStripePaymentMethod}
            onGetUserStripePaymentMethod={onGetUserStripePaymentMethod}
            onDetachStripePaymentMethod={onDetachStripePaymentMethod}
            setReGet={setReGet}
            enableCoupon={enableCoupon}
          />
        )
    );
  };

  return getContent();
};

PaymentStripe.defaultProps = {
  enableCoupon: false,
};

PaymentStripe.propTypes = {
  /**
   * text displayed in action button
   */
  btnText: PropTypes.string.isRequired,
  enableCoupon: PropTypes.bool,
  onSelectStripePaymentMethod: PropTypes.func.isRequired,
  onRenderCardForm: PropTypes.func.isRequired,
};

export default PaymentStripe;
