import * as types from './types';

/**
 * Set authenticate redirect url
 *
 * @param      {string}  url
 * @return     {import('../typedefs').ReduxAction}
 */
export const setAuthenticateRedirectPathName = (pathName) => ({
  type: types.SET_AUTHENTICATE_REDIRECT_PATH_NAME,
  payload: { pathName },
});
