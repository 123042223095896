import gql from 'graphql-tag';

export const getUserPoolsQuery = gql`
  query getUserPools(
    $getUserPoolsInput: GetUserPoolsInput!
  )
  {
    getUserPools(
      getUserPoolsInput: $getUserPoolsInput
    ) {
      items {
        pid
        userId
        ownerReward
      }
      meta {
        totalItems
        itemCount
        itemsPerPage
        totalPages
        currentPage
      }
    }
  }
`;

export const getUserRollupRewardsQuery = gql`
  query getUserRollupRewards(
    $getUserRollupRewardsInput: GetUserRollupRewardsInput!
  )
  {
    getUserRollupRewards(
      getUserRollupRewardsInput: $getUserRollupRewardsInput
    ) {
      ownerRollupRewards
      payoutAddress
      machineType
    }
  }
`;

export const getPoolsQuery = gql`
query getPools(
  $getPoolsInput: GetPoolsInput!
) {
    getPools(
      getPoolsInput: $getPoolsInput
    ) {
      items{
        poolId
        pid
        active
        dateCreated
        dateUpdated
        user {
          userId
          email
        }
        poolData {
          poolDataId
          poolId
          pid
          userId
          commission
          machineNum
          activeMachineNum
          unitCap
          poolCreated
          commissionSet
          capSet
          payoutAddress
          dateCreated
          dateUpdated
        }
      }
      meta{
        totalItems
        itemCount
        itemsPerPage
        totalPages
        currentPage
      }
    }
  }
`;
