import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';

import { useAddFunctionToRef } from '../../hooks/hooks';
import { errorServices, errorOperations } from '../../store/error/index';
import { paymentOperations } from '../../store/payment/index';
import { stripeOperations } from '../../store/stripe/index';
import { VALIDATE_PROMOTION_CODE_SUCCESS } from '../../store/stripe/types';
import ErrorAlert from '../Error/ErrorAlert';
import ErrorMessage from '../ErrorMessage/ErrorMessage';
import { RoundedButton } from '../MaterialUiCustom/MaterialUiCustom';

import { styles } from './styles';

const { extractErrorMessage } = errorServices;
const useStyles = makeStyles(styles);

const { REACT_APP_STRIPE_60DAYS_PROMOTION_CODE } = process.env;

const CouponDialog = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { onClose, open } = props;
  const { t } = useTranslation();
  const errorR = useSelector((state) => state.errorR);
  const { validatePromotionCodeError } = errorR || {};
  const message = extractErrorMessage(validatePromotionCodeError);
  const [promotionCodeInput, setPromotionCodeInput] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState(false);

  const onDismount = useAddFunctionToRef(() => {
    dispatch(errorOperations.clearError('validatePromotionCode'));
    // dispatch(errorOperations.clearErrorToRender());
  });

  useEffect(() => {
    setErrorMessage(message);
  }, [message]);

  useEffect(() => onDismount, [onDismount]);

  const handleClose = () => {
    onClose();
  };

  const updatePromotionCodeInput = (event) => {
    event.persist();
    const newPromotionCodeInput = R.view(R.lensPath(['target', 'value']), event);
    setPromotionCodeInput(newPromotionCodeInput);
  };

  const onUpdatePromotionCode = async (promotionCode) => {
    await dispatch(
      paymentOperations.updateStripePromotionCode(promotionCode),
    );
    handleClose();
  };

  const handleSetCoupon = async () => {
    setLoading(true);
    const isTrialDaysCoupon = promotionCodeInput === REACT_APP_STRIPE_60DAYS_PROMOTION_CODE;

    if (isTrialDaysCoupon) {
      await onUpdatePromotionCode(promotionCodeInput);
      setLoading(false);
      return;
    }

    const { type } = await dispatch(
      stripeOperations.validatePromotionCode(promotionCodeInput),
    );

    if (type === VALIDATE_PROMOTION_CODE_SUCCESS) {
      await onUpdatePromotionCode(promotionCodeInput);
    }
    setLoading(false);
  };

  return (
    <Dialog onClose={handleClose} open={open} fullWidth>
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        className={classes.couponDialog}
      >
        <ErrorAlert />
        <Grid item>
          <DialogTitle id="simple-dialog-title">{t('Please enter your coupon')}</DialogTitle>
        </Grid>
        <Grid item className={classes.textFieldContainer}>
          <TextField
            name="promotionCode"
            type="text"
            label={t('Coupon code')}
            variant="outlined"
            className={classes.textField}
            onChange={updatePromotionCodeInput}
          />
        </Grid>
        <Grid item>
          <ErrorMessage errorMessage={errorMessage} />
        </Grid>
        <Grid item>
          <RoundedButton
            variant="contained"
            color="primary"
            type="submit"
            disabled={!promotionCodeInput || loading}
            className={classNames(classes.button)}
            onClick={() => handleSetCoupon(promotionCodeInput)}
          >
            {t('Confirm')}
          </RoundedButton>
        </Grid>
      </Grid>
    </Dialog>
  );
};

CouponDialog.propTypes = {
  /**
   * Close handle function
   */
  onClose: PropTypes.func.isRequired,
  /**
   * Show dialog if true, hide if false
   */
  open: PropTypes.bool.isRequired,
};

export default CouponDialog;
