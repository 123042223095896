import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

import ReferralDataSection from './ReferralDataSection';

const ReferralReferals = (props) => {
  const {
    visitors,
    leads,
    conversions,
  } = props;
  const { t } = useTranslation();
  const explanations = {
    visitors: t('A Visitor is when someone clicks your affiliate link and lands on our site.'),
    leads: t('A Lead happen as soon as someone sign up for an account from your affiliate link.'),
    conversions: t('A Conversion is when your friends successfully deploy a node. Note that Conversions are only counted after the free trial period.'),
  };
  const data = {
    visitors: {
      title: t('visitors'),
      value: visitors,
    },
    leads: {
      title: t('leads'),
      value: leads,
    },
    conversions: {
      title: t('conversions'),
      value: conversions,
    },
  };
  return (
    <ReferralDataSection data={data} explanations={explanations} />
  );
};

ReferralReferals.defaultProps = {
  visitors: 0,
  leads: 0,
  conversions: 0,
};

ReferralReferals.propTypes = {
  visitors: PropTypes.number,
  leads: PropTypes.number,
  conversions: PropTypes.number,
};

export default ReferralReferals;
