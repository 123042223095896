import {
  createReducerUpdaterWithScope,
  createReducer,
  updateStateWithKey,
} from '../store_utils';

import * as types from './types';

const errorIS = {
  nodesEarningError: '',
  errorToRender: {},
};

const reducerUpdaters = {
  [types.SET_ERROR]: createReducerUpdaterWithScope('Error'),
  [types.CLEAR_ERROR]: createReducerUpdaterWithScope('Error'),
  [types.SET_ERROR_TO_RENDER]: (state, payload) => (
    updateStateWithKey('errorToRender', state, payload)
  ),
  [types.CLEAR_ERROR_TO_RENDER]: (state, payload) => (
    updateStateWithKey('errorToRender', state, payload)
  ),
};
const errorR = createReducer(errorIS, reducerUpdaters);

export default errorR;
