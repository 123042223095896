import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import {
  toNumber,
} from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

// routes

// others

import { stylesUsageItem } from './styles';

const useStyles = makeStyles(stylesUsageItem);

const MyNodeInfoUsageItemCircular = (props) => {
  const classes = useStyles();
  const { value, title, unit } = props;

  const valueToRender = (!value || value === 'NaN') ? '0' : value;
  return (
    <Box className={classNames(classes.container)} position="relative" display="inline-flex">
      <CircularProgress
        variant="determinate"
        value={100}
        size={150}
        className={classNames(classes.dataWithCircleBg)}
      />
      <Box
        top={0}
        left={0}
        bottom={0}
        right={0}
        position="absolute"
      >
        <CircularProgress
          variant="determinate"
          value={valueToRender === '0' ? 0 : toNumber(value)}
          size={150}
          className={classNames(classes.dataWithCircle)}
        />
      </Box>
      <Box
        top={0}
        left={0}
        bottom={0}
        right={0}
        position="absolute"
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
      >
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <Typography className={classNames(classes.value)}>{valueToRender}</Typography>
          <Typography className={classNames(classes.unit)}>{unit}</Typography>
        </Box>
        <Box>
          <Typography className={classNames(classes.title)}>{title}</Typography>
        </Box>
      </Box>
    </Box>
  );
};

MyNodeInfoUsageItemCircular.defaultProps = {
  value: '',
  title: '',
  unit: '',
};

MyNodeInfoUsageItemCircular.propTypes = {
  value: PropTypes.string,
  title: PropTypes.string,
  unit: PropTypes.string,
};

export default MyNodeInfoUsageItemCircular;
