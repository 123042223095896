// mutation
import {
  createQuery,
  createMutation,
} from '../../../modules/apollo/apollo';

import {
  updateUserNodeMutation,
  updateUserNodesMutation,
} from './mutation';
// query
import {
  getUserNodesQuery,
  getUserNodeQuery,
  getUserNodeEarningQuery,
  getNodesEstimateActivationDateQuery,
  getNodesOnChainStatusQuery,
  getTotalEarningQuery,
  getTotalEarningInPeriodQuery,
  getIntervalEarningQuery,
  getCoinPriceQuery,
  getCoinPriceChartQuery,
  getZenBalanceQuery,
  getUserEarningHistoryQuery,
  getTotalEarningInfluxdbQuery,
  getIntervalFixedEarningQuery,
  getTotalFixedEarningQuery,
  getNodeOwnerTotalEarningQuery,
} from './queries';

// apollo

export const graphqlGetUserNodes = createQuery(getUserNodesQuery);
export const graphqlGetUserNode = createQuery(getUserNodeQuery);
export const graphqlGetUserNodeData = createQuery(getUserNodeEarningQuery);
export const graphqlGetNodesEstimateActivationDate = createQuery(
  getNodesEstimateActivationDateQuery,
);
export const graphqlGetNodesOnChainStatus = createQuery(getNodesOnChainStatusQuery);
export const graphqlGetTotalEarning = createQuery(getTotalEarningQuery);
export const graphqlGetTotalEarningInPeriod = createQuery(getTotalEarningInPeriodQuery);
export const graphqlGetIntervalEarning = createQuery(getIntervalEarningQuery);
export const graphqlGetUserEarningHistory = createQuery(getUserEarningHistoryQuery);
export const graphqlGetCoinPrice = createQuery(getCoinPriceQuery);
export const graphqlGetCoinPriceChart = createQuery(getCoinPriceChartQuery);
export const graphqlGetZenBalance = createQuery(getZenBalanceQuery);
export const graphqlGetTotalEarningInfluxdb = createQuery(getTotalEarningInfluxdbQuery);
export const graphqlGetIntervalFixedEarning = createQuery(getIntervalFixedEarningQuery);
export const graphqlGetTotalFixedEarning = createQuery(getTotalFixedEarningQuery);
export const graphqlGetNodeOwnerTotalEarning = createQuery(getNodeOwnerTotalEarningQuery);

export const graphalUpdateUserNode = createMutation(updateUserNodeMutation);
export const graphalUpdateUserNodes = createMutation(updateUserNodesMutation);
