import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

// store
import { isInfluxdbError } from '../../../store/error/services';
import Alert from '../../MaterialUiCustom/Alert/Alert';

import BUSDEarningHistory from './BUSDEarningHistory';
import BUSDOverview from './BUSDOverview';

const { REACT_APP_LENDING_MAINTENANCE } = process.env;
const BUSDContent = () => {
  const { t } = useTranslation();

  const getIntervalFixedEarningError = useSelector((state) => state.errorR.getIntervalFixedEarningError);
  const influxdbErrorExists = isInfluxdbError(getIntervalFixedEarningError || {});

  return (
    <Grid container justifyContent="center" spacing={4}>
      {REACT_APP_LENDING_MAINTENANCE === 'ON' && (
        <Grid item xs={12}>
          <Alert
            severity="warning"
          >
            <Typography variant="body2" color="inherit">
              {t('Lending earning is under maintenance. Your earning is safe.')}
            </Typography>
          </Alert>
        </Grid>
      )}
      <Grid item xs={12}>
        {influxdbErrorExists && (
          <Alert
            severity="error"
          >
            <Typography variant="body2" color="inherit">
              {t('Our server is busy. Please try again later.')}
            </Typography>
          </Alert>
        )}
      </Grid>
      <Grid item xs={12}>
        <BUSDOverview />
      </Grid>
      <Grid item xs={12}>
        <BUSDEarningHistory />
      </Grid>
    </Grid>
  );
};

export default BUSDContent;
