// import * as R from 'ramda';

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React from 'react';

import EarningItem from '../EarningItem/EarningItem';

import { amountCardItemStyles } from './styles';

const useStyles = makeStyles(amountCardItemStyles);

const AmountCardItem = (props) => {
  const classes = useStyles();
  const {
    data,
    loading,
    titleTextColor,
    earningTextColor,
    earningExchangedTextColor,
  } = props;

  const {
    title,
    titleFontSize,
    value,
    valueFontSize,
    valueExchanged,
    className,
  } = data;

  return (
    <EarningItem
      disableBackground
      align="left"
      title={title}
      titleFontSize={titleFontSize}
      valueFontSize={valueFontSize}
      titleTextColor={titleTextColor}
      content={(
        <Grid container alignItems="flex-end">
          <Grid item xs={12} sm={12} md={12} lg="auto" className={valueExchanged ? classes.haveExchangedValue : classes.value}>
            <Box

              className={className}
              style={{ fontSize: valueFontSize, color: earningTextColor }}
            >
              {value}
            </Box>
          </Grid>
          {valueExchanged && (
          <Grid item xs={12} sm={12} md={12} lg="auto" className={classes.exchangedText}>
            <Typography
              variant="body1"
              align="left"
              color="textSecondary"
              style={{ color: earningExchangedTextColor }}
            >
              {valueExchanged}
            </Typography>
          </Grid>
          )}
        </Grid>
      )}
      loading={loading}
    />
  );
};

AmountCardItem.defaultProps = {
  className: '',
  loading: false,
  earningTextColor: '#000000',
  titleTextColor: '',
  earningExchangedTextColor: '',
};

AmountCardItem.propTypes = {
  data: PropTypes.shape({
    title: PropTypes.string,
    titleFontSize: PropTypes.number,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    valueFontSize: PropTypes.number,
    valueExchanged: PropTypes.string,
    className: PropTypes.string,
  }).isRequired,
  loading: PropTypes.bool,
  className: PropTypes.string,
  earningTextColor: PropTypes.string,
  titleTextColor: PropTypes.string,
  earningExchangedTextColor: PropTypes.string,
};

export default AmountCardItem;
