import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { signupAgreementsStyles } from './styles';

const useStyles = makeStyles(signupAgreementsStyles);

const SignupAgreements = ({
  onPolicyCheckBoxClick,
  lang,
  agreements,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const termAndServiceAgreementsComp = {
    en: (<Typography
      variant="body2"
    >
      <>
        {t('By creating an account. I agree to the')}
        {' '}
        <Link href="https://www.neukind.com/terms" target="_blank">
          {t('Terms of Service')}
        </Link>
        {' '}
        {t('and')}
        {' '}
        <Link href="https://www.neukind.com/privacy" target="_blank">
          {t('Privacy Policy')}
        </Link>
      </>
    </Typography>
    ),
    ja: (<Typography
      variant="body2"
    >
      <>
        <Link href="https://www.neukind.com/terms" target="_blank">
          利用規約
        </Link>
        と
        <Link href="https://www.neukind.com/privacy" target="_blank">
          プライバシーポリシー
        </Link>
        に同意し、アカウントを作成します
      </>
         </Typography>),

  };

  const newsletterComp = {
    en: (<Typography
      variant="body2"
    >
      {t('Subscribe to our newsletter for special offers and exclusive discounts')}
    </Typography>
    ),
  };

  const agreementKeys = [
    {
      key: 'newsletter',
      comp: newsletterComp,
    },
    {
      key: 'termsAndService',
      comp: termAndServiceAgreementsComp,
    },

  ];

  const getAgreements = R.curry(
    (agreementsComponent, lang) => R.prop(lang, agreementsComponent) || R.prop('en', agreementsComponent),
  );
  const checkboxClassNames = {
    'zh-CN': classes.checkboxZh,
    'zh-TW': classes.checkboxZh,
  };
  const getCheckboxClassName = R.curry(
    (checkboxClassNames, lang) => checkboxClassNames[lang] || classes.checkbox,
  );
  return (
    <FormGroup>
      {
        R.addIndex(R.map)((agreement, index) => {
          const {
            comp,
            key,
          } = agreement;
          const DisplayComp = getAgreements(comp, lang);
          return (
            <FormControlLabel
              key={key}
              control={(
                <Checkbox
                  onChange={onPolicyCheckBoxClick(key)}
                  color="primary"
                  checked={agreements[key]}
                  className={getCheckboxClassName(checkboxClassNames, lang)}
                />
            )}
              label={DisplayComp}
            />
          );
        })(agreementKeys)
        }
    </FormGroup>
  );
};

SignupAgreements.propTypes = {
  onPolicyCheckBoxClick: PropTypes.func.isRequired,
  lang: PropTypes.string.isRequired,
  agreements: PropTypes.shape({
    termsAndService: PropTypes.bool.isRequired,
    newsletter: PropTypes.bool.isRequired,
  }).isRequired,
};

export default SignupAgreements;
