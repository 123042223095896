import DateFnsUtils from '@date-io/date-fns';
import { makeStyles } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';
import whyDidYouRender from '@welldone-software/why-did-you-render';
import { isValid } from 'date-fns';
import {
  TextField,
  Select,
  KeyboardDatePicker,
} from 'mui-rff';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Form } from 'react-final-form';
import { useSelector } from 'react-redux';

import {
  useIsMobile,
} from '../../../hooks/hooks';
import { unicodeDateFormat } from '../../../modules/appConstants';
import { RoundedButton, CloseIconButton } from '../../MaterialUiCustom/MaterialUiCustom';
import { updateOrderDataStyles } from '../styles';
// constants

if (process.env.REACT_APP_ENV !== 'prod') {
  whyDidYouRender(React);
}

const useStyles = makeStyles(updateOrderDataStyles);

const UpdateOrderData = (props) => {
  const {
    initialValues,
    onClose,
    open,
    onCancelButtonClick,
    onUpdateOrderData,
    t,
  } = props;
  const { orderType } = initialValues;

  const updateOrderDataLoading = useSelector((state) => state.loadingR.updateOrderDataLoading);

  const classes = useStyles();
  const isMobileDevice = useIsMobile();
  const [selectedOrderType] = useState(orderType);

  const validate = (values) => {
    const errors = {};
    if (!values.orderDate) {
      errors.orderDate = t('Required');
    }
    if (!values.quantity) {
      errors.quantity = t('Required');
    }
    if (values.quantity <= 0) {
      errors.quantity = t('Minimum quantity is 1');
    }
    if (!values.orderType) {
      errors.orderType = t('Required');
    }
    if (!isValid(values.orderDate)) {
      errors.orderDate = t('Invalid date format');
    }
    if (values.dateTrialEnd && !isValid(values.dateTrialEnd)) {
      errors.dateTrialEnd = t('Invalid date format');
    }
    return errors;
  };

  const generateUpdateOrderDataForm = () => (
    <Form
      initialValues={initialValues}
      onSubmit={onUpdateOrderData}
      subscription={{ submitting: true, pristine: true, invalid: true }}
      validate={validate}
      render={({
        handleSubmit,
        submitting,
        pristine,
        invalid,
      }) => (
        <form
          id="updateOrderDataForm"
          onSubmit={handleSubmit}
        >
          <Grid container justifyContent="center" spacing={4} className={classes.formGrid}>
            <Grid item xs={10} sm={7}>
              <TextField
                disabled
                id="order-id-input-textfield"
                label={t('Order ID')}
                variant="outlined"
                name="orderId"
                required
                type="text"
              />
            </Grid>
            <Grid item xs={10} sm={7}>
              <TextField
                disabled
                id="email-input-textfield"
                label={t('Email')}
                variant="outlined"
                name="email"
                type="text"
              />
            </Grid>
            <Grid item xs={10} sm={7}>
              <Select
                id="order-type-input-select"
                name="orderType"
                label={t('Order Type')}
                data={selectedOrderType}
                variant="outlined"
                disabled
              >
                <MenuItem value="NODE_PROVIDER_MINING">NODE_PROVIDER_MINING</MenuItem>
                <MenuItem value="NODE_PROVIDER_NEUNODE">NODE_PROVIDER_NEUNODE</MenuItem>
                <MenuItem value="NAAS">NAAS</MenuItem>
                <MenuItem value="LENDING">LENDING</MenuItem>
              </Select>
            </Grid>
            <Grid item xs={10} sm={7}>
              <TextField
                id="quantity-input-textfield"
                label={t('Quantity')}
                variant="outlined"
                name="quantity"
                required
                type="number"
              />
            </Grid>
            <Grid item xs={10} sm={7}>
              <KeyboardDatePicker
                variant="inline"
                label={t('Order Date')}
                dateFunsUtils={DateFnsUtils}
                name="orderDate"
                inputVariant="outlined"
                format={unicodeDateFormat}
                InputAdornmentProps={{ position: 'start' }}
                DialogProps={{ disableBackdropClick: true }}
                required
              />
            </Grid>
            <Grid item xs={10} sm={7}>
              <TextField
                id="trial-days-input-textfield"
                label={t('Trial Days')}
                variant="outlined"
                name="trialDays"
                type="number"
              />
            </Grid>
            <Grid item xs={10} sm={7}>
              <KeyboardDatePicker
                variant="inline"
                label={t('Trial End Date')}
                dateFunsUtils={DateFnsUtils}
                name="dateTrialEnd"
                inputVariant="outlined"
                format={unicodeDateFormat}
                InputAdornmentProps={{ position: 'start' }}
                DialogProps={{ disableBackdropClick: true }}
              />
            </Grid>
            <Grid container item xs={10} sm={7} justifyContent="flex-end"/* className={classes.actionGrid} */>
              <Grid item>
                <RoundedButton
                  type="submit"
                  variant="contained"
                  disabled={pristine || submitting || invalid}
                  color="primary"
                >
                  { t('update')}
                </RoundedButton>
              </Grid>
              <Grid item>
                <RoundedButton
                  variant="contained"
                  color="secondary"
                  disabled={submitting}
                  onClick={onCancelButtonClick}
                >
                  { t('cancel')}
                </RoundedButton>
              </Grid>
            </Grid>
          </Grid>
        </form>
      )}
    />
  );

  return (
    <Dialog
      fullScreen={isMobileDevice}
      fullWidth
      maxWidth="md"
      onClose={onClose}
      open={open}
    >
      <Typography variant="h2" className={classes.dialogTitleText}>
        {t('Update Order Data')}
      </Typography>
      <CloseIconButton
        onClick={onClose}
        color="default"
        disabled={updateOrderDataLoading}
        className={classes.closeButton}
      />
      {
        generateUpdateOrderDataForm()
      }
    </Dialog>
  );
};

UpdateOrderData.propTypes = {
  initialValues: PropTypes.shape({
    orderId: PropTypes.string,
    orderType: PropTypes.string,
    quantity: PropTypes.number,
    orderDate: PropTypes.object,
    trialDays: PropTypes.number,
    dateTrialEnd: PropTypes.object,
  }).isRequired,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  onCancelButtonClick: PropTypes.func.isRequired,
  onUpdateOrderData: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

UpdateOrderData.whyDidYouRender = true;
export default React.memo(UpdateOrderData);
