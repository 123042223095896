import { sleep } from 'jsutils';
import * as R from 'ramda';

import { getItem } from '../../modules/browser_utils';
import { shouldUpdateAuthToken } from '../../modules/jwt_utils';
import { errorOperations } from '../error/index';

import * as actions from './actions';
import { isFetchingNewAuthToken, getRequiredDataToUpdateAuthTokenData } from './services';

const { REACT_APP_ENV, REACT_APP_JWT_LOG } = process.env;

/**
 * Dispatch actions to get new auth tokens from server, replace auth tokens with new ones in local
 * storage and update auth token status in store
 * @param  {import('./typedefs').UserId}   userId
 * @param  {import('./typedefs').ItemKey}  key
 * @param  {Function}                      dispatch  The dispatch function of redux
 * @param  {Function}                      getState The getState function of redux
 * @return {import('../typedefs').ReduxAction}
 */
export const updateAuthTokenData = R.curry(
  async (authTokenDataKey, userDataKey, authStatusKey, dispatch, getState) => {
    const { fetchNewAuthTokenRequestStatus, tabName } = getState().jwtR;
    // Getting all the following data from browser local storage
    const {
      userId,
      email,
      refreshToken,
      idToken,
    } = getRequiredDataToUpdateAuthTokenData(authTokenDataKey, userDataKey, authStatusKey);
    // TODO: FOR DEBUG ONLY
    if (REACT_APP_ENV !== 'prod' && REACT_APP_JWT_LOG === 'ON') {
      console.log('IdToken', idToken, '\nRefreshToken', refreshToken, '\nUserId', userId, '\nEmail', email, '\nFetching New Access Token Now: ', isFetchingNewAuthToken(fetchNewAuthTokenRequestStatus));
    }

    const leaderTabName = getItem('leaderTabName');
    try {
      if (
        shouldUpdateAuthToken(authTokenDataKey)
        && !isFetchingNewAuthToken(fetchNewAuthTokenRequestStatus)
        && tabName === leaderTabName
      ) {
        dispatch(actions.setAuthTokenStatusOutdated());
        dispatch(actions.setFetchNewAuthTokenRequestStatus());

        // TODO: FOR TEST ONLY. TO EMULATE PROD/STAGE ENV
        if (REACT_APP_ENV === 'dev') {
          const randomWaitingTimeTest = Math.floor(Math.random() * 10000);
          console.log('Waiting To Emulate prod ENV for these time:', randomWaitingTimeTest);
          console.log('Timer Starting At:', new Date());
          await sleep(randomWaitingTimeTest);
          console.log('$$$Sending Request at: ', new Date());
        }
        const response = dispatch(
          await actions.updateAuthTokenData(
            userId,
            email,
            refreshToken,
            idToken,
          ),
        );
        return dispatch(actions.updateTokenDataInStorage(authTokenDataKey, response.payload));
      }
      // Since auth token need updated and it's being updated now we assume that it's currently
      // in outdated state now.
      return actions.setAuthTokenStatusOutdated();
    } catch (error) {
      dispatch(actions.clearFetchNewAuthTokenRequestStatus());
      return dispatch(errorOperations.setError(
        'Authentication token update',
        { fallbackErrorMessage: error.message, error },
      ));
    }
  },
);

export const { electLeaderTab } = actions;
