import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { overviewPieChartTooltipStyles } from './styles';

const useStyles = makeStyles(overviewPieChartTooltipStyles);

const OverviewPieChartTooltip = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const {
    datum,
  } = props;
  const metadata = R.view(R.lensPath(['data', 'metadata']), datum);
  const data = R.view(R.lensPath(['data']), datum);
  const { label, value } = data;
  const {
    balance,
    staked,
    earning,
    isNoBalance,
  } = metadata || {};

  if (!label || isNoBalance) {
    return null;
  }

  const getRowContent = (title, content) => (
    <Box display="flex">
      <Box>
        <Typography className={classes.rowTitle}>
          {`${title}:`}
        </Typography>
      </Box>
      <Box ml={1}>
        <Typography className={classes.rowContent}>
          {content}
        </Typography>
      </Box>
    </Box>
  );
  return (
    <Paper className={classes.root}>
      <Box display="flex">
        <Box mr={2}>
          <Typography className={classes.rowContent}>
            {label}
          </Typography>
        </Box>
        <Box>
          <Typography className={classes.rowContent}>
            {`${value}%`}
          </Typography>
        </Box>
      </Box>
      <Divider className={classes.divider} />
      {getRowContent(t('Balance'), balance)}
      {getRowContent(t('Staked'), staked)}
      {getRowContent(t('Earning'), earning)}
    </Paper>
  );
};

OverviewPieChartTooltip.propTypes = {
  datum: PropTypes.shape({
    color: PropTypes.string,
    label: PropTypes.string,
    value: PropTypes.string,
    metadata: PropTypes.shape({
      balance: PropTypes.string,
      staked: PropTypes.string,
      earning: PropTypes.string,
      isNoBalance: PropTypes.bool,
    }),
  }).isRequired,
};

OverviewPieChartTooltip.defaultProps = {
};

export default React.memo(OverviewPieChartTooltip);
