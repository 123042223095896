import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import whyDidYouRender from '@welldone-software/why-did-you-render';
import getSymbolFromCurrency from 'currency-symbol-map';
import { upperCase } from 'lodash';
import * as moment from 'moment';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';

import { useAddFunctionToRef } from '../../hooks/hooks';
import { deployConfigOperations } from '../../store/deploy_config/index';
import { paymentOperations } from '../../store/payment/index';
import RoundedButton from '../MaterialUiCustom/Button/RoundedButton';
import Payment from '../Payment/Payment';

import {
  marketProtocolDeployPaymentStyles,
} from './styles';

// operations

const { REACT_APP_ENV } = process.env;

if (REACT_APP_ENV !== 'prod') {
  whyDidYouRender(React);
}

const useStyles = makeStyles(marketProtocolDeployPaymentStyles);

const MarketProtocolDeployPayment = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const {
    trialDays,
    nodeType,
    paymentSelected,
    quantity,
    pricing,
  } = props;
  const {
    priceIds,
  } = useSelector((state) => state.stripeR.stripePrice);
  const getTrialDateEnd = (trialDays) => {
    const now = moment();
    const dateTrialEnd = now.add(trialDays, 'days');
    return dateTrialEnd.format('MM/DD/YYYY');
  };

  const [selectedStripePaymentMethodId, setSelectedStripePaymentMethod] = useState('');
  const [stripePriceId, setStripePriceId] = useState('');
  const [paymentPeriod, setPaymentPeriod] = useState('year');

  React.useEffect(() => {
    if (nodeType) {
      const { stripePriceId } = priceIds[nodeType][paymentPeriod];
      setStripePriceId(stripePriceId);
    }
  }, [priceIds, nodeType, paymentPeriod]);

  const onUpdatePaymentStripe = useAddFunctionToRef(
    (
      stripePriceId,
      selectedStripePaymentMethodId,
    ) => {
      dispatch(
        paymentOperations.updatePaymentStripe(
          stripePriceId,
          selectedStripePaymentMethodId,
        ),
      );
    },
  );

  const onTogglePaymentSelected = useAddFunctionToRef(
    (disabled) => {
      dispatch(deployConfigOperations.toggleDeployConfigMetadata({
        updatePath: ['paymentSelected'],
        metadata: disabled,
      }));
      dispatch(
        deployConfigOperations.toogleDisableDeployBtnByNodeType(nodeType),
      );
    },
  );
  const onRenderCardForm = () => {
    if (paymentSelected) {
      onTogglePaymentSelected(false);
    }
  };

  const getPlanList = () => R.map((plan) => {
    const {
      paymentPeriod: paymentPeriodPlan,
      discount,
    } = plan;
    // i18next-extract-mark-context-next-line ["year", "month"]
    const paymentPeriodPlanToDisplay = t('payment period plan 1', { context: paymentPeriodPlan });
    return (
      <Grid
        item
        key={paymentPeriodPlan}
      >
        <RoundedButton
          color={paymentPeriod === paymentPeriodPlan ? 'secondary' : 'default'}
          variant={paymentPeriod === paymentPeriodPlan ? 'contained' : 'outlined'}
          onClick={() => setPaymentPeriod(paymentPeriodPlan)}
          disableElevation
          className={classes.planButton}
        >
          {paymentPeriodPlanToDisplay}
          {discount > 0 && (
          <Box
            border={1}
            borderColor="#E1FE92"
            bgcolor="#E1FE92"
            borderRadius={16}
            className={classes.discountOnButton}
          >
            <Typography
              color="textPrimary"
              variant="body1"
              className={classes.discountOnButtonText}
            >
              {`-${discount * 100}%`}
            </Typography>
          </Box>
          )}
        </RoundedButton>
      </Grid>
    );
  }, pricing);

  const getPriceContent = () => {
    const priceData = R.find(R.propEq('paymentPeriod', paymentPeriod), pricing);
    const {
      price,
      discount,
      currency,
    } = priceData;

    const total = price * quantity;
    const currencySymbol = getSymbolFromCurrency(upperCase(currency));
    const totalToDisplay = total.toFixed(2);
    const specialDiscountToDisplay = `Save ${currencySymbol}${(total / (1 - discount) - total).toFixed(2)}`;
    return (
      <Box display="flex" alignItems="flex-end" flexWrap="wrap">
        <Box
          display="flex"
          mr={4}
          justifyContent="center"
          alignItems="center"
        >
          <Box>
            <Typography variant="body1" className={classes.totalText}>
              {`${t('Total')}:`}
              {' '}
            </Typography>
          </Box>
          <Box ml={1} mt={1}>
            <Typography variant="body2" className={classes.currencySymbol}>
              {currencySymbol}
            </Typography>
          </Box>
          <Box>
            <Typography variant="body1" className={classes.valueText}>
              {totalToDisplay}
            </Typography>
          </Box>
        </Box>
        {discount && (
        <Box mb={1} border={0} borderColor="primary.main" borderRadius={16}>
          <Typography color="primary" className={classes.discountText}>
            {specialDiscountToDisplay}
          </Typography>
        </Box>
        )}
      </Box>
    );
  };

  const getTrialContent = () => (trialDays ? (
    <Box
      display="flex"
      justifyContent="center"
      flexDirection="column"
      pt={1}
      pb={1}
    >
      <Box>
        <Typography variant="h6">
          {`${trialDays} ${t('days free trial')}`}
        </Typography>
      </Box>
      <Box>
        <Typography variant="body1">
          {`${t('Charge will start on')}: ${getTrialDateEnd(trialDays)}`}
        </Typography>
      </Box>
    </Box>
  ) : null);

  useEffect(() => {
    if (stripePriceId && selectedStripePaymentMethodId) {
      onUpdatePaymentStripe(
        stripePriceId,
        selectedStripePaymentMethodId,
      );
      onTogglePaymentSelected(true);
    } else {
      onTogglePaymentSelected(false);
    }
  }, [
    onUpdatePaymentStripe,
    stripePriceId,
    selectedStripePaymentMethodId,
    onTogglePaymentSelected,
  ]);

  return (
    <Grid container justifyContent="center" className={classes.root}>
      <Grid item xs={12}>
        <Grid container justifyContent="center" spacing={4} className={classes.planList}>
          {getPlanList()}
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Box className={classes.payment}>
          <Box className={classes.paymentContent}>
            <Grid container spacing={4}>
              <Grid item xs={12}>
                {getPriceContent()}
              </Grid>
              <Grid item xs={12}>
                <Payment
                  btnText="add card"
                  onSelectStripePaymentMethod={setSelectedStripePaymentMethod}
                  onRenderCardForm={onRenderCardForm}
                  enableCoupon
                />
              </Grid>
              <Grid item xs={12}>
                {getTrialContent()}
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};

MarketProtocolDeployPayment.propTypes = {
  trialDays: PropTypes.number.isRequired,
  nodeType: PropTypes.string.isRequired,
  paymentSelected: PropTypes.bool.isRequired,
  quantity: PropTypes.number.isRequired,
  pricing: PropTypes.arrayOf(PropTypes.shape({
    paymentPeriod: PropTypes.string,
    price: PropTypes.number,
    currency: PropTypes.string,
    discount: PropTypes.number,
  })).isRequired,
};

MarketProtocolDeployPayment.whyDidYouRender = true;
export default React.memo(MarketProtocolDeployPayment);
