// react-router

import Typography from '@material-ui/core/Typography';
import { Alert } from '@material-ui/lab';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

// store
// custom hooks
import { useGetQuery } from '../../../hooks/hooks';
// routes
import {
  adminDashboardRoute,
} from '../../../routes';
import { adminUtils } from '../../../store/admin/index';
import { routerServices } from '../../../store/router/index';
import { RoundedButton } from '../../MaterialUiCustom/MaterialUiCustom';

const ImpersonationAlert = (props) => {
  const {
    email: impersonatedUserEmailStore,
    onStopImpersonation,
  } = props;
  const history = useHistory();
  const queryLng = useGetQuery('lng');
  const impersonatedUserInSession = JSON.parse(sessionStorage.getItem('impersonatedUser'));
  const impersonatedUserEmailInSession = R.view(R.lensProp('email'), impersonatedUserInSession);
  const impersonatedUserEmailToUse = impersonatedUserEmailInSession || impersonatedUserEmailStore;
  const createRedirectToAdminDashboard = R.curry((queryLng, pathname) => {
    routerServices.historyPush(
      history,
      {
        pathname,
        queryLng,
      },
    );
  });
  const redirectToAdminDashboard = createRedirectToAdminDashboard(queryLng);
  const handleStopImersonationBtnClick = () => {
    onStopImpersonation();
    adminUtils.removeImpersonationDataFromSessionStorage();
    redirectToAdminDashboard(adminDashboardRoute);
  };
  const { t } = useTranslation();
  return (
    <Alert
      severity="warning"
      action={(
        <RoundedButton
          variant="contained"
          color="secondary"
          size="small"
          onClick={() => handleStopImersonationBtnClick()}
        >
          {t('stop impersonating')}
        </RoundedButton>
        )}
    >
      <Typography variant="body1" color="inherit">
        {t('Careful, you are impersonating')}
        {' '}
        <strong>{impersonatedUserEmailToUse}</strong>
      </Typography>
    </Alert>
  );
};

ImpersonationAlert.propTypes = {
  email: PropTypes.string.isRequired,
  onStopImpersonation: PropTypes.func.isRequired,
};

export default ImpersonationAlert;
