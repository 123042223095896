import { makeStyles } from '@material-ui/core/styles';
import MuiAlert from '@material-ui/lab/Alert';
import PropTypes from 'prop-types';
import React from 'react';

import { styles } from './styles';

const useStyles = makeStyles(styles);

const Alert = ({
  variant,
  severity,
  color,
  onClose,
  action,
  customClasses,
  className,
  children,
}) => {
  const classes = useStyles();
  return (
    <MuiAlert
      variant={variant}
      severity={severity}
      color={color}
      onClose={onClose}
      action={action}
      classes={customClasses}
      className={className || classes.alert}
    >
      {children}
    </MuiAlert>
  );
};
Alert.defaultProps = {
  variant: 'standard',
  severity: 'warning',
  color: 'warning',
  onClose: null,
  action: null,
  customClasses: null,
  className: null,
};
Alert.propTypes = {
  variant: PropTypes.string,
  severity: PropTypes.string,
  color: PropTypes.string,
  onClose: PropTypes.func,
  action: PropTypes.node,
  className: PropTypes.string,
  customClasses: PropTypes.shape({
    // add more from MUI when you need
    root: PropTypes.string,
  }),
  children: PropTypes.node.isRequired,
};

export default Alert;
