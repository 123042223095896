// query
import {
  createQuery,
} from '../../../modules/apollo/apollo';

import {
  getAffiliateQuery,
} from './queries';
// apollo

export const graphqlGetAffiliateQuery = createQuery(getAffiliateQuery);
