const contentWidth = (theme) => ({
  maxWidth: 608,
});

export const eth2DeployStyles = (theme) => ({
  mobileStepperDot: {
    margin: '0px 8px',
  },
  mobileStepperDotActive: {
    backgroundColor: theme.palette.secondary.main,
  },
  mobileStepper: {
    marginTop: 55,
    flexGrow: 1,
  },
  fakeButton: {
    height: 40,
  },
  marginContentTop: {
    marginBottom: 50,
  },
  stepLabel: {
    [theme.breakpoints.down('xs')]: {
      fontSize: 12,
      '&.MuiStepLabel-alternativeLabel': {
        marginTop: 0,
      },
    },
  },
});

export const eth2DeployContentStyles = (theme) => ({
  eth2DeployContentRoot: {
    ...theme.shadow.deployFormContent,
    borderRadius: 10,
    padding: theme.spacing(2),
    [theme.breakpoints.down('xs')]: {
      padding: '22px 0px',
    },
  },
  centerComponent: {
    ...theme.centerComponent,
  },
  eth2DeployContent: {
    padding: theme.spacing(2),
  },
  encryptionPasswordField: {
    width: '100%',
  },
  encryptionPassword: {
    padding: theme.spacing(6, 1, 2, 1),
  },
  contentGridItem: {
    ...theme.centerComponent,
  },
  footerButton: {
    padding: theme.spacing(1.5, 6, 1.5, 6),
  },
  connectToWallet: {
    ...contentWidth(theme),
    ...theme.border.default,
  },
  connectToWalletContent: {
    margin: theme.spacing(2),
  },
  generateKeyPairs: {
    ...contentWidth(theme),
    ...theme.border.default,
  },
  generateKeyPairsContent: {
    margin: theme.spacing(2),
  },
  deposit: {
    ...contentWidth(theme),
    ...theme.border.default,
  },
  depositContent: {
    margin: theme.spacing(2),
  },
  completeSetup: {
    ...contentWidth(theme),
  },
  completeSetupContent: {
    margin: theme.spacing(2),
  },
  approveBtnGrid: {
    ...theme.centerComponent,
  },
  alertGrid: {
    width: '100%',
    ...contentWidth(theme),
    padding: `${theme.spacing(3, 0, 0, 0)} !important`,
  },
});

export const eth2DeployContentItemStyles = ((theme) => ({
  content: {
    ...theme.centerComponent,
  },
  title: {
    [theme.breakpoints.down('xs')]: {
      fontSize: 30,
    },
    fontWeight: 600,
  },
}));

export const eth2DeployCompleteStyles = ((theme) => ({
  congrat: {
    ...contentWidth(theme),
    fontWeight: 600,
    [theme.breakpoints.down('xs')]: {
      fontSize: 20,
    },
  },
  description: {
    ...contentWidth(theme),
  },
  depositDetailsItem: {
    maxWidth: 350,
  },
}));

export const eth2DeployDepositStyles = ((theme) => ({
  contentTitle: {
    ...theme.centerComponent,
    ...contentWidth(theme),
    fontWeight: 600,
    [theme.breakpoints.down('xs')]: {
      fontSize: 20,
    },
  },
  leftContent: {
    fontWeight: 600,
    [theme.breakpoints.down('xs')]: {
      fontSize: 15,
    },
  },
  item: {
    ...theme.centerComponent,
    padding: theme.spacing(1, 1),
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(0, 1, 0, 1),
    },
  },
}));

export const eth2DeployPaymentStyles = (theme) => ({
  root: {
    ...theme.centerComponent,
  },
  payment: {
    ...contentWidth(theme),
    ...theme.border.default,
  },
  paymentContent: {
    margin: theme.spacing(4),
  },
  planButton: {
    width: 120,
  },
  discountText: {
    margin: theme.spacing(1),
  },
  discountOnButton: {
    color: theme.palette.primary.main,
    position: 'absolute',
    top: '0%',
    left: '80%',
    marginTop: -12,
    marginLeft: -12,
  },
  discountOnButtonText: {
    fontSize: 12, fontWeight: 600, marginLeft: 6, marginRight: 6,
  },
});

export const eth2DeployConnectToWalletStyles = (theme) => ({
  connectWalletRoot: {
    ...theme.centerComponent,
  },
  connectWallet: {
    marginLeft: 'auto',
    marginRight: 'auto',
    ...contentWidth(theme),
    width: '100%',
  },
});
