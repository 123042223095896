import * as R from 'ramda';

import {
  returnAction,
} from '../store_utils';

import * as types from './types';
import * as adminUtils from './utils';

/**
 * Impersonate user action.
 *
 */
export const impersonateUser = R.curry(
  (userData) => {
    adminUtils.setImpersonationDataToSessionStorage('true', userData);
    return R.pipe(
      R.mergeLeft({ isImpersonating: true }),
      returnAction(types.IMPERSONATE_USER_SUCCESS),
    )({ user: userData });
  },
);

/**
 * Clear reload user action.
 *
 */
export const clearReloadUser = () => (
  {
    type: types.CLEAR_RELOAD_USER_SUCCESS,
    payload: {
      shouldReloadUser: false,
    },
  }
);

// When admin impersonates any user we load the impersonated user data to
// 'userR.user' reducer only in the tab in which admin is imperosnating a user.
export const resetImpersonation = () => ({ type: types.RESET_IMPERSONATION });
