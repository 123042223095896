import * as R from 'ramda';

import { setItem } from '../../modules/browser_utils';
import {
  createReducer,
  updateStateWithKey,
} from '../store_utils';

import { checkIfAuthTokenUpdateSucceed } from './services';
import * as types from './types';

export const updateAuthTokensStatusIS = {
  fetchNewAuthTokenRequestStatus: '',
  areAuthTokensOutdated: true,
  isLeaderTab: false,
};

const updateAuthTokensStatusRUpdater = {
  [types.SET_FETCH_NEW_AUTH_TOKEN_REQUEST_STATUS]: (
    state,
    payload,
  ) => updateStateWithKey('fetchNewAuthTokenRequestStatus', state, payload),
  [types.UPDATE_AUTH_TOKEN_SUCCESS]: (
    state,
    payload,
  ) => R.pipe(
    checkIfAuthTokenUpdateSucceed,
    updateStateWithKey('fetchNewAuthTokenRequestStatus', state),
  )(payload),
  [types.CLEAR_FETCH_NEW_AUTH_TOKEN_REQUEST_STATUS]: (
    state,
    payload,
  ) => R.pipe(
    checkIfAuthTokenUpdateSucceed,
    updateStateWithKey('fetchNewAuthTokenRequestStatus', state),
  )(payload),
  [types.SET_AUTH_TOKENS_STATUS_OUTDATED]: (
    state,
    payload,
  ) => updateStateWithKey('areAuthTokensOutdated', state, payload),
  [types.SET_AUTH_TOKENS_STATUS_UPDATED]: (
    state,
    payload,
  ) => updateStateWithKey('areAuthTokensOutdated', state, payload),
  [types.ELECT_LEADER_TAB]: (
    state,
    payload,
  ) => {
    setItem('leaderTabName', payload);
    return updateStateWithKey('tabName', state, payload);
  },
};

const jwtR = createReducer(
  updateAuthTokensStatusIS,
  updateAuthTokensStatusRUpdater,
);

export default jwtR;
