import { combineReducers } from 'redux';

import {
  createReducer,
  mergeStateAndPayload,
} from '../store_utils';

import * as types from './types';

export const aithenticateRedirectIS = {
  pathName: '',
};
const aithenticateRedirectRUpdaters = {
  [types.SET_AUTHENTICATE_REDIRECT_PATH_NAME]: mergeStateAndPayload,
};

const aithenticateRedirectR = createReducer(aithenticateRedirectIS, aithenticateRedirectRUpdaters);

const routerR = combineReducers({
  aithenticateRedirect: aithenticateRedirectR,
});
export default routerR;
