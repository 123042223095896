import { combineReducers } from 'redux';

import {
  createReducer,
  updateStateWithKey,
} from '../store_utils';

import * as types from './types';

export const sidebarIS = {
  selectedIndex: 0,
};
const navbarRUpdaters = {
  [types.SELECT_SIDE_BAR_MENU_ITEM]: (state, payload) => updateStateWithKey(
    'selectedIndex',
    state,
    payload,
  ),
  [types.SELECT_SIDE_BAR_MENU_ITEM_BY_ROUTE]: (state, payload) => {
    const { itemIndex } = payload;
    const { selectedIndex } = state;
    // return the state when update manually by SELECT_SIDE_BAR_MENU_ITEM
    if (selectedIndex === itemIndex) return state;
    return updateStateWithKey(
      'selectedIndex',
      state,
      itemIndex,
    );
  },
};

const sidebarR = createReducer(sidebarIS, navbarRUpdaters);

const navigationR = combineReducers({
  sidebar: sidebarR,
});
export default navigationR;
