import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import Skeleton from '@material-ui/lab/Skeleton';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

import RoundedButton from '../../MaterialUiCustom/Button/RoundedButton';

import MiningAddress from './MiningAddress';
import MiningBoxWrapper from './MiningBoxWrapper';
import { miningHeaderExtraStyle } from './styles';

const useStyles = makeStyles(miningHeaderExtraStyle);

const MiningHeaderExtra = ({
  accounts,
  getCryptoAddressesLoading,
  haveCryptoAccounts,
  handleOpenAddressCreation,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const getAddressSectionContent = () => (!getCryptoAddressesLoading
    ? (
      <MiningAddress
        accounts={accounts}
        t={t}
      />
    )
    : (
      <MiningBoxWrapper
        Icon={<Skeleton variant="circle" width={40} height={40} />}
        Text={<Skeleton variant="text" />}
        maxWidth
      />
    ));
  const getBtnSectionContent = () => (!getCryptoAddressesLoading
    ? (
      <RoundedButton
        onClick={() => handleOpenAddressCreation()}
        color="primary"
        variant="contained"
        className={classes.connectWalletButton}
        noMargin
      >
        {t('connect wallet')}
      </RoundedButton>
    )
    : (
      <Box minWidth={200}>
        <MiningBoxWrapper
          Icon={<Skeleton variant="circle" width={40} height={40} />}
          Text={<Skeleton variant="text" />}
          maxWidth
        />
      </Box>
    ));
  return (
    haveCryptoAccounts
      ? (
        getAddressSectionContent()
      )
      : (
        getBtnSectionContent()
      )
  );
};

MiningHeaderExtra.defaultProps = {
  accounts: [],
  getCryptoAddressesLoading: false,
  handleOpenAddressCreation: () => {},
  haveCryptoAccounts: false,
};

MiningHeaderExtra.propTypes = {
  accounts: PropTypes.arrayOf(PropTypes.shape({
    address: PropTypes.string,
  })),
  getCryptoAddressesLoading: PropTypes.bool,
  haveCryptoAccounts: PropTypes.bool,
  handleOpenAddressCreation: PropTypes.func,
};

export default MiningHeaderExtra;
