import * as R from 'ramda';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import ReactTableV7 from '../../Tables/ReactTableV7';
import { convertFiatToCrypto } from '../../utils/utils';
import InvestmentEarningDashboardItem from '../InvestmentNodeDetailsPageItem';

// others
import { miningDevicesColumns } from './columns';

const NodeOwnerDevices = ({ orders }) => {
  const { t } = useTranslation();

  const [openStatusInfoIndex, setOpenStatusInfoIndex] = useState(-1);
  const handleStatusInfoOpen = (index) => {
    setOpenStatusInfoIndex(index);
  };
  const handleStatusInfoClose = (index) => {
    setOpenStatusInfoIndex(index);
  };

  // const { items: orders } = useSelector((state) => state.nodeOwnerR.nodeOwnerOrder.mining);
  const {
    getOrdersLoading,
  } = useSelector((state) => state.loadingR);
  const coinPrice = useSelector((state) => state.nodeR.coinPrice);
  const convertToPHA = (value) => convertFiatToCrypto(coinPrice, value, 'PHA');

  const dataToUse = R.pipe(
    R.map(
      R.prop('devices'),
    ),
    R.flatten,
  )(orders);

  const columnsToUse = miningDevicesColumns(
    t,
    convertToPHA,
    openStatusInfoIndex,
    handleStatusInfoOpen,
    handleStatusInfoClose,
  );

  return (
    <InvestmentEarningDashboardItem
      titlePadding="21px 32px 16px 32px"
      title={t('All Miners')}
      content={(
        <ReactTableV7
          loading={getOrdersLoading}
          data={dataToUse}
          columns={columnsToUse}
          customPageSize={3}
          customPageSizeList={[3, 5, 10, 20, 50]}
        />
)}
    />
  );
};

export default NodeOwnerDevices;
