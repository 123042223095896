import Box from '@material-ui/core/Box';
import CssBaseline from '@material-ui/core/CssBaseline';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { useAddFunctionToRef } from '../../hooks/hooks';
import { errorOperations } from '../../store/error/index';
import Navigation from '../Navigation/Navigation';

import ErrorPage from './ErrorPage';

// store

import { styles } from './styles';

const useStyles = makeStyles(styles);

const ErrorFallBackPage = (props) => {
  const { errorMessage } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const errorTitle = errorMessage === 'Server Unavailable'
    ? '503'
    : '404';
  const finalErrorMessage = errorMessage === 'Server Unavailable'
    ? t('Sorry, service is currently unavailable')
    : t('Sorry, something went wrong. The page you are looking for does not exist or might have been removed');
  const onClearErrorToRender = useAddFunctionToRef(() => (
    dispatch(errorOperations.clearErrorToRender())
  ));

  useEffect(() => {
    onClearErrorToRender();
  }, [onClearErrorToRender]);

  return (
    <>
      <CssBaseline />
      <Box className={classes.root}>
        <Navigation isErrorPage />
        <main className={classNames(classes.content)}>

          <Box className={classes.appBarSpacer} />
          <ErrorPage
            errorTitle={errorTitle}
            errorMessage={finalErrorMessage}
            displayBackButton
          />
        </main>
      </Box>
    </>
  );
};

ErrorFallBackPage.propTypes = {
  errorMessage: PropTypes.string.isRequired,
};

export default ErrorFallBackPage;
