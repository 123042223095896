import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import whyDidYouRender from '@welldone-software/why-did-you-render';
import PropTypes from 'prop-types';
import React from 'react';

import {
  marketProtocolDeployContentItemStyles,
} from './styles';

const { REACT_APP_ENV } = process.env;

if (REACT_APP_ENV !== 'prod') {
  whyDidYouRender(React);
}

const useStyles = makeStyles(marketProtocolDeployContentItemStyles);

const MarketProtocolDeployContentItem = (props) => {
  const classes = useStyles();

  const {
    title,
    headerAction,
    content,
    headerWrap,
  } = props;

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          flexWrap={headerWrap ? 'wrap' : 'nowrap'}
        >
          <Box mb={1}>
            <Typography color="textSecondary" className={classes.title}>
              {title}
            </Typography>
          </Box>
          <Box>
            {headerAction}
          </Box>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Box className={classes.marketProtocolDeployContent}>
          {content}
        </Box>
      </Grid>
    </Grid>
  );
};

MarketProtocolDeployContentItem.defaultProps = {
  headerWrap: false,
  headerAction: null,
  title: null,
  content: null,
};

MarketProtocolDeployContentItem.propTypes = {
  title: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
  ]),
  headerAction: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
  ]),
  content: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
  ]),
  headerWrap: PropTypes.bool,
};

MarketProtocolDeployContentItem.whyDidYouRender = true;

export default React.memo(MarketProtocolDeployContentItem);
