import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Skeleton from '@material-ui/lab/Skeleton';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import LocalDate from '../../LocalDate/LocalDate';
import ReactTableV7 from '../../Tables/ReactTableV7';
import InvestmentEarningDashboardItem from '../InvestmentNodeDetailsPageItem';

// others
import { eth2ValidatorAllValidatorColumns } from './columns';
import { eth2ValidatorAllValidatorsStyles } from './styles';

const useStyles = makeStyles(eth2ValidatorAllValidatorsStyles);

const ETH2ValidatorAllValidators = (props) => {
  const classes = useStyles();

  const { t } = useTranslation();

  const { data } = props;
  // const dataToUse = R.filter(R.prop('index'), data); // only show nodes which have index in beaconchain
  const nodeActivatedDate = useSelector((state) => state.nodeR.nodeActivatedDate);

  const {
    getUserNodesLoading,
    getTotalEarningLoading,
  } = useSelector((state) => state.loadingR);

  const columnsToUse = eth2ValidatorAllValidatorColumns(
    t,
  );

  const getRowExpandedContentItem = (title, content) => (
    <Box display="flex">
      <Box mr={2}>
        <Typography className={classes.rowExpandedContentItemTitle}>
          {title}
        </Typography>
      </Box>
      <Box>
        {content}
      </Box>
    </Box>
  );

  const getRowExpandedContent = (row) => {
    const { index: rowIndex } = row;
    const {
      pubKey,
    } = data[rowIndex];

    const link = `https://beaconcha.in/validator/${pubKey}`;

    const { estimateActivationDate: activeDate } = nodeActivatedDate[pubKey] || {};

    const activeDateContent = activeDate === 'loading' ? (
      <Skeleton
        variant="text"
        width={100}
        height="100%"
      />
    ) : (
      <LocalDate
        date={activeDate}
      />
    );

    return (
      <Grid container spacing={3} className={classes.rowExpandedContent}>
        <Grid item xs={12}>
          {getRowExpandedContentItem(t('Validator key'),
            <Link href={link} target="_blank">
              <Typography noWrap className={classes.pubKeyText}>
                {pubKey}
              </Typography>
            </Link>)}
        </Grid>
        <Grid item xs={12}>
          {getRowExpandedContentItem(t('Active since'), activeDate && activeDate !== '-' ? activeDateContent : '-')}
        </Grid>
      </Grid>
    );
  };

  // useEffect(() => {
  //   onGetNodesEstimateActivationDate(rowExpandingPubkey);
  // }, [onGetNodesEstimateActivationDate, rowExpandingPubkey]);

  return (
    <InvestmentEarningDashboardItem
      titlePadding="21px 32px 16px 32px"
      title={t('All Validators')}
      content={(
        <ReactTableV7
          loading={getUserNodesLoading || getTotalEarningLoading}
          data={data}
          columns={columnsToUse}
          customPageSize={3}
          customPageSizeList={[3, 5, 10, 20, 50]}
          renderRowSubComponent={getRowExpandedContent}
        />
    )}
    />
  );
};

ETH2ValidatorAllValidators.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      earning: PropTypes.number.isRequired,
      balance: PropTypes.number.isRequired,
      nodeId: PropTypes.string.isRequired,
      index: PropTypes.number.isRequired,
      dateCreated: PropTypes.string.isRequired,
      apr: PropTypes.number.isRequired,
      status: PropTypes.string.isRequired,
      pubKey: PropTypes.string.isRequired,
    }),
  ).isRequired,
};

export default React.memo(ETH2ValidatorAllValidators);
