import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { columnsToRender } from './constants';
import { styles } from './styles';
// constants

const useStyles = makeStyles(styles);

const OverviewItemHeader = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const { type } = props;

  const getColumnContent = (title) => (
    <Grid item xs={2} sm={2} md={2} lg={2} key={`overview_header_${title}`}>
      <Typography
        variant="body1"
        className={classes.nodeDescriptionTitle}
      >
        {title}
      </Typography>
    </Grid>
  );

  /* TODO: create one object to reuse
  *  DIFFICULTY: t() only can use in component since it is a hook
  */
  const titles = {
    apy: t('APY'),
    amount: t('Deposit Amount'),
    quantity: t('Quantity'),
    staked: t('Staked'),
    earning: t('Total Earning'),
    balance: t('Total Balance'),
  };

  const firstColumnsTitleMap = {
    naas: t('Node Type'),
    'node-owner': t('Node Type'),
    lending: t('Asset'),
  };

  const getHeadersContent = (type, columnsToRender) => {
    const columns = columnsToRender[type];

    return R.map((eachColumn) => {
      const title = titles[eachColumn];
      return getColumnContent(title);
    }, columns);
  };

  return (
    <>
      <Box
        display="flex"
        alignItems="center"
        className={classes.itemHeader}
      >
        <Box flexGrow={1}>
          <Grid container spacing={1}>
            <Grid item xs={4} sm={4} md={4} lg={4}>
              <Typography
                variant="body1"
                className={classes.nodeDescriptionTitle}
              >
                {firstColumnsTitleMap[type]}
              </Typography>
            </Grid>
            {getHeadersContent(type, columnsToRender)}
          </Grid>
        </Box>
      </Box>
      <Divider className={classes.itemHeaderDivider} />
    </>
  );
};

OverviewItemHeader.defaultProps = {
};

OverviewItemHeader.propTypes = {
  type: PropTypes.string.isRequired,
};

export default OverviewItemHeader;
