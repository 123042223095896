import Box from '@material-ui/core/Box';
import Step from '@material-ui/core/Step';
import StepConnector from '@material-ui/core/StepConnector';
import StepLabel from '@material-ui/core/StepLabel';
import Stepper from '@material-ui/core/Stepper';
import Typography from '@material-ui/core/Typography';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import CheckOutlinedIcon from '@material-ui/icons/CheckOutlined';
import QueryBuilderOutlinedIcon from '@material-ui/icons/QueryBuilderOutlined';
import classNames from 'classnames';
import startCase from 'lodash/fp/startCase';
import toLower from 'lodash/fp/toLower';
import toUpper from 'lodash/fp/toUpper';
import moment from 'moment';
import * as R from 'ramda';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import ReactTableV7 from '../../Tables/ReactTableV7';
import InvestmentEarningDashboardItem from '../InvestmentNodeDetailsPageItem';

// others
import { timelineStepNameMapIndex } from '../constants';

import { miningOrderHistoryColumns } from './columns';
import { miningOrderHistoryStyles } from './styles';

const colorSet = {
  COMPLETE: '#B0F400',
  PROCESSING: 'primary',
};

const Connector = withStyles({
  line: {
    height: '100%',
  },
})(StepConnector);

const useColorlibStepIconStyles = makeStyles({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '50%',
    minWidth: 30,
    minHeight: 30,
  },
});

const ColorlibStepIcon = R.curry((status, props) => {
  const classes = useColorlibStepIconStyles();
  const { active, completed } = props;

  const icons = {
    COMPLETE: <CheckOutlinedIcon fontSize="small" style={{ color: colorSet.COMPLETE }} />,
    PROCESSING: <QueryBuilderOutlinedIcon fontSize="small" color={colorSet.PROCESSING} />,
    NOT_REACHED: <CheckOutlinedIcon fontSize="small" style={{ visibility: 'hidden' }} />,
  };
  return (
    <Box
      border={1}
      className={classNames(classes.root, {
        [classes.active]: active,
        [classes.completed]: completed,
      })}
    >
      {icons[status]}
    </Box>
  );
});

const useStyles = makeStyles(miningOrderHistoryStyles);

const MiningOrderHistory = ({ orders }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  // const { items: orders } = useSelector((state) => state.nodeOwnerR.nodeOwnerOrder.mining);
  const {
    getOrdersLoading,
  } = useSelector((state) => state.loadingR);

  const columnsToUse = miningOrderHistoryColumns(
    t,
  );

  const getSteps = (data) => R.addIndex(R.map)((eachStep, index) => {
    const {
      stepStatus,
      dateCompleted,
      description,
      expectDateCompleted,
      stepName,
    } = eachStep;
    const getStepContent = (description, status) => (
      <>
        <Typography color="textSecondary" variant="h3">
          {description}
        </Typography>
        {(status !== 'NOT_REACHED')
          ? (
            <Box
              display="flex"
              alignItems="center"
            >
              <Box mr={0.5}>
                <Typography>
                  {startCase(toLower(stepName))}
                  {' '}
                  -
                </Typography>
              </Box>
              <Box color={colorSet[status]}>
                <Typography color={status === 'PROCESSING' ? colorSet[status] : 'inherit'}>
                  {startCase(toLower(status))}
                </Typography>
              </Box>
            </Box>
          )
          : (
            <Typography>
              {startCase(toLower(stepName))}
            </Typography>
          )}

      </>
    );

    if (stepStatus === 'COMPLETE' && stepName !== 'SETUP') {
      return getStepContent(
        dateCompleted ? toUpper(moment(new Date(dateCompleted)).format('MMMM D, YYYY')) : '',
        stepStatus,
      );
    }

    if (index === 3) {
      return getStepContent(
        expectDateCompleted ? `${t('Expected complete setup date')}: ${toUpper(moment(new Date(expectDateCompleted)).format('MMMM D, YYYY'))}` : '',
        stepStatus,
      );
    }

    return getStepContent(
      description,
      stepStatus,
    );
  })(data);

  const getRowExpandedContent = (row) => {
    const { index: rowIndex } = row;
    const timeline = R.pipe(
      R.view(
        R.lensPath([rowIndex, 'timeline']),
      ),
      R.sortWith(
        [R.ascend((item) => timelineStepNameMapIndex[item.stepName])],
      ),
    )(orders);
    const steps = getSteps(timeline);
    const lastCompletedStepIndex = R.findLastIndex(R.propEq('stepStatus', 'COMPLETE'))(timeline);

    return (
      <>
        <Typography variant="h3">
          {t('Purchase Details')}
        </Typography>
        <Stepper
          activeStep={lastCompletedStepIndex}
          orientation="vertical"
          connector={<Connector style={{ paddingLeft: 3 }} />}
          className={classes.miningStepperContainer}
        >
          {steps.map((stepContent, index) => (
            <Step
              key={timeline[index].stepName}
              className={classes.miningStepperStep}
            >
              <StepLabel StepIconComponent={ColorlibStepIcon(timeline[index].stepStatus)}>{stepContent}</StepLabel>
            </Step>
          ))}
        </Stepper>
      </>
    );
  };

  return (
    <InvestmentEarningDashboardItem
      titlePadding="21px 32px 16px 32px"
      title={t('Order History')}
      content={(
        <ReactTableV7
          loading={getOrdersLoading}
          data={orders[0].orderId ? orders : []}
          columns={columnsToUse}
          customPageSize={3}
          customPageSizeList={[3, 5, 10, 20, 50]}
          renderRowSubComponent={getRowExpandedContent}
        />
    )}
    />
  );
};

export default MiningOrderHistory;
