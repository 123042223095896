import whyDidYouRender from '@welldone-software/why-did-you-render';
import React, { useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';

if (process.env.REACT_APP_ENV !== 'prod') {
  whyDidYouRender(React);
}
function useReduxFetching(fetchAction, ...arg) {
  const dispatch = useDispatch();
  const refProps = useRef({ fetchAction, arg, dispatch });
  useEffect(() => {
    refProps.current.dispatch(refProps.current.fetchAction(...refProps.current.arg));
  }, []);
}
useReduxFetching.whyDidYouRender = true;
export default useReduxFetching;
