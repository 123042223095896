import { isEmpty } from 'lodash';
import * as R from 'ramda';
import { combineReducers } from 'redux';
import { PURGE } from 'redux-persist';

import {
  CREATE_ORDER_SUCCESS,
  GET_NODE_PROVIDER_NEUNODE_ORDERS_SUCCESS,
  GET_NODE_PROVIDER_NEUNODE_ETH2_ORDERS_SUCCESS,
  GET_NODE_PROVIDER_MINING_ORDERS_SUCCESS,
  GET_LENDING_ORDERS_SUCCESS,
} from '../order/types';
import {
  createReducer,
} from '../store_utils';

import * as types from './types';

const descendDateCreated = R.sortWith(
  [R.descend((item) => new Date(R.prop('dateCreated', item)))],
);

const defaultNodeOwnerOrder = {
  items: [
    {
      amount: 0,
      contract: {
        contractStartDate: '', contractEndDate: '', contractPeriodValue: 0, contractPeriod: '', contractIRR: 0,
      },
      dateCreated: '',
      dateUpdated: '',
      devices: [],
      nodeOwnerOrderId: '',
      quantity: 0,
      timeline: [],
      userId: '',
      totalEarning: 0,
      earningHistory: [],
    },
  ],
};

export const nodeOwnerOrderIS = {
  neunode: defaultNodeOwnerOrder,
  neunodeETH2: defaultNodeOwnerOrder,
  mining: {
    ...defaultNodeOwnerOrder,
    mining6CoreOrders: defaultNodeOwnerOrder.items,
    mining4CoreOrders: defaultNodeOwnerOrder.items,
  },
  busd: defaultNodeOwnerOrder,
};

const filter4Core = R.filter((item) => item.metadata && item.metadata.machineType === 'FOUR_CORE');
// if metadata is null, it is for 6 core
const filter6Core = R.filter((item) => !item.metadata);
const getMiningNodes = R.curry((defaultNodeOwnerOrder, machineType, payload) => {
  const handleEmptyNodes = (nodes) => (isEmpty(nodes) ? defaultNodeOwnerOrder.items : nodes);
  if (machineType === 'SIX_CORE') {
    return R.pipe(
      descendDateCreated,
      filter6Core,
      handleEmptyNodes,
    )(payload.items);
  }
  if (machineType === 'FOUR_CORE') {
    return R.pipe(
      descendDateCreated,
      filter4Core,
      handleEmptyNodes,
    )(payload.items);
  }
  return defaultNodeOwnerOrder;
});

const nodeOwnerOrderRUpdaters = {
  [GET_NODE_PROVIDER_NEUNODE_ORDERS_SUCCESS]: (state, payload) => R.merge(state, {
    neunode: {
      items: descendDateCreated(payload.items),
    },
  }),
  [GET_NODE_PROVIDER_NEUNODE_ETH2_ORDERS_SUCCESS]: (state, payload) => R.merge(state, {
    neunodeETH2: {
      items: descendDateCreated(payload.items),
    },
  }),
  [GET_NODE_PROVIDER_MINING_ORDERS_SUCCESS]: (state, payload) => R.merge(state, {
    mining: {
      items: descendDateCreated(payload.items),
      mining6CoreOrders: getMiningNodes(defaultNodeOwnerOrder, 'SIX_CORE', payload),
      mining4CoreOrders: getMiningNodes(defaultNodeOwnerOrder, 'FOUR_CORE', payload),
    },
  }),
  [GET_LENDING_ORDERS_SUCCESS]: (state, payload) => R.merge(state, {
    busd: {
      items: descendDateCreated(payload.items),
    },
  }),
  [PURGE]: R.always(nodeOwnerOrderIS),
};
const nodeOwnerOrderR = createReducer(nodeOwnerOrderIS, nodeOwnerOrderRUpdaters);

export const nodeOwnerOrderConfigIS = {
  product: '',
  quantity: 5,
  paymentMethod: 'BANK_TRANSFER',
  items: [],
};
const nodeOwnerOrderConfigRUpdaters = {
  [types.UPDATE_NODE_PROVIDER_ORDER_CONFIG]: (
    state,
    payload,
  ) => {
    const { updatePath, data } = payload;
    return R.set(R.lensPath(updatePath), data, state);
  },
  [types.RESET_NODE_PROVIDER_ORDER_CONFIG]: R.always(nodeOwnerOrderConfigIS),
  [CREATE_ORDER_SUCCESS]: (state, payload) => {
    const { payment } = payload;

    return R.merge(
      state,
      {
        payment,
      },
    );
  },
  [PURGE]: R.always(nodeOwnerOrderConfigIS),
};
const nodeOwnerOrderConfigR = createReducer(
  nodeOwnerOrderConfigIS, nodeOwnerOrderConfigRUpdaters,
);

const nodeOwnerR = combineReducers({
  nodeOwnerOrder: nodeOwnerOrderR,
  nodeOwnerOrderConfig: nodeOwnerOrderConfigR,
});
export default nodeOwnerR;
