import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import whyDidYouRender from '@welldone-software/why-did-you-render';
import * as R from 'ramda';
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { nodeOwnerOperations } from '../../../store/node_provider/index';
import RoundedButton from '../../MaterialUiCustom/Button/RoundedButton';

// store

const { REACT_APP_ENV } = process.env;

if (REACT_APP_ENV !== 'prod') {
  whyDidYouRender(React);
}

const paymentMethodOptions = {
  // Crypto: [
  //   {
  //     name: 'USDC',
  //     disabled: true,
  //     value: 'USDC',
  //   },
  //   {
  //     name: 'BTC',
  //     disabled: true,
  //     value: 'BTC',
  //   },
  //   {
  //     name: 'ETH',
  //     disabled: true,
  //     value: 'ETH',
  //   },
  // ],
  Fiat: [
    {
      name: 'BANK TRANSFER',
      disabled: false,
      value: 'BANK_TRANSFER',
    },
  ],
};

const NodeOwnerFormPayment = (props) => {
  const dispatch = useDispatch();
  const { paymentMethod } = useSelector((state) => state.nodeOwnerR.nodeOwnerOrderConfig);

  const onUpdatePaymentMethod = (paymentMethod) => {
    dispatch(nodeOwnerOperations.updateNodeProviderOrderConfig(['paymentMethod'], paymentMethod));
  };

  const getPaymentMethodContent = (data) => R.pipe(
    R.toPairs,
    R.map((eachPaymentMethod) => {
      const categoryTitle = eachPaymentMethod[0];
      const options = eachPaymentMethod[1];

      const optionsContent = R.map((eachOption) => {
        const { name, disabled, value } = eachOption;
        return (
          <Grid item key={name}>
            <RoundedButton
              onClick={() => onUpdatePaymentMethod(value)}
              noMargin
              variant={paymentMethod === value ? 'contained' : 'outlined'}
              color={paymentMethod === value ? 'secondary' : 'default'}
              disabled={disabled}
            >
              {name}
            </RoundedButton>
          </Grid>
        );
      })(options);

      return (
        <Grid item xs={12} key={categoryTitle}>
          <Grid container style={{ width: '100%' }} spacing={1}>
            <Grid item xs={12}>
              <Typography>
                {categoryTitle}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={2}>
                {optionsContent}
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <Box pt={2} />
            </Grid>
          </Grid>
        </Grid>
      );
    }),
  )(data);

  return (
    <Grid container style={{ width: '100%', paddingLeft: 24 }} spacing={1}>
      {getPaymentMethodContent(paymentMethodOptions)}
    </Grid>
  );
};

NodeOwnerFormPayment.propTypes = {
};

NodeOwnerFormPayment.whyDidYouRender = true;
export default React.memo(NodeOwnerFormPayment);
