import React from 'react';

import Mining from './Mining';

const Mining4Core = () => (
  <Mining
    getUserRollupRewardsType="CENTRALIZED"
    machineType="FOUR_CORE"
    nodeType="PHALA_MINING_NODE_4_CORES"
  />
);

export default Mining4Core;
