import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { accountPropType, accountsPropType } from '../propTypes';

import ConnectWalletAddresses from './ConnectWalletAddresses';
import ConnectWalletWeb3 from './ConnectWalletWeb3';

const ConnectWalletExtension = ({
  enableWeb3Loading,
  isExtensionsInjected,
  accounts,
  openAddressCreation,
  chain,
  ss58Format,
  handleOnCancel,
  onConfirm,
  createCryptoAddressLoading,
  selectedAccount,
}) => {
  const { t } = useTranslation();
  const [activeStep, setActiveStep] = useState(0);
  const steps = {
    0: {
      title: '',
    },
    1: {
      title: t('Select an address'),
      // description: t('Your mining rewards will be sent to this address. It cannot be changed once added'),
    },
  };

  const stepsContents = {
    0: (
      <ConnectWalletWeb3
        enableWeb3Loading={enableWeb3Loading}
        isExtensionsInjected={isExtensionsInjected}
        accounts={accounts}
        openAddressCreation={openAddressCreation}
        chain={chain}
        ss58Format={ss58Format}
        setActiveStep={setActiveStep}
        activeStep={activeStep}
        handleOnCancel={handleOnCancel}
      />
    ),
    1: (
      <ConnectWalletAddresses
        accounts={accounts}
        onConfirm={onConfirm}
        createCryptoAddressLoading={createCryptoAddressLoading}
        enableWeb3Loading={enableWeb3Loading}
        handleOnCancel={handleOnCancel}
        showBtn
        setActiveStep={setActiveStep}
        isExtensionsInjected={isExtensionsInjected}
        web3
        selectedAccount={selectedAccount}
      />
    ),
  };

  const createGetStepdata = R.curry((steps, activeStep) => {
    const stepData = steps[activeStep];
    if (!stepData) throw new Error(`Step data expected an object but ${stepData} found`);
    return stepData;
  });

  const getStepData = createGetStepdata(steps);

  const createGetStepItem = R.curry((key, activeStep) => {
    const stepData = getStepData(activeStep);
    const item = stepData[key];
    return item;
  });
  const getStepDescription = createGetStepItem('description');

  const getStepContent = (activeStep) => {
    const stepContent = stepsContents[activeStep];
    return stepContent;
  };
  return (
    <Box>
      <Box>
        <Typography>
          {getStepDescription(activeStep)}
        </Typography>
      </Box>
      <Box>
        {getStepContent(activeStep) }
      </Box>
    </Box>
  );
};

ConnectWalletExtension.propTypes = {
  enableWeb3Loading: PropTypes.bool,
  isExtensionsInjected: PropTypes.bool.isRequired,
  accounts: accountsPropType,
  openAddressCreation: PropTypes.bool.isRequired,
  chain: PropTypes.string.isRequired,
  ss58Format: PropTypes.number.isRequired,
  handleOnCancel: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  createCryptoAddressLoading: PropTypes.bool,
  selectedAccount: accountPropType,
};
ConnectWalletExtension.defaultProps = {
  accounts: [],
  createCryptoAddressLoading: undefined,
  enableWeb3Loading: undefined,
  selectedAccount: undefined,
};

export default ConnectWalletExtension;
