import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import AddOutlinedIcon from '@material-ui/icons/AddOutlined';
import BackspaceOutlinedIcon from '@material-ui/icons/BackspaceOutlined';
import RemoveOutlinedIcon from '@material-ui/icons/RemoveOutlined';
import whyDidYouRender from '@welldone-software/why-did-you-render';
import toUpper from 'lodash/fp/toUpper';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';

import { useAddFunctionToRef } from '../../../hooks/hooks';
import { nodeOwnerOperations } from '../../../store/node_provider/index';
import { paymentOperations } from '../../../store/payment/index';
import CouponDialog from '../../CouponDialog/CouponDialog';

const { REACT_APP_ENV } = process.env;

if (REACT_APP_ENV !== 'prod') {
  whyDidYouRender(React);
}

const NodeOwnerFormQuantity = (props) => {
  const { t } = useTranslation();
  const {
    price, productName, productImage, min, max, description,
  } = props;
  const dispatch = useDispatch();
  const { quantity } = useSelector((state) => state.nodeOwnerR.nodeOwnerOrderConfig);
  const { promotionCode } = useSelector((state) => state.paymentR.paymentDetail);

  const [openCoupon, setopenCoupon] = useState(false);

  const priceText = price.toLocaleString('en-US', {
    style: 'currency',
    currency: 'JPY',
  });

  const onUpdateNodeProviderOrderQuantity = (newQuantity, subtotal, tax) => {
    dispatch(nodeOwnerOperations.updateNodeProviderOrderConfig(['quantity'], newQuantity));
    dispatch(nodeOwnerOperations.updateNodeProviderOrderConfig(['items'], [
      {
        image: productImage,
        product: productName,
        price: priceText,
        quantity: newQuantity,
        subtotal,
        ct: tax,
      },
    ]));
  };

  const subtotal = (quantity * price);
  const tax = (quantity * (price / 10));
  const total = subtotal + tax;

  const setQuantityValue = (value) => {
    if (value >= min && value <= max) {
      onUpdateNodeProviderOrderQuantity(value, value * price, value * (price / 10));
    }
  };
  const handleQuantityChange = (event) => {
    const { value } = event.target;
    setQuantityValue(value);
  };

  const cancelCoupon = async () => {
    await dispatch(
      paymentOperations.updateStripePromotionCode(''),
    );
  };
  const getCouponContent = () => {
    if (promotionCode) {
      return (
        <Box display="flex">
          <Box mt={0.2}>
            <Typography variant="body2">
              {`${t('Coupon')}:`}
            </Typography>
          </Box>
          <Box ml={0.5} mt={0.2}>
            <Typography variant="body2" style={{ fontWeight: 550 }}>
              {toUpper(promotionCode)}
            </Typography>
          </Box>
          <Box ml={1}>
            <IconButton style={{ padding: 0 }} onClick={cancelCoupon}>
              <BackspaceOutlinedIcon color="error" style={{ fontSize: 20 }} />
            </IconButton>
          </Box>
        </Box>
      );
    }

    return (
      <Button
        color="primary"
        onClick={() => setopenCoupon(true)}
        style={{
          margin: 0,
          padding: 0,
        }}
      >
        {t('Have a coupon?')}
      </Button>
    );
  };

  const getCouponDialogContent = () => {
    if (openCoupon) {
      return (
        <CouponDialog
          open
          onClose={() => setopenCoupon(false)}
        />
      );
    }

    return null;
  };

  const onUpdateNodeProviderOrderQuantityRef = useAddFunctionToRef(
    () => onUpdateNodeProviderOrderQuantity(min, (min * price), (min * (price / 10))),
  );
  useEffect(() => {
    onUpdateNodeProviderOrderQuantityRef();
  }, [onUpdateNodeProviderOrderQuantityRef]);

  return (
    <>
      <Box
        display="flex"
        flexDirection="column"
        pl={1}
        pr={1}
      >
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          <Box pt={2} pr={4}>
            <Typography style={{ fontSize: 25, fontWeight: 550 }}>
              {productName}
            </Typography>
          </Box>
          <Box pt={2}>
            <Typography style={{ fontWeight: 550 }}>
              {priceText}
            </Typography>
          </Box>
        </Box>
        <Box>
          <Typography color="textSecondary" style={{ fontWeight: 550 }}>
            {description}
          </Typography>
        </Box>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          ml={2}
        >
          <Box pt={2}>
            <img
              src={productImage}
              alt="productName-icon"
              style={{ width: 100 }}
            />
          </Box>
          <Box pt={2}>
            <Box
              display="flex"
              alignItems="center"
            >
              <Box>
                <IconButton onClick={() => setQuantityValue(quantity - 1)}>
                  <RemoveOutlinedIcon />
                </IconButton>
              </Box>
              <Box>
                <TextField
                  name="quantity"
                  type="number"
                  variant="outlined"
                  value={quantity}
                  onChange={handleQuantityChange}
                  InputProps={{ inputProps: { min, max } }}
                />
              </Box>
              <Box>
                <IconButton onClick={() => setQuantityValue(quantity + 1)}>
                  <AddOutlinedIcon />
                </IconButton>
              </Box>
            </Box>
          </Box>
        </Box>
        <Divider />
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="flex-end"
          ml={2}
        >
          <Box pt={2}>
            <Typography color="textSecondary" style={{ fontWeight: 550 }}>
              {t('Subtotal')}
            </Typography>
          </Box>
          <Box pt={2}>
            <Typography color="textSecondary" style={{ fontWeight: 550 }}>
              {subtotal.toLocaleString('en-US', {
                style: 'currency',
                currency: 'JPY',
              })}
            </Typography>
          </Box>
        </Box>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="flex-end"
          ml={2}
        >
          <Box pt={2}>
            <Typography color="textSecondary" style={{ fontWeight: 550 }}>
              {t('Consumption Tax')}
            </Typography>
          </Box>
          <Box pt={2}>
            <Typography color="textSecondary" style={{ fontWeight: 550 }}>
              {tax.toLocaleString('en-US', {
                style: 'currency',
                currency: 'JPY',
              })}
            </Typography>
          </Box>
        </Box>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="flex-end"
          ml={2}
        >
          <Box pt={2}>
            <Typography style={{ fontWeight: 550 }}>
              {t('Total')}
            </Typography>
          </Box>
          <Box pt={2}>
            <Typography style={{ fontSize: 25, fontWeight: 550 }}>
              {total.toLocaleString('en-US', {
                style: 'currency',
                currency: 'JPY',
              })}
            </Typography>
          </Box>
        </Box>
        <Box ml={2} pt={2}>
          {getCouponContent()}
        </Box>
      </Box>
      {getCouponDialogContent()}
    </>
  );
};

NodeOwnerFormQuantity.defaultProps = {
  min: 1,
  max: 999,
  description: '',
};

NodeOwnerFormQuantity.propTypes = {
  min: PropTypes.number,
  max: PropTypes.number,
  price: PropTypes.number.isRequired,
  productName: PropTypes.string.isRequired,
  productImage: PropTypes.string.isRequired,
  description: PropTypes.string,
};

NodeOwnerFormQuantity.whyDidYouRender = true;
export default React.memo(NodeOwnerFormQuantity);
