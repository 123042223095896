import Step from '@material-ui/core/Step';
import StepContent from '@material-ui/core/StepContent';
import StepLabel from '@material-ui/core/StepLabel';
import Stepper from '@material-ui/core/Stepper';
import isEmpty from 'lodash/isEmpty';
import isNull from 'lodash/isNull';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { CustomError } from '../../modules/error_utils';

import SecretPhraseGeneratorContent from './SecretPhraseGeneratorContent';

const errorStepMessage = 'Step invalid. Please reload and try again';
const SecretPhraseGenerator = (props) => {
  const {
    onGenerateSecretPhrase,
    secretPhrase,
    onConfirmSuccess,
    customClasses,
    hideStepsTitle,
    firstStepSecondaryBtnProps,
    firstStepSecondaryBtnAction,
    onGenerateSecretPhraseSuccess,
    stepper,
    extraSteps,
    extraStepsContent,
    extraStepsActions,
    regenerateSecretPhraseWhenGoBack,
    stepActionPrecedeFn,
    precedeFnCondition,
    resetSecretPhrase,
    mustCopy,
  } = props;
  const [secretPhraseGenerated, setSecretPhraseGenerated] = useState(false);
  const [activeStep, setActiveStep] = React.useState(0);

  const { t } = useTranslation();
  const steps = {
    0: {
      title: t('Generate secret phrase'),
      descriptions: {
        primary: t('In order to generate your wallet, we will generate a phrase'),
        secondary: t('This is the ONLY WAY to restore your wallet. Please make sure to write down the secret phrase and keep it safe!'),
      },
      hints: {

        hint: t('This is the ONLY WAY to restore your wallet. Please make sure to write down the secret phrase and keep it safe!'),
        successHint: '',
      },

    },
    1: {
      title: t('Confirm secret phrase'),
      descriptions: {
        primary: t('Input secret phrase in the right order'),
      },
      hints: {
        hint: '',
        successHint: t('Confirmed successfully'),
        errorHint: t('Secret phrase does not match'),
      },

    },
  };
  const withExtraSteps = R.merge(steps, extraSteps || {});

  /* STEPS */

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };
  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const createGetStepDataByKey = R.curry((steps, activeStep, key) => {
    const stepData = steps[activeStep];
    if (isEmpty(stepData)) throw (new CustomError(errorStepMessage));
    const data = stepData[key];
    if (isEmpty(data)) return '';
    return data;
  });

  const getStepDataByKey = createGetStepDataByKey(withExtraSteps, activeStep);

  const getStepDescription = R.curry(
    (
      steps,
      activeStep,
      secretPhraseGenerated,
    ) => {
      try {
        const descriptions = getStepDataByKey('descriptions');

        switch (activeStep) {
          case 0:
            return !secretPhraseGenerated ? descriptions.primary : descriptions.secondary;
          default:
            return descriptions.primary;
        }
      } catch (error) {
        console.log(error);
      }
    },
  );

  const getContinueButtonText = (activeStep) => {
    if (activeStep === 0 || activeStep === 1) return t('confirm');
    return t('continue');
  };
  const getHintText = (steps, activeStep, secretPhraseSaved, secretPhraseConfirmed) => {
    try {
      const hints = getStepDataByKey('hints');

      switch (activeStep) {
        // temporary disable successHint (changed to empty string)
        case 0:
          return secretPhraseSaved ? hints.successHint : '';
        case 1: {
          const isError = secretPhraseConfirmed === false;
          if (isNull(secretPhraseConfirmed)) return '';
          return isError ? hints.errorHint : hints.successHint;
        }
        default:
          return hints.hint;
      }
    } catch (error) {
      // todo redirect to error component
      console.log(error);
    }
  };

  const getHideStepTitle = (hideStepsTitle) => {
    if (!hideStepsTitle) return false;
    const hideStepTitle = hideStepsTitle[activeStep] || {};
    return hideStepTitle;
  };
  const getStepTitle = (activeStep) => {
    try {
      const hideStepTitle = getHideStepTitle(hideStepsTitle);
      if (hideStepTitle) return '';
      const title = getStepDataByKey('title');
      switch (activeStep) {
        case 1:
          return !secretPhraseGenerated ? title.primary : title.secondary;
        default:
          return title.primary;
      }
    } catch (error) {
      // todo redirect to error component
      console.log(error);
    }
  };

  const generatorProps = {
    t,
    onGenerateSecretPhrase,
    secretPhrase,
    onConfirmSuccess,
    customClasses,
    hideStepsTitle,
    firstStepSecondaryBtnProps,
    firstStepSecondaryBtnAction,
    onGenerateSecretPhraseSuccess,
    handleNext,
    handleBack,
    getStepTitle,
    activeStep,
    getStepDescription,
    getHintText,
    getContinueButtonText,
    setSecretPhraseGenerated,
    secretPhraseGenerated,
    extraStepsContent,
    extraStepsActions,
    withExtraSteps,
    regenerateSecretPhraseWhenGoBack,
    stepActionPrecedeFn,
    precedeFnCondition,
    resetSecretPhrase,
    mustCopy,
  };

  return stepper ? (
    <Stepper activeStep={activeStep} orientation="vertical">
      {
        R.pipe(
          R.toPairs,
          R.map(
            (step) => {
              const [, stepData] = step;
              const { title } = stepData;
              return (
                <Step key={title}>
                  <StepLabel>{title}</StepLabel>
                  <StepContent>
                    <SecretPhraseGeneratorContent
                      {...generatorProps}
                    />
                  </StepContent>
                </Step>
              );
            },

          ),
        )(withExtraSteps)

      }
    </Stepper>
  ) : (
    <SecretPhraseGeneratorContent
      {...generatorProps}

    />

  );
};

SecretPhraseGenerator.propTypes = {
  onGenerateSecretPhrase: PropTypes.func,
  secretPhrase: PropTypes.string,
  onConfirmSuccess: PropTypes.func.isRequired,
  onClose: PropTypes.func,
  customClasses: PropTypes.shape({
    wrapPaper: PropTypes.string.isRequired,
  }),
  hideStepsTitle: PropTypes.bool,
  firstStepSecondaryBtnProps: PropTypes.object,
  firstStepSecondaryBtnAction: PropTypes.func,
  onGenerateSecretPhraseSuccess: PropTypes.func,
  stepper: PropTypes.bool,
  extraSteps: PropTypes.shape({
    2: PropTypes.shape({
      title: PropTypes.string.isRequired,
      descriptions: PropTypes.shape({
        primary: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
    3: PropTypes.shape({
      title: PropTypes.string.isRequired,
      descriptions: PropTypes.shape({
        primary: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
    4: PropTypes.shape({
      title: PropTypes.string.isRequired,
      descriptions: PropTypes.shape({
        primary: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
  }),
  extraStepsContent: PropTypes.shape({
    2: PropTypes.element.isRequired,
    3: PropTypes.element.isRequired,
    4: PropTypes.element.isRequired,
  }),
  extraStepsActions: PropTypes.shape({
    2: PropTypes.shape({
      secondary: PropTypes.string.isRequired,
      isFormAction: PropTypes.bool.isRequired,
      formId: PropTypes.string.isRequired,
      isExtra: PropTypes.bool.isRequired,
      btnTexts: PropTypes.shape({
        primary: PropTypes.string.isRequired,
        secondary: PropTypes.string.isRequired,
      }).isRequired,
      disabled: PropTypes.bool.isRequired,
    }).isRequired,
    3: PropTypes.shape({
      primary: PropTypes.string.isRequired,
      secondary: PropTypes.string.isRequired,
      isExtra: PropTypes.bool.isRequired,
      btnTexts: PropTypes.shape({
        primary: PropTypes.string.isRequired,
        secondary: PropTypes.string.isRequired,
      }).isRequired,
      disabled: PropTypes.bool.isRequired,
    }).isRequired,
    4: PropTypes.shape({
      primary: PropTypes.func.isRequired,
      secondary: PropTypes.string.isRequired,
      isExtra: PropTypes.bool.isRequired,
      btnTexts: PropTypes.shape({
        primary: PropTypes.string.isRequired,
        secondary: PropTypes.string.isRequired,
      }).isRequired,
      loading: PropTypes.bool,
    }),
  }),
  regenerateSecretPhraseWhenGoBack: PropTypes.bool,
  stepActionPrecedeFn: PropTypes.func,
  precedeFnCondition: PropTypes.bool,
  resetSecretPhrase: PropTypes.bool,
  mustCopy: PropTypes.bool,
};

SecretPhraseGenerator.defaultProps = {
  onClose: () => { },
  onGenerateSecretPhrase: () => { },
  secretPhrase: null,
  customClasses: null,
  hideStepsTitle: false,
  firstStepSecondaryBtnProps: null,
  firstStepSecondaryBtnAction: () => {},
  onGenerateSecretPhraseSuccess: () => {},
  stepper: false,
  extraSteps: null,
  extraStepsContent: null,
  extraStepsActions: null,
  regenerateSecretPhraseWhenGoBack: false,
  stepActionPrecedeFn: () => {},
  precedeFnCondition: false,
  resetSecretPhrase: false,
  mustCopy: false,
};

export default SecretPhraseGenerator;
