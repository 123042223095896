import Box from '@material-ui/core/Box';
import WarningOutlinedIcon from '@material-ui/icons/WarningOutlined';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import ReactTableV7 from '../../Tables/ReactTableV7';
import CustomTooltip from '../../Tooltip/CustomTooltip';
import InvestmentEarningDashboardItem from '../InvestmentNodeDetailsPageItem';

// others
import { ununifiValidatorEarningHistoryColumns } from './columns';

const UnUniFiValidatorEarningHistory = () => {
  const { t } = useTranslation();
  const {
    getIntervalEarningLoading,
  } = useSelector((state) => state.loadingR);
  const intervalEarning = useSelector((state) => state.nodeR.nodesEarning.ununifi.interval);

  const columnsToUse = ununifiValidatorEarningHistoryColumns(
    t,
  );

  return (
    <InvestmentEarningDashboardItem
      titlePadding="21px 32px 16px 32px"
      title={(
        <Box display="flex" alignItems="center">
          <Box>
            {`${t('Daily Statistics')}`}
          </Box>
          <Box ml={0.5} mr={2}>
            <CustomTooltip
              title={t('Coming Soon')}
              content={(
                <WarningOutlinedIcon
                  color="disabled"
                  style={{ fontSize: 20 }}
                />
              )}
            />
          </Box>
        </Box>
)}
      content={(
        <ReactTableV7
          data={intervalEarning}
          columns={columnsToUse}
          customPageSize={3}
          customPageSizeList={[3, 5, 10, 20, 50]}
          loading={getIntervalEarningLoading}
        />
)}
    />
  );
};

export default UnUniFiValidatorEarningHistory;
