import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import isEmpty from 'lodash/isEmpty';
import * as R from 'ramda';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { getGitbookLink } from '../../../../modules/gitbook';
import { RoundedButton } from '../../../MaterialUiCustom/MaterialUiCustom';

import { addressConnectStyles } from './styles';

import ButtonLoading from 'components/MaterialUiCustom/Button/ButtonLoading';
import { useAddFunctionToRef } from 'hooks/hooks';
import { web3Operations } from 'store/web3';

const useStyles = makeStyles(addressConnectStyles);

const BoxWrapper = ({ children }) => (
  <Box>
    <Box>
      {children}
    </Box>
  </Box>
);

const MiningAddressCreationConnect = ({
  enableWeb3Loading,
  isExtensionsInjected,
  accounts,
  autoConnect,
  openAddressCreation,
  chain,
  ss58Format,
  setActiveStep,
  activeStep,
  handleOnCancel,
  resetAllChildren,
}) => {
  const isAccountEmpty = isEmpty(accounts);
  const extensionNotInjected = !isExtensionsInjected;
  const extensionsInjectedButEmptyAccounts = isExtensionsInjected && isAccountEmpty;
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const lang = useSelector((state) => state.langR.langSetting.lang);

  const onEnableWeb3 = useAddFunctionToRef(async (chain, ss58Format) => {
    dispatch(await web3Operations.enableWeb3(chain, { ss58Format }));
  });
  // ENABLE WEB3
  useEffect(() => {
    if (openAddressCreation && autoConnect) onEnableWeb3();
  }, [autoConnect, onEnableWeb3, openAddressCreation]);
  const onWeb3AccountsChange = useAddFunctionToRef(async (chain, { ss58Format }) => {
    dispatch(await web3Operations.onWeb3AccountsChange(chain, { ss58Format }));
  });

  useEffect(() => {
    if (isExtensionsInjected) onWeb3AccountsChange(chain, { ss58Format });
    if (!isExtensionsInjected) setActiveStep(0);
  }, [chain, isExtensionsInjected, onWeb3AccountsChange, setActiveStep, ss58Format]);

  useEffect(() => {
    if (activeStep !== 0 && extensionsInjectedButEmptyAccounts) setActiveStep(0);
  }, [activeStep, extensionsInjectedButEmptyAccounts, setActiveStep]);

  useEffect(() => {
    const moveToSelectAddress = R.and(
      !isAccountEmpty,
      isExtensionsInjected && activeStep === 0,
    );
    if (moveToSelectAddress) setActiveStep(1);
  }, [activeStep, isAccountEmpty, isExtensionsInjected, setActiveStep]);

  useEffect(() => {
    if (resetAllChildren) {
      handleOnCancel();
    }
  }, [handleOnCancel, resetAllChildren]);

  const handleMainBtnText = () => {
    if (extensionNotInjected) return t('reload');
    const reconnectText = t('reconnect');
    if (extensionsInjectedButEmptyAccounts) return reconnectText;
    return reconnectText;
  };

  const mainBtnAction = () => {
    if (extensionNotInjected) return window.location.reload();
    if (extensionsInjectedButEmptyAccounts) return onEnableWeb3(chain, ss58Format);
  };
  const installationDocUrl = getGitbookLink('phala-network/how-to-add-pha-address/polkadot-.js-extension-guide', lang);
  const extensionSettingDocUrl = getGitbookLink('phala-network/how-to-add-pha-address/allow-a-website-in-polkadot-.js-extension', lang);
  const addAccountDocUrl = getGitbookLink('phala-network/how-to-add-pha-address/polkadot-.js-extension-guide#add-an-account-in-polkadot-js-extension', lang);

  const getConnectContent = (isExtensionsInjected) => {
    const extensionNotInjected = !isExtensionsInjected;
    const extensionsInjectedButEmptyAccounts = isExtensionsInjected && isAccountEmpty;
    const NotInjectedText = (
      <Typography className={classes.notInjectedText}>
        { t('Please install Polkadot{.js} extension. If you have already installed the extension, please make sure that app.neukind.com is allowed in its setting')}

        <Link href={installationDocUrl} target="_blank">
          {t('Install Polkadot{.js} extension')}
        </Link>
        <Link href={extensionSettingDocUrl} target="_blank">
          {t('Polkadot{.js} extension setting guide')}
        </Link>
      </Typography>
    );

    const InjectedButEmptyAcccountsText = (
      <Typography className={classes.notInjectedText}>
        { t("You don't have any account on your Polkadot{.js} extension. Please add at least one account")}
        <Link href={addAccountDocUrl} target="_blank">
          {t('How to add account in Polkadot{.js} extension')}
        </Link>
      </Typography>
    );

    const ActionSection = (
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="flex-end"
      >
        <RoundedButton
          onClick={handleOnCancel}
          color="secondary"
          disabled={enableWeb3Loading}
        >
          cancel
        </RoundedButton>
        <ButtonLoading
          rounded
          onClick={() => mainBtnAction()}
          color="primary"
          loading={Boolean(enableWeb3Loading)}
          disabled={enableWeb3Loading}
        >
          {handleMainBtnText()}
        </ButtonLoading>
      </Box>
    );
    if (extensionNotInjected) {
      return (
        <Box>
          <Box>
            <BoxWrapper>
              {NotInjectedText}
            </BoxWrapper>
          </Box>
          {ActionSection}
        </Box>
      );
    }
    if (extensionsInjectedButEmptyAccounts) {
      return (
        <Box>
          <Box>
            <BoxWrapper>
              {InjectedButEmptyAcccountsText}
            </BoxWrapper>
          </Box>
          {ActionSection}
        </Box>
      );
    }
    return (
      !isAccountEmpty
        ? (
          <BoxWrapper>
            <Typography variant="body2">
              {t('connected')}
            </Typography>
          </BoxWrapper>
        )
        : (
          <Box>
            <Box>
              <BoxWrapper>
                {NotInjectedText}
              </BoxWrapper>
            </Box>
            {ActionSection}
          </Box>

        )

    );
  };
  return (enableWeb3Loading
    ? <Box display="flex" justifyContent="center" p={1}><CircularProgress /></Box>
    : (
      getConnectContent(isExtensionsInjected)
    )
  );
};

export default MiningAddressCreationConnect;
