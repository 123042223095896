import BigNumber from 'bignumber.js';
import isInteger from 'lodash/isInteger';
import isNaN from 'lodash/isNaN';
import isNil from 'lodash/isNil';
import isUndefined from 'lodash/isUndefined';
import round from 'lodash/round';
import toNumber from 'lodash/toNumber';
import * as R from 'ramda';
import { isNumber, isPlainObject } from 'ramda-adjunct';

export const getApr = (inititalAmount, earning, nodeAge) => {
  const apr = round(
    (
      ((earning / inititalAmount) / nodeAge) * 365
    ) * 100,
    2,
  );
  const getAprSucceed = !isNaN(apr) && !isNil(apr);
  return getAprSucceed ? apr : 0;
};

/**
   * Get node age in days
   * @param  {string} dateCreated - the date when the node is active
   * @return {number}
   */
export const getNodeAge = (activeDate) => {
  const dateObj = new Date(activeDate);
  const now = new Date();
  const diff = (now.getTime() - dateObj.getTime()) / (1000 * 60 * 60 * 24);
  const nodeAge = Math.abs(round(diff, 2));
  return nodeAge;
};

export const percentage = (value, round) => {
  const result = (value * 100).toFixed(round);
  return result;
};

export const percentageText = (value) => {
  const result = `${value * 100}%`;
  return result;
};

export const toFiat = (value, currency) => {
  const result = toNumber(value).toLocaleString('en-US', {
    style: 'currency',
    currency,
  });

  return result;
};

/**
 * We are using toFixed() to format the number which can return a string 'NaN'.
 * So we add both non-string NaN and string NaN.
 */
export const isValid = (value) => !isNil(value) && !isNaN(value) && !isUndefined(value) && value !== 'NaN';
const handlePrice = R.curry((coin, priceData) => {
  if (isPlainObject(priceData)) {
    const len = coin ? R.lensPath([coin, 'price']) : R.lensPath(['price']);
    return R.pipe(
      R.view((len)),
      (price) => price || 0,
    )(priceData);
  }
  if (isNumber(priceData)) {
    return priceData;
  }
  return 0;
});
export const convertCryptoToFiat = R.curry((coin, priceData, cryptoValue) => {
  const price = handlePrice(coin, priceData);
  return new BigNumber(cryptoValue).times(new BigNumber(price)).toNumber();
});

export const convertToCryptoNumber = (value) => {
  const valueToUse = toNumber(value);
  if (!valueToUse) {
    return '0';
  }
  return isInteger(valueToUse) ? valueToUse.toFixed(0) : valueToUse.toFixed(3);
};

export const convertFiatToCrypto = (data, value, coinType) => {
  const priceData = R.prop(coinType, data);
  const { price } = priceData || { };
  if (!price) {
    return '0';
  }
  return convertToCryptoNumber(value / price);
};
