import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import whyDidYouRender from '@welldone-software/why-did-you-render';
import PropTypes from 'prop-types';
import React from 'react';

import ConnectToWallet from '../../ConnectToWallet/ConnectToWallet';
import ConnectToWalletCalculator from '../ConnectToWalletCalculator/ConnectToWalletCalculator';

import {
  eth2DeployConnectToWalletStyles,
} from './styles';

const { REACT_APP_ENV } = process.env;

const useStyles = makeStyles(eth2DeployConnectToWalletStyles);

if (REACT_APP_ENV !== 'prod') {
  whyDidYouRender(React);
}

const Eth2DeployConnectToWallet = ({
  context,
  walletConnected,
  showPopupWrongNetworkError,
  setActivatingConnector,
  wrongNetwork,
  balance,
  onDisconnectConnector,
  onApproveTx,
}) => {
  const classes = useStyles();
  return (
    (
      <Box className={classes.connectWallet}>
        <ConnectToWallet
          AfterConnectedComponent={ConnectToWalletCalculator}
          context={context}
          walletConnected={walletConnected}
          setActivatingConnector={setActivatingConnector}
          wrongNetwork={wrongNetwork}
          balance={balance}
          onDisconnectConnector={onDisconnectConnector}
          onApproveTx={onApproveTx}
          showPopupWrongNetworkError={showPopupWrongNetworkError}
        />
      </Box>
    )
  );
};

Eth2DeployConnectToWallet.propTypes = {
};

Eth2DeployConnectToWallet.defaultProps = {
  context: {
    connector: null,
    library: null,
    chainId: 0,
    account: null,
    active: false,
    error: null,
  },
  walletConnected: false,
  setActivatingConnector: () => {},
  balance: 0,
  onDisconnectConnector: () => {},
  onApproveTx: () => {},
  wrongNetwork: false,
  showPopupWrongNetworkError: () => {},
};

Eth2DeployConnectToWallet.propTypes = {
  /**
   * The types of the properties of the context can be found
   * {@link https://github.com/NoahZinsmeister/web3-react/tree/v6/docs#overview}
   */
  context: PropTypes.shape({
    connector: PropTypes.object,
    library: PropTypes.any,
    chainId: PropTypes.number,
    account: PropTypes.string,
    activate: PropTypes.func.isRequired,
    deactivate: PropTypes.func.isRequired,
    active: PropTypes.bool.isRequired,
    error: PropTypes.object,
  }),
  walletConnected: PropTypes.bool,
  setActivatingConnector: PropTypes.func,
  balance: PropTypes.number,
  onDisconnectConnector: PropTypes.func,
  onApproveTx: PropTypes.func,
  wrongNetwork: PropTypes.bool,
  showPopupWrongNetworkError: PropTypes.func,
};

Eth2DeployConnectToWallet.whyDidYouRender = true;
export default React.memo(Eth2DeployConnectToWallet);
