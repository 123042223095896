import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';
import React from 'react';

export const Filter = ({ column }) => (
  <Box style={{ marginTop: 5 }}>
    {column.canFilter && column.render('Filter')}
  </Box>
);

export const DefaultColumnFilter = ({
  column: {
    filterValue,
    setFilter,
    preFilteredRows: { length },
  },
}) => (
  <TextField
    value={filterValue || ''}
    onChange={(e) => {
      e.persist();
      console.log(setFilter);
      setFilter(e.target.value || undefined);
    }}
    placeholder={`search (${length}) ...`}
  />
);
