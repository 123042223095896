import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import isNumber from 'lodash/isNumber';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import React from 'react';
import { useTranslation } from 'react-i18next';

const ChainInfoRequirements = (props) => {
  const {
    hardware,
    stakingAmount,
    cryptoUnit,
  } = props;
  const { t } = useTranslation();
  const getHardwareContent = R.pipe(
    R.toPairs,
    R.map((each) => (
      <Typography variant="body1" key={each[0]}>{`${each[0]}: ${each[1]}`}</Typography>
    )),
  )(hardware);

  const getStakingAmountContent = R.curry((stakingAmount, cryptoUnit) => (isNumber(stakingAmount)
    ? `${stakingAmount} ${cryptoUnit}`
    : stakingAmount));
  return (
    <Grid container direction="row" spacing={8}>
      <Grid item xs={12} sm={6}>
        <Typography variant="body1" style={{ fontWeight: 500 }}>
          {t('Hardware')}
          :
        </Typography>
        {getHardwareContent}
      </Grid>
      <Grid item xs={12} sm={6}>
        <Typography variant="body1" style={{ fontWeight: 500 }}>
          {t('Staking amount')}
          :
        </Typography>
        <Typography variant="body1">{getStakingAmountContent(stakingAmount, cryptoUnit)}</Typography>
      </Grid>
    </Grid>
  );
};

ChainInfoRequirements.propTypes = {
  stakingAmount: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  hardware: PropTypes.shape({
    CPU: PropTypes.string.isRequired,
    RAM: PropTypes.string.isRequired,
    SSD: PropTypes.string.isRequired,
  }).isRequired,
  cryptoUnit: PropTypes.string.isRequired,
};
export default ChainInfoRequirements;
