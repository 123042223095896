import Box from '@material-ui/core/Box';
import Dialog from '@material-ui/core/Dialog';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';

// constants
import { drawerWidth } from '../../NavigationDrawer/constants';

const styles = (theme) => ({
  spinner: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  spinnerShift: {
    marginLeft: drawerWidth,
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  childrenWrap: {
    padding: theme.spacing(1) * 3,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
  },
});
const useStyles = makeStyles(styles);

const DialogWrapped = (props) => {
  const classes = useStyles();
  const openNavDrawer = useSelector((state) => state.handleDrawerR.openNavDrawer);
  const {
    open, fullScreen, fullWidth, maxWidth, children,
  } = props;
  return (
    <Dialog
      fullScreen={fullScreen}
      fullWidth={fullWidth}
      maxWidth={maxWidth}
      open={open}
      className={classNames(classes.spinner, openNavDrawer && classes.spinnerShift)}
      aria-labelledby="dialog"
    >
      <Box className={classes.childrenWrap}>
        {children}
      </Box>
    </Dialog>
  );
};

DialogWrapped.defaultProps = {
  fullScreen: false,
  fullWidth: false,
  maxWidth: 'lg',
  children: null,
};

DialogWrapped.propTypes = {
  // openNavDrawer: PropTypes.bool.isRequired,
  open: PropTypes.bool.isRequired,
  fullScreen: PropTypes.bool,
  fullWidth: PropTypes.bool,
  maxWidth: PropTypes.string,
  children: PropTypes.element,
};

export default DialogWrapped;
