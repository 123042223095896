import gql from 'graphql-tag';

export const createDepositMutation = gql`
  mutation createDeposit(
    $createDepositInput: CreateDepositInput!
  )
  {
    createDeposit(
      createDepositInput: $createDepositInput
    ) {
      depositId
      steps{
        activeStep
        stepsData {
          step
        }
      }
      depositData{
        depositDataId
        nodeId
        data
      }
    }
  }
`;
