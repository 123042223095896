export const singupStyles = (theme) => ({
  paper: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(2),
    maxWidth: 512,
    marginBottom: theme.spacing(2),
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  button: {
    width: '100%',
    margin: theme.spacing(1, 0),
    padding: theme.spacing(1, 0),
  },
  divider: {
    ...theme.mixins.gutters(),
    marginBottom: theme.spacing(1),
    maxWidth: 464,
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  centerComponent: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'stretch',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  form: {
    flexDirection: 'column',
  },
  textField: {
    width: '100%',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
  },
  copyright: {
    marginTop: theme.spacing(5),
  },
  title: {
    fontWeight: 600,
    textTransform: 'capitalize',
  },
  content: {
    marginBottom: theme.spacing(2),
    width: '100%',
  },
  signInButton: {
    fontSize: 16,
    padding: 0,
  },
});

export const signupAgreementsStyles = (theme) => ({
  checkbox: {
    marginBottom: 14,
  },
});
