import _ from 'lodash';

export const valueValidate = (
  testValue,
  testKey,
  values,
  testPara,
  message,
  required,
) => {
  const errors = {};
  const testCondition = !_.isArray(testPara)
    ? testPara.test(testValue)
    : testPara.includes(testValue);
  if (!testValue && required) {
    errors[testKey] = 'Required';
  } else if (!testCondition) {
    errors[testKey] = message || `Invalid ${testKey} address`;
  }
  return errors;
};

export const requiredValueValidate = (valueItem) => {
  const errors = {};
  const key = Object.keys(valueItem)[0];
  const value = Object.values(valueItem)[0];
  if (!value) {
    errors[key] = 'Required';
  }
  return errors;
};

const handleCondition = ({ testCases, testValue }) => {
  const testCondition = !_.isArray(testCases)
    ? testCases.test(testValue)
    : testCases.includes(testValue);
  return testCondition;
};
export const patternValueValidate = ({ valueItem, testCases, message }) => {
  const errors = {};
  const key = Object.keys(valueItem)[0];
  const value = Object.values(valueItem)[0];
  const passed = handleCondition({ testCases, testValue: value });
  if (!passed) {
    errors[key] = message;
  }
  return errors;
};

export const minLength = (min) => (value) => (value && value.length < min ? `Must be ${min} characters or more` : undefined);

export const minLength8 = minLength(8);
