import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import whyDidYouRender from '@welldone-software/why-did-you-render';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import React from 'react';
import { CSVLink } from 'react-csv';
import { useTranslation } from 'react-i18next';

// compoments
import ReactTableV7 from '../../Tables/ReactTableV7';
import { createUsersSuccessColumns } from '../columns';
import { createUsersSuccessStyles } from '../styles';
// columns

if (process.env.REACT_APP_ENV !== 'prod') {
  whyDidYouRender(React);
}

const useStyles = makeStyles(createUsersSuccessStyles);

const CreateUsersSuccess = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const {
    data,
  } = props;

  const csvHeaders = [
    { label: 'User Id', key: 'userId' },
    { label: 'Email', key: 'email' },
    { label: 'Role', key: 'role' },
    { label: 'Joined Since', key: 'joined' },
    { label: 'Activated', key: 'activated' },
  ];

  const formatedDataForDownload = R.map(
    (user) => ({
      ...user,
      activated: R.prop('activated', user).toString(),
    }),
  )(data);

  const columnsToUse = createUsersSuccessColumns(t);

  return (
    <>
      <Button className={classes.downloadButton}>
        <CSVLink
          data={formatedDataForDownload}
          headers={csvHeaders}
          filename="created_users.csv"
          style={{ textDecoration: 'none' }}
        >
          <Typography color="primary" className={classes.downloadButtonText}>
            {t('Download CSV')}
          </Typography>
        </CSVLink>
      </Button>
      <ReactTableV7
        data={data}
        columns={columnsToUse}
        customPageSize={data.length}
        customPageSizeList={[data.length]}
        disablePaging
      />
    </>
  );
};

CreateUsersSuccess.defaultProps = {
  data: [],
};

CreateUsersSuccess.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      userId: PropTypes.string,
      email: PropTypes.string,
      role: PropTypes.string,
      joined: PropTypes.string,
      activated: PropTypes.bool,
    }),
  ),
};

CreateUsersSuccess.whyDidYouRender = true;
export default React.memo(CreateUsersSuccess);
