import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { gweiToEther } from 'jsutils';
import * as R from 'ramda';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { convertCryptoToFiat, convertToCryptoNumber } from '../utils/utils';

import EarningDataCard from './EarningDataCard';
import { earningDashBoardStyles } from './styles';

const useStyles = makeStyles(earningDashBoardStyles);

const EarningDashboardOneNode = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  const coinPrice = useSelector((state) => state.nodeR.coinPrice);
  const { ETH } = coinPrice;
  const { currency } = ETH || { currency: 'JPY' };
  const nodesEarning = useSelector((state) => state.nodeR.nodesEarning.eth2);
  const { totalEarningIn24hOneNode, totalEarningIn7dOneNode } = nodesEarning;
  const totalEarningIn24h = gweiToEther(totalEarningIn24hOneNode);
  const totalEarningIn7d = gweiToEther(totalEarningIn7dOneNode);

  const {
    getCoinPriceLoading,
    getTotalEarningIn24hOneNodeLoading,
    getTotalEarningIn7dOneNodeLoading,
  } = useSelector((state) => state.loadingR);

  const getFormattedPrimaryText = R.curry((text) => (
    <Typography
      className={classes.primaryText}
      align="center"
    >
      {text}
    </Typography>
  ));

  const getFormattedSecondaryText = R.curry((text) => (
    <Typography
      align="center"
      variant="button"
      color="textSecondary"
    >
      {text}
    </Typography>
  ));

  return (
    <Grid
      container
      spacing={1}
      alignItems="stretch"
    >
      <Grid item xs={6} sm={4}>
        <EarningDataCard
          title={t('ETH Price')}
          loading={getCoinPriceLoading}
          content={
            (
              <Grid container spacing={2} direction="column" alignItems="center">
                <Grid item>
                  {getFormattedPrimaryText(`${coinPrice.ETH.price.toLocaleString('en-US', {
                    style: 'currency',
                    currency,
                  })}`)}
                </Grid>
                <Grid item>
                  {getFormattedSecondaryText('1 ETH')}
                </Grid>
              </Grid>
            )
          }
        />
      </Grid>
      <Grid item xs={6} sm={4}>
        <EarningDataCard
          title={t('Last 7 days earning')}
          loading={getTotalEarningIn7dOneNodeLoading}
          content={(
            <Grid container spacing={2} direction="column" alignItems="center">
              <Grid item>
                {getFormattedPrimaryText(`${convertCryptoToFiat('ETH', coinPrice, totalEarningIn7d).toLocaleString('en-US', {
                  style: 'currency',
                  currency,
                })}`)}
              </Grid>
              <Grid item>
                {getFormattedSecondaryText(`≈ ${(convertToCryptoNumber(totalEarningIn7d))} ETH`)}
              </Grid>
            </Grid>
          )}
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <EarningDataCard
          title={t('Last 24h earning')}
          loading={getTotalEarningIn24hOneNodeLoading}
          content={(
            <Grid container spacing={2} direction="column" alignItems="center">
              <Grid item>
                {getFormattedPrimaryText(`${convertCryptoToFiat('ETH', coinPrice, totalEarningIn24h).toLocaleString('en-US', {
                  style: 'currency',
                  currency,
                })}`)}
              </Grid>
              <Grid item>
                {getFormattedSecondaryText(`≈ ${(convertToCryptoNumber(totalEarningIn24h))} ETH`)}
              </Grid>
            </Grid>
          )}
        />
      </Grid>
    </Grid>
  );
};

EarningDashboardOneNode.propTypes = {
};

export default EarningDashboardOneNode;
