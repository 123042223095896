import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React from 'react';

import { styles } from './styles';

const useStyles = makeStyles(styles);

const ErrorMessage = (props) => {
  const classes = useStyles();

  const {
    errorMessage,
    ...properties
  } = props;

  return (
    <Typography color="error" {...properties} className={classes.errorText}>{errorMessage}</Typography>
  );
};

ErrorMessage.defaultProps = {
  errorMessage: '',
};

ErrorMessage.propTypes = {
  errorMessage: PropTypes.string,
};

export default ErrorMessage;
