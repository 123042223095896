import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import isString from 'lodash/isString';
import PropTypes from 'prop-types';
import React from 'react';

import { overviewEmptyItemStyles } from './styles';

const useStyles = makeStyles(overviewEmptyItemStyles);
const OverviewEmptyItem = (props) => {
  const classes = useStyles();

  const { text } = props;

  const getTextContent = (text) => {
    if (isString(text)) {
      return (
        <Typography
          color="textSecondary"
          align="center"
          className={classes.emptyItemContent}
        >
          {text}
        </Typography>
      );
    }

    return (
      <Box
        className={classes.emptyItemContent}
      >
        {text}
      </Box>
    );
  };

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      className={classes.emptyItem}
    >
      <Box>
        {getTextContent(text)}
      </Box>
    </Box>
  );
};

OverviewEmptyItem.propTypes = {
  text: PropTypes.oneOfType(
    [
      PropTypes.element,
      PropTypes.string,
    ],
  ),
};

OverviewEmptyItem.defaultProps = {
  text: '',
};

export default React.memo(OverviewEmptyItem);
