import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import * as R from 'ramda';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import InvestmentEarningDashboardItem from '../InvestmentNodeDetailsPageItem';

import { nodeOwnerOverviewStyles } from './styles';

const useStyles = makeStyles(nodeOwnerOverviewStyles);

const NeunodeOverview = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  const totalEarningData = useSelector((state) => state.nodeR.nodeOwnerEarning.total.neunode);
  const { items: orders } = useSelector((state) => state.nodeOwnerR.nodeOwnerOrder.neunode);
  const { contract } = orders[0] || {};
  const {
    getOrdersLoading,
  } = useSelector((state) => state.loadingR);

  const getOverviewItemContent = (text) => (
    <Typography
      variant="body1"
      align="center"
      className={classes.overviewText}
    >
      {text}
    </Typography>
  );

  const { total: totalEarning, currency: currencySymbol } = totalEarningData || { total: 0, currency: 'JPY' };

  const activeNodesCount = R.pipe(
    R.map(
      R.pipe(
        R.prop('devices'),
        R.filter((item) => {
          const { contract } = item;
          const { contractEndDate: endDate } = contract;
          const today = new Date();
          const contractEndDate = new Date(endDate);

          const diff = today.getTime() - contractEndDate.getTime();

          return diff < 0 && R.propEq('deviceStatus', 'ACTIVE', item);
        }),
        (devices) => devices.length,
      ),
    ),
    R.sum,
  )(orders);
  const nodesCount = R.pipe(
    R.map(
      R.prop('quantity'),
    ),
    R.sum,
  )(orders);

  const getActiveNodeContent = (value, max) => {
    const percent = (value / max) * 100;
    return (
      <Box className={classes.container}>
        <Box position="relative" display="inline-flex">
          <CircularProgress
            variant="determinate"
            value={100}
            size={100}
            className={classes.dataWithCircleBg}
          />
          <Box
            top={0}
            left={0}
            bottom={0}
            right={0}
            position="absolute"
          >
            <CircularProgress
              variant="determinate"
              value={percent}
              size={100}
              className={classes.dataWithCircle}
            />
          </Box>
          <Box
            top={0}
            left={0}
            bottom={0}
            right={0}
            position="absolute"
            display="flex"
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
          >
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <Typography variant="body1" className={classes.percent}>{`${value}/${max}`}</Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    );
  };

  return (
    <Grid container alignItems="stretch" spacing={2}>
      <Grid item xs={6} sm={4}>
        <InvestmentEarningDashboardItem
          align="center"
          title={t('Active Node')}
          content={getActiveNodeContent(activeNodesCount, nodesCount)}
          loading={getOrdersLoading}
          loadingVariant="circle"
        />
      </Grid>
      <Grid item xs={6} sm={4}>
        <Grid container style={{ height: '100%', margin: 0 }}>
          <Grid item xs={12} style={{ marginBottom: '4px' }}>
            <InvestmentEarningDashboardItem
              align="center"
              title={t('Fixed-term Contract')}
              content={getOverviewItemContent(`${contract.contractPeriodValue} ${contract.contractPeriod}`)}
              loading={getOrdersLoading}
            />
          </Grid>
          <Grid item xs={12} style={{ marginTop: '4px' }}>
            <InvestmentEarningDashboardItem
              align="center"
              title={t('ROI')}
              content={getOverviewItemContent(`${(contract.contractIRR * 100).toFixed(2)}%`)}
              loading={getOrdersLoading}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} sm={4}>
        <InvestmentEarningDashboardItem
          align="center"
          title={t('Total Earnings')}
          content={(
            <Typography
              variant="body1"
              align="center"
              className={classes.totalEarning}
            >
              {totalEarning.toLocaleString('en-US', {
                style: 'currency',
                currency: currencySymbol,
              })}
            </Typography>
          )}
          loading={getOrdersLoading}
        />
      </Grid>
    </Grid>
  );
};

export default NeunodeOverview;
