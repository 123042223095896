import * as R from 'ramda';

import * as types from './types';

export const setWalletSelectedAccount = R.curry(
  (payload) => ({
    payload,
    type: types.SET_WALLET_SELECTED_ACCOUNT,
  }),
);

export const resetWalletSelectedAccount = R.curry(
  () => ({
    type: types.RESET_WALLET_SELECTED_ACCOUNT,
  }),
);

export const setWalletConnectMethod = R.curry(
  (payload) => ({
    payload,
    type: types.SET_WALLET_CONNECT_METHOD,
  }),
);
