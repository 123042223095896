import Box from '@material-ui/core/Box';
import Checkbox from '@material-ui/core/Checkbox';
import Typography from '@material-ui/core/Typography';
import toNumber from 'lodash/toNumber';
import * as R from 'ramda';
import React from 'react';
// others
import Moment from 'react-moment';

import { isValid } from '../../utils/utils';

export const poolListColumns = (
  t,
  isMobileDevice,
  viewOrderActions,
) => ([
  {
    accessor: 'email',
    Header: t('Email'),
    width: 250,
    Cell: (data) => {
      const email = R.view(R.lensPath(['row', 'original', 'email']), data) || 'N/A';
      return (
        <Box position="absolute" top="50%" style={{ transform: 'translateY(-50%)' }}>
          <Typography noWrap style={{ width: 250, fontSize: 14 }}>
            {email}
          </Typography>
        </Box>
      );
    },
  },
  {
    accessor: 'poolId',
    Header: t('Pool ID'),
    width: 150,
    Cell: (data) => {
      const poolId = data.row.original.poolId || 'N/A';
      return (
        <Box position="absolute" top="50%" style={{ transform: 'translateY(-50%)' }}>
          <Typography
            noWrap
            style={{
              width: 125, fontSize: 14, textOverflow: 'unset', overflowX: 'auto',
            }}
          >
            {poolId}
          </Typography>
        </Box>
      );
    },
  },
  {
    accessor: 'commission',
    Header: t('Commission'),
    width: 115,
    Cell: (data) => {
      const commission = R.view(R.lensPath(['row', 'original', 'commission']), data);
      return (
        <Box position="absolute" top="50%" style={{ transform: 'translateY(-50%)' }}>
          {isValid(commission) ? toNumber(commission) : 'N/A'}
        </Box>
      );
    },
  },
  {
    accessor: 'machineNum',
    Header: t('Machine'),
    width: 100,
    Cell: (data) => {
      const machineNum = R.view(R.lensPath(['row', 'original', 'machineNum']), data);
      return (
        <Box position="absolute" top="50%" style={{ transform: 'translateY(-50%)' }}>
          {isValid(machineNum) ? machineNum : 'N/A'}
        </Box>
      );
    },
  },
  {
    accessor: 'activeMachineNum',
    Header: t('Active Machine'),
    width: 140,
    Cell: (data) => {
      const poolData = R.view(R.lensPath(['row', 'original']), data) || {};
      const activeMachineNum = R.prop('activeMachineNum', poolData);
      const machineNum = R.prop('machineNum', poolData);
      return (
        <Box position="absolute" top="50%" style={{ transform: 'translateY(-50%)', color: machineNum - activeMachineNum > 0 ? '#EB5135' : 'default' }}>
          {isValid(activeMachineNum) ? activeMachineNum : 'N/A'}
        </Box>
      );
    },
  },
  {
    accessor: 'active',
    Header: t('Active'),
    width: 75,
    Cell: (data) => {
      const { active } = data.row.original;
      return (
        <Box position="absolute" top="50%" style={{ transform: 'translateY(-50%)' }}>
          <Checkbox
            checked={active}
            disabled
            color="default"
          />
        </Box>
      );
    },
  },
  {
    accessor: 'dateCreated',
    Header: t('Create Date'),
    width: 125,
    Cell: (data) => {
      const dateCreated = data.row.original.dateCreated || 'N/A';
      const formatedDateCreated = dateCreated !== 'N/A'
        ? (
          <Moment format="MMM D, YYYY">
            {dateCreated}
          </Moment>
        )
        : dateCreated;
      return (
        <Box position="absolute" top="50%" style={{ transform: 'translateY(-50%)' }}>
          {formatedDateCreated}
        </Box>
      );
    },
  },
  {
    Header: '',
    id: 'actions',
    width: 75,
    Cell: (data) => {
      const { index, original } = data.row;
      return (
        <Box position="absolute" top="50%" style={{ transform: 'translateY(-50%)' }}>
          {viewOrderActions(original, index)}
        </Box>
      );
    },
  },
]);
