import grey from '@material-ui/core/colors/grey';

export const failPageStyles = (theme) => ({
  icon: {
    color: theme.palette.error.main,
    fontSize: '5rem',
  },
  primaryText: {
    color: grey[500],
  },
  secondaryText: {
    color: grey[400],
  },
});
