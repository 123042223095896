import * as R from 'ramda';
import { combineReducers } from 'redux';
import { PURGE } from 'redux-persist';

import {
  createReducer,
  mergeStateAndPayload,
  updateStateWithKey,
} from '../store_utils';

import * as types from './types';

const paymentDetailIS = {
  paymentMethod: 'STRIPE_CARD',
  amount: 0,
  currencySymbol: 'USD',
  stripe: {
    stripePaymentMethodId: '',
    stripePriceId: '',
  },
  promotionCode: '',
  trialDays: 0,
};
const paymentDetailRUpdaters = {
  [types.UPDATE_PAYMENT_DATA]: (
    state,
    payload,
  ) => {
    const { amount } = payload;
    return mergeStateAndPayload(state, { amount });
  },
  [types.UPDATE_PAYMENT_STRIPE]: (
    state,
    payload,
  ) => updateStateWithKey('stripe', state, payload),

  [PURGE]: R.always(paymentDetailIS),

  [types.UPDATE_PROMOTION_CODE]: (
    state,
    payload,
  ) => {
    const { updatePath, data } = payload;
    return R.set(R.lensPath(updatePath), data, state);
  },

  [types.UPDATE_TRIAL_DAYS]: (
    state,
    payload,
  ) => {
    const { updatePath, data } = payload;
    return R.set(R.lensPath(updatePath), data, state);
  },
};

const paymentDetailR = createReducer(paymentDetailIS, paymentDetailRUpdaters);

const paymentHistoryIS = {
  items: [],
};
const paymentHistoryRUpdaters = {
  [types.GET_PAYMENT_HISTORY]: (
    state,
    payload,
  ) => {
    const { items } = payload;
    return mergeStateAndPayload(state, { items });
  },
  [PURGE]: R.always(paymentHistoryIS),
};

const paymentHistoryR = createReducer(paymentHistoryIS, paymentHistoryRUpdaters);

const subscriptionsIS = {
  data: [],
};
const subscriptionsRUpdaters = {
  [types.GET_SUBSCRIPTIONS_SUCCESS]: (
    state,
    payload,
  ) => {
    const { data } = payload;
    return mergeStateAndPayload(state, { data });
  },
  [PURGE]: R.always(subscriptionsIS),
};

const subscriptionsR = createReducer(subscriptionsIS, subscriptionsRUpdaters);

const paymentR = combineReducers({
  paymentDetail: paymentDetailR,
  paymentHistory: paymentHistoryR,
  subscriptions: subscriptionsR,
});

export default paymentR;
