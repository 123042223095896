export const earningItemStyles = (theme) => ({
  cardHeader: {
    padding: theme.spacing(0),
  },
  cardContent: {
    padding: theme.spacing(0),
    '&:last-child': {
      padding: theme.spacing(0),
    },
  },
  loading: {
    maxWidth: 300,
  },
});
