const validateSetPassword = (values) => {
  const { password, confirmPassword } = values;
  const errors = {};
  if (!password) {
    errors.password = 'Required';
  }
  if (!confirmPassword) {
    errors.confirmPassword = 'Required';
  }

  if (confirmPassword !== password) {
    errors.confirmPassword = 'Password does not match';
  }
  return errors;
};

export {
  validateSetPassword,
};
