import gql from 'graphql-tag';

export const sendContactUsEmailMutation = gql`
  mutation sendContactUsEmail(
    $sendContactUsEmailInput: SendContactUsEmailInput!
  )
  {
    sendContactUsEmail(
      sendContactUsEmailInput: $sendContactUsEmailInput
    ) {
      messageId
      from
      to
      response
    }
  }
`;
