import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import {
  toLower,
} from 'lodash';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { getChainsData } from '../Market/constants';
import { CloseIconButton } from '../MaterialUiCustom/MaterialUiCustom';

import ChangeSubscriptionPlanDialogContent from './ChangeSubscriptionPlanDialogContent';
// others
import { styles } from './styles';

const useStyles = makeStyles(styles);

const ChangeSubscriptionPlanDialog = (props) => {
  const classes = useStyles();
  const {
    open,
    onClose,
    data,
  } = props;
  const { t } = useTranslation();
  const changeSubscriptionPlanLoading = useSelector(
    (state) => state.loadingR.changeSubscriptionPlanLoading,
  );

  const {
    id: subscriptionId,
    metadata: {
      nodeType,
    },
    plan: {
      interval: currentPaymentPeriod,
    },
    quantity,
  } = data;
  const protocolName = R.pipe(
    R.split('_'),
    R.head,
    toLower,
  )(nodeType);
  const protocolData = getChainsData(t)[protocolName];
  const {
    nodeTypes,
  } = protocolData;
  const {
    pricing,
  } = R.find(
    R.propEq('type', nodeType),
    nodeTypes,
  );

  const [paymentPeriod, setPaymentPeriod] = useState(currentPaymentPeriod);

  const handleOnClose = () => {
    onClose();
  };

  return (
    <Dialog open={open} onClose={handleOnClose}>
      <DialogTitle disableTypography className={classes.dialogTitle}>
        <Typography variant="h6">{t('Choose your new plan')}</Typography>
        { !changeSubscriptionPlanLoading && (
        <CloseIconButton
          onClick={handleOnClose}
          color="default"
        />
        )}
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <ChangeSubscriptionPlanDialogContent
          pricing={pricing}
          paymentPeriod={paymentPeriod}
          setPaymentPeriod={setPaymentPeriod}
          quantity={quantity}
          subscriptionId={subscriptionId}
          nodeType={nodeType}
          onSuccessCallback={handleOnClose}
          changeSubscriptionPlanLoading={changeSubscriptionPlanLoading}
        />
      </DialogContent>
    </Dialog>
  );
};

ChangeSubscriptionPlanDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  data: PropTypes.shape({
    CPU: PropTypes.string,
    RAM: PropTypes.string,
    SSD: PropTypes.string,
    id: PropTypes.string,
    metadata: PropTypes.shape({
      nodeType: PropTypes.string,
    }),
    plan: PropTypes.shape({
      interval: PropTypes.string,
    }),
    quantity: PropTypes.number,
  }).isRequired,
};

export default ChangeSubscriptionPlanDialog;
