import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import OverviewItem from '../OverviewItem';
import { getFiatContent } from '../utils';

const OverviewZenItem = (props) => {
  const { t } = useTranslation();
  const {
    nodes,
    totalEarning,
    totalBalance,
    showTitles,
  } = props;

  const coinPrice = useSelector((state) => state.nodeR.coinPrice);
  const {
    getZenBalanceLoading,
  } = useSelector((state) => state.loadingR);

  const { quantity } = nodes;

  if (!getZenBalanceLoading) {
    if (!quantity) {
      return null;
    }
  }

  const totalEarningToUse = totalEarning || 0;
  const totalBalanceToUse = totalBalance || 0;

  const isLoading = !coinPrice.ZEN;
  const formatedData = {
    image: '/img/icon/zen.png',
    name: t('Horizen Secure Node'),
    plan: '1 year Auto-renewal',
    quantity: quantity || 0,
    earning: getFiatContent(totalEarningToUse, isLoading),
    balance: getFiatContent(totalBalanceToUse, isLoading),
    staked: getFiatContent(totalBalanceToUse - totalEarningToUse, isLoading),
  };
  return (
    <Grid item xs={12}>
      <OverviewItem
        type="horizen"
        data={formatedData}
        showTitles={showTitles}
        disabled
        loading={getZenBalanceLoading}
      />
    </Grid>
  );
};

OverviewZenItem.propTypes = {
  nodes: PropTypes.object,
  totalEarning: PropTypes.number,
  totalBalance: PropTypes.number,
  showTitles: PropTypes.bool,
};

OverviewZenItem.defaultProps = {
  nodes: {},
  totalEarning: 0,
  totalBalance: 0,
  showTitles: false,
};

export default React.memo(OverviewZenItem);
