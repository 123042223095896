const R = require('ramda');

export const selectDeployConfigR = (state) => state.deployConfigR;
export const selectDeployConfig = (deployConfigR) => deployConfigR.deployConfig;

export const selectDeployDone = R.curry(
  (state) => R.pipe(
    selectDeployConfigR,
    selectDeployConfig,
    R.prop('done'),
  )(state),
);
