import Grid from '@material-ui/core/Grid';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { CopyButton } from '../MaterialUiCustom/MaterialUiCustom';

import { referralInfoLinkStyles } from './styles';

const useStyles = makeStyles(referralInfoLinkStyles);

const ReferralInfoLink = (props) => {
  const { links } = props;
  const { t } = useTranslation();
  const classes = useStyles();
  const url = R.pipe(
    R.head,
    R.prop('url'),
  )(links);

  return (
    <Grid container spacing={1} justifyContent="flex-start">
      <Grid item xs={12} className={classes.textGrid}>
        <Typography variant="body1">
          {t('Share your link bellow, and earn your first commission!')}
        </Typography>
      </Grid>
      <Grid item xs={12} className={classes.linkGrid}>
        <TextField
          id="refferal-link"
          className={classes.linkField}
          defaultValue={t('Coming soon')}
          disabled
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <CopyButton
                  text={url}
                  disabled
                />
              </InputAdornment>
            ),
          }}
          variant="outlined"
        />

      </Grid>
    </Grid>
  );
};

ReferralInfoLink.defaultProps = {
  links: [
    { url: '' },
  ],
};

ReferralInfoLink.propTypes = {
  links: PropTypes.arrayOf(
    PropTypes.shape({
      url: PropTypes.string,
    }),
  ),
};

export default ReferralInfoLink;
