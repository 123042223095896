import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Radio from '@material-ui/core/Radio';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import getSymbolFromCurrency from 'currency-symbol-map';
import { startCase, upperCase } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

import { planItemStyles } from './styles';

const useStyles = makeStyles(planItemStyles);

// const paymentPeriod = 'month';
const MarketProtocolDeployPlanItem = (props) => {
  const classes = useStyles();
  const {
    paymentPeriod,
    label,
    total,
    specialDiscount,
    currency,
    disabled,
  } = props;

  const currencySymbol = getSymbolFromCurrency(upperCase(currency));
  const periodToDisplay = paymentPeriod === 'year' ? 'Month' : startCase(paymentPeriod);
  const totalToDisplay = paymentPeriod === 'year' ? (total / 12).toFixed(2) : total.toFixed(2);
  const detailToDisplay = paymentPeriod === 'year' ? `${currencySymbol}${total.toFixed(2)} billed ${paymentPeriod}ly` : '';
  const specialDiscountToDisplay = `save ${currencySymbol}${(total / (1 - specialDiscount) - total).toFixed(2)}`;

  return (
    <Paper className={classes.planItemRoot}>
      <Grid
        container
        direction="column"
      >
        <Grid item xs={12}>
          <Box
            display="flex"
            alignItems="center"
          >
            <Box className={classes.planItemRadio} mr={1}>
              <Radio
                value={paymentPeriod}
                name={`radio-button-${paymentPeriod}`}
                disabled={disabled}
              />
            </Box>
            <Box mr={1}>
              <Typography className={classes.label}>
                {label}
              </Typography>
            </Box>
            {!!specialDiscount && (
            <Box border={1} borderColor="primary.main" borderRadius={10}>
              <Typography color="primary" className={classes.discountText}>
                {specialDiscountToDisplay}
              </Typography>
            </Box>
            )}
          </Box>
        </Grid>
        <Grid item xs={12} className={classes.priceSection}>
          <Box
            display="flex"
            flexDirection="column"
          >
            <Box
              display="flex"
              alignItems="center"
            >
              <Typography>
                {currencySymbol}
              </Typography>
              <Typography className={classes.planItemPrice}>
                {totalToDisplay}
              </Typography>
              <Typography>
                {`/${periodToDisplay}`}
              </Typography>
            </Box>
            <Box>
              <Typography>
                {detailToDisplay}
              </Typography>
            </Box>
          </Box>
        </Grid>

      </Grid>

    </Paper>
  );
};

MarketProtocolDeployPlanItem.defaultProps = {
  specialDiscount: 0,
};

MarketProtocolDeployPlanItem.propTypes = {
  paymentPeriod: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  total: PropTypes.number.isRequired,
  specialDiscount: PropTypes.number,
  currency: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired,
};

export default MarketProtocolDeployPlanItem;
