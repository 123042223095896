export const serviceTermsStyles = (theme) => ({
  rootBox: {
    bottom: 0,
    maxWidth: 480,
    margin: 'auto',
  },
  text: {
    fontSize: 12,
    padding: theme.spacing(1, 0.5, 0.5, 0.5),
  },
  dot: {
    fontSize: 17,
  },
});

export const serviceTermsDrawerStyles = (theme) => ({
  rootBox: {
    bottom: 0,
  },
  text: {
    fontSize: 10,
    padding: theme.spacing(1, 0.5, 0.5, 0.5),
  },
  dot: {
    fontSize: 10,
  },
});
