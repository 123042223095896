export const addressStyles = (theme) => ({
  listItem: {
    cursor: 'pointer',
  },
});

export const addressConnectStyles = (theme) => ({
  notInjectedText: {
    display: 'flex',
    flexDirection: 'column',
  },
});

export const minginAddressEmptyAccountsStyles = (theme) => ({
  notInjectedText: {
    display: 'flex',
    flexDirection: 'column',
  },
});

export const addressCreationStyles = (theme) => ({
  dialogText: {
    color: theme.customColor.almostBlack,
  },
  dialog: {
    [theme.breakpoints.down('xs')]: {
      zIndex: '9999999999 !important',
    },
  },
  dialogPaper: {
    padding: theme.spacing(2, 0),
    [theme.breakpoints.up('sm')]: {
      minWidth: 300,
    },
  },
  dialogTitle: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
    alignItems: 'center',
  },
  dialogTitleText: {
    ...theme.typography.h2,
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
  },
  successPage: {
    paddingBottom: theme.spacing(7),
  },
});

export const addressCreationGenerateStyles = (theme) => ({
  wrapPaper: {
    padding: 0,
  },
  actionGrid: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
});
export const addressCreationMethodStyles = (theme) => ({
  itemIcon: {
    width: 40,
    height: 40,
  },
  itemText: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
});
