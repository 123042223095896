import camelCase from 'lodash/camelCase';
import * as R from 'ramda';
import { combineReducers } from 'redux';

import {
  createReducer,
} from '../store_utils';

import * as types from './types';

const deployConfigIS = {
  quantity: 1,
  nodeType: '',
  eth2ValidatorNode: {
    network: process.env.REACT_APP_ENV !== 'prod' ? 1 : 0,
    credentials: [],
    mnemonic: '',
    keystoreFilePassword: '',
  },
  // this will be supplied to createOrder function
  nodes: [],
  done: false,
};

const deployConfigRUpdaters = {
  [types.GENERATE_DEPLOY_NODE_CONFIG]: (
    state,
    payload,
  ) => {
    const {
      data: {
        credentials,
        mnemonic,
      }, nodeType,
    } = payload;
    return R.merge(state, {
      [camelCase(nodeType)]: {
        ...state[camelCase(nodeType)],
        credentials,
        mnemonic,
      },
    });
  },
  [types.ADD_SUB_NODES_TO_NODES]: (
    state,
    payload,
  ) => R.set(R.lensPath(['nodes']), payload, state),

  [types.UPDATE_CREDENTIALS]: (
    state,
    payload,
  ) => R.set(R.lensPath(['data', 'credentials']), payload, state),

  [types.RESET_DEPLOY_NODE_CONFIG]: R.always(deployConfigIS),

  [types.RESET_DEPLOY_NODE_CONFIG_ONE_NODE_TYPE]: (
    state,
    payload,
  ) => {
    const {
      nodeType,
    } = payload;
    const camelCasedNodeType = camelCase(nodeType);
    return {
      ...state,
      [camelCasedNodeType]: {
        ...deployConfigIS[camelCasedNodeType],
      },
    };
  },

  [types.UPDATE_DEPLOY_NODE_CONFIG]: (
    state,
    payload,
  ) => {
    const { updatePath, data } = payload;
    return R.set(R.lensPath(updatePath), data, state);
  },

  [types.UPDATE_DEPLOY_NODE_QUANTITY]: (
    state,
    payload,
  ) => {
    const { updatePath, data } = payload;
    return R.set(R.lensPath(updatePath), data, state);
  },

  [types.DEPLOY_NODE_SUCCESS]: (
    state,
    payload,
  ) => {
    const { done } = payload;
    return R.set(R.lensPath(['done']), done, state);
  },
};

const deployConfigR = createReducer(deployConfigIS, deployConfigRUpdaters);

const metadataIS = {
  /**
   * Disable the deploy button
   * Avoid passing prop to nested component
   * @type {Boolean}
   */
  deployBtnDisabled: true,
  paymentSelected: false,
  allConditionsPassed: false,
  ETH2_VALIDATOR_NODE: {
    disableKeystorePasswordField: false,
    haveFiles: false,
  },
  NEM_UNUNIFI_VALIDATOR_NODE: {

  },
};
const metadataRUpdaters = {
  [types.TOGGLE_DEPLOY_NODE_CONFIG_META_DATA]: (
    state,
    payload,
  ) => {
    const { updatePath, metadata } = payload;
    return R.set(R.lensPath(updatePath), metadata, state);
  },
  [types.RESET_DEPLOY_NODE_CONFIG_META_DATA]: R.always(metadataIS),
};

const metadataR = createReducer(metadataIS, metadataRUpdaters);

const reducers = combineReducers({
  deployConfig: deployConfigR,
  metadata: metadataR,
});

export default reducers;
