import gql from 'graphql-tag';

export const sendMonthlyReportEmailMutation = gql`
  mutation sendMonthlyReportEmail(
    $sendMonthlyReportEmailInput: SendMonthlyReportEmailInput!
  )
  {
    sendMonthlyReportEmail(
      sendMonthlyReportEmailInput: $sendMonthlyReportEmailInput
    ) {
      responses {
        messageId
        from
        to
        response
      }
    }
  }
`;
