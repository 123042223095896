import * as R from 'ramda';

import { getItem } from '../../modules/browser_utils';

/**
 * extend the paylod of FGET_NEW_AUTH_TOKEN_SUCCESS actions paylod
 * @param {string}  type redux actions type property type
 * @param {import('./typedefs').authTokenDataResponse} payload
 * @return {import('../user/typedefs').ReduxAction}
 */
export const extendUpdateAuthTokenPayload = R.curry(({ type, payload }) => {
  const indexOfRejectKeyword = type.indexOf('REJECT');
  let fetchNewAuthTokensSucceed = false;
  if (indexOfRejectKeyword === -1) {
    fetchNewAuthTokensSucceed = true;
  }
  const extendedPayload = R.flip(R.merge)({ fetchNewAuthTokensSucceed })(payload);
  return {
    type,
    payload: extendedPayload,
  };
});

/**
 * Check whether auth token failed or not
 * @param  {import('./typedefs').AuthTokenUpdateFailedError} error
 * @return {boolean}        true if auth token was failed and false otherwise
 */
export const checkIfAuthTokenUpdateSucceed = R.curry((payload) => {
  const accessToken = R.view(R.lensPath(['authTokenData', 'accessToken']), payload);
  if (accessToken) {
    return 'SUCCEED';
  }
  return 'FAILED';
});

export const isFetchingNewAuthToken = R.curry((fetchNewAuthTokenRequestStatus) => (
  fetchNewAuthTokenRequestStatus === 'LOADING'
));

export const getRequiredDataToUpdateAuthTokenData = R.curry(
  (authTokenDataKey, userDataKey, authStatusKey) => {
    const currentAuthTokenData = getItem(authTokenDataKey);
    const userData = getItem(userDataKey);
    const isAuthenticated = getItem(authStatusKey);
    const refreshToken = R.view(R.lensProp('refreshToken'), currentAuthTokenData);
    const idToken = R.view(R.lensProp('idToken'), currentAuthTokenData);
    const userId = R.view(R.lensProp('userId'), userData);
    const email = R.view(R.lensProp('email'), userData);
    return {
      userId,
      email,
      isAuthenticated,
      idToken,
      refreshToken,
    };
  },
);
