import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import React from 'react';

import TabPanel from './TabPanel';
import { customTabstyles } from './styles';

const useStyles = makeStyles(customTabstyles);

const CustomTabs = (props) => {
  const {
    tabs,
    extraComponent,
    loading,
    paddingContent,
    belowTabsComponent,
    extraComponentGridProps,
  } = props;
  const classes = useStyles();

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const tabsList = R.addIndex(R.map)((tab, index) => (
    <Tab
      label={tab.label}
      key={tab.label}
      disableRipple
      disableFocusRipple
      className={index === 0 ? classes.firstTab : classes.tab}
      classes={{
        wrapper: classes.wrapper,
        selected: classes.selectedTab,
      }}
    />
  ), tabs);
  const tabsContents = R.addIndex(R.map)((tab, index) => (
    <TabPanel value={value} index={index} key={tab.label}>
      {tab.content}
    </TabPanel>
  ),
  tabs);

  const getTabContents = (loading) => (
    loading
      ? <CircularProgress />
      : tabsContents
  );
  return (
    <Grid
      container
      direction="column"
    >
      <Grid item>
        <Grid
          container
          justifyContent="space-between"
          alignItems="center"
          spacing={2}
        >
          <Grid item xs={12} sm="auto">
            <Grid
              container
              justifyContent="flex-start"
            >
              <Tabs
                value={value}
                indicatorColor="secondary"
                textColor="primary"
                onChange={handleChange}
                TabIndicatorProps={{ style: { display: 'none', background: 'cyan' } }}
                className={classes.tabs}
              >
                {tabsList}
              </Tabs>
            </Grid>
            <Grid
              container
              justifyContent="flex-start"
            >
              {belowTabsComponent}
            </Grid>
          </Grid>

          {extraComponent && (
            <Grid item xs={12} sm="auto">
              <Grid
                container
                justifyContent="flex-end"
                {...extraComponentGridProps}
              >
                {extraComponent}
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>

      <Grid
        item
        className={
          loading ? classes.tabContent : null
        }
        style={{ paddingTop: paddingContent }}
      >
        {getTabContents(loading)}
      </Grid>
    </Grid>
  );
};

CustomTabs.defaultProps = {
  extraComponent: null,
  loading: false,
  paddingContent: 35,
  belowTabsComponent: null,
  extraComponentGridProps: {},
};

CustomTabs.propTypes = {
  /**
   * tabs list
   * {
   *  label: '',
   *  content: </>,
   * }
   */
  tabs: PropTypes.array.isRequired,
  /**
   * Component will be rendered on right
   * side of tabs
   */
  extraComponent: PropTypes.oneOfType([
    PropTypes.elementType,
    PropTypes.element,
  ]),
  /**
   * if true display loading circle
   */
  loading: PropTypes.bool,
  /**
   * padding between label and content
   */
  paddingContent: PropTypes.number,
  belowTabsComponent: PropTypes.element,
  extraComponentGridProps: PropTypes.object,
};

export default React.memo(CustomTabs);
