import {
  pipeAwait,
} from 'jsutils';
import * as R from 'ramda';

import {
  graphqlNormalize,
} from '../../modules/apollo/apollo';
import {
  returnAction,
} from '../store_utils';

import {
  graphqlGetPools,
  graphqlGetUserPools,
  graphqlGetUserRollupRewards,
  graphqlUpdatePools,
  graphqlUpdatePoolDatas,
} from './graphql/services';
import * as types from './types';

export const getUserPools = R.curry(
  async (
    userId,
  ) => pipeAwait(
    graphqlGetUserPools,
    graphqlNormalize('getUserPools'),
    returnAction(types.GET_USER_POOLS_SUCCESS),
  )({
    getUserPoolsInput: {
      userId,
    },
  }),
);

export const getUserRollupRewards = R.curry(
  async (
    userId,
    getUserRollupRewardsType,
    machineType,
  ) => pipeAwait(
    graphqlGetUserRollupRewards,
    graphqlNormalize('getUserRollupRewards'),
    returnAction(types.GET_USER_ROLLUP_REWARDS_SUCCESS),
  )({
    getUserRollupRewardsInput: {
      userId,
      getUserRollupRewardsType,
      machineType,
    },
  }),
);

export const getPools = R.curry(
  async (
    query,
    pagination,
  ) => pipeAwait(
    graphqlGetPools,
    graphqlNormalize('getPools'),
    returnAction(types.GET_POOLS_SUCCESS),
  )({
    getPoolsInput: {
      options: {
        useQueryBuilder: false,
      },
      query,
      pagination,
    },
  }),
);

export const updatePools = R.curry(
  async (
    data,
  ) => pipeAwait(
    graphqlUpdatePools,
    graphqlNormalize('updatePools'),
    returnAction(types.UPDATE_POOLS_SUCCESS),
  )({
    updatePoolsInput: {
      data,
    },
  }),
);

export const updatePoolDatas = R.curry(
  async (
    data,
  ) => pipeAwait(
    graphqlUpdatePoolDatas,
    graphqlNormalize('updatePoolDatas'),
    returnAction(types.UPDATE_POOL_DATA_SUCCESS),
  )({
    updatePoolDatasInput: {
      data,
    },
  }),
);
