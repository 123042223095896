import Typography from '@material-ui/core/Typography';
import isEmpty from 'lodash/isEmpty';
import toUpper from 'lodash/toUpper';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';

import { useAddFunctionToRef } from '../../../hooks/hooks';
import { cryptoAddressOperations, cryptoAddressSelectors } from '../../../store/crypto_address/index';
import { nodeOperations } from '../../../store/node/index';
import { orderOperations } from '../../../store/order/index';
import { poolOperations } from '../../../store/pool/index';
import { web3Operations } from '../../../store/web3';
import Alert from '../../MaterialUiCustom/Alert/Alert';
import CustomTabs from '../../Tabs/CustomTabs';

import MiningAddressCreation from './MiningAddressCreation/MiningAddressCreation';
import MiningContent from './MiningContent';
import MiningHeaderExtra from './MiningHeaderExtra';

import { isAPIReadFailedError } from 'store/error/services';

// hardcode chain and subChain. TODO dry
const chain = 'dot';
const subChain = 'pha';
// HARD code for now. DRY later
const ss58Format = 30;
// autoConnect is a feature flag
// and should not be defined inside the component

// feature flag
const { REACT_APP_MINING_WALLET, REACT_APP_PHALA_WS_ENDPOINT } = process.env;
const disableMiningWallet = REACT_APP_MINING_WALLET === 'OFF';
const autoConnect = false;

const Mining = ({
  getUserRollupRewardsType,
  machineType,
  nodeType,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [api, setApi] = useState(null);
  const [openAddressCreation, setOpenAddressCreation] = useState(false);

  const handleOpenAddressCreation = useAddFunctionToRef(() => {
    setOpenAddressCreation(true);
  });

  const handleCloseAddressCreation = useAddFunctionToRef(() => {
    setOpenAddressCreation(false);
  });

  const { getTotalEarningError, getUserRollupRewardsError } = useSelector((state) => state.errorR);
  const getPhalaEarningErrorExists = isAPIReadFailedError(getTotalEarningError || getUserRollupRewardsError || {});
  const { userId } = useSelector((state) => state.userR.user);
  const getCryptoAddressesLoading = useSelector((state) => state.loadingR.getCryptoAddressesLoading);
  const addresses = useSelector((state) => state.cryptoAddressR.addresses);
  const accounts = cryptoAddressSelectors.selectCryptoAddressAccounts(chain, subChain, addresses);
  const address = R.pipe(
    R.head,
    (account) => (account ? R.prop('address', account) : ''),
  )(accounts);
  const done = useSelector((state) => state.cryptoAddressR.addresses.done);
  const haveCryptoAccounts = !isEmpty(accounts) && done;
  const getMiningOrder = useAddFunctionToRef(async (userId) => {
    await dispatch(orderOperations.getOrders(userId, 'NODE_PROVIDER_MINING'));
  });

  const onGetCoinPrice = useAddFunctionToRef(
    async () => {
      await dispatch(nodeOperations.getCoinPrice('PHA', 'JPY'));
    },
  );

  const onGetCryptoAddresses = useAddFunctionToRef(
    async () => {
      dispatch(
        await cryptoAddressOperations.getCryptoAddresses(
          userId,
          null,
          toUpper(chain),
          toUpper(subChain),
        ),
      );
    },
  );

  const onGetUserRollupReward = useAddFunctionToRef(
    async (userId, getUserRollupRewardsType, machineType) => {
      dispatch(
        await poolOperations.getUserRollupRewards(
          userId,
          getUserRollupRewardsType,
          machineType,
        ),
      );
    },
  );

  const onGetTotalPaidOutReward = useAddFunctionToRef(async (userId, nodeType) => {
    await dispatch(nodeOperations.getNodeOwnerTotalEarning(userId, nodeType));
  })

  const onInitializeApi = useAddFunctionToRef(async (chain, endpoint) => {
    const { payload } = await dispatch(web3Operations.initializeApi(chain, endpoint));
    return payload;
  });

  const onGetAccountInfo = useAddFunctionToRef(
    async (chain, subChain, { api, address }) => {
      dispatch(await web3Operations.getAccountInfo(
        chain,
        subChain,
        {
          api,
          address,
        },
      ));
    },
  );

  const onGetIntervalEarning = useAddFunctionToRef(async (userId, nodeType) => {
    await dispatch(nodeOperations.getIntervalEarning(
      userId,
      nodeType,
      'jpy',
      null,
      null,
      '1d',
      999999, // Have to pass large rows number to get all data, TODO: Remove this then use pagination to optimize
      1,
    ));
  });

  useEffect(() => {
    getMiningOrder(userId);
    onGetCoinPrice();
    if (!disableMiningWallet) {
      onGetCryptoAddresses();
    }
  }, [getMiningOrder, onGetCoinPrice, onGetCryptoAddresses, userId]);

  useEffect(() => {
    const shouldOpen = R.and(
      autoConnect,
      !haveCryptoAccounts,
    );
    if (shouldOpen) {
      handleOpenAddressCreation();
    }
  }, [accounts, done, handleCloseAddressCreation, handleOpenAddressCreation, haveCryptoAccounts]);

  useEffect(() => {
    onGetUserRollupReward(userId, getUserRollupRewardsType, machineType);
  }, [getUserRollupRewardsType, machineType, onGetUserRollupReward, userId]);

  useEffect(() => {
    onGetTotalPaidOutReward(userId, nodeType);
  }, [userId, nodeType, onGetTotalPaidOutReward]);

  useEffect(() => {
    const handleAsync = async () => {
      const api = await onInitializeApi('dot', REACT_APP_PHALA_WS_ENDPOINT);
      setApi(api);
    };
    handleAsync();
  }, [onInitializeApi]);

  useEffect(() => {
    if (address && api) {
      onGetAccountInfo(chain, subChain, { api, address });
    }
  }, [address, api, onGetAccountInfo]);

  useEffect(() => {
    onGetIntervalEarning(userId, nodeType);
  }, [onGetIntervalEarning, userId, nodeType]);

  const tabs = [
    {
      label: t('Phala Network Mining'),
      content: <MiningContent machineType={machineType} nodeType={nodeType}/>,
      mainTab: true,
    },
  ];

  return (
    <>
      {
        getPhalaEarningErrorExists
        && (
          <Alert
            severity="error"
          >
            <Typography variant="body2" color="inherit">
              {t('Our server is busy. Please try again later.')}
            </Typography>
          </Alert>
        )
        // End
      }
      <CustomTabs
        tabs={tabs}
        extraComponent={!disableMiningWallet ? (
          <MiningHeaderExtra
            getCryptoAddressesLoading={getCryptoAddressesLoading}
            accounts={accounts}
            haveCryptoAccounts={haveCryptoAccounts}
            autoConnect={autoConnect}
            chain={chain}
            handleOpenAddressCreation={handleOpenAddressCreation}
            ss58Format={ss58Format}
          />
        ) : null}
      />
      {!disableMiningWallet && (
        <MiningAddressCreation
          openAddressCreation={openAddressCreation}
          handleOpenAddressCreation={handleOpenAddressCreation}
          handleCloseAddressCreation={handleCloseAddressCreation}
          chain={chain}
          ss58Format={ss58Format}
          haveCryptoAccounts={haveCryptoAccounts}
        />
      )}
    </>
  );
};

Mining.propTypes = {
  getUserRollupRewardsType: PropTypes.oneOf(['CENTRALIZED', 'DECENTRALIZED']).isRequired,
  machineType: PropTypes.oneOf(['FOUR_CORE', 'SIX_CORE']).isRequired,
  nodeType: PropTypes.oneOf(['PHALA_MINING_NODE', 'PHALA_MINING_NODE_4_CORES']).isRequired,
};

export default Mining;
