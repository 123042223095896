import * as R from 'ramda';

export const metadataDisableBtnPickers = {
  ETH2_VALIDATOR_NODE: R.pick(['haveFiles']),
  NEM_UNUNIFI_VALIDATOR_NODE: R.pick([]),
};

export const formatSubNodeDataFns = {
  ETH2_VALIDATOR_NODE: (data) => R.pipe(
    R.pick(['credentials', 'keystoreFilePassword']),
    (subNodeData) => {
      const { credentials, keystoreFilePassword } = subNodeData;
      return R.map(R.merge({ keystoreFilePassword }), credentials);
    },
  )(data),
};

export const createFormatSubNodeData = R.curry(
  (formatSubNodeDataFns, nodeType, data) => {
    const fn = formatSubNodeDataFns[nodeType];
    return fn(data);
  },
);

export const formatSubNodeData = createFormatSubNodeData(formatSubNodeDataFns);
