export const userListStyles = (theme) => ({
  table: {
    padding: theme.spacing(2),
    overflow: 'scroll',
  },
  root: {
    padding: theme.spacing(2),
  },
  loadingCircle: {
    ...theme.centerComponent,
  },
  reFetchButton: {
    padding: theme.spacing(0),
  },
});

export const orderStyles = (theme) => ({
  createOrdersButton: {
    padding: theme.spacing(1.5, 5),
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  statusDialog: {
    padding: theme.spacing(4),
  },
  successDialogDetails: {
    width: '100%',
    marginTop: theme.spacing(4),
    padding: theme.spacing(2),
  },
});

export const adminDashboardStyles = (theme) => ({
  headerButtonContainer: {
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  headerButton: {
    padding: theme.spacing(1.5, 5),
    margin: theme.spacing(1, 0),
  },
  statusDialog: {
    padding: theme.spacing(4),
  },
  successDialogDetails: {
    marginTop: theme.spacing(4),
    padding: theme.spacing(2),
  },
});

export const orderListStyles = (theme) => ({
  root: {
    padding: theme.spacing(2),
  },
  loadingCircle: {
    ...theme.centerComponent,
  },
  customTooltip: {
    backgroundColor: '#f9f9f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    boxShadow: theme.shadows[3],
  },
  customArrow: {
    color: '#f9f9f9',
  },
  menuButton: {
    width: '100%',
  },
  table: {
    padding: theme.spacing(2, 2),
  },
  statusDialog: {
    padding: theme.spacing(4),
  },
  reFetchButton: {
    padding: theme.spacing(0),
  },
});

export const updateOrderDataStyles = (theme) => ({
  formGrid: {
    padding: theme.spacing(4),
  },
  actionGrid: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  dialogTitleText: {
    ...theme.typography.h2,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
  },
});

export const orderDetailsStyles = (theme) => ({
  orderDetailsContainer: {
    marginTop: theme.spacing(4),
    marginLeft: 'auto',
    marginRight: 'auto',
    width: '70%',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  orderDetailsGrid: {
    padding: theme.spacing(2),
  },
  dialogTitleText: {
    ...theme.typography.h2,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
  },
});

export const dialogAppBarStyles = (theme) => ({
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: theme.spacing(2),
  },
});

export const createOrdersStyles = (theme) => ({
  content: {
    marginTop: theme.spacing(4),
    marginLeft: 'auto',
    marginRight: 'auto',
    width: '70%',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  itemContainer: {
    ...theme.centerComponent,
    width: '100%',
    minHeight: 50,
    padding: theme.spacing(6, 0),
  },
  addButtonContainer: {
    ...theme.centerComponent,
    width: '100%',
    borderRadius: '0px 0px 10px 10px',
    backgroundColor: '#F4F5F9',
    transition: 'background-color 200ms linear',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#E3E6F0',
      transition: 'background-color 200ms linear',
    },
  },
  addButton: {
    fontSize: 50,
  },
  emptyItem: {
    borderColor: '#E8E8E8',
    padding: theme.spacing(6, 0),
    backgroundColor: '#FFF',
    borderRadius: '10px 10px 0px 0px',
  },
  emptyItemContent: {
    fontSize: 12,
    fontWeight: 600,
  },
  actionGrid: {
    padding: theme.spacing(4, 0),
    marginLeft: 'auto',
    marginRight: 'auto',
    width: '70%',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  formField: {
    width: '100%',
    '& .MuiInputLabel-asterisk': {
      color: '#db3131',
      '&$error': {
        color: '#db3131',
      },
    },
  },
  orderSubtitle: {
    paddingTop: theme.spacing(4),
  },
  dialogTitleText: {
    ...theme.typography.h2,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
  },
  formFieldSwitch: {
    ...theme.border.default,
    padding: theme.spacing(1, 2, 2, 1.5),
  },
  formFieldSwitchHelperText: {
    fontSize: 10,
  },
  stepFieldTitle: {
    fontSize: 14,
  },
});

export const createUsersStyles = (theme) => ({
  content: {
    marginTop: theme.spacing(4),
    marginLeft: 'auto',
    marginRight: 'auto',
    width: '70%',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  itemContainer: {
    ...theme.centerComponent,
    width: '100%',
    minHeight: 50,
    padding: theme.spacing(6, 0),
  },
  addButtonContainer: {
    ...theme.centerComponent,
    width: '100%',
    borderRadius: '0px 0px 10px 10px',
    backgroundColor: '#F4F5F9',
    transition: 'background-color 200ms linear',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#E3E6F0',
      transition: 'background-color 200ms linear',
    },
  },
  addButton: {
    fontSize: 50,
  },
  emptyItem: {
    borderColor: '#E8E8E8',
    padding: theme.spacing(6, 0),
    backgroundColor: '#FFF',
    borderRadius: '10px 10px 0px 0px',
  },
  emptyItemContent: {
    fontSize: 12,
    fontWeight: 600,
  },
  actionGrid: {
    padding: theme.spacing(4, 0),
    marginLeft: 'auto',
    marginRight: 'auto',
    width: '70%',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  formField: {
    width: '100%',
    '& .MuiInputLabel-asterisk': {
      color: '#db3131',
      '&$error': {
        color: '#db3131',
      },
    },
  },
  orderSubtitle: {
    paddingTop: theme.spacing(4),
  },
  formFieldSwitch: {
    ...theme.border.default,
    padding: theme.spacing(2),
  },
  dialogTitleText: {
    ...theme.typography.h2,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
  },
  table: {
    maxWidth: window.innerWidth * 0.70,
    overflow: 'auto',
    [theme.breakpoints.down('xs')]: {
      maxWidth: window.innerWidth * 0.95,
    },
  },
});

export const createUsersSuccessStyles = (theme) => ({
  userInfoTitle: {
    padding: theme.spacing(2, 0),
  },
  userInfoRow: {
    padding: theme.spacing(0.5, 0),
  },
  userInfoDivider: {
    padding: theme.spacing(3, 0),
  },
  downloadButton: {
    padding: theme.spacing(2),
    margin: 0,
  },
  downloadButtonText: {
    fontSize: 14,
    fontWeight: 500,
  },
});

export const createOrdersSuccessStyles = (theme) => ({
  userInfoTitle: {
    padding: theme.spacing(2, 0),
  },
  userInfoRow: {
    padding: theme.spacing(0.5, 0),
  },
  userInfoDivider: {
    padding: theme.spacing(3, 0),
  },
  downloadButton: {
    padding: theme.spacing(2),
    margin: 0,
  },
  downloadButtonText: {
    fontSize: 14,
    fontWeight: 500,
  },
});
