import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import React from 'react';

import { styles } from './styles';

const useStyles = makeStyles(styles);

const SectionCard = (props) => {
  const {
    leftContent,
    leftContentGridWidths,
    rightContent,
    rightContentGridWidths,
    leftContentClassName,
    rootClassName,
    centerRightContent,
    disableBackground,
  } = props;
  const classes = useStyles();
  const leftContentClassNameToUse = isEmpty(leftContentClassName) ? null : leftContentClassName;
  const rootClassNameToUse = isEmpty(rootClassName) ? classes.root : rootClassName;

  return (
    <Box
      className={rootClassNameToUse}
      elevation={disableBackground ? 0 : 1}
    >
      <Grid container spacing={2} justifyContent="center">
        <Grid item {...leftContentGridWidths} className={leftContentClassNameToUse}>
          {leftContent}
        </Grid>
        <Grid
          item
          {...rightContentGridWidths}
          className={classNames(
            centerRightContent && classes.centerRightContent,
          )}
        >
          {rightContent}
        </Grid>
      </Grid>
    </Box>
  );
};
SectionCard.propTypes = {
  /**
   * content to display on the left
   */
  leftContent: PropTypes.element.isRequired,
  /**
   * className applying left content Grid
   */
  leftContentClassName: PropTypes.string,
  /**
   * className applying container Grid
   */
  rootClassName: PropTypes.string,
  /**
   * Center right content inside the grid
   */
  centerRightContent: PropTypes.bool,
  rightContent: PropTypes.oneOfType([PropTypes.element, PropTypes.array]).isRequired,
  disableBackground: PropTypes.bool,
  leftContentGridWidths: PropTypes.shape({
    xs: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
    sm: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
    md: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
    lg: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
    xl: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
  }),
  rightContentGridWidths: PropTypes.shape({
    xs: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
    sm: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
    md: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
    lg: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
    xl: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
  }),
};

SectionCard.defaultProps = {
  leftContentClassName: '',
  rootClassName: '',
  centerRightContent: false,
  disableBackground: false,
  leftContentGridWidths: {
    xs: 12,
    lg: 4,
  },
  rightContentGridWidths: {
    xs: 12,
    lg: 8,
  },
};
export default SectionCard;
