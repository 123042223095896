import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import camelCase from 'lodash/camelCase';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { useAddFunctionToRef } from '../../../../hooks/hooks';
import { deployConfigOperations } from '../../../../store/deploy_config/index';
import SecretPhrase from '../../../SecretPhrase/SecretPhrase';

import ProtocolConfigEth20WalletFiles from './ProtocolConfigEth20WalletFiles';
// operations
// hook

import { eth20WalletImportStyles } from './styles';

const useStyles = makeStyles(eth20WalletImportStyles);

const ProtolConfigEth20WalletInputs = (props) => {
  const {
    keystoreFilePassword,
    nodeType,
    quantity,
    network,
    showPopupWrongNetworkError,
    wrongNetwork,
    withNetworkError,
  } = props;
  const dispatch = useDispatch();
  const { mnemonic, credentials } = useSelector(
    (state) => R.view(
      R.lensPath(['deployConfigR', 'deployConfig', camelCase(nodeType)]),
      state,
    ),
  );
  const classes = useStyles();
  const [showFiles, setShowFiles] = useState(false);

  const onConfirmSecretPhraseSuccess = ({
    secretPhraseConfirmed,
  }) => {
    if (secretPhraseConfirmed) {
      setShowFiles(true);
    }
  };
  const onToggleHaveFiles = useAddFunctionToRef(
    (disabled) => {
      dispatch(deployConfigOperations.toggleDeployConfigMetadata({
        updatePath: [nodeType, 'haveFiles'],
        metadata: disabled,
      }));
      dispatch(
        deployConfigOperations.toogleDisableDeployBtnByNodeType(nodeType),
      );
    },
  );

  const onGenerateEth2NodeConfig = R.curry(
    async (
      nodeType,
      keystoreFilePassword,
      quantity,
      network,
    ) => {
      const configData = {
        nodeType,
        password: keystoreFilePassword,
        numKeysToGenerate: quantity,
        network,
        amount: network === 0 ? 32000000000 : 1000000000,
      };
      return dispatch(
        deployConfigOperations.generateDeployNodeConfig(
          nodeType,
          configData,
        ),
      );
    },
  );
  return (
    <Grid
      container
      spacing={2}
      // alignItems="center"
      className={classes.uploadParentGrid}
    >
      { showFiles
          && (
          <Grid item xs={12}>
            <ProtocolConfigEth20WalletFiles
              credentials={credentials}
              onToggleHaveFiles={onToggleHaveFiles}
              withNetworkError={withNetworkError}
            />
          </Grid>
          )}
      { !showFiles
        && (
          <SecretPhrase
            onGenerateSecretPhrase={() => onGenerateEth2NodeConfig(nodeType, keystoreFilePassword, quantity, network)}
            secretPhrase={mnemonic}
            onConfirmSuccess={onConfirmSecretPhraseSuccess}
            stepActionPrecedeFn={showPopupWrongNetworkError}
            precedeFnCondition={wrongNetwork}
          />
        )}
    </Grid>
  );
};

ProtolConfigEth20WalletInputs.propTypes = {
  keystoreFilePassword: PropTypes.string.isRequired,
  nodeType: PropTypes.string.isRequired,
  quantity: PropTypes.number.isRequired,
  network: PropTypes.number.isRequired,
  showPopupWrongNetworkError: PropTypes.func.isRequired,
  wrongNetwork: PropTypes.bool.isRequired,
  withNetworkError: PropTypes.func.isRequired,
};

export default ProtolConfigEth20WalletInputs;
