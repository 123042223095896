import gql from 'graphql-tag';

export const getUserByIdQuery = gql`
  query getUserById($userId: String!)
  {
    getUserById(
      getUserByIdInput: { userId: $userId }
    ) {
      user {
        userId
        email
        joined
        modified
        lastSignin
        activated
      }
    }
  }
`;

export const getActivationQuery = gql`
  query getActivation($activationId: String!)
  {
    getActivation(
      getActivationInput: { activationId: $activationId }
    ) {
      activationId
      userId
    }
  }
`;

export const getFirstSigninQuery = gql`
  query getFirstSignin($getFirstSigninInput: GetFirstSigninInput!)
  {
    getFirstSignin(
      getFirstSigninInput: $getFirstSigninInput
    ) {
      firstSigninId
      userId
      email
      dateCreated
      dateUpdated
      signedIn
    }
  }
`;

export const getFirstSigninsQuery = gql`
  query getFirstSignins($getFirstSigninsInput: GetFirstSigninsInput!)
  {
    getFirstSignins(
      getFirstSigninsInput: $getFirstSigninsInput
    ) {
      items {
        firstSigninId
        userId
        email
        dateCreated
        dateUpdated
        signedIn
      }
    }
  }
`;
