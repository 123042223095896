import * as R from 'ramda';

import { CustomError } from '../../modules/error_utils';

import { coins } from './coins';

const createGetCoinMethods = R.curry((coins, coin) => {
  const coinMethod = coins[coin];
  if (!coinMethod) throw new CustomError(`${coin} method not found`);
  return coinMethod;
});

const getCoinMethods = createGetCoinMethods(coins);

export default {
  getCoinMethods,
};
