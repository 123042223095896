import whyDidYouRender from '@welldone-software/why-did-you-render';
import * as R from 'ramda';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { CustomError } from '../../../../modules/error_utils';
import Connector from '../../../ConnectToWallet/Connector';
import ConnectorWrapper from '../../../ConnectToWallet/ConnectorWrapper';

import MiningAddressCreationConnect from './MiningAddressCreationConnect';
import MiningAddressCreationGenerate from './MiningAddressCreationGenerate';

import useAddFunctionToRef from 'hooks/useAddFunctionToRef';
import { web3Operations } from 'store/web3';

if (process.env.REACT_APP_ENV !== 'prod') {
  whyDidYouRender(React);
}

const accountTypes = {
  connect: 'injectedAccounts',
  generate: 'generatedAccounts',
};
const MiningAddressCreationMethods = ({
  enableWeb3Loading,
  isExtensionsInjected,
  accounts,
  onCancel,
  setAccountType,
  openAddressCreation,
  chain,
  ss58Format,
  resetAllChildren,
  setSelectedMethod,
  onCreateCryptoAddress,
  userId,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [method, setMethod] = useState('');
  const onSetMethod = R.curry((method) => {
    setMethod(method);
    setSelectedMethod(method);
    const accountType = accountTypes[method];
    setAccountType(accountType);
  });
  const onEnableWeb3 = useAddFunctionToRef(async (chain, ss58Format) => {
    dispatch(await web3Operations.enableWeb3(chain, { ss58Format }));
  });
  const onClickSelectConnect = (chain, ss58Format) => {
    onSetMethod('connect');
    onEnableWeb3(chain, ss58Format);
  };
  const handleOnCancel = useAddFunctionToRef(() => {
    onSetMethod('');
    onCancel();
  });

  const createCryptoAddressLoading = useSelector(
    (state) => state.loadingR.createCryptoAddressLoading,
  );
  const Methods = {
    connect: (
      <MiningAddressCreationConnect
        enableWeb3Loading={enableWeb3Loading}
        isExtensionsInjected={isExtensionsInjected}
        accounts={accounts}
        openAddressCreation={openAddressCreation}
        chain={chain}
        ss58Format={ss58Format}
        handleOnCancel={handleOnCancel}
        resetAllChildren={resetAllChildren}
        userId={userId}
        onCreateCryptoAddress={onCreateCryptoAddress}
        createCryptoAddressLoading={createCryptoAddressLoading}
      />
    ),
    generate: (
      <MiningAddressCreationGenerate
        onSetMethod={onSetMethod}
        handleOnCancel={handleOnCancel}
        resetAllChildren={resetAllChildren}
        onCreateCryptoAddress={onCreateCryptoAddress}
        userId={userId}
        createCryptoAddressLoading={createCryptoAddressLoading}
      />
    ),
  };
  const getMethodsComponents = R.curry((Methods, method) => {
    const Method = Methods[method];
    if (!Method) throw new CustomError('Address creation method not found');
    return Method;
  });
  const methodOptions = [
    {
      connectorName: t('Generate wallet (recommended)'),
      connectorImgSrc: '/img/neukind-logo-dark-circle.png',
      onClick: () => onSetMethod('generate'),
    },
    {
      connectorName: t('Connect Polkadot{.js} exenstion'),
      connectorImgSrc: '/img/icon/polkadot-js.png',
      onClick: () => onClickSelectConnect(chain, ss58Format),
    },
  ];
  const getMethodOptions = (methodOptions) => R.map(
    (method) => {
      const { connectorName, connectorImgSrc, onClick } = method;
      return (
        <Connector
          onClick={onClick}
          connectorName={connectorName}
          connectorImgSrc={connectorImgSrc}
          key={connectorImgSrc}
        />
      );
    },
  )(methodOptions);

  return !method
    ? (
      <ConnectorWrapper>
        {getMethodOptions(methodOptions)}
      </ConnectorWrapper>
    )
    : (
      getMethodsComponents(Methods, method)
    );
}

  ;

// MiningAddressCreationMethod.propTypes = {
//   match: PropTypes.shape({
//     params: PropTypes.shape({
//       chainAbbreviation: PropTypes.string.isRequired,
//     }).isRequired,
//   }).isRequired,
// };
MiningAddressCreationMethods.whyDidYouRender = true;
export default React.memo(MiningAddressCreationMethods);
