import * as R from 'ramda';

import {
  sideBarsItemIndices,
} from './constants';
import * as types from './types';

export const selectSidebarItem = R.curry(
  (itemIndex) => ({
    type: types.SELECT_SIDE_BAR_MENU_ITEM,
    payload: itemIndex,
  }),
);

export const createSelectSidebarIndexByRoute = R.curry(
  (sideBarsItemIndices, route) => {
    const itemIndex = R.pipe(
      R.split('/'),
      R.filter(Boolean),
      R.head,
      R.lensProp,
      R.flip(R.view)(sideBarsItemIndices),
      (index) => index || R.prop(R.slice(1, Infinity, route), sideBarsItemIndices),
    )(route);

    if (!itemIndex) {
      return {
        type: types.SELECT_SIDE_BAR_MENU_ITEM_BY_ROUTE,
        payload: {
          itemIndex,
          /**
           * Indicate route changed if true
           * @type {Boolean}
           */
          indexChanged: false,
        },
      };
    }
    return {
      type: types.SELECT_SIDE_BAR_MENU_ITEM_BY_ROUTE,
      payload: { itemIndex, indexChanged: true },
    };
  },
);

export const selectSidebarIndexByRoute = createSelectSidebarIndexByRoute(sideBarsItemIndices);
