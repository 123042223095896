import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { reduxForm } from 'redux-form';
import { useHistory } from 'react-router-dom';

import { useAddFunctionToRef, useGetQuery } from '../../hooks/hooks';
import { dashboardRoute, signinRoute } from '../../routes';
import { errorServices, errorOperations } from '../../store/error/index';
import { routerServices } from '../../store/router/index';
import { userOperations } from '../../store/user/index';
import EmailField from '../EmailField/EmailField';
import ErrorMessage from '../ErrorMessage/ErrorMessage';
import { RoundedButton } from '../MaterialUiCustom/MaterialUiCustom';

import { asyncValidate } from './asyncValidate';
import { styles } from './styles';
import { validate } from './validate';

const { extractErrorMessage } = errorServices;
const useStyles = makeStyles(styles);

const ForgotPassword = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();

  const history = useHistory();
  const queryLng = useGetQuery('lng');
  const {
    handleSubmit,
  } = props;
  const { submitting, pristine, invalid } = props;

  const [disableButton, setDisableButton] = useState(false);
  const [done, setDone] = useState(false);

  const authentication = useSelector((state) => state.userR.authentication);
  const { isAuthenticated } = authentication;
  const errorR = useSelector((state) => state.errorR);
  const { sendForgotPasswordEmailError } = errorR || {};
  const message = extractErrorMessage(sendForgotPasswordEmailError);
  const [errorMessage, setErrorMessage] = useState('');

  const onDismount = useAddFunctionToRef(() => dispatch(errorOperations.clearError('sendForgotPasswordEmail')));

  useEffect(() => {
    setErrorMessage(message);
  }, [message]);

  useEffect(() => onDismount, [onDismount]);
  const { lang } = useSelector((state) => state.langR.langSetting);

  const handleForgotPassword = R.curry(async (lang, formValues) => {
    const { email } = formValues;
    const response = await dispatch(userOperations.sendForgotPasswordEmail(email, lang));
    if (response.type === 'SEND_FORGOT_PASSWORD_SUCCESS') {
      setDisableButton(true);
      setDone(true);
    }
  });

  useEffect(() => {
    if (isAuthenticated) {
      routerServices.historyPush(
        history,
        {
          pathname: dashboardRoute,
          queryLng,
        },
      );
    }
  }, [history, isAuthenticated, queryLng]);

  const handleOnClickSignin = () => {
    routerServices.historyPush(
      history,
      {
        pathname: signinRoute,
        queryLng,
      },
    );
  };
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Box className={classes.paper}>
          <form
            className={classnames(classes.centerComponent, classes.form)}
            onSubmit={handleSubmit(handleForgotPassword(lang))}
          >
            <Typography
              variant="h2"
              align="center"
              color="secondary"
              className={classes.title}
            >
              {t('Reset your password')}
            </Typography>
            <ErrorMessage errorMessage={errorMessage} />
            {!done && <EmailField renderCase="forgot password" />}
            {done && (
            <Grid container direction="column" justifyContent="center" alignItems="center" className={classnames(classes.middleElements)}>
              <CheckCircleIcon color="primary" style={{ fontSize: 40 }} />
              <Typography>{t('An email has been sent. Please check your email.')}</Typography>
            </Grid>
            )}
            <Box width="100%">
              <RoundedButton
                variant="contained"
                color="primary"
                type="submit"
                className={classes.button}
                disabled={disableButton || submitting || pristine || invalid}
                noMargin
              >
                {t('Send')}
              </RoundedButton>
            </Box>
          </form>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Divider className={classnames(classes.divider)} />
      </Grid>
      <Grid item xs={12}>
        <Box className={classes.paper}>
          <Grid container direction="row" justifyContent="center" alignItems="center" spacing={0}>
            <Grid item>
              <Typography variant="body1" align="right">{t('Already have an account?')}</Typography>
            </Grid>
            <Grid item>
              <RoundedButton
                variant="text"
                color="primary"
                onClick={() => handleOnClickSignin()}
                className={classes.signInButton}
              >
                {t('Sign in')}
              </RoundedButton>
            </Grid>
          </Grid>
        </Box>
      </Grid>
    </Grid>
  );
};
// validate prop' type
ForgotPassword.propTypes = {
  submitting: PropTypes.bool.isRequired,
  pristine: PropTypes.bool.isRequired,
  invalid: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
};

export default reduxForm({
  form: 'ForgotPassword',
  validate,
  asyncValidate,
})(ForgotPassword);
