import Grid from '@material-ui/core/Grid';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { investmentBaseRoute } from '../../routes';

import InvestmentContentItem from './InvestmentContentItem';

// others

const InvestmentContent = () => {
  const history = useHistory();
  const { t } = useTranslation();

  const onOpenNeunodePage = () => {
    history.push(`${investmentBaseRoute}/neunode`);
  };
  const onOpenMiningPage = () => {
    history.push(`${investmentBaseRoute}/mining`);
  };
  // const onOpenMarketPage = () => {
  //   history.push(marketRoute);
  // };

  return (
    <Grid container justifyContent="center" spacing={4}>
      <Grid item xs={12}>
        <InvestmentContentItem
          title={t('Neunode')}
          description={t('Stable return on your fiat based investment amount')}
          viewMoreCallback={onOpenNeunodePage}
        />
      </Grid>
      <Grid item xs={12}>
        <InvestmentContentItem
          title={t('Mining Node')}
          specialTag="Limited Sale"
          description={t('One-time payment, everyday rewards')}
          numOfPlaceHolders={1}
          viewMoreCallback={onOpenMiningPage}
        />
      </Grid>
      {/* <Grid item xs={12}>
        <InvestmentContentItem
          title={t('Staking')}
          description={t('Non-custodial, stable return of your favorite token')}
          numOfPlaceHolders={3}
          viewMoreCallback={onOpenMarketPage}
        />
      </Grid> */}
    </Grid>
  );
};

export default InvestmentContent;
