import { Decimal } from 'decimal.js';
import { gweiToEther } from 'jsutils';
import toNumber from 'lodash/toNumber';
import * as R from 'ramda';
import { combineReducers } from 'redux';
import { PURGE } from 'redux-persist';

import {
  createReducer,
  mergeStateAndPayload,
  updateStateWithKey,
} from '../store_utils';

import * as types from './types';
// utils
// STRIPE CUSTOMER
const walletConnectIS = {
  connector: null,
  fetching: false,
  chainId: 1,
  accounts: [],
  address: '',
  assets: [],
  connected: false,
};

const walletConnectRUpdaters = {
  [types.INITIALIZE_WALLET_CONNECT]: (
    state,
    payload,
  ) => updateStateWithKey('connector', state, payload),

  [types.WALLET_CONNECT_SUBSCRIBE_TO_EVENTS]: (
    state,
    payload,
  ) => updateStateWithKey('connector', state, payload),

  [types.ON_WALLET_CONNECT_UPDATE]: (
    state,
    payload,
  ) => mergeStateAndPayload(state, payload),

  [types.ON_WALLET_CONNECT_CONNECT]: (
    state,
    payload,
  ) => mergeStateAndPayload(state, payload),

  [types.ON_WALLET_CONNECT_DISCONECT]: (
    state,
    payload,
  ) => mergeStateAndPayload(state, payload),

  [types.GET_WALLET_CONNECT_ACCOUNT_ASSETS]: (
    state,
    payload,
  ) => updateStateWithKey('assets', state, payload),

  [types.ON_WALLET_CONNECT_DISCONECT]: R.always(walletConnectIS),

  [types.UPDATE_WALLET_CONNECTOR_DATA]: (
    state,
    payload,
  ) => {
    const { updateKey, data } = payload;
    return updateStateWithKey(updateKey, state, data);
  },

  [types.SEND_TRANSACTION_WALLET_CONNECT_SUCCESS]: (
    state,
    payload,
  ) => {
    const { connector } = payload;
    return updateStateWithKey('connector', state, connector);
  },

  [types.KILL_WALLET_CONNECT_SESSION]: (
    state,
    payload,
  ) => {
    const { killed } = payload;
    return updateStateWithKey('connected', state, !killed);
  },

  [types.WALLET_CONNECT_SUBSCRIBE_TO_CANCEL]: null,

  // this reset the state to initial state
  [PURGE]: R.always(walletConnectIS),

  // not update state
};

const walletConnectR = createReducer(walletConnectIS, walletConnectRUpdaters);

const depositTransactionIS = {
  ETH2_VALIDATOR_NODE: {
    depositData: '',
    amountToSend: 0,
    depositContractAddress: '0x1Dc1b731C972BD41891810f8B006dc4ca25e61F8',
  },
};

const depositTransactionRUpdaters = {
  [types.UPDATE_DEPOSIT_TRANSACTION_DATA]: (
    state,
    payload,
  ) => {
    const { updateKeyPath, data } = payload;
    return R.set(R.lensPath(updateKeyPath), data, state);
  },
  // reset only this reducer
  [types.RESET_DEPOSIT_TRANSACTION_DATA]: R.always(depositTransactionIS),

  // reset all along with some other reducers
  [PURGE]: R.always(depositTransactionIS),

};
const depositTransactionR = createReducer(depositTransactionIS, depositTransactionRUpdaters);

const gasPricesIS = {
  ETH2_VALIDATOR_NODE: {
    gasPriceWei: 0,
    gasPriceGwei: 0,
  },
};

const gasPricesRUpdaters = {
  [types.GET_GAS_PRICE]: (
    state,
    payload,
  ) => {
    const { gasPrice: gasPriceWei, nodeType } = payload;
    const gasPriceGwei = new Decimal(gweiToEther(toNumber(210000654654))).toFixed(2);
    const data = {};
    data[nodeType] = {
      gasPriceWei: toNumber(gasPriceWei),
      gasPriceGwei,
    };
    return R.merge(state, data);
  },
  // reset all along with some other reducers
  [PURGE]: R.always(gasPricesIS),
};
const gasPricesR = createReducer(gasPricesIS, gasPricesRUpdaters);

const reducers = combineReducers({
  walletConnect: walletConnectR,
  depositTransaction: depositTransactionR,
  gasPrices: gasPricesR,
});

export default reducers;
