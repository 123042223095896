import * as R from 'ramda';
import { combineReducers } from 'redux';
import { PURGE } from 'redux-persist';

import {
  createReducer,
  mergeStateAndPayload,
  createReducerUpdaterWithScope,
} from '../store_utils';

import * as types from './types';

/**
 * Pagination input reducer format
 *
 */
export const paginationInputIS = {
  getUsersPaginationInput: {
    pageIndex: 0,
    pageSize: 10,
  },
};

const paginationInputRUpdaters = {
  [types.SET_PAGINATION_INPUT_SUCCESS]: createReducerUpdaterWithScope('PaginationInput'),
  [PURGE]: R.always(paginationInputIS),
};

/**
 * Pagination Input reducer
 *
 * @param      {import('./typedefs').PaginationInputIS}  paginationInputIS
 * @param      {object}  paginationInputRUpdaters
 * @return     {any} return pagination input reducer
 */
const paginationInputR = createReducer(paginationInputIS, paginationInputRUpdaters);

/**
 * Pagination meta reducer format
 *
 */
export const paginationMetaIS = {
  getUsersPaginationMeta: {
    totalItems: 0,
    itemCount: 0,
    itemsPerPage: 0,
    totalPages: -1, // the default value for page count in react table is '-1'
    currentPage: 0,
  },
};

const paginationMetaRUpdaters = {
  [types.GET_PAGINATION_META_SUCCESS]: mergeStateAndPayload,
  [types.SET_PAGINATION_META_SUCCESS]: createReducerUpdaterWithScope('PaginationMeta'),
  [PURGE]: R.always(paginationMetaIS),
};

/**
 * Pagination meta reducer
 *
 * @param      {import('./typedefs').PaginationMetaIS}  paginationMetaIS
 * @param      {object}  paginationMetaRUpdaters
 * @return     {any} return pagination meta reducer
 */
const paginationMetaR = createReducer(paginationMetaIS, paginationMetaRUpdaters);

/**
 * Pagination reducer
 *
 * @type       {any}
 */
const paginationR = combineReducers({
  paginationInput: paginationInputR,
  paginationMeta: paginationMetaR,
});
export default paginationR;
