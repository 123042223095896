// this file will replece store/reducers.js
import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import adminR from './admin/index';
import blockchainConfigR from './blockchain_config/index';
import contactFormR from './contact_form/index';
import cryptoAddressR from './crypto_address/index';
import deployConfigR from './deploy_config/index';
import depositR from './deposit/index';
import drawerR from './drawer/index';
import errorR from './error/index';
import jwtR from './jwt/index';
import langR from './lang/index';
import loadingR from './loading/index';
import navigationR from './navigation/index';
import nodeR from './node/index';
import nodeOwnerR from './node_provider/index';
import paginationR from './pagination/index';
import paymentR from './payment/index';
import poolR from './pool/index';
import referralR from './referral/index';
import routerR from './router/index';
import secretPhraseR from './secret_phrase/index';
import stripeR from './stripe/index';
import userR from './user/index';
import walletR from './wallet/index';
import walletConnectR from './wallet_connect/index';
import web3R from './web3/index';

const adminPersistConfig = {
  key: 'admin',
  storage,
  blacklist: ['reloadUser', 'users', 'nodes', 'orders'],
};

export const rootReducer = combineReducers({
  adminR: persistReducer(adminPersistConfig, adminR),
  userR,
  nodeR,
  nodeOwnerR,
  drawerR,
  loadingR,
  errorR,
  jwtR,
  blockchainConfigR,
  secretPhraseR,
  routerR,
  stripeR,
  paymentR,
  navigationR,
  paginationR,
  deployConfigR,
  walletConnectR,
  walletR,
  web3R,
  depositR,
  referralR,
  langR,
  cryptoAddressR,
  contactFormR,
  poolR,
  form: formReducer,
});
