import React from 'react';
import './i18n';
import ReactDOM from 'react-dom';
import './index.css';
import { cryptoWaitReady } from '@polkadot/util-crypto';
// MUI THEME
import { ThemeProvider } from '@material-ui/styles';
// REDUX
import { Provider } from 'react-redux';
import {
  BrowserRouter as Router,
} from 'react-router-dom';
import { PersistGate } from 'redux-persist/lib/integration/react';
// REACT-ROUTER
// WEB3
import { Web3Provider } from '@ethersproject/providers';
import { Web3ReactProvider } from '@web3-react/core';

import 'react-toastify/dist/ReactToastify.css';
// STRIPE
import App from './App';
import ErrorBoundary from './components/Error/ErrorBoundary';
import Loading from './components/Loading/Loading';
import * as serviceWorker from './serviceWorker';
import { store, persistor } from './store/store';
import MuiTheme from './theme/MuiTheme';
// COMPONENTS

// STORE
// eslint-disable-next-line no-unused-vars
function getLibrary(provider, connector) {
  const library = new Web3Provider(provider);
  library.pollingInterval = 12000;
  return library;
}

cryptoWaitReady()
  .then(() => {
    ReactDOM.render(
      <Provider store={store}>
        <Web3ReactProvider getLibrary={getLibrary}>
          <PersistGate loading={<Loading />} persistor={persistor}>
            <ThemeProvider theme={MuiTheme}>
              <Router>
                <ErrorBoundary>
                  <App />
                </ErrorBoundary>
              </Router>
            </ThemeProvider>
          </PersistGate>
        </Web3ReactProvider>

      </Provider>, document.getElementById('root'),
    );
    serviceWorker.unregister();
  })
  .catch(console.error);
