import * as R from 'ramda';

import {
  createAction,
} from '../store_utils';

import { handleError, handleErrorMessage } from './services';
import * as types from './types';

/**
 * Customize error and create an action
 * @param  {string} scope - scope of the error
 * @param  {string} fallbackErrorMessage - custom message used when error doesn't have message
 * or we want to hide error from user
 * @param  {Object} error
 * @return {Object}  rerdux's action object
 */
export const setError = R.curry(
  (scope, { fallbackErrorMessage, error, errorMessageConverterObj }) => R.pipe(
    handleError,
    (customizedError) => handleErrorMessage(
      scope,
      { fallbackErrorMessage, error: customizedError, errorMessageConverterObj },
    ),
    createAction(types.SET_ERROR)(scope),
  )(error),
);

/**
 * Set error scope to empty (clear error)
 * @param  {string} scope - scope of the error
 * @return {Object}  rerdux's action object
 */
export const clearError = R.curry(
  (scope) => createAction(types.CLEAR_ERROR)(scope)(undefined),
);

export const setErrorToRender = R.curry((payload) => ({
  type: types.SET_ERROR_TO_RENDER,
  payload,
}));

export const clearErrorToRender = () => ({
  type: types.CLEAR_ERROR_TO_RENDER,
  payload: {},
});
