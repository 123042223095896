export const styles = (theme) => ({
  root: {
    ...theme.centerComponent,
    padding: theme.spacing(9, 6),
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(2, 1, 3, 1),
    },
  },
  parentBox: {
    width: '100%',
  },
  centerRightContent: {
    ...theme.centerComponent,
  },
});
