// mutation
import {
  createMutation,
} from '../../../modules/apollo/apollo';

import { generateDeployNodeConfigMutation } from './mutations';

// apollo

export const graphqlGenerateDeployNodeConfig = createMutation(generateDeployNodeConfigMutation);
