/* eslint-disable camelcase */
import {
  Box, IconButton, makeStyles, Typography,
} from '@material-ui/core';
import CloudUploadOutlinedIcon from '@material-ui/icons/CloudUploadOutlined';
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';
import whyDidYouRender from '@welldone-software/why-did-you-render';
import { isEmpty } from 'lodash';
import * as R from 'ramda';
import React, {
  useCallback, useEffect, useState,
} from 'react';
import { useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';

import { ununifiDeployFileStyles } from './styles';

import useAddFunctionToRef from 'hooks/useAddFunctionToRef';

const useStyles = makeStyles(ununifiDeployFileStyles);

const { REACT_APP_ENV } = process.env;

if (REACT_APP_ENV !== 'prod') {
  whyDidYouRender(React);
}

function UnUniFiDeployFile({
  setError,
  onFileChange,
  data,
  nodeId,
  isError,
  setErrorIn,
  removeFile,
}) {
  const [nodeData, setNodeData] = useState({});
  const classes = useStyles();
  const { t } = useTranslation();
  const onFileChangeRef = useAddFunctionToRef(onFileChange);
  const handleSimilarFiles = useAddFunctionToRef((data, fileContent) => {
    const hasDups = R.pipe(
      R.values,
      R.map((item) => {
        const { public_key } = item.fileContent;
        return fileContent.public_key === public_key;
      }),
      R.any((item) => !!item),
    )(data);
    if (hasDups) {
      setError(t('Duplicate public-wallet file. Please check your file'));
      setErrorIn((state) => R.assoc(nodeId, true, state));
      return false;
    }
    return true;
  });

  const readFile = useAddFunctionToRef((acceptedFiles) => {
    try {
      if (acceptedFiles.length < 1) return;
      const reader = new FileReader();
      reader.onload = () => {
        const content = JSON.parse(reader.result);
        if (content.mnemonic) {
          setErrorIn((state) => R.assoc(nodeId, true, state));
          return setError(
            t('Please upload your public-wallet file not wallet-backup file'),
          );
        }
        const ok = handleSimilarFiles(data, content);
        if (ok) {
          return onFileChangeRef(acceptedFiles, content);
        }
        return undefined;
      };
      reader.readAsText(acceptedFiles[0]);
    } catch (error) {
      throw new Error(error.toString());
    }
  });
  const onDrop = useCallback((acceptedFiles) => {
    setErrorIn((state) => R.assoc(nodeId, false, state));
    readFile(acceptedFiles);
  }, [nodeId, readFile, setErrorIn]);
  const { getRootProps, getInputProps } = useDropzone({
    maxFiles: 1,
    onDrop,
    accept: '.txt',
  });

  useEffect(() => {
    if (!isEmpty(data)) {
      const nodeData = data[nodeId];
      if (nodeData) setNodeData(nodeData);
    }
  }, [data, nodeId]);

  const onRemoveFile = (nodeId) => {
    removeFile(nodeId);
    setNodeData({});
    setErrorIn((state) => R.assoc(nodeId, false, state));
  };
  const getContent = () => (
    <Box
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      width="100%"
      mb={1}
    >
      {!isEmpty(nodeData) && !isError ? (
        <Typography>{nodeData.fileName}</Typography>
      ) : (
        <Box className={classes.uploadSection}>
          <div {...getRootProps()}>
            <input {...getInputProps()} />
            <Box display="flex" justifyContent="space-between" alignItems="center" p={2}>
              <CloudUploadOutlinedIcon />
              <Box ml={1}><Typography variant="body2">{t('Upload your public-wallet file')}</Typography></Box>
            </Box>

          </div>
        </Box>
      )}
      {nodeData.nodeId && (
      <IconButton onClick={() => onRemoveFile(nodeId)}>
        <DeleteOutlineOutlinedIcon />
      </IconButton>
      )}
    </Box>
  );
  return getContent();
}
UnUniFiDeployFile.whyDidYouRender = true;

export default React.memo(UnUniFiDeployFile);
