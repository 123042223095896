import * as R from 'ramda';

import { errorOperations } from '../error/index';
import { loadingOperations } from '../loading/index';

import * as actions from './actions';

export const getNodeProviderOrder = R.curry(
  async (userId, dispatch) => {
    try {
      dispatch(loadingOperations.setLoading('getNodeProviderOrder', true));
      const response = dispatch(
        await actions.getNodeProviderOrder(userId),
      );
      dispatch(errorOperations.clearError('getNodeProviderOrder'));
      dispatch(loadingOperations.setLoading('getNodeProviderOrder', false));
      return response;
    } catch (error) {
      dispatch(loadingOperations.setLoading('getNodeProviderOrder', false));
      return dispatch(errorOperations.setError(
        'getNodeProviderOrder',
        { error },
      ));
    }
  },
);

export const { updateNodeProviderOrderConfig } = actions;
export const { resetNodeProviderOrderConfig } = actions;
