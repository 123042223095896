import { IconButton, InputAdornment, makeStyles } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { TextField } from 'mui-rff';
import * as R from 'ramda';
import React, { useState } from 'react';
import { Form, FormSpy } from 'react-final-form';
import { useDispatch } from 'react-redux';

import { RoundedButton } from '../../../MaterialUiCustom/MaterialUiCustom';

import { addressCreationGenerateStyles } from './styles';

import { web3Operations } from 'store/web3';

const useStyles = makeStyles(addressCreationGenerateStyles);
// const subChain = 'pha';
const MiningAddressCreationConfigure = ({
  secretPhrase,
  onPasswordChange,
  onPristineChange,
  formId,
  t,
  onErrorsChange,
  onWalletConfigChange,
  initialValues,
  onLastWalletConfigChange,
}) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const onGenerateAccounts = R.curry(
    (coin, data) => {
      dispatch(web3Operations.generateAccounts(coin, data));
    },
  );

  const onGenerateAccountsWrap = (values) => {
    const { password, name } = values;
    onLastWalletConfigChange(values);
    onGenerateAccounts(
      'dot',
      {
        type: null,
        // hardcode this so that it return phala
        ss58Format: 30,
        // hard derivation with 0
        hardDerivation: 0,
        // only generate 1 account
        startIndex: 0,
        endIndex: 0,
        secretPhrase,
        password,
        metadata: { name },
      },
    );
  };
  const showBtn = false;
  const validate = (values) => {
    const errors = {};
    if (!values.name) {
      errors.name = t('Required');
    }
    if (!values.password) {
      errors.password = t('Required');
    }
    return errors;
  };
  return (
    <Form
      initialValues={initialValues}
      onSubmit={onGenerateAccountsWrap}
      subscription={{ submitting: true, pristine: true, errors: true }}
      keepDirtyOnReinitialize
      validateOnBlur
      validate={validate}
      render={({
        handleSubmit,
        form,
        pristine,
      }) => (
        <form
          id={formId || 'walletConfigureForm'}
          onSubmit={handleSubmit}
        >
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                id="name-input-textfield"
                // i18next-extract-mark-context-next-line ["wallet"]
                label={t('Name', { context: 'wallet' })}
                variant="outlined"
                name="name"
                required
                type="text"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                name="password"
                id="password-input-with-icon-textfield"
                label={t('Password')}
                variant="outlined"
                required
                type={showPassword ? 'text' : 'password'}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label={t('Toggle password visibility')}
                        onClick={handleClickShowPassword}
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            {showBtn && (
              <Grid item xs={12} className={classes.actionGrid}>
                <RoundedButton
                  variant="text"
                  color="default"
                >
                  { t('back')}
                </RoundedButton>
                <RoundedButton
                  type="submit"
                  variant="contained"
                  disabled={pristine}
                  color="primary"
                >
                  { t('generate')}
                </RoundedButton>
              </Grid>
            )}
          </Grid>
          <FormSpy subscription={{ values: true, pristine: true, errors: true }}>
            {({ values, pristine, errors }) => {
              // setTimeout an hacky way to avoid FormSpy warning
              setTimeout(() => onPristineChange(pristine), 0);
              setTimeout(() => onWalletConfigChange(values), 0);
              setTimeout(() => onErrorsChange(errors), 0);

              return null;
            }}
          </FormSpy>
        </form>
      )}
    />
  );
};

export default MiningAddressCreationConfigure;
