import * as R from 'ramda';
import { isArray } from 'ramda-adjunct';

import { errorOperations } from '../error/index';
import { loadingOperations } from '../loading/index';

import * as actions from './actions';

/**
 * Get user nodes
 *
 * @param      {string}  userId
 * @param      {Function}  dispatch  The dispatch function of redux
 * @return     {import('../typedefs').ReduxAction}
 */
export const getUserNodes = R.curry(
  async (userId, dispatch) => {
    try {
      dispatch(loadingOperations.setLoading('getUserNodes', true));
      const response = dispatch(await actions.getUserNodes(userId));
      dispatch(errorOperations.clearError('getUserNodes'));
      dispatch(loadingOperations.setLoading('getUserNodes', false));
      return response;
    } catch (error) {
      dispatch(loadingOperations.setLoading('getUserNodes', false));
      return dispatch(errorOperations.setError(
        'getUserNodes',
        { error },
      ));
    }
  },
);

/**
 * Get node status by node name
 *
 * @param      {string}  userId
 * @param      {string}  nodeName
 * @param      {Function}  dispatch  The dispatch function of redux
 * @return     {import('../typedefs').ReduxAction}
 */
export const getUserNode = R.curry(
  async (userId, nodeName, dispatch) => {
    try {
      dispatch(loadingOperations.setLoading('getUserNode', true));

      const response = dispatch(
        await actions.getUserNode(userId, nodeName),
      );

      dispatch(errorOperations.clearError('getUserNode'));
      dispatch(loadingOperations.setLoading('getUserNode', false));
      return response;
    } catch (error) {
      dispatch(loadingOperations.setLoading('getUserNode', false));
      return dispatch(errorOperations.setError(
        'getUserNode',
        { error },
      ));
    }
  },
);

export const saveNodeDeployInputsExtra = R.curry(
  async (nodeType, data, dispatch) => {
    try {
      dispatch(loadingOperations.setLoading('saveNodeDeployInputsExtra', true));
      const response = dispatch(
        await actions.saveNodeDeployInputsExtra(
          nodeType,
          data,
        ),
      );
      dispatch(errorOperations.clearError('saveNodeDeployInputsExtra'));
      return response;
    } catch (error) {
      dispatch(loadingOperations.setLoading('saveNodeDeployInputsExtra', false));
      return dispatch(errorOperations.setError(
        'getUserNodes',
        { fallbackErrorMessage: 'save node deploy input extra failed', error },
      ));
    }
  },
);

// export const formatSubNodeData = R.curry((nodeType, data, dispatch) => {
//   try {
//     return actions.formatSubNodeData(nodeType, data);
//   } catch (error) {
//     return dispatch(errorOperations.setError(
//       'formatSubNodeData',
//       { message: 'Formate sub node failed', error },
//     ));
//   }
// });

export const getUserNodeEarning = R.curry(
  async (
    nodeType,
    nodeName,
    publicKey,
    dispatch,
  ) => {
    try {
      dispatch(loadingOperations.setLoading('getUserNodeEarning', true));
      const response = dispatch(
        await actions.getUserNodeEarning(
          nodeType,
          nodeName,
          publicKey,
        ),
      );

      dispatch(errorOperations.clearError('getUserNodeEarning'));
      dispatch(loadingOperations.setLoading('getUserNodeEarning', false));
      return response;
    } catch (error) {
      dispatch(loadingOperations.setLoading('getUserNodeEarning', false));
      return dispatch(errorOperations.setError(
        'getUserNodeEarning',
        { fallbackErrorMessage: 'get user node data failed', error },
      ));
    }
  },
);

export const updateUserNode = R.curry(
  async (
    userId,
    nodeName,
    nodeType,
    parentNode,
    subNode,
    dispatch,
  ) => {
    try {
      dispatch(loadingOperations.setLoading('updateUserNode', true));
      const response = dispatch(
        await actions.updateUserNode(
          userId,
          nodeName,
          nodeType,
          parentNode,
          subNode,
        ),
      );
      dispatch(errorOperations.clearError('updateUserNode'));
      dispatch(loadingOperations.setLoading('updateUserNode', false));
      return response;
    } catch (error) {
      dispatch(loadingOperations.setLoading('updateUserNode', false));
      return dispatch(errorOperations.setError(
        'updateUserNode',
        { fallbackErrorMessage: 'update user node failed', error },
      ));
    }
  },
);

export const updateUserNodes = R.curry(
  async (
    nodes,
    dispatch,
  ) => {
    try {
      dispatch(loadingOperations.setLoading('updateUserNodes', true));
      const response = dispatch(
        await actions.updateUserNodes(
          nodes,
        ),
      );
      dispatch(errorOperations.clearError('updateUserNodes'));
      dispatch(loadingOperations.setLoading('updateUserNodes', false));
      return response;
    } catch (error) {
      dispatch(loadingOperations.setLoading('updateUserNodes', false));
      return dispatch(errorOperations.setError(
        'updateUserNodes',
        { fallbackErrorMessage: 'update user nodes failed', error },
      ));
    }
  },
);

export const getNodesEstimateActivationDate = R.curry(
  async (
    publicKeys,
    nodeType,
    dispatch,
  ) => {
    try {
      dispatch(loadingOperations.setLoading('getNodesEstimateActivationDate', true));
      const response = dispatch(
        await actions.getNodesEstimateActivationDate(
          publicKeys,
          nodeType,
        ),
      );
      dispatch(errorOperations.clearError('getNodesEstimateActivationDate'));
      dispatch(loadingOperations.setLoading('getNodesEstimateActivationDate', false));
      return response;
    } catch (error) {
      dispatch(loadingOperations.setLoading('getNodesEstimateActivationDate', false));
      return dispatch(errorOperations.setError(
        'getNodesEstimateActivationDate',
        { fallbackErrorMessage: 'Get node estimate activation date failed', error },
      ));
    }
  },
);

export const getNodesOnChainStatus = R.curry(
  async (
    publicKeys,
    nodeType,
    dispatch,
  ) => {
    try {
      dispatch(loadingOperations.setLoading('getNodesOnChainStatus', true));
      const response = dispatch(
        await actions.getNodesOnChainStatus(
          publicKeys,
          nodeType,
        ),
      );
      dispatch(errorOperations.clearError('getNodesOnChainStatus'));
      dispatch(loadingOperations.setLoading('getNodesOnChainStatus', false));
      return response;
    } catch (error) {
      dispatch(loadingOperations.setLoading('getNodesOnChainStatus', false));
      return dispatch(errorOperations.setError(
        'getNodesOnChainStatus',
        { error },
      ));
    }
  },
);

export const getTotalEarning = R.curry(
  async (
    userId,
    publicKeys,
    nodeType,
    dispatch,
  ) => {
    try {
      dispatch(loadingOperations.setLoading('getTotalEarning', true));
      const response = dispatch(
        await actions.getTotalEarning(
          userId,
          publicKeys,
          nodeType,
        ),
      );
      dispatch(errorOperations.clearError('getTotalEarning'));
      dispatch(loadingOperations.setLoading('getTotalEarning', false));
      return response;
    } catch (error) {
      dispatch(loadingOperations.setLoading('getTotalEarning', false));
      return dispatch(errorOperations.setError(
        'getTotalEarning',
        { error },
      ));
    }
  },
);

export const getTotalEarningInPeriod = R.curry(
  async (
    publicKeys,
    nodeType,
    period,
    dispatch,
  ) => {
    const periodName = R.replace('_', '', period);
    const functionNameToUse = isArray(publicKeys) ? `getTotalEarningIn${periodName}` : `getTotalEarningIn${periodName}OneNode`;
    try {
      dispatch(loadingOperations.setLoading(functionNameToUse, true));
      const response = dispatch(
        await actions.getTotalEarningInPeriod(
          publicKeys,
          nodeType,
          period,
        ),
      );
      dispatch(errorOperations.clearError(functionNameToUse));
      dispatch(loadingOperations.setLoading(functionNameToUse, false));
      return response;
    } catch (error) {
      dispatch(loadingOperations.setLoading(functionNameToUse, false));
      return dispatch(errorOperations.setError(
        functionNameToUse,
        { error },
      ));
    }
  },
);

export const getIntervalEarning = R.curry(
  async (
    userId,
    nodeType,
    currency,
    startTime,
    endTime,
    interval,
    rows,
    page,
    dispatch,
  ) => {
    const functionNameToUse = 'getIntervalEarning';
    try {
      dispatch(loadingOperations.setLoading(functionNameToUse, true));
      const response = dispatch(
        await actions.getIntervalEarning(
          userId,
          nodeType,
          currency,
          startTime,
          endTime,
          interval,
          rows,
          page,
        ),
      );
      dispatch(errorOperations.clearError(functionNameToUse));
      dispatch(loadingOperations.setLoading(functionNameToUse, false));
      return response;
    } catch (error) {
      dispatch(loadingOperations.setLoading(functionNameToUse, false));
      return dispatch(errorOperations.setError(
        functionNameToUse,
        { error },
      ));
    }
  },
);

export const getUserEarningHistory = R.curry(
  async (
    userId,
    nodeType,
    currency,
    startTime,
    endTime,
    interval,
    dispatch,
  ) => {
    const functionNameToUse = 'getUserEarningHistory';
    try {
      dispatch(loadingOperations.setLoading(functionNameToUse, true));
      const response = dispatch(
        await actions.getUserEarningHistory(
          userId,
          nodeType,
          currency,
          startTime,
          endTime,
          interval,
        ),
      );
      dispatch(errorOperations.clearError(functionNameToUse));
      dispatch(loadingOperations.setLoading(functionNameToUse, false));
      return response;
    } catch (error) {
      dispatch(loadingOperations.setLoading(functionNameToUse, false));
      return dispatch(errorOperations.setError(
        functionNameToUse,
        { error },
      ));
    }
  },
);

export const getCoinPrice = R.curry(
  async (
    coinSymbol,
    currency,
    dispatch,
  ) => {
    try {
      dispatch(loadingOperations.setLoading('getCoinPrice', true));
      const response = dispatch(
        await actions.getCoinPrice(
          coinSymbol,
          currency,
        ),
      );
      dispatch(errorOperations.clearError('getCoinPrice'));
      dispatch(loadingOperations.setLoading('getCoinPrice', false));
      return response;
    } catch (error) {
      dispatch(loadingOperations.setLoading('getCoinPrice', false));
      return dispatch(errorOperations.setError(
        'getCoinPrice',
        { error },
      ));
    }
  },
);

export const getCoinPriceChart = R.curry(
  async (
    coinSymbol,
    currency,
    days,
    dispatch,
  ) => {
    const operationName = 'getCoinPriceChart';
    try {
      dispatch(loadingOperations.setLoading(operationName, true));
      const response = dispatch(
        await actions.getCoinPriceChart(
          coinSymbol,
          currency,
          days,
        ),

      );
      dispatch(errorOperations.clearError(operationName));
      dispatch(loadingOperations.setLoading(operationName, false));
      return response;
    } catch (error) {
      dispatch(loadingOperations.setLoading(operationName, false));
      return dispatch(errorOperations.setError(
        operationName,
        { error },
      ));
    }
  },
);

export const getZenBalance = R.curry(
  async (
    userId,
    dispatch,
  ) => {
    try {
      dispatch(loadingOperations.setLoading('getZenBalance', true));
      const response = dispatch(
        await actions.getZenBalance(
          userId,
        ),
      );
      dispatch(errorOperations.clearError('getZenBalance'));
      dispatch(loadingOperations.setLoading('getZenBalance', false));
      return response;
    } catch (error) {
      dispatch(loadingOperations.setLoading('getZenBalance', false));
      return dispatch(errorOperations.setError(
        'getZenBalance',
        { error },
      ));
    }
  },
);

export const getTotalEarningInfluxdb = R.curry(
  async (
    userId,
    nodeType,
    currency,
    duration,
    dispatch,
  ) => {
    try {
      dispatch(loadingOperations.setLoading('getTotalEarningInfluxdb', true));
      const response = dispatch(
        await actions.getTotalEarningInfluxdb(
          userId,
          nodeType,
          currency,
          duration,
        ),
      );
      dispatch(errorOperations.clearError('getTotalEarningInfluxdb'));
      dispatch(loadingOperations.setLoading('getTotalEarningInfluxdb', false));
      return response;
    } catch (error) {
      dispatch(loadingOperations.setLoading('getTotalEarningInfluxdb', false));
      return dispatch(errorOperations.setError(
        'getTotalEarningInfluxdb',
        { error },
      ));
    }
  },
);

export const getIntervalFixedEarning = R.curry(
  async (
    data,
    dispatch,
  ) => {
    const operationName = 'getIntervalFixedEarning';
    const {
      userId,
      earningType,
      startTime,
      endTime,
      interval,
      contractId,
      currency,
      page,
      rows,
    } = data;
    try {
      dispatch(loadingOperations.setLoading(operationName, true));
      const response = dispatch(
        await actions.getIntervalFixedEarning(
          userId,
          earningType,
          startTime,
          endTime,
          interval,
          contractId,
          currency,
          page,
          rows,
        ),
      );
      dispatch(errorOperations.clearError(operationName));
      dispatch(loadingOperations.setLoading(operationName, false));
      return response;
    } catch (error) {
      dispatch(loadingOperations.setLoading(operationName, false));
      return dispatch(errorOperations.setError(
        operationName,
        { error },
      ));
    }
  },
);

export const getTotalFixedEarning = R.curry(
  async (
    userId,
    startTime,
    endTime,
    contractId,
    currency,
    dispatch,
  ) => {
    const operationName = 'getTotalFixedEarning';
    try {
      dispatch(loadingOperations.setLoading(operationName, true));
      const response = dispatch(
        await actions.getTotalFixedEarning(
          userId,
          startTime,
          endTime,
          contractId,
          currency,
        ),
      );
      dispatch(errorOperations.clearError(operationName));
      dispatch(loadingOperations.setLoading(operationName, false));
      return response;
    } catch (error) {
      dispatch(loadingOperations.setLoading(operationName, false));
      return dispatch(errorOperations.setError(
        operationName,
        { error },
      ));
    }
  },
);

export const getNodeOwnerTotalEarning = R.curry(
  async (
    userId,
    nodeType,
    dispatch,
  ) => {
    const operationName = 'getNodeOwnerTotalEarning';
    try {
      dispatch(loadingOperations.setLoading(operationName, true));
      const response = dispatch(
        await actions.getNodeOwnerTotalEarning(
          userId,
          nodeType,
        ),
      );
      dispatch(errorOperations.clearError(operationName));
      dispatch(loadingOperations.setLoading(operationName, false));
      return response;
    } catch (error) {
      dispatch(loadingOperations.setLoading(operationName, false));
      return dispatch(errorOperations.setError(
        operationName,
        { error },
      ));
    }
  },
);
