import Box from '@material-ui/core/Box';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grid from '@material-ui/core/Grid';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import NavigateNextOutlinedIcon from '@material-ui/icons/NavigateNextOutlined';
import Skeleton from '@material-ui/lab/Skeleton';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import ChainIcon from '../Chain/ChainIcon';
import { RoundedButton } from '../MaterialUiCustom/MaterialUiCustom';

import OverviewItemDescription from './OverviewItemDescription';
import { styles } from './styles';

const useStyles = makeStyles(styles);
const OverviewItem = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const [openTooltip, setOpenTooltip] = useState(false);
  const handleTooltipClose = () => {
    setOpenTooltip(false);
  };
  const handleTooltipOpen = () => {
    setOpenTooltip(true);
  };

  const {
    data, disabled, showTitles, type, onClick, loading,
  } = props;

  if (loading) {
    return (
      <Box
        className={classes.loadingItem}
      >
        <Skeleton
          variant="text"
          width="100%"
          height="100%"
        />
      </Box>
    );
  }

  return (
    <ClickAwayListener onClickAway={handleTooltipClose}>
      <Tooltip
        arrow
        open={openTooltip && disabled}
        title={t('Details coming soon')}
        placement="left"
      >
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          className={classes.item}
          onClick={disabled ? handleTooltipOpen : onClick}
        >
          <Box flexGrow={1}>
            <Box
              className={classes.itemContent}
            >
              <Grid
                container
                justifyContent="center"
                alignItems="center"
              >
                <Grid item>
                  <ChainIcon
                    image={data.image}
                    title={data.name}
                    className={classes.chainIconMobile}
                  />
                </Grid>
                <Grid item xs>
                  <OverviewItemDescription
                    image={data.image}
                    name={data.name}
                    data={data}
                    showTitles={showTitles}
                    status={data.status}
                    type={type}
                  />
                </Grid>
              </Grid>
            </Box>
          </Box>
          <Box>

            <RoundedButton variant="text" noMargin className={classes.nodeDescriptionDetailsButton}>

              <Box
                display="flex"
                alignItems="center"
              >
                <Box>
                  <Typography color="primary" className={classes.nodeDescriptionDetailsButtonText}>
                    {t('Details')}
                  </Typography>
                </Box>
                <Box mt={0.5}>
                  <NavigateNextOutlinedIcon color="primary" className={classes.nodeDescriptionDetailsButtonText} />
                </Box>
              </Box>
            </RoundedButton>
          </Box>
        </Box>
      </Tooltip>
    </ClickAwayListener>
  );
};

OverviewItem.propTypes = {
  /**
   * data data to display
   */
  data: PropTypes.shape({
    image: PropTypes.string,
    name: PropTypes.string.isRequired,
    dateCreated: PropTypes.string,
    plan: PropTypes.string,
    status: PropTypes.string,
    apy: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
      PropTypes.element,
    ]),
    amount: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
      PropTypes.element,
    ]),
    quantity: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
      PropTypes.element,
    ]),
    staked: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
      PropTypes.element,
    ]),
    earning: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
      PropTypes.element,
    ]),
    balance: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
      PropTypes.element,
    ]),
  }).isRequired,
  /**
   * Disable on click function
   */
  disabled: PropTypes.bool,
  showTitles: PropTypes.bool,
  type: PropTypes.string,
  onClick: PropTypes.func,
  loading: PropTypes.bool,
};

OverviewItem.defaultProps = {
  disabled: false,
  showTitles: true,
  type: 'naas',
  onClick: () => {},
  loading: false,
};

export default React.memo(OverviewItem);
