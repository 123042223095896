import * as R from 'ramda';

import { errorOperations } from '../error/index';
import { loadingOperations } from '../loading/index';

import * as actions from './actions';

export const createCryptoAddress = R.curry(
  async (
    userId,
    address,
    chain,
    subChain,
    dispatch,
  ) => {
    try {
      dispatch(loadingOperations.setLoading('createCryptoAddress', true));
      const { type, payload } = dispatch(await actions.createCryptoAddress(
        userId,
        address,
        chain,
        subChain,
      ));
      dispatch(loadingOperations.setLoading('createCryptoAddress', false));
      dispatch(errorOperations.clearError('createCryptoAddress'));
      return { type, payload };
    } catch (error) {
      dispatch(loadingOperations.setLoading('createCryptoAddress', false));
      return dispatch(errorOperations.setError(
        'createCryptoAddress',
        {
          fallbackErrorMessage: 'Create crypto address failed',
          error,
        },
      ));
    }
  },
);

export const getCryptoAddresses = R.curry(
  async (
    userId,
    address,
    chain,
    subChain,
    dispatch,
  ) => {
    try {
      dispatch(loadingOperations.setLoading('getCryptoAddresses', true));
      const { type, payload } = dispatch(
        await actions.getCryptoAddresses(
          userId,
          address,
          chain,
          subChain,
        ),
      );
      dispatch(loadingOperations.setLoading('getCryptoAddresses', false));
      dispatch(errorOperations.clearError('getCryptoAddresses'));
      return { type, payload };
    } catch (error) {
      dispatch(loadingOperations.setLoading('getCryptoAddresses', false));
      return dispatch(errorOperations.setError(
        'getCryptoAddresses',
        {
          fallbackErrorMessage: 'Get crypto address failed',
          error,
        },
      ));
    }
  },
);

export const generateAccounts = R.curry(
  async (
    coin,
    data,
    dispatch,
  ) => {
    try {
      dispatch(loadingOperations.setLoading('generateAccounts', true));
      const { type, payload } = dispatch(actions.generateAccounts(coin, data));
      dispatch(errorOperations.clearError('generateAccounts'));
      return { type, payload };
    } catch (error) {
      dispatch(loadingOperations.setLoading('generateAccounts', false));
      return dispatch(errorOperations.setError(
        'generateAccounts',
        { fallbackErrorMessage: 'generate accounts failed', error },
      ));
    }
  },
);

export const backupAccount = R.curry(
  (
    coin,
    data,
    dispatch,
  ) => {
    try {
      dispatch(loadingOperations.setLoading('backupAccount', true));
      const { type, payload } = dispatch(actions.backupAccount(coin, data));
      dispatch(errorOperations.clearError('backupAccount'));
      return { type, payload };
    } catch (error) {
      dispatch(loadingOperations.setLoading('backupAccount', false));
      return dispatch(errorOperations.setError(
        'backupAccount',
        { fallbackErrorMessage: 'backup accounts failed', error },
      ));
    }
  },
);
