import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { useTheme, makeStyles } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import AddOutlinedIcon from '@material-ui/icons/AddOutlined';
import RemoveOutlinedIcon from '@material-ui/icons/RemoveOutlined';
import whyDidYouRender from '@welldone-software/why-did-you-render';
import toNumber from 'lodash/toNumber';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';

import { nodeOwnerOperations } from '../../../store/node_provider/index';

import {
  nodeOwnerFormCartStyles,
} from './styles';

const { REACT_APP_ENV } = process.env;

if (REACT_APP_ENV !== 'prod') {
  whyDidYouRender(React);
}

const useStyles = makeStyles(nodeOwnerFormCartStyles);

const NodeOwnerFormCart = (props) => {
  const { t } = useTranslation();
  const {
    price, productName, disabled, min, max,
  } = props;
  const classes = useStyles();
  const theme = useTheme();
  const dispatch = useDispatch();
  const isUnderMd = useMediaQuery(theme.breakpoints.down('md'));
  const { quantity } = useSelector((state) => state.nodeOwnerR.nodeOwnerOrderConfig);

  const { items } = useSelector((state) => state.nodeOwnerR.nodeOwnerOrderConfig);

  const priceText = price.toLocaleString('en-US', {
    style: 'currency',
    currency: 'JPY',
  });

  const onUpdateNodeProviderOrderQuantity = (newQuantity, subtotal, tax) => {
    dispatch(nodeOwnerOperations.updateNodeProviderOrderConfig(['quantity'], newQuantity));
    dispatch(nodeOwnerOperations.updateNodeProviderOrderConfig(['items'], [
      {
        image: items[0].image,
        product: productName,
        price: priceText,
        quantity: newQuantity,
        subtotal,
        ct: tax,
      },
    ]));
  };

  const setQuantityValue = (value) => {
    if (value >= min && value <= max) {
      onUpdateNodeProviderOrderQuantity(value, value * price, value * (price / 10));
    }
  };
  const handleQuantityChange = (event) => {
    const { value } = event.target;
    setQuantityValue(toNumber(value));
  };

  const getMobileItemInfoLine = (title, text) => (
    <Box display="flex">
      <Box mr={1} flex={0.3}>
        <Typography color="textSecondary" noWrap>
          {title}
          :
        </Typography>
      </Box>
      <Box flex={0.7}>
        <Typography>
          {text}
        </Typography>
      </Box>
    </Box>
  );

  const getItemsContent = (data) => R.addIndex(R.map)((eachItem, index) => {
    const {
      image,
      product,
      price,
      subtotal,
      ct,
    } = eachItem;

    if (isUnderMd) {
      return (
        <Box display="flex" alignItems="center" key={`${product}_${index}`} mb={2}>
          <Box flex={0.5}>
            <img
              src={image}
              alt={`${product}_icon_${index}`}
              style={{ width: 100 }}
            />
          </Box>
          <Box flex={1}>
            <Box display="flex" flexDirection="column">
              <Box>
                {getMobileItemInfoLine(t('Product'), product)}
              </Box>
              <Box>
                {getMobileItemInfoLine(t('Price'), price)}
              </Box>
              <Box>
                <Box display="flex" alignItems="center">
                  <Box mr={1} flex={0.3}>
                    <Typography color="textSecondary">
                      {t('Qty')}
                      :
                    </Typography>
                  </Box>
                  <Box flex={0.7}>
                    <Box
                      display="flex"
                      alignItems="center"
                    >
                      <Box>
                        <IconButton
                          className={classes.quantityButtonMinor}
                          onClick={() => setQuantityValue(quantity - 1)}
                          disabled={disabled}
                        >
                          <RemoveOutlinedIcon fontSize="small" />
                        </IconButton>
                      </Box>
                      <Box>
                        <TextField
                          name="quantity"
                          type="tel"
                          variant="outlined"
                          value={quantity}
                          onChange={handleQuantityChange}
                          InputProps={{ inputProps: { min, max } }}
                          className={classes.quantity}
                          disabled={disabled}
                        />
                      </Box>
                      <Box>
                        <IconButton
                          className={classes.quantityButtonPlus}
                          onClick={() => setQuantityValue(quantity + 1)}
                          disabled={disabled}
                        >
                          <AddOutlinedIcon fontSize="small" />
                        </IconButton>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Box>
              <Box>
                {getMobileItemInfoLine(t('Subtotal'), subtotal.toLocaleString('en-US', {
                  style: 'currency',
                  currency: 'JPY',
                }))}
              </Box>
              <Box>
                {getMobileItemInfoLine(t('CT (10%)'), ct.toLocaleString('en-US', {
                  style: 'currency',
                  currency: 'JPY',
                }))}
              </Box>
            </Box>
          </Box>
        </Box>
      );
    }
    return (
      <Box display="flex" alignItems="center" key={`${product}_${index}`}>
        <Box flex={1}>
          <img
            src={image}
            alt={`${product}_icon_${index}`}
            style={{ width: 50 }}
          />
        </Box>
        <Box flex={1}>
          <Typography>
            {product}
          </Typography>
        </Box>
        <Box flex={1}>
          <Typography>
            {price}
          </Typography>
        </Box>
        <Box flex={1}>
          <Box
            display="flex"
            alignItems="center"
          >
            <Box>
              <IconButton
                className={classes.quantityButtonMinor}
                onClick={() => setQuantityValue(quantity - 1)}
                disabled={disabled}
              >
                <RemoveOutlinedIcon fontSize="small" />
              </IconButton>
            </Box>
            <Box flex={0.8}>
              <TextField
                name="quantity"
                type="tel"
                variant="outlined"
                value={quantity}
                onChange={handleQuantityChange}
                InputProps={{ inputProps: { min, max } }}
                className={classes.quantity}
                disabled={disabled}
              />
            </Box>
            <Box>
              <IconButton
                className={classes.quantityButtonPlus}
                onClick={() => setQuantityValue(quantity + 1)}
                disabled={disabled}
              >
                <AddOutlinedIcon fontSize="small" />
              </IconButton>
            </Box>
          </Box>
        </Box>
        <Box flex={1}>
          <Typography>
            {subtotal.toLocaleString('en-US', {
              style: 'currency',
              currency: 'JPY',
            })}
          </Typography>
        </Box>
        <Box flex={1}>
          <Typography>
            {ct.toLocaleString('en-US', {
              style: 'currency',
              currency: 'JPY',
            })}
          </Typography>
        </Box>
      </Box>
    );
  }, data);

  return (
    <Box
      display="flex"
      flexDirection="column"
      style={{ width: '100%' }}
    >
      {!isUnderMd && (
      <Box display="flex">
        <Box flex={1} />
        <Box flex={1}>
          <Typography color="textSecondary">
            {t('Product')}
          </Typography>
        </Box>
        <Box flex={1}>
          <Typography color="textSecondary">
            {t('Price')}
          </Typography>
        </Box>
        <Box flex={1}>
          <Typography color="textSecondary">
            {t('Qty')}
          </Typography>
        </Box>
        <Box flex={1}>
          <Typography color="textSecondary">
            {t('Subtotal')}
          </Typography>
        </Box>
        <Box flex={1}>
          <Typography color="textSecondary">
            {t('CT (10%)')}
          </Typography>
        </Box>
      </Box>
      )}
      {getItemsContent(items)}
    </Box>
  );
};

NodeOwnerFormCart.defaultProps = {
  min: 1,
  max: 999,
  disabled: false,
};

NodeOwnerFormCart.propTypes = {
  min: PropTypes.number,
  max: PropTypes.number,
  price: PropTypes.number.isRequired,
  productName: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
};

NodeOwnerFormCart.whyDidYouRender = true;
export default React.memo(NodeOwnerFormCart);
