import {
  createReducerUpdaterWithScope,
  createReducer,
} from '../store_utils';

import * as types from './types';

const loadingIS = {
  nodesEarningLoading: false,
};

const reducerUpdaters = {
  [types.SET_LOADING]: createReducerUpdaterWithScope('Loading'),
};

const loadingR = createReducer(loadingIS, reducerUpdaters);

export default loadingR;
