import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';
import LaunchOutlinedIcon from '@material-ui/icons/LaunchOutlined';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

// routes

import MyNodeInfoDescriptionItem from '../../MyNodeInfoDescriptionItem';
// others

import { stylesDescription } from '../styles';

const useStyles = makeStyles(stylesDescription);

const MyNodeInfoKeyDot = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const { nodeKey } = props;

  const sessionKeyToDisplay = nodeKey ? `${R.slice(0, 15, nodeKey)}....${R.slice(-5, R.length(nodeKey), nodeKey)}` : '';

  const copyToClipboard = () => {
    const tempInput = document.createElement('input');
    tempInput.value = nodeKey;
    document.body.appendChild(tempInput);
    tempInput.select();
    document.execCommand('copy');
    document.body.removeChild(tempInput);

    toast.error(t('Copied to clipboard'), {
      position: 'top-right',
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  return (
    <Box className={classNames(classes.descriptionContent)}>
      <MyNodeInfoDescriptionItem
        title={t('Session Key')}
        content={(
          <Grid container alignItems="center" spacing={2}>
            <Grid item>
              <Typography variant="body1">{sessionKeyToDisplay}</Typography>
            </Grid>
            <Grid item>
              <IconButton onClick={copyToClipboard}>
                <FileCopyOutlinedIcon />
              </IconButton>
            </Grid>
            <Grid item>
              <IconButton>
                <LaunchOutlinedIcon />
              </IconButton>
            </Grid>
          </Grid>
      )}
      />
    </Box>
  );
};

MyNodeInfoKeyDot.propTypes = {
  nodeKey: PropTypes.string.isRequired,
};

export default MyNodeInfoKeyDot;
