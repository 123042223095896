import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import whyDidYouRender from '@welldone-software/why-did-you-render';
import PropTypes from 'prop-types';
import React from 'react';

import Invoice from '../Invoice/Invoice';
import { CloseIconButton } from '../MaterialUiCustom/MaterialUiCustom';

import { paymentPagePaymentHistoryStyles } from './styles';

if (process.env.REACT_APP_ENV !== 'prod') {
  whyDidYouRender(React);
}

const useStyles = makeStyles(paymentPagePaymentHistoryStyles);

const PaymentPagePaymentHistoryDetail = (props) => {
  const classes = useStyles();

  const { data, onClosePaymentDetail } = props;
  const { invoice } = data;

  // const statusBgColorSet = {
  //   paid: '#cbf4c9',
  //   open: '#d6ecff',
  // };

  // const statusTextColorSet = {
  //   paid: '#0e6245',
  //   open: '#3d4eac',
  // };

  // const statusBgColor = statusBgColorSet[status] || '#e3e8ee';
  // const statusTextColor = statusTextColorSet[status] || '#4f566b';

  // const getSummaryContentItem = (title, content) => (
  //   <Grid container spacing={2}>
  //     <Grid xs={12} sm={6} md={6} lg={6}>
  //       {title}
  //     </Grid>
  //     <Grid xs={12} sm={6} md={6} lg={6}>
  //       {content}
  //     </Grid>
  //   </Grid>
  // );

  return (
  // <Grid container spacing={2} className={classes.root}>
  //   <Grid xs={12}>
  //     <Box display="flex">
  //       <Box>
  //         <ReceiptOutlinedIcon color="disabled" />
  //       </Box>
  //       <Box>
  //         <Typography color="textSecondary" className={classes.titleText}>
  //           {t('Invoice')}
  //         </Typography>
  //       </Box>
  //     </Box>
  //   </Grid>
  //   <Grid xs={12}>
  //     <Box display="flex">
  //       <Box>
  //         <Typography className={classes.invoiceNumber}>
  //           {number}
  //         </Typography>
  //       </Box>
  //       <Box>
  //         <Typography color="textSecondary" className={classes.textFor}>
  //           {t('for')}
  //         </Typography>
  //       </Box>
  //       <Box>
  //         <Typography className={classes.totalTitle}>
  //           {total.toLocaleString('en-US', {
  //             style: 'currency',
  //             currency: currency ? toUpper(currency) : 'JPY',
  //           })}
  //         </Typography>
  //       </Box>
  //       <Box
  //         style={{
  //           borderRadius: 5,
  //           backgroundColor: statusBgColor,
  //         }}
  //         color={statusTextColor}
  //       >
  //         <Typography variant="subtitle2" className={classes.statusText}>{status}</Typography>
  //       </Box>
  //     </Box>
  //   </Grid>

  //   <Grid xs={12}>
  //     <Box display="flex" justifyContent="space-between">
  //       <Box>
  //         <Typography color="textSecondary" className={classes.titleText}>
  //           {t('Summary')}
  //         </Typography>
  //       </Box>
  //       <Box>
  //         <Button variant="outlined">
  //           <Box display="flex">
  //             <Box>
  //               <GetAppOutlinedIcon />
  //             </Box>
  //             <Box>
  //               <Typography className={classes.downloadButtonText}>
  //                 {t('Invoice PDF')}
  //               </Typography>
  //             </Box>
  //           </Box>
  //         </Button>
  //       </Box>
  //     </Box>
  //   </Grid>

  //   <Grid xs={12}>
  //     <Divider />
  //   </Grid>

  //   <Grid xs={12}>
  //     <Grid container spacing={2} className={classes.summaryContent}>
  //       <Grid item xs={12} sm={6} md={6} lg={6}>
  //         {getSummaryContentItem(t('Billed to email'), customerEmail)}
  //         {getSummaryContentItem(t('Currency'), toUpper(currency))}
  //       </Grid>
  //       <Grid item xs={12} sm={6} md={6} lg={6}>
  //         {getSummaryContentItem(t('Invoice number'), number)}
  //         {getSummaryContentItem(t('Memo'), memo)}
  //       </Grid>
  //     </Grid>
  //   </Grid>

  // </Grid>

    <Box className={classes.root}>
      <Box display="flex" justifyContent="flex-end">
        <CloseIconButton
          onClick={onClosePaymentDetail}
          color="default"
        />
      </Box>
      <Invoice
        invoice={invoice}
      />
    </Box>
  );
};

PaymentPagePaymentHistoryDetail.defaultProps = {

};

PaymentPagePaymentHistoryDetail.propTypes = {
  data: PropTypes.shape({
    invoice: PropTypes.object,
  }).isRequired,
  onClosePaymentDetail: PropTypes.func.isRequired,
};

PaymentPagePaymentHistoryDetail.whyDidYouRender = true;

export default PaymentPagePaymentHistoryDetail;
