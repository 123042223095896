import * as R from 'ramda';

import * as types from './types';

export const setLang = R.curry((
  lang,
) => ({
  type: types.SET_LANG,
  payload: lang,
}));
