import { createTheme } from '@material-ui/core/styles';

const theme = createTheme();

const shadowBox = {
  boxShadow: 'rgb(25 19 38 / 10%) 0px 2px 12px -8px, rgb(25 19 38 / 5%) 0px 1px 1px',
};

const titleFontFamily = {
  fontFamily: [
    'Montserrat',
    'Sans-serif',
  ].join(','),
};

const contentFontFamily = {
  fontFamily: [
    'Noto-sans',
    'Sans-serif',
  ].join(','),
};

const borderColor = {
  default: '#D9DFEB',
  dark: '#fff',
};

const border = {
  default: {
    border: `1px solid ${borderColor.default}`,
    borderRadius: 10,
  },
};

const green = {
  main: '#b2f405',
  light: '#e8ff57',
  dark: '#7cc100',
};

const MuiTheme = createTheme({
  typography: {
    fontFamily: [
      'Noto-sans',
      'Sans-serif',
    ].join(','),
    h1: {
      ...theme.typography.h1,
      ...titleFontFamily,
      fontSize: 40,
      fontWeight: 700,
      [theme.breakpoints.down('xs')]: {
        fontSize: 28,
      },
    },
    h2: {
      ...theme.typography.h2,
      ...titleFontFamily,
      fontSize: '1.5rem',
      fontWeight: 600,
    },
    h3: {
      ...theme.typography.h3,
      fontSize: '1rem',
      fontWeight: 600,
      fontFamily: [
        'Noto-sans',
        'Sans-serif',
      ].join(','),
    },
  },
  font: {
    title: {
      ...titleFontFamily,
    },
    content: {
      ...contentFontFamily,
    },
  },
  palette: {
    primary: {
      main: '#ff5e0c',
      light: '#ff9043',
      dark: '#c42800',
      contrastText: '#ffffff',
    },
    secondary: {
      main: '#1a2433',
      light: '#424c5d',
      dark: '#00000c',
      contrastText: '#ffffff',
    },
    green,
    yellow: {
      main: '#e8be00',
      light: '#fff04d',
      dark: '#b18e00',
      contrastText: '#ffffff',
    },
    success: green,
    background: {
      paper: '#fff',
      default: '#fff',
      section: '#e5e5e5',
    },
    text: {
      secondary: '#9BA5B5',
      primary: '#333',
      white: '#FFF',
    },
  },
  customColor: {
    yellow: '#e8be00',
    green: '#7bae87',
    almostBlack: '#333',
  },
  borderColor,
  border,
  nodeStatus: {
    active: '#C7ED63',
  },
  shadow: {
    default: {
      boxShadow: 'rgb(25 19 38 / 10%) 0px 2px 12px -8px, rgb(25 19 38 / 5%) 0px 1px 1px',
    },
    deployFormContent: {
      boxShadow: '1px 3px 12px #949EB129',
    },
  },
  nodeColors: {
    ETH: '#ACBBF5',
    UnUniFi: '#51698D',
    PHA: '#E4FCA7',
    Neunode: '#FBAC84',
    ZEN: '#87D8DE',
    BUSD: '#F9DC83',
    noBalance: '#D9DFEB',
  },
  centerComponent: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  alignCenterFixed: {
    width: 50,
    height: 50,
    position: 'fixed',
  },
  card: {
    card: {
      width: '100%',
    },
    cardHeader: {
      padding: theme.spacing(2),
    },
    cardContent: {
      padding: 0,
      '&:last-child': {
        paddingBottom: 0,
      },
    },
    cardBorder: {
      backgroundColor: '#fff',
      border: '1px solid #D9DFEB',
      borderRadius: 4,
    },
  },
  table: {
    cardContent: {
      padding: 0,
      '&:last-child': {
        paddingBottom: 0,
      },
    },
  },
  mixins: {
    ...theme.mixins,
    toolbar: {
      minHeight: theme.spacing(6),
      [`${theme.breakpoints.up('xs')} and (orientation: landscape)`]: {
        minHeight: theme.spacing(5),
      },
      [theme.breakpoints.up('sm')]: {
        minHeight: theme.spacing(7),
      },
    },
  },
  overrides: {
    MuiStepIcon: {
      text: {
        fill: '#ffffff',
      },
      root: {
        fontSize: 40,
        [theme.breakpoints.down('xs')]: {
          fontSize: 25,
        },
        '&$completed': {
          color: '#1A2433',
        },
        '&$active': {
          color: '#1A2433',
        },
      },
      active: {},
      completed: {},
    },
    MuiPaper: {
      rounded: {
        backgroundColor: '#fff',
        border: '1px solid #D9DFEB',
        borderRadius: '10px',
      },
      elevation1: {
        boxShadow: 0,
      },
      elevation2: {
        ...shadowBox,
      },
      elevation3: {
        ...shadowBox,
      },
      elevation4: {
        ...shadowBox,
      },
      elevation5: {
        ...shadowBox,
      },
      elevation6: {
        ...shadowBox,
      },
      elevation7: {
        ...shadowBox,
      },
      elevation8: {
        ...shadowBox,
      },
      elevation9: {
        ...shadowBox,
      },
    },
    MuiButton: {
      root: {
        transition: 'color .01s',
      },
      contained: {
        ...shadowBox,
      },
    },
    MuiCard: {
      // border: `1px solid ${grey[200]}`,
      backgroundColor: '#fff',
      ...border.default,
    },
    MuiDivider: {
      root: {
        backgroundColor: borderColor.default,
      },
    },
    MuiOutlinedInput: {
      root: {
        borderRadius: '10px',
        '& fieldset': {
          borderColor: borderColor.default,
        },
      },
    },
    MuiTableCell: {
      root: {
        borderBottom: 'none',
      },
      head: {
        paddingBottom: theme.spacing(0.5),
      },
    },
    MuiFormHelperText: {
      root: {
        marginTop: 0,
      },
      contained: {
        marginLeft: theme.spacing(1),
      },
    },
    MuiCssBaseline: {
      '@global': {
        '*::-webkit-scrollbar': {
          width: 8,
          height: 8,
        },
        '*::-webkit-scrollbar-track': {
          borderRadius: 10,
          '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.1)',
        },
        '*::-webkit-scrollbar-thumb': {
          borderRadius: 10,
          backgroundColor: 'rgba(0,0,0,0.1)',
        },
        '*::-webkit-scrollbar-thumb:hover': {
          borderRadius: 10,
          backgroundColor: 'rgba(0,0,0,0.2)',
        },
      },
    },
  },
});
export default MuiTheme;
