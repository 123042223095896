import {
  pipeAwait,
} from 'jsutils';
import * as R from 'ramda';

import {
  graphqlNormalize,
} from '../../modules/apollo/apollo';
import {
  keyToAuthTokenDataInLocalStorage,
  keyToUserDataInLocalStorage,
  keyToAuthStatusInLocalStorage,
} from '../../modules/appConstants';
import { setItem, removeItem } from '../../modules/browser_utils';
import { adminUtils } from '../admin/index';
import {
  returnAction,
} from '../store_utils';

import {
  graphqlSignin,
  graphqlSignup,
  graphqlSignOut,
  graphqlActivateUser,
  graphqlValidateEmail,
  graphqlValidatePassword,
  graphqlChangeUserEmail,
  graphqlChangeUserPassword,
  graphqlSendForgotPasswordEmail,
  graphqlResetPassword,
  graphqlValidateForgotPassword,
  graphqlResendActivation,
  graphqlGetActivation,
  graphqlCreateFirstSignin,
  graphqlGetFirstSignin,
  graphqlSetUserFirstSignedIn,
  graphqlGetUserById,
  graphqlGetFirstSignins,
} from './graphql/services';
import * as services from './services';
import * as types from './types';

/**
 * Creates a signin.
 *
 * @param      {string}  email     The email
 * @param      {string}  password  The password
 * @return     {import('./typedefs').JsonApi}
 */
export const signin = R.curry(
  async (email, password, role) => {
    const trimedAndLowerCaseEmail = services.formatEmail(email);
    return pipeAwait(
      graphqlSignin,
      graphqlNormalize('signin'),
      returnAction(types.SIGNIN_SUCCESS),
    )({ email: trimedAndLowerCaseEmail, password, role });
  },
);

/**
 * Creates a signup.
 *
 * @param      {import('./typedefs').SignupBody}  body
 * @return     {import('./typedefs').JsonApi}
 */
export const signup = R.curry(
  async (signupData) => {
    const { email } = signupData;
    const formatedEmail = services.formatEmail(email);
    const formatedEmailData = {
      ...signupData,
      email: formatedEmail || email,
    };
    return pipeAwait(
      graphqlSignup,
      graphqlNormalize('signup'),
      returnAction(types.SIGNUP_SUCCESS),
    )(formatedEmailData);
  },
);

/**
 * Sign out action.
 *
 * @param      {string}  userId
 * @return     {import('../typedefs').ReduxAction}
 */
export const signOut = (userId, idToken) => {
  // If 'userId' and 'idToken' does not exist just sign out user in FE.
  // Because 'userId' and 'idToken' are required param in 'signOutMutation'.
  // So if either 'userId' and 'idToken' does not exist it will cause
  // GRAPHQL_VALIDATION_FAILED error.
  if (!userId || !idToken) {
    return returnAction(
      types.SIGN_OUT,
      {
        isAuthenticated: false,
      },
    );
  }
  setItem(keyToAuthStatusInLocalStorage, false);
  return pipeAwait(
    graphqlSignOut,
    graphqlNormalize('signOut'),
    R.omit(['__typename']),
    returnAction(types.SIGN_OUT),
  )({ userId, idToken });
};

/**
 * Activate user
 *
 * @param      {uuid}  activationId
 * @return     {import('./typedefs').JsonApi}
 */
export const activateUser = R.curry(
  async (activationId) => pipeAwait(
    graphqlActivateUser,
    graphqlNormalize('activateUser'),
    returnAction(types.ACTIVATE_SUCCESS),
  )({ activationId }),
);

/**
 * Validate email
 *
 * @param      {string}  email
 * @return     {import('./typedefs').JsonApi}
 */
export const validateEmail = R.curry(
  async (email) => pipeAwait(
    graphqlValidateEmail,
    graphqlNormalize('validateEmail'),
    returnAction(types.VALIDATE_EMAIL_SUCCESS),
  )({ email }),
);

/**
 * Validate password
 *
 * @param      {object}  body
 * @return     {import('./typedefs').JsonApi}
 */
export const validatePassword = R.curry(
  async (body) => pipeAwait(
    graphqlValidatePassword,
    graphqlNormalize('validatePassword'),
    returnAction(types.VERIFY_PASSWORD_SUCCESS),
  )(body),
);

/**
 * Change user email
 *
 * @param      {string}  userId
 * @param      {string}  newEmail
 * @return     {import('./typedefs').JsonApi}
 */
export const changeUserEmail = R.curry(
  async (userId, newEmail) => pipeAwait(
    graphqlChangeUserEmail,
    graphqlNormalize('changeUserEmail'),
    returnAction(types.UPDATE_EMAIL_SUCCESS),
  )({ userId, newEmail }),
);

/**
 * Change user password
 *
 * @param      {string}  userId
 * @param      {string}  oldPassword
 * @param      {string}  newPassword
 * @return     {import('./typedefs').JsonApi}
 */
export const changeUserPassword = R.curry(
  async (userId, oldPassword, newPassword) => pipeAwait(
    graphqlChangeUserPassword,
    graphqlNormalize('changeUserPassword'),
    returnAction(types.UPDATE_PASSWORD_SUCCESS),
  )({ userId, oldPassword, newPassword }),
);

/**
 * Send forgot password email
 *
 * @param      {string}  email
 * @return     {import('./typedefs').JsonApi}
 */
export const sendForgotPasswordEmail = R.curry(
  async (email, lang) => pipeAwait(
    graphqlSendForgotPasswordEmail,
    graphqlNormalize('sendForgotPasswordEmail'),
    returnAction(types.SEND_FORGOT_PASSWORD_SUCCESS),
  )({ email, lang }),
);

/**
 * Reset user password
 *
 * @param      {string}  newPassword
 * @param      {string}  forgotPasswordId
 * @return     {import('./typedefs').JsonApi}
 */
export const resetPassword = R.curry(
  async (newPassword, forgotPasswordId) => pipeAwait(
    graphqlResetPassword,
    graphqlNormalize('resetPassword'),
    returnAction(types.RESET_PASSWORD_SUCCESS),
  )({ newPassword, forgotPasswordId }),
);

/**
 * Validate forgot password request
 *
 * @param      {string}  forgotPasswordId
 * @return     {import('./typedefs').JsonApi}
 */
export const validateForgotPassword = R.curry(
  async (forgotPasswordId) => pipeAwait(
    graphqlValidateForgotPassword,
    graphqlNormalize('validateForgotPassword'),
    returnAction(types.VALIDATE_FORGOT_PASSWORD_REQUEST_SUCCESS),
  )({ forgotPasswordId }),
);

/**
 * Resend activation email
 *
 * @param      {string}  userId
 * @return     {import('./typedefs').JsonApi}
 */
export const resendActivation = R.curry(
  async (userId, lang) => pipeAwait(
    graphqlResendActivation,
    graphqlNormalize('resendActivation'),
    returnAction(types.RESEND_ACTIVATION),
  )({ userId, lang }),
);

/**
 * Get activation
 *
 * @param      {string}  activationId
 * @return     {import('./typedefs').JsonApi}
 */
export const getActivation = R.curry(
  async (activationId) => pipeAwait(
    graphqlGetActivation,
    graphqlNormalize('getActivation'),
    returnAction(types.GET_ACTIVATION),
  )({ activationId }),
);

/**

  return coreUserData;

 * Returns action to set auth token status as updated
 * @return     {import('../typedefs').ReduxAction}
 */
export const setAuthTokenStatusUpdated = () => ({
  type: types.SET_AUTH_TOKENS_STATUS_UPDATED,
  payload: false,
});

/**
 * Load user action.
 *
 */
export const loadUser = (userData) => R.pipe(
  returnAction(types.LOAD_USER),
)(userData);

export const resetLoadUser = () => ({ type: types.RESET_LOAD_USER });

/**
 * Load admin action.
 *
 */
export const loadAdmin = (payload) => (
  R.pipe(
    returnAction(types.LOAD_ADMIN),
  )(payload)
);

/**
 * Authentication acton.
 *
 * @return     {import('../typedefs').ReduxAction}
 */
export const authenticateUser = () => {
  setItem(keyToAuthStatusInLocalStorage, true);
  return {
    type: types.AUTHENTICATE_USER,
    payload: true,
  };
};

/**
 * Sign out user in FE only
 *
 * @return     {import('../typedefs').ReduxAction}
 */
export const signOutSync = () => {
  removeItem(keyToAuthTokenDataInLocalStorage);
  removeItem(keyToUserDataInLocalStorage);
  setItem(keyToAuthStatusInLocalStorage, false);
  adminUtils.removeImpersonationDataFromSessionStorage();
  return {
    type: types.SIGN_OUT,
    payload: {
      isAuthenticated: false,
    },
  };
};

export const createFirstSignin = R.curry(
  async (userId) => pipeAwait(
    graphqlCreateFirstSignin,
    graphqlNormalize('createFirstSignin'),
    returnAction(types.CREATE_FIRST_SIGNIN_SUCCESS),
  )({
    createFirstSigninInput: {
      userId,
    },
  }),
);

export const getFirstSignin = R.curry(
  async (firstSigninId) => pipeAwait(
    graphqlGetFirstSignin,
    graphqlNormalize('getFirstSignin'),
    returnAction(types.GET_FIRST_SIGNIN_SUCCESS),
  )({
    getFirstSigninInput: {
      firstSigninId,
    },
  }),
);

export const getFirstSignins = R.curry(
  async (query) => pipeAwait(
    graphqlGetFirstSignins,
    graphqlNormalize('getFirstSignins'),
    services.formatGetFirstSigninResponse,
    returnAction(types.GET_FIRST_SIGNINS_SUCCESS),
  )({
    getFirstSigninsInput: {
      query,
    },
  }),
);

export const setUserFirstSignedIn = R.curry(
  async (firstSigninId) => pipeAwait(
    graphqlSetUserFirstSignedIn,
    graphqlNormalize('setUserFirstSignedIn'),
    returnAction(types.SET_USER_FIRST_SIGNED_IN_SUCCESS),
  )({
    setUserFirstSignedInInput: {
      firstSigninId,
    },
  }),
);

export const validateUser = R.curry(
  async (userId) => pipeAwait(
    graphqlGetUserById,
    graphqlNormalize('getUserById'),
    returnAction(types.VALIDATE_USER_SUCCESS),
  )({ userId }),
);
