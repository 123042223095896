import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import toNumber from 'lodash/toNumber';
import React from 'react';

import LocalDate from '../../LocalDate/LocalDate';
import { convertToCryptoNumber } from '../../utils/utils';

export const busdEarningHistoryColumns = (
  t,
) => [
  {
    Header: t('Date'),
    id: 'timestamp',
    Cell: (data) => {
      const { timestamp } = data.row.original;
      return (
        <Grid container direction="row" alignItems="center">
          <LocalDate
            date={timestamp}
          />
        </Grid>
      );
    },
  },
  {
    Header: t('Earning'),
    accessor: 'earning',
    Cell: (data) => {
      const { earning } = data.row.original;
      const prefix = earning >= 0 ? '+' : '';
      return (
        <Grid container direction="row" alignItems="center">
          <Typography style={{ color: '#F0B90B' }}>
            {`${prefix}${convertToCryptoNumber(toNumber(earning))} BUSD`}
          </Typography>
        </Grid>
      );
    },
  },
  {
    Header: t('Fiat Equivalent'),
    accessor: 'earningFiat',
    Cell: (data) => {
      const { earningFiat } = data.row.original;
      return (
        <Grid container direction="row" alignItems="center">
          {toNumber(earningFiat).toLocaleString('en-US', {
            style: 'currency',
            currency: 'JPY',
          })}
        </Grid>
      );
    },
  },
  {
    Header: t('Exchange Rate'),
    accessor: 'currencyPrice',
    Cell: (data) => {
      const { currencyPrice } = data.row.original;
      return (
        <Grid container direction="row" alignItems="center">
          {`(1BUSD = ${toNumber(currencyPrice).toLocaleString('en-US', {
            style: 'currency',
            currency: 'JPY',
          })})`}
        </Grid>
      );
    },
  },
];
