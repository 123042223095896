import {
  weiToEther,
} from 'jsutils';
import isEmpty from 'lodash/isEmpty';
import lowerCase from 'lodash/lowerCase';
import toNumber from 'lodash/toNumber';
import * as R from 'ramda';

export const selectAddressBalance = R.curry(
  (symbol, assets) => (isEmpty(assets)
    ? 0
    : R.pipe(
      R.find((item) => lowerCase(item.symbol) === lowerCase(symbol)),
      R.prop('balance'),
      toNumber,
      weiToEther,
    )(assets)),
);
