import { makeStyles } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { walletTransferBoardFeeStyles } from './styles';

import useAddFunctionToRef from 'hooks/useAddFunctionToRef';
import { web3Operations } from 'store/web3';

const useStyles = makeStyles(walletTransferBoardFeeStyles);

const WalletTransferFee = ({
  chain,
  api,
  recipient,
  sender,
  amount,
  transactionFee,
  invalid,
  t,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const onGetTransactionInfo = useAddFunctionToRef(
    async (
      chain,
      {
        api,
        recipient,
        sender,
        amount,
      },
    ) => {
      dispatch(await web3Operations.getTransactionInfo(chain, {
        api,
        recipient,
        sender,
        amount,
      }));
    },
  );
  useEffect(() => {
    if (recipient && amount > 0 && sender && !invalid) {
      onGetTransactionInfo(
        chain,
        {
          api,
          recipient,
          sender,
          amount,
        },
      );
    }
  }, [amount, api, chain, invalid, onGetTransactionInfo, recipient, sender]);
  return (
    <Paper
      className={classes.feeOuterBox}
    >
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <Typography>
          {t('Transaction fee')}
        </Typography>
      </Box>
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <Box>
          <Typography>
            {transactionFee}
          </Typography>
        </Box>
        <Box ml={1}>
          <Typography>
            PHA
          </Typography>
        </Box>
      </Box>
    </Paper>
  );
};

WalletTransferFee.propTypes = {
  chain: PropTypes.string.isRequired,
  api: PropTypes.object,
  recipient: PropTypes.string,
  sender: PropTypes.string.isRequired,
  amount: PropTypes.number,
  transactionFee: PropTypes.number,
  invalid: PropTypes.bool.isRequired,
};
WalletTransferFee.defaultProps = {
  recipient: undefined,
  transactionFee: 0,
  amount: 0,
  api: undefined,
};

export default WalletTransferFee;
