/* eslint-disable jsx-a11y/anchor-is-valid */
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import { useSelector, useDispatch } from 'react-redux';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import ArrowBackIosOutlinedIcon from '@material-ui/icons/ArrowBackIosOutlined';
import whyDidYouRender from '@welldone-software/why-did-you-render';
import { isSuccessAction } from 'jsutils';
import camelCase from 'lodash/camelCase';
import isNil from 'lodash/isNil';
import toNumber from 'lodash/toNumber';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import React, {
  useState, useEffect, useMemo, useCallback,
} from 'react';
import {
  useHistory,
  useParams,
} from 'react-router-dom';

import MarketProtocolDeployContentItem from '../MarketProtocolDeployContentItem';
import ProtocolsConfig from './ProtocolsConfig/ProtocolsConfig';
import { CloseIconButton, RoundedButton } from '../../MaterialUiCustom/MaterialUiCustom';
import { useAddFunctionToRef } from '../../../hooks/hooks';
import { dashboardRoute } from '../../../routes';
import { deployConfigOperations, deploySelectors } from '../../../store/deploy_config/index';

// others
import { getChainsData } from '../../Market/constants';
import { depositConstants, depositOperations, depositServices } from '../../../store/deposit/index';
import { paymentOperations } from '../../../store/payment/index';
import useConnectToWallet from '../../ConnectToWallet/useConnectToWallet';
import PasswordTextField from '../../MaterialUiCustom/TextField/PasswordTextField';
import MarketProtocolDeployPayment from '../MarketProtocolDeployPayment';
import { makeDefaultNodeNameToDisplay } from '../utils';
import Eth2DeployComplete from './Eth2DeployComplete';
import Eth2DeployConnectToWallet from './Eth2DeployConnectToWallet';
import Eth2DeployDeposit from './Eth2DeployDeposit';
import {
  eth2DeployContentStyles,
} from './styles';

import Alert from 'components/MaterialUiCustom/Alert/Alert';
import BackToHomeButton from 'components/MaterialUiCustom/Button/BackToHomeButton';
import { errorOperations } from 'store/error';

const { REACT_APP_STRIPE_60DAYS_PROMOTION_CODE } = process.env;
const { REACT_APP_ENV, REACT_APP_PRICE_PER_VALIDATOR } = process.env;

if (REACT_APP_ENV !== 'prod') {
  whyDidYouRender(React);
}

const useStyles = makeStyles(eth2DeployContentStyles);
const { TransactionStatus } = depositConstants;

const Eth2DeployContent = (props) => {
  const {
    activeStep,
    handleStepperNext,
    steps,
    t,
  } = props;
  const quantity = useSelector((state) => state.deployConfigR.deployConfig.quantity);
  const requiredBalance = quantity * toNumber(REACT_APP_PRICE_PER_VALIDATOR);
  const {
    context,
    walletConnected,
    activatingConnector,
    setActivatingConnector,
    wrongNetwork,
    balance,
    enoughBalance,
    onDisconnectConnector,
    showPopupWrongNetworkError,
  } = useConnectToWallet({
    requiredBalance,
  });

  const {
    active,
    error,
    account,
    connector,
    chainId,
  } = context;

  const createWithNetworkError = R.curry(
    (showPopupWrongNetworkError, wrongNetwork, actionFn) => {
      if (wrongNetwork) {
        return showPopupWrongNetworkError();
      }
      return actionFn();
    },
  );

  const withNetworkError = createWithNetworkError(showPopupWrongNetworkError, wrongNetwork);

  // HARDCODE
  const nodeType = 'ETH2_VALIDATOR_NODE';
  const dispatch = useDispatch();
  const history = useHistory();
  const classes = useStyles();
  const [tryAgain, setTryAgain] = useState(false);
  const [haveKeystoreFilePassword, setHaveKeystoreFilePassword] = useState(false);
  const deployDone = useSelector((state) => deploySelectors.selectDeployDone(state));
  const broadcastDepositTransactionsError = useSelector(
    (state) => state.errorR.broadcastDepositTransactionsError,
  );
  const deployError = useSelector(
    (state) => state.errorR.deployError,
  );
  const [
    keystoreFilePasswordErrorMessage,
    setKeystoreFilePasswordErrorMessage,
  ] = useState('');
  const broadcastDepositTransactionsLoading = useSelector(
    (state) => state.loadingR.broadcastDepositTransactionsLoading,
  );
  const createDepositLoading = useSelector(
    (state) => state.loadingR.createDepositLoading,
  );
  const deployLoading = useSelector(
    (state) => state.loadingR.deployLoading,
  );
  const { paymentSelected, rulesAgreed } = useSelector((state) => state.deployConfigR.metadata);
  const { userId } = useSelector((state) => state.userR.user);
  const paymentDetail = useSelector((state) => state.paymentR.paymentDetail);
  const {
    promotionCode,
    trialDays,
  } = paymentDetail;
  const { network, keystoreFilePassword, credentials } = useSelector(
    (state) => R.view(
      R.lensPath(['deployConfigR', 'deployConfig', camelCase(nodeType)]),
      state,
    ),
  );
  const { nodes } = useSelector(
    (state) => R.view(R.lensPath(['deployConfigR', 'deployConfig']), state),
  );
  const { depositKeys } = useSelector((state) => state.depositR.depositData);
  const { haveFiles } = useSelector((state) => state.deployConfigR.metadata[nodeType]);

  const { protocolName } = useParams();
  const protocolData = getChainsData(t)[protocolName];
  const {
    nodeTypes,
  } = protocolData;

  const {
    trialDays: presetTrialDays,
    pricing,
  } = R.find(
    R.propEq('type', nodeType),
    nodeTypes,
  );

  const handleTrialDays = useAddFunctionToRef((trialDays, promotionCode) => {
    const codesExist = R.and(
      !isNil(promotionCode),
      !isNil(REACT_APP_STRIPE_60DAYS_PROMOTION_CODE),
    );
    const is60DaysCode = codesExist && promotionCode === REACT_APP_STRIPE_60DAYS_PROMOTION_CODE;
    dispatch(paymentOperations.updateStripeTrialDays(is60DaysCode ? 60 : trialDays));
  });

  useEffect(() => {
    handleTrialDays(presetTrialDays, promotionCode);
  }, [handleTrialDays, presetTrialDays, promotionCode]);

  const ConfigToRender = R.view(
    R.lensPath([nodeType]),
    ProtocolsConfig,
  );

  const handleOnCompletedAndClose = () => {
    history.push({
      pathname: dashboardRoute,
    });
  };

  const onUpdateKeystoreFilePassword = (keystoreFilePassword) => {
    dispatch(
      deployConfigOperations.updateDeployConfig(
        [camelCase(nodeType), 'keystoreFilePassword'],
        keystoreFilePassword,
      ),
    );
  };

  const onKeystoreFilePasswordInputChange = (password) => {
    if (password.length < 8) {
      setKeystoreFilePasswordErrorMessage(t('Password must be at least 8 character'));
    } else {
      setKeystoreFilePasswordErrorMessage('');
    }
    onUpdateKeystoreFilePassword(password);
  };

  const makeDefaultNodeNameToDisplayRef = useAddFunctionToRef(makeDefaultNodeNameToDisplay);
  const onUpdateDeployConfigNodes = useAddFunctionToRef(
    (nodeType, quantity) => {
      if (nodeType && quantity && quantity > 0) {
        dispatch(deployConfigOperations.updateDeployConfigNodes(
          makeDefaultNodeNameToDisplayRef(nodeType),
          nodeType,
          quantity,
        ));
      }
    },
  );
  useEffect(() => {
    onUpdateDeployConfigNodes(nodeType, quantity);
  }, [
    quantity,
    nodeType,
    onUpdateDeployConfigNodes,
  ]);

  useEffect(() => {
    // Since footer handling rendering within this component
    // this is a quickfix to make tryAgain false when go back to step 0
    // without reloading the page
    if (activeStep !== 2 && tryAgain) {
      setTryAgain(false);
    }
  }, [activeStep, tryAgain]);

  useEffect(() => () => dispatch(errorOperations.clearError('deploy')), [dispatch]);

  const onAddSubNodesToNodes = useAddFunctionToRef(R.curry((
    nodeType,
    nodes,
    subNodeData,
  ) => {
    dispatch(deployConfigOperations.addSubNodesToNodes(
      nodeType,
      nodes,
      subNodeData,
    ));
  }));

  const onDeposit = useCallback(() => {
    dispatch(depositOperations.broadcastDepositTransactions(
      connector,
      account,
    ));
  }, [
    connector,
    account,
    dispatch,
  ]);

  const onDeployNode = R.curry(
    async (
      userId,
      quantity,
      paymentDetail,
      nodes,
    ) => {
      const paymentDetailToUse = R.merge(paymentDetail, {
        paymentType: 'SUBSCRIPTION',
        dateCreated: new Date(),
        dateUpdated: new Date(),
      });

      const res = await dispatch(deployConfigOperations.deploy(
        userId,
        quantity,
        paymentDetailToUse,
        {
          nodes,
        },
        'NAAS',
      ));
      return res;
    },
  );

  const onCreateDeposit = useAddFunctionToRef(R.curry((
    userId,
    depositKeys,
    deployData, // passing deploy data here make sure that we have
  ) => {
    // temporary solution todo merge this with the steps above
    // what we want to do is able to store what step user at while deploying node
    const steps = {
      activeStep: 3,
      stepsData: [{ step: 0 }, { step: 1 }, { step: 2 }, { step: 3 }],
    };
    const { nodes: savedNodes } = deployData;
    const depositData = depositServices.mergeDepositKeysAndSavedNodes(depositKeys, savedNodes);
    dispatch(depositOperations.createDeposit(
      userId,
      steps,
      depositData,
    ));
  }));
  // This function is used to reset the credential
  // const onResetDeployNodeConfigOneNode = useAddFunctionToRef((nodeType) => {
  //   dispatch(deployConfigOperations.resetDeployConfigOneNodeType(nodeType));
  // });
  const createGetStepTitle = R.curry((steps, activeStep) => steps[activeStep].title);
  const getStepTitle = createGetStepTitle(steps);
  const memoPricing = useMemo(() => pricing, [pricing]);

  const getStepContent = (step) => {
    switch (step) {
      case 0: {
        return (
          <Grid container>
            <Grid item xs={12}>
              <MarketProtocolDeployContentItem
                title={getStepTitle(activeStep)}
                headerWrap
                content={(
                  <Eth2DeployConnectToWallet
                    t={t}
                    pricing={memoPricing}
                    nodeType={nodeType}
                    paymentSelected={paymentSelected}
                    quantity={quantity}
                    trialDays={trialDays}
                    requiredBalance={requiredBalance}
                    context={context}
                    walletConnected={walletConnected}
                    activatingConnector={activatingConnector}
                    setActivatingConnector={setActivatingConnector}
                    wrongNetwork={wrongNetwork}
                    showPopupWrongNetworkError={showPopupWrongNetworkError}
                    balance={balance}
                    onDisconnectConnector={onDisconnectConnector}
                  />
            )}
              />
            </Grid>
            {walletConnected && (
            <>
              <Grid item xs={12}>
                <Box mb={4}>
                  <Divider />
                </Box>
              </Grid>
              <Grid item xs={12}>
                <MarketProtocolDeployContentItem
                  title={t('Choose a plan')}
                  content={(
                    <MarketProtocolDeployPayment
                      pricing={pricing}
                      nodeType={nodeType}
                      paymentSelected={paymentSelected}
                      quantity={quantity}
                      trialDays={trialDays}
                    />
            )}
                />
              </Grid>
            </>
            )}
          </Grid>
        );
      }
      case 1:
        return (
          <MarketProtocolDeployContentItem
            title={getStepTitle(activeStep)}
            headerWrap
            content={(
              <Box className={classes.generateKeyPairs}>
                <Box className={classes.generateKeyPairsContent}>
                  {haveKeystoreFilePassword && (
                    <Grid container justifyContent="center">
                      <Grid item xs={12}>
                        <Button
                          onClick={() => {
                            dispatch(deployConfigOperations.toggleDeployConfigMetadata({
                              updatePath: [nodeType, 'haveFiles'],
                              metadata: false,
                            }));
                            onUpdateKeystoreFilePassword('');
                            setHaveKeystoreFilePassword(false);
                            // onResetDeployNodeConfigOneNode(nodeType);
                          }}
                          startIcon={<ArrowBackIosOutlinedIcon style={{ fontSize: 12 }} color="disabled" />}
                        >
                          <Typography variant="body2">
                            {t('Change password')}
                          </Typography>
                        </Button>
                      </Grid>
                      <Grid item xs={12}>
                        <ConfigToRender
                          nodeType={nodeType}
                          keystoreFilePassword={keystoreFilePassword}
                          setHaveKeystoreFilePassword={setHaveKeystoreFilePassword}
                          quantity={quantity}
                          network={network}
                          onSuccessCallback={handleStepperNext}
                          showPopupWrongNetworkError={showPopupWrongNetworkError}
                          wrongNetwork={wrongNetwork}
                          withNetworkError={withNetworkError}
                        />
                      </Grid>
                    </Grid>
                  )}
                  {!haveKeystoreFilePassword && (
                    <Grid container justifyContent="flex-end" spacing={2} className={classes.encryptionPassword}>
                      <Grid item xs={12}>
                        <Typography variant="h6">
                          {t('Enter keystore file password')}
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Box>
                          <Box>
                            <Typography variant="body2">
                              {t("Keystore password protects your ETH validator wallet address's fund by encrypting its private key into a JSON file")}
                            </Typography>
                          </Box>
                          <Box mt={1}>
                            <PasswordTextField
                              variant="outlined"
                              type="password"
                              label={t('Encryption password')}
                              className={classes.encryptionPasswordField}
                              value={keystoreFilePassword}
                              onChange={(event) => {
                                onKeystoreFilePasswordInputChange(event.target.value);
                              }}
                            />
                          </Box>
                          <Box mt={1}>
                            {keystoreFilePasswordErrorMessage && (
                              <Typography id="error" color="error">
                                {keystoreFilePasswordErrorMessage}
                              </Typography>
                            )}
                          </Box>
                        </Box>

                      </Grid>
                      <Grid item>
                        <RoundedButton
                          variant="contained"
                          color="primary"
                          disabled={!keystoreFilePassword || keystoreFilePasswordErrorMessage !== ''}
                          onClick={() => {
                            if (wrongNetwork) return showPopupWrongNetworkError();
                            setHaveKeystoreFilePassword(true);
                          }}
                        >
                          {t('Next')}
                        </RoundedButton>
                      </Grid>
                    </Grid>
                  )}
                </Box>
              </Box>
            )}
          />
        );
      case 2: {
        const getAlert = (deployError) => (deployError
          ? <Alert severity="error">{t('Deploy failed. Please keep your JSON files and contact us.')}</Alert>
          : <Alert severity="warning">{t('Keep this page open until your transactions are done.')}</Alert>);
        return (
          <Grid container justifyContent="center" className={classes.depositContentWrapper}>
            <Grid item xs={12}>
              <MarketProtocolDeployContentItem
                title={getStepTitle(activeStep)}
                headerWrap
                content={(
                  <Box className={classes.deposit}>
                    <Box className={classes.depositContent}>
                      <Eth2DeployDeposit
                        quantity={quantity}
                        balance={balance}
                        nodeType={nodeType}
                        requiredBalance={requiredBalance}
                        account={account}
                        chainId={chainId}
                      />
                    </Box>
                  </Box>
            )}
              />
            </Grid>
            <Grid item xs={12} className={classes.alertGrid}>
              {getAlert(deployError)}
            </Grid>
          </Grid>
        );
      }
      case 3:
        return (
          <MarketProtocolDeployContentItem
            title={getStepTitle(activeStep)}
            headerAction={(
              <CloseIconButton
                color="default"
                onClick={handleOnCompletedAndClose}
              />
            )}
            content={(
              <Box className={classes.completeSetup}>
                <Box className={classes.completeSetupContent}>
                  <Eth2DeployComplete
                    depositKeys={depositKeys}
                    nodeType={nodeType}
                    network={network}
                  />
                </Box>
              </Box>
            )}
          />
        );
      default:
        break;
    }
  };

  const getFooterContent = (step) => {
    switch (step) {
      case 0:
        return walletConnected ? (
          <RoundedButton
            variant="contained"
            color="primary"
            onClick={() => withNetworkError(handleStepperNext)}
            disabled={
              !enoughBalance
              || !paymentSelected
              || !walletConnected
              || !rulesAgreed
              || !balance
              || error
            }
            loading={false} // we dont need loading here
            className={classes.footerButton}
          >
            {t('Next step')}
          </RoundedButton>
        ) : null;
      case 1: {
        const handleOnClick = () => {
          onAddSubNodesToNodes(
            nodeType,
            nodes,
            {
              credentials,
              keystoreFilePassword,
            },
          );
          handleStepperNext();
        };
        return (
          <RoundedButton
            variant="contained"
            color="primary"
            onClick={() => withNetworkError(handleOnClick)}
            disabled={!haveFiles || !active}
            loading={false}
            className={classes.footerButton}
          >
            {t('Next step')}
          </RoundedButton>
        ); }
      case 2:
        return (
          <RoundedButton
            variant="contained"
            color="primary"
            onClick={() => withNetworkError(onDeposit)}
            loading={broadcastDepositTransactionsLoading || createDepositLoading || deployLoading}
            className={classes.footerButton}
            disabled={
                  !enoughBalance
                  || !active
                  || Boolean(deployError)
                }
          >
            {tryAgain ? t('try again') : t('approve')}
          </RoundedButton>
        );
      case 3:
        return <BackToHomeButton variant="contained" color="secondary" />;
      default:
        return null;
    }
  };

  const handleAfterDeposit = useAddFunctionToRef(R.curry(
    async (
      activeStep,
      depositKeys,
      userId,
      quantity,
      paymentDetail,
      nodes,
      deployDone,
    ) => {
      const depositSucceeded = depositKeys.length
        ? R.all(R.propEq('transactionStatus', TransactionStatus.SUCCEEDED), depositKeys)
        : false;
      if (depositSucceeded && !deployDone && activeStep === 2) {
        const { type, payload: { data } } = await onDeployNode(
          userId,
          quantity,
          paymentDetail,
          nodes,
        );
        if (type && isSuccessAction(type)) {
          await onCreateDeposit(userId, depositKeys, data);
          handleStepperNext();
        }
      }
    },
  ));

  useEffect(() => {
    handleAfterDeposit(
      activeStep,
      depositKeys,
      userId,
      quantity,
      paymentDetail,
      nodes,
      deployDone,
    );
  }, [activeStep, deployDone, depositKeys, handleAfterDeposit, nodes, paymentDetail, quantity, userId]);

  useEffect(() => {
    const someRejectedOrFailed = R.any(
      (eachDepositKey) => R.propEq('transactionStatus', TransactionStatus.REJECTED, eachDepositKey)
        || R.propEq('transactionStatus', TransactionStatus.FAILED, eachDepositKey),
      depositKeys,
    );
    const tryAgain = someRejectedOrFailed || broadcastDepositTransactionsError;
    setTryAgain(tryAgain);
  }, [broadcastDepositTransactionsError, depositKeys]);

  return (
    <Box className={classes.eth2DeployContentRoot}>
      <Box className={classes.eth2DeployContent}>
        <Grid container justifyContent="center" spacing={2}>
          <Grid item xs={12} className={classes.contentGridItem}>
            {getStepContent(activeStep)}
          </Grid>
          <Grid item xs={12} className={classes.contentGridItem}>
            <Box mt={2}>
              {getFooterContent(activeStep)}
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

Eth2DeployContent.propTypes = {
  activeStep: PropTypes.number.isRequired,
  handleStepperNext: PropTypes.func.isRequired,
  steps: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
    }),
  ).isRequired,
};

Eth2DeployContent.whyDidYouRender = true;

export default React.memo(Eth2DeployContent);
