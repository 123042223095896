import Box from '@material-ui/core/Box';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Divider from '@material-ui/core/Divider';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import * as momentTimeZone from 'moment-timezone';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
// others
import Moment from 'react-moment';

import { styles } from './styles';

const useStyles = makeStyles(styles);

const LocalDate = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { date, format, detailsFormat } = props;

  const [openMenuIndex, setOpenMenuIndex] = useState(false);

  const handleMenuClose = () => {
    setOpenMenuIndex(false);
  };
  const handleMenuOpen = () => {
    setOpenMenuIndex(true);
  };

  const getTimeItem = (title, content) => (
    <Box display="flex">
      <Box flex={0.4} mr={2}>
        <Typography className={classes.tooltipItemTitle}>
          {title}
        </Typography>
      </Box>
      <Box flex={0.6}>
        <Typography className={classes.tooltipItemContent}>
          {content}
        </Typography>
      </Box>
    </Box>
  );

  return (
    <Tooltip
      placement="right-start"
      interactive
      open={openMenuIndex}
      disableFocusListener
      disableHoverListener
      disableTouchListener
      classes={{
        tooltip: classes.customTooltip,
        arrow: classes.customArrow,
      }}
      title={(
        <ClickAwayListener onClickAway={handleMenuClose}>
          <Box>
            <Typography variant="body1" className={classes.tooltipTitle} color="textSecondary">
              {t('Time conversion')}
            </Typography>
            <Divider />
            {getTimeItem('UTC',
              <Moment utc format={detailsFormat}>
                {date}
              </Moment>)}
            <Divider />
            {getTimeItem(momentTimeZone.tz.guess(true),
              <Moment format={detailsFormat}>
                {date}
              </Moment>)}
          </Box>
        </ClickAwayListener>
          )}
    >
      <Moment format={format} className={classes.date} onClick={handleMenuOpen}>
        {date}
      </Moment>
    </Tooltip>
  );
};

LocalDate.defaultProps = {
  date: '',
  detailsFormat: 'hh:mm A MMM D, YYYY',
  format: 'MMM D, YYYY',
};

LocalDate.propTypes = {
  date: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  detailsFormat: PropTypes.string,
  format: PropTypes.string,
};

export default LocalDate;
