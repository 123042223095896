export const UPDATE_TRANSACTION_STATUS = 'UPDATE_TRANSACTION_STATUS';

export const GET_BALANCE_SUCCESS = 'GET_BALANCE_SUCCESS';

export const UPDATE_WEB3 = 'UPDATE_WEB3';

export const ENABLE_WEB3 = 'ENABLE_WEB3';

export const ON_WEB3_ACCOUNT_CHANGE = 'ON_WEB3_ACCOUNT_CHANGE';

export const GENEREATE_ACCOUNTS_SUCCESS = 'GENEREATE_ACCOUNTS_SUCCESS';

export const BACKUP_ACCOUNTS_SUCCESS = 'BACKUP_ACCOUNTS_SUCCESS';

export const BROADCAST_TRANSACTION_SUCCESS = 'BROADCAST_TRANSACTION_SUCCESS';

export const INITIALIZE_API_SUCCESS = 'INITIALIZE_API_SUCCESS';

export const GET_TRANSACTION_INFO_SUCCESS = 'GET_TRANSACTION_INFO_SUCCESS';

export const GET_ACCOUNT_INFO_SUCCESS = 'GET_ACCOUNT_INFO_SUCCESS';

export const SET_WALLET_SELECTED_ACCOUNT = 'SET_WALLET_SELECTED_ACCOUNT';

export const RESET_TRANSACTION_DONE = 'RESET_TRANSACTION_DONE';

export const RESET_TRANSACTION_HASH = 'RESET_TRANSACTION_HASH';

export const RESET_TRANSACTION_INFO = 'RESET_TRANSACTION_INFO';
