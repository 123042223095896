import * as paginationOperations from './operations';
import reducer from './reducers';
import * as paginationTypes from './types';

export {
  paginationOperations,
  paginationTypes,
};

export default reducer;
