import React from 'react';
import { useTranslation } from 'react-i18next';

import ErrorPage from './ErrorPage';

const NotFoundPage = () => {
  const { t } = useTranslation();
  return (
    <ErrorPage
      errorTitle="404"
      errorMessage={t('Sorry, an error has occurred. The page you were looking does not exist or might has been removed')}
      displayBackButton
    />
  );
};

export default NotFoundPage;
