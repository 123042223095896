import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import RedirectButton from './RedirectButton';

import { dashboardRoute, signinRoute } from 'routes';

const BackToHomeButton = ({
  variant,
  color,
}) => {
  const { t } = useTranslation();
  const { isAuthenticated } = useSelector((state) => state.userR.authentication);
  // todo change marketRoute to myNodeRoute
  const redirectURL = isAuthenticated ? dashboardRoute : signinRoute;
  return (
    <RedirectButton
      redirectURL={redirectURL}
      color={color}
      variant={variant}
    >
      {t('Back to home')}
    </RedirectButton>
  );
};

BackToHomeButton.propTypes = {
  variant: PropTypes.string,
  color: PropTypes.string,
};
BackToHomeButton.defaultProps = {
  variant: 'text',
  color: 'primary',
};
export default BackToHomeButton;
