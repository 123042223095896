// query
import {
  createMutation,
} from '../../../modules/apollo/apollo';

import {
  uploadFileToS3Mutation,
} from './mutations';
// apollo

export const graphqlUploadFileToS3 = createMutation(uploadFileToS3Mutation);
