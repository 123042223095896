import { regexExp } from '../../modules/appConstants';
import { valueValidate } from '../../modules/validates';

export const validate = (values) => {
  const { email } = values;
  const { emailRegex } = regexExp;
  let errors = {};
  errors = { ...errors, ...valueValidate(email, 'email', values, emailRegex) };
  return errors;
};
