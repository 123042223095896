export const styles = (theme) => ({
  paper: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    maxWidth: 512,
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  title: {
    fontWeight: 600,
  },
  button: {
    width: '100%',
    margin: theme.spacing(1, 0),
    padding: theme.spacing(1, 0),
  },
  centerComponent: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  form: {
    flexDirection: 'column',
  },
  middleElements: {
    paddingTop: theme.spacing(2),
  },
  doneText: {
    paddingBottom: theme.spacing(2),
  },
});
