import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import * as R from 'ramda';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { useWidth } from '../../hooks/hooks';
import Payment from '../Payment/Payment';
import SectionCard from '../SectionCard/SectionCard';

import { paymentPagePaymentStyles } from './styles';

const useStyles = makeStyles(paymentPagePaymentStyles);

const alignItemsNoneWidths = ['xs', 'sm', 'md'];
const PaymentPagePayment = (props) => {
  const classes = useStyles();
  const { stripePaymentMethods } = useSelector(
    (state) => state.stripeR,
  );
  const { t } = useTranslation();
  const paymentMethodsNum = R.length(stripePaymentMethods.data);

  const getPaymentMethodText = (paymentMethodsNum) => {
    const isPlural = paymentMethodsNum > 1;
    return isPlural
      ? t('cards')
      : t('card');
  };
  const width = useWidth();
  const leftContent = (
    <Box
      display="flex"
      flexDirection="column"
      alignItems={R.includes(width, alignItemsNoneWidths) ? null : 'center'}
    >
      <Box>
        <Typography
          variant="h2"
        >
          {t('Payment Method')}
        </Typography>
      </Box>
      <Box>
        <Typography
          variant="body1"
        >
          {
          paymentMethodsNum >= 1
            ? getPaymentMethodText(paymentMethodsNum)
            : t('none')
        }
        </Typography>
      </Box>
    </Box>
  );
  const rightContent = (
    <Payment
      btnText={t('add card')}
    />
  );
  return (
    <Paper className={classes.root}>
      <SectionCard
        leftContent={leftContent}
        rightContent={rightContent}
        leftContentGridWidths={{
          xs: 12, sm: 12, md: 12, lg: 5,
        }}
        rightContentGridWidths={{
          xs: 12, sm: 12, md: 12, lg: 7,
        }}
      />
    </Paper>
  );
};

export default PaymentPagePayment;
