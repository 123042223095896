import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import whyDidYouRender from '@welldone-software/why-did-you-render';
import React from 'react';
import { useTranslation } from 'react-i18next';

import {
  ununifiDeployCompleteStyles,
} from './styles';

const { REACT_APP_ENV } = process.env;

if (REACT_APP_ENV !== 'prod') {
  whyDidYouRender(React);
}

const useStyles = makeStyles(ununifiDeployCompleteStyles);

const UnUniFiDeployComplete = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <Typography variant="h4" className={classes.congrat}>
          {t("Congratulations! You've finished setting up your nodes")}
        </Typography>
      </Grid>
    </Grid>
  );
};

UnUniFiDeployComplete.propTypes = {
};

UnUniFiDeployComplete.whyDidYouRender = true;
export default React.memo(UnUniFiDeployComplete);
