import IconButton from '@material-ui/core/IconButton';
import MuiSnackbar from '@material-ui/core/Snackbar';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import PropTypes from 'prop-types';
import React from 'react';

const styles = (theme) => ({
  close: {
    padding: theme.spacing(1) / 2,
  },
});

const Snackbar = (props) => {
  const {
    classes,
    openSnackbar,
    handleCloseSnackbar,
    snackBarUsage,
    snackBarMessage,
    snackBar2ndMessage,
  } = props;
  return (
    <MuiSnackbar
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      open={openSnackbar}
      autoHideDuration={6000}
      onClose={(event) => handleCloseSnackbar(event)}
      ContentProps={{
        'aria-describedby': 'message-id',
      }}
        // TODO dry
      message={snackBarUsage === 'account' || snackBarUsage === 'refesh' ? <span id="message-id">{snackBarMessage}</span> : <span id="message-id">Purchase successfully!</span>}
      action={[
        <Typography key="thankyou-message" color="primary">
          {snackBar2ndMessage}
        </Typography>,
        <IconButton
          key="close"
          aria-label="Close"
          color="inherit"
          className={classes.close}
          onClick={handleCloseSnackbar}
        >
          <CloseIcon />
        </IconButton>,
      ]}
    />
  );
};

Snackbar.defaultProps = {
  handleCloseSnackbar: () => {},
  snackBarMessage: '',
  snackBar2ndMessage: '',
};

Snackbar.propTypes = {
  classes: PropTypes.object.isRequired,
  openSnackbar: PropTypes.bool.isRequired,
  handleCloseSnackbar: PropTypes.func,
  snackBarUsage: PropTypes.string.isRequired,
  snackBarMessage: PropTypes.string,
  snackBar2ndMessage: PropTypes.string,
};

export default withStyles(styles)(Snackbar);
