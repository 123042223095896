import {
  pipeAwait,
} from 'jsutils';
import * as R from 'ramda';

import {
  graphqlNormalize,
} from '../../modules/apollo/apollo';
import {
  returnAction,
} from '../store_utils';

import {
  TransactionStatus,
} from './constants';
import {
  graphqlCreateDeposit,
} from './graphql/services';
import * as types from './types';

export const createDeposit = R.curry(
  async (
    userId,
    steps,
    depositData,
  ) => pipeAwait(
    graphqlCreateDeposit,
    graphqlNormalize('createDeposit'),
    returnAction(types.CREATE_DEPOSIT_SUCCESS),
  )({
    createDepositInput: {
      userId,
      steps,
      data: depositData,
    },
  }),
);
export const extractDepositKeys = R.curry((depositData) => {
  const depositKeys = R.pipe(
    JSON.parse,
    R.map(R.pick([
      'pubkey',
      'withdrawal_credentials',
      'amount',
      'signature',
      'deposit_message_root',
      'deposit_data_root',
    ])),
    R.map(R.assoc('transactionStatus', TransactionStatus.READY)),
  )(depositData);
  return {
    type: types.EXTRACT_DEPOSIT_KEYS,
    payload: depositKeys,
  };
});

export const updateTransactionStatus = R.curry((
  pubkey,
  status,
  txHash,
) => ({
  type: types.UPDATE_TRANSACTION_STATUS,
  payload: {
    pubkey,
    status,
    txHash,
  },
}));

export const resetDeposit = () => ({
  type: types.RESET_DEPOSIT,
});

export const insertNodeData = R.curry((
  selectedNodesToDeposit,
) => ({
  type: types.INSERT_NODE_DATA,
  payload: selectedNodesToDeposit,
}));

export const depositSuccess = (payload) => ({
  type: types.DEPOSIT_SUCCESS,
  payload,
});
