import * as R from 'ramda';

const supportedLangs = ['en', 'ja'];
export const createGetGitbookLink = R.curry((supportedLangs, baseUrl, slug, lang) => {
  const supported = R.includes(lang, supportedLangs);
  const langToUse = supported ? lang : 'en';
  const uriComponent = langToUse !== 'en' ? `v/${langToUse}` : '';
  const slash = uriComponent ? '/' : '';
  return `${baseUrl}${slash}${uriComponent}/${slug}`;
});

export const getGitbookLink = createGetGitbookLink(supportedLangs, 'https://docs.neukind.com');
