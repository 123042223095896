import gql from 'graphql-tag';

export const signupUsersMutation = gql`
  mutation signupUsers(
    $signupUsersInput: SignupUsersInput!
  )
  {
    signupUsers(
      signupUsersInput: $signupUsersInput
    ) {
      items {
        user {
          userId
          email
          joined
          modified
          lastSignin
          activated
          role
        }
      }
    }
  }
`;
