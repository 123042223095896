export const eth20WalletStyles = (theme) => ({
  walletParentGrid: {
    width: 400,
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
});

export const eth20WalletImportStyles = (theme) => ({
  fileNameText: {
    width: '100%',
  },
  uploadButton: {
    marginTop: theme.spacing(1),
    height: 35,
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(0),
      width: '100%',
    },
  },
  uploadParentGrid: {
    width: '100%',
  },
  fileFieldBtn: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  encryptionPasswordField: {
    width: '100%',
  },
  dialogTitle: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  dialogPaper: {
    overflow: 'hidden',
  },
  centerComponent: {
    ...theme.centerComponent,
  },
});

export const eth20WalletFilesStyles = (theme) => ({
  containerGrid: {
    maxWidth: 500,
    padding: theme.spacing(1, 1, 1, 2),
  },
  actionGrid: {
    ...theme.centerComponent,
    justifyContent: 'flex-end',
  },
});
