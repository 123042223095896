import {
  toNumbers,
} from 'jsutils';
import isFunction from 'lodash/isFunction';
import * as R from 'ramda';

export const returnAction = R.curry(
  (type, payload) => ({ type, payload }),
);

// todo remove loadingAction
export const loadingAction = (type) => (scope) => (
  !scope ? { type, payload: true } : { type, payload: { scope, loading: true } }
);
export const failAction = (type) => (error) => ({ type, payload: error });

export const createAction = R.curry(
  (type, scope, value) => ({ type, payload: { scope, value } }),
);
// reducer
export const createReducerUpdaterWithScope = R.curry((scopeSuffix) => (state, payload) => {
  const { scope, value } = payload;
  return {
    ...state,
    [`${scope}${scopeSuffix}`]: value,
  };
});
/**
 * Merge state and payload if payload is an object
 * @param  {Object} state
 * @param  {Object} payload
 * @return {Object}
 */
export const mergeStateAndPayload = R.curry(
  (state, payload) => R.mergeRight(state, payload),
);
/**
 * Convert targeting props in payload to number and
 * update state
 * @param  {Array} toNumberTargetKeys - targeting key to convert to number
 * @param  {Object} state
 * @param  {Object} payload
 * @return {Object}
 */
export const toNumbersPayloadAndUpdateState = R.curry(
  (toNumberTargetKeys, state, payload) => R.pipe(
    toNumbers(toNumberTargetKeys),
    mergeStateAndPayload(state),
  )(payload),
);
/**
 * Update a specific prop in state
 * @param  {Object} state
 * @param  {any} payload
 * @return {Object}
 */
export const updateStateWithKey = R.curry(
  (key, state, value) => R.assoc(key, value, state),
);

export const updateStateWithKeyPath = R.curry(
  (keyPath, value, state) => R.set(R.lensPath(keyPath), value, state),
);
/**
 * crearte a reducer from default state and updates
 * @param  {Object} defaultState   - the initial state
 * @param  {Object} reducerUpdaters - reducer updaters
 * if a updater in undaters is not a funcntion, return the state
 * @return {Object}  the updated state
 */
export const createReducer = R.curry(
  (defaultState, reducerUpdaters) => (state = defaultState, action) => {
    const { type, payload } = action;
    const reducerUpdater = reducerUpdaters[type];
    if (isFunction(reducerUpdater)) {
      return reducerUpdater(state, payload);
    }
    return state;
  },
);

export const createUpdateAction = R.curry(
  (type, updatePath, data) => ({
    type,
    payload: { updatePath, data },
  }),
);

export const createResetAction = (type) => () => ({
  type,
});

/**
 * dispatch an action creator
 * @param  {Function}    dispatch
 * @param  {Function}    action
 * @param  {Array} arg   action's arguments
 * @return {Object}
 */
export const dispatchAction = (dispatch, action, ...arg) => {
  const { type, payload } = dispatch(action(...arg));
  return { type, payload };
};

/**
 * Dispatch the given action.
 * This function should not be curried when defined
 * it can be curried when called by using R.curryN
 * @param  {[type]} async dispatch, action, ...args
 * @return {[type]}       [description]
 */
export const dispatchAsync = async (dispatch, action, ...args) => {
  const { type, payload } = dispatch(await action(...args));
  return { type, payload };
};
