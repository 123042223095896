import Skeleton from '@material-ui/lab/Skeleton';
import toNumber from 'lodash/toNumber';
import React from 'react';

export const getFiatContent = (value, loading = false) => (loading
  ? (
    <Skeleton
      variant="text"
      width="100%"
      height="100%"
    />
  ) : toNumber(value).toLocaleString('en-US', {
    style: 'currency',
    currency: 'JPY',
  })
);
