import Box from '@material-ui/core/Box';
import Checkbox from '@material-ui/core/Checkbox';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import startCase from 'lodash/fp/startCase';
import toLower from 'lodash/fp/toLower';
import * as R from 'ramda';
import React from 'react';

// others
import Moment from 'react-moment';

import { timelineStepNameMapIndex } from '../Investment/constants';
import LocalDate from '../LocalDate/LocalDate';
import { RoundedButton } from '../MaterialUiCustom/MaterialUiCustom';

export const userListColumns = (t, viewUserDashboard, loading) => ([
  {
    accessor: 'userId',
    Header: 'user ID',
    width: 300,
    Cell: (data) => {
      const { userId } = data.row.original;
      return (
        <Box position="absolute" top="50%" style={{ transform: 'translateY(-50%)' }}>
          { userId }
        </Box>
      );
    },
  },
  {
    accessor: 'email',
    Header: t('Email'),
    width: 220,
    Cell: (data) => {
      const { email } = data.row.original;
      return (
        <Box position="absolute" top="50%" style={{ transform: 'translateY(-50%)', overflow: 'hidden' }}>
          { email }
        </Box>
      );
    },
  },

  {
    accessor: 'joined',
    Header: t('Joined'),
    width: 100,
    Cell: (data) => {
      const { joined } = data.row.original;
      return (
        <Box position="absolute" top="50%" style={{ transform: 'translateY(-50%)' }}>
          <Moment format="MMM D, YYYY">
            { joined }
          </Moment>
        </Box>
      );
    },
  },
  // hide this for now
  // {
  //   accessor: 'modified',
  //   Header: t('Modified'),
  //   Cell: (data) => {
  //     const { modified } = data.row.original;
  //     const formatedModified = (
  //       <Moment format="MMM D, YYYY">
  //         { modified }
  //       </Moment>
  //     );
  //     return (
  //       <Box position="absolute" top="50%" style={{ transform: 'translateY(-50%)' }}>
  //         {modified ? formatedModified : 'N/A'}
  //       </Box>
  //     );
  //   },
  // },
  {
    accessor: 'lastSignin',
    Header: t('Last Sign In'),
    width: 100,
    Cell: (data) => {
      const { lastSignin } = data.row.original;
      const formatedLastSignIn = (
        <Moment format="MMM D, YYYY">
          { lastSignin }
        </Moment>
      );
      return (
        <Box position="absolute" top="50%" style={{ transform: 'translateY(-50%)' }}>
          {lastSignin ? formatedLastSignIn : 'N/A'}
        </Box>
      );
    },
  },
  {
    accessor: 'activated',
    Header: t('Activated'),
    width: 50,
    Cell: (data) => {
      const { activated } = data.row.original;
      return (
        <Box position="absolute" top="50%" style={{ transform: 'translateY(-50%)' }}>
          { activated ? 'Yes' : 'No'}
        </Box>
      );
    },
  },
  {
    Header: '',
    id: 'dashboard',
    width: 50,
    Cell: (data) => (
      <Box position="absolute" top="50%" style={{ transform: 'translateY(-50%)' }}>
        <RoundedButton
          variant="contained"
          onClick={() => viewUserDashboard(data.row.original)}
          color="secondary"
          disabled={loading}
        >
          view
        </RoundedButton>
      </Box>
    ),
  },
]);

export const orderListColumns = (
  t,
  isMobileDevice,
  viewOrderActions,
) => ([
  {
    accessor: 'email',
    Header: t('Email'),
    width: 250,
    Cell: (data) => {
      const email = data.row.original.email || 'N/A';
      return (
        <Box position="absolute" top="50%" style={{ transform: 'translateY(-50%)' }}>
          <Typography noWrap style={{ width: 250, fontSize: 14 }}>
            {email}
          </Typography>
        </Box>
      );
    },
  },
  {
    accessor: 'orderId',
    Header: t('Order ID'),
    width: 150,
    Cell: (data) => {
      const orderId = data.row.original.orderId || 'N/A';
      return (
        <Box position="absolute" top="50%" style={{ transform: 'translateY(-50%)' }}>
          <Typography
            noWrap
            style={{
              width: 125, fontSize: 14, textOverflow: 'unset', overflowX: 'auto',
            }}
          >
            {orderId}
          </Typography>
        </Box>
      );
    },
  },
  {
    accessor: 'orderType',
    Header: t('Order Type'),
    width: 200,
    Cell: (data) => {
      const orderType = data.row.original.orderType || 'N/A';
      return (
        <Box position="absolute" top="50%" style={{ transform: 'translateY(-50%)' }}>
          {orderType}
        </Box>
      );
    },
  },
  {
    accessor: 'quantity',
    Header: t('Qty'),
    width: 50,
    Cell: (data) => {
      const { quantity } = data.row.original;
      return (
        <Box position="absolute" top="50%" style={{ transform: 'translateY(-50%)' }}>
          {quantity}
        </Box>
      );
    },
  },
  {
    Header: t('Order Status'),
    accessor: 'Order Status',
    width: 125,
    Cell: (data) => {
      const { timeline, orderType } = data.row.original;

      const isLending = orderType === 'LENDING';
      const timeLineToUse = timeline ? R.sortWith(
        [R.ascend((item) => timelineStepNameMapIndex[item.stepName])],
        timeline,
      ) : [];
      const lastCompletedStepIndex = R.findLastIndex(R.propEq('stepStatus', 'COMPLETE'))(timeLineToUse);
      const currentStepIndex = lastCompletedStepIndex === timeLineToUse.length - 1 ? lastCompletedStepIndex : lastCompletedStepIndex + 1;
      return (
        <Box position="absolute" top="50%" style={{ transform: 'translateY(-50%)' }}>
          {
            currentStepIndex >= 0 && !isLending ? R.pipe(
              R.view(R.lensPath([currentStepIndex, 'stepName'])),
              toLower,
              startCase,
            )(timeLineToUse)
              : '-'
          }
        </Box>
      );
    },
  },
  {
    accessor: 'orderDate',
    Header: t('Order Date'),
    width: 125,
    show: !isMobileDevice,
    Cell: (data) => {
      const orderDate = data.row.original.orderDate || 'N/A';
      const formatedOrderDate = orderDate !== 'N/A'
        ? (
          <Moment format="MMM D, YYYY">
            {orderDate}
          </Moment>
        )
        : orderDate;
      return (
        <Box position="absolute" top="50%" style={{ transform: 'translateY(-50%)' }}>
          {formatedOrderDate}
        </Box>
      );
    },
  },
  {
    Header: '',
    id: 'actions',
    width: 75,
    Cell: (data) => {
      const { index, original } = data.row;
      return (
        <Box position="absolute" top="50%" style={{ transform: 'translateY(-50%)' }}>
          {viewOrderActions(original, index)}
        </Box>
      );
    },
  },
]);

export const firstSigninColumns = (
  t,
  onSelect,
  onSelectAll,
  isSelectedAll,
) => ([
  {
    accessor: 'select',
    Header: (
      <Grid container direction="row" alignItems="center">
        <Checkbox
          checked={isSelectedAll}
          onChange={onSelectAll}
        />
      </Grid>
    ),
    Cell: (data) => {
      const { selected, email } = data.row.original;
      return (
        <Grid container direction="row" alignItems="center">
          <Checkbox
            checked={selected}
            onChange={() => onSelect(!selected, email)}
          />
        </Grid>
      );
    },
  },
  {
    accessor: 'email',
    Header: t('Email'),
    Cell: (data) => {
      const { email } = data.row.original;
      return (
        <Grid container direction="row" alignItems="center">
          { email }
        </Grid>
      );
    },
  },
  {
    accessor: 'dateCreated',
    Header: t('Date Created'),
    Cell: (data) => {
      const { dateCreated } = data.row.original;
      return (
        <Grid container direction="row" alignItems="center">
          <LocalDate date={dateCreated} />
        </Grid>
      );
    },
  },
  {
    accessor: 'hasUnUniFi',
    Header: t('Order Type'),
    Cell: (data) => {
      const { hasUnUniFi } = data.row.original;
      return (
        <Grid container direction="row" alignItems="center">
          {hasUnUniFi ? t('UnUniFi Validator Node') : t('Other')}
        </Grid>
      );
    },
  },
]);

export const createUsersSuccessColumns = (
  t,
) => ([
  {
    accessor: 'userId',
    Header: t('User Id'),
    Cell: (data) => {
      const { userId } = data.row.original;
      return (
        <Grid container direction="row" alignItems="center">
          { userId }
        </Grid>
      );
    },
  },
  {
    accessor: 'email',
    Header: t('Email'),
    Cell: (data) => {
      const { email } = data.row.original;
      return (
        <Grid container direction="row" alignItems="center">
          { email }
        </Grid>
      );
    },
  },
  {
    accessor: 'role',
    Header: t('Role'),
    Cell: (data) => {
      const { role } = data.row.original;
      return (
        <Grid container direction="row" alignItems="center">
          { role }
        </Grid>
      );
    },
  },
  {
    accessor: 'joined',
    Header: t('Joined Since'),
    Cell: (data) => {
      const { joined } = data.row.original;
      return (
        <Grid container direction="row" alignItems="center">
          <LocalDate date={joined} />
        </Grid>
      );
    },
  },
  {
    accessor: 'activated',
    Header: t('Activated'),
    Cell: (data) => {
      const { activated } = data.row.original;
      return (
        <Grid container direction="row" alignItems="center">
          <Checkbox
            checked={activated}
            disabled
            color="default"
          />
        </Grid>
      );
    },
  },
]);

export const createOrdersSuccessColumns = (
  t,
) => ([
  {
    accessor: 'email',
    Header: t('Email'),
    Cell: (data) => {
      const email = data.row.original.email || 'N/A';
      return (
        <Grid container direction="row" alignItems="center">
          {email}
        </Grid>
      );
    },
  },
  {
    accessor: 'orderType',
    Header: t('Order Type'),
    Cell: (data) => {
      const orderType = data.row.original.orderType || 'N/A';
      return (
        <Grid container direction="row" alignItems="center">
          {orderType}
        </Grid>
      );
    },
  },
  {
    accessor: 'quantity',
    Header: t('Qty'),
    Cell: (data) => {
      const { quantity } = data.row.original;
      return (
        <Grid container direction="row" alignItems="center">
          {quantity}
        </Grid>
      );
    },
  },
  {
    Header: t('Order Status'),
    accessor: 'Order Status',
    Cell: (data) => {
      const { timeline, orderType } = data.row.original;

      const isLending = orderType === 'LENDING';
      const timeLineToUse = timeline ? R.sortWith(
        [R.ascend((item) => timelineStepNameMapIndex[item.stepName])],
        timeline,
      ) : [];
      const lastCompletedStepIndex = R.findLastIndex(R.propEq('stepStatus', 'COMPLETE'))(timeLineToUse);
      const currentStepIndex = lastCompletedStepIndex === timeLineToUse.length - 1 ? lastCompletedStepIndex : lastCompletedStepIndex + 1;
      return (
        <Grid container direction="row" alignItems="center">
          {
            currentStepIndex >= 0 && !isLending ? R.pipe(
              R.view(R.lensPath([currentStepIndex, 'stepName'])),
              toLower,
              startCase,
            )(timeLineToUse)
              : '-'
          }
        </Grid>
      );
    },
  },
  {
    accessor: 'orderDate',
    Header: t('Order Date'),
    Cell: (data) => {
      const orderDate = data.row.original.orderDate || 'N/A';
      const formatedOrderDate = orderDate !== 'N/A'
        ? (
          <Moment format="MMM D, YYYY">
            {orderDate}
          </Moment>
        )
        : orderDate;
      return (
        <Grid container direction="row" alignItems="center">
          {formatedOrderDate}
        </Grid>
      );
    },
  },
]);
