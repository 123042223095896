export const connectAddressStyles = (theme) => ({
  listItem: {
    cursor: 'pointer',
  },
});

export const connectWalletConnectStyles = (theme) => ({
  notInjectedText: {
    display: 'flex',
    flexDirection: 'column',
  },
});
