import gql from 'graphql-tag';

export const getCryptoAddressesesQuery = gql`
  query getCryptoAddresses($getCryptoAddressesInput: GetCryptoAddressesInput!)
  {
    getCryptoAddresses(
      getCryptoAddressesInput: $getCryptoAddressesInput
    ) {
      items{
        address
        chain
        subChain
        userId
      }
    }
  }
`;
