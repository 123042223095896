// import { userOperations } from 'store/user';
import * as R from 'ramda';

import * as actions from './actions';

export const { clearReloadUser, impersonateUser } = actions;

export const stopImpersonation = R.curry((dispatch) => {
  dispatch(actions.resetImpersonation());
  // dispatch(userOperations.resetLoadUser())
});
