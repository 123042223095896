import {
  pipeAwait,
} from 'jsutils';
import * as R from 'ramda';

import {
  graphqlNormalize,
} from '../../../modules/apollo/apollo';
import {
  returnAction,
} from '../../store_utils';

import {
  graphqlGetOrders,
  graphqlUpdateOrderData,
  graphqlUpdateOrderTimelines,
  graphqlCreateOrders,
} from './graphql/services';
import { normalizeOrderListData } from './services';
import * as types from './types';

export const adminGetOrders = R.curry(
  async (
    getOrdersInput,
    paginationInput,
  ) => pipeAwait(
    graphqlGetOrders,
    graphqlNormalize('getOrders'),
    normalizeOrderListData,
    returnAction(types.ADMIN_GET_ORDERS_SUCCESS),
  )({
    getOrdersInput: {
      data: getOrdersInput,
      pagination: paginationInput,
    },
  }),
);

export const updateOrderData = R.curry(
  async (
    updateOrderDataInput,
  ) => pipeAwait(
    graphqlUpdateOrderData,
    graphqlNormalize('updateOrderData'),
    normalizeOrderListData,
    returnAction(types.UPDATE_ORDER_DATA_SUCCESS),
  )({
    updateOrderDataInput,
  }),
);

export const updateOrderTimelines = R.curry(
  async (
    orderId,
    activeDate,
    timelines,
  ) => pipeAwait(
    graphqlUpdateOrderTimelines,
    graphqlNormalize('updateTimelines'),
    returnAction(types.UPDATE_ORDER_TIMELINES_SUCCESS),
  )({
    updateTimelinesInput: {
      orderId,
      activeDate,
      data: timelines,
    },
  }),
);

export const createOrders = R.curry(
  async (
    data,
  ) => pipeAwait(
    graphqlCreateOrders,
    graphqlNormalize('createOrders'),
    normalizeOrderListData,
    returnAction(types.CREATE_ORDERS_SUCCESS),
  )({
    createOrdersInput: {
      data,
    },
  }),
);
