import {
  pipeAwait,
} from 'jsutils';
import * as R from 'ramda';

import {
  graphqlNormalize,
} from '../../modules/apollo/apollo';
import {
  returnAction,
  createUpdateAction,
  createResetAction,
} from '../store_utils';

import {
  graphqlGenerateDeployNodeConfig,
} from './graphql/services';
import * as services from './services';
import * as types from './types';

/**
 * Generate deploy data
 *
 * @param      {string}  userId
 * @return     {object}
 */
export const generateDeployNodeConfig = R.curry(
  async (
    nodeType,
    configData,
  ) => pipeAwait(
    graphqlGenerateDeployNodeConfig,
    graphqlNormalize('generateDeployNodeConfig'),
    returnAction(types.GENERATE_DEPLOY_NODE_CONFIG),
  )({ generateDeployNodeConfigInput: { nodeType, data: configData } }),
);

export const toggleDeployConfigMetadata = ({ updatePath, metadata }) => ({
  type: types.TOGGLE_DEPLOY_NODE_CONFIG_META_DATA,
  payload: { updatePath, metadata },
});

export const updateCredentials = (credentials) => ({
  type: types.UPDATE_CREDENTIALS,
  payload: credentials,
});

export const updateDeployConfigNetWork = createUpdateAction(types.UPDATE_DEPLOY_NODE_CONFIG, ['network']);
/**
 * Update config function with more flexible keys
 * @type {[type]}
 */
export const updateDeployConfig = createUpdateAction(types.UPDATE_DEPLOY_NODE_CONFIG);

export const updateDeployConfigNodes = R.curry((
  nodeName,
  nodeType,
  quantity,
) => {
  const initArray = Array.from(Array(quantity).keys());
  const nodes = R.map((value) => ({
    nodeName: quantity > 1 ? `${nodeName}-${value + 1}` : nodeName,
    nodeType,
  }), initArray);
  return createUpdateAction(types.UPDATE_DEPLOY_NODE_CONFIG, ['nodes'], nodes);
});

export const formatSubNodeData = R.curry(
  (nodeType, data) => R.pipe(
    services.formatSubNodeData(nodeType),
    returnAction(types.FORMAT_SUB_NODE_DATA),
  )(data),
);

export const addSubNodesToNodes = R.curry((
  newNodes,
) => ({
  type: types.ADD_SUB_NODES_TO_NODES,
  payload: newNodes,
}));

export const resetDeployConfig = createResetAction(types.RESET_DEPLOY_NODE_CONFIG);
export const resetDeployConfigOneNodeType = (nodeType) => ({
  type: types.RESET_DEPLOY_NODE_CONFIG_ONE_NODE_TYPE,
  payload: { nodeType },
});

export const resetDeployNodeConfigMetatData = createResetAction(
  types.RESET_DEPLOY_NODE_CONFIG_META_DATA,
);

export const updateDeployNodeQuantity = createUpdateAction(
  types.UPDATE_DEPLOY_NODE_QUANTITY,
  ['quantity'],
);

export const deploy = (payload) => ({
  type: types.DEPLOY_NODE_SUCCESS,
  payload: {
    done: true,
    data: payload,
  },
});
