export const chains = [
  'dot',
  'eth2',
  'btc',
  'ksm',
  'bsc',
  'trx',
  'icx',
  'iost',
  'ununifi',
  'cspr',
  // 'nem',
];

export const ChainStatus = Object.freeze({
  ACTIVE: 'ACTIVE',
  DISABLED: 'DISABLED',
  CONTACT_REQUIRED: 'CONTACT_REQUIRED',
  TRIAL: 'TRIAL',
  HIDDEN: 'HIDDEN',
  MAINTENANCE: 'MAINTENANCE',
});

export const getChainsData = (t) => ({
  btc: {
    iconPath: '/img/icon/btc.png',
    name: 'Bitcoin',
    abbreviation: 'btc',
    cryptoUnit: 'BTC',
    status: ChainStatus.CONTACT_REQUIRED,
    target: ['developer', 'full node'],
    brief: t('Bitcoin is digital money that can be exchanged permissionlessly, peer-to-peer, without middlemen. It is based on open-source software anyone can run to keep the network decentralized and therefore censorship resistant. Through the use of Proof-of-Work, the system ensures that a transaction can only be sent to one person at a time, preventing double-spending.'),
    nodeTypes: [
      {
        type: 'BTC_LIGHTNING_NODE',
        benefit:
          t('Running a Lightning Node is one of the best way to contribute to the Lightning Network and get reward from running a Lightning Node. Operating a channel, users are rewarded from the transaction fee which can be customized. Well managed lightning nodes not only provide liquidity to the Lightning Network for bitcoin but also earns a good yield on the channel transaction fee. Help providing Lightning Network here at Neukind'),
        minUnit: 1,
        stakingAmount: 0,
        hardware: {
          CPU: '4 Cores',
          RAM: '16 GB',
          SSD: '614 GB',
        },
        launchInfo: 'https://bitcoin.org/',
        linksSocialMedia: [
          {
            title: 'Official Website',
            link: 'https://bitcoin.org/en/',
          },
          {
            title: 'Github',
            link: 'https://github.com/bitcoin/bitcoin',
          },
        ],
        trialDays: 0,
        pricing: [
          {
            price: 1.3,
            currency: 'USD',
            paymentPeriod: 'day',
          },
          {
            price: 39,
            currency: 'USD',
            paymentPeriod: 'month',
          },
        ],
      },
    ],
  },
  eth2: {
    iconPath: '/img/icon/eth2.png',
    name: 'Ethereum 2.0',
    abbreviation: 'eth2',
    cryptoUnit: 'ETH',
    target: ['developer', 'staking'],
    status: ChainStatus.DISABLED,
    brief: t('Ethereum is an open source, public, and censorship-resistant blockchain that enables users to build decentralized applications on top of it. Ethereum enables anyone to operate and participate in its network in an trustless manner, without the need for a third party.'),
    nodeTypes: [
      {
        type: 'ETH2_VALIDATOR_NODE',
        benefit: t('Contribute to Ethereum network with providing staking for validation. The biggest factor for people to stake for ETH 2.0 will be the staking reward.'),
        minUnit: 2,
        stakingAmount: 32,
        hardware: {
          CPU: '8 Cores',
          RAM: '32 GB',
          SSD: '1000 GB',
        },
        launchInfo: 'https://launchpad.ethereum.org/',

        linksSocialMedia: [
          {
            title: 'Eth2 Launch Pad',
            link: 'https://launchpad.ethereum.org/',
          },
          {
            title: 'Discord',
            link: 'https://invite.gg/ethstaker',
          },
          {
            title: 'Official Website',
            link: 'https://ethereum.org/en/developers/',
          },
          {
            title: 'Github',
            link: 'https://github.com/ethereum',
          },
        ],
        trialDays: 0,
        pricing: [
          {
            // todo set an original montly price, then calculate other price from there
            price: 374.4,
            currency: 'USD',
            paymentPeriod: 'year',
            discount: 0.2,
          },
          {
            price: 39,
            currency: 'USD',
            paymentPeriod: 'month',
          },
        ],
      },
    ],
  },
  dot: {
    iconPath: '/img/icon/dot.png',
    name: 'Polkadot',
    abbreviation: 'dot',
    cryptoUnit: 'DOT',
    target: ['developer', 'full node'],
    status: ChainStatus.CONTACT_REQUIRED,
    brief: t('Polkadot was designed by Dr. Gavin Wood, the former CTO of Ethereum. The original intention was to solve the expansion problem of Ethereum and improve the congestion in the operation of smart contracts. In addition to solving the expansion problem of Ethereum, Polkadot also wants to solve the problem of blockchain cross-chain transactions. At present, it is difficult to circulate information between various blockchains, and cross-chain transactions cannot be carried out. Compared with the mature network architecture of Web2.0, Polkadot is committed to solving this pain point and making the blockchain move towards Web3.0.'),
    nodeTypes: [
      {
        type: 'DOT_VALIDATOR_NODE',
        benefit: t('Validators can receive block rewards and transaction fees for securing Relay Chain by staking DOTs, validating proofs from collators and participating in the network in consensus with other validators.'),
        minUnit: 1,
        stakingAmount: t('The minimum stake that is necessary to be elected as an active validator is dynamic and can change over time.'),
        hardware: {
          CPU: '4 Cores',
          RAM: '16 GB',
          SSD: '160 GB',
        },
        launchInfo: 'https://polkadot.network/',
        linksSocialMedia: [
          {
            title: 'Telegram',
            link: 'https://t.me/polkadotofficial',
          },
          {
            title: 'Official Website',
            link: 'https://polkadot.network',
          },
          {
            title: 'Github',
            link: 'https://github.com/paritytech/polkadot',
          },
        ],
        trialDays: 0,
        pricing: [
          {
            price: 95.9,
            currency: 'USD',
            paymentPeriod: 'day',
          },
          {
            price: 2877,
            currency: 'USD',
            paymentPeriod: 'month',
          },
        ],
      },
    ],
  },

  ksm: {
    iconPath: '/img/icon/ksm.png',
    name: 'Kusama',
    abbreviation: 'ksm',
    cryptoUnit: 'KSM',
    target: ['developer', 'staking'],
    status: ChainStatus.CONTACT_REQUIRED,
    brief: t('The Kusama network was launched to test Polkadot in advance in a way that is close to the real environment of Polkadot to ensure the security of Polkadot. Firstly, it can allow future Polkadot network participants to practice ahead of time. For example, validator nodes, parachain teams, and builders of Polkadot ecological tools can use Kusama to prepare in advance. Secondly, some of Polkadot\'s new ideas are risky. Ideas involving protocols and operating environment levels may be tested on the Kusama network first.'),
    nodeTypes: [
      {
        type: 'KSM_VALIDATOR_NODE',
        benefit:
          t('Users can contribute to the security of the Kusama network and receive rewards in KSM tokens for running a validator node.'),
        minUnit: 1,
        stakingAmount: 350,
        hardware: {
          CPU: '2 Cores',
          RAM: '4 GB',
          SSD: '160 GB',
        },
        launchInfo: 'https://kusama.network/',
        linksSocialMedia: [
          {
            title: 'Telegram',
            link: 'https://t.me/kusamanetworkofficial',
          },
          {
            title: 'Official Website',
            link: 'https://kusama.network',
          },
          {
            title: 'Github',
            link: 'https://github.com/kusamanetwork',
          },
        ],
        trialDays: 0,
        pricing: [
          {
            price: 31.9,
            currency: 'USD',
            paymentPeriod: 'day',
          },
          {
            price: 959,
            currency: 'USD',
            paymentPeriod: 'month',
          },
        ],
      },
    ],
  },
  ununifi: {
    iconPath: '/img/icon/ununifi.png',
    name: 'UnUniFi',
    abbreviation: 'ununifi',
    cryptoUnit: 'GUU',
    target: ['developer', 'staking'],
    status: ChainStatus.CONTACT_REQUIRED,
    hidePrice: true,
    brief: t('The blockchains UnUniFi is developed by CauchyE. It is a decentralized stablecoin platform that linked to the Japanese yen (JPY). On the blockchain UnUniFi, the native UnUniFi stablecoin token can be issued and used using the governance token "GUU". The blockchain was forked from the Kava project, which offers a DeFi lending platform. CauchyE is also developing an explorer called Cosmoscan with a wallet function for the Cosmos "Internet of Blockchains."'),
    nodeTypes: [
      {
        type: 'UNUNIFI_VALIDATOR_NODE',
        benefit: t('Node validators can get commission from delegators who delegate their GUU token (Governance token of UnUniFi) in your node. Through this process, the validators contribute to improve the security and decentralization of the UnUniFi blockchain.'),
        minUnit: 1,
        // launchInfo: 'https://polkadot.network/',
        trialDays: 0,
        linksSocialMedia: [
          {
            title: 'Official website',
            link: 'https://CauchyE.com',
          },
          {
            title: 'Github',
            link: 'https://github.com/CauchyE',
          },
          {
            title: 'Twitter',
            link: 'https://twitter.com/CauchyE_ja',
          },
        ],
        pricing: [
          {
            price: 0,
            currency: 'USD',
            paymentPeriod: 'day',
          },
          {
            price: 0,
            currency: 'USD',
            paymentPeriod: 'month',
          },
        ],
      },
    ],
  },
  cspr: {
    iconPath: '/img/icon/cspr.png',
    name: 'Casper',
    abbreviation: 'cspr',
    cryptoUnit: 'CSPR',
    target: ['developer', 'staking'],
    status: ChainStatus.CONTACT_REQUIRED,
    hidePrice: true,
    brief: t('The Casper Network is a proof-of-stake blockchain designed for enterprise and developer. Built off Casper CBC specification, Casper provide a flexible, fast and secure blockchain. Casper\'s smart contract is upgradable. Predictable gas fee makes the network efficient even in rush hours.'),
    nodeTypes: [
      {
        type: 'CSPR_VALIDATOR_NODE',
        benefit: t('Node operators (validators) receive commission from providing staking infrastructure for Casper token owner who want stake their token (degelators). Rewards are distributed evenly to degelators who participate in consensus to finialize blocks.'),
        minUnit: 1,
        // launchInfo: 'https://polkadot.network/',
        trialDays: 0,
        linksSocialMedia:
          [
            {
              title: 'Telegram',
              link: 'https://t.me/casperblockchain',
            },
            {
              title: 'Official website',
              link: 'https://casper.network/',
            },
            {
              title: 'Github',
              link: 'https://github.com/CasperLabs',
            },
            {
              title: 'Twitter',
              link: 'https://twitter.com/Casper_Network',
            },
            {
              title: 'Discord',
              link: 'https://discordapp.com/invite/Q38s3Vh',
            },
            {
              title: 'Youtube',
              link: 'https://www.youtube.com/channel/UCjFz9Sfi4yFwocnDQTWDSqA',
            },
          ],
        pricing: [
          {
            price: 0,
            currency: 'USD',
            paymentPeriod: 'day',
          },
          {
            price: 0,
            currency: 'USD',
            paymentPeriod: 'month',
          },
        ],
      },
    ],
  },
  bnb: {
    iconPath: '/img/icon/bnb.png',
    name: 'Binance',
    abbreviation: 'bnb',
    cryptoUnit: 'BNB',
    target: ['developer', 'staking'],
    status: ChainStatus.DISABLED,
    brief: t('Binance smart chain brings EVM compatible smart contract functionality by running parallel to the Binance Chain. However, BSC is an independent blockchain that can run even if Binance Chain goes offline.'),
    nodeTypes: [
      {
        type: 'BSC_SMART_CHAIN_VALIDATOR',
        benefit:
          t('Binance Smart Chain is in testnet. In mainnet, the top 21 most staked nodes will be part of the validator set and will be rewarded with transaction fees.'),
        minUnit: 1,
        stakingAmount: 10000,
        hardware: {
          CPU: '4 Cores',
          RAM: '16 GB',
          SSD: '500 GB',
        },
        launchInfo: 'https://kusama.network/',
        linksSocialMedia: [
          {
            title: 'Telegram',
            link: 'https://t.me/binanceacademy',
          },
          {
            title: 'Official Website',
            link: 'https://www.binance.org/en/smartChain',
          },
          {
            title: 'Github',
            link: 'https://github.com/binance-chain',
          },
        ],
        trialDays: 0,
        pricing: [
          {
            price: 0,
            currency: 'USD',
            paymentPeriod: 'day',
          },
          {
            price: 0,
            currency: 'USD',
            paymentPeriod: 'month',
          },
        ],
      },
    ],
  },
  cosmos: {
    iconPath: '/img/icon/cosmos.png',
    name: 'Cosmos',
    abbreviation: 'cosmos',
    cryptoUnit: 'ATOM',
    status: ChainStatus.DISABLED,
    target: ['developer', 'staking'],
    brief: t('Cosmos is the first blockchain to support inter blockchain communication. Inter blockchain communication and the flexibility of the SDK makes Cosmos apart from other projects. When creating a blockchain with Cosmos it is called the zone. The Cosmos Hub maintain a record of state of each different zone and the zone also maintain a record of state of the hub to ensure the security of the blockchain. You can delegate to a validator or become a validator. '),
    nodeTypes: [
      {
        type: 'COSMOS_VALIDATOR_NODE',
        benefit:
          t('By delegating/validating on Cosmos network you are contributing to the security of the network. On the other hand you will be able to earn 8% APY passively.'),
        minUnit: 1,
        launchInfo: 'https://polkadot.network/',
        linksSocialMedia: [
        ],
        trialDays: 0,
        pricing: [
          {
            price: 0,
            currency: 'USD',
            paymentPeriod: 'day',
          },
          {
            price: 0,
            currency: 'USD',
            paymentPeriod: 'month',
          },
        ],
      },
    ],
  },

  algorand: {
    iconPath: '/img/icon/algorand.png',
    name: 'Algorand',
    abbreviation: 'algorand',
    cryptoUnit: 'ALGO',
    target: ['developer', 'staking'],
    status: ChainStatus.DISABLED,
    brief: t('Algorand is a project started by Silvio Micali. The project has previously raised approximately $66 million from venture capital firms such as Union Square Ventures and Pillar Venture Capital. Algorand claims that it will solve the current bottlenecks faced by the public chain while maintaining decentralization, and make the blockchain both scalable and secure. Silvio Micali claims that Algorand\'s consensus algorithm solves the triangle problem of the blockchain , He called this consensus algorithm "Pure Proof of Stake".'),
    nodeTypes: [
      {
        type: 'ALGORAND_FULL_NODE',
        benefit:
         t('Earn around 5% ~ 6% of annual Algorand passively by staking 1 or more ALGO. You can claim reward with every transaction going in or out of your wallet.'),
        minUnit: 1,
        launchInfo: 'https://polkadot.network/',
        linksSocialMedia: [
        ],
        trialDays: 0,
        pricing: [
          {
            price: 0,
            currency: 'USD',
            paymentPeriod: 'day',
          },
          {
            price: 0,
            currency: 'USD',
            paymentPeriod: 'month',
          },
        ],
      },
      {
        type: 'ALGORAND_VALIDATOR_NODE',
        benefit:
          t('Earn around 5% ~ 6% of annual Algorand passively by staking 1 or more ALGO. You can claim reward with every transaction going in or out of your wallet.'),
        minUnit: 1,
        launchInfo: 'https://polkadot.network/',
        linksSocialMedia: [
        ],
        trialDays: 0,
        pricing: [
          {
            price: 0,
            currency: 'USD',
            paymentPeriod: 'day',
          },
          {
            price: 0,
            currency: 'USD',
            paymentPeriod: 'month',
          },
        ],
      },
    ],
  },
  solana: {
    iconPath: '/img/icon/solana.png',
    name: 'Solana',
    abbreviation: 'solana',
    cryptoUnit: 'SOL',
    target: ['developer', 'staking'],
    status: ChainStatus.DISABLED,
    brief: t('Solana’s core is Proof of History (PoH) that solve time problem in distributed network. Solana was founded late 2017 with Proof-of stake protocol that focus on providing scalability. The Proof of History is the core and referred as the clock of the network, it allows validator to agree on the Verifiable Delay Function (VDF). '),
    nodeTypes: [
      {
        type: 'SOLANA_VALIDATOR_NODE',
        benefit:
          t('Staking SOL is a direct way of contributing to Solana blockchain. By doing so you will earn reward for the effort of ensuring Solana’s security. Solana is a Proof-of-Stake network with delegations, which allows anyone who holds SOL token to delegate or become a validator to process transaction.'),
        minUnit: 1,
        launchInfo: 'https://polkadot.network/',
        linksSocialMedia: [],
        trialDays: 0,
        pricing: [
          {
            price: 0,
            currency: 'USD',
            paymentPeriod: 'day',
          },
          {
            price: 0,
            currency: 'USD',
            paymentPeriod: 'month',
          },
        ],
      },
    ],
  },

  // nem: {
  //   iconPath: '/img/icon/solana.png',
  //   name: 'Nem',
  //   abbreviation: 'nem',
  //   cryptoUnit: 'SOL',
  //   target: ['developer', 'staking'],
  //   status: ChainStatus.TRIAL,
  //   brief: t('Solana’s core is Proof of History (PoH) that solve time problem in distributed network. Solana was founded late 2017 with Proof-of stake protocol that focus on providing scalability. The Proof of History is the core and referred as the clock of the network, it allows validator to agree on the Verifiable Delay Function (VDF). '),
  //   nodeTypes: [
  //     {
  //       type: 'NEM_UNUNIFI_VALIDATOR_NODE',
  //       benefit:
  //         t('Staking SOL is a direct way of contributing to Solana blockchain. By doing so you will earn reward for the effort of ensuring Solana’s security. Solana is a Proof-of-Stake network with delegations, which allows anyone who holds SOL token to delegate or become a validator to process transaction.'),
  //       minUnit: 1,
  //       launchInfo: 'https://polkadot.network/',
  //       linksSocialMedia: {
  //       },
  //       trialDays: 30,
  //       pricing: [
  //         {
  //           price: 3274.4,
  //           currency: 'USD',
  //           paymentPeriod: 'year',
  //           discount: 0.2,
  //         },
  //         {
  //           price: 39,
  //           currency: 'USD',
  //           paymentPeriod: 'month',
  //         },
  //       ],
  //     },
  //   ],
  // },
});
