import Fade from '@material-ui/core/Fade';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Alert from '@material-ui/lab/Alert';
import isNull from 'lodash/isNull';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { useAddFunctionToRef, useGetQuery } from '../../hooks/hooks';
import {
  nodeOperations,
  nodeSelectors,
} from '../../store/node/index';
import { routerServices } from '../../store/router/index';
import EarningDashboardOneNode from '../MyNodesList/EarningDashboardOneNode';
import MyNode from '../MyNodesList/MyNode';
import LaunchNodeTabs from '../Tabs/LaunchNodeTabs';

import MyNodeInfoDescription from './MyNodeInfoDescription/MyNodeInfoDescription';
import { formatNodeNameFns } from './utils';
import { isAPIReadFailedError } from 'store/error/services';

import { notFoundPageRoute } from 'routes';

const { REACT_APP_DISABLE_ETH2_ORDER } = process.env;

const MyNodeInfo = (props) => {
  const {
    match,
  } = props;
  const { t } = useTranslation();
  const history = useHistory();
  const queryLng = useGetQuery('lng');
  const dispatch = useDispatch();
  const { nodeName } = R.view(R.lensPath(['params']), match);
  const { userId } = useSelector((state) => state.userR.user);
  const [loading, setLoading] = useState(true);

  const getUserNodeLoading = useSelector((state) => state.loadingR.getUserNodeLoading);
  const {
    getTotalEarningIn24hOneNodeError,
    getTotalEarningIn7dOneNodeError,
  } = useSelector((state) => state.errorR);

  const doesTotalEarningIn24hOneNodeApiErrorExists = isAPIReadFailedError(
    getTotalEarningIn24hOneNodeError
  );
  const doesTotalEarningIn7dhOneNodeApiErrorExists = isAPIReadFailedError(
    getTotalEarningIn7dOneNodeError
  );
  const doesApiReadFailedErrorExists = doesTotalEarningIn24hOneNodeApiErrorExists
    || doesTotalEarningIn7dhOneNodeApiErrorExists;

  const onGetUserNode = useAddFunctionToRef(
    async (userId, nodeName) => {
      setLoading(true);
      await dispatch(nodeOperations.getUserNode(userId, nodeName));
      setLoading(false);
    },
  );

  useEffect(() => {
    onGetUserNode(userId, nodeName);
  }, [onGetUserNode, userId, nodeName]);

  const nodeStatus = useSelector(nodeSelectors.selectNodeData(nodeName));

  const {
    nodeType,
    dateCreated,
    nodeId,
  } = nodeStatus;

  const DescriptionToRender = R.view(
    R.lensPath([nodeType]),
    MyNodeInfoDescription,
  );
  useEffect(() => {
    if (!DescriptionToRender) {
      return routerServices.historyPush(
        history,
        {
          pathname: notFoundPageRoute,
          queryLng,
        },
      );
    }
  }, [DescriptionToRender, history, queryLng]);

  useEffect(() => {
    if (!loading) {
      if (!nodeStatus.nodeName) { // nodeName null means node data is not existed
        return routerServices.historyPush(
          history,
          {
            pathname: notFoundPageRoute,
            queryLng,
          },
        );
      }
    }
  }, [nodeStatus, history, queryLng, loading]);

  const onGetNodesEarningIn24hOneNode = useAddFunctionToRef(async (nodeStatus) => {
    if (nodeStatus.nodeInfo.pubKey) {
      await dispatch(nodeOperations.getTotalEarningInPeriod(nodeStatus.nodeInfo.pubKey, 'ETH2_VALIDATOR_NODE', '24_h'));
    }
  });

  const getTotalEarningIn7DaysOneNode = useAddFunctionToRef(async (nodeStatus) => {
    if (nodeStatus.nodeInfo.pubKey) {
      await dispatch(nodeOperations.getTotalEarningInPeriod(nodeStatus.nodeInfo.pubKey, 'ETH2_VALIDATOR_NODE', '7_d'));
    }
  });

  const onGetCoinPrice = useAddFunctionToRef(
    async () => {
      await dispatch(nodeOperations.getCoinPrice('ETH', 'JPY'));
    },
  );

  useEffect(() => {
    onGetNodesEarningIn24hOneNode(nodeStatus);
  }, [onGetNodesEarningIn24hOneNode, nodeStatus]);

  useEffect(() => {
    getTotalEarningIn7DaysOneNode(nodeStatus);
  }, [getTotalEarningIn7DaysOneNode, nodeStatus]);

  useEffect(() => {
    onGetCoinPrice();
  }, [onGetCoinPrice]);

  const isETH2ValidatorNode = nodeType === 'ETH2_VALIDATOR_NODE';
  const isEth2OrderDisabled = isETH2ValidatorNode && REACT_APP_DISABLE_ETH2_ORDER === 'ON';

  const content = (
    <Fade in>
      <Grid container spacing={2} alignItems="center">
        { isETH2ValidatorNode && (
        <Grid
          item
          xs={12}
        >
          <EarningDashboardOneNode nodeList={[nodeStatus]} />
        </Grid>
        )}
        <Grid item xs={12}>
          <MyNode node={nodeStatus} disabled showTitles />
        </Grid>
        <Grid item xs={12}>
          { DescriptionToRender && !isNull(DescriptionToRender)
        && (
          <DescriptionToRender
            nodeType={nodeType}
            nodeDescription={nodeStatus}
            nodeName={nodeName}
            dateCreated={dateCreated}
            nodeId={nodeId}
          />
        )}
        </Grid>
      </Grid>
    </Fade>
  );

  const tabs = [
    {
      label: `${t('My Nodes')}/${formatNodeNameFns[nodeType](nodeName)}`,
      content: loading ? null : content,
    },
  ];

  return (
    <>
      {
        doesApiReadFailedErrorExists && (
          <Alert
            severity="error"
            style={{
              marginBottom: '8px',
            }}
          >
            <Typography variant="body2" color="inherit">
              {t('Our server is busy. Please reload or try again later.')}
            </Typography>
          </Alert>
        )
      }
      {isEth2OrderDisabled && (
        <Grid item xs={12}>
          <Alert
            severity="warning"
          >
            <Typography variant="body2" color="inherit">
              {t('Eth2 validator deployment is currently unavailable.')}
            </Typography>
          </Alert>
        </Grid>
      )}
      <LaunchNodeTabs
        disabled={isEth2OrderDisabled}
        tabs={tabs}
        loading={getUserNodeLoading}
      />
    </>
  );
};

MyNodeInfo.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      nodeName: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

export default MyNodeInfo;
