export const substrateSS58Registry = {
  specification: 'https://github.com/paritytech/substrate/wiki/External-Address-Format-(SS58)',
  schema: {
    prefix: 'The address prefix. Must be an integer and unique.',
    network: 'Unique identifier for the network that will use this prefix, string, no spaces. To integrate with CLI tools, e.g. `--network polkadot`.',
    displayName: 'The name of the network that will use this prefix, in a format friendly for display.',
    symbols: 'Array of symbols of any tokens the chain uses, usually 2-5 characters. Most chains will only have one. Chains that have multiple instances of the Balances pallet should order the array by instance.',
    decimals: "Array of integers representing the number of decimals that represent a single unit to the end user. Must be same length as `symbols` to represent each token's denomination.",
    standardAccount: 'Signing curve for standard account. Substrate supports ed25519, sr25519, and secp256k1.',
    website: 'A website or Github repo associated with the network.',
  },
  registry: [
    {
      prefix: 0,
      network: 'polkadot',
      displayName: 'Polkadot Relay Chain',
      symbols: [
        'DOT',
      ],
      decimals: [
        10,
      ],
      standardAccount: '*25519',
      website: 'https://polkadot.network',
    },
    {
      prefix: 1,
      network: null,
      displayName: 'Bare 32-bit Schnorr/Ristretto (S/R 25519) public key.',
      symbols: null,
      decimals: null,
      standardAccount: null,
      website: null,
    },
    {
      prefix: 2,
      network: 'kusama',
      displayName: 'Kusama Relay Chain',
      symbols: [
        'KSM',
      ],
      decimals: [
        12,
      ],
      standardAccount: '*25519',
      website: 'https://kusama.network',
    },
    {
      prefix: 3,
      network: null,
      displayName: 'Bare 32-bit Ed25519 public key.',
      symbols: null,
      decimals: null,
      standardAccount: null,
      website: null,
    },
    {
      prefix: 4,
      network: 'katalchain',
      displayName: 'Katal Chain',
      symbols: null,
      decimals: null,
      standardAccount: '*25519',
      website: null,
    },
    {
      prefix: 5,
      network: 'plasm',
      displayName: 'Plasm Network',
      symbols: [
        'PLM',
      ],
      decimals: [
        15,
      ],
      standardAccount: '*25519',
      website: 'https://plasmnet.io',
    },
    {
      prefix: 6,
      network: 'bifrost',
      displayName: 'Bifrost',
      symbols: [
        'BNC',
      ],
      decimals: [
        12,
      ],
      standardAccount: '*25519',
      website: 'https://bifrost.finance/',
    },
    {
      prefix: 7,
      network: 'edgeware',
      displayName: 'Edgeware',
      symbols: [
        'EDG',
      ],
      decimals: [
        18,
      ],
      standardAccount: '*25519',
      website: 'https://edgewa.re',
    },
    {
      prefix: 8,
      network: 'karura',
      displayName: 'Karura',
      symbols: [
        'KAR',
      ],
      decimals: [
        12,
      ],
      standardAccount: '*25519',
      website: 'https://karura.network/',
    },
    {
      prefix: 9,
      network: 'reynolds',
      displayName: 'Laminar Reynolds Canary',
      symbols: [
        'REY',
      ],
      decimals: [
        18,
      ],
      standardAccount: '*25519',
      website: 'http://laminar.network/',
    },
    {
      prefix: 10,
      network: 'acala',
      displayName: 'Acala',
      symbols: [
        'ACA',
      ],
      decimals: [
        12,
      ],
      standardAccount: '*25519',
      website: 'https://acala.network/',
    },
    {
      prefix: 11,
      network: 'laminar',
      displayName: 'Laminar',
      symbols: [
        'LAMI',
      ],
      decimals: [
        18,
      ],
      standardAccount: '*25519',
      website: 'http://laminar.network/',
    },
    {
      prefix: 12,
      network: 'polymesh',
      displayName: 'Polymesh',
      symbols: [
        'POLYX',
      ],
      decimals: [
        6,
      ],
      standardAccount: '*25519',
      website: 'https://polymath.network/',
    },
    {
      prefix: 13,
      network: 'substratee',
      displayName: 'SubstraTEE',
      symbols: null,
      decimals: null,
      standardAccount: '*25519',
      website: 'https://www.substratee.com',
    },
    {
      prefix: 14,
      network: 'totem',
      displayName: 'Totem',
      symbols: [
        'XTX',
      ],
      decimals: [
        0,
      ],
      standardAccount: '*25519',
      website: 'https://totemaccounting.com',
    },
    {
      prefix: 15,
      network: 'synesthesia',
      displayName: 'Synesthesia',
      symbols: [
        'SYN',
      ],
      decimals: [
        12,
      ],
      standardAccount: '*25519',
      website: 'https://synesthesia.network/',
    },
    {
      prefix: 16,
      network: 'kulupu',
      displayName: 'Kulupu',
      symbols: [
        'KLP',
      ],
      decimals: [
        12,
      ],
      standardAccount: '*25519',
      website: 'https://kulupu.network/',
    },
    {
      prefix: 17,
      network: 'dark',
      displayName: 'Dark Mainnet',
      symbols: null,
      decimals: null,
      standardAccount: '*25519',
      website: null,
    },
    {
      prefix: 18,
      network: 'darwinia',
      displayName: 'Darwinia Network',
      symbols: [
        'RING',
        'KTON',
      ],
      decimals: [
        9,
        9,
      ],
      standardAccount: '*25519',
      website: 'https://darwinia.network/',
    },
    {
      prefix: 19,
      network: 'geek',
      displayName: 'GeekCash',
      symbols: [
        'GEEK',
      ],
      decimals: [
        12,
      ],
      standardAccount: '*25519',
      website: 'https://geekcash.org',
    },
    {
      prefix: 20,
      network: 'stafi',
      displayName: 'Stafi',
      symbols: [
        'FIS',
      ],
      decimals: [
        12,
      ],
      standardAccount: '*25519',
      website: 'https://stafi.io',
    },
    {
      prefix: 21,
      network: 'dock-testnet',
      displayName: 'Dock Testnet',
      symbols: [
        'DCK',
      ],
      decimals: [
        6,
      ],
      standardAccount: '*25519',
      website: 'https://dock.io',
    },
    {
      prefix: 22,
      network: 'dock-mainnet',
      displayName: 'Dock Mainnet',
      symbols: [
        'DCK',
      ],
      decimals: [
        6,
      ],
      standardAccount: '*25519',
      website: 'https://dock.io',
    },
    {
      prefix: 23,
      network: 'shift',
      displayName: 'ShiftNrg',
      symbols: null,
      decimals: null,
      standardAccount: '*25519',
      website: null,
    },
    {
      prefix: 24,
      network: 'zero',
      displayName: 'ZERO',
      symbols: [
        'PLAY',
      ],
      decimals: [
        18,
      ],
      standardAccount: '*25519',
      website: 'https://zero.io',
    },
    {
      prefix: 25,
      network: 'zero-alphaville',
      displayName: 'ZERO Alphaville',
      symbols: [
        'PLAY',
      ],
      decimals: [
        18,
      ],
      standardAccount: '*25519',
      website: 'https://zero.io',
    },
    {
      prefix: 26,
      network: 'jupiter',
      displayName: 'Jupiter',
      symbols: [
        'jDOT',
      ],
      decimals: [
        10,
      ],
      standardAccount: '*25519',
      website: 'https://jupiter.patract.io',
    },
    {
      prefix: 28,
      network: 'subsocial',
      displayName: 'Subsocial',
      symbols: null,
      decimals: null,
      standardAccount: '*25519',
      website: null,
    },
    {
      prefix: 29,
      network: 'cord',
      displayName: 'Dhiway CORD Network',
      symbols: [
        'DCU',
      ],
      decimals: [
        18,
      ],
      standardAccount: '*25519',
      website: 'https://dhiway.com/',
    },
    {
      prefix: 30,
      network: 'phala',
      displayName: 'Phala Network',
      symbols: [
        'PHA',
      ],
      decimals: [
        12,
      ],
      standardAccount: '*25519',
      website: 'https://phala.network',
    },
    {
      prefix: 31,
      network: 'litentry',
      displayName: 'Litentry Network',
      symbols: [
        'LIT',
      ],
      decimals: [
        12,
      ],
      standardAccount: '*25519',
      website: 'https://litentry.com/',
    },
    {
      prefix: 32,
      network: 'robonomics',
      displayName: 'Robonomics',
      symbols: [
        'XRT',
      ],
      decimals: [
        9,
      ],
      standardAccount: '*25519',
      website: 'https://robonomics.network',
    },
    {
      prefix: 33,
      network: 'datahighway',
      displayName: 'DataHighway',
      symbols: null,
      decimals: null,
      standardAccount: '*25519',
      website: null,
    },
    {
      prefix: 34,
      network: 'ares',
      displayName: 'Ares Protocol',
      symbols: [
        'ARES',
      ],
      decimals: [
        12,
      ],
      standardAccount: '*25519',
      website: 'https://www.aresprotocol.com/',
    },
    {
      prefix: 35,
      network: 'vln',
      displayName: 'Valiu Liquidity Network',
      symbols: [
        'USDv',
      ],
      decimals: [
        15,
      ],
      standardAccount: '*25519',
      website: 'https://valiu.com/',
    },
    {
      prefix: 36,
      network: 'centrifuge',
      displayName: 'Centrifuge Chain',
      symbols: [
        'RAD',
      ],
      decimals: [
        18,
      ],
      standardAccount: '*25519',
      website: 'https://centrifuge.io/',
    },
    {
      prefix: 37,
      network: 'nodle',
      displayName: 'Nodle Chain',
      symbols: [
        'NODL',
      ],
      decimals: [
        18,
      ],
      standardAccount: '*25519',
      website: 'https://nodle.io/',
    },
    {
      prefix: 38,
      network: 'kilt',
      displayName: 'KILT Chain',
      symbols: [
        'KILT',
      ],
      decimals: [
        18,
      ],
      standardAccount: '*25519',
      website: 'https://kilt.io/',
    },
    {
      prefix: 39,
      network: 'mathchain',
      displayName: 'MathChain mainnet',
      symbols: [
        'MATH',
      ],
      decimals: [
        18,
      ],
      standardAccount: '*25519',
      website: 'https://mathwallet.org',
    },
    {
      prefix: 40,
      network: 'mathchain-testnet',
      displayName: 'MathChain testnet',
      symbols: [
        'MATH',
      ],
      decimals: [
        18,
      ],
      standardAccount: '*25519',
      website: 'https://mathwallet.org',
    },
    {
      prefix: 41,
      network: 'poli',
      displayName: 'Polimec Chain',
      symbols: null,
      decimals: null,
      standardAccount: '*25519',
      website: 'https://polimec.io/',
    },
    {
      prefix: 42,
      network: 'substrate',
      displayName: 'Substrate',
      symbols: null,
      decimals: null,
      standardAccount: '*25519',
      website: 'https://substrate.dev/',
    },
    {
      prefix: 43,
      network: null,
      displayName: 'Bare 32-bit ECDSA SECP-256k1 public key.',
      symbols: null,
      decimals: null,
      standardAccount: null,
      website: null,
    },
    {
      prefix: 44,
      network: 'chainx',
      displayName: 'ChainX',
      symbols: [
        'PCX',
      ],
      decimals: [
        8,
      ],
      standardAccount: '*25519',
      website: 'https://chainx.org/',
    },
    {
      prefix: 45,
      network: 'uniarts',
      displayName: 'UniArts Network',
      symbols: [
        'UART',
        'UINK',
      ],
      decimals: [
        12,
        12,
      ],
      standardAccount: '*25519',
      website: 'https://uniarts.me',
    },
    {
      prefix: 46,
      network: 'reserved46',
      displayName: 'This prefix is reserved.',
      symbols: null,
      decimals: null,
      standardAccount: null,
      website: null,
    },
    {
      prefix: 47,
      network: 'reserved47',
      displayName: 'This prefix is reserved.',
      symbols: null,
      decimals: null,
      standardAccount: null,
      website: null,
    },
    {
      prefix: 48,
      network: 'neatcoin',
      displayName: 'Neatcoin Mainnet',
      symbols: [
        'NEAT',
      ],
      decimals: [
        12,
      ],
      standardAccount: '*25519',
      website: 'https://neatcoin.org',
    },
    {
      prefix: 63,
      network: 'hydradx',
      displayName: 'HydraDX',
      symbols: [
        'HDX',
      ],
      decimals: [
        12,
      ],
      standardAccount: '*25519',
      website: 'https://hydradx.io',
    },
    {
      prefix: 65,
      network: 'aventus',
      displayName: 'AvN Mainnet',
      symbols: [
        'AVT',
      ],
      decimals: [
        18,
      ],
      standardAccount: '*25519',
      website: 'https://aventus.io',
    },
    {
      prefix: 66,
      network: 'crust',
      displayName: 'Crust Network',
      symbols: [
        'CRU',
      ],
      decimals: [
        12,
      ],
      standardAccount: '*25519',
      website: 'https://crust.network',
    },
    {
      prefix: 67,
      network: 'equilibrium',
      displayName: 'Equilibrium Network',
      symbols: [
        'Unknown',
        'USD',
        'EQ',
        'ETH',
        'BTC',
        'EOS',
        'DOT',
        'CRV',
      ],
      decimals: [
        0,
        9,
        9,
        9,
        9,
        9,
        9,
        9,
      ],
      standardAccount: '*25519',
      website: 'https://equilibrium.io',
    },
    {
      prefix: 69,
      network: 'sora',
      displayName: 'SORA Network',
      symbols: [
        'XOR',
      ],
      decimals: [
        18,
      ],
      standardAccount: '*25519',
      website: 'https://sora.org',
    },
    {
      prefix: 252,
      network: 'social-network',
      displayName: 'Social Network',
      symbols: [
        'NET',
      ],
      decimals: [
        18,
      ],
      standardAccount: '*25519',
      website: 'https://social.network',
    },
  ],
};
