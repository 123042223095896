export const searchBarStyle = (theme) => ({
  searchPaper: {
    width: 320,
    borderWidth: 0,
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
  searchInput: {
    width: '100%',
  },
});

export const busdContentItemStyles = ((theme) => ({
  root: {
    padding: theme.spacing(2, 2),
    height: '100%',
  },
  content: {
    padding: theme.spacing(0, 0, 1, 0),
    height: '100%',
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(0, 0, 1, 0),
    },
  },
  title: {
    padding: theme.spacing(1, 2, 0, 2),
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(1, 0, 0, 0),
    },
    fontWeight: 600,
  },
}));

export const busdOverviewStyles = (theme) => {
  const h3Properties = {
    fontSize: theme.typography.h3.fontSize,
    fontWeight: theme.typography.h3.fontWeight,
  };
  return ({
    container: {
      padding: theme.spacing(0, 2, 1, 2),
      minWidth: '100px',
      ...theme.centerComponent,
    },
    dataWithCircleBg: {
      color: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
    },
    dataWithCircle: {
      strokeLinecap: 'round',
      color: theme.nodeStatus.active,
    },
    percent: {
      ...h3Properties,
    },
    totalEarning: {
      padding: theme.spacing(0, 2, 1, 2),
      fontSize: theme.typography.h2.fontSize,
      fontWeight: theme.typography.h2.fontWeight,
    },
    overviewText: {
      ...h3Properties,
    },
    percentTextAsc: {
      ...h3Properties,
      color: theme.palette.green.main,
    },
    percentTextDesc: {
      ...h3Properties,
      color: theme.palette.error.main,
    },
    stack2Top: {
      paddingBottom: theme.spacing(0.5),
      [theme.breakpoints.down('xs')]: {
        padding: theme.spacing(0, 0.5, 0, 0),
      },
    },
    stack2Bottom: {
      paddingTop: 4,
      [theme.breakpoints.down('xs')]: {
        padding: theme.spacing(0, 0, 0, 0.5),
      },
    },
    amountCard: {
      height: '100%',
      margin: 0,
      background: 'transparent linear-gradient(20deg, #F0B90B 50%, #F9DC83 120%) 0% 0% no-repeat padding-box',
    },
  });
};

export const busdEarningHistoryStyles = (theme) => ({
  busdStepperContainer: {
    backgroundColor: '#F4F5F9',
    marginLeft: '40%',
    padding: theme.spacing(0, 0, 3, 0),
  },
  busdStepperStep: {
    backgroundColor: '#F4F5F9',
  },
});
