import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import whyDidYouRender from '@welldone-software/why-did-you-render';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { reduxForm, reset } from 'redux-form';

import { minLength8 } from '../../modules/validates';
import { userOperations } from '../../store/user/index';
import ErrorAlert from '../Error/ErrorAlert';
import ErrorMessage from '../ErrorMessage/ErrorMessage';
import { RoundedButton } from '../MaterialUiCustom/MaterialUiCustom';
import PasswordField from '../PasswordField/PasswordField';

import { styles } from './styles';
import { validateChangePassword } from './validate';

if (process.env.REACT_APP_ENV !== 'prod') {
  whyDidYouRender(React);
}
const useStyles = makeStyles(styles);
const formName = 'changePassword';

/**
 * Change password section
 *
 * @component
 * @example
 * return (
 *   <UserPasswordChange open={openChangePassword} onClose={onCloseChangePassword} userId={userId} email={email} />
 * )
 */
const UserPasswordChange = (props) => {
  const {
    onChangePasswordSuccess,
  } = props;
  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();
  const { onClose, open, handleSubmit } = props;
  const { submitting, pristine, invalid } = props;
  const { userId } = props;

  const [errorMessage, setErrorMessage] = useState('');
  const [showPassword, setShowPassword] = useState(false);

  /**
   * Toggle showPassword when user click the show passwrod button
   */
  const handleClickShowPassword = () => setShowPassword(!showPassword);

  const handleClose = () => {
    dispatch(reset(formName));
    onClose();
    setErrorMessage('');
  };

  const handleChangePassword = async (formValues) => {
    const { oldPassword, newPassword } = formValues;
    const payload = await dispatch(userOperations.changeUserPassword(userId, oldPassword, newPassword));
    const errorMessageInResponse = R.view(
      R.lensPath(['payload', 'value', 'error', 'message']),
      payload,
    );
    if (payload.type === 'UPDATE_PASSWORD_SUCCESS') {
      setErrorMessage('');
      handleClose();
      onChangePasswordSuccess();
    } else if (errorMessageInResponse) {
      // If error is Service Unavailable or GraphQL Validation Failed error then error object
      // will be different. And so in that case 'errorMessageInResponse' will have falsy value.
      // And for Service Unavailable or GraphQL Validation Failed error {@link ErrorAlert} will be
      // displayed only. For these errors we must not show the following error message.
      setErrorMessage(t('Update password failed'));
    }
  };

  if (open) {
    return (
      <Dialog onClose={handleClose} open={open} fullWidth>
        <ErrorAlert />
        <form onSubmit={handleSubmit(handleChangePassword)}>
          <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
            className={classes.changePasswordDialog}
          >
            <DialogTitle id="simple-dialog-title" className={classes.dialogTitle}>{t('Change password')}</DialogTitle>
            <ErrorMessage errorMessage={errorMessage} />
            <PasswordField
              handleClickShowPassword={handleClickShowPassword}
              showPassword={showPassword}
              customClassName={classNames(classes.textField)}
              name="oldPassword"
              label={t('Old Password')}
            />
            <PasswordField
              handleClickShowPassword={handleClickShowPassword}
              showPassword={showPassword}
              customClassName={classNames(classes.textField)}
              name="newPassword"
              label={t('New Password')}
              validate={minLength8}
            />
            <RoundedButton
              variant="contained"
              color="primary"
              type="submit"
              disabled={submitting || pristine || invalid}
              className={classNames(classes.button)}
            >
              {t('Change password')}
            </RoundedButton>
          </Grid>
        </form>
      </Dialog>
    );
  }
  return null;
};

UserPasswordChange.propTypes = {
  /**
   * Close handle function
   */
  onClose: PropTypes.func.isRequired,
  /**
   * Show dialog if true, hide if false
   */
  open: PropTypes.bool.isRequired,
  /**
   * Submit function
   */
  handleSubmit: PropTypes.func.isRequired,
  /**
   * Is redux form submitting
   */
  submitting: PropTypes.bool.isRequired,
  /**
   * Redux form props
   */
  pristine: PropTypes.bool.isRequired,
  /**
   * Redux form props
   */
  invalid: PropTypes.bool.isRequired,
  /**
   * Userid to query on server
   */
  userId: PropTypes.string.isRequired,
  /**
   * callback when change password successfully
   */
  onChangePasswordSuccess: PropTypes.func.isRequired,
};

UserPasswordChange.whyDidYouRender = true;

export default reduxForm({
  form: formName,
  validate: validateChangePassword,
})(UserPasswordChange);
