import { combineReducers } from 'redux';

import {
  createReducer,
  mergeStateAndPayload,
} from '../store_utils';

import * as types from './types';

const orderIS = {
  orderId: '',
  dateCreated: '',
  dateUpdated: '',
  payment: {
    paymentMethod: '',
    dateCreated: '',
    dateUpdated: '',
    amount: 0,
    currency: 'JPY',
    stripe: {
      stripePaymentMethodId: '',
      orderId: '',
      stripeSubscriptionId: '',
      stripePriceId: '',
    },
  },
  nodes: [],
};

const orderRUpdaters = {
  [types.CREATE_ORDER_SUCCESS]: (
    state,
    payload,
  ) => mergeStateAndPayload(state, payload),
};

const orderR = createReducer(orderIS, orderRUpdaters);

const reducers = combineReducers({
  order: orderR,
});

export default reducers;
