// query
import {
  createQuery,
  createMutation,
} from '../../../modules/apollo/apollo';

import {
  signinMutation,
  signupMutation,
  signOutMutation,
  activateUserMutation,
  validateEmailMutation,
  validatePasswordMutation,
  changeUserEmailMutation,
  changeUserPasswordMutation,
  sendForgotPasswordEmailMutation,
  resetPasswordMutation,
  validateForgotPasswordMutation,
  resendActivationMutation,
  createFirstSigninMutation,
  setUserFirstSignedInMutation,
} from './mutations';
import {
  getUserByIdQuery,
  getActivationQuery,
  getFirstSigninQuery,
  getFirstSigninsQuery,
} from './queries';
// mutation
// apollo

export const graphqlGetUserById = createQuery(getUserByIdQuery);
export const graphqlGetActivation = createQuery(getActivationQuery);
export const graphqlGetFirstSignin = createQuery(getFirstSigninQuery);
export const graphqlGetFirstSignins = createQuery(getFirstSigninsQuery);

export const graphqlSignin = createMutation(signinMutation);
export const graphqlSignup = createMutation(signupMutation);
export const graphqlSignOut = createMutation(signOutMutation);
export const graphqlActivateUser = createMutation(activateUserMutation);
export const graphqlValidateEmail = createMutation(validateEmailMutation);
export const graphqlValidatePassword = createMutation(validatePasswordMutation);
export const graphqlChangeUserEmail = createMutation(changeUserEmailMutation);
export const graphqlChangeUserPassword = createMutation(changeUserPasswordMutation);
export const graphqlSendForgotPasswordEmail = createMutation(sendForgotPasswordEmailMutation);
export const graphqlResetPassword = createMutation(resetPasswordMutation);
export const graphqlValidateForgotPassword = createMutation(validateForgotPasswordMutation);
export const graphqlResendActivation = createMutation(resendActivationMutation);
export const graphqlCreateFirstSignin = createMutation(createFirstSigninMutation);
export const graphqlSetUserFirstSignedIn = createMutation(setUserFirstSignedInMutation);
