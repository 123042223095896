import toLower from 'lodash/toLower';
import * as R from 'ramda';

export const formatCreateCryptoAddressData = R.curry(
  (data) => {
    const { chain, subChain } = data;
    const lowerCaseChain = toLower(chain);
    return subChain
      ? {
        chain: lowerCaseChain,
        [toLower(subChain)]: {
          accounts: [data],
        },
      }
      : {
        accounts: data,
        chain: lowerCaseChain,
      };
  },
);

export const formatGetCryptoAddressesData = R.curry(
  (chain, subChain, res) => {
    const { items, meta } = res;
    const lowerCaseChain = toLower(chain);

    const data = subChain
      ? {
        chain: lowerCaseChain,
        [toLower(subChain)]: {
          accounts: items,
        },
      }
      : {
        accounts: items,
        chain: lowerCaseChain,
      };
    return {
      items: data,
      meta,
    };
  },
);
