import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

// this component
import {
  officialEthereum2DepositContractUrl,
  depositDocsUrl,
} from './constants';
import { stylesDescription } from './styles';

const useStyles = makeStyles(stylesDescription);

// i18next-extract-disable
const ETH2MyNodeInfoAccountNotDeposit = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const {
    depositAmount,
    currency,
    status,
  } = props;
  const content = {
    PENDING_DEPOSIT: (
      <Box className={classNames(classes.descriptionContent)}>
        <Grid container direction="column" alignItems="center" spacing={2}>
          <Grid item>
            <Typography variant="h6" align="center">{t('Become a Validator')}</Typography>
          </Grid>
          <Grid item>
            <Typography variant="body1" align="center">
              {t('Deposit')}
              {' '}
              {`${depositAmount} ${currency} ${t('to')}`}
              {' '}
              <Link href={officialEthereum2DepositContractUrl} target="_blank">
                {t('Official Ethereum 2.0 Deposit Contract')}
              </Link>
              {' '}
              {t('to activate your validator node. Follow instruction')}
              {' '}
              <Link href={depositDocsUrl} target="_blank">
                {t('here')}
              </Link>
            </Typography>
          </Grid>
        </Grid>
      </Box>
    ),
    CONFIRMING_DEPOSIT: (
      <Box className={classNames(classes.descriptionContent)}>
        <Typography variant="body1" align="center">
          {t('Please wait for your deposit transaction to confirm')}
        </Typography>
      </Box>

    ),
  };
  const getContent = (status) => content[status];
  return getContent(status);
};

ETH2MyNodeInfoAccountNotDeposit.defaultProps = {
  depositAmount: '',
  currency: '',
  onClickConnectToWallet: () => {},
};

ETH2MyNodeInfoAccountNotDeposit.propTypes = {
  depositAmount: PropTypes.string,
  currency: PropTypes.string,
  onClickConnectToWallet: PropTypes.func,
};

export default ETH2MyNodeInfoAccountNotDeposit;
