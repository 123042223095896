import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import whyDidYouRender from '@welldone-software/why-did-you-render';
import classNames from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { stylesDescription } from './styles';

const useStyles = makeStyles(stylesDescription);
if (process.env.REACT_APP_ENV !== 'prod') {
  whyDidYouRender(React);
}
const UnUniFiMyNodeInfoAccount = () => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <Box className={classNames(classes.descriptionContent)}>
      <Typography
        variant="body1"
        align="center"
        color="primary"
        className={classNames(classes.description)}
      >
        {/* {t('Data will be shown after your node is deployed')} */}
        {t('coming soon')}
      </Typography>
    </Box>
  );
};

UnUniFiMyNodeInfoAccount.defaultProps = {
};

UnUniFiMyNodeInfoAccount.propTypes = {
};
UnUniFiMyNodeInfoAccount.whyDidYouRender = true;

export default React.memo(UnUniFiMyNodeInfoAccount);
