import gql from 'graphql-tag';

export const getAffiliateQuery = gql`
  query getAffiliate($getAffiliateInput: GetAffiliateInput!)
  {
    getAffiliate(
      getAffiliateInput: $getAffiliateInput
    ) {
      affiliateId
      datePaid
      dateUpdated
      currency
      total
      paid
      unpaid
      visitors
      leads
      conversions
      metadata
    }
  }
`;
