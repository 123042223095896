import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Skeleton from '@material-ui/lab/Skeleton';
import toNumber from 'lodash/fp/toNumber';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import {
  useHistory,
} from 'react-router-dom';

import { investmentBaseRoute } from '../../../routes';
import { convertToCryptoNumber } from '../../utils/utils';
import OverviewItem from '../OverviewItem';

// import { getFiatContent } from '../utils';

import { styles } from '../styles';

const useStyles = makeStyles(styles);

const OverviewBUSDItem = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const history = useHistory();
  const {
    showTitles,
    empty,
    totalEarningInCoin,
    totalEarning,
    totalBalanceInCoin,
    totalBalance,
  } = props;

  const {
    getOrdersLoading,
    getIntervalFixedEarningLoading,
  } = useSelector((state) => state.loadingR);

  const redirectToBUSDPage = () => {
    history.push({
      pathname: `${investmentBaseRoute}/busd`,
    });
  };

  if (!getOrdersLoading) {
    if (empty) {
      return null;
    }
  }

  const getEarningContent = (value, valueInCoin, isLoading) => {
    if (isLoading) {
      return (
        <>
          <Skeleton
            variant="text"
            width="100%"
            height="100%"
          />
          <Skeleton
            variant="text"
            width="100%"
            height="100%"
          />
        </>
      );
    }
    return (
      <>
        <Typography variant="body1" className={classes.nodeDescriptionContent}>
          {`${convertToCryptoNumber(toNumber(valueInCoin))} BUSD`}
        </Typography>
        {/* <Typography variant="body2" color="textSecondary" className={classes.nodeDescriptionSubContent}>
          {`≈${getFiatContent(value, isLoading)}`}
        </Typography> */}
      </>
    );
  };

  const isLoading = getIntervalFixedEarningLoading;
  const formatedData = {
    image: '/img/icon/busd.png',
    name: t('BUSD (Auto compound)'),
    apy: '15%',
    earning: getEarningContent(totalEarning, totalEarningInCoin, isLoading),
    balance: getEarningContent(totalBalance, totalBalanceInCoin, isLoading),
    amount: getEarningContent(totalBalance - totalEarning, totalBalanceInCoin - totalEarningInCoin, isLoading),
  };
  return (
    <Grid item xs={12}>
      <OverviewItem
        type="busd"
        data={formatedData}
        showTitles={showTitles}
        onClick={redirectToBUSDPage}
        loading={getOrdersLoading}
      />
    </Grid>
  );
};

OverviewBUSDItem.propTypes = {
  showTitles: PropTypes.bool,
  empty: PropTypes.bool,
  totalEarning: PropTypes.number,
  totalBalance: PropTypes.number,
  totalEarningInCoin: PropTypes.number,
  totalBalanceInCoin: PropTypes.number,
};

OverviewBUSDItem.defaultProps = {
  empty: false,
  showTitles: false,
  totalEarning: 0,
  totalBalance: 0,
  totalEarningInCoin: 0,
  totalBalanceInCoin: 0,
};

export default React.memo(OverviewBUSDItem);
