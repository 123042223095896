import * as R from 'ramda';

import { errorOperations } from '../error/index';
import { loadingOperations } from '../loading/index';

import * as actions from './actions';
import { errorMessageConverterObj } from './constants';

export const createOrder = R.curry(
  async (
    userId,
    quantity,
    paymentDetail,
    detail,
    orderType,
    dispatch,
  ) => {
    try {
      dispatch(loadingOperations.setLoading('createOrder', true));
      const { type, payload } = dispatch(
        await actions.createOrder(
          userId,
          quantity,
          paymentDetail,
          detail,
          orderType,
        ),
      );
      dispatch(loadingOperations.setLoading('createOrder', false));
      dispatch(errorOperations.clearError('createOrder'));
      return { type, payload };
    } catch (error) {
      dispatch(loadingOperations.setLoading('createOrder', false));
      return dispatch(errorOperations.setError(
        'createOrder',
        { error, errorMessageConverterObj },
      ));
    }
  },
);

export const getOrders = R.curry(
  async (
    userId,
    orderType,
    dispatch,
  ) => {
    try {
      dispatch(loadingOperations.setLoading('getOrders', true));
      const { type, payload } = dispatch(
        await actions.getOrders(
          userId,
          orderType,
        ),
      );
      dispatch(loadingOperations.setLoading('getOrders', false));
      dispatch(errorOperations.clearError('getOrders'));
      return { type, payload };
    } catch (error) {
      dispatch(loadingOperations.setLoading('getOrders', false));
      return dispatch(errorOperations.setError(
        'getOrders',
        { error, errorMessageConverterObj },
      ));
    }
  },
);
