import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import Alert from '../MaterialUiCustom/Alert/Alert';
import SearchBar from '../SearchBar/SearchBar';
import CustomTabs from '../Tabs/CustomTabs';

import MarketTabPanel from './MarketTabPanel';

// others

const { REACT_APP_DISABLE_ETH2_ORDER } = process.env;
const Market = () => {
  const { t } = useTranslation();
  const [searchString, setSearchString] = useState('');

  const tabs = [
    {
      label: t('Node as a Service'),
      content: <MarketTabPanel searchString={searchString} />,
      mainTab: true,
    },
  ];

  const onSearch = (str) => {
    setSearchString(str);
  };

  return (
    <>
      {REACT_APP_DISABLE_ETH2_ORDER === 'ON' && (
        <Grid item xs={12}>
          <Alert
            severity="warning"
          >
            <Typography variant="body2" color="inherit">
              {t('Eth2 validator deployment is currently unavailable.')}
            </Typography>
          </Alert>
        </Grid>
      )}
      <CustomTabs
        tabs={tabs}
        extraComponent={(
          <SearchBar
            onSearch={onSearch}
            placeholder={t('Search Protocol')}
          />
        )}
        belowTabsComponent={(
          <Typography variant="h3" color="textSecondary">
            {t('Deploy your node in a few clicks, pay as you go')}
          </Typography>
        )}
      />
    </>
  );
};

export default Market;
