import SvgIcon from '@material-ui/core/SvgIcon';
import * as React from 'react';

const ReferralIconSvg = (props) => {
  const { ...muiSvgIconProps } = props;
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      width={22.5}
      height={19.5}
      viewBox="0 0 22.5 19.5"
      {...muiSvgIconProps}
    >
      <g
        data-name="Icon feather-user-plus"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
      >
        <path
          data-name="Path 9265"
          d="M15.068 18.75v-2a3.912 3.912 0 00-3.818-4H4.568a3.912 3.912 0 00-3.818 4v2"
        />
        <path
          data-name="Path 9266"
          d="M11.727 4.75a3.912 3.912 0 01-3.818 4 3.912 3.912 0 01-3.818-4 3.912 3.912 0 013.818-4 3.912 3.912 0 013.818 4z"
        />
        <path data-name="Path 9267" d="M18.886 5.75v6" />
        <path data-name="Path 9268" d="M21.75 8.75h-5.727" />
      </g>
    </SvgIcon>
  );
};

ReferralIconSvg.defaultProps = {
};

ReferralIconSvg.propTypes = {
};

export default ReferralIconSvg;
