import * as R from 'ramda';
import isEmail from 'validator/lib/isEmail';

import { userOperations, userTypes } from '../../../store/user/index';

export const validateCreateUser = R.curry(async (t, dispatch, data, values) => {
  const errors = {};
  const { email } = values;

  if (email !== '') {
    const isExistedInForm = R.pipe(
      R.values,
      R.filter(R.propEq('email', email)),
      (emails) => R.length(emails) > 1,
    )(data);

    if (isExistedInForm) {
      errors.email = t('Invalid email. Email has been existed in this form.');
    } else {
      const response = await dispatch(userOperations.validateEmail(email));
      if (response.type !== userTypes.VALIDATE_EMAIL_SUCCESS) {
        const { message } = response.payload.value;
        errors.email = message;
      }
      if (!isEmail(email)) {
        errors.email = t('Invalid email');
      }
    }
  } else {
    errors.email = t('Required');
  }

  return errors;
});
