import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import CreditCardOutlinedIcon from '@material-ui/icons/CreditCardOutlined';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { basePath } from './constants';
import { stripeCardStyles } from './styles';

const useStyles = makeStyles(stripeCardStyles);
// TODO add amex and diners
const supportedCards = ['discover', 'jcb', 'mastercard', 'unionpay', 'visa'];
const PaymentStripeCard = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const {
    card,
    stripePaymentMethodId,
    selectedStripePaymentMethodId,
    handleSelectStripePaymentMethod,
  } = props;
  const {
    stripeCard,
    selectedStripeCard,
  } = classes;
  const [classNameToUse, setClassNameToUse] = React.useState(stripeCard);
  const {
    last4,
  } = card;
  const getCardIconPath = R.curry(
    (basePath, card) => {
      const { brand } = card;
      return `${basePath}/${brand}.png`;
    },
  );

  const getCardIcon = R.curry((basePath, card) => {
    const { brand } = card;
    const supported = R.includes(brand, supportedCards);
    return supported ? (
      <img
        src={getCardIconPath(basePath, card)}
        alt={`${brand} ${t('card')} ${last4}`}
        className={classes.cardIcon}
      />
    )
      : (
        <CreditCardOutlinedIcon />
      );
  });

  const shouldUseSelectedClass = R.and(
    selectedStripePaymentMethodId === stripePaymentMethodId,
    selectedStripePaymentMethodId,
  );

  React.useEffect(() => {
    if (shouldUseSelectedClass) {
      setClassNameToUse(selectedStripeCard);
    } else {
      setClassNameToUse(stripeCard);
    }
  }, [selectedStripeCard, shouldUseSelectedClass, stripeCard]);
  return (
    <Paper
      variant="outlined"
      className={classNameToUse || classes.cardPaper}
      onClick={() => handleSelectStripePaymentMethod(stripePaymentMethodId)}
    >
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        width={300}
      >
        <Box>
          {getCardIcon(basePath, card)}
        </Box>
        <Box>
          <Typography
            variant="body1"
          >
            {`**** **** **** ${last4}`}
          </Typography>
        </Box>
      </Box>
    </Paper>

  );
};

PaymentStripeCard.defaultProps = {
};

PaymentStripeCard.propTypes = {
  card: PropTypes.shape({
    brand: PropTypes.string,
    last4: PropTypes.string,
  }).isRequired,
  stripePaymentMethodId: PropTypes.string.isRequired,
  selectedStripePaymentMethodId: PropTypes.string.isRequired,
  handleSelectStripePaymentMethod: PropTypes.func.isRequired,
};

export default PaymentStripeCard;
