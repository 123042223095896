import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import Alert from '../../MaterialUiCustom/Alert/Alert';

import MiningDevices from './MiningDevices';
import MiningOrderHistory from './MiningOrderHistory';
import MiningOverview from './MiningOverview';
import MiningStatistics from './MiningStatistics';

const { REACT_APP_PHALA_MAINTENANCE, REACT_APP_PHALA_USERID_NOT_PAY_HOSTING_FEE } = process.env;
const MiningContent = ({ nodeType, machineType }) => {
  const { t } = useTranslation();
  const userId = useSelector((state) => state.userR.user.userId);
  const { mining6CoreOrders, mining4CoreOrders } = useSelector((state) => state.nodeOwnerR.nodeOwnerOrder.mining);
  const handleOrders = (machineType) => {
    if (machineType === 'SIX_CORE') {
      return mining6CoreOrders;
    }
    if (machineType === 'FOUR_CORE') {
      return mining4CoreOrders;
    }
    throw new Error('Mining orders not found');
  };
  const orders = handleOrders(machineType);
  const notPayUserIds = REACT_APP_PHALA_USERID_NOT_PAY_HOSTING_FEE ? R.split(',', REACT_APP_PHALA_USERID_NOT_PAY_HOSTING_FEE) : [];
  const isNotPayUserId = R.includes(userId, notPayUserIds);

  return (
    <Grid container justifyContent="center" spacing={4}>
      {isNotPayUserId && (
        <Grid item xs={12}>
          <Alert
            severity="warning"
          >
            <Typography variant="body2" color="inherit">
              Zofukuデータセンターホスティング料金について、お客様の入金が確認できておりません。メールをご確認ください。
              {/* {t('Phala earning statistic is under maintenance. Your earnings are safe.')} */}
              {/* {t('Due to massive updates on the Phala blockchain, the displayed rollup earnings might not be up-to-date. The corrected data will be displayed as soon as possible. Your actual earnings should not be affected so please be reassured.')} */}
            </Typography>
          </Alert>
        </Grid>
      )}
      {REACT_APP_PHALA_MAINTENANCE === 'ON' && (
      <Grid item xs={12}>
        <Alert
          severity="warning"
        >
          <Typography variant="body2" color="inherit">
            {/* {t('Phala earning statistic is under maintenance. Your earnings are safe.')} */}
            {/* {t('Due to massive updates on the Phala blockchain, the displayed rollup earnings might not be up-to-date. The corrected data will be displayed as soon as possible. Your actual earnings should not be affected so please be reassured.')} */}
            {t('Due to the network upgrade of Dec 16th 2022, the mining rewards have changed from PHA to wPHA. We are adding new features to update our dashboard. In the meanwhile, you can check your balance using 3rd party explorers. Your funds are safe and the rewards have already been sent to your wallet.')}
          </Typography>
        </Alert>
      </Grid>
      )}
      <Grid item xs={12}>
        <MiningOverview orders={orders} nodeType={nodeType} machineType={machineType} />
      </Grid>
      <Grid item xs={12}>
        <MiningDevices orders={orders} />
      </Grid>
      <Grid item xs={12}>
        <MiningStatistics />
      </Grid>
      <Grid item xs={12}>
        <MiningOrderHistory orders={orders} />
      </Grid>
    </Grid>
  );
};

MiningContent.propTypes = {
  machineType: PropTypes.oneOf(['FOUR_CORE', 'SIX_CORE']).isRequired,
  nodeType: PropTypes.oneOf(['PHALA_MINING_NODE', 'PHALA_MINING_NODE_4_CORES']).isRequired,
};

export default MiningContent;
