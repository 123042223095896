export const GET_USER_NODES_SUCCESS = 'GET_USER_NODES_SUCCESS';
export const GET_USER_NODE_SUCCESS = 'GET_USER_NODE_SUCCESS';
export const SAVE_NODE_DEPLOY_INPUTS = 'SAVE_NODE_DEPLOY_INPUTS';
export const FORMAT_SUB_NODE_DATA = 'FORMAT_SUB_NODE_DATA';
export const GET_USER_NODE_EARNING_SUCCESS = 'GET_USER_NODE_EARNING_SUCCESS';
export const UPDATE_USER_NODE_SUCCESS = 'UPDATE_USER_NODE_SUCCESS';
export const UPDATE_USER_NODES_SUCCESS = 'UPDATE_USER_NODES_SUCCESS';
export const GET_NODES_ESTIMATE_ACTIVATION_DATE_SUCCESS = 'GET_NODES_ESTIMATE_ACTIVATION_DATE_SUCCESS';
export const GET_NODES_ON_CHAIN_STATUS_SUCCESS = 'GET_NODES_ON_CHAIN_STATUS_SUCCESS';
export const GET_NODES_TOTAL_EARNING_SUCCESS = 'GET_NODES_TOTAL_EARNING_SUCCESS';
export const GET_NODES_TOTAL_EARNING_INFLUXDB_SUCCESS = 'GET_NODES_TOTAL_EARNING_INFLUXDB_SUCCESS';
export const GET_NODES_TOTAL_EARNING_IN_PERIOD_SUCCESS = 'GET_NODES_TOTAL_EARNING_IN_PERIOD_SUCCESS';
export const GET_NODES_INTERVAL_EARNING_SUCCESS = 'GET_NODES_INTERVAL_EARNING_SUCCESS';
export const GET_EARNING_HISTORY_SUCCESS = 'GET_EARNING_HISTORY_SUCCESS';
export const GET_COIN_PRICE_SUCCESS = 'GET_COIN_PRICE_SUCCESS';
export const GET_COIN_PRICE_CHART_SUCCESS = 'GET_COIN_PRICE_CHART_SUCCESS';
export const GET_ZEN_BALANCE_SUCCESS = 'GET_ZEN_BALANCE_SUCCESS';
export const GET_INTERVAL_FIXED_EARNING_SUCCESS = 'GET_INTERVAL_FIXED_EARNING_SUCCESS';
export const GET_TOTAL_FIXED_EARNING_SUCCESS = 'GET_TOTAL_FIXED_EARNING_SUCCESS';
export const GET_NODE_OWNER_TOTAL_EARNING_SUCCESS = 'GET_NODE_OWNER_TOTAL_EARNING_SUCCESS';
