import { makeStyles } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Dialog from '@material-ui/core/Dialog';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import whyDidYouRender from '@welldone-software/why-did-you-render';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';

import {
  useIsMobile,
  useAddFunctionToRef,
} from '../../../hooks/hooks';
import { adminNodeOperations } from '../../../store/admin/node/index';
import { RoundedButton, CloseIconButton } from '../../MaterialUiCustom/MaterialUiCustom';
import ReactTableV7 from '../../Tables/ReactTableV7';

// store

import { firstSigninColumns } from '../columns';
import { createUsersStyles } from '../styles';
// columns

if (process.env.REACT_APP_ENV !== 'prod') {
  whyDidYouRender(React);
}

const useStyles = makeStyles(createUsersStyles);

const FirstSignins = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const isMobileDevice = useIsMobile();
  const { t } = useTranslation();

  const {
    data,
    onClose,
    open,
    onSendFirstSigninEmails,
  } = props;
  const [selectedList, setSelectedList] = useState(R.map(R.prop('email'), data));

  const allNodes = useSelector((state) => R.view(R.lensPath(['adminR', 'adminNode', 'nodeList', 'nodeList', 'entities', 'nodes']), state)) || [];

  const onGetUserNodes = useAddFunctionToRef(async () => {
    await dispatch(adminNodeOperations.getNodes({ query: [{ nodeType: 'UNUNIFI_VALIDATOR_NODE' }] }));
  });
  useEffect(() => {
    onGetUserNodes();
  }, [onGetUserNodes]);

  const firstSignins = R.pipe(
    R.map(
      (firstSignin) => {
        const { userId } = firstSignin;

        const hasUnUniFi = !!R.find((node) => R.propEq('nodeType', 'UNUNIFI_VALIDATOR_NODE', node) && R.propEq('user', userId, node), R.values(allNodes));
        return {
          ...firstSignin,
          hasUnUniFi,
          orderType: hasUnUniFi ? 'UnUniFi' : 'UNUSED',
          selected: selectedList.includes(R.prop('email', firstSignin)),
        };
      },
    ),
    R.sort(R.descend(R.prop('dateCreated'))),
  )(data);

  const sendFirstSigninEmailsLoading = useSelector((state) => state.loadingR.sendFirstSigninEmailsLoading);

  const handleOnClose = () => {
    onClose();
  };
  const handleSendFirstSigninEmails = async (firstSignins) => {
    const firstSigninsToSendEmails = R.pipe(
      R.filter(R.propEq('selected', true)),
      R.map(R.pick(['firstSigninId', 'userId', 'email', 'orderType'])),
    )(firstSignins);
    await onSendFirstSigninEmails(firstSigninsToSendEmails);
  };

  const isSelectedAll = firstSignins.length === selectedList.length;
  const unnSelectAll = () => setSelectedList([]);
  const onSelectAll = () => {
    if (isSelectedAll) {
      unnSelectAll();
    } else {
      R.pipe(
        R.map(R.prop('email')),
        setSelectedList,
      )(data);
    }
  };
  const onSelect = (selected, email) => {
    R.pipe(
      selected ? R.append(email) : R.reject(R.equals(email)),
      setSelectedList,
    )(selectedList);
  };

  const columnsToUse = firstSigninColumns(t, onSelect, onSelectAll, isSelectedAll);

  return (
    <Dialog
      fullScreen={isMobileDevice}
      fullWidth
      maxWidth="md"
      onClose={handleOnClose}
      open={open}
    >
      <Typography variant="h2" className={classes.dialogTitleText}>
        {t('Users have first signin')}
      </Typography>
      <CloseIconButton
        onClick={handleOnClose}
        color="default"
        className={classes.closeButton}
      />
      <Paper className={classes.content}>
        <Box className={classes.table}>
          <ReactTableV7
            data={firstSignins}
            columns={columnsToUse}
            customPageSize={firstSignins.length}
            customPageSizeList={[firstSignins.length]}
          />
        </Box>
      </Paper>
      <Grid container justifyContent="center" className={classes.actionGrid}>
        <Grid item>
          <RoundedButton
            variant="contained"
            color="primary"
            loading={sendFirstSigninEmailsLoading}
            onClick={() => handleSendFirstSigninEmails(firstSignins)}
            disabled={isEmpty(selectedList)}
          >
            { t('send email')}
          </RoundedButton>
        </Grid>
        <Grid item>
          <RoundedButton
            variant="contained"
            color="secondary"
            onClick={handleOnClose}
          >
            { t('cancel')}
          </RoundedButton>
        </Grid>
      </Grid>
    </Dialog>
  );
};

FirstSignins.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      firstSigninId: PropTypes.string,
      userId: PropTypes.string,
      email: PropTypes.string,
      dateCreated: PropTypes.string,
      dateUpdated: PropTypes.string,
      signedIn: PropTypes.bool,
    }),
  ).isRequired,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  onSendFirstSigninEmails: PropTypes.func.isRequired,
};

FirstSignins.whyDidYouRender = true;
export default React.memo(FirstSignins);
