// query
import {
  createQuery,
  createMutation,
} from '../../../../modules/apollo/apollo';

import {
  updateOrderDataMutation,
  updateOrderTimelinesMutation,
  createOrdersMutation,
} from './mutations';
import {
  getOrdersQuery,
} from './queries';
// mutations
// apollo

export const graphqlGetOrders = createQuery(getOrdersQuery);

export const graphqlUpdateOrderData = createMutation(updateOrderDataMutation);
export const graphqlUpdateOrderTimelines = createMutation(updateOrderTimelinesMutation);
export const graphqlCreateOrders = createMutation(createOrdersMutation);
