import Grid from '@material-ui/core/Grid';
import toUpper from 'lodash/fp/toUpper';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import {
  useHistory,
} from 'react-router-dom';

// route
import { investmentBaseRoute } from '../../../routes';
import OverviewItem from '../OverviewItem';
import { getFiatContent } from '../utils';

const {
  REACT_APP_NODE_OWNER_USERIDS,
} = process.env;
const OverviewEth2Item = (props) => {
  const { t } = useTranslation();
  const history = useHistory();
  const {
    nodes,
    totalEarning,
    totalBalance,
    showTitles,
    userId,
  } = props;

  const subscriptions = useSelector((state) => state.paymentR.subscriptions.data);
  const {
    getUserNodesLoading,
    getTotalEarningLoading,
  } = useSelector((state) => state.loadingR);
  const coinPrice = useSelector((state) => state.nodeR.coinPrice);

  const redirectToETH2Page = () => {
    history.push({
      pathname: `${investmentBaseRoute}/eth2-validator`,
    });
  };

  if (!getUserNodesLoading) {
    if (!nodes || !nodes.length) {
      return null;
    }
  }

  const lastNode = R.last(nodes);
  const { dateCreated } = lastNode || {};

  const subscriptionsToUse = R.filter(
    (eachSubscription) => (
      R.prop('status', eachSubscription) !== 'canceled'
          && R.prop('status', eachSubscription) !== 'paused'
    ),
    subscriptions,
  );

  const { invoices, plan: subscriptionPlan } = subscriptionsToUse[0] || {};
  const priceData = R.view(R.lensPath([0, 'lines', 'data', 0, 'price']), invoices);
  const currency = R.prop('currency', priceData);
  const price = R.prop('unitAmount', priceData);
  const currencyToUse = currency ? toUpper(currency) : null;
  const period = R.prop('interval', subscriptionPlan);

  const getPlan = (userId) => {
    if (R.includes(userId, REACT_APP_NODE_OWNER_USERIDS)) {
      return 'Node owner';
    }
    const plan = subscriptionPlan ? `${price.toLocaleString('en-US', {
      style: 'currency',
      currency: currencyToUse,
    })}/ ${period}` : '-';
    return plan;
  };

  const plan = getPlan(userId);
  const isLoading = getTotalEarningLoading || !coinPrice.ETH;
  const formatedData = {
    image: '/img/icon/eth2.png',
    name: t('ETH2.0 Validator Node'),
    dateCreated,
    plan,
    quantity: nodes.length,
    earning: getFiatContent(totalEarning, isLoading),
    balance: getFiatContent(totalBalance, isLoading),
    staked: getFiatContent(totalBalance - totalEarning, isLoading),
  };
  return (
    <Grid item xs={12}>
      <OverviewItem
        type="naas"
        data={formatedData}
        showTitles={showTitles}
        onClick={redirectToETH2Page}
        loading={getUserNodesLoading}
      />
    </Grid>
  );
};

OverviewEth2Item.propTypes = {
  nodes: PropTypes.array,
  totalEarning: PropTypes.number,
  totalBalance: PropTypes.number,
  showTitles: PropTypes.bool,
  userId: PropTypes.string.isRequired,
};

OverviewEth2Item.defaultProps = {
  nodes: [],
  totalEarning: 0,
  totalBalance: 0,
  showTitles: false,
};

export default React.memo(OverviewEth2Item);
