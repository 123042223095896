import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import RadioGroup from '@material-ui/core/RadioGroup';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
// toastify
import { toast } from 'react-toastify';

import { paymentOperations } from '../../store/payment/index';
import { stripeOperations } from '../../store/stripe/index';
import { CHANGE_SUBSCRIPTION_PLAN_SUCCESS } from '../../store/stripe/types';
import VerifyPasswordDialog from '../VerifyPasswordDialog/VerifyPasswordDialog';

import MarketProtocolDeployPlanItem from './ChangeSubscriptionPlanItem';
import { paymentPeriodStyles } from './styles';
// store

const useStyles = makeStyles(paymentPeriodStyles);

// const paymentPeriod = 'month';
const ChangeSubscriptionPlanDialogContent = (props) => {
  const {
    pricing,
    paymentPeriod,
    setPaymentPeriod,
    quantity,
    subscriptionId,
    nodeType,
    onSuccessCallback,
    changeSubscriptionPlanLoading,
  } = props;
  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();
  const [openVerifyPassword, setOpenVerifyPassword] = React.useState(false);
  const [newPeriod, setNewPeriod] = React.useState(paymentPeriod);

  const handleVerifyPasswordClose = () => {
    setOpenVerifyPassword(false);
  };
  const handleVerifyPasswordOpen = (newPeriod) => {
    setOpenVerifyPassword(true);
    setNewPeriod(newPeriod);
  };

  const userR = useSelector((state) => state.userR);
  const { user } = userR;
  const { userId, email } = user;

  const {
    priceIds,
  } = useSelector((state) => state.stripeR.stripePrice);

  const onChangePeriod = async (newPeriod) => {
    const { stripePriceId } = priceIds[nodeType][newPeriod];

    const { type } = await dispatch(stripeOperations.changeSubscriptionPlan(
      subscriptionId, stripePriceId,
    ));
    if (type === CHANGE_SUBSCRIPTION_PLAN_SUCCESS) {
      setPaymentPeriod(newPeriod);
      toast.success(t('Subscription plan has been changed'), {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      onSuccessCallback();
      await dispatch(paymentOperations.getSubscriptions(userId));
    } else {
      toast.error(t('Change subscription plan failed'), {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  const getPlanList = () => R.map((plan) => {
    const {
      paymentPeriod: planPaymentPeriod, price, currency, discount,
    } = plan;

    return (
      <Grid
        item
        key={planPaymentPeriod}
        className={classes.planListItemGrid}
        xs={12}
        md={6}
      >
        <MarketProtocolDeployPlanItem
          paymentPeriod={planPaymentPeriod}
          label={`1-${planPaymentPeriod} plan`}
          specialDiscount={discount}
          total={price * quantity}
          currency={currency}
          disabled={changeSubscriptionPlanLoading}
        />
      </Grid>
    );
  }, pricing);
  const getPaymentPeriodContent = () => {
    if (changeSubscriptionPlanLoading) {
      return (
        <Box className={classes.loadingCircle}>
          <CircularProgress />
        </Box>
      );
    }

    return (
      <RadioGroup
        row
        aria-label="plan-select"
        name="plan-select"
        value={paymentPeriod}
        onChange={(event) => handleVerifyPasswordOpen(event.target.value)}
      >
        <Grid container spacing={2}>
          {getPlanList()}
        </Grid>
      </RadioGroup>
    );
  };
  return (
    <>
      {getPaymentPeriodContent()}
      {openVerifyPassword && (
        <VerifyPasswordDialog
          open={openVerifyPassword}
          onClose={handleVerifyPasswordClose}
          onSuccessCallback={() => onChangePeriod(newPeriod)}
          email={email}
        />
      )}
    </>
  );
};

ChangeSubscriptionPlanDialogContent.defaultProps = {
  changeSubscriptionPlanLoading: false,
};

ChangeSubscriptionPlanDialogContent.propTypes = {
  pricing: PropTypes.arrayOf(
    PropTypes.shape({
      paymentPeriod: PropTypes.string,
      price: PropTypes.number,
      currency: PropTypes.string,
      discount: PropTypes.number,
    }),
  ).isRequired,
  paymentPeriod: PropTypes.string.isRequired,
  setPaymentPeriod: PropTypes.func.isRequired,
  quantity: PropTypes.number.isRequired,
  subscriptionId: PropTypes.string.isRequired,
  nodeType: PropTypes.string.isRequired,
  onSuccessCallback: PropTypes.func.isRequired,
  changeSubscriptionPlanLoading: PropTypes.bool,
};

export default ChangeSubscriptionPlanDialogContent;
