import * as R from 'ramda';
import { combineReducers } from 'redux';
import { PURGE } from 'redux-persist';

import {
  createReducer,
  mergeStateAndPayload,
} from '../../store_utils';

import { formatNodeListPayload, formatMonthlyReportPayload } from './services';
import * as types from './types';

export const nodeListIS = {
  totalNodes: 0,
  nodeList: {},
};

const nodeListRUpdaters = {
  [types.GET_NODES_SUCCESS]: (state, payload) => (
    R.pipe(
      formatNodeListPayload,
      mergeStateAndPayload(state),
    )(payload)
  ),
  [PURGE]: R.always(nodeListIS),
};

const nodeListR = createReducer(nodeListIS, nodeListRUpdaters);

export const monthlyReportIS = {
};

const monthlyReportRUpdaters = {
  [types.GET_ADMIN_MONTHLY_REPORT_SUCCESS]: (state, payload) => (
    R.pipe(
      formatMonthlyReportPayload,
      mergeStateAndPayload(state),
    )(payload)
  ),
  [PURGE]: R.always(monthlyReportIS),
};

const monthlyReportR = createReducer(monthlyReportIS, monthlyReportRUpdaters);

export const monthlyReportEmailConfigIS = {
  data: [],
};

const monthlyReportEmailConfigRUpdaters = {
  [types.UPDATE_MONTHLY_REPORT_EMAIL_CONFIG]: mergeStateAndPayload,
  [PURGE]: R.always(monthlyReportEmailConfigIS),
};

const monthlyReportEmailConfigR = createReducer(
  monthlyReportEmailConfigIS, monthlyReportEmailConfigRUpdaters,
);

const adminNodeR = combineReducers({
  nodeList: nodeListR,
  monthlyReport: monthlyReportR,
  monthlyReportEmailConfig: monthlyReportEmailConfigR,
});

export default adminNodeR;
