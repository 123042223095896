import Box from '@material-ui/core/Box';
import InputBase from '@material-ui/core/InputBase';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import SearchIcon from '@material-ui/icons/Search';
import PropTypes from 'prop-types';
import React from 'react';

import { searchBarStyle } from './styles';

const useStyles = makeStyles(searchBarStyle);

const SearchBar = (props) => {
  const { onSearch, placeholder, inputBaseProps } = props;
  const classes = useStyles();

  const onChange = (event) => {
    onSearch(event.target.value);
  };

  return (
    <Paper className={classes.searchBarRoot}>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
      >
        <Box pt={0.5} pl={1} pr={1.5}>
          <SearchIcon className={classes.searchBarIcon} />
        </Box>
        <Box width="100%">
          <InputBase
          // className={classes.searchPaper}
            id="search-bar"
            placeholder={placeholder}
            variant="outlined"
            onChange={onChange}
            {...inputBaseProps}
          />
        </Box>
      </Box>
    </Paper>
  );
};

SearchBar.defaultProps = {
  onSearch: () => {},
  placeholder: '',
};

SearchBar.propTypes = {
  /**
   * Search callback
   */
  onSearch: PropTypes.func,
  placeholder: PropTypes.string,
  inputBaseProps: PropTypes.object,
};

SearchBar.defaultProps = {
  /**
   * Search callback
   */

  inputBaseProps: {},
};
export default SearchBar;
