import lowerCase from 'lodash/lowerCase';
import round from 'lodash/round';
import * as R from 'ramda';
import * as RA from 'ramda-adjunct';
import ReactGA from 'react-ga';

import { HttpStatusCodes } from './appConstants';

const noConversionCurrencies = ['jpy'];
const createCentToCurrency = R.curry(
  (noConversionCurrencies, currency, value) => {
    const shouldConvert = !R.includes(lowerCase(currency), noConversionCurrencies);
    return shouldConvert
      ? round(value / 100, 2)
      : value;
  },
);

export const selectUserDataToUse = R.curry((userR) => {
  const { user, admin } = userR;
  if (admin.email) {
    return {
      userId: admin.userId,
      email: admin.email,
    };
  }
  const { userId, email } = user;
  return { userId, email };
});

export const centToCurrency = createCentToCurrency(noConversionCurrencies);

export const formatParameterValidationError = R.curry((errorMessage) => ({
  message: errorMessage,
  extensions: {
    statusCode: [HttpStatusCodes.PARAMETER_VALIDATION_ERROR],
    status: ['ParameterValidationError'],
  },
}));

export const generateReactGaException = R.curry((error, fatal) => (
  ReactGA.exception({
    description: JSON.stringify(error),
    fatal,
  })
));
export const formatNormalizedItemListPayload = R.curry((stateKey, payload) => {
  const entities = R.view(R.lensProp('entities'), payload);
  const result = R.view(R.lensProp('result'), payload);
  const itemList = R.pipe(
    R.omit(['meta']),
    (result) => ({ result }),
    R.mergeLeft({ entities }),
  )(result);
  return {
    [stateKey]: itemList,
  };
});

export const checkEmptyObjectsEquality = R.curry((objA, objB) => {
  if (!objA && !objB) {
    return true;
  }

  if (!objA || !objB) {
    return false;
  }

  if (
    R.isEmpty(objA)
    && R.isEmpty(objB)
    && RA.isObj(objA)
    && RA.isObj(objB)
    && !RA.isArray(objA)
    && !RA.isArray(objB)
  ) {
    return true;
  }
  return false;
});

export const detectScrollbarWidth = () => {
  // thanks too https://davidwalsh.name/detect-scrollbar-width
  const scrollDiv = document.createElement('div');
  scrollDiv.setAttribute(
    'style', 'width: 100px; height: 100px; overflow: scroll; position:absolute; top:-9999px;',
  );
  document.body.appendChild(scrollDiv);
  const scrollbarWidth = scrollDiv.offsetWidth - scrollDiv.clientWidth;
  document.body.removeChild(scrollDiv);
  return scrollbarWidth;
};

export const formatGraphqlMockError = R.curry((error) => ([{
  message: String(R.view(R.lensPath([0, 'message']), error) || 'Internal server error'),
  extensions: {
    isGraphQLError: true,
    statusCode: HttpStatusCodes.BAD_REQUEST,
    status: 'BadRequestException',
  },
}]));
