import React from 'react';
import { useTranslation } from 'react-i18next';

import CustomTabs from '../Tabs/CustomTabs';

import PaymentPageContent from './PaymentPageContent';

const PaymentPage = (props) => {
  const { t } = useTranslation();

  const tabs = [
    {
      label: t('Payment'),
      content: <PaymentPageContent />,
      mainTab: true,
    },
  ];

  return (
    <CustomTabs
      tabs={tabs}
    />
  );
};

export default PaymentPage;
