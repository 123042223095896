import startCase from 'lodash/fp/startCase';
import * as R from 'ramda';

import {
  isGraphQlError,
  handleGraphQlError,
  isGraphQlValidationFailedError,
} from '../../modules/apollo/services';
import { HttpStatusCodes } from '../../modules/appConstants';
import {
  isAxiosError,
  isAxiosErrorDev,
  handleAxiosError,
} from '../../modules/axios_utils';
import { generateReactGaException } from '../../modules/utils';

import { generalErrorMessageConverterObj } from './constants';

export const isNetworkError = R.curry((error) => {
  const errorStatusCode = R.pipe(
    R.view(R.lensPath(['extensions', 'statusCode'])),
    (statusCodeList) => (statusCodeList ? R.head(statusCodeList) : null),
  )(error);

  return errorStatusCode === HttpStatusCodes.SERVICE_UNAVAILABLE;
});

export const isParameterValidationError = R.curry((error) => (
  R.pipe(
    R.view(R.lensPath(['extensions', 'statusCode', 0])),
    R.equals(HttpStatusCodes.PARAMETER_VALIDATION_ERROR),
  )(error)
));

export const isCustomError = R.curry((error) => (
  R.pipe(
    R.view(R.lensPath(['extensions', 'statusCode', 0])),
    R.equals(HttpStatusCodes.CUSTOM_ERROR),
  )(error)
));

export const isError = R.curry((status, message, error) => {
  const errorStatusCodeExists = R.pipe(
    R.view(R.lensPath(['error', 'extensions', 'statusCode'])),
    (statusCode) => (statusCode || []),
    R.includes(status),
  )(error);

  const errorStatusExists = R.pipe(
    R.view(R.lensPath(['error', 'extensions', 'status'])),
    (status) => (status || []),
    R.includes(message),
  )(error);

  const errorErrorExists = errorStatusCodeExists && errorStatusExists;
  errorErrorExists && generateReactGaException(error, false);
  return errorErrorExists;
});
export const isTCPReadFailedError = isError(HttpStatusCodes.TCP_READ_FAILED, 'TCP Read Failed');
export const isInfluxdbError = isError(HttpStatusCodes.INFLUXDB_ERROR, 'Influxdb Error');

export const isAPIReadFailedError = R.curry((error) => {
  const APIReadFailedStatusCodeExists = R.pipe(
    R.view(R.lensPath(['error', 'extensions', 'statusCode'])),
    (statusCode) => (statusCode || []),
    R.includes(HttpStatusCodes.API_READ_FAILED),
  )(error);

  const APIReadFailedStatusExists = R.pipe(
    R.view(R.lensPath(['error', 'extensions', 'status'])),
    (status) => (status || []),
    R.includes('API Read Failed'),
  )(error);

  const APIReadFailedErrorExists = APIReadFailedStatusCodeExists && APIReadFailedStatusExists;
  APIReadFailedErrorExists && generateReactGaException(error, false);
  return APIReadFailedErrorExists;
});

// TODO: Use a single method to detect GraphQl validation failed error, network error, parameter
// validation error, custom error.
export const handleError = R.curry(
  (error) => {
    if (isGraphQlError(error)) {
      const graphqlError = handleGraphQlError(error);
      generateReactGaException(graphqlError, false);
      return graphqlError;
    }

    if (isGraphQlValidationFailedError(error)) {
      generateReactGaException(error, true);
      return error;
    }

    if (isNetworkError(error)) {
      generateReactGaException(error, false);
      return error;
    }

    if (isParameterValidationError(error)) {
      generateReactGaException(error, true);
      return error;
    }

    if (isAxiosError(error) || isAxiosErrorDev(error)) {
      console.log('[AxiosError]: ', error);
      return handleAxiosError(error);
    }

    // TODO: Stop using error.custom
    if (error.custom || isCustomError(error)) {
      generateReactGaException(error, false);
      return error;
    }

    const clientInternalError = {
      message: error.message,
      extensions: {
        statusCode: [HttpStatusCodes.CLIENT_INTERNAL_ERROR],
        status: ['ClientInternalError'],
      },
    };
    generateReactGaException(clientInternalError, true);
    return clientInternalError;
  },
);

/**
 * Sometimes the actual error message should not be show to user. In that case
 * convert the error message to make it suitable for user.
 * @param  {Object}       generalErrorMessageConverter Generally if necessary each
 *                                                     operation has its own set of
 *                                                     error message converter. But
 *                                                     this error message converter
 *                                                     is applicable to all
 *                                                     operations
 * @param  {Object||null} errorMessageConverterObj     Error message converter for
 *                                                     the operation
 * @param  {string}       messageInError               Error message in given error object
 * @return {string}                                    Converted error message. If no
 *                                                     no conversion is necessary actual
 *                                                     error message.
 */
const createConvertErrorMessage = R.curry((
  generalErrorMessageConverter,
  errorMessageConverterObj,
  messageInError,
) => {
  const finalErrorMessageConverterObj = R.mergeLeft(
    generalErrorMessageConverterObj,
    errorMessageConverterObj,
  );
  const convertedErrorMessage = R.pipe(
    R.keys,
    R.find(
      (errorMessageConverterObjKey) => (
        R.includes(errorMessageConverterObjKey, messageInError)
      ),
    ),
    R.flip(R.prop)(finalErrorMessageConverterObj),
  )(finalErrorMessageConverterObj);

  // Note: ?? ==> Nullish coalescing operator
  return convertedErrorMessage ?? messageInError;
});

const convertErrorMessage = createConvertErrorMessage(generalErrorMessageConverterObj);

/**
 * Extract error data that is necessary to handle error message
 * @param  {Object}      error                    Error from which data will be extracted
 * @param  {Object|null} errorMessageConverterObj Error message converter for the operation
 * @return {Object}                               Necessary error data
 */
export const extractNecessaryErrorData = R.curry((error, errorMessageConverterObj) => {
  const messageInError = R.view(R.lensProp('message'), error);
  const errorStatusCode = R.pipe(
    R.view(R.lensPath(['extensions', 'statusCode'])),
    (statusCodeList) => (statusCodeList ? R.head(statusCodeList) : null),
  )(error);

  const convertedErrorMessage = convertErrorMessage(
    errorMessageConverterObj || {},
    messageInError,
  );
  return {
    errorStatusCode,
    convertedErrorMessage,
  };
});

export const handleErrorMessage = R.curry(
  (scope, { fallbackErrorMessage, error, errorMessageConverterObj }) => {
    const {
      errorStatusCode,
      convertedErrorMessage,
    } = extractNecessaryErrorData(error, errorMessageConverterObj);

    if (errorStatusCode === HttpStatusCodes.SERVICE_UNAVAILABLE) {
      return {
        message: 'Service unavailable',
        error,
      };
    }

    if (errorStatusCode === HttpStatusCodes.GRAPHQL_VALIDATION_FAILED) {
      return {
        message: 'Whoops! Something went wrong here',
        error,
      };
    }

    if (errorStatusCode === HttpStatusCodes.INTERNAL_SERVER_ERROR) {
      return {
        message: fallbackErrorMessage || `${startCase(scope)} Failed`,
        error,
      };
    }

    /* if (R.includes(': ', convertedErrorMessage)) {
      const index = 1;
      return {
        message: convertedErrorMessage.split(': ')[index],
        error,
      };
    } */

    return {
      message: convertedErrorMessage || fallbackErrorMessage || `${startCase(scope)} Failed`,
      error,
    };
  },
);

export const extractErrorMessage = R.curry(
  (error) => {
    if (!error) {
      return '';
    }
    // this message is provided along with error see setError
    const defaultMessage = error.message
      ? error.message
      : 'Sorry, something went wrong please contact admin';
    // error message from server
    const serverMessage = R.view(R.lensPath(['error', 'message']), error);
    return defaultMessage || serverMessage;
  },
);

/**
 * Decide and return error that should be rendered. If error should not be rendered return null
 * @param  {Object}      error Given error object
 * @return {Object|null}       Given error object if it should renderd. Return null if error
 *                             should not be rendered
 */
export const shouldSetErrorToRender = R.curry((errorAction) => {
  const errorMessageLens = R.lensPath(['payload', 'value', 'message']);
  const errorStatusCodeLens = R.lensPath(['payload', 'value', 'error', 'extensions', 'statusCode']);
  const errorMessage = R.view(errorMessageLens, errorAction);
  const errorStatusCode = R.pipe(
    R.view(errorStatusCodeLens),
    (statusCodeList) => (statusCodeList ? R.head(statusCodeList) : null),
  )(errorAction);
  const errorStatusCodesToBeRendered = [
    HttpStatusCodes.CLIENT_INTERNAL_ERROR,
    HttpStatusCodes.SERVICE_UNAVAILABLE,
    HttpStatusCodes.GRAPHQL_VALIDATION_FAILED,
  ];

  if (R.includes(errorStatusCode, errorStatusCodesToBeRendered)) {
    return {
      message: errorMessage,
      statusCode: errorStatusCode,
    };
  }
  return null;
});

/**
 * Throw an error with given error messsage if the error page should be rendered
 * @param  {Object} errorToRender Error to render
 * @throws                        Always throws an error with given error message
 */
export const shouldRenderErrorPage = R.curry((errorToRender) => {
  const errorToRenderMessage = R.view(R.lensProp('message'), errorToRender);
  const errorToRenderStatusCode = R.view(R.lensProp('statusCode'), errorToRender);
  if (errorToRenderStatusCode === HttpStatusCodes.CLIENT_INTERNAL_ERROR) {
    throw new Error(errorToRenderMessage);
  }
});

/**
 * Decide whether the error alert should be rendered
 * @param  {Object} errorToRender  Given error object
 * @return {bool}                  Decision whether the error alert should be rendered or not
 */
export const shouldRenderErrorAlert = R.curry((errorToRender) => {
  const errorStatusCodesThatShouldBeAlerted = [
    HttpStatusCodes.SERVICE_UNAVAILABLE,
    HttpStatusCodes.GRAPHQL_VALIDATION_FAILED,
  ];

  const errorToRenderStatusCode = R.view(R.lensProp('statusCode'), errorToRender);
  return R.includes(errorToRenderStatusCode, errorStatusCodesThatShouldBeAlerted);
});
