import Box from '@material-ui/core/Box';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';
import { isFunction } from 'lodash';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import useDeepCompareEffect from 'use-deep-compare-effect';

import { useAddFunctionToRef } from '../../hooks/hooks';
import Coupon from '../Coupon/Coupon';
import { CloseIconButton, RoundedButton } from '../MaterialUiCustom/MaterialUiCustom';
import VerifyPasswordDialog from '../VerifyPasswordDialog/VerifyPasswordDialog';

import PaymentStripeCard from './PaymentStripeCard';
import PaymentStripeCheckoutForm from './PaymentStripeCheckoutForm';
import { stripeCardListStyles } from './styles';

// hook

const useStyles = makeStyles(stripeCardListStyles);
// only support card for now
const paymentMethodType = 'card';
const PaymentStripeCardList = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const {
    paymentMethods,
    onSelectStripePaymentMethod,
    onGetUserStripePaymentMethod,
    onDetachStripePaymentMethod,
    setReGet,
    enableCoupon,
  } = props;
  const { userId, email } = useSelector((state) => state.userR.user);
  const { stripeCustomer } = useSelector((state) => state.stripeR);
  const {
    stripeCustomerId,
  } = stripeCustomer;

  const [selectedStripePaymentMethodId, setStripeSelectedPaymentMethod] = React.useState('');
  const [openAddCard, setOpenAddCard] = React.useState(false);
  const [openVerifyPassword, setOpenVerifyPassword] = React.useState(false);
  const [paymentMethodToDetach, setPaymentMethodToDetach] = React.useState('');

  const handleOpenAddCard = () => {
    setOpenAddCard(true);
  };
  const handleCloseAddCard = () => {
    setOpenAddCard(false);
  };

  const handleOnSubmitExtra = async () => {
    await onGetUserStripePaymentMethod(userId, paymentMethodType);
    handleCloseAddCard(false);
  };
  const handleSelectStripePaymentMethod = useAddFunctionToRef(
    async (stripePaymentMethodId) => {
      setStripeSelectedPaymentMethod(stripePaymentMethodId);
      // set selectedStripePaymentMethodId in higher component
      if (isFunction(onSelectStripePaymentMethod)) {
        await onSelectStripePaymentMethod(stripePaymentMethodId);
      }
    },
  );
  /**
   * Close change password
   */
  const onCloseVerifyPassword = () => {
    setOpenVerifyPassword(false);
  };

  /**
   * Open change password
   */
  const showVerifyPassword = (stripePaymentMethodId) => {
    setOpenVerifyPassword(true);
    setPaymentMethodToDetach(stripePaymentMethodId);
  };

  const onSuccessCallback = R.curry(async () => {
    const { payload } = await onDetachStripePaymentMethod(
      paymentMethodType,
      userId,
      paymentMethodToDetach,
    );
    const { detached } = payload;
    if (R.equals(detached, true)) {
      setReGet();
    }
  });
  /**
   * Handle select default card
   */
  useDeepCompareEffect(() => {
    if (R.length(paymentMethods) >= 1) {
      const stripePaymentMethodId = R.pipe(
        R.head,
        R.prop('stripePaymentMethodId'),
      )(paymentMethods);
      setStripeSelectedPaymentMethod(stripePaymentMethodId);
      // set selectedStripePaymentMethodId in higher component
      if (isFunction(onSelectStripePaymentMethod)) {
        onSelectStripePaymentMethod(stripePaymentMethodId);
      }
    }
  }, [
    paymentMethods,
    onSelectStripePaymentMethod,
    isFunction,
  ]);
  const getContent = (paymentMethods) => R.addIndex(R.map)((item, index) => {
    const { card, stripePaymentMethodId } = item;
    const key = `payment_method_${index}`;
    return (
      <Grid item xs={12} key={key}>
        <Box
          display="flex"
          flexDirection="row"
        >
          <Box>
            <PaymentStripeCard
              card={card}
              stripePaymentMethodId={stripePaymentMethodId}
              selectedStripePaymentMethodId={selectedStripePaymentMethodId}
              handleSelectStripePaymentMethod={handleSelectStripePaymentMethod}
            />
          </Box>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            m={1}
          >
            <Box>
              <IconButton onClick={() => showVerifyPassword(stripePaymentMethodId)}>
                <DeleteOutlineOutlinedIcon />
              </IconButton>
            </Box>
          </Box>
        </Box>
      </Grid>
    );
  })(paymentMethods);

  return (
    <>
      <Grid container spacing={2}>
        {getContent(paymentMethods)}
        <Grid item xs={12} className={classes.buttonField}>
          <Grid container spacing={2} justifyContent="space-between" alignItems="center">
            {enableCoupon && (
            <Grid item>
              <Coupon />
            </Grid>
            )}
            <Grid item>
              <RoundedButton
                color="secondary"
                variant="contained"
                onClick={handleOpenAddCard}
                noMargin
              >
                {t('add another card')}
              </RoundedButton>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Dialog onClose={handleOpenAddCard} aria-labelledby="payment-dialog" open={openAddCard} fullWidth>
        <DialogTitle disableTypography id="payment-dialog-title" className={classes.dialogTitle}>
          <Typography variant="h6">{t('Add A Payment Method')}</Typography>
          <CloseIconButton
            onClick={handleCloseAddCard}
            color="default"
          />
        </DialogTitle>
        <DialogContent dividers>
          <Box mt={2} mb={2}>
            <PaymentStripeCheckoutForm
              btnText="add"
              stripeCustomerId={stripeCustomerId}
              onSubmitExtra={handleOnSubmitExtra}
            />
          </Box>
        </DialogContent>
      </Dialog>
      {openVerifyPassword && (
        <VerifyPasswordDialog
          open={openVerifyPassword}
          onClose={onCloseVerifyPassword}
          onSuccessCallback={onSuccessCallback}
          email={email}
        />
      )}
    </>
  );
};

PaymentStripeCardList.defaultProps = {
  enableCoupon: false,
};

PaymentStripeCardList.propTypes = {
  paymentMethods: PropTypes.array.isRequired,
  onSelectStripePaymentMethod: PropTypes.func.isRequired,
  onGetUserStripePaymentMethod: PropTypes.func.isRequired,
  onDetachStripePaymentMethod: PropTypes.func.isRequired,
  setReGet: PropTypes.func.isRequired,
  enableCoupon: PropTypes.bool,
};

export default PaymentStripeCardList;
