import gql from 'graphql-tag';

export const getUserStripeData = gql`
  query getUserStripeData($userId: String!)
  {
    getUserStripeData(
     userId: $userId
    ) {
       userId
       stripeCustomerId
    }
  }
`;

export const getUserStripePaymentMethods = gql`
  query getUserStripePaymentMethods(
    $userId: String!,
    $paymentMethodType: String!

  )
  {
    getUserStripePaymentMethods(
     userId: $userId,
     paymentMethodType: $paymentMethodType
    ) {
        userId
        data {
          stripePaymentMethodId
          card {
            brand
            country
            expMonth
            expYear
            last4
          }
          created
          customer
          type
        }
    }
  }
`;

export const validatePromotionCode = gql`
  query validatePromotionCode(
    $validatePromotionCodeInput: ValidatePromotionCodeInput!,
  )
  {
    validatePromotionCode(
      validatePromotionCodeInput: $validatePromotionCodeInput,
    ) {
      id
      name
    }
  }
`;
