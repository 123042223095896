const contentWidth = (theme) => ({
  maxWidth: 700,
});

export const nodeOwnerFormContentItem = ((theme) => ({
  content: {
    ...theme.centerComponent,
  },
  title: {
    [theme.breakpoints.down('xs')]: {
      fontSize: 30,
    },
    fontWeight: 600,
  },
}));

export const nodeOwnerCompleteStyles = ((theme) => ({
  congrat: {
    ...contentWidth(theme),
    fontWeight: 600,
    [theme.breakpoints.down('xs')]: {
      fontSize: 20,
    },
  },
  description: {
    ...contentWidth(theme),
  },
  depositDetailsItem: {
    maxWidth: 350,
  },
  highlightText: {
    color: theme.palette.primary.main,
  },
  invoice: {
    [theme.breakpoints.down('xs')]: {
      width: window.innerWidth * 0.8,
      overflow: 'auto',
    },
  },
}));

export const nodeOwnerPaymentStyles = (theme) => ({
  root: {
    ...theme.centerComponent,
  },
  payment: {
    ...contentWidth(theme),
    ...theme.border.default,
  },
  paymentContent: {
    margin: theme.spacing(4),
  },
  planButton: {
    width: 120,
  },
  discountText: {
    margin: theme.spacing(1),
  },
  discountOnButton: {
    color: theme.palette.primary.main,
    position: 'absolute',
    top: '0%',
    left: '80%',
    marginTop: -12,
    marginLeft: -12,
  },
  discountOnButtonText: {
    fontSize: 12, fontWeight: 600, marginLeft: 6, marginRight: 6,
  },
  totalText: {
    fontSize: theme.typography.h1.fontSize,
    fontWeight: 400,
  },
  currencySymbol: {
    fontSize: theme.typography.body2.fontSize,
    fontWeight: 500,
  },
  valueText: {
    fontSize: theme.typography.h1.fontSize,
    fontWeight: 400,
  },
});

export const nodeOwnerFormCartStyles = (theme) => ({
  quantity: {
    maxWidth: 50,
  },
  quantityButtonMinor: {
    padding: 0,
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(1, 1, 1, 1),
      marginLeft: theme.spacing(-1.5),
    },
  },
  quantityButtonPlus: {
    padding: 0,
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(1, 1, 1, 1),
    },
  },
});
