import * as R from 'ramda';

import { errorOperations } from '../error/index';
import { loadingOperations } from '../loading/index';

import * as actions from './actions';

export const { updatePaymentData } = actions;

export const { updatePaymentStripe } = actions;

/**
 * Get payment history
 *
 * @param      {string}  userId
 * @param      {Function}  dispatch  The dispatch function of redux
 * @return     {import('../typedefs').ReduxAction}
 */
export const getPaymentHistory = R.curry(
  async (userId, dispatch) => {
    try {
      dispatch(loadingOperations.setLoading('getPaymentHistory', true));
      const response = dispatch(
        await actions.getPaymentHistory(userId),
      );
      dispatch(errorOperations.clearError('getPaymentHistory'));
      dispatch(loadingOperations.setLoading('getPaymentHistory', false));

      return response;
    } catch (error) {
      dispatch(loadingOperations.setLoading('getPaymentHistory', false));
      return dispatch(errorOperations.setError(
        'getPaymentHistory',
        { fallbackErrorMessage: 'Get payment history failed', error },
      ));
    }
  },
);

export const getSubscriptions = R.curry(
  async (userId, dispatch) => {
    try {
      dispatch(loadingOperations.setLoading('getSubscriptions', true));
      const response = dispatch(
        await actions.getSubscriptions(userId),
      );
      dispatch(errorOperations.clearError('getSubscriptions'));
      dispatch(loadingOperations.setLoading('getSubscriptions', false));

      return response;
    } catch (error) {
      dispatch(loadingOperations.setLoading('getSubscriptions', false));
      return dispatch(errorOperations.setError(
        'getSubscriptions',
        { error },
      ));
    }
  },
);

export const { updateStripePromotionCode } = actions;
export const { updateStripeTrialDays } = actions;
