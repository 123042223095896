import * as R from 'ramda';

export const selectAccountData = R.curry((account, state) => {
  const accountData = R.view(
    R.lensPath(['web3R', 'web3Account', account]),
    state,
  );
  return accountData || {};
});

export const selectBalance = R.curry((account, state) => {
  const accountData = selectAccountData(account, state);
  const { balance, enoughBalance, done } = accountData;
  return { balance, enoughBalance, done };
});

export const selectWeb3InjectedData = R.curry((chain, state) => {
  const { isWeb3Injected, isExtensionsInjected } = state;
  if (!isWeb3Injected) {
    return {
      accounts: [],
      isWeb3Injected,
      isExtensionsInjected,
    };
  }
  const web3WalletData = state.injectedAccounts[chain];
  const {
    activeChain,
  } = web3WalletData;
  const { accounts } = web3WalletData[activeChain];
  return {
    activeChain,
    accounts,
    isWeb3Injected,
    isExtensionsInjected,
  };
});

export const selectWeb3GeneratedAccounts = R.curry((chain, state) => {
  const accountsData = state.generatedAccounts[chain];
  const {
    activeChain,
  } = accountsData;
  if (!activeChain) {
    return {
      accounts: [],
    };
  }
  const { accounts } = accountsData[activeChain];
  return {
    accounts,
    activeChain,
  };
});

const web3AccountSelectors = {
  generatedAccounts: selectWeb3GeneratedAccounts,
  injectedAccounts: selectWeb3InjectedData,
};

export const selectInjectedOrGeneratedAccounts = R.curry(
  (chain, accountType, state) => {
    if (!accountType) {
      return {
        accounts: [],
        isWeb3Injected: false,
        isExtensionsInjected: false,
      };
    }
    return web3AccountSelectors[accountType](chain, state);
  },
);
export const selectWeb3R = R.view(R.lensPath(['web3R']));
export const selectTransactionsR = R.pipe(
  selectWeb3R,
  R.view(R.lensPath(['transactions'])),
);

export const selectSubChain = R.curry((chain, subChain, state) => {
  const subChainData = R.pipe(
    selectTransactionsR,
    R.view(R.lensPath([chain, subChain])),
  )(state);
  return subChainData;
});
export const selectTransactions = R.curry((chain, subChain, state) => {
  const txs = R.pipe(
    selectSubChain(chain, subChain),
    R.view(R.lensPath(['txs'])),
  )(state);
  return txs;
});
// this function is built to get the first transaction
// for now we only have to transfer 1 traction at the time
// and also we are not checking for transaction status
export const selectFirstTransaction = R.curry((chain, subChain, state) => {
  const firstTxs = R.pipe(
    selectTransactions(chain, subChain),
    R.values,
    R.head,
  )(state);
  return firstTxs || {};
});

export const selectTransactionDone = R.curry((state) => {
  const done = R.pipe(
    selectTransactionsR,
    R.view(R.lensPath(['done'])),
  )(state);
  return done;
});

export const selectTransactionInfo = R.curry((chain, state) => {
  const txInfo = R.pipe(
    selectTransactionsR,
    R.view(R.lensPath([chain, 'txInfo'])),
  )(state);
  return txInfo;
});

export const selectAccountsInfo = R.curry(
  (state) => R.pipe(
    selectWeb3R,
    R.view(R.lensPath(['accountsInfo'])),
  )(state),
);

export const selectAccountInfo = R.curry(
  (chain, subChain, address, state) => (address
    ? R.pipe(
      selectAccountsInfo,
      R.view(R.lensPath([chain, subChain, address])),
    )(state)
    : {}),
);

export const selectAccountBalance = R.curry((chain, subChain, address, state) => R.pipe(
  selectAccountInfo(chain, subChain, address),
  R.view(R.lensPath(['balance'])),
  (balance) => balance || { free: 0, reserved: 0 },
)(state));

export const selectWallet = R.pipe(
  selectWeb3R,
  R.view(R.lensPath(['wallet'])),
);

export const selectWalletSelectedAccount = (state) => R.pipe(
  selectWallet,
  R.view(R.lensPath(['selectedAccount'])),
)(state);
