import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { sumFromList } from 'jsutils';
import toNumber from 'lodash/fp/toNumber';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import React from 'react';
import { useTranslation } from 'react-i18next';
// import GetAppOutlinedIcon from '@material-ui/icons/GetAppOutlined';
// others
import Moment from 'react-moment';

import { styles } from './styles';

const useStyles = makeStyles(styles);

const Invoice = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const {
    invoice,
  } = props;

  const {
    total,
    tax,
    customerEmail,
    description,
    // invoicePdf,
    customerName,
    dueDate,
    status,
    number,
    currency,
    customerAddress,
    customerPhone,
    lines: {
      data: products,
    },
    totalDiscountAmounts,
  } = invoice;

  const {
    city,
    country,
    line1,
    line2,
    postalCode,
    state,
  } = customerAddress || {};

  const address = customerAddress ? R.pipe(
    R.values,
    R.filter((eachStr) => eachStr !== ''),
    R.join('\n'),
  )({
    postalCode,
    state,
    city,
    line1,
    line2,
    country,
  }) : null;
  const discount = sumFromList('amount', totalDiscountAmounts);
  const currencySymbol = currency || 'JPY';

  const statusBgColorSet = {
    paid: '#cbf4c9',
    unpaid: '#d6ecff',
  };

  const statusTextColorSet = {
    paid: '#0e6245',
    unpaid: '#3d4eac',
  };

  const statusBgColor = statusBgColorSet[status] || '#e3e8ee';
  const statusTextColor = statusTextColorSet[status] || '#4f566b';

  // TODO: remove titleInEnglish
  const detailsItem = (title, titleInEnglish, content) => {
    // i18next-extract-mark-context-next-line ["Billed to email", "Name", "Address", "Phone number", "Invoice number", "Due date", "Memo"]
    const contentToUse = content || `${t('No', { context: titleInEnglish })}`;

    return (
      <Grid container spacing={2}>
        <Grid item xs={3}>
          <Typography className={classes.text} color="textSecondary">
            {title}
          </Typography>
        </Grid>
        <Grid item xs={9}>
          <Typography
            color={content ? 'textPrimary' : 'textSecondary'}
            className={classes.detailsItemContent}
          >
            {contentToUse}
          </Typography>
        </Grid>
      </Grid>
    );
  };

  const priceItem = (product, amount, quanity) => (
    <>
      <Grid container spacing={2} justifyContent="space-between">
        <Grid item>
          <Typography className={classes.text}>
            {product}
          </Typography>
          <Typography className={classes.text} color="textSecondary">
            {`${t('Qty')} ${quanity}`}
          </Typography>
        </Grid>
        <Grid item>
          <Typography className={classes.text}>
            {amount.toLocaleString('en-US', {
              style: 'currency',
              currency: currencySymbol,
            })}
          </Typography>
        </Grid>
      </Grid>
    </>
  );

  const getProductItems = (products) => R.addIndex(R.map)(
    (eachProduct, index) => {
      const {
        description,
        quantity,
        price,
      } = eachProduct;
      const { unitAmount } = price;

      return (
        <Grid item xs={12} key={`${index}_${description}`}>
          {priceItem(description, unitAmount * quantity, quantity)}
          <Divider />
        </Grid>
      );
    },
  )(products);

  return (
    <>
      <Box className={classes.border}>
        <Grid container spacing={1} className={classes.container}>
          <Grid item xs={12}>
            <Typography className={classes.text} color="textSecondary">
              {t('Invoice from Neukind, Inc.')}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography className={classes.total}>
              {total.toLocaleString('en-US', {
                style: 'currency',
                currency: currencySymbol,
              })}
            </Typography>
          </Grid>
          <Grid item>
            <Box
              style={{
                borderRadius: 5,
                backgroundColor: statusBgColor,
              }}
              color={statusTextColor}
            >
              <Typography variant="subtitle2" className={classes.statusText}>{status}</Typography>
            </Box>
          </Grid>

          <Grid item xs={12}>
            <Divider />
          </Grid>

          {/* <Grid item>
            <Box
              display="flex"
              style={{ cursor: 'pointer' }}
              onClick={() => window.open(invoicePdf, '_blank')}
            >
              <Box mr={1}>
                <GetAppOutlinedIcon color="primary" />
              </Box>
              <Box>
                <Typography className={classes.text} color="primary">
                  {t('Download invoice')}
                </Typography>
              </Box>
            </Box>
          </Grid> */}

          <Grid item xs={12}>
            <Box mt={2} />
          </Grid>

          <Grid item xs={12}>
            {detailsItem(t('Billed to email'), 'Billed to email', customerEmail)}
          </Grid>
          <Grid item xs={12}>
            {detailsItem(t('Name'), 'Name', customerName)}
          </Grid>
          <Grid item xs={12}>
            {detailsItem(t('Address'), 'Address', address)}
          </Grid>
          <Grid item xs={12}>
            {detailsItem(t('Phone number'), 'Phone number', customerPhone)}
          </Grid>
          <Grid item xs={12}>
            {detailsItem(t('Invoice number'), 'Invoice number', number)}
          </Grid>
          <Grid item xs={12}>
            {detailsItem(
              t('Due date'),
              'Due date',
              dueDate ? (
                <Moment format="MMM D, YYYY">
                  {toNumber(dueDate) * 1000}
                </Moment>
              ) : null,
            )}
          </Grid>
          <Grid item xs={12}>
            {detailsItem(
              t('Memo'),
              'Memo',
              description ? description.substring(2, description.length) : null, // Remove first end line
            )}
          </Grid>
        </Grid>
      </Box>

      <Box className={classes.border}>
        <Grid container spacing={2} className={classes.container}>
          <Grid item xs={12}>
            <Typography className={classes.text} color="textSecondary">
              {t('Details')}
            </Typography>
          </Grid>

          {getProductItems(products)}

          {tax
          && (
          <>
            <Grid item xs={12}>
              <Grid container spacing={2} justifyContent="space-between">
                <Grid item>
                  <Typography className={classes.text}>
                    {t('Tax')}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography className={classes.text}>
                    {tax.toLocaleString('en-US', {
                      style: 'currency',
                      currency: currencySymbol,
                    })}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
          </>
          )}

          {discount > 0
          && (
          <>
            <Grid item xs={12}>
              <Grid container spacing={2} justifyContent="space-between">
                <Grid item>
                  <Typography className={classes.text}>
                    {t('Discount')}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography className={classes.text}>
                    -
                    {discount.toLocaleString('en-US', {
                      style: 'currency',
                      currency: currencySymbol,
                    })}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
          </>
          )}

          <Grid item xs={12}>
            <Grid container spacing={2} justifyContent="space-between">
              <Grid item>
                <Typography className={classes.text}>
                  {t('Total')}
                </Typography>
              </Grid>
              <Grid item>
                <Typography className={classes.text}>
                  {total.toLocaleString('en-US', {
                    style: 'currency',
                    currency: currencySymbol,
                  })}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>

          <Grid item xs={12}>
            <Typography className={classes.text} color="textSecondary">
              {t('Questions? Contact us at')}
              {' '}
              <Link target="_blank">
                support@neukind.jp
              </Link>
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

Invoice.defaultProps = {
};

Invoice.propTypes = {
  invoice: PropTypes.shape({
    total: PropTypes.number.isRequired,
    tax: PropTypes.number.isRequired,
    customerEmail: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    customerName: PropTypes.string,
    dueDate: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
    number: PropTypes.string.isRequired,
    currency: PropTypes.string.isRequired,
    customerAddress: PropTypes.string,
    customerPhone: PropTypes.string,
    lines: PropTypes.shape({
      data: PropTypes.array.isRequired,
    }),
    totalDiscountAmounts: PropTypes.number,
  }).isRequired,
};

export default Invoice;
