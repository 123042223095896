import gql from 'graphql-tag';

export const createUserStripePaymentMethodMutation = gql`
  mutation createUserStripePaymentMethod(
    $createUserStripePaymentMethodInput: CreateUserStripePaymentMethodInput!
  )
  {
    createUserStripePaymentMethod(
      createUserStripePaymentMethodInput: $createUserStripePaymentMethodInput
  ) {
      userId
      data {
        stripePaymentMethodId
        card {
          brand
          country
          expMonth
          expYear
          last4
        }
        created
        customer
        type
      }
    }
  }
`;

export const detachStripePaymentMethodMutation = gql`
  mutation detachStripePaymentMethod(
    $detachStripePaymentMethodInput: DetachStripePaymentMethodInput!
  )
  {
    detachStripePaymentMethod(
      detachStripePaymentMethodInput: $detachStripePaymentMethodInput
  ) {
      userId
      stripePaymentMethodId
      detached
   }
  }
`;

export const cancelSubscriptionMutation = gql`
  mutation cancelSubscription(
    $cancelSubscriptionInput: CancelSubscriptionInput!
  )
  {
    cancelSubscription(
      cancelSubscriptionInput: $cancelSubscriptionInput
  ) {
      subscriptionId
      startDate
      cancelDate
   }
  }
`;

export const changeSubscriptionPlanMutation = gql`
  mutation changeSubscriptionPlan(
    $changeSubscriptionPlanInput: ChangeSubscriptionPlanInput!
  )
  {
    changeSubscriptionPlan(
      changeSubscriptionPlanInput: $changeSubscriptionPlanInput
  ) {
      subscriptionId
      items {
        data {
          id
          price {
            id
          }
        }
      }
    }
  }
`;
