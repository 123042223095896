import { pipeAwait } from 'jsutils';
import * as R from 'ramda';

import { utils } from '../crypto/index';

import { graphqlCreateCryptoAddress, graphqlGetCryptoAddressesQuery } from './graphql/services';
import * as services from './services';
import * as types from './types';

import { graphqlNormalize } from 'modules/apollo/services';
import { returnAction } from 'store/store_utils';

export const createCryptoAddress = R.curry(
  (userId, address, chain, subChain) => pipeAwait(
    graphqlCreateCryptoAddress,
    graphqlNormalize('createCryptoAddress'),
    services.formatCreateCryptoAddressData,
    (data) => {
      const { chain, ...rest } = data;
      return {
        chain,
        data: rest,
      };
    },
    returnAction(types.CREATE_CRYPTO_ADDRESS_SUCCESS),
  )({
    createCryptoAddressInput: {
      userId, address, chain, subChain,
    },
  }),
);

export const getCryptoAddresses = R.curry(
  (userId, address, chain, subChain) => pipeAwait(
    graphqlGetCryptoAddressesQuery,
    graphqlNormalize('getCryptoAddresses'),
    services.formatGetCryptoAddressesData(chain, subChain),
    (data) => {
      const {
        items: {
          chain,
          ...rest
        },
      } = data;
      return {
        chain,
        data: rest,
      };
    },
    returnAction(types.GET_CRYPTO_ADDRESS_SUCCESS),
  )({
    getCryptoAddressesInput: {
      data: {
        userId,
        address,
        chain,
        subChain,
      },
    },
  }),
);

export const generateAccounts = R.curry((coin, data) => {
  const { generateAccounts } = utils.getCoinMethods(coin);
  const accountsData = generateAccounts(data);
  return {
    type: types.GENEREATE_ACCOUNTS_SUCCESS,
    payload: {
      done: true,
      chain: coin,
      data: accountsData,
    },
  };
});

export const backupAccount = R.curry((coin, data) => {
  const { backupAccount } = utils.getCoinMethods(coin);
  const json = backupAccount(data);
  return {
    type: types.BACKUP_ACCOUNTS_SUCCESS,
    payload: json,
  };
});
