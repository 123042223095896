import Promise from 'bluebird';
import _ from 'lodash';

import { userOperations, userTypes } from '../../store/user/index';

const accountAsyncValidate = async (values, dispatch) => {
  const { email } = values;
  const errors = {};
  if (email) {
    const response = await dispatch(userOperations.validateEmail(email));
    if (response.type !== userTypes.VALIDATE_EMAIL_SUCCESS) {
      const { message } = response.payload.value;
      errors.email = message;
    }
    if (!_.isEmpty(errors)) throw errors;
  }
  return Promise.resolve();
};

export {
  accountAsyncValidate,
};
