import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { styles } from './styles';

const useStyles = makeStyles(styles);

const MyNodesListHeader = () => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <Card className={classes.header}>
      <CardContent className={classes.headerContent}>
        <Grid container justifyContent="space-between" alignContent="center" spacing={2}>
          <Grid item xs={12} sm={3} md={3}>
            <Typography variant="body1" className={classes.headerText}>{t('NODE NAME')}</Typography>
          </Grid>
          <Grid item xs={12} sm={3} md={3}>
            <Typography variant="body1" className={classes.headerText}>{t('CREATED DATE')}</Typography>
          </Grid>
          {/* <Grid item xs={12} sm={3} md={3}>
                      <Typography variant="body1" className={classes.headerText}>{t('UPTIME')}</Typography>
                    </Grid> */}
          <Grid item xs={12} sm={3} md={3}>
            <Typography variant="body1" className={classes.headerText}>{t('STATUS')}</Typography>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default MyNodesListHeader;
