import gql from 'graphql-tag';

export const getUserStripeData = gql`
  query getUserStripeData($userId: String!)
  {
    getUserStripeData(
     userId: $userId
    ) {
       userId
       stripeCustomerId
    }
  }
`;

export const getUserStripePaymentMethods = gql`
  query getUserStripePaymentMethods(
    $userId: String!,
    $paymentMethodType: String!

  )
  {
    getUserStripePaymentMethods(
     userId: $userId,
     paymentMethodType: $paymentMethodType
    ) {
        userId
        data {
          stripePaymentMethodId
          card {
            brand
            country
            expMonth
            expYear
            last4
          }
          created
          customer
          type
        }
    }
  }
`;

export const getOrdersQuery = gql`
  query getOrders(
    $getOrdersInput: GetOrdersInput!
  )
  {
    getOrders(
      getOrdersInput: $getOrdersInput
    ) {
      items {
        orderId
        totalEarning
        orderType
        dateTrialEnd
        payments {
          amount
          paymentId
          paymentType
          dateCreated
          dateUpdated
          paymentMethod
          currencySymbol
          stripe {
            stripePaymentMethodId
            stripePriceId
            stripeInvoice
            stripeInvoiceId
            stripeCustomerId
            stripeInvoicePdf
            stripeSubscriptionId
          }
        }
        quantity
        contract {
          contractPeriodValue
          contractId
          contractIRR
          dateCreated
          dateUpdated
          contractType
          contractPeriod
          contractEndDate
          contractSignDate
          contractStartDate
        }
        timeline {
          dateCompleted
          expectDateCompleted
          stepName
          metadata
          stepStatus
          dateCreated
          dateUpdated
          nodeProviderOrderTimelineId
          description
        }
        metadata
        dateCreated
        dateUpdated
        devices {
          contract {
            contractPeriodValue
            contractId
            contractIRR
            dateCreated
            dateUpdated
            contractType
            contractPeriod
            contractEndDate
            contractSignDate
            contractStartDate
          }
          earnings {
            earning
            dateCreated
            dateUpdated
            currencySymbol
            nodeProviderEarningId
            deviceId
            cryptoPrices
          }
          macAddress
          dateActive
          dateCreated
          dateUpdated
          deviceStatus
          deviceId
        }
        earningHistory {
          orderId
          activeNodesCount
          timestamp
          earning
        }
      }
      meta {
        totalItems
        itemCount
        itemsPerPage
        totalPages
        currentPage
      }
    }
  }
`;
