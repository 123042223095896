import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import React from 'react';

import MiningBoxWrapper from './MiningBoxWrapper';
import { miningAddressStyle } from './styles';

import ChainIcon from 'components/Chain/ChainIcon';
import CopyButton from 'components/MaterialUiCustom/Button/CopyButton';
import WalletAccount from 'components/WalletAccount/WalletAccount';

const useStyles = makeStyles(miningAddressStyle);

const MiningAddress = ({
  accounts,
  t,
}) => {
  const classes = useStyles();
  const address = R.pipe(
    R.head,
    (account) => (account ? R.prop('address', account) : ''),
  )(accounts);
    // check if address empty
  return (
    <MiningBoxWrapper
      Icon={(
        <ChainIcon
          image="/img/icon/phala.png"
          title="address"
          className={classes.chainIcon}
        />
      )}
      Text={(
        <WalletAccount
          t={t}
          account={address}
        />
      )}
      Action={(
        <CopyButton
          text={address}
          color="primary"
          size="small"
        />
)}
    />
  );
};
MiningAddress.defaultProps = {
  accounts: [],
};

MiningAddress.propTypes = {
  accounts: PropTypes.arrayOf(PropTypes.shape({
    address: PropTypes.string,
  })),
  t: PropTypes.func.isRequired,
};
export default MiningAddress;
