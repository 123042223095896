import * as R from 'ramda';

/**
 * Store data in browser local storage
 * @param  {string} key     key for data to store
 * @param  {object} value   data to store
 */
export const setItem = R.curry((key, value) => (
  window.localStorage.setItem(key, JSON.stringify(value))
));

/**
 * Check if data item with given key exists in browser local storage
 * @param  {string} key key to the data item in local storage
 * @return {boolean}    true if data item exists in browser local storage and false otherwise
 */
export const doesItemWithKeyExistsInLocalStorage = R.curry((key) => {
  const dataItem = window.localStorage.getItem(key);
  if (dataItem) {
    return true;
  }
  return false;
});

/**
 * Get data with given key from browser local storage
 * @param  {string} key key of the data to fetch
 * @return {object}
 */
export const getItem = R.curry((key) => (
  JSON.parse(window.localStorage.getItem(key))
));

/**
 * TODO Unit test for the method
 * Remove data with given key from browser local storage
 * @param  {string} key key of the data to fetch
 * @return {void}
 */
export const removeItem = R.curry((key) => {
  if (doesItemWithKeyExistsInLocalStorage(key)) {
    return window.localStorage.removeItem(key);
  }
});

/**
 * Store data in browser session storage
 * @param  {string} key     key for data to store
 * @param  {object} value   data to store
 */
export const sessionStorageSetItem = R.curry((key, value) => (
  window.sessionStorage.setItem(key, JSON.stringify(value))
));

/**
 * Check if data item with given key exists in browser session storage
 * @param  {string} key key to the data item in session storage
 * @return {boolean}    true if data item exists in browser session storage and false otherwise
 */
export const doesItemExistInSessionStorage = R.curry((key) => (
  window.sessionStorage.getItem(key)
));

/**
 * Get data with given key from browser session storage
 * @param  {string} key key of the data to fetch
 * @return {object}
 */
export const sessionStorageGetItem = R.curry((key) => (
  JSON.parse(window.sessionStorage.getItem(key))
));

/**
 * TODO Unit test for the method
 * Remove data with given key from browser session storage
 * @param  {string} key key of the data to fetch
 * @return {void}
 */
export const sessionStorageRemoveItem = R.curry((key) => {
  if (doesItemExistInSessionStorage(key)) {
    return window.sessionStorage.removeItem(key);
  }
});

export const isPageReloaded = () => {
  // This is already depreceated but still supported by most browsers.
  // More at: https://developer.mozilla.org/en-US/docs/Web/API/Performance/navigation
  if (window.performance.navigation) {
    return window.performance.navigation.type === performance.navigation.TYPE_RELOAD;
  }

  // This is an experimental technology and not yet supported by all browsers.
  // More at: https://developer.mozilla.org/en-US/docs/Web/API/PerformanceNavigationTiming
  // And here: https://developer.mozilla.org/en-US/docs/Web/API/Performance/getEntriesByType
  const performanceEntries = window.performance.getEntriesByType('navigation');
  return R.pipe(
    R.map((nav) => nav.type),
    R.includes('reload'),
  )(performanceEntries);
};
