import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

import CustomTabs from '../Tabs/CustomTabs';

import NodeOwnerContent from './NodeOwnerContent';
import NodeOwnerForm from './forms/NodeOwnerForm';

const NodeOwner = (props) => {
  const { t } = useTranslation();

  const mapURLToService = {
    neunode: <NodeOwnerForm
      service="neunode"
    />,
    'phala-mining': <NodeOwnerForm
      service="phala-mining"
    />,
  };

  const {
    match,
  } = props;
  const { params } = match;
  const { service } = params;

  const content = mapURLToService[service];

  const tabs = [
    {
      label: t('Node Owner'),
      content: <NodeOwnerContent />,
      mainTab: true,
    },
  ];

  return (
    content || (
    <CustomTabs
      tabs={tabs}
      belowTabsComponent={(
        <Typography variant="h3" color="textSecondary">
          {t('Own a node, be part of the web3 infrastructure')}
        </Typography>
      )}
    />
    )
  );
};

NodeOwner.defaultProps = {
};

NodeOwner.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      service: PropTypes.string,
    }).isRequired,
  }).isRequired,
};

export default NodeOwner;
