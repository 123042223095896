const styles = (theme) => ({
  icon: {
    margin: theme.spacing(0, 2, 0, 2),
    width: '7%',
  },
  iconImage: {
    width: '100%',
    height: 'auto',
  },
});

export default styles;
