import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import CopyButton from '../../MaterialUiCustom/Button/CopyButton';
import ReactTableV7 from '../../Tables/ReactTableV7';
import CustomTooltip from '../../Tooltip/CustomTooltip';
import InvestmentEarningDashboardItem from '../InvestmentNodeDetailsPageItem';

// others
import { ununifiValidatorAllValidatorColumns } from './columns';
import { ununifiValidatorAllValidatorsStyles } from './styles';

import LocalDate from 'components/LocalDate/LocalDate';

const useStyles = makeStyles(ununifiValidatorAllValidatorsStyles);

const UnUniFiValidatorAllValidators = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { data } = props;
  const {
    getUserNodesLoading,
    getTotalEarningLoading,
  } = useSelector((state) => state.loadingR);

  const columnsToUse = ununifiValidatorAllValidatorColumns(
    t,
  );

  const getRowExpandedContentItem = (title, content) => (
    <Box display="flex">
      <Box mr={2}>
        <Typography className={classes.rowExpandedContentItemTitle}>
          {title}
        </Typography>
      </Box>
      <Box>
        {content}
      </Box>
    </Box>
  );

  const getRowExpandedContent = (row) => {
    const { index: rowIndex } = row;
    const {
      pubKey,
      accAddress,
      dateCreated,
    } = data[rowIndex];

    return (
      <Grid container spacing={3} className={classes.rowExpandedContent}>
        <Grid item xs={12}>
          {getRowExpandedContentItem(t('Public key'),
            <Box display="flex">
              <Box>
                <CustomTooltip
                  title={pubKey}
                  content={(

                    <Typography noWrap className={classes.pubKeyText}>
                      {pubKey}
                    </Typography>
              )}
                />
              </Box>
              <Box>
                <CopyButton
                  text={pubKey}
                  color="primary"
                  size="small"
                />
              </Box>
            </Box>)}
        </Grid>
        <Grid item xs={12}>
          {getRowExpandedContentItem(t('Account Address'),
            <Box display="flex">
              <Box>
                <CustomTooltip
                  title={accAddress}
                  content={(

                    <Typography noWrap className={classes.pubKeyText}>
                      {accAddress}
                    </Typography>
              )}
                />
              </Box>
              <Box>
                <CopyButton
                  text={accAddress}
                  color="primary"
                  size="small"
                />
              </Box>
            </Box>)}
        </Grid>
        <Grid item xs={12}>
          {getRowExpandedContentItem(t('Active Since'),
            dateCreated ? (
              <LocalDate
                date={dateCreated}
              />
            ) : '_')}
        </Grid>
      </Grid>
    );
  };

  return (
    <InvestmentEarningDashboardItem
      titlePadding="21px 32px 16px 32px"
      title={t('All Validators')}
      content={(
        <ReactTableV7
          loading={getUserNodesLoading || getTotalEarningLoading}
          data={data}
          columns={columnsToUse}
          customPageSize={3}
          customPageSizeList={[3, 5, 10, 20, 50]}
          renderRowSubComponent={getRowExpandedContent}
        />
    )}
    />
  );
};

UnUniFiValidatorAllValidators.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      earning: PropTypes.number,
      balance: PropTypes.number,
      nodeId: PropTypes.string,
      dateCreated: PropTypes.string,
      apr: PropTypes.number,
      status: PropTypes.string,
      pubKey: PropTypes.string,
      accAddress: PropTypes.string,
      validatorId: PropTypes.string,
      ipv4Domain: PropTypes.string,
      ipv6Domain: PropTypes.string,
    }),
  ).isRequired,
};

export default React.memo(UnUniFiValidatorAllValidators);
