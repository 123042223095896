import PropTypes from 'prop-types';
import React from 'react';

import SecretPhraseGenerator from './SecretPhraseGenerator';

const SecretPhrase = (props) => {
  const {
    onGenerateSecretPhrase,
    secretPhrase,
    onConfirmSuccess,
    onClose,
    customClasses,
    hideStepsTitle,
    firstStepSecondaryBtnProps,
    firstStepSecondaryBtnAction,
    onGenerateSecretPhraseSuccess,
    stepper,
    extraSteps,
    extraStepsContent,
    extraStepsActions,
    regenerateSecretPhraseWhenGoBack,
    stepActionPrecedeFn,
    precedeFnCondition,
    resetSecretPhrase,
    mustCopy,
  } = props;

  // TODO add import secret phrase
  return (
    <SecretPhraseGenerator
      secretPhrase={secretPhrase}
      onGenerateSecretPhrase={onGenerateSecretPhrase}
      onConfirmSuccess={onConfirmSuccess}
      onClose={onClose}
      customClasses={customClasses}
      hideStepsTitle={hideStepsTitle}
      firstStepSecondaryBtnProps={firstStepSecondaryBtnProps}
      firstStepSecondaryBtnAction={firstStepSecondaryBtnAction}
      onGenerateSecretPhraseSuccess={onGenerateSecretPhraseSuccess}
      stepper={stepper}
      extraSteps={extraSteps}
      extraStepsContent={extraStepsContent}
      extraStepsActions={extraStepsActions}
      regenerateSecretPhraseWhenGoBack={regenerateSecretPhraseWhenGoBack}
      stepActionPrecedeFn={stepActionPrecedeFn}
      precedeFnCondition={precedeFnCondition}
      resetSecretPhrase={resetSecretPhrase}
      mustCopy={mustCopy}
    />
  );
};

SecretPhrase.propTypes = {
  onGenerateSecretPhrase: PropTypes.func,
  secretPhrase: PropTypes.string,
  onConfirmSuccess: PropTypes.func.isRequired,
  onClose: PropTypes.func,
  customClasses: PropTypes.shape({
    wrapPaper: PropTypes.string.isRequired,
  }),
  hideStepsTitle: PropTypes.bool,
  firstStepSecondaryBtnProps: PropTypes.object,
  firstStepSecondaryBtnAction: PropTypes.func,
  onGenerateSecretPhraseSuccess: PropTypes.func,
  stepper: PropTypes.bool,
  extraSteps: PropTypes.shape({
    2: PropTypes.shape({
      title: PropTypes.string.isRequired,
      descriptions: PropTypes.shape({
        primary: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
    3: PropTypes.shape({
      title: PropTypes.string.isRequired,
      descriptions: PropTypes.shape({
        primary: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
    4: PropTypes.shape({
      title: PropTypes.string.isRequired,
      descriptions: PropTypes.shape({
        primary: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
  }),
  extraStepsContent: PropTypes.shape({
    2: PropTypes.element.isRequired,
    3: PropTypes.element.isRequired,
    4: PropTypes.element.isRequired,
  }),
  extraStepsActions: PropTypes.shape({
    2: PropTypes.shape({
      secondary: PropTypes.string.isRequired,
      isFormAction: PropTypes.bool.isRequired,
      formId: PropTypes.string.isRequired,
      isExtra: PropTypes.bool.isRequired,
      btnTexts: PropTypes.shape({
        primary: PropTypes.string.isRequired,
        secondary: PropTypes.string.isRequired,
      }).isRequired,
      disabled: PropTypes.bool.isRequired,
    }).isRequired,
    3: PropTypes.shape({
      primary: PropTypes.string.isRequired,
      secondary: PropTypes.string.isRequired,
      isExtra: PropTypes.bool.isRequired,
      btnTexts: PropTypes.shape({
        primary: PropTypes.string.isRequired,
        secondary: PropTypes.string.isRequired,
      }).isRequired,
      disabled: PropTypes.bool.isRequired,
    }).isRequired,
    4: PropTypes.shape({
      primary: PropTypes.func.isRequired,
      secondary: PropTypes.string.isRequired,
      isExtra: PropTypes.bool.isRequired,
      btnTexts: PropTypes.shape({
        primary: PropTypes.string.isRequired,
        secondary: PropTypes.string.isRequired,
      }).isRequired,
      loading: PropTypes.bool,
    }),
  }),
  regenerateSecretPhraseWhenGoBack: PropTypes.bool,
  stepActionPrecedeFn: PropTypes.func,
  precedeFnCondition: PropTypes.bool,
  resetSecretPhrase: PropTypes.bool,
  mustCopy: PropTypes.bool,
};
SecretPhrase.defaultProps = {
  onClose: () => { },
  onGenerateSecretPhrase: () => { },
  secretPhrase: null,
  customClasses: null,
  hideStepsTitle: false,
  firstStepSecondaryBtnProps: null,
  firstStepSecondaryBtnAction: () => {},
  onGenerateSecretPhraseSuccess: () => {},
  stepper: false,
  extraSteps: null,
  extraStepsContent: null,
  extraStepsActions: null,
  regenerateSecretPhraseWhenGoBack: false,
  stepActionPrecedeFn: () => {},
  precedeFnCondition: false,
  resetSecretPhrase: false,
  mustCopy: false,
};

export default SecretPhrase;
