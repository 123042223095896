import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Fade from '@material-ui/core/Fade';
import { makeStyles } from '@material-ui/core/styles';
import startCase from 'lodash/fp/startCase';
import toLower from 'lodash/fp/toLower';
import toUpper from 'lodash/fp/toUpper';
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

// routes

import { useHistory } from 'react-router-dom';

// store
import { useGetQuery } from '../../hooks/hooks';
import { contactFormOperations } from '../../store/contact_form/index';
import { navigationOperations } from '../../store/navigation/index';
import { routerServices } from '../../store/router/index';
import DeployNodeButton from '../DeployNodeButton/DeployNodeButton';

// DISABLE FOR NOW do not delete
// import ChainInfoOverview from './ChainInfoOverview';
// others
import { ChainStatus, getChainsData } from '../Market/constants';
import { makeNodeTypeToDisplay } from '../MarketProtocolDeploy/utils';
import CustomTabs from '../Tabs/CustomTabs';

import ChainInfoNode from './ChainInfoNode';
import ChainInfoTitle from './ChainInfoTitle';
import { styles } from './styles';

import { marketRoute, notFoundPageRoute } from 'routes';

const useStyles = makeStyles(styles);

const ChainInfo = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const {
    match,
  } = props;
  const { t } = useTranslation();
  const history = useHistory();
  const queryLng = useGetQuery('lng');
  const {
    params: { chainAbbreviation },
  } = match;
  const [chainData, setChainData] = useState({ nodeTypes: [] });
  const {
    nodeTypes,
    status,
    cryptoUnit,
    brief,
  } = chainData;
  const nodeType = R.view(R.lensPath([0, 'type']), nodeTypes);
  useEffect(() => {
    const chainData = getChainsData(t)[chainAbbreviation];
    const { status } = chainData || {};
    if (isEmpty(chainData) || status === ChainStatus.DISABLED) {
      return routerServices.historyPush(
        history,
        {
          pathname: notFoundPageRoute,
          queryLng,
        },
      );
    }
    if (chainData) setChainData(chainData);
  }, [t, chainAbbreviation, history, queryLng]);

  const isChainActive = status === ChainStatus.ACTIVE || status === ChainStatus.TRIAL;
  const shouldDisableBtn = status === ChainStatus.DISABLED || status === ChainStatus.MAINTENANCE;

  const deployButtoTextToDisplay = () => {
    if (status === ChainStatus.ACTIVE) {
      return t('Deploy a Node');
    }
    if (status === ChainStatus.CONTACT_REQUIRED) {
      return t('Contact Us');
    }

    if (status === ChainStatus.TRIAL) {
      return t('FREE TRIAL');
    }
    if (status === ChainStatus.MAINTENANCE) {
      return t('Maintenance');
    }

    return t('Coming Soon');
  };

  const tabs = R.concat([
    // DISABLE FOR NOW do not delete
    // {
    //   label: 'Overview',
    //   content: <ChainInfoOverview chain={chainData} />,
    // },
  ],
  R.map((each) => ({
    label: makeNodeTypeToDisplay(each.type),
    content: (<ChainInfoNode cryptoUnit={cryptoUnit} nodeType={each} chainDescription={brief} />),
  }), nodeTypes));

  const onSelectSidebarItem = () => {
    dispatch(navigationOperations.selectSidebarItem(1));
  };
  const handleOnClick = (marketRoute, chainAbbreviation) => {
    routerServices.historyPush(
      history,
      {
        pathname: `${marketRoute}/${chainAbbreviation}/deploy`,
        queryLng,
      },
    );
  };
  const openContactForm = (metadata) => {
    dispatch(contactFormOperations.openContactForm(metadata));
  };
  const getOnClick = (isChainActive, marketRoute, chainAbbreviation) => {
    onSelectSidebarItem();
    return isChainActive
      ? handleOnClick(marketRoute, chainAbbreviation)
      : openContactForm({ subject: `${toUpper(chainAbbreviation)} (${startCase(toLower(nodeType))})` });
  };

  return (
    <Fade in>
      <Card
        className={classes.card}
      >
        <CardHeader
          title={(
            <ChainInfoTitle chainData={chainData} />
        )}
          className={classes.cardHeader}
        />

        <CardContent
          className={classes.cardContent}
        >
          <CustomTabs
            tabs={tabs}
            extraComponent={(
              <DeployNodeButton
                variant="contained"
                color="primary"
                className={classes.deployButton}
                btnText={deployButtoTextToDisplay()}
                disabled={shouldDisableBtn}
                onClick={() => getOnClick(isChainActive, marketRoute, chainAbbreviation)}
              />
          )}
          />
        </CardContent>
      </Card>
    </Fade>
  );
};

ChainInfo.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      chainAbbreviation: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

export default ChainInfo;
