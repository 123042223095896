import { web3AccountsSubscribe } from '@polkadot/extension-dapp';
import * as R from 'ramda';

import * as types from '../../types';

import * as actions from './actions';

export const { enableWeb3 } = actions;

export const onWeb3AccountsChange = R.curry(
  async ({ ss58Format }, dispatch) => {
    // define it here to get the data inside web3AccountsSubscribe callback
    const unsubscribe = await web3AccountsSubscribe(
      async (injectedAccounts) => {
        dispatch(await actions.onWeb3AccountsChange({
          ss58Format,
          accounts: injectedAccounts,
        }));
      },
    );
    // this payload is not used to update reducer
    // only to get unsubscribe function to handle unsubscription on React Components
    return {
      type: types.ON_WEB3_ACCOUNT_CHANGE,
      payload: {
        unsubscribe,
      },
    };
  },
);
