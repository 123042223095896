// mutation
import {
  createMutation,
} from '../../../modules/apollo/apollo';

import {
  createDepositMutation,
} from './mutation';
// apollo

export const graphqlCreateDeposit = createMutation(createDepositMutation);
