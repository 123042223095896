import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import HelpOutlineOutlinedIcon from '@material-ui/icons/HelpOutlineOutlined';
import moment from 'moment';
import * as R from 'ramda';
import React from 'react';
import { CSVLink } from 'react-csv';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import ReactTableV7 from '../../Tables/ReactTableV7';
import CustomTooltip from '../../Tooltip/CustomTooltip';
import InvestmentEarningDashboardItem from '../InvestmentNodeDetailsPageItem';

// others
import { busdEarningHistoryColumns } from './columns';
import { busdEarningHistoryStyles } from './styles';

const useStyles = makeStyles(busdEarningHistoryStyles);

const BUSDEarningHistory = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const {
    getIntervalFixedEarningLoading,
  } = useSelector((state) => state.loadingR);

  const { interval_1mo: earnings } = useSelector((state) => state.nodeR.nodeOwnerEarning.interval.busd);

  const endOfLastMonth = moment().tz('UTC').startOf('month').subtract(1, 'days')
    .endOf('day');
  const earningHistory = R.pipe(
    R.map((eachEarning) => {
      const {
        orderId, nodeNumber, endTime, originalTotal, convertedTotal,
      } = eachEarning;

      if (moment(endTime).tz('UTC').unix() > endOfLastMonth.unix()) {
        return null;
      }

      return {
        orderId,
        nodeNumber,
        timestamp: endTime,
        earning: originalTotal,
        earningFiat: convertedTotal,
        currencyPrice: convertedTotal / originalTotal,
      };
    }),
    R.reject(R.equals(null)),
  )(earnings || []);

  const columnsToUse = busdEarningHistoryColumns(
    t,
  );

  const formatedIntervalEarning = R.map((eachData) => ({
    ...eachData,
    date: moment(eachData.timestamp).toLocaleString(),
    currency: 'JPY',
  }))(earningHistory || []);

  const csvHeaders = [
    { label: 'Date', key: 'timestamp' },
    { label: 'Earning (BUSD)', key: 'earning' },
    { label: 'Fiat Equivalent', key: 'earningFiat' },
    { label: 'Exchange Rate', key: 'currencyPrice' },
    { label: 'Currency', key: 'currency' },
  ];

  return (
    <InvestmentEarningDashboardItem
      titlePadding="21px 32px 16px 32px"
      title={(
        <Box display="flex" alignItems="center">
          <Box>
            {t('Monthly Statistics')}
          </Box>
          <Box ml={0.5} mr={2}>
            <CustomTooltip
              title={t('Data will be displayed at least one day after your nodes become active')}
              content={(
                <HelpOutlineOutlinedIcon
                  color="disabled"
                  style={{ fontSize: 20 }}
                />
              )}
            />
          </Box>
          <Box>
            <Button disabled={getIntervalFixedEarningLoading} className={classes.downloadButton}>
              <CSVLink
                data={formatedIntervalEarning}
                headers={csvHeaders}
                filename="BUSD_earning_history.csv"
                style={{ textDecoration: 'none' }}
              >
                <Typography color="primary" className={classes.downloadButtonText}>
                  Download CSV
                </Typography>
              </CSVLink>
            </Button>
          </Box>
        </Box>
)}
      content={(
        <ReactTableV7
          data={earningHistory}
          columns={columnsToUse}
          customPageSize={3}
          customPageSizeList={[3, 5, 10, 20, 50]}
          loading={getIntervalFixedEarningLoading}
        />
)}
    />
  );
};

export default BUSDEarningHistory;
