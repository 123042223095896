import env from '../../config/env';
import { useTestnet } from '../../modules/appConstants';

const { rootUrl } = env;
const userUrl = `${rootUrl}/user`;
const userRelationshipUrl = `${rootUrl}/user-relationship`;
const testnet = useTestnet;

const periodUnitToSeconds = {
  h: 3600,
  d: 86400,
};

const earningTypeMap = {
  NEUNODE: 'neunode',
  NEUNODE_ETH2: 'neunodeETH2',
  LENDING: 'busd',
  PHALA_MINING_NODE: 'phalaSixCore',
  PHALA_MINING_NODE_4_CORES: 'phalaFourCore',
};

const mapNodeTypeToCoin = {
  ETH2_VALIDATOR_NODE: 'eth2',
  UNUNIFI_VALIDATOR_NODE: 'ununifi',
  PHALA_MINING_NODE: 'pha',
  PHALA_MINING_NODE_4_CORES: 'pha',
};

export {
  testnet,
  userUrl,
  userRelationshipUrl,
  periodUnitToSeconds,
  earningTypeMap,
  mapNodeTypeToCoin,
};
