import * as R from 'ramda';

import { errorOperations } from '../error/index';
import { loadingOperations } from '../loading/index';

import * as actions from './actions';

export const getUserStripeData = R.curry(
  async (
    userId,
    dispatch,
    getState,
  ) => {
    try {
      dispatch(loadingOperations.setLoading('getUserStripeData', true));
      const { stripeR: { stripeCustomer } } = getState();

      const { type, payload } = dispatch(
        await actions.getUserStripeData(
          stripeCustomer,
          userId,
        ),
      );
      dispatch(loadingOperations.setLoading('getUserStripeData', false));
      dispatch(errorOperations.clearError('getUserStripeData'));
      return { type, payload };
    } catch (error) {
      dispatch(loadingOperations.setLoading('getUserStripeData', false));
      return dispatch(errorOperations.setError(
        'getUserStripeData',
        { fallbackErrorMessage: 'get user stripe data failed', error },
      ));
    }
  },
);

export const getUserStripePaymentMethods = R.curry(
  async (
    paymentMethodType,
    userId,
    dispatch,
  ) => {
    try {
      dispatch(loadingOperations.setLoading('getUserStripePaymentMethods', true));
      const { type, payload } = dispatch(
        await actions.getUserStripePaymentMethods(
          paymentMethodType,
          userId,
        ),
      );
      dispatch(loadingOperations.setLoading('getUserStripePaymentMethods', false));
      dispatch(errorOperations.clearError('getUserStripePaymentMethods'));
      return { type, payload };
    } catch (error) {
      dispatch(loadingOperations.setLoading('getUserStripePaymentMethods', false));
      return dispatch(errorOperations.setError(
        'getUserStripePaymentMethods',
        { fallbackErrorMessage: 'get user Stripe PaymentMethods failed', error },
      ));
    }
  },
);

export const detachStripePaymentMethod = R.curry(
  async (
    paymentMethodType,
    userId,
    stripePaymentMethodId,
    dispatch,
  ) => {
    try {
      dispatch(loadingOperations.setLoading('detachStripePaymentMethod', true));
      const { type, payload } = dispatch(
        await actions.detachStripePaymentMethod(
          paymentMethodType,
          userId,
          stripePaymentMethodId,
        ),
      );
      dispatch(loadingOperations.setLoading('detachStripePaymentMethod', false));
      dispatch(errorOperations.clearError('detachStripePaymentMethod'));
      return { type, payload };
    } catch (error) {
      dispatch(loadingOperations.setLoading('detachStripePaymentMethod', false));
      return dispatch(errorOperations.setError(
        'detachStripePaymentMethod',
        { fallbackErrorMessage: 'get user Stripe PaymentMethods failed', error },
      ));
    }
  },
);

export const createUserStripePaymentMethod = R.curry(
  async (
    stripe,
    userId,
    stripeCustomerId,
    card,
    dispatch,
  ) => {
    try {
      dispatch(loadingOperations.setLoading('createUserStripePaymentMethod', true));
      const { type, payload } = dispatch(
        await actions.createUserStripePaymentMethod(stripe,
          userId,
          stripeCustomerId,
          card),
      );
      dispatch(loadingOperations.setLoading('createUserStripePaymentMethod', false));
      dispatch(errorOperations.clearError('createUserStripePaymentMethod'));
      return { type, payload };
    } catch (error) {
      dispatch(loadingOperations.setLoading('createUserStripePaymentMethod', false));
      return dispatch(errorOperations.setError(
        'createUserStripePaymentMethod',
        { fallbackErrorMessage: 'create user Stripe PaymentMethods failed', error },
      ));
    }
  },
);

export const cancelSubscription = R.curry(
  async (
    subscriptionId,
    userId,
    nodeType,
    orderId,
    dispatch,
  ) => {
    try {
      dispatch(loadingOperations.setLoading('cancelSubscription', true));
      const { type, payload } = dispatch(
        await actions.cancelSubscription(
          subscriptionId,
          userId,
          nodeType,
          orderId,
        ),
      );
      dispatch(loadingOperations.setLoading('cancelSubscription', false));
      dispatch(errorOperations.clearError('cancelSubscription'));
      return { type, payload };
    } catch (error) {
      dispatch(loadingOperations.setLoading('cancelSubscription', false));
      return dispatch(errorOperations.setError(
        'cancelSubscription',
        { fallbackErrorMessage: 'Cancel subscription failed', error },
      ));
    }
  },
);

export const changeSubscriptionPlan = R.curry(
  async (
    subscriptionId,
    priceId,
    dispatch,
  ) => {
    try {
      dispatch(loadingOperations.setLoading('changeSubscriptionPlan', true));
      const { type, payload } = dispatch(
        await actions.changeSubscriptionPlan(
          subscriptionId,
          priceId,
        ),
      );
      dispatch(loadingOperations.setLoading('changeSubscriptionPlan', false));
      dispatch(errorOperations.clearError('changeSubscriptionPlan'));
      return { type, payload };
    } catch (error) {
      dispatch(loadingOperations.setLoading('changeSubscriptionPlan', false));
      return dispatch(errorOperations.setError(
        'changeSubscriptionPlan',
        { fallbackErrorMessage: 'Change subscription plan failed', error },
      ));
    }
  },
);

export const validatePromotionCode = R.curry(
  async (
    promotionCode,
    dispatch,
  ) => {
    try {
      dispatch(loadingOperations.setLoading('validatePromotionCode', true));
      const { type, payload } = dispatch(
        await actions.validatePromotionCode(promotionCode),
      );
      dispatch(loadingOperations.setLoading('validatePromotionCode', false));
      dispatch(errorOperations.clearError('validatePromotionCode'));
      return { type, payload };
    } catch (error) {
      dispatch(loadingOperations.setLoading('validatePromotionCode', false));
      return dispatch(errorOperations.setError(
        'validatePromotionCode',
        { fallbackErrorMessage: 'Invalid coupon code', error },
      ));
    }
  },
);
