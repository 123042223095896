// mutation
import {
  createMutation,
} from '../../../modules/apollo/apollo';

import {
  updateAuthTokenMutation,
} from './mutations';
// apollo

export const graphqlUpdateAuthToken = createMutation(updateAuthTokenMutation);
