import {
  from,
  ApolloClient,
  InMemoryCache,
  ApolloLink,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { RetryLink } from '@apollo/client/link/retry';
import { createUploadLink } from 'apollo-upload-client';

import {
  keyToAuthTokenDataInLocalStorage,
} from '../appConstants';
import { getAccessToken } from '../jwt_utils';

const uri = process.env.REACT_APP_API_URL || 'http://127.0.0.1:5001';

const uploadLink = createUploadLink({
  uri: `${uri}/graphql`,
  // Enable sending cookies over cross-origin requests
  credentials: 'include',
  headers: {
    'keep-alive': 'true',
  },
});
const defaultOptions = {
  query: {
    fetchPolicy: 'network-only',
    errorPolicy: 'all',
  },
  mutate: {
    errorPolicy: 'all',
  },
};

const operationNameMiddleware = new ApolloLink((operation, forward) => {
  const { operationName } = operation;
  operation.setContext({
    operationName,
  });
  return forward(operation);
});

const authLink = setContext(({ headers }, previousContext) => {
  const { operationName } = previousContext;
  return new Promise((success) => {
    getAccessToken(keyToAuthTokenDataInLocalStorage, operationName)
      .then((accessToken) => success(
        {
          headers: {
            ...headers,
            authorization: accessToken ? `Bearer ${accessToken}` : '',
          },
        },
      ));
  });
});

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    // console.log('[GraphQL Errors]:', graphQLErrors);
  }
  if (networkError) {
    console.log('[Network Error]:', networkError);
  }
});

const link = from([
  operationNameMiddleware,
  authLink,
  errorLink,
  new RetryLink(), // retry on network error
  uploadLink,
]);

export const client = new ApolloClient({
  link,
  cache: new InMemoryCache(),
  defaultOptions,
});
