import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Skeleton from '@material-ui/lab/Skeleton';
import toNumber from 'lodash/fp/toNumber';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import {
  useHistory,
} from 'react-router-dom';

// route
import { investmentBaseRoute } from '../../../routes';
import { convertToCryptoNumber } from '../../utils/utils';
import OverviewItem from '../OverviewItem';
import { styles } from '../styles';

const {
  REACT_APP_UNUNIFI_SPECIAL_USERID,
} = process.env;

const useStyles = makeStyles(styles);

const OverviewUnUniFiItem = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const history = useHistory();
  const {
    nodes,
    totalEarningInCoin,
    totalEarning,
    totalBalanceInCoin,
    totalBalance,
    showTitles,
    userId,
  } = props;

  const {
    getUserNodesLoading,
    getTotalEarningLoading,
  } = useSelector((state) => state.loadingR);

  if (!getUserNodesLoading) {
    if (!nodes.length) {
      return null;
    }
  }

  const redirectToUnUniFiPage = () => {
    history.push({
      pathname: `${investmentBaseRoute}/ununifi-validator`,
    });
  };

  // hardcode for 1 one UnUniFi owner have 50% off
  const plan = userId !== REACT_APP_UNUNIFI_SPECIAL_USERID
    ? '¥16,000/ month'
    : '¥8,000/ month';

  const getEarningContent = (value, valueInCoin, isLoading) => {
    if (isLoading) {
      return (
        <Skeleton
          variant="text"
          width="100%"
          height="100%"
        />
      );
    }
    const valueInCoinNumber = toNumber(valueInCoin);
    const valueInCoinToDisplay = convertToCryptoNumber(valueInCoinNumber);
    return (
      <Typography variant="body1" className={classes.nodeDescriptionContent}>
        {`${valueInCoinToDisplay} GUU`}
      </Typography>
    );
  };

  const isLoading = getTotalEarningLoading;

  const formatedData = {
    image: '/img/icon/ununifi.png',
    name: t('UnUniFi Validator Node'),
    plan,
    quantity: nodes.length,
    earning: getEarningContent(totalEarning, totalEarningInCoin, isLoading),
    balance: getEarningContent(totalBalance, totalBalanceInCoin, isLoading),
    staked: getEarningContent(totalBalance - totalEarning, totalBalanceInCoin - totalEarningInCoin, isLoading),
  };
  return (
    <Grid item xs={12}>
      <OverviewItem
        type="naas"
        data={formatedData}
        showTitles={showTitles}
        onClick={redirectToUnUniFiPage}
        loading={getUserNodesLoading}
      />
    </Grid>
  );
};

OverviewUnUniFiItem.propTypes = {
  nodes: PropTypes.array,
  showTitles: PropTypes.bool,
  userId: PropTypes.string.isRequired,
  totalEarning: PropTypes.number,
  totalBalance: PropTypes.number,
  totalEarningInCoin: PropTypes.number,
  totalBalanceInCoin: PropTypes.number,
};

OverviewUnUniFiItem.defaultProps = {
  nodes: [],
  showTitles: false,
  totalEarning: 0,
  totalBalance: 0,
  totalEarningInCoin: 0,
  totalBalanceInCoin: 0,
};

export default React.memo(OverviewUnUniFiItem);
