import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import {
  useHistory,
} from 'react-router-dom';
import { reduxForm } from 'redux-form';

import {
  useAddFunctionToRef,
  useGetQuery,
} from '../../hooks/hooks';
import {
  dashboardRoute, forgotPasswordRoute, signupRoute,
} from '../../routes';
import { drawerOperations } from '../../store/drawer/index';
import { errorServices, errorOperations } from '../../store/error/index';
import { navigationOperations } from '../../store/navigation/index';
import { routerServices } from '../../store/router/index';
import { userOperations } from '../../store/user/index';
import EmailField from '../EmailField/EmailField';
import ErrorMessage from '../ErrorMessage/ErrorMessage';
import { RoundedButton } from '../MaterialUiCustom/MaterialUiCustom';
import PasswordField from '../PasswordField/PasswordField';
import ServiceTerms from '../ServiceTerms/ServiceTerms';
import SocialIcons from '../SocialIcons/SocialIcons';

import { styles } from './styles';
// store

// routes

// import { routerOperations } from '../../store/router/index';

import { validateSignin } from './validate';

const { extractErrorMessage } = errorServices;
const useStyles = makeStyles(styles);

const SigninAdmin = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const history = useHistory();
  const queryLng = useGetQuery('lng');
  const {
    submitting,
    pristine,
    invalid,
    handleSubmit,
  } = props;
  const userR = useSelector((state) => state.userR);
  const errorR = useSelector((state) => state.errorR);
  const { isAuthenticated } = userR.authentication;
  const { signinError } = errorR || {};
  const message = extractErrorMessage(signinError);
  const dispatch = useDispatch();
  const onSignin = (email, password) => dispatch(userOperations.signin({ t }, email, password, 'admin'));
  const onDismount = useAddFunctionToRef(() => dispatch(errorOperations.clearError('signin')));
  const onSelectSidebarItem = useAddFunctionToRef((index) => {
    dispatch(navigationOperations.selectSidebarItem(index));
  });
  const [showPassword, setShowPassword] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  // shouldRenderErrorPage(errorToRender);
  const toogleShowPassWord = () => {
    setShowPassword((showPassword) => !showPassword);
  };
  useEffect(() => {
    setErrorMessage(message);
  }, [message]);

  useEffect(() => onDismount, [onDismount]);
  const onCloseDrawer = useAddFunctionToRef(() => {
    dispatch(drawerOperations.closeDrawer());
  });
  useEffect(() => {
    onCloseDrawer();
  }, [onCloseDrawer]);

  useEffect(() => {
    if (isAuthenticated) {
      onSelectSidebarItem(1);
      routerServices.historyPush(
        history,
        {
          pathname: dashboardRoute,
          queryLng,
        },
      );
    }
  }, [history, isAuthenticated, onSelectSidebarItem, queryLng]);

  const crateHandleLinkBtnClick = R.curry((queryLng, pathname) => {
    routerServices.historyPush(
      history,
      {
        pathname,
        queryLng,
      },
    );
  });
  const handleLinkBtnClick = crateHandleLinkBtnClick(queryLng);
  const getContent = () => (
    <>
      <Box className={classnames(classes.paper)}>
        <form
          className={classnames(classes.centerComponent, classes.form)}
          onSubmit={handleSubmit(({ email, password }) => onSignin(email, password))}
        >
          <Typography
            variant="h1"
            align="center"
            className={classes.title}
          >
            {t('Sign In')}
          </Typography>
          <ErrorMessage errorMessage={errorMessage} />
          <EmailField
            renderCase="sign in"
            showLabel={false}
            showTitle
          />
          <PasswordField
            label="Admin Key"
            handleClickShowPassword={toogleShowPassWord}
            showPassword={showPassword}
            showLabel={false}
            showTitle
          />
          <Box
            display="flex"
            flexDirection="column"
            width="100%"
          >
            <Box>
              <RoundedButton
                variant="contained"
                color="primary"
                type="submit"
                className={classes.button}
                disabled={submitting || pristine || invalid}
                noMargin
              >
                {t('sign in')}
              </RoundedButton>
            </Box>
            <Box>
              <Button
                disableRipple
                onClick={() => handleLinkBtnClick(forgotPasswordRoute)}
                className={classes.forgotPasswordText}
              >
                <Typography variant="body2">
                  {t('Forgot Password?')}
                </Typography>
              </Button>
            </Box>
          </Box>

        </form>
      </Box>
      <Divider className={classnames(classes.divider)} />
      <Box className={classnames(classes.paper)}>
        <Grid container direction="row" justifyContent="center" alignItems="center" spacing={1}>
          <Grid item>
            <Typography variant="body1" align="right">{t("Don't have an account?")}</Typography>
          </Grid>
          <Grid item>
            <RoundedButton
              variant="text"
              disableRipple
              color="primary"
              onClick={() => handleLinkBtnClick(signupRoute)}
              className={classes.signUpButton}
              noMargin
            >
              {t('Sign up')}
            </RoundedButton>
          </Grid>
        </Grid>
      </Box>
    </>
  );

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyItems="center"
      alignItems="center"
    >
      <Box
        className={classes.content}
      >
        {getContent()}
      </Box>
      <Box>
        <Box mb={3}>
          <SocialIcons />
        </Box>
        <ServiceTerms />
      </Box>
    </Box>
  );
};

SigninAdmin.defaultProps = {
  location: {
    state: {
      previousPathName: '',
    },
  },
};

// validate prop' type
SigninAdmin.propTypes = {
  submitting: PropTypes.bool.isRequired,
  pristine: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  location: PropTypes.shape({
    state: PropTypes.shape({
      previousPathName: PropTypes.string,
    }),
  }),
};

export default reduxForm({
  form: 'signin',
  validate: validateSignin,
})(SigninAdmin);
