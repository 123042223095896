import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { useIsMobile } from '../../hooks/hooks';

import { investmentContentItemStyles } from './styles';

const useStyles = makeStyles(investmentContentItemStyles);

const InvestmentContentItem = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const isMobile = useIsMobile();

  const {
    title,
    description,
    viewMoreCallback,
    numOfPlaceHolders,
    specialTag,
  } = props;

  const placeHoldersContent = R.pipe(
    R.repeat(
      <Box className={classes.placeHolder} border={0.5} />,
    ),
    R.addIndex(R.map)((eachPlaceHolder, index) => (
      <Grid item key={`place_holder_${index}`}>
        {eachPlaceHolder}
      </Grid>
    )),
  )(numOfPlaceHolders || 1);

  const specialTagContent = specialTag ? (
    <Box className={classes.specialTag} border={0.5}>
      <Typography className={classes.specialTagText} align="center" variant="body1" color="primary">
        {specialTag}
      </Typography>
    </Box>
  ) : null;

  return (
    <Paper className={classes.root}>
      <Grid container alignItems="center" wrap="nowrap" spacing={2} className={classes.content}>
        <Grid item xs={isMobile ? 4 : 3}>
          <Grid container direction="column">
            <Grid item>
              <Typography
                variant="h2"
                className={classes.title}
              >
                {title}
              </Typography>
            </Grid>
            <Grid item>
              <Typography>
                {description}
              </Typography>
            </Grid>
            <Grid item>
              <Button
                size="small"
                onClick={viewMoreCallback}
                className={classes.viewMoreButton}
                classes={{
                  label: classes.viewMoreButtonLabel,
                }}
              >
                {t('View more')}
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={isMobile ? 4 : 6}>
          <Grid container alignItems="center" spacing={2}>
            {placeHoldersContent}
          </Grid>
        </Grid>
        <Grid item xs={isMobile ? 4 : 3}>
          {specialTagContent}
        </Grid>
      </Grid>
    </Paper>
  );
};

InvestmentContentItem.defaultProps = {
  viewMoreCallback: () => {},
  numOfPlaceHolders: 0,
};

InvestmentContentItem.propTypes = {
  title: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
  ]).isRequired,
  description: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
  ]).isRequired,
  viewMoreCallback: PropTypes.func,
  numOfPlaceHolders: PropTypes.number,
  specialTag: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
  ]).isRequired,
};

export default InvestmentContentItem;
