import gql from 'graphql-tag';

export const getPaymentsQuery = gql`
  query getPayments($getPaymentsInput: GetPaymentsInput!)
  {
    getPayments(
      getPaymentsInput: $getPaymentsInput 
    ) {
      items {
        id
        amount
        amountCapturable
        amountReceived
        canceledAt
        cancellationReason
        created
        currency
        customer
        description
        invoice
        metadata
        status
      }
    }
  }
`;

export const getSubscriptionsQuery = gql`
  query getSubscriptions($getSubscriptionsInput: GetSubscriptionsInput!)
  {
    getSubscriptions(
      getSubscriptionsInput: $getSubscriptionsInput 
    ) {
      data {
        id
        cancelAt
        canceledAt
        cancelAtPeriodEnd
        created
        currentPeriodEnd
        currentPeriodStart
        customer
        daysUntilDue
        defaultPaymentMethod
        endedAt
        latestInvoice
        metadata
        quantity
        startDate
        status
        trialEnd
        trialStart
        invoices
        upcomingInvoice
        plan
      }
    }
  }
`;
