import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import LaunchOutlinedIcon from '@material-ui/icons/LaunchOutlined';
import whyDidYouRender from '@welldone-software/why-did-you-render';
import * as moment from 'moment';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import React from 'react';
import { useTranslation } from 'react-i18next';

import {
  eth2DeployCompleteStyles,
} from './styles';

const { REACT_APP_ENV } = process.env;

if (REACT_APP_ENV !== 'prod') {
  whyDidYouRender(React);
}

const useStyles = makeStyles(eth2DeployCompleteStyles);

const Eth2DeployComplete = (props) => {
  const classes = useStyles();
  const { depositKeys, network } = props;
  const { t } = useTranslation();

  const getLinkContent = () => R.addIndex(R.map)((eachDepositKey, index) => {
    const { txHash } = eachDepositKey;
    const urlRoot = network === 0 ? 'https://etherscan.io/tx' : 'https://goerli.etherscan.io/tx';
    const link = `${urlRoot}/${txHash}`;

    return (
      <Box
        display="flex"
        justifyContent="space-between"
        mt={1}
        key={`node ${index + 1}`}
        className={classes.depositDetailsItem}
      >
        <Box>
          <Typography>
            {`node ${index + 1}`}
          </Typography>
        </Box>
        <Box>
          <Link href={link} target="_blank">
            <Box display="flex" alignItems="center">
              <Box mr={1}>
                <LaunchOutlinedIcon />
              </Box>
              <Box mb={1}>
                <Typography>
                  {t('View on Etherscan')}
                </Typography>
              </Box>
            </Box>
          </Link>
        </Box>
      </Box>
    );
  }, depositKeys);

  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <Typography variant="h4" className={classes.congrat}>
          {t("Congratulations! You've finished setting up your validator nodes")}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography className={classes.description}>
          {`${t('Your deposit for Eth2.0 validator node has been broadcasted on')} ${moment().utc()}`}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography style={{ fontWeight: 600 }}>
          Deposit details
        </Typography>
        {getLinkContent()}
      </Grid>
    </Grid>
  );
};

Eth2DeployComplete.propTypes = {
  depositKeys: PropTypes.arrayOf(
    PropTypes.shape({
      pubkey: PropTypes.string,
    }),
  ).isRequired,
  network: PropTypes.number.isRequired,
};

Eth2DeployComplete.whyDidYouRender = true;
export default React.memo(Eth2DeployComplete);
