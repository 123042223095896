import {
  createReducer,
  mergeStateAndPayload,
} from '../store_utils';

import * as types from './types';

const fileIS = {
  fileLocation: '',
};

const fileRUpdaters = {
  [types.UPLOAD_FILE_TO_S3_SUCCESS]: (
    state,
    payload,
  ) => mergeStateAndPayload(state, payload),
};

const fileR = createReducer(fileIS, fileRUpdaters);

export default fileR;
