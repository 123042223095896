import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import {
  useHistory,
} from 'react-router-dom';

import { investmentBaseRoute } from '../../../routes';
import OverviewItem from '../OverviewItem';
import { getFiatContent } from '../utils';

const OverviewPhalaItem4Core = (props) => {
  const { t } = useTranslation();
  const history = useHistory();
  const {
    nodes,
    nodeCount,
    totalEarning,
    totalBalance,
    showTitles,
  } = props;

  const {
    getOrdersLoading,
  } = useSelector((state) => state.loadingR);

  const { dateCreated, contract } = nodes[0] || {};
  const { contractPeriodValue, contractPeriod } = contract;
  const plan = `${contractPeriodValue} ${contractPeriod} Auto-renewal`;

  if (!getOrdersLoading) {
    if (!nodeCount) {
      return null;
    }
  }

  const redirectToMiningPage = () => {
    history.push({
      pathname: `${investmentBaseRoute}/mining-4core`,
    });
  };

  const isLoading = totalBalance === -1; // -1 mean loading
  const formatedData = {
    image: '/img/icon/phala.png',
    name: t('Phala Network 4-core Mining Node'),
    plan,
    dateCreated,
    quantity: nodeCount,
    earning: getFiatContent(totalEarning, isLoading),
    balance: getFiatContent(totalBalance, isLoading),
    staked: getFiatContent(totalBalance - totalEarning, isLoading),
  };
  return (
    <Grid item xs={12}>
      <OverviewItem
        type="mining"
        data={formatedData}
        showTitles={showTitles}
        onClick={redirectToMiningPage}
        loading={getOrdersLoading}
      />
    </Grid>
  );
};

OverviewPhalaItem4Core.propTypes = {
  nodes: PropTypes.array,
  nodeCount: PropTypes.number,
  totalEarning: PropTypes.number,
  totalBalance: PropTypes.number,
  showTitles: PropTypes.bool,
};

OverviewPhalaItem4Core.defaultProps = {
  nodes: [],
  nodeCount: 0,
  totalEarning: 0,
  totalBalance: 0,
  showTitles: false,
};

export default React.memo(OverviewPhalaItem4Core);
